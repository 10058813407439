import Api from '@/util/Api'
import Store from '@/store'
export default class UserEventApiService {

  static log({ event = '', notes = '', table = null, table_item_id = null  }) {
    setTimeout(() => {
      if(Store.getters['general/getterDeviceInfo']?.uid) {
        return Api.post(`/api/users/user_events/store`, { event, notes, table, table_item_id })
      }
    }, 200)
  }

}