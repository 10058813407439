<template>
  <div class="w-full flex flex-col text-bluebrand p-6">
    <h1 class="text-2xl font-bold my-6">
      Design system guide
    </h1>

    <div class="w-full flex flex-col">
      <h3 class="text-gray04 font-bold text-lg mb-3">
        Buttons
      </h3>
      <div
        v-for="button in buttons"
        :key="button"
        class="mb-3 cursor-pointer"
      >
        <RenderHtml
          :html="button"
          @click.native="copy(button)"
        />
      </div>
    </div>

    <div class="w-full flex flex-col">
      <h3 class="text-gray04 font-bold text-lg mb-3">
        Inputs
      </h3>
      <div
        v-for="input in inputs"
        :key="input"
        class="mb-3 cursor-pointer"
      >
        <RenderHtml
          :html="input"
          @click.native="copy(input)"
        />
      </div>
    </div>

    <div class="w-full flex flex-col mt-4">
      <h3 class="text-gray04 font-bold text-lg mb-3">
        Headers
      </h3>
      <div
        v-for="text in headers"
        :key="text"
        class="mb-3 cursor-pointer"
      >
        <RenderHtml
          :html="text"
          @click.native="copy(text)"
        />
      </div>
    </div>

    <div class="w-full flex flex-col mt-4">
      <h3 class="text-gray04 font-bold text-lg mb-3">
        Accents
      </h3>
      <div
        v-for="text in accents"
        :key="text"
        class="mb-3 cursor-pointer"
      >
        <RenderHtml
          :html="text"
          @click.native="copy(text)"
        />
      </div>
    </div>

    <div class="w-full flex flex-col mt-4">
      <h3 class="text-gray04 font-bold text-lg mb-3">
        Paragraphs
      </h3>
      <div
        v-for="text in paragraphs"
        :key="text"
        class="mb-3 cursor-pointer"
      >
        <RenderHtml
          :html="text"
          @click.native="copy(text)"
        />
      </div>
    </div>

    <div class="w-full flex flex-col">
      <h3 class="text-gray04 font-bold text-lg mb-3">
        Colors
      </h3>
      <div class="w-full flex flex-wrap">
        <div
          v-for="(color, index) in getColors"
          :key="index"
          class="mb-3 cursor-pointer p-2"
        >
          <div
            class="w-32 h-32 rounded shadow p-2 flex flex-col"
            :class="[`bg-${color.key}`]"
            @click="copy(`bg-${color.key}`)"
          >
            <span>[bg/text]-{{ color.key }}</span>
            <span>{{ color.value }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { copyToClipboard } from '@/util/Helpers'
import tailwindConfig from '../../../../tailwind.config'
export default {
  name: 'DesignSystemIndex',
  data() {
    return {
      buttons: [
        `<AppButton size="medium" label="Example button" cssClass="bg-sec"/>`,
        `<AppButton size="big" label="Example button" cssClass="bg-sec"/>`,
      ],
      inputs: [
        `<InputWithLabel class="mt-6" :label="'Input'" :value="false" :mask="'####'" ></InputWithLabel>`
      ],
      headers: [
        `<h6 class="text-header-1 text-bluebrand font-bold">Some header 6</h6>`,
        `<h5 class="text-header-2 text-bluebrand font-bold">Some header 5</h5>`,
        `<h4 class="text-header-3 text-bluebrand font-bold">Some header 4</h4>`,
        `<h3 class="text-header-4 text-bluebrand font-bold">Some header 3</h3>`,
        `<h2 class="text-header-5 text-bluebrand font-bold">Some header 2</h2>`,
        `<h1 class="text-header-6 text-bluebrand font-bold">Some header 1</h1>`,
      ],
      accents: [
        `<p class="text-accent-1 text-bluebrand font-bold">Some accent 1</p>`,
        `<p class="text-accent-2 text-bluebrand font-bold">Some accent 2</p>`,
        `<p class="text-accent-3 text-bluebrand font-bold">Some accent 3</p>`,
      ],
      paragraphs: [
        `<p class="text-p-1 text-bluebrand font-bold">Some paragraph 1</p>`,
        `<p class="text-p-2 text-bluebrand font-bold">Some paragraph 2</p>`,
        `<p class="text-p-3 text-bluebrand font-bold">Some paragraph 3</p>`,
      ],
      colors: tailwindConfig.theme.colors
    }
  },
  computed: {
    getColors() {
      return Object.keys(tailwindConfig.theme.colors).map((key) => {
        return { key: key, value: tailwindConfig.theme.colors[key] }
      })
    }
  },
  methods: {
    copy(string) {
      copyToClipboard(string)
      this.$appToast({ message: 'Copied to clipboard!', duration: 5500 })
    }
  }
}
</script>

<style>

</style>