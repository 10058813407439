import { getNextQuarterFromToday } from '@/util/DateFunctions'
import dayjs from 'dayjs'

export const defaultImpactAssumptionValues = {

  // Input Basic Assumptions
  annual_penetration_rate : 5,
  applicability_rate : 50,
  active_policy_duration : 5,
  delay_installation : 1,
  start_at : getNextQuarterFromToday(),
  years : 30,

  // Input Advanced Assumptions
  likelihood_installations_first_third: 0,
  likelihood_installations_second_third: 33,
  likelihood_installations_final_third: 66,
  grid_max_renewables_year: 2045,
  grid_max_renewables_level: 100,
  current_grid_renewable_level: 33,
  current_kwh_emissions_factor: 0.00025566,
  current_therms_emissions_factor: 0.0054544,
  is_auto_generated: true,
  is_flexible_path: true
}

export default class ImpactAssumptions {
  constructor(obj = {}) {
    this.id = obj.id || null
    if (obj === null) obj = defaultImpactAssumptionValues

    for ( let key in defaultImpactAssumptionValues) {
      this[key] = obj && obj[key] ? obj[key] : defaultImpactAssumptionValues[key]
    }    
    
    // Helpers
    this.start_at_date = dayjs(this.start_at, 'MM/DD/YYYY')
    this.first_third_end_at = this.start_at_date.add((this.years / 3), 'years')
    this.second_third_end_at = this.start_at_date.add((this.years / 3) * 2, 'years')
    this.final_third_end_at = this.start_at_date.add(this.years, 'years')

  }


  get getBasicFinishAt() {
    return dayjs(this.start_at_date)
      .add(this.years, 'years')
  }

  get getAdvancedFinishAt() {
    return dayjs(this.start_at_date)
      .add(this.active_policy_duration, 'years')
      .add(this.delay_installation, 'years')
      .add(this.years, 'years')
  }

  get getAnnualPenetrationRateFinal() {
    return ((this.annual_penetration_rate / 100) * (this.applicability_rate / 100))
  }

  getActiveInstallationPeriod({ format = 'YYYY' } = { }) {
    const activeInstallationPeriodStart = this.start_at_date
    const activeInstallationPeriodEnd = activeInstallationPeriodStart.add(this.active_policy_duration, 'years')
    return [activeInstallationPeriodStart.format(format), activeInstallationPeriodEnd.format(format)]
  }
  
}