import GraphQL from '@/util/GraphQL'

export default class StudyTypeApiService {

  /**
   * get all StudyTypes with type_prototypes
   * *custom graphql resolver
   */
  static getWithTypePrototypes () {
    const query = `
      {
        study_types {
          id
          title
          type_prototypes {
            id
            title
            slug
            building_stock_title
            building_stock_helper
            building_type_id
            order
            color
          }
        }
      }
    `
    return GraphQL({ query, caller: `StudyTypeApiService.getWithTypePrototypes` })
  }
}