import { POLICY_LIST_JURISDICTION_FILTER_ALL } from '@/business-logic/constants/policiesEnum'
import { initialState } from './state'

export default {
  setLoggedUserToken(state, {type, token }) {
    state.loggedUserType = type
    state.loggedUserToken = token
  },
  setLoggedUser(state, {user}) {
    state.loggedUser = user
    if (user?.user_preferences) {
      state.user_preferences = user.user_preferences      
    }
    if (user?.is_team || user?.is_admin) {
      window.localStorage.setItem('is_explorer_team', true)
    }
  },
  setLogout(state) {
    const userPreferences = { ... state.user_preferences }
    const initialStateKeepingUserPreferences = { ...initialState(), user_preferences : userPreferences }
    Object.assign(state, initialStateKeepingUserPreferences )
  },

  setUserPreferences(state, { path, value }) {
    if (path) {
      Object.byString(state.user_preferences, path, value)
    }
  },
  setUserPreferencesFilterByJurisdiction(state, { jurisdiction, jurisdictions }) {
    const currentState = state.user_preferences?.policies?.myPolicies?.filterByJurisdiction 
    let filterBy = [... currentState ?? [] ]
    
    if (
      currentState === null &&
      jurisdiction.id !== POLICY_LIST_JURISDICTION_FILTER_ALL.id
    ) {
      filterBy = jurisdictions.filter(jur => jur.id !== jurisdiction.id).map(jur => jur.id)
    } else if (jurisdiction && jurisdiction.id === POLICY_LIST_JURISDICTION_FILTER_ALL.id) {
      filterBy = []
    } else if (jurisdiction && filterBy.includes(jurisdiction.id)) {      
      filterBy = filterBy.filter(jurisdictionId => jurisdiction.id !== jurisdictionId)
      state.user_preferences.lastJurisdictionVisited = jurisdiction
    } else if (jurisdiction) {
      filterBy.push(jurisdiction.id)
    } else {
      filterBy = null
    }

    state.user_preferences.policies.myPolicies.filterByJurisdiction = filterBy
    
  },    
  
  setModuleVisited(state, payload) {
    state.user_preferences.modulesVisited = [... new Set([...state.user_preferences.modulesVisited, payload ])]
  }
  
} 