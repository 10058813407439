
import MainVuex from '@/store'
import Graphql from '@/util/GraphQL'
import Study from '@/models/Study'
import StudyGroup from '@/models/StudyGroup'
import StudyType from '@/models/StudyType'

const isPrivateQuery  = () => {
  const isPrivate = MainVuex.getters.getterLoggedUser && MainVuex.getters.getterLoggedUserType == 'admin'
  return isPrivate ? `` : `where: [{ column: "is_private", operation: "=", value: "false" }]`
}

export default class StudyGroupApiService {

  static async all() {

    const query = `
      {
        study_groups (
          orderBy: [{column: "order", order: "asc"}]
        ) {
          id
          title
          order
          study_type_id
          custom_fields {
            item_id
            data
          }
          studies (
              orderBy: [{column: "released_at", order: "desc"}]
              ${isPrivateQuery()}
            ) {
            id
            title
            type_id
            released_at
            published_at
            has_content
            is_upcoming
            source_pdf
            study_group_id
            custom_fields {
              item_id
              data
            }
          }
        }
      }
    `
    const { data } = await Graphql({ query })

    if (data.study_groups) {
      return data.study_groups.map((studyGroup) => {              
        studyGroup.studies = studyGroup.studies.map(study => new Study(study))              
        return new StudyGroup(studyGroup)
      })
    }

    return []
    
  }


  static async byStudyType() {
    const query = `
      {
        study_types(orderBy: [{column: "order", order: "asc"}]) {
          id
          title
          order
          custom_fields {
            item_id
            data
          }
          study_groups {
            id
            title
            order
            study_type_id
            custom_fields {
              item_id
              data
            }
            studies (
                orderBy: [{column: "released_at", order: "desc"}]
                ${isPrivateQuery()}
              ) {
              id
              is_upcoming
              title
              released_at
              published_at
              source_pdf
              study_group_id
              custom_fields {
                item_id
                data
              }
            }
          }
        }
      }
    `
    const { data } = await Graphql({ query })

    if (data.study_types) {
      
      return data.study_types.map((studyType) => {
        
        studyType.study_groups = studyType.study_groups.map(studyGroup => {
          studyGroup.studies = studyGroup.studies.map(study => new Study(study))              
          return new StudyGroup(studyGroup)
        })

        return new StudyType(studyType)
      })
    }

    return []
  }
  
}