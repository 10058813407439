<template>
  <pre
    v-if="shouldShow"
    class="bg-red p-1 bg-opacity-40"
  >
      <slot />
    </pre>
</template>

<script>
export default {
  name: 'DebugPre',
  props: {
    envs: {
      default: () => ['development', 'staging']
    }
  },
  computed: {
    shouldShow() {
      return this.envs.includes(process.env.VUE_APP_ENV)
    }
  }
}
</script>