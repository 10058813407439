<template>
  <div class="w-full psui-bg-gray-10 block pb-32">
    <div class="w-full flex flex-col px-3 pr-8 md:pl-8 min-h-screen justify-start">
      <template v-for="studyType in studyTypes">
        <StudyResultsContentStudyType 
          :key="`studyresults-studytype-${studyType.id}`"
          :study-type="studyType"
        >
          <template v-for="studyGroup in studyType.study_groups">
            <template v-for="typePrototype in type_prototypes">
              <template v-for="prototype in getStudyGroupPrototypesFilteredByTypePrototypeAndSortedByStudyRelease({ studyGroup, typePrototype })">                
                <StudyResultsContentPrototype
                  v-show="
                    !checkQueryKeyValue('exclude_type_prototype_ids', typePrototype.id) && 
                      !checkQueryKeyValue('exclude_study_ids', prototype.study.id) &&
                      prototype.study.published_at         
                  "
                  :key="`studyresults-prototype-${prototype.id}`"
                  :study="prototype.study"
                  :prototype="prototype"
                  :study-group="studyGroup"
                >
                  <div class="flex flex-col space-y-10">
                    <template v-if="prototype.study.vintages">
                      <template v-for="vintage in getVintagesOrdered(prototype.study.vintages)">                        
                        <StudyResultsContentVintage
                          v-show="!checkQueryKeyValue('exclude_type_vintage_ids', vintage.type_vintage_id)"
                          :key="`studyresults-vintage-${vintage.id}`"
                          :vintage="vintage"
                          :study="prototype.study"
                          :prototype="prototype"
                        />
                      </template>
                    </template>
                    <template v-if="prototype.study.fuels">
                      <template v-for="fuel in getFuelsOrdered(prototype.study.fuels)">
                        <StudyResultsContentFuel
                          v-show="!checkQueryKeyValue('exclude_type_fuel_ids', fuel.type_fuel_id)"
                          :key="`studyresults-fuel-${fuel.id}`"
                          :fuel="fuel"
                          :study="prototype.study"
                          :prototype="prototype"
                        />
                      </template>
                    </template>
                  </div>
                </StudyResultsContentPrototype>
              </template>
            </template>
          </template>
        </StudyResultsContentStudyType>
      </template>
    </div>
  </div>
</template>

<script>
import StudyResultsContentStudyType from './content/StudyType'
import StudyResultsContentPrototype from './content/Prototype'
import StudyResultsContentVintage from './content/Vintage'
import StudyResultsContentFuel from './content/Fuel'
import StudyType from '@/models/StudyType'
import StudyResultApiService from '@/services/api/StudyResultApiService'

export default {
  name: 'StudyResultsContent',
  components: { 
    StudyResultsContentStudyType,
    StudyResultsContentPrototype,
    StudyResultsContentVintage,
    StudyResultsContentFuel
  },
  data : () => ({
    studyTypes : [],
    type_prototypes: [],
  }),
  computed: {
    studies() {
      return this.studyTypes.reduce((prev, studyType) => {
        studyType.study_groups.map(studyGroup => {
          prev = [ ...prev, ...studyGroup.studies]
        })
        return prev
      }, [])
    },
    isShowingExistingBuildings() {
      const existingBuildingStudiesIds = this.studies.filter(study => study.type_id == 1 && study.published_at).map(study => study.id)
      return existingBuildingStudiesIds
    }
  },
  mounted() {
    this.getStudyResultsInitialData()
  },
  methods: {
    getStudyResultsInitialData() {
      StudyResultApiService.get().then(({ study_types, type_prototypes }) => {
        this.studyTypes = study_types.map((i) => new StudyType(i)) ?? []
        this.type_prototypes = type_prototypes ?? []
      })
    },

    getStudyGroupPrototypes(study_group) {
      const studyGroup = { ...study_group }
      delete studyGroup.studies 
      return [...study_group.studies].reduce((prev, study) => {
          study.prototypes.forEach((prototype) => {
            prev.push({ ... prototype, study : { ...study, study_group: studyGroup } })
          })
        return prev
      }, [])
    },

    getStudyGroupPrototypesFilteredByTypePrototypeAndSortedByStudyRelease({ studyGroup, typePrototype }) {
      return this
        .getStudyGroupPrototypes(studyGroup)
        .filter((prototype) => parseInt(prototype.type_prototype_id) === parseInt(typePrototype.id) )
        .sort((a, b) => new Date(b.study.released_at) - new Date(a.study.released_at))
        .filter((prototype, index) => {
          // Apply the filter again to return only the lastest version (first item / index 0) of the study as DEFAULT
          // https://epcgo.atlassian.net/browse/DDP-1216?filter=10042
          if(!this.checkQueryHasKey('exclude_study_ids')) return index === 0
          return true
        })
    },
    getVintagesOrdered(vintages) {
      const vintagesOrdered = [...vintages]
      vintagesOrdered.sort((a,b) => a.type_vintage?.order - b.type_vintage?.order )
      return vintagesOrdered
    },
    getFuelsOrdered(fuels) {
      const fuelsOrdered = [...fuels]
      fuelsOrdered.sort((a,b) => a.type_fuel?.order - b.type_fuel?.order )
      return fuelsOrdered
    }
  }
}

</script>

