/**
 * 
 */

export default ({ currentQuarterDate, assumption }) => {

  let baselineInstallationsStartedPercentage = 100
  let baselineInstallationsCompletedPercentage = 100

  if(currentQuarterDate.isBefore(assumption.first_third_end_at)) {
    baselineInstallationsStartedPercentage = assumption.likelihood_installations_first_third
  }
  if(currentQuarterDate.isSameOrAfter(assumption.start_at_date.add(assumption.delay_installation, 'years')) &&  currentQuarterDate.isBefore(assumption.first_third_end_at.add(assumption.delay_installation, 'years'))) {
    baselineInstallationsCompletedPercentage = assumption.likelihood_installations_first_third
  }

  // Baseline second third
  if(currentQuarterDate.isSameOrAfter(assumption.first_third_end_at) && currentQuarterDate.isBefore(assumption.second_third_end_at)) {
    baselineInstallationsStartedPercentage = assumption.likelihood_installations_second_third
  }
  if(currentQuarterDate.isSameOrAfter(assumption.first_third_end_at.add(assumption.delay_installation, 'years')) && currentQuarterDate.isBefore(assumption.second_third_end_at.add(assumption.delay_installation, 'years'))) {
    baselineInstallationsCompletedPercentage = assumption.likelihood_installations_second_third
  }

   // Baseline second third
   if(currentQuarterDate.isSameOrAfter(assumption.second_third_end_at)) {
    baselineInstallationsStartedPercentage = assumption.likelihood_installations_final_third
  }
  if(currentQuarterDate.isSameOrAfter(assumption.second_third_end_at.add(assumption.delay_installation, 'years'))) {
    baselineInstallationsCompletedPercentage = assumption.likelihood_installations_final_third
  }

  return {
    baselineInstallationsStartedPercentage,
    baselineInstallationsCompletedPercentage
  }
}