export default [
  {
    "utility": "1-PGE",
    "3_eletric": "PG&E",
    "3_gas": "PG&E",
    "2_eletric": "PG&E",
    "2_gas": "PG&E",
    "1_eletric": "PG&E",
    "1_gas": "PG&E"
  },
  {
    "utility": "2-PGE",
    "3_eletric": "PG&E",
    "3_gas": "PG&E",
    "2_eletric": "PG&E",
    "2_gas": "PG&E",
    "1_eletric": "PG&E",
    "1_gas": "PG&E"
  },
  {
    "utility": "3-PGE",
    "3_eletric": "PG&E",
    "3_gas": "PG&E",
    "2_eletric": "PG&E",
    "2_gas": "PG&E",
    "1_eletric": "PG&E",
    "1_gas": "PG&E"
  },
  {
    "utility": "4-PGE",
    "3_eletric": "PG&E",
    "3_gas": "PG&E",
    "2_eletric": "PG&E",
    "2_gas": "PG&E",
    "1_eletric": "PG&E",
    "1_gas": "PG&E"
  },
  {
    "utility": "4-CPAU",
    "3_eletric": "CPAU",
    "3_gas": "CPAU",
    "2_eletric": "PG&E",
    "2_gas": "PG&E",
    "1_eletric": "PG&E",
    "1_gas": "PG&E"
  },
  {
    "utility": "5-PGE",
    "3_eletric": "PG&E",
    "3_gas": "PG&E",
    "2_eletric": "PG&E",
    "2_gas": "PG&E",
    "1_eletric": "PG&E",
    "1_gas": "PG&E"
  },
  {
    "utility": "5-SCG",
    "3_eletric": "PG&E",
    "3_gas": "SCG",
    "2_eletric": "PG&E",
    "2_gas": "SCG",
    "1_eletric": "PG&E",
    "1_gas": "SCG"
  },
  {
    "utility": "6-SCE",
    "3_eletric": "SCE",
    "3_gas": "SCG",
    "2_eletric": "SCE",
    "2_gas": "SCG",
    "1_eletric": "SCE",
    "1_gas": "SCG"
  },
  {
    "utility": "6-LADWP",
    "3_eletric": "LADWP",
    "3_gas": "SCG",
    "2_eletric": "LADWP",
    "2_gas": "SCG",
    "1_eletric": "SCE",
    "1_gas": "SCG"
  },
  {
    "utility": "7-SDGE",
    "3_eletric": "SDG&E",
    "3_gas": "SDG&E",
    "2_eletric": "SDG&E",
    "2_gas": "SDG&E",
    "1_eletric": "SDG&E",
    "1_gas": "SDG&E"
  },
  {
    "utility": "8-SCE",
    "3_eletric": "SCE",
    "3_gas": "SCG",
    "2_eletric": "SCE",
    "2_gas": "SCG",
    "1_eletric": "SCE",
    "1_gas": "SCG"
  },
  {
    "utility": "8-LADWP",
    "3_eletric": "LADWP",
    "3_gas": "SCG",
    "2_eletric": "LADWP",
    "2_gas": "SCG",
    "1_eletric": "SCE",
    "1_gas": "SCG"
  },
  {
    "utility": "9-SCE",
    "3_eletric": "SCE",
    "3_gas": "SCG",
    "2_eletric": "SCE",
    "2_gas": "SCG",
    "1_eletric": "SCE",
    "1_gas": "SCG"
  },
  {
    "utility": "9-LADWP",
    "3_eletric": "LADWP",
    "3_gas": "SCG",
    "2_eletric": "LADWP",
    "2_gas": "SCG",
    "1_eletric": "SCE",
    "1_gas": "SCG"
  },
  {
    "utility": "10-SCE",
    "3_eletric": "SCE",
    "3_gas": "SCG",
    "2_eletric": "SCE",
    "2_gas": "SCG",
    "1_eletric": "SCE",
    "1_gas": "SCG"
  },
  {
    "utility": "10-SDGE",
    "3_eletric": "SDG&E",
    "3_gas": "SDG&E",
    "2_eletric": "SDG&E",
    "2_gas": "SDG&E",
    "1_eletric": "SDG&E",
    "1_gas": "SDG&E"
  },
  {
    "utility": "11-PGE",
    "3_eletric": "PG&E",
    "3_gas": "PG&E",
    "2_eletric": "PG&E",
    "2_gas": "PG&E",
    "1_eletric": "PG&E",
    "1_gas": "PG&E"
  },
  {
    "utility": "12-PGE",
    "3_eletric": "PG&E",
    "3_gas": "PG&E",
    "2_eletric": "PG&E",
    "2_gas": "PG&E",
    "1_eletric": "PG&E",
    "1_gas": "PG&E"
  },
  {
    "utility": "12-SMUD",
    "3_eletric": "SMUD",
    "3_gas": "PG&E",
    "2_eletric": "SMUD",
    "2_gas": "PG&E",
    "1_eletric": "PG&E",
    "1_gas": "PG&E"
  },
  {
    "utility": "13-PGE",
    "3_eletric": "PG&E",
    "3_gas": "PG&E",
    "2_eletric": "PG&E",
    "2_gas": "PG&E",
    "1_eletric": "PG&E",
    "1_gas": "PG&E"
  },
  {
    "utility": "14-SCE",
    "3_eletric": "SCE",
    "3_gas": "SCG",
    "2_eletric": "SCE",
    "2_gas": "SCG",
    "1_eletric": "SCE",
    "1_gas": "SCG"
  },
  {
    "utility": "14-SDGE",
    "3_eletric": "SDG&E",
    "3_gas": "SDG&E",
    "2_eletric": "SDG&E",
    "2_gas": "SDG&E",
    "1_eletric": "SDG&E",
    "1_gas": "SDG&E"
  },
  {
    "utility": "15-SCE",
    "3_eletric": "SCE",
    "3_gas": "SCG",
    "2_eletric": "SCE",
    "2_gas": "SCG",
    "1_eletric": "SCE",
    "1_gas": "SCG"
  },
  {
    "utility": "16-PGE",
    "3_eletric": "PG&E",
    "3_gas": "PG&E",
    "2_eletric": "PG&E",
    "2_gas": "PG&E",
    "1_eletric": "PG&E",
    "1_gas": "PG&E"
  },
  {
    "utility": "16-LADWP",
    "3_eletric": "LADWP",
    "3_gas": "SCG",
    "2_eletric": "LADWP",
    "2_gas": "SCG",
    "1_eletric": "PG&E",
    "1_gas": "PG&E"
  }
]