<template>
  <div class="w-full my-4 pr-4 flex flex-wrap justify-start font-medium mb-20">
    <div
      v-for="(columnGroup, indexColumnGroup) in getColumns.columnGroups"
      :key="indexColumnGroup"
      class="w-full mb-4 rounded-sm"
      :class="{ 'is-dragging-out' : indexColumnGroup == isDraggingOverGroup}"
    >
      <!-- COLUMN GROUP -->
      <div 
        class="w-full flex justify-between rounded-sm"
        draggable="true"
        @dragstart="dragStartGroup({ indexColumnGroup })"
        @dragover="dragOverGroup({ indexColumnGroup })"
        @dragend="dragEndGroup"
      >
        <h2 class="w-full text-gray03 ts--accent--book--2 mb-2 cursor-grab">
          {{ columnGroup.title }}
        </h2>
        <img
          class="h-6 cursor-grab"
          src="/icons/move-balls.svg"
          alt=""
        >
      </div>

      <div class="w-full flex flex-wrap">
        <!-- COLUMNS -->
        <div
          v-for="(column, indexColumn) in columnGroup.columns"
          :id="`edit-columns-column-${indexColumnGroup}-${indexColumn}`"
          :key="`edit-columns-column-${indexColumnGroup}-${indexColumn}`"
          class="w-full mb-3 app-draggable-list-item"
        >
          <div
            class="select-none w-full border border-gray05 text-gray02 p-2 px-3 rounded-sm flex items-center"
            draggable="true"
            :class="{ 'is-dragging-out' : checkColumnIsDragOver({ indexColumnGroup, indexColumn }) }"
            @dragstart="dragStartColumn({ indexColumnGroup, indexColumn })"
            @dragover="dragOverColumn({ indexColumnGroup, indexColumn })"
            @dragend="dragEndColumn"
          >
            <Checkbox
              class="whitespace-no-wrap"
              :label="column.title"
              :value="column.isActive"
              @change="toggleColumnIsActive({ studyKey: getColumns.key, indexColumnGroup, indexColumn, columnGroupKey: columnGroup.key})"
            />
            <div class="w-full flex justify-end">
              <img
                class="mx-2"
                src="/icons/move-balls.svg"
                alt=""
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DragAndDropEditColumnsList',
  props: ['getColumns', 'module'],
  data() {
    return {
      // Column
      isDraggingColumnGroup: -1,
      isDraggingOverColumn: -1,
      isDraggingColumn: -1,

      // Group
      isDraggingGroup: -1,
      isDraggingOverGroup: -1,
    }
  },

  computed: {
    getColumnsStateKey() {
      return `${this.module}Columns`
    },
    getMoveColumnStoreMutationName() {
      return `${this.module}/moveColumnItem`
    },
    getMoveColumnGroupStoreMutationName() {
      return `${this.module}/moveColumnGroup`
    },
    getToggleColumnIsActiveStoreMutationName() {
      return `${this.module}/toggleColumnIsActive`
    }

  },

  methods: {

    toggleColumnIsActive({ studyKey, indexColumnGroup, indexColumn, columnGroupKey }) {
      this.$store.commit(this.getToggleColumnIsActiveStoreMutationName, { columnsStateKey: this.getColumnsStateKey, studyKey, indexColumnGroup, indexColumn, columnGroupKey })
    },

    /**
    * Drag and drop COLUMN
    */
     dragStartColumn({ indexColumnGroup, indexColumn }) {
      this.isDraggingColumn = indexColumn
      this.isDraggingColumnGroup = indexColumnGroup
    },
    dragEndColumn() {
      this.moveItemColumn({
        studyKey: this.getColumns.key, 
        indexColumnGroup: this.isDraggingColumnGroup,
        from: this.isDraggingColumn,
        to: this.isDraggingOverColumn
      })
      this.dragLeaveColumn()
      this.dragLeaveGroup()
    },
    dragOverColumn({ indexColumnGroup, indexColumn }) {
      if(this.isDraggingColumnGroup != indexColumnGroup) return
      this.isDraggingOverColumn = indexColumn
    },
    checkColumnIsDragOver({indexColumnGroup, indexColumn }) {
      return (this.isDraggingColumnGroup == indexColumnGroup && this.isDraggingOverColumn == indexColumn)
    },
    dragLeaveColumn() {
      this.isDraggingColumn = -1
      this.isDraggingColumnGroup = -1
      this.isDraggingOverColumn = -1
    },
    moveItemColumn({ studyKey, indexColumnGroup, from, to}) {
      this.$store.commit(this.getMoveColumnStoreMutationName, { 
        columnsStateKey: this.getColumnsStateKey, 
        studyKey, 
        indexColumnGroup, 
        from, 
        to
      })
      this.blinkColumns({ indexColumnGroup, to })
      this.dragLeaveColumn()
    },

    blinkColumns({ indexColumnGroup, to }) {
      const colToBlink = document.getElementById(`edit-columns-column-${indexColumnGroup}-${to}`)
      if(colToBlink) {
        colToBlink.classList.add('blink')
        setTimeout(() => colToBlink.classList.remove('blink'), 800)
      }
    },

    /**
    * Drag and drop GROUP
    */
    dragStartGroup({ indexColumnGroup }) {
      this.isDraggingGroup = indexColumnGroup
    },
    dragEndGroup() {
      this.moveItemGroup({ 
        studyKey: this.getColumns.key,
        from: this.isDraggingGroup,
        to: this.isDraggingOverGroup
      })
      this.dragLeaveGroup()
    },
    dragOverGroup({ indexColumnGroup }) {
      if(this.isDraggingGroup < 0) return
      this.isDraggingOverGroup = indexColumnGroup
    },
    dragLeaveGroup() {
      this.isDraggingGroup = -1
      this.isDraggingOverGroup = -1
    },
    moveItemGroup({ studyKey, from, to}) {
      this.$store.commit(this.getMoveColumnGroupStoreMutationName, { 
        columnsStateKey: this.getColumnsStateKey,
        studyKey,
        from,to
      })
    }
    
  }
}
</script>
<style lang="scss" scoped>
  .app-draggable-list-item {
    cursor: grab;
    label {
      margin-bottom: 0;
    }
  }
</style>