<template>
  <BaseModal
    ref="baseModal"
    modal-dialog-classes="w-auto"
    @close="overwriteLocalData"
  >
    <div class="flex justify-center">
      <div class="flex flex-col space-y-6 modal-overwrite-custom-data-dialog">
        <h3 class="psui-text-h4 psui-text-gray-80 font-bold">
          Looks like you already have custom building estimates saved for {{ getLastJurisdiction.title }} {{ getLastJurisdiction.type }}.
        </h3>
        <p class="psui-text-p psui-text-gray-50">
          Want us to replace them with the new ones you entered?
        </p>

        <div class="w-full flex space-x-2">
          <!-- PERSIST LOCAL TO DB OVERWRITING CONFLICTS -->
          <PsButton
            label="Yes, overwrite old data"
            @click="overwriteStoredData()"
          />
          <!-- PERSIST DB TO LOCAL OVERWRITING CONFLICTS -->
          <PsButton
            label="No, restore old data"
            layout="onlytext"
            @click="overwriteLocalData()"
          />
        </div>
      </div>
    </div>
  </BaseModal>
</template>

<script>
import CustomBuildingStockApiService from '@/services/api/CustomBuildingStockApiService'
import CustomBuildingStock from '@/models/CustomBuildingStock'
import CustomBuildingEstimateApiService from '@/services/api/CustomBuildingEstimateApiService'
import CustomBuildingEstimate from '@/models/CustomBuildingEstimate'
import DataAndObjectFunctions from '@/util/DataAndObjectFunctions'

export default {
  name: 'AssumptionsOverwriteDataModal',
  data: () => ({
  }),
  computed: {
    getCustomBuildingStocks() {
      return this.$store.state.assumptions.buildingStocks.custom_building_stocks
    },
    getCustomBuildingEstimate() {
      return this.$store.state.assumptions.buildingEstimates.custom_building_estimates
    },
    getLastJurisdiction() {
      return this.$store.getters['lastJurisdictionVisited']
    }
  },
  mounted() {
    this.$eventBus.$on('openAssumptionsOverwriteDataModal', this.open)
    this.$eventBus.$on('checkBuildingStockAndEstimateConflict', () => {
      this.checkBuildingStockAndEstimateConflict()
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('openAssumptionsOverwriteDataModal')
    this.$eventBus.$off('checkBuildingStockAndEstimateConflict')
  },
  methods: {

    open() {
      if(this.$refs.baseModal)  this.$refs.baseModal.showModal = true
    },

    close() {
      this.$refs.baseModal.showModal = false
    },

    async checkBuildingStockAndEstimateConflict() {
      setTimeout( async () => {
        const hasConflictBuildingStocks = await this.checkHasConflictOnBuildingStocks()
        const hasConflictBuildingEstimates = await this.checkHasConflictOnBuildingEstimates()
        if(hasConflictBuildingStocks || hasConflictBuildingEstimates) {
          this.open()
        } else {
          this.$store.dispatch('assumptions/storeBatchAllCustomBuildings')
          .then(() => {
            this.$store.dispatch('assumptions/buildingStocks/getCustomBuildingStocks')
            this.$store.dispatch('assumptions/buildingEstimates/getCustomBuildingEstimates')
          })
        }
      }, 2000)
      
    },

    async checkHasConflictOnBuildingStocks() {
      let hasConflict = false
      const { data } = await new CustomBuildingStockApiService().get()
      const custom_building_stocks = data.currentUser?.custom_building_stocks
      if(custom_building_stocks) {
        for(const cbs of custom_building_stocks) {
          const cbsConflict = this.getCustomBuildingStocks.findInArray({
            ...DataAndObjectFunctions.filterObjectKeys(CustomBuildingStock.filtrableKeys, cbs)
          })
          if(cbsConflict) hasConflict = true
        }
      }
      return hasConflict
    },

    async checkHasConflictOnBuildingEstimates() {
      let hasConflict = false
      const { data } = await new CustomBuildingEstimateApiService().get()
      const custom_building_estimates = data.currentUser?.custom_building_estimates
      if(custom_building_estimates) {
        for(const cbs of custom_building_estimates) {
          const cbsConflict = this.getCustomBuildingEstimate.findInArray({
            ...DataAndObjectFunctions.filterObjectKeys(CustomBuildingEstimate.filtrableKeys, cbs)
          })
          if(cbsConflict) hasConflict = true
        }
      }
      return hasConflict
    },

    overwriteStoredData() {
      this.$store.dispatch('assumptions/storeBatchAllCustomBuildings')
        .then(() => {
          this.close()
          this.$store.dispatch('assumptions/buildingStocks/getCustomBuildingStocks')
          this.$store.dispatch('assumptions/buildingEstimates/getCustomBuildingEstimates')
        })
    },

    overwriteLocalData() {
      Promise.all([
        this.$store.dispatch('assumptions/buildingStocks/getCustomBuildingStocks'),
        this.$store.dispatch('assumptions/buildingEstimates/getCustomBuildingEstimates'),
      ])
      .then(() => {
        this.close()
      })
    }
  }
}
</script>

<style scoped>
  .modal-overwrite-custom-data-dialog {
    width: 400px;
  }
</style>
