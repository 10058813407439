<template>
  <PsAccordionItem
    title="Current Grid Clean Energy Share"
    :class="{ 'form-dirty' : hasValueUpdated }"
  >
    <transition
      name="fade"
      mode="out-in"
    >
      <div v-if="!advancedMode">
        Today 
        <DropdownWithInputNumber
          :value="getCurrentGrid"
          :min-value="0"
          :max-value="100"
          :step="0.1"
          :base-value="currentGridBaseValue"
          :button-label="`${getCurrentGrid}%`"
          suffix="%"
          @update:value="onUpdateCurrentGrid"
        />
        of grid supplied electricity is generated from renewable or zero-emission power sources, resulting in {{ simpleCurrentKwhEmissionFactor }} metric tons of GHG emissions per kWh. <span
          class="underline cursor-pointer text-deepsky-500 font-bold  "
          @click="advancedMode = !advancedMode"
        > Or you may specify BOTH current electric renewable share and current electric emissions factor.</span>
      </div>
      <div v-else>
        Today 
        <DropdownWithInputNumber
          :value="getCurrentGrid"
          :min-value="0"
          :max-value="100"
          :step="0.1"
          :base-value="currentGridBaseValue"
          :button-label="`${getCurrentGrid}%`"
          suffix="%"
          @update:value="onUpdateCurrentGrid"
        />
        of grid supplied electricity is generated from renewable or zero-emission power sources, resulting in 
        <DropdownWithInputNumber
          :value="getCurrentKwhEmissionFactor"
          :min-value="0"
          :max-value="0.001"
          :step="0.0000001"
          :base-value="currentKwhEmissionFactorBaseValue"          
          :button-label="`${getCurrentKwhEmissionFactor}`"
          @update:value="onUpdateCurrentKwhEmissionFactor"
        />
        metric tons of GHG emissions per kWh. <span
          class="underline cursor-pointer text-deepsky-500 font-bold  "
          @click="advancedMode = !advancedMode"
        > Or you may specify JUST the current electric renewable share, and we will calculate the electric emissions factor.</span>
      </div>
    </transition>        
  </PsAccordionItem>
</template>

<script>
import DropdownWithInputNumber from '@/components/general/DropdownWithInputNumber.vue'
import { defaultImpactAssumptionValues } from '@/models/ImpactAssumptions'

// Source : https://docs.google.com/spreadsheets/d/16PIVWUg9dK3Uz-42CvEvG6QPeiTzEsVO0lhiaq8a7yI/edit#gid=802498198
const zeroRenewableKwhEmissionFactor = 0.000381587301587302

export default {
  name: 'AssumptionsImpactsCurrentGridCleanEnergyShare',
  components: { DropdownWithInputNumber },
  data: () => ({
    currentGridBaseValue: defaultImpactAssumptionValues.current_grid_renewable_level,
    currentKwhEmissionFactorBaseValue: defaultImpactAssumptionValues.current_kwh_emissions_factor,
    advancedMode: false
  }),
  computed: {
    simpleCurrentKwhEmissionFactor() {      
      return ((1 - (this.getCurrentGrid/100)) * zeroRenewableKwhEmissionFactor).toPrecision(5)
    },
    getCurrentGrid() {
      return this.$store.state.assumptions[this.$store.state.assumptions.drawerOptions.type]['current_grid_renewable_level']
    },
    getCurrentKwhEmissionFactor() {
      return this.$store.state.assumptions[this.$store.state.assumptions.drawerOptions.type]['current_kwh_emissions_factor']
    },
    hasValueUpdated() {
      if (this.advancedMode) {
        return this.currentGridBaseValue != this.getCurrentGrid
      } else {
        return (this.getCurrentKwhEmissionFactor != this.currentKwhEmissionFactorBaseValue) || (this.currentGridBaseValue != this.getCurrentGrid)
      }
    }
  },
  watch: {
    advancedMode(value) {
      if (!value) {
        this.setCurrentKwhEmissionFactor(parseFloat(this.simpleCurrentKwhEmissionFactor))
      }
    }
  },
  mounted() {
    if (parseFloat(this.getCurrentKwhEmissionFactor) !== parseFloat(this.simpleCurrentKwhEmissionFactor)) {
      this.advancedMode = true
    }
  },
  methods: {
    precise(number) {
      return parseFloat(number).toPrecision(5)
    },
    onUpdateCurrentGrid($event) {      
      if (!this.advancedMode) {
        const currentKwhEmissionFactor = (1 - ($event/100)) * zeroRenewableKwhEmissionFactor
        this.setCurrentKwhEmissionFactor(this.precise(currentKwhEmissionFactor))        
      }
      this.setCurrentGridValue(parseFloat($event))
    },
    
    onUpdateCurrentKwhEmissionFactor($event) {
      this.setCurrentKwhEmissionFactor(parseFloat(this.precise($event)))
    },

    setCurrentGridValue(value) {
      this.$store.dispatch('assumptions/updateAssumptionsKeyValue', { key: 'current_grid_renewable_level', value })
    },
    setCurrentKwhEmissionFactor(value) {
      this.$store.dispatch('assumptions/updateAssumptionsKeyValue', { key: 'current_kwh_emissions_factor', value })
    }
  }
}
</script>
