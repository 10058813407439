import dayjs from 'dayjs'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
dayjs.extend(quarterOfYear)

export const checkDateIsInitOfQuarter = (date) => {
  return dayjs(date, 'MM/DD/YYYY').isSame(dayjs(date, 'MM/DD/YYYY').startOf('quarter'))
}

export const getNextQuarterFromToday = ({ format = 'MM/DD/YYYY' } = {}) => {
  if(checkDateIsInitOfQuarter(dayjs().format('MM/DD/YYYY'))) return dayjs().startOf('quarter').format('MM/DD/YYYY')
  return dayjs().add(1, 'quarter').startOf('quarter').format(format)
}

export const getQuartersInYear = (year = dayjs().year(), format = 'YYYY-MM-DD') => {
  const quarters = []
  const yearStartAt = dayjs().year(year).startOf('year').format(format)
  const yearEndAt = dayjs().year(year).endOf('year').format(format)
  for(let currentQuarter = yearStartAt; dayjs(currentQuarter).isBefore(dayjs(yearEndAt)); currentQuarter = dayjs(currentQuarter).add(1, 'quarter').format(format)) {
    quarters.push(currentQuarter)
  }
  return quarters
}

export const getQuartersBetweenDates = (startAt = dayjs().format('YYYY-MM-DD'), endAt = dayjs.add(1, 'year').format('YYYY-MM-DD'), format = 'YYYY-MM-DD') => {
  const quarters = []
  const yearStartAt = dayjs(startAt, format).format(format)
  const yearEndAt = dayjs(endAt, format).format(format)
  for(let currentQuarter = yearStartAt; dayjs(currentQuarter).isBefore(dayjs(yearEndAt)); currentQuarter = dayjs(currentQuarter).add(1, 'quarter').format(format)) {
    quarters.push(currentQuarter)
  }
  return quarters
}