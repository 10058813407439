<template>
  <tr :class="{ 'psui-bg-gray-10': !climate_zone_prefix }">
    <td>{{ climate_zone_prefix ? climate_zone_prefix : 'All' }}</td>
    <td 
      v-for="(year, year_index) in years"
      :key="`year_${year_index}`"
    >
      <div class="w-full flex flex-col">
        <div class="w-full flex justify-end items-center">
          <span>+</span>
          <span>{{ formatUnits( getYearAndClimateZoneBuildingEstimatesUnits(year, climate_zone_prefix)) }}</span>
          <span
            v-if="getYearAndClimateZoneBuildingEstimatesGrowthRate(
              getYearAndClimateZoneBuildingEstimatesUnits(year, climate_zone_prefix),
              year_index,
              climate_zone_prefix
            ) !== false"
            class="psui-text-green-70 psui-font-bold psui-text-xsmall psui-flex psui-items-center ml-2"
          >
            <PsIcon 
              icon="arrow_upward" 
              color="psui-text-green-70"
              icon-classes="psui-text-xsmall psui-leading-none"
              class="table-icon"
            />
            <span>
              {{ getYearAndClimateZoneBuildingEstimatesGrowthRate(
                getYearAndClimateZoneBuildingEstimatesUnits(year, climate_zone_prefix),
                year_index,
                climate_zone_prefix
              ) }}
            </span>
          </span>
        </div>
        <span
          v-if="getClimateZoneBuildingStocksUnits(climate_zone_prefix)"
          class="psui-text-gray-50 psui-text-xsmall"
        >{{ formatUnits( getAllUnitsByClimateZone(year_index, climate_zone_prefix) ) }}</span>
      </div>
    </td>
    <td>
      <div class="psui-w-full psui-flex psui-justify-end psui-items-center space-x-2">
        <span class="psui-text-gray-80">+{{ formatUnits( getTotalColumn(climate_zone_prefix).building_estimates ) }}</span>
        <span 
          v-if="getTotalColumn(climate_zone_prefix).growth_rate !== false"
          class="psui-text-green-70 psui-font-bold psui-text-xsmall psui-flex psui-items-center" 
        >
          <PsIcon 
            icon="arrow_upward" 
            color="psui-text-green-70"
            icon-classes="psui-text-xsmall psui-leading-none"
            class="table-icon"
          />
          <span>
            {{ getTotalColumn(climate_zone_prefix).growth_rate }}
          </span>
        </span>
      </div>
    </td>
  </tr>
</template>

<script>
import { BUILDING_ESTIMATES_BASE_YEARS } from '@/modules/app/assumptions/shared/enums'
import { MathHelpers } from '@igortrindade/lazyfy'

export default {
  name: 'TypePrototypeFutureTableRow',
  props: [
    'climate_zone_prefix',
    'type_prototype',
    'breakingDownByConstructionImplementationTypeSelected',
    'breakingDownByBuildingHeightSelected'
  ],
  data() {
    return {
      years: BUILDING_ESTIMATES_BASE_YEARS,
    }
  },
  methods: {
    
    getFilters(climate_zone_prefix) {
      const filters = { type_prototype_id: this.type_prototype.id }
      if(climate_zone_prefix) filters.climate_zone_prefix = climate_zone_prefix
      if(this.breakingDownByConstructionImplementationTypeSelected) filters.construction_implementation_type_id = this.breakingDownByConstructionImplementationTypeSelected.id
      if(this.breakingDownByBuildingHeightSelected) filters.building_height_id = this.breakingDownByBuildingHeightSelected.id
      return filters
    },

    getClimateZoneBuildingStocksUnits(climate_zone_prefix = null) {
      const filters = this.getFilters(climate_zone_prefix)
      return this.$store.getters['assumptions/buildingStocks/getterAllBuildingStocksUnits'](filters)
    },

    getYearAndClimateZoneBuildingEstimatesUnits(year, climate_zone_prefix = null) {
      const filters = this.getFilters(climate_zone_prefix)
      return this.$store.getters['assumptions/buildingEstimates/getterAllBuildingEstimateUnits']({ year, ...filters })
    },
    

    getYearAndClimateZoneBuildingEstimatesGrowthRate(buildingEstimateUnits, year_index, climate_zone_prefix) {
      const years = [ ...this.years ].splice(0, year_index)
      const building_stock_units = this.getClimateZoneBuildingStocksUnits(climate_zone_prefix)
      const aggregatedBuildingEstimateUnits = this.getYearAndClimateZoneBuildingEstimatesUnits(years, climate_zone_prefix) ? this.getYearAndClimateZoneBuildingEstimatesUnits(years, climate_zone_prefix) : 0
      if(!building_stock_units || !building_stock_units && year_index === 0) return false
      if(!MathHelpers.getPercentageOfAmount((building_stock_units + aggregatedBuildingEstimateUnits), buildingEstimateUnits, true)) return false
      return MathHelpers.getPercentageOfAmount((building_stock_units + aggregatedBuildingEstimateUnits), buildingEstimateUnits, true)
    },

    getAllUnitsByClimateZone(year_index, climate_zone_prefix) {
      const years = [ ...this.years ].splice(0, year_index+1)
      const building_stock_units = this.getClimateZoneBuildingStocksUnits(climate_zone_prefix)
      const aggregatedBuildingEstimateUnits = this.getYearAndClimateZoneBuildingEstimatesUnits(years, climate_zone_prefix)
      return building_stock_units + aggregatedBuildingEstimateUnits
    },

    getTotalColumn(climate_zone_prefix = null) {
      const filters = this.getFilters(climate_zone_prefix)
      const building_estimates = this.$store.getters['assumptions/buildingEstimates/getterAllBuildingEstimateUnits'](filters)
      const building_stock_units = this.getClimateZoneBuildingStocksUnits(climate_zone_prefix)
      const base_value = this.getAllUnitsByClimateZone(0, climate_zone_prefix)
      const growth_rate = building_stock_units ? MathHelpers.getPercentageOfAmount((base_value), building_estimates, true) : false
      
      return {
        building_estimates,
        growth_rate
      }
    }
  }

}
</script>

<style lang="scss" scoped>
    ::v-deep .table-icon {
      span {
        font-size: 12px !important;
      }
    }
</style>