import GraphQL from '@/util/GraphQL'
import { buildingEstimatesPrototypes } from '@/modules/app/assumptions/shared/BuildingEstimatesMockupData'
export default class BuildingEstimateApiService {
  
  static getJurisdictionUnits(jurisdiction_id) {
    const query = `
    {
      building_estimates (
        where: [
          { column: "jurisdiction_id", operation: "=", value: "${parseInt(jurisdiction_id)}" }      
        ]
      ) {
        jurisdiction_id
        climate_zone_prefix
        type_prototype_id
        year
        construction_implementation_type_id
        units
        building_height_id
        growth_rate
      }
    }
  `
  return GraphQL({ query, caller: 'BuildingEstimateApiService.getJurisdictionUnits' })
    .then(({ data }) => {
      return data.building_estimates
    })
  }

  static getBuildingEstimatesPrototypes() {
    return buildingEstimatesPrototypes
  }

}