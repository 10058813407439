<template>
  <div class="app-inputrangeslider">
    <div class="bar-wrapper">
      <input 
        type="range"
        class="slider"
        :min="min" 
        :max="max" 
        :value="value"
        :step="step"
        @input="$emit('update:value', Number($event.target.value))"
      >
      <div class="bar">
        <span
          class="fill"
          :style="{ width: `${valueScaledInPixels}px` }"
        />
        <span
          v-if="baseValue"
          class="basevalue"
          :style="{ left: `${baseValueScaledInPixels}px` }"
        />
      </div>
    </div>
    <div 
      v-if="baseValue !== 'undefined'" 
      v-tooltip="'Reset to Default'"
      class="reset-wrapper"
      @click="$emit('update:value', Number(baseValue))"
    >
      <svg
        width="36"
        height="40"
        fill="#CDCCCC"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M17.398 13.238H18c4.408 0 8 3.573 8 7.98 0 4.408-3.592 8-8 8-4.408 0-8-3.592-8-8 0-1.922.718-3.805 2-5.262a.916.916 0 011.282-.077c.368.33.407.912.077 1.281A6.178 6.178 0 0018 27.412c3.4 0 6.176-2.776 6.176-6.174 0-3.398-2.777-6.175-6.175-6.175h-.582l.912.913a.914.914 0 01-.64 1.553.914.914 0 01-.642-.272l-2.465-2.466a.914.914 0 01-.272-.64c0-.233.097-.466.271-.641l2.447-2.466a.914.914 0 011.282 0c.35.35.35.932 0 1.281l-.913.913zM20 21.218a2 2 0 11-4 0 2 2 0 014 0z"
          fill="inherit"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputRangeSlider',
  props: {
    min: {
      type: Number,
      default: 1,
    },    
    max: {
      type: Number,
      required: true,
    },
    value: {
      type: [Number, String],
    },
    baseValue: {
      type: [Number, Boolean]
    },
    step: {
      type: Number,
      default: 1
    }
  },
  computed: {
    rangeDistance() {
      return this.value - this.min
    },
    rangeDistanceMax() {
      return this.max - this.min
    },
    valueScaled() {
      return this.getValueToScale(this.rangeDistance)
    },
    baseValueScaled() {
      return this.getValueToScale(this.baseValue)
    },
    valueScaledInPixels() {
      const value = (164 * this.getValueToScale(this.rangeDistance) / 100 )
      if (value > 0 && value < 82) {
        return value + 4
      } else if (value > 0 && value > 82) {
        return value - 2
      } else {
        return value
      }
    },
    baseValueScaledInPixels() {
      return 164 * this.baseValueScaled / 100       
    }
  },
  methods: {
    getValueToScale(value) {
      return 100 * value / this.rangeDistanceMax
    }
  }
}
</script>

<style lang="scss" scoped>

$barHeight : 14px;
$sliderHeight : 21px;
$barBorderHeight : 5px;

.app-inputrangeslider {
  display: flex;
  align-items: center;
  padding-left: 20px;
  background-color: white;
  border-radius: $barBorderHeight;
}

.bar-wrapper {
  width: 164px;
  height: $barHeight;
  position: relative;
  flex-shrink: 0;
  margin-top: 9px;
  margin-bottom: 9px;
  margin-right: 20px;
  border-radius: $barBorderHeight;
}

.reset-wrapper {
  flex-shrink: 0;
  border-left: 2px solid #F7F7F7;
  height: 100%;  
  border-top-right-radius: $barBorderHeight;
  border-bottom-right-radius: $barBorderHeight;
  cursor: pointer;

  svg {
    margin-left: 6px;
    margin-right: 8px;
    transition: 300ms ease-in all;
  }

  &:hover {
    svg {
      fill: #4B8CA6;
    }
  }
}

/* The slider itself */
input {
  appearance: none;
  width: 100%; /* Full-width */
  height: $barHeight; /* Specified height */
  background-color: transparent;
  outline: none; /* Remove outline */  
  transition: opacity .2s;
  border-radius: $barBorderHeight;
  position: relative;
  z-index: 1;
  cursor: pointer;
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
// .slider::-webkit-slider-thumb, .slider::-moz-range-thumb {
.slider::-webkit-slider-thumb {
  cursor: pointer;
  appearance: none;
  width: $sliderHeight;
  height: $sliderHeight;
  background: #F7F7F7;
  border: 3px solid #82C2DD;
  border-radius: 30px;
  margin-top: 1px;
}

.bar {
  width: 100%;
  position: absolute;
  height: $barHeight;
  border-radius: $barBorderHeight;
  background: #E4EEF2;
  top: calc(#{$sliderHeight - $barHeight} - 1px);
  left: 0;
  z-index: 0;
  top: 3px;

  span.fill {
    position: absolute;
    height: $barHeight;
    border-radius: $barBorderHeight;
    background: #4B8CA6;
    top: 0;
    left: 0;
    z-index: 0;
  }

  span.basevalue {
    position: absolute;
    height: $barHeight;
    border-radius: $barBorderHeight;
    background-color: #82C2DD;
    top: 0;
    left: 0;
    z-index: 0;
    width: 2px;
  }
}

</style>