<template>
  <div class="w-full flex items-center justify-center h-screen py-6 overflow-y-auto block p-3">
    <div
      v-if="!showConfirmation"
      class="login-card-w my-auto"
    >
      <div class="apply-card bg-white flex flex-wrap justify-center">
        <h3 class="mb-6 w-full text-left text-pri text-lg font-semi-bold">
          Create an account
        </h3>
        
        <InputWithLabel
          :id="'user-email'"
          v-model="user.email"
          class="mt-6"
          :label="'Email'"
          :type="'Email'"
          :validation="validation.email"
          @blur="checkEmail"
        />
        <InputWithLabel
          v-model="user.password"
          class="mt-6"
          :type="'password'"
          :label="'Password'"
          :validation="validation.password"
        />

        <div class="w-full my-3">
          <p
            v-if="user.password.length >= 8"
            class="italic text-base text-gray03 flex items-center"
          >
            Your password must contain 8 characters <feather
              type="check"
              size="14"
            />
          </p>
          <p
            v-if="user.password.length < 8"
            class="italic text-base text-red flex items-center"
          >
            Your password must contain 8 characters <feather
              type="x"
              size="14"
            />
          </p>
        </div>

        <InputWithLabel
          v-model="user.password_confirmation"
          class=""
          :label="'Confirm'"
          :type="'password'"
        />
        <div
          class="w-full mt-3"
          :class="!user.password_confirmation ? 'invisible' : ''"
        >
          <p
            v-if="user.password == user.password_confirmation"
            class="italic text-base text-gray03 flex items-center"
          >
            Confirm your password <feather
              type="check"
              size="14"
            />
          </p>
          <p
            v-else
            class="italic text-base text-red flex items-center"
          >
            Confirm your password <feather
              type="x"
              size="14"
            />
          </p>
        </div>

        <div class="w-full text-left">
          <button
            class="my-4 button bg-sec text-white rounded-full uppercase"
            @click="registerUser()"
          >
            Register
          </button>
        </div>

        <p class="my-4 w-full or-separator text-gray04">
          <span>or</span>
        </p>

        <AppLoginGoogle class="w-full text-sm inline-block" />
      </div>

      <h4 class="w-full text-left text-gray mt-6 text-sm">
        You can also
        <router-link
          class="text-sec font-medium"
          :to="{ name: 'UserSignin', query: { email: user.email }}"
        >
          Sign in
        </router-link>
      </h4>
    </div>

    <div
      v-else
      class="login-card-w my-auto"
    >
      <div class="apply-card flex flex-wrap justify-center">
        <h3 class="mb-6 w-full text-center text-pri text-lg font-semi-bold">
          Check your email
        </h3>

        <div class="w-full flex justify-center items-center">
          <img
            src="/images/mailbox.svg"
            width="100px"
            alt=""
          >
        </div>

        <h4 class="text-gray04 text-center leading-2 mt-6">
          We've sent instructions in your email. If after a few minutes you don't receive anything, check you spam or try again.
        </h4>

        <div class="w-full flex flex-col justify-between items-center mt-6">
          <button class="button bg-sec text-white rounded-full uppercase">
            resend verification email
          </button>
          <router-link
            to="/"
            class="text-blue02 inline-block mt-4"
          >
            Update my email address
          </router-link>
          <router-link
            to="/"
            class="text-blue02 inline-block mt-4"
          >
            Go back to homepage
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppLoginGoogle from './AppLoginGoogle'
import User from '@/models/User'
export default {
  name: 'SignIn',
  components: { AppLoginGoogle },
  data() {
    return {
      showConfirmation: false,
      user: new User(),
      validation: {
        email: {
          hasError: false,
          label: ''
        },
        password: {
          hasError: false,
          label: ''
        },
        password_confirmation: {
          hasError: false,
          label: ''
        },
      }
    }
  },
  mounted() {
    if(this.$route.query.email) {
      this.user.email = this.$route.query.email
    }

    setTimeout(() => {
      document.getElementById('user-email').focus()
    }, 500)
  },
  methods: {

    async checkEmail() {
      if(!this.user.email) {
        this.validation.email.hasError = true
        this.validation.email.label = 'Please, fill your email'
        return
      }
      this.$api.get(`/users/checkEmail/${this.user.email}`)
      .then(({ data }) => {
        const { user_finded } = data

        if(user_finded) {
          this.$toast.error('Hmmm. We found an account with that email, please sign in!')
          this.validation.email.hasError = true
          this.validation.email.label = 'We found an account with that email, please sign in!'
        } else {
          this.validation.email.hasError = false
        }

      })

    },

    registerUser() {

      if(!this.user.email) {
        this.validation.email.hasError = true
        this.validation.email.label = 'Please, fill your email'
        return
      }

      if(!this.user.password || this.user.password.length < 7) {
        this.validation.password.hasError = true
        this.validation.password.label = 'Please, fill your password'
        return
      }

      if(this.user.password != this.user.password_confirmation) {
        this.validation.password_confirmation.hasError = true
        this.validation.password_confirmation.label = 'Confirmation doesnt match'
        return
      }

      this.$api.post(`/users`, this.user )
        .then((response) => {
          const {token} = response.data
          this.$store.commit('setLoggedUserToken', {type: 'app', token})
          this.$store.dispatch('getLoggedUser', {type: 'app'})
          this.$toast.success('Register succesfully!')
          this.$router.push({ name: 'UserAccount' })
        })
        .catch(() => {
          this.$toast.error('Error on sign up, check your data and try again')
        })
    }
  }
}
</script>