<template>
  <BaseModal 
    ref="baseModal"
    title="Construction Implementation Type"
    modal-dialog-classes="max-w-lg"
    @close="close"
  >
    <div class="w-full flex flex-col">
      <InputWithLabel
        v-model="construction_implementation_type.title"
        class="mt-6"
        label="Title"
      />

      <InputWithLabel
        v-model="construction_implementation_type.slug"
        class="mt-6"
        label="Slug"
      />

      <ContentEditor
        label="Content"
        class="mt-6"
        :content.sync="construction_implementation_type.content"
      />

      <div class="w-full flex mt-6">
        <PsButton
          size="medium"
          :label="construction_implementation_type.id ? `Update` : `Store`"
          @click="storeOrUpdate()"
        />
      </div>
    </div>
  </BaseModal>
</template>

<script>
import ConstructionImplementationType from '@/models/ConstructionImplementationType'
export default {
  name: "AdminConstructionImplementationTypeEditModal",
  data() {
    return {
      construction_implementation_type: new ConstructionImplementationType()
    }
  },
  mounted() {
    this.$eventBus.$on('openConstructionImplementationTypeModal', ({ construction_implementation_type_id = null } = {}) => {
      this.construction_implementation_type.id = construction_implementation_type_id
      this.$refs.baseModal.showModal = true
      if(construction_implementation_type_id) this.getConstructionImplementationType()
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('openConstructionImplementationTypeModal')
  },
  methods: {
    getConstructionImplementationType() {
      this.construction_implementation_type.show()
        .then(({ data }) => {
          this.construction_implementation_type = new ConstructionImplementationType(data.construction_implementation_type)
        })
    },

    storeOrUpdate() {
      this.construction_implementation_type.storeOrUpdate()
        .then(() => {
          this.$emit('getConstructionImplementationTypes')
          this.close()
        })
    },

    close() {
      this.construction_implementation_type = new ConstructionImplementationType()
      this.$refs.baseModal.showModal = false
    }
  }
}
</script>

<style>

</style>