<template>
  <div
    v-if="
      (isNotMainDomain && !isLocalHost && show && warningMessage) ||
        (isLocalHost && isProductionApi && show && warningMessage) ||
        showAlways
    " 
    id="footer-notes-by-domain" 
    :class="{ 'text-header-6' : isAdmin }"
    @click="show = false"
    v-html="warningMessage"
  />
</template>

<script>
export default {
  name: 'FooterNotesByDomain',
  data: () => ({
    show: true,
    showAlways : false
  }),
  computed: {
    isNotMainDomain() {
      return window.location.hostname !== "explorer.localenergycodes.com"
    },
    isTeamDomain() {
      return window.location.hostname === "explorer-production-team.vercel.app"
    },
    isQaDomain() {
      return window.location.hostname.includes('qa')
    },
    isLocalHost() {
      return window.location.hostname === 'localhost'
    },
    isAdmin() {
      return this.$route.path.includes('admin')
    },
    isProductionApi() {
      return process.env.VUE_APP_BASE_API_URL === "https://explorer-api.localenergycodes.com" ? true : false
    },
    warningMessage() {
      
      if (this.isProductionApi && this.isLocalHost) {
        return "Production database! Do not create/update data!"
      } else if (this.isNotMainDomain && this.isAdmin && this.isProductionApi) {
        return 'This admin reflects the production database, please use with caution!'
      } else {
        return ''
      }
    }
  },
  watch: {
    $route() {
      this.show = true
    }
  }
}
</script>

<style lang="scss">
  #footer-notes-by-domain {
    position: fixed;
    padding: 4px 16px;
    background-color: violet;
    z-index: 9999;
    top: 0;
    right: 0;
  }
</style>