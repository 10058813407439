<template>
  <PsAccordionItem
    title="Natural Gas Emissions Factor"
    :class="{ 'form-dirty' : hasValueUpdated }"
  >
    Grid supplied natural gas is responsible for
    <DropdownWithInputNumber
      :value="getValue"
      :min-value="0.001"
      :max-value="0.02"
      :step="0.000001"
      :base-value="baseValue"
      :button-label="`${getValue}`"
      @update:value="setValue"
    />
    metric tons of GHG emissions per therm.
  </PsAccordionItem>
</template>

<script>
import DropdownWithInputNumber from '@/components/general/DropdownWithInputNumber.vue'
import { defaultImpactAssumptionValues } from '@/models/ImpactAssumptions'

export default {
  name: 'AssumptionsCardPenetrationRate',
  components: { DropdownWithInputNumber },
  data: () => ({
    baseValue: defaultImpactAssumptionValues.current_therms_emissions_factor
  }),
  computed: {
    getValue() {
      return this.$store.state.assumptions[this.$store.state.assumptions.drawerOptions.type]['current_therms_emissions_factor']
    },
    hasValueUpdated() {
      return this.baseValue != this.getValue
    }
  },
  methods: {
    setValue(value) {
      this.$store.dispatch('assumptions/updateAssumptionsKeyValue', { key: 'current_therms_emissions_factor', value })
    }
  }
}
</script>
