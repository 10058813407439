<template>
  <div class="psui-w-full psui-h-full psui-rounded-md psui-bg-white psui-shadow-elevation-5 psui-p-8 psui-flex psui-flex-col psui-justify-between">
    <div class="psui-w-full psui-flex psui-flex-col psui-justify-between">
      <div class="psui-flex psui-items-start">
        <h4 class="psui-text-h5 psui-text-gray-80 psui-font-bold psui-mr-auto">
          {{getBuildingTypeSelected.slug === path.RESIDENTIAL_BUILDINGS.slug ? 'New Unit Forecasts' : 'New Construction Forecast'}} 
          
          <span class="psui-font-normal">{{getTitleByBuildingTypeSelected}}</span>
        </h4>
        <PsCardInfos
          v-if="getAnnualGrowthRate" 
          title="Annual growth rate"
          :subtitle="BUILDING_ESTIMATES_BASE_YEARS_TIMEFRAME"
          :total="getAnnualGrowthRate"
          @click="openAssumptionsDrawer()"
        />
        <PsCardInfos 
          class="psui-ml-2"
          :title="`Total ${getUnitOfMeasurementByBuildingTypeSelected}`"
          :subtitle="BUILDING_ESTIMATES_BASE_YEARS_TIMEFRAME"
          :total="formatUnits(getAllBuildingEstimatesUnits)"
          @click="openAssumptionsDrawer()"
        />
      </div>

      <div class="psui-block">
        <ApexChartGeneral 
          :options="getChartOptions()"
          :palette-color-index="paletteColorIndex"
        />
      </div>
      <div 
        v-if="getBuildingTypeSelected.slug === path.RESIDENTIAL_BUILDINGS.slug"
        class="w-full flex flex-row-reverse space-x-4 overflow-x-auto py-4"
      >
        <PsChartLegend
          v-for="(type_prototype, index) in getTypePrototypeThatHasUnits" 
          :key="`new_units_chart_type_prototype_${type_prototype.id}`"
          :text="type_prototype.title"
          :dot-color="{ backgroundColor: colors[index] }"
        />
      </div>
      <div
        v-else
        class="w-full flex justify-center"
      >
        <PsChartLegend
          text="Total"
          :dot-color="{backgroundColor :'#518BE2'}"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { BUILDING_ESTIMATES_BASE_YEARS, BUILDING_ESTIMATES_BASE_YEARS_TIMEFRAME, ASSUMPTIONS_PATHS } from '@/modules/app/assumptions/shared/enums'
import { colors } from '@/business-logic/constants/chartDefaultOptions'
import { MathHelpers } from '@igortrindade/lazyfy'
export default {
  name: 'FutureUnitsForecastChart',
  props: ['getClimateZoneSelected', 'getTypePrototypesWithUnits', 'paletteColorIndex', 'getBuildingTypeSelected'],
  data() {
    return {
      chart: null,
      chartIsLoading: false,
      BUILDING_ESTIMATES_BASE_YEARS,
      BUILDING_ESTIMATES_BASE_YEARS_TIMEFRAME,
      colors,
      path: ASSUMPTIONS_PATHS
    }
  },
  computed: {
    getTypePrototypeThatHasUnits() {
      return this.getTypePrototypesWithUnits.map((type_prototype) => {
        const building_estimates_units = this.$store.getters['assumptions/buildingEstimates/getterAllBuildingEstimateUnits']({
            type_prototype_id: type_prototype.id,
            climate_zone_prefix: this.getClimateZoneSelected.map(cz => cz.prefix)
          })
        return { ...type_prototype, building_estimates_units }
      })
      .filter((type_prototype) => type_prototype.building_estimates_units)
    },
    getChartSeries() {
      return this.getBuildingTypeSelected.slug === this.path.RESIDENTIAL_BUILDINGS.slug ? this.getTypePrototypeThatHasUnits.map((type_prototype, index) => {
        return {
          data: BUILDING_ESTIMATES_BASE_YEARS.map((year) => {
            const building_estimates_units = this.$store.getters['assumptions/buildingEstimates/getterAllBuildingEstimateUnits']({
              type_prototype_id: type_prototype.id,
              climate_zone_prefix: this.getClimateZoneSelected.map(cz => cz.prefix),
              year
            })
            return {
              x: year,
              y: building_estimates_units,
              fillColor: colors[index],
              strokeColor: colors[index],
              year,
              type_prototype,
              building_estimates_units,
              total_building_estimate_units: this.getAllBuildingEstimatesUnits,
              percentage: MathHelpers.getPercentageOfAmount(this.getAllBuildingEstimatesUnits, building_estimates_units)
            }
          })
        }
      }) : [{
          data: BUILDING_ESTIMATES_BASE_YEARS.map((year) => {
            const building_estimates_units = this.$store.getters['assumptions/buildingEstimates/getterAllBuildingEstimateUnits']({
              type_prototype_id: this.getTypePrototypeThatHasUnits.map(tp => tp.id),
              climate_zone_prefix: this.getClimateZoneSelected.map(cz => cz.prefix),
              year
            })

            return {
              x: year,
              y: building_estimates_units,
              fillColor: '#518BE2',
              strokeColor: '#518BE2',
              year,
              building_estimates_units,
              total_building_estimate_units: this.getAllBuildingEstimatesUnits,
              percentage: MathHelpers.getPercentageOfAmount(this.getAllBuildingEstimatesUnits, building_estimates_units)
            }
          })
        }]
      },

    getAllBuildingEstimatesUnits() {
      return this.$store.getters['assumptions/buildingEstimates/getterAllBuildingEstimateUnits']({
        climate_zone_prefix: this.getClimateZoneSelected.map(cz => cz.prefix),
        type_prototype_id: this.getAllTypePrototypeByBuildingTypeSelected,

      })
    },

    getAllBuildingStockUnits() {
      return this.$store.getters['assumptions/buildingStocks/getterAllBuildingStocksUnits']({
        climate_zone_prefix: this.getClimateZoneSelected.map(cz => cz.prefix),
        type_prototype_id: this.getAllTypePrototypeByBuildingTypeSelected 
      })
    },
    getAnnualGrowthRate() {
      const percentage = (this.getAllBuildingEstimatesUnits / 3) // 3 years code cycle
      return this.getAllBuildingStockUnits && MathHelpers.getPercentageOfAmount(this.getAllBuildingStockUnits, percentage, true)
    },
    getDrawerPath(){
      return this.$store.state.assumptions.drawerOptions.path
    },
    getAllTypePrototypeByBuildingTypeSelected(){
      if(!this.getBuildingTypeSelected) {
        return []
      } else {
        return this.$store
          .getters['globalEntities/TypePrototype/getterGlobalTypePrototypeFilteredByBuildingType'](this.getBuildingTypeSelected.id)
          .map(type_prototype => type_prototype.id)
      }
    },
    getTitleByBuildingTypeSelected(){
      return this.getBuildingTypeSelected.slug ===  this.path.RESIDENTIAL_BUILDINGS.slug ? "" : `(${this.getUnitOfMeasurementByBuildingTypeSelected})`
    },
    getUnitOfMeasurementByBuildingTypeSelected(){
      return this.getBuildingTypeSelected.slug ===  this.path.RESIDENTIAL_BUILDINGS.slug ? "" : "ft²"
    }
  },
  methods: {
    getChartOptions() {
      const self = this
      return {
        height: 320,
        series: this.getChartSeries, 
        chart: {
          type: 'bar',
        },
        xaxis: {
          labels: {
            formatter: function(value) {
              if(!BUILDING_ESTIMATES_BASE_YEARS.includes(value)) return ''
              return value
            }
          }
        },
        yaxis: {
          labels: {
            formatter: function(value) {
              return self.formatUnits(value)
            }
          },
        },
        tooltip: {
          custom: function ({ seriesIndex, dataPointIndex }) {
            const item = self.getChartSeries[seriesIndex].data[dataPointIndex]
            const unitsOrSqft = self.getBuildingTypeSelected.slug === self.path.RESIDENTIAL_BUILDINGS.slug ? 'Units' : 'ft²'
            return `
              <div class="rounded-sm psui-shadow-elevation-30 flex" style="background-color: ${ item.fillColor } !important;">
                <div class="w-full bg-white p-4 ml-2 flex flex-col space-y-2">
                  <h4 class="psui-text-gray-80 psui-text-xsmall font-bold">Year: ${ item.year }</h4>
                  <div class="w-full flex justify-between space-x-2">
                    <h2 class="psui-text-gray-80 psui-text-small" >${ self.formatUnits(item.building_estimates_units) } ${ unitsOrSqft }</h2>
                    ${
                      (!item.type_prototype || item.type_prototype.building_stock_units === 0) ? '' :
                      `
                        <div class="psui-bg-gray-30 vertical-divider block py-2"></div>
                        <h2 class="psui-text-gray-50 psui-text-small">${ self.formatNumber(MathHelpers.getPercentageOfAmount(item.type_prototype.building_stock_units, item.building_estimates_units)) }%</h2>
                      `
                    }
                  </div>
                  ${ self.getBuildingTypeSelected.slug !== self.path.RESIDENTIAL_BUILDINGS.slug ? '' : `<h4 class="psui-text-gray-40 psui-text-xsmall font-bold">${ item.type_prototype.title }</h4>` }
                </div>
              </div>
            `
          }
        }
      }
    },
    openAssumptionsDrawer() {
      this.$store.commit('assumptions/openDrawerAtBuildingEstimatesResidentialNew', this.getBuildingTypeSelected)
      // this.$store.commit('assumptions/updateDrawerIsEditingFromChart', true)
    }
  }
}
</script>

<style scoped>
    ::v-deep .apexcharts-tooltip.apexcharts-theme-light {
      border: none !important;
    }
</style>