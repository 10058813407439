<template>
  <div 
    v-tooltip="tooltip"
    class="app-dotnotification"
    :class="bg"
    :style="{ 
      borderRadius: `${borderRadius}px`,
      width: `${size}px`,
      height: `${size}px`,
    }"
  />  
</template>

<script>
export default {
  name: 'AppDotNotification',
  props: {
    'border-radius' : {
      type: Number,
      default: 4
    },
    size: {
      type: Number,
      default: 8
    },
    bg: {
      type: String,
      default: 'bg-lime-600'
    },
    tooltip: {
      type: [String, Object, Boolean],
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
  .app-dotnotification {
    display: block;
  }
</style>