<template>
  <ul class="dropdown-menu-list py-2">
    <li 
      v-for="item in getItems" 
      :key="getKeyValue(item)"
      :class="{ 'is-selected': getSelected === getKeyValue(item) }"
      class="text-header-2 cursor-pointer transition-all"
      @click="selectItem(item)"
    >
      {{ getKeyLabel(item) }}
    </li>
  </ul>  
</template>

<script>
export default {
  name: 'DropdownMenuList',
  props: {
    items: {
      type: Array,
      required: true
    },
    selected: {},
    keyLabel: {
      type: [String, Function],
      default: 'label'
    },
    keyValue: {
      type: [String, Function],
      default: 'value'
    }
  },
  computed: {
    getSelected() {
      if (this.selected) {
        if (typeof this.selected === 'object' && this.selected[this.keyValue] ) {
          return this.selected[this.keyValue]        
        } else {
          return this.selected
        }
      } else {
        return false
      }
    },
    getItems() {
      return this.items
    },
  },
  methods: {
    selectItem(item) {
      this.$emit('update:selected', this.getKeyValue(item) )
      this.$emit('change', item )
    },

    getKeyLabel(item) {
      if(typeof this.keyLabel == 'function') return this.keyLabel(item)
      if(typeof item === 'string') return item
      return item[this.keyLabel]
    },
    getKeyValue(item) {
      if(typeof this.keyValue == 'function') return this.keyValue(item)
      if(typeof item === 'string') return item
      return item[this.keyValue]
    },
  }
}
</script>

<style lang="scss" scoped>
  li {
    padding: 10px 24px;
    border-bottom: 1px solid #E4EEF2;
    color: #002A3A;
    &:last-of-type {
      border-bottom: 0;
    }
    &:hover, &.is-selected {
      color: #76848A;
    }
  }
</style>