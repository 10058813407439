
import PoliciesIndex from '@/modules/app/policy/shared/PoliciesIndex.vue'
import PoliciesList from '@/modules/app/policy/list/PoliciesList.vue'
import PolicyEditCustomCombination from '@/modules/app/policy/show/PolicyEditCustomCombination.vue'
import PoliciesShowIndex from '@/modules/app/policy/show/PoliciesShowIndex.vue'
import ComparisonShow from '@/modules/app/comparison/ComparisonShow.vue'

export default [
  {
    path: '/policies',
    component: PoliciesIndex,
    children: [
      { 
        path: '',
        name: 'PolicyList',
        component: PoliciesList,
      },
      { 
        name: 'ComparisonShow',
        path: 'comparison', 
        component: ComparisonShow,
      },
      { 
        name: 'PolicyShow',
        path: ':policy_id/:policy_active_tab?', 
        component: PoliciesShowIndex,
      },
      { 
        name: 'PolicyEditCustomCombination',
        path: 'custom-combination/:custom_combination_id/edit', 
        component: PolicyEditCustomCombination,
      },
    ]
  }
]