import PolicyApiService from '@/services/api/PolicyApiService'

export default {

  getUserPolicies({ commit }) {
    return PolicyApiService.getUserPolicies()
      .then((policies) => {
        commit('setUserPolicies', policies)
      })
  },

  getUserSharedPolicies({ commit }) {
    return PolicyApiService.getUserSharedPolicies()
    .then((shared_policies) => {
      commit('setUserSharedPolicies', shared_policies)
    })
  },

  getAllUserPolicies({ dispatch }) {
    return Promise.all([
      dispatch('getUserPolicies'),
      dispatch('getUserSharedPolicies')
    ])
  }
  
}