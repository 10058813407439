<template>
  <StudyResultsContentCard
    v-if="lastJurisdictionVisited && lastClimateZoneVisited"
    :expanded.sync="expanded"
    :prototype="prototype"
    :vintage="vintage"
    :fuel="false"
    :climate_zone="lastClimateZoneVisited"
    :jurisdiction="lastJurisdictionVisited"
    @study-data-update="onStudyDataUpdate($event)"
  >
    <template v-slot:header>
      <div class="w-full flex flex-col xl:flex-row xl:items-center">
        <div class="w-auto whitespace-no-wrap mr-4 flex items-center w-full">
          <div class="show-helper-on-hover flex items-center ">
            <h4
              class="text-deepsky-800 text-header-4 font-bold cursor-pointer"
              @click.stop="$eventBus.$emit('openDescriptionModal', { type: 'vintage', id: vintage.id })"
            >
              {{ vintage.title_long ? vintage.title_long : vintage.title }}
            </h4>
            <AppHelperButton @click.native="$eventBus.$emit('openDescriptionModal', { type: 'vintage', id: vintage.id })" />
          </div>
          <transition
            name="fade"
            mode="out-in"
          >
            <router-link 
              v-if="prototypeUnits && vintageUnits"
              v-tooltip="{ content: buildingStockTooltip }"
              :to="{ name: 'BuildingEstimates', params: { jurisdiction_slug } }"
              class="flex items-center ml-2 mr-auto cursor-pointer bg-ash-100 rounded pr-2"
            >
              <AppPieChart
                :value="vintageUnits / prototypeUnits * 100"
                :caption="vintageUnitsAsPercentageTxt"
                class="mr-4"
              />            
              <p class="text-header-1 text-ash-400 font-bold">
                {{ Number(vintageUnits).toLocaleString() }} Units
              </p>
            </router-link>
            <div
              v-else-if="buildingStockFilters && typeof vintageUnits === 'undefined'"
              class="ml-2 flex items-center"
            >
              <vue-skeleton-loader
                :width="24"
                :height="24"
                animation="fade"
                class="mr-2"
              />
              <vue-skeleton-loader
                :width="120"
                :height="18"
                animation="fade"
              />
            </div>
          </transition>
        </div>        
        <div
          v-if="expanded"
          class="w-full flex flex-wrap xl:justify-end py-4 xl:py-0"
        >
          <StudyResultsContentMeasuresDropdown
            v-model="dataFilterSelected"
            :options="getDataFilters"
            @forecastTableFilterChanged="toggleAllPossibleMeasures"
          />
          <div class="divider ml-3 mr-4 hidden xl:block" />
          <AppCardButton
            icon="columns"
            description="Add/Hide Columns"
            icon-class="text-deepsky-500"
            class="cursor-pointer"
            @click.native="$eventBus.$emit('openStudyResultsDrawerEditColumns', 'Existing Buildings')"
          />
        </div>
      </div>
    </template>

    <template v-slot:body="{ studyData }">
      <ForecastTable
        ref="forecastTable"
        :combined-measures-count.sync="combinedMeasuresCount"
        :study="study"
        :prototype="prototype"
        :building_stock_units="vintageUnits"
        :vintage="vintage"
        :study_data="studyData"
        :data-filter-selected.sync="dataFilterSelected"
        :climate_zone_raw="$route.params.climate_zone_raw"
        :expanded="expanded"
        class="mt-3"
      />
    </template>

    <template v-slot:footer>            
      <AppSeeMore 
        class="mr-auto"
        :expanded="expanded" 
        @click.native.stop="expanded = !expanded" 
      />
      <CustomCombinationToPolicyButton
        v-if="expanded"
        :measures-count="combinedMeasuresCount" 
        @click="saveToPolicy()"
      />
    </template>
  </StudyResultsContentCard>
</template>

<script>
import CustomCombinationToPolicyButton from './general/CustomCombinationToPolicyButton.vue'
import StudyResultsContentCard from './StudyResultsContentCard.vue'
import StudyResultsContentMeasuresDropdown from './MeasuresDropdown.vue'
import AppSeeMore from '@/components/general/AppSeeMore.vue'
import AppPieChart from '@/components/charts/AppPieChart.vue'
import AppHelperButton from '@/components/general/AppHelperButton.vue'
import ForecastTable from './table/ForecastTable'
import { mapGetters } from 'vuex'
import { convertToPercentageIfIsANumber } from '@/util/Helpers'

export default {
  name: 'StudyResultsContentVintage',
  components: { 
    ForecastTable, 
    StudyResultsContentMeasuresDropdown, 
    StudyResultsContentCard, 
    AppHelperButton, 
    AppPieChart, 
    AppSeeMore, 
    CustomCombinationToPolicyButton 
  },
  props: {
    prototype: {
      type: Object,
      required: true
    },
    vintage: {
      type: Object,
      required: true,
    },
    study: {
      type: Object,
      required: true
    },
  },  
  data() {
    return {
      dataFilterSelected: 'none',
      combinedMeasuresCount: 0,
      expanded: false,
    }
  },
  computed: {
    jurisdiction_slug() {
      if (this.$route.params.jurisdiction_slug) {
        return this.$route.params.jurisdiction_slug
      } else if (this.lastJurisdictionVisited && this.lastJurisdictionVisited?.slug) {
        return this.lastJurisdictionVisited.slug
      } else {
        return null
      }
    },
    prototypeUnits() {
      return this.$parent?.buildingStockUnits ?? false
    },    
    vintageUnits() {
      return this.isUpdating ? false : this.$store.getters['assumptions/buildingStocks/getterAllBuildingStocksUnits'](this.buildingStockFilters)
    },
    buildingStockFilters() {
      const jurisdiction_id = this.lastJurisdictionVisited?.id ?? null
      const climate_zone_prefix = this.$route?.params?.climate_zone_raw?.split('-')?.[0] ?? null
      const type_prototype_id = this.prototype.type_prototype_id
      const type_vintage_id = this.vintage.type_vintage_id
      return jurisdiction_id && climate_zone_prefix && type_prototype_id && type_vintage_id ? { jurisdiction_id, climate_zone_prefix, type_prototype_id, type_vintage_id } : null
    },
    getterJurisdictionClimatesZonesPrefixSelected() {
      return this.lastClimateZoneVisited?.prefix ?? ''
    },
    vintageUnitsAsPercentageTxt() {
      return convertToPercentageIfIsANumber({ value: this.vintageUnits, maxValue: this.prototypeUnits, maximumFractionDigitsIfHigherThanOne: 1 })
    },
    buildingStockTooltip() {
      if (this.lastJurisdictionVisited?.type === 'City') {
        return `${this.vintageUnitsAsPercentageTxt} of ${this.prototype.title.toLowerCase()} in ${this.lastJurisdictionVisited.title_type}, climate zone ${this.getterJurisdictionClimatesZonesPrefixSelected}, were ${this.vintage.title_long.toLowerCase()}. <br /><br /> Click for more details.`        
      } else {
        return `${this.vintageUnitsAsPercentageTxt} of ${this.prototype.title.toLowerCase()} in unincorporated ${this.lastJurisdictionVisited.title_type}, climate zone ${this.getterJurisdictionClimatesZonesPrefixSelected}, were ${this.vintage.title_long.toLowerCase()}. <br /><br /> Click for more details.`        
      }
    },
    getDataFilters() {
      const filtersAvailable = []
      const allData = { value: 'none', isSelected: true, label: `None` }
      filtersAvailable.push(allData)
      const combineAllPossible = { value: 'combine_all_possible', isSelected: false, label: `All Possible` }
      filtersAvailable.push(combineAllPossible)
      if(this.combinedMeasuresCount && this.dataFilterSelected != 'combine_all_possible') {
        const custom = { value: 'custom', isSelected: false, label: `Custom` }
        filtersAvailable.push(custom)
      }
      return filtersAvailable
    },
    ...mapGetters(['lastJurisdictionVisited', 'lastClimateZoneVisited']),
    ...mapGetters('assumptions', ['isUpdating']),
  },
  watch: {
    dataFilterSelected (cur, prev) {
      if (prev === 'none' && !this.expanded) {
        this.expanded = true
      }
    },
  },
  methods: {
    toggleAllPossibleMeasures({ combinedMeasureFilterSelected }) {
      this.$refs.forecastTable.toggleAllPossibleMeasures({ combinedMeasureFilterSelected })
    },
    goToBuildingEstimates() {
      this.$router.push({ name: 'BuildingEstimates' })
    },
    saveToPolicy() {
      const measuresIds = [...this.$refs.forecastTable.measureCombinations].map(studyData => studyData.measure_id )
      const eventName = 'openAddToPolicyModal'
      const eventPayload = {
        measuresIds, 
        study_id: this.study.id,
        prototype_id : this.prototype.id, 
        vintage_id: this.vintage.id 
      }
      this.$store.commit('general/setRedirectTo', { route: this.$route.path, event: { name: eventName, payload: eventPayload } })
      this.$eventBus.$emit(eventName, eventPayload)
    },    
    onStudyDataUpdate($event) {
      this.studyData = $event
    }
  },  
}
</script>

<style lang="scss" scoped>
  .divider {
    background-color: #E4EEF2;
    width: 1px;
  }
  ::v-deep {
    .forecast-table--cell {
      --cell-color: #E4EEF2;
      &.bg-combination-disabled {        
        --cell-color: #F0F0F0;
        .measure-combination-icon svg {
          fill: #CDCCCC;
        }
      }
      &.bg-not-effective {
        --cell-color: #fdf3f3;
        .measure-combination-icon svg {
          fill: #E3B6B6;
        }
      }
      &.bg-measure-added {
        --cell-color: #F6F9FB;
      }
    }
    .has-combination {
      .forecast-table--cell {
        &.bg-combination-disabled {
          background-color: #F0F0F0;
        }
        &.bg-not-effective {
          background-color: #fdf3f3;
        }
        &.bg-measure-added {
          background-color: #F6F9FB;
        }
      }
    }

  }
</style>