<template>
  <div class="w-full">
    <div class="column-selector-wrapper w-full flex justify-start">
      <h1 class="text-header-4 font-bold text-deepsky-800">
        Showing projections for 
        <Dropdown
          ref="measureDropdown"
          :button-classes="'px-2 bg-transparent w-full'"
          :dropdown-dialog-classes="'left-0 bg-white'"
        >
          <template v-slot:buttonLabel>            
            <span class="text-header-4 text-deepsky-500 border-b border-gray05 pb-1 flex items-center mt-2">
              {{ getColumnSelected.title }} ({{ formatStudyData(getColumnSelected.key, study_data[getColumnSelected.key], study_data) }})
              <feather
                class="ml-2"
                type="chevron-down"
                size="14"
              />
            </span>
          </template>
          <template v-slot:items>
            <div class="w-auto flex flex-wrap flex-col justify-start font-medium">
              <button
                v-for="(column, index) in getOrderedColumns"
                :key="index"
                class="text-header-3 w-auto text-left bg-transparent focus:outline-none border-b border-blue05 last:border-none py-2 px-5"
                :class="column.key == getColumnSelected.key ? 'text-gray04 cursor-default' : 'text-sec font-bold'"
                @click="setColumnSelected(column)"
              >
                {{ column.title }} ({{ formatStudyData(column.key, study_data[column.key], study_data) }})
              </button>
            </div>
          </template>
        </Dropdown>
      </h1>
      <div class="tabs-wrapper hidden">
        <button
          v-for="(column, index) in getOrderedColumns"
          :key="index"
          class="text-left bg-transparent focus:outline-none rounded-sm px-4 py-2 mr-3"
          :class="column.key == getColumnSelected.key ? 'is-selected border border-blue05 bg-blue05-50' : 'border border-white'"
          @click="setColumnSelected(column)"
        >
          <h3 class="tab-title">
            {{ column.title }}
          </h3>
          <h4 class="tab-description">
            {{ column.description }}
          </h4>
          <h3 class="tab-total mt-1">
            {{ formatStudyData(column.key, study_data[column.key], study_data) }}
          </h3>
        </button>
      </div>
    </div>
    <div
      v-if="getProjectionsByYear.length"
      class="w-full p-10 pl-0 overflow-hidden"
    >
      <ChartProjection 
        :projections-by-year="getProjectionsByYear" 
        :column-selected="getColumnSelected" 
        :columns="columns"
        :assumption="assumption"
      />
    </div>
  </div>
</template>

<script>
import ChartProjection from './ChartProjection'
import getProjectionsByYear from '@/business-logic/services/projections/getProjectionsByYear'
import {
  forecast_units,
  forecast_emissions_savings,
  forecast_kwh_savings,
  forecast_therms_savings,
  forecast_lifecycle_savings,
  forecast_initial_cost
} from '@/modules/app/jurisdiction/study-results/vuex/columns'
import { find } from '@/util/Functions'
export default {
  name: 'CardProjection',
  components: { ChartProjection },
  props: {
    study_data: {
      required: true
    },
    building_stock_units: {
      required: true,
    },
    assumption: {
      required: true
    },
    columnSelectedKey: {
      default: false
    },
    firstSelectedColumnKey: {
      default: 'forecast_units_affected'
    }
  },
  data() {
    return {
      subtitle: '',
      projectionsByYear: [],
      dataInitialized: false,
      columns: [
        forecast_initial_cost(),
        forecast_emissions_savings(),
        forecast_units(),
        forecast_lifecycle_savings(),
        forecast_kwh_savings(),
        forecast_therms_savings(),
      ],
      editAssumptions: false,
    }
  },
  computed: {
    getColumnSelected() {
      const columnSelected = find(this.columns, { key: this.columnSelectedKey })
      if(columnSelected) return columnSelected
      return this.columns[1]
    },
    getOrderedColumns() {
      return this.columns.map((item) => item).sort((item) => (item.key == this.firstSelectedColumnKey) ? -1 : 1)
    },
    getProjectionsByYear() {
      return getProjectionsByYear({ 
        assumption: this.assumption,
        building_stock_units: this.building_stock_units,
        kwh_savings: this.study_data.kwh_savings,
        therms_savings: this.study_data.therms_savings,
        annual_bill_savings: this.study_data.annual_bill_savings,
        initial_cost: this.study_data.initial_cost
      })
    }
  },
  methods: {
    setColumnSelected(column) {
      this.$emit('update:columnSelectedKey', column.key)
    }
  }
}
</script>

<style scoped lang="scss">
  .column-selector-wrapper {
    @media screen and (min-width: 1600px) {
      h1 {
        display: none;
      }
      .tabs-wrapper {
        display: block;
      }
    }
  }

  button {
    transition: 300ms ease-in-out all;
    color: #979797;
    position: relative;
    &.is-selected .tab-title {
      color: #4B8CA6;
      &::before {
        content: "";
        position: absolute;
        left: 8px;
        top: 10px;
        background: #4B8CA6;
        width: 4px;
        height: 12px;
        display: inline-block;
        margin-right: 5px;
      }
    }
  }

  .tab-title {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 120%;
    color: #76848A;
  }
  
  .tab-description {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 120%;
    color: #76848A;
  }
  
  .tab-total {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #64666D;
  }

</style>