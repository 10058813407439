<template>
  <div>
    <InputDropdownSearch      
      :label="label"
      :placeholder="getPlaceholder"
      :data-handler="handleRequestSearch"
      :item="jurisdiction"
      :input-class="inputClass"
      :focus-on-mount="focusOnMount"
      obj-key="title_type"
      @update:item="setJurisdictionSelected"
      @focus="isFocused = true"
      @blur="isFocused = false"
    />
  </div>
</template>

<script>
import InputDropdownSearch from './InputDropdownSearch.vue'
import Jurisdiction from '@/models/Jurisdiction'

export default {
  name: 'JurisdictionInput',
  components: { InputDropdownSearch },
  props: {
    jurisdiction: {
      type: [Boolean, Object],
      required: true
    },
    focusOnMount: {
      default: false
    },
    inputClass: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: 'City/County'
    },
    placeholder: {
      type: String,
      default: 'Type a City or County'
    },
  },
  data() {
    return {
      items: [],
      isFocused: false
    }
  },
  computed: {
    getPlaceholder() {
      if (this.jurisdictionAsText && !this.isFocused) {
        return this.jurisdictionAsText        
      } else if (this.isFocused) {
        return this.placeholder
      } else {
        return ''
      }
    },
    jurisdictionAsText() {
      return this.jurisdiction ? this.jurisdiction.title_type : false
    },
  },
  methods: {

    async handleRequestSearch({ search, cancelToken }) {

      const query = `
        {
          jurisdictions (
            where: [
              { column: "title", operation: "ILIKE", value: "${search}%"}
            ]
            paginate: { offset: 0, limit: 30 }
            orderBy: [{ column: "title" }]
          ){
            id
            title
            type
            title_type
            county
            slug
            climate_zones {
              id
              raw
              prefix
            }
          }
        }
      `
      const { data } = await this.$graphql({ query, config: { cancelToken: cancelToken.token }})
      const items = data.jurisdictions.map((jurisdiction) => new Jurisdiction(jurisdiction))

      return items
    },

    setJurisdictionSelected(jurisdiction) {
      jurisdiction.climate_zones = jurisdiction.climate_zones.map((item) => { return { ...item, isSelected: false }})
      this.$emit('update:jurisdiction', jurisdiction)
    }
    
  }
}
</script>