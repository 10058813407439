<template>
  <div class="w-full p-6">
    <div class="w-full flex justify-end">
      <router-link
        :to="{ name: 'AdminClimateZoneCreate' }"
      >
        <PsButton
          label="Create"
          size="big"
          icon="add"
        />
      </router-link>
    </div>

    <InputWithLabel
      v-model="search"
      class="mt-6"
      :label="'Search climate zones'"
      @keydown="getClimateZones"
    />

    <table class="table table-bordered mt-6 whitespace-no-wrap">
      <thead>
        <tr>
          <th>ID</th>
          <th>Prefix</th>
          <th>Suffix</th>
          <th>Raw</th>
          <th>Fallback</th>
          <th class="psui-w-48">
            Edit
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(climate_zone, climate_zone_index) in climate_zones"
          :key="climate_zone.raw"
          class="cursor-move"
          draggable="true"
          @dragstart="dragStart(climate_zone_index)"
          @dragover.prevent
          @dragend="dragEnd"
          @drop="dragFinish(climate_zone_index, climate_zones)"
        >          
          <td>{{ climate_zone.id }}</td>
          <td>{{ climate_zone.prefix }}</td>
          <td>{{ climate_zone.suffix }}</td>
          <td>{{ climate_zone.raw }}</td>
          <td><span v-if="climate_zone.fallback_climate_zone">{{ climate_zone.fallback_climate_zone.raw }}</span></td>
          <td class="psui-flex psui-space-x-2">
            <router-link 
              :to="{ name: 'AdminClimateZoneEdit', params: { climate_zone_id: climate_zone.id}}" 
            >
              <PsButton 
                label="Edit"
                size="medium" 
                icon="edit"
              />
            </router-link>
            <PsButton 
              layout="caution" 
              label="Delete"
              size="medium"
              icon="delete"
              @click="selectClimateZoneToDelete(climate_zone)"
            />
          </td>
        </tr>
      </tbody>
    </table>

    <BaseModal
      ref="deleteClimateZoneModal"
      modal-dialog-classes="w-full max-w-sm"
      title="Attention"
    >
      <div
        v-if="climate_zone_selected"
        class="w-full flex flex-col items-start mt-6"
      >
        <p class="text-base text-gray04">
          Delete Climate Zone <span class="text-red font-bold">{{ climate_zone_selected.raw }}</span>?
        </p>
        <PsButton
          layout="caution"
          class="psui-mt-6"
          label="Confirm delete"
          size="big"
          @click="deleteClimateZone(climate_zone_selected.id)" 
        />
      </div>
    </BaseModal>

    <Paginator
      v-model="offset"
      :length="climate_zones.length"
      :limit="limit"
      @getItems="getClimateZones({ offset })"
    />
  </div>
</template>

<script>
export default {
  name: 'AdminHelperList',
  data() {
    return {
      search: '',
      climate_zones: [],
      climate_zone_selected: null,
      offset: 0,
      limit: 50,
    }
  },
  computed: {
    getTableToOrdenateItems() {
      return `climate_zones`
    }
  },
  mounted() {
    this.getClimateZones()
  },
  methods: {
    async getClimateZones({ offset = 0 } = {}) {
      this.offset = offset
      const query = `
        {
          climate_zones(
            where: [{column: "raw", operation: "ILIKE", value: "%${this.search}%"}], 
            orderBy: [{column: "id", order: "asc"}], 
            paginate: { offset: ${this.offset}, limit: ${this.limit}}
          ) {
            id
            prefix
            suffix
            raw
            fallback_climate_zone {
              id
              raw
            }
          }
        }
      `
      const { data } = await this.$graphql({ query })

      this.climate_zones = data.climate_zones
    },

      selectClimateZoneToDelete(climate_zone) {
      this.climate_zone_selected = climate_zone
      this.$refs.deleteClimateZoneModal.showModal = true
    },

    deleteClimateZone(climate_zone_selected_id) {
      this.$api.delete(`/climate_zone/${climate_zone_selected_id}`)
        .then(({ data }) => {
          this.$toast.success(data.feedback)
          this.$refs.deleteClimateZoneModal.showModal = false
          this.climate_zone_selected = null
          this.getClimateZones()

        })
        .catch((err) => {
          this.$toast.error(err.response.data.feedback)
        })
    }
  }
}
</script>