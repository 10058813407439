import CustomBuildingEstimateApiService from "@/services/api/CustomBuildingEstimateApiService"
import { initClassData } from '@/util/Functions'
export default class CustomBuildingEstimate extends CustomBuildingEstimateApiService {
  
  static get fillable() {
    return [
      { key: 'id', default: null },
      { key: 'policy_id', default: null },
      { key: 'jurisdiction_id', default: null },
      { key: 'type_prototype_id', default: null },
      { key: 'climate_zone_prefix', default: null },
      { key: 'year', default: null },
      { key: 'construction_implementation_type_id', default: null },
      { key: 'building_height_id', default: null },
      { key: 'units', default: 0 },
    ]
  }

  static get filtrableKeys() {
    return [
      'policy_id', 
      'jurisdiction_id', 
      'type_prototype_id', 
      'climate_zone_prefix', 
      'year',
      'construction_implementation_type_id',
      'building_height_id',
    ]
  }

  constructor(data) {
    super()
    initClassData(CustomBuildingEstimate.fillable, this, data)
  }
  
}