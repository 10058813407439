/* eslint-disable no-unused-vars */
import { jsPDF } from "jspdf"
import { registerLatoBold } from '@/assets/fonts/Lato-Bold-normal.js'
import { registerLatoRegular } from  '@/assets/fonts/Lato-Regular-normal.js'
import { registerLatoLight } from  '@/assets/fonts/Lato-Light-normal.js'
import { scaleDimensions, getBaseUrlImageDimensions } from '@/util/Helpers'
import imageLoader from '@policystudio/policy-studio-ui-vue/src/util/imageLoader'

export default class BasePdfModel {

  colors = {
    blue02 : '#4B8CA6',
    blue05 : '#E4EEF2',
    gray01 : '#151C22',
    gray02 : '#383B44',
    gray03 : '#76848A',
    gray04 : '#64666D'
  }

  margins = { 
    top: 45, 
    right: 20, 
    left: 20, 
    bottom: 35 
  }

  lastTablePage = null
  debug = false

  jsPDFOptions = {
    orientation: 'l',
    unit: 'px',
    // format: [ this.pageWidth, this.pageHeight ]
    format: 'a4'
  }

  constructor(args) {
    this.init(args)
  }

  async init(args) {
        
    this.doc = args?.doc ?? new jsPDF(this.jsPDFOptions)
    await this.registerProperties(args)
    await this.registerCustomFonts()    
  }

  registerProperties({ title, subtitle, sourceUrl, headline, sources, debug }) {    
        
    if (debug) this.debug = debug
    if (title) this.title = title
    if (subtitle) this.subtitle = subtitle
    if (sources) this.sources = sources
    
    this.sourceUrl = sourceUrl ?? window.location.href
    this.headline = headline ?? 'Cost-Effectiveness Results Summary'

    this.pageWidth = this.doc.internal.pageSize.getWidth()
    this.pageHeight = this.doc.internal.pageSize.getHeight()

    this.totalPagesExp = '{total_pages_count_string}'
  }

  registerCustomFonts() {
    registerLatoBold(this.doc)
    registerLatoRegular(this.doc)
    registerLatoLight(this.doc)
    this.doc.setFont('Lato-Regular')
  }

  async addPage({ internal = false } = {}) {    
    this.doc.addPage()

    if (internal) {
      await this.addPageHeader()
      await this.addPageFooter()
    }
    
  }

  async addImageAsPage( { image, internal }) {
  
    const { width : printableWidth, height: printableHeight } = this.getThePrintableArea()    
    const { width, height } = scaleDimensions({ width: image.width, height: image.height, maxWidth: printableWidth, maxHeight: printableHeight})
    
    const imageXPosition = (this.pageWidth - this.getHorizontalMargins() - width) / 2
    await this.doc.addImage(image, imageXPosition, this.margins.top, width, height)
  
    await this.addPageGrid()
    await this.addContentAreas()

  }

  async addImagesAsPages({ images }) {
    for (let index = 0; index < images.length; index++) {
      const image = images[index]
      await this.addBase64ImageAsPage({ image })
      if (index !== (images.length - 1)) {
        await this.addPage()
      }
    }
  }

  async addBase64ImageAsPage( { image, internal, alignX = true, alignY = true }) {

    const { width : printableWidth, height: printableHeight } = this.getThePrintableArea()
    const { width: imgWidth, height: imgHeight } = await getBaseUrlImageDimensions(image)

    const { width, height } = scaleDimensions({ width: imgWidth, height: imgHeight, maxWidth: printableWidth, maxHeight: printableHeight})

    let imageXPosition = this.margins.left
    let imageYPosition = this.margins.top
    if (alignX) {
      imageXPosition = ((printableWidth - width) / 2) + this.margins.left
    }
    if (alignY) {
      imageYPosition = ((printableHeight - height) / 2) + this.margins.top
    }

    await this.doc.addImage(image, imageXPosition, imageYPosition, width, height)

    await this.addPageGrid()
    await this.addContentAreas()
  }

  async addPageGrid( { column = 20, row = 20 } = {}) {
    
    if (this.debug == false) return 

    const maxWidth = this.pageWidth
    
    this.doc.setFontSize(10)
    this.doc.setDrawColor('gray')
    this.doc.setLineWidth(.3)
    
    let xPosition = 0
    while (xPosition <= maxWidth ) {      
      xPosition += column
      this.doc.line(xPosition, 0, xPosition, this.pageHeight)
      this.doc.text(`${xPosition}`, xPosition + 1 , 3, { baseline : 'top' })
    }

    let yPosition = 0
    while (yPosition <= maxWidth ) {      
      yPosition += row
      this.doc.line(0, yPosition, this.pageWidth, yPosition)
      this.doc.text(`${yPosition}`, 3 , yPosition + 1, { baseline : 'top' })
    }        

  }

  async addBackgroundCover() {
    const pdfCoverBackgroundBase64 = await imageLoader({ imageUrl: `/images/pdf_cover_background_2022.png`, returnsBase64: true })
    const { width: imgWidth, height: imgHeight } = await getBaseUrlImageDimensions(pdfCoverBackgroundBase64)
    const { width, height } = await scaleDimensions({ width: imgWidth, height: imgHeight, maxWidth: this.pageWidth, maxHeight: this.pageHeight})    
    await this.doc.addImage(pdfCoverBackgroundBase64, 0, 0, width, height)
  }

  async addCoverPage() {

    this.addPage()
    const { pageNumber } = this.doc.internal.getCurrentPageInfo()
    this.doc.movePage(pageNumber, 1)

    await this.addBackgroundCover()

    const yStartPosition = 180
    const xStartPosition = 37

    this.doc.setDrawColor(this.colors.blue02)
    this.doc.setLineWidth(2.5)
    this.doc.line(xStartPosition, yStartPosition - 2, xStartPosition, yStartPosition + 90) 

    this.doc.setFont('Lato-Bold')
    this.doc.setTextColor(this.colors.blue02)
    this.doc.setFontSize(21)
    this.doc.text(this.headline, xStartPosition + 12, yStartPosition, { baseline : 'top' })

    this.doc.setFont('Lato-Regular')
    this.doc.setTextColor(this.colors.gray02)
    this.doc.setFontSize(36)
    this.doc.text(this.title, xStartPosition + 12, yStartPosition + 28 , { baseline : 'top' })
    
    this.doc.setFontSize(32)
    this.doc.setFont('Lato-Light')
    this.doc.setTextColor(this.colors.gray02)
    this.doc.text(this.subtitle, xStartPosition + 12, yStartPosition + 66, { baseline : 'top' })
    
        
    this.addPageGrid()
  }

  async addLastPage() { 

    await this.addPage()
    await this.addBackgroundCover()
    await this.addSourceNotes()
    await this.addPageGrid()
    
  }

  addSourceNotes({ yStartPosition = 90, xStartPosition = 37 , gap = 12, sourceHeight = 30} = { }) {
    
    const sourcesLength = this.sources?.length ?? 1
    const sourcesHeight = sourcesLength * sourceHeight
    const sourceTitleHeight = 20
    let blockHeight = gap + sourcesHeight
    
    this.doc.setFont('Lato-Bold')
    this.doc.setTextColor(this.colors.blue02)
    this.doc.setFontSize(21)
    this.doc.text('Sources', xStartPosition + gap , yStartPosition + gap, { baseline : 'middle' })
    blockHeight += 20

    this.doc.setFontSize(12)
    this.doc.setTextColor(this.colors.gray02)        

    const sourceTextXStartPosition = xStartPosition + gap

    if (this.sources) {
      const sourceTextYStartPosition = yStartPosition + gap + sourceTitleHeight + gap
      this.sources.map( (link, index ) => {
        this.doc.setFont('Lato-Regular')
        this.doc.textWithLink( link.title, sourceTextXStartPosition, sourceTextYStartPosition + ( sourceHeight * index ), { url:  link.url } )
        
        this.doc.setFont('Lato-Light')
        this.doc.text( link.subtitle , sourceTextXStartPosition, sourceTextYStartPosition + gap + ( sourceHeight * index ) )
      })
    }    

    const notesTopMargin = 35
    const notesYStart = yStartPosition + blockHeight + notesTopMargin
    
    this.doc.textWithLink( `This document has been generated from ${this.sourceUrl}`, sourceTextXStartPosition, notesYStart, { url:  this.sourceUrl } )
    this.doc.textWithLink( 'Find more reach code resources at localenergycodes.com', sourceTextXStartPosition, notesYStart + 15, { url:  'https://localenergycodes.com' } )

    // Draw the Blue Vertical line
    this.doc.setDrawColor(this.colors.blue02)
    this.doc.setLineWidth(2.5)
    this.doc.line(xStartPosition, yStartPosition, xStartPosition, yStartPosition + blockHeight )

  } 

  async addPageHeader() {
    
    const logoExplorerBase64 = await imageLoader({ imageUrl: `/images/logo_explorer.png`, returnsBase64: true })
    const { width, height } = await getBaseUrlImageDimensions(logoExplorerBase64)
    const { width: logoWidth, height: logoHeight } = await scaleDimensions({ width, height, maxWidth: this.pageWidth, maxHeight : this.margins.top * 0.5 })

    const logoYPosition = (this.margins.top - logoHeight) / 2
    await this.doc.addImage(logoExplorerBase64, this.margins.left, logoYPosition, logoWidth, logoHeight, 'logoExplorer')    

    this.doc.setDrawColor('#DBE8ED')
    this.doc.setLineWidth(.6)
    
    const marginGap = 10
    const logoBorderLineXPosition = this.margins.left + logoWidth + marginGap
    const logoBorderYPositionStart = logoYPosition
    const logoBorderYPositionEnd = logoYPosition + logoHeight
    this.doc.line(logoBorderLineXPosition, logoBorderYPositionStart, logoBorderLineXPosition, logoBorderYPositionEnd) 
    

    const captionsXPosition = logoBorderLineXPosition + marginGap
    const captionsYPositionStart = 20
    
    this.doc.setFont('Lato-Bold')
    this.doc.setTextColor(this.colors.gray03)
    this.doc.setFontSize(10)
    this.doc.text(this.headline, captionsXPosition, captionsYPositionStart, { baseline : 'bottom' })

    this.doc.setFont('Lato-Regular')
    this.doc.setTextColor(this.colors.gray02)
    this.doc.setFontSize(12)
    this.doc.text(`${this.title} - ${this.subtitle}`, captionsXPosition, captionsYPositionStart + 12, { baseline : 'bottom' })
    
    this.doc.setDrawColor(this.colors.blue05)
    this.doc.setLineWidth(.5)
    this.doc.line(this.margins.left, this.margins.top, this.pageWidth - this.margins.right, this.margins.top)
    
    
  }
  
  addPageFooter() {        

    this.doc.setDrawColor(this.colors.blue05)
    this.doc.setLineWidth(.5)

    const pageSize = this.doc.internal.pageSize
    const xStartPos = this.margins.left
    const xFinalPos = pageSize.getWidth() - this.margins.right
    const yPos = pageSize.getHeight() - this.margins.bottom + 10

    this.doc.line(xStartPos, yPos, xFinalPos, yPos) 
    
    this.doc.setFont('Lato-Bold')
    this.doc.setTextColor(this.colors.blue02)
    this.doc.setFontSize(8)
    this.doc.textWithLink( 
        'EXPLORER.LOCALENERGYCODES.COM', 
        xFinalPos - 195,
        yPos + 10 , 
        { 
          url: 'https://explorer.localenergycodes.com', 
          baseline : 'top', 
          // align: 'right', 
          charSpace: 3 
        } 
      )

  }

  async addPageCount(currentPage, totalPages) {

    this.doc.setFont('Lato-Regular')
    this.doc.setFontSize(11)
    this.doc.setTextColor(this.colors.gray04)
    
    const xPos = this.pageWidth - this.margins.right - 50
    const yPos = this.margins.top / 2

    await this.doc.text(`Page ${currentPage} of ${totalPages}`, xPos, yPos, { baseline: 'middle' } )

  }

  async addInternalPagesPattern() {

    const pages = this.doc.internal.getNumberOfPages()
    for (let j = 1; j < pages + 1 ; j++) {      
      if (j === 1 || j === pages) continue
      this.doc.setPage(j)
      await this.addPageCount(j - 1, pages - 2)
      await this.addPageHeader()
      await this.addPageFooter()
    }

  }  

  async preExport() {
    await this.addCoverPage()
    await this.addLastPage()
    await this.addInternalPagesPattern()
  }

  async export() {    
    await this.preExport()
    const fileName = this.slugify(`cost-effectiveness-explorer-${this.headline}-${this.title}-${this.subtitle}`)

    if (this.debug) {
      this.doc.output('dataurlnewwindow')      
    } else {
      this.doc.save(fileName)
    }
  }

  getThePrintableArea() {
    const pageWidth = this.doc.internal.pageSize.getWidth()
    const pageHeight = this.doc.internal.pageSize.getHeight()

    return {
      width: pageWidth - this.getHorizontalMargins(),
      height: pageHeight - this.getVerticalMargins()
    }
  }

  getHorizontalMargins() {
    return (this.margins.left + this.margins.right)
  }
  
  getVerticalMargins() {
    return (this.margins.top + this.margins.bottom)
  }

  addContentAreas() {
    
    if (this.debug == false) return 
    
    const pageWidth = this.doc.internal.pageSize.getWidth()
    const pageHeight = this.doc.internal.pageSize.getHeight()

    const { width : printableWidth, height: printableHeight } = this.getThePrintableArea()
    this.doc.setLineWidth(1)
    
    // Printable Area
    this.doc.setDrawColor(255,0,0)
    this.doc.rect(this.margins.left, this.margins.top, printableWidth, printableHeight )
    
    // Header Area
    this.doc.setDrawColor(0,255,0)
    this.doc.rect(this.margins.left, 0, printableWidth, this.margins.top )
    
    // FooterArea
    this.doc.setDrawColor(0,0,255)
    this.doc.rect(this.margins.left, pageHeight - this.margins.bottom, printableWidth, this.margins.bottom )
    

  }

  slugify(text) {
    return text
      .toString()                     // Cast to string
      .toLowerCase()                  // Convert the string to lowercase letters
      .normalize('NFD')       // The normalize() method returns the Unicode Normalization Form of a given string.
      .trim()                         // Remove whitespace from both sides of a string
      .replace(/\s+/g, '-')           // Replace spaces with -
      .replace(/[^\w-]+/g, '')       // Remove all non-word chars
      .replace(/-+/g, '-')        // Replace multiple - with single -
  }

}
