<template>
  <div class="psui-divide-y psui-divide-blue-20">
    <div 
      v-for="type_prototype in type_prototypes"
      :key="`type-prototype-assumption-${type_prototype.id}`"
      class="py-7"
    >
      <h2 
        class="font-bold psui-text-gray-80 mb-3" 
        @click="toggleBreakdown('breakdownByConstructionImplementationType', type_prototype.id, getConstructionImplementationTypes )"
      >
        {{ type_prototype.title }}
      </h2>

      <!-- CONSTRUCTION IMPLEMENTATION TYPE -->
      <div class="mt-4">
        <div class="breakdown-switch psui-flex psui-justify-between psui-items-center psui-text-gray-60 psui-bg-gray-10">
          <span class="psui-text-small">Break-down by construction type</span>
          <PsSwitch
            :value="checkSwitch('breakdownByConstructionImplementationType', type_prototype.id)"
            size="small"
            label="Breakdown by construction types"
            @click.native.prevent="toggleBreakdown('breakdownByConstructionImplementationType', type_prototype.id, getConstructionImplementationTypes )"
          />
        </div>
        <PsToggle 
          v-if="breakdownByConstructionImplementationType[type_prototype.id]"
          :items="getConstructionImplementationTypes"
          key-label="title"
          key-value="id"
          :selected="getConstructionImplementationTypes.findInArray({id: getSelectedConstructionType(type_prototype.id) })"
          @change="toggleBreakdownItem('breakdownByConstructionImplementationType', type_prototype.id, $event)"
        />
      </div>

      <!-- BUILDING HEIGHT -->
      <div
        v-if="type_prototype.slug === 'multifamily-units'"
        class="mt-4"
      >
        <div
          class="breakdown-switch psui-flex psui-justify-between psui-items-center psui-text-gray-60 psui-bg-gray-10"
        >
          <span class="psui-text-small">Break-down by building height</span>
          <PsSwitch
            :value="checkSwitch('breakdownByBuildingHeight', type_prototype.id)"
            size="small"
            label="Breakdown by construction types"
            @click.native.prevent="toggleBreakdown('breakdownByBuildingHeight', type_prototype.id, getBuildingHeights )"
          />
        </div>
        <PsToggle 
          v-if="breakdownByBuildingHeight[type_prototype.id]"
          :items="getBuildingHeights"
          key-label="title"
          key-value="id"
          :selected="getBuildingHeights.findInArray({id: getSelectedBuildingHeight(type_prototype.id) })"
          @change="toggleBreakdownItem('breakdownByBuildingHeight', type_prototype.id, $event)"
        />
      </div>
      
      <AssumptionsResidentialNewYears 
        v-if="specifyByYear" 
        :filters="getFilters(type_prototype, { type_prototype_id : type_prototype.id })" 
      />
      <AssumptionsBuildingEstimatesInputs
        v-else 
        :filters="getFilters(type_prototype, { type_prototype_id : type_prototype.id })"
      />
      <!-- <hr class="psui-border-blue-70 opacity-10 my-7"/> -->
    </div>
  </div>
</template>

<script>
import AssumptionsResidentialNewYears from './AssumptionsResidentialNewYears.vue'
import AssumptionsBuildingEstimatesInputs from '../AssumptionsBuildingEstimatesInputs.vue'
import { RESIDENTIAL_NEW_SPECIFICATIONS, BUILDING_ESTIMATES_BASE_YEARS } from '@/modules/app/assumptions/shared/enums'
import { constructionImplementationTypes } from '@/modules/app/assumptions/shared/BuildingEstimatesMockupData'

export default {
  name: 'AssumptionsResidentialNewBuildingTypes',
  components: { AssumptionsBuildingEstimatesInputs, AssumptionsResidentialNewYears },
  props: {
    specifyBy: [ Boolean, Array ],
    type_prototypes: Array,
    climate_zone_prefix: [Boolean, Number],
  },
  data: () => ({
    breakdownByConstructionImplementationType : {},
    breakdownByBuildingHeight : {},
    construction_implementation_types : constructionImplementationTypes,
    years: BUILDING_ESTIMATES_BASE_YEARS
  }),
  computed: {
    specifyByYear() {
      return this.specifyBy.includes(RESIDENTIAL_NEW_SPECIFICATIONS.YEAR.key) 
    },
    getConstructionImplementationTypes() {
      return this.$store.getters['globalEntities/ConstructionImplementationType/getterGlobalConstructionImplementationTypes']
    },
    getBuildingHeights() {
      return this.$store.getters['globalEntities/BuildingHeight/getterGlobalBuildingHeights']
    },
  },
  methods: {
    getFilters(type_prototype, filters) {
      return {
        ...this.getBaseFilters(type_prototype),
        ...filters
      }
    },
    getBaseFilters(type_prototype) {
      const filters = {}

      if (this.breakdownByBuildingHeight[type_prototype.id]) 
        filters.building_height_id =  this.getSelectedBuildingHeight(type_prototype.id)
      
      if (this.breakdownByConstructionImplementationType[type_prototype.id]) 
        filters.construction_implementation_type_id = this.getSelectedConstructionType(type_prototype.id)

      if (this.specifyBy.includes(RESIDENTIAL_NEW_SPECIFICATIONS.CLIMATE_ZONE.key) && this.climate_zone_prefix) 
        filters.climate_zone_prefix = this.climate_zone_prefix
      
      filters.type_prototype_id = type_prototype.id
      
      return filters
    },

    getSelectedBuildingHeight(type_prototype_id) {
      return this.breakdownByBuildingHeight[type_prototype_id] ?? this.breakdownByBuildingHeight[type_prototype_id].id
    },
    getSelectedConstructionType(type_prototype_id) {
      return this.breakdownByConstructionImplementationType[type_prototype_id] ?? this.breakdownByConstructionImplementationType[type_prototype_id].id
    },

    // New methods
    checkSwitch(breakdownObjKey, type_prototype_id) {
      return this[breakdownObjKey][type_prototype_id] ? true : false
    },
    toggleBreakdown(breakdownObjKey, type_prototype_id, items) {
      if(!this[breakdownObjKey][type_prototype_id]) {
        this.$set(this[breakdownObjKey], type_prototype_id, items[0].id)
      } else {
        this[breakdownObjKey][type_prototype_id] = false
      }
    },
    toggleBreakdownItem(breakdownObjKey, type_prototype_id, selected) {
      this[breakdownObjKey][type_prototype_id] = selected.id
    },

  },
}
</script>

<style lang="scss" scoped>
  .breakdown-switch {
    padding: 6px 12px;
    border-radius: 4px 4px 0px 0px;
    border-bottom: 1px solid white;
    
  }

  ::v-deep .psui-el-toggle {
    border-radius: 0px 0px 4px 4px;
    padding: 3px 4px;
  } 

</style>