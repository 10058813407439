<template>
  <div class="app-chartpie">
    <svg viewBox="0 0 32 32">
      <circle
        v-if="backgroundColor"
        cx="16"
        cy="16"
        r="15.91549430918954"
        :fill="backgroundColor"
      />
      <circle 
        v-for="(slice, index) in slices"
        :key="index"
        pointer-events="stroke"
        class="donut-segment"
        cx="16"
        cy="16" 
        r="15.91549430918954" 
        fill="transparent" 
        :stroke="slice.color" 
        stroke-width="32"
        :stroke-dasharray="`${slice.value} ${ 100 - slice.value}`"
        :stroke-dashoffset="getDatashOffset(index)"
        @mouseover="$emit('sliceover', { $event, slice, index })"
        @mouseleave="$emit('sliceleave', { $event, slice, index })"
      />
      <circle
        v-if="isDonut"
        cx="16"
        cy="16"
        r="12.5"
        fill="white"
      />
      <text
        v-if="text"
        x="50%"
        y="50%"
        dominant-baseline="middle"
        text-anchor="middle"
      >{{ text }}</text>
    </svg>
  </div>
</template>
<script>
export default {
  name: 'AppChartPie',
  props: {
    slices: {
      type: Array,
      required: true,
    },
    size: {
      type: Number,
      default: 32
    },
    isDonut: {
      type: Boolean,
      default: false
    },
    backgroundColor: {
      type: String
    },
    text: {
      type: String
    }
  },
  methods: {
    getDatashOffset(sliceIndex) {
      if (sliceIndex === 0) {
        return 0
      } else {
        const allPrecedingSliceValues = this.slices.reduce((prev, cur, index) => {
          return index < sliceIndex ? prev + cur.value : prev
        }, 0)
        return 100 - allPrecedingSliceValues
      }
    },
  }
}
</script>

<style lang="scss" scoped>
  svg {
    transform: rotate(-90deg);
    border-radius: 50%;
    width: 100%;
    height: 100%;
    position: relative;

    text {
      transform: rotate(90deg) translateY(1px);
      transform-origin: center;
      text-anchor: middle;
      font-size: 10px;      
      line-height: 1;
      fill: #81878A;
    }
  }
</style>

