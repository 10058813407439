import { groupBy } from '@/util/Functions'
import Jurisdiction from '@/models/Jurisdiction'
import getStudyColumnsBySelectedStatus from '@/business-logic/services/columns/getStudyColumnsBySelectedStatus'

export default {

  getterComparisonColumns: (state) => state.comparisonColumns,
  getterExistingBuildingsColumns: (state, getters) => getters.getterComparisonColumns['existingBuildings'],
  getterNewBuildingsColumns: (state, getters) => getters.getterComparisonColumns['newBuildings'],
  
  getterExistingBuildingsColumnsSelected: (state, getters) => getStudyColumnsBySelectedStatus(getters.getterComparisonColumns['existingBuildings'], true),
  getterExistingBuildingsColumnsUnselected: (state, getters) => getStudyColumnsBySelectedStatus(getters.getterComparisonColumns['existingBuildings'], false),

  getterOnlyShowingDifferentAssumptions: (state) => ( buildingType = 'existingBuildings' ) => {
    if (state.comparisonColumns[buildingType]) {
      const differentColumns = [ ...state.comparisonColumns[buildingType].columnGroups.find((columnGroup) => columnGroup.key === 'different_assumptions').columns ]
      const sharedColumns = [ ... state.comparisonColumns[buildingType].columnGroups.find((columnGroup) => columnGroup.key === 'shared_assumptions').columns ]
  
      const differentColumnsActivated = differentColumns.map(column => column.isActive).filter(i => i)
      const sharedColumnsUnactived = sharedColumns.map(column => column.isActive).filter(i => !i)

      if (
          differentColumns.length &&
          ( differentColumns.length === differentColumnsActivated.length ) &&
          ( sharedColumns.length === sharedColumnsUnactived.length )
      ) {
        return true
      } else {
        return false
      }
    }
  },

  getterUserPoliciesByJurisdiction: (state) => {
    if (state.userPolicies.length > 0) {
      return groupBy(state.userPolicies, (c) => c.jurisdiction_id )
    } else {
      return false
    }
  },

  getterJurisdictionsByUserPolicies : (state) => {
    if (state.userPolicies.length > 0) {
      return state.userPolicies.reduce((prev, cur) => {
        prev[cur.jurisdiction_id] = new Jurisdiction(cur.jurisdiction)
        return prev
      }, { })
    } else {
      return false
    }
  },
}