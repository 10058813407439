import projectionsColumns from '@/business-logic/constants/projectionsColumns'

export default ({ data, dataToAggregate }) => {
  for(const col of projectionsColumns) {
    if(typeof data[col] == 'undefined') {
      data[col] = parseFloat(dataToAggregate[col])
      continue
    }
    
    if(col == 'forecast_units_affected') {
      continue
    }
    
    data[col] += parseFloat(dataToAggregate[col])
  }
}