<template>
  <a
    :class="[theme, `icon--${icon}`, disabled ? 'disabled' : '']"
    :href="disabled ? '#' : to" 
    :target="target"
    :disabled="disabled"
    class="w-full"
  >
    <img
      v-if="icon"
      class="absolute left-0 ml-4"
      :src="`/icons/${icon}.svg`"
      :alt="`Icon ${label}`"
      width="20px"
    >
    <span class="ml-6 ellipsis">{{ label }}</span>
  </a>
</template>

<script>
export default {  
  props: {
    label: {
      type: String,
      required: true
    },
    icon: {
      type: String,
    },
    theme: {
      type: String,
      default: 'btn--sec'
    },
    to: {
      type: String,
      required: true,
      default: ''
    },
    target: {
      type: String,
      default: '_self'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
}
</script>