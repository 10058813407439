<template>
  <BaseModal
    ref="modalSelectJurisdiction"
    title="Select a jurisdiction"
    :disable-close="true"
  >
    <div class="modal-jurisdiction-selection mb-4">
      <JurisdictionSelect 
        :jurisdiction="lastJurisdictionVisited"   
        :input-class="'text-header-5'"
        label="Start typing the name of your city or county"
        icon="search"
        icon-class="psui-text-blue-60"
        @change="onChange"
      />
    </div>
  </BaseModal>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'ModalJurisdictionSelection',
  computed: {
    ...mapGetters(['lastJurisdictionVisited'])
  },
  watch: {
    $route(route) {
      if (!route?.params?.jurisdiction_slug) {
        this.$refs.modalSelectJurisdiction.showModal = true
      } else {
        this.$refs.modalSelectJurisdiction.showModal = false
      }
    }
  },
  created() {
    this.$eventBus.$on('openModalJurisdictionSelection', () => {
      this.$refs.modalSelectJurisdiction.showModal = true
    })
  },
  methods: {    
     onChange(jurisdiction) {
      this.$store.dispatch('setUserLastJurisdictionAndFirstClimateZoneVisited', { value: jurisdiction, context: 'updateLastJurisdictionVisitedFromModalJurisdictionSelection' })
      this.pushToParams({ 'jurisdiction_slug':jurisdiction.slug, 'climate_zone_raw': jurisdiction.climate_zones[0].raw })
    },
  }
}
</script>

<style scoped lang="scss">

  .modal-jurisdiction-selection {
    ::v-deep {
      .jurisdiction--select {
        margin-top: 36px;
        .input-wrapper {
          min-height: 49px;
          input {
            width: 100%;
            padding: 8px;
            &::placeholder {
              color: #318FAC;
            }
          }
        }
      }
    }
  } 
  
</style>