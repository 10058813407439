
export const GA_EVENTS = {
  DOWNLOAD_PDF : 'DOWNLOAD_PDF',
  SHARE_URL : 'SHARE_URL',
  FILTER_BY : 'FILTER_BY'
}

export const GA_EVENT_CATEGORIES = {
  USER_INTERACTION : 'USER_INTERACTION',
}

export const GA_LABELS = {

  SUMMARY_STUDY_DOWNLOAD_PDF : 'SUMMARY_STUDY_DOWNLOAD_PDF',
  SUMMARY_STUDY_MORE_INFO : 'SUMMARY_STUDY_MORE_INFO',
  SUMMARY_STUDY_SEE_RESULTS : 'SUMMARY_STUDY_SEE_RESULTS',
  SUMMARY_HOW_TO_GET_READY : 'SUMMARY_HOW_TO_GET_READY',
  SUMMARY_SEND_ME_A_REMINDER : 'SUMMARY_SEND_ME_A_REMINDER',
  SUMMARY_CUSTOM_LINKS : 'SUMMARY_CUSTOM_LINKS',


  RESULTS_SHARE_URL : 'RESULTS_SHARE_URL',
  RESULTS_DOWNLOAD_PDF : 'RESULTS_DOWNLOAD_PDF',
  RESULTS_FILTER_BY : 'RESULTS_FILTER_BY',
  RESULTS_HOW_TO_GET_READY : 'RESULTS_HOW_TO_GET_READY',
  RESULTS_SEND_ME_A_REMINDER : 'RESULTS_SEND_ME_A_REMINDER',

  BUILDINGSTOCK_SHARE_URL : 'BUILDINGSTOCK_SHARE_URL',
  BUILDINGSTOCK_DOWNLOAD_PDF : 'BUILDINGSTOCK_DOWNLOAD_PDF',
  BUILDINGSTOCK_DOWNLOAD_CSV : 'BUILDINGSTOCK_DOWNLOAD_CSV',
  BUILDINGSTOCK_FILTER_BY : 'BUILDINGSTOCK_FILTER_BY',

  BUILDING_ESTIMATES_DOWNLOAD_PDF: 'BUILDING_ESTIMATES_DOWNLOAD_PDF',
  BUILDING_ESTIMATES_DOWNLOAD_CSV: 'BUILDING_ESTIMATES_DOWNLOAD_CSV',
}


export default {
  methods: {
    gaEvent(action, args) {
      if(process.env.VUE_APP_ENV == 'production' && window.location.origin == 'https://explorer.localenergycodes.com') {
        this.$gtag.event(action, args)
      }
    },
    gaEventShareUrl(event_label, value = null) {
      value = value ?? window.location.href
      this.gaEvent(GA_EVENTS.SHARE_URL, {
        event_category: GA_EVENT_CATEGORIES.USER_INTERACTION,
        event_label,
        value
      })
    },
    gaEventDownloadFile(event_label, value = null) {
      this.gaEvent(GA_EVENTS.DOWNLOAD_PDF, {
        event_category: GA_EVENT_CATEGORIES.USER_INTERACTION,
        event_label,
        value
      })
    },
    gaEventFilterBy(event_label, value = null) {
      this.gaEvent(GA_EVENTS.FILTER_BY, {
        event_category: GA_EVENT_CATEGORIES.USER_INTERACTION,
        event_label,
        value
      })
    }
  },
}
