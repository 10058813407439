import BasePdfService from '@/services/pdf/BasePdfService'
import StudyData from '@/models/StudyData'
import StudyType from '@/models/StudyType'
import MainVuex from '@/store'
import autoTable from 'jspdf-autotable'
import formatStudyData from '@/formatters/formatStudyData'
import StudyDataApiService from '@/services/api/StudyDataApiService'
import StudyResultApiService from '@/services/api/StudyResultApiService'
export default class StudyResultsGeneratePdfService extends BasePdfService {

  fontSizeDefault = 10
  studyTypes = []
  type_prototypes = []
  prototypes = []

  constructor(args) {    
    super(args)
    return this.initPdf()
  }
  
  async initPdf() {

    try {
      await this.getForecastData()
      const tables = await this.getTables()
      await this.addTables(tables)
      await this.export()
      
    } catch (error) {
      console.error(error)
      throw new Error(error)
    }
  }

  async getForecastData() {
    
    const studyResultsAssumption = MainVuex.getters['assumptions/getterAssumptionsSelected']

    await StudyResultApiService.get().then(({ study_types, type_prototypes }) => {
      this.studyTypes = study_types.map((i) => new StudyType(i)) ?? []
      this.type_prototypes = type_prototypes ?? []
    })

    for(const studyType of this.studyTypes) {
      for( const studyGroup of studyType.study_groups) {
        for ( const typePrototype of this.type_prototypes) {
          for ( const prototype of this.getStudyGroupPrototypesFilteredByTypePrototypeAndSortedByStudyRelease({ studyGroup, typePrototype })) {
            
            if (!window.$vueInstance.checkQueryKeyValue('exclude_type_prototype_ids', typePrototype.id) && 
                !window.$vueInstance.checkQueryKeyValue('exclude_study_ids', prototype.study.id) &&
                prototype.study.published_at
            ) {
              
              const key = studyType.title === 'Existing Buildings' ? 'vintages' : 'fuels'

              for ( const prototypeKey of prototype?.study?.[key] ) {
                
                const studyData = await StudyDataApiService.get(this.getStudyDataPayload({ prototype, [key === 'vintages' ? 'vintage' : 'fuel' ] : prototypeKey }))
                const studyDataPayload = {}
                
                if (key === 'vintages') {
                  const building_stock_units = await MainVuex.getters['assumptions/buildingStocks/getterAllBuildingStocksUnits']({ 
                    jurisdiction_id : MainVuex.getters['lastJurisdictionVisited']?.id,
                    climate_zone_prefix: MainVuex.getters['lastClimateZoneVisited']?.prefix,
                    type_prototype_id: typePrototype.id,
                    type_vintage_id : prototypeKey.type_vintage_id
                  })
                  studyDataPayload.building_stock_units = building_stock_units
                  studyDataPayload.assumption = studyResultsAssumption
                }
                
                prototypeKey.study_data = studyData.map( (study_data) => new StudyData({ study_data, ...studyDataPayload }))
              }

              prototype.studyType = studyType 

              this.prototypes.push(prototype)
                            
            }
          }
        }
      }
    }     

    return this.studyTypes

  }


  getTableHeaders(studyType) {
    
    const getterColumns = studyType.title === 'Existing Buildings' ? 'getterExistingBuildingsColumnsSelected' : 'getterNewBuildingsColumnsSelected'      
    const columnGroups = MainVuex.getters[`studyResults/${getterColumns}`].columnGroups

    const headers = [
      {
        key: 'title',
        label: studyType.title === 'Existing Buildings' ? 'Measure' : 'Package',
      }
    ]

    if (columnGroups) {
      columnGroups.forEach(columnGroup => {
        if (columnGroup.columns) {
          columnGroup.columns.forEach((column) => {
            if (column.isActive) {
              headers.push({
                key: column.key,
                label: column.title,
                groupKey: columnGroup.key,
                groupTitle: columnGroup.title
              })
            }
          })
        }
      }) 
    }

    return headers
  }
  
  async getTables() {
    
    let tables = []
    
    this.prototypes.forEach(prototype => {

      const key = prototype.studyType.title === 'Existing Buildings' ? 'vintages' : 'fuels'
      const prototypeKeys = key === 'vintages' ? this.getVintagesOrdered(prototype.study.vintages) : this.getFuelsOrdered(prototype.study.fuels)

      for( const prototypeKey of prototypeKeys) {
                
        if (key === 'vintages' && window.$vueInstance.checkQueryKeyValue('exclude_type_vintage_ids', prototypeKey.type_vintage_id)) return false 
        if (key === 'fuels' && window.$vueInstance.checkQueryKeyValue('exclude_type_fuel_ids', prototypeKey.type_fuel_id)) return false

        tables.push({
          study: prototype.study.title,
          studyType: prototype.studyType,
          prototype: prototype.title,
          key : prototypeKey.title_long ? prototypeKey.title_long : prototypeKey.title,
          item: prototypeKey,
          headers: this.getTableHeaders(prototype.studyType),
          rows : prototypeKey.study_data
        })

      }      

    })

    return tables
  }


  async addTables(tables) {
            
    tables.map((table, tableIndex) => {
      
      const maxColums = 7
      const bodies = this.formatTableRowsWithFixedColumns(table, maxColums)
      const headers = this.formatTableHeadersWithFixedColumns(table, maxColums)            
      
      
      let lastHeaderIndex = 0

      headers.forEach((head, headerIndex) => {
  
          lastHeaderIndex = headerIndex   
          const yPosition = lastHeaderIndex === tableIndex && this.doc?.lastAutoTable?.finalY ? this.doc?.lastAutoTable?.finalY + 20 : this.margins.top + 20      
          const body = bodies[headerIndex]

          if (headers.length > 1) {            
            head.splice(2,0, this.addTableIndexCaption({ colSpan : head[head.length - 1].length , tableIndex, headerIndex, headers }))
          }

          autoTable(this.doc, {
            theme: 'plain',
            body,
            head, 
            startY: yPosition,
            styles: {
              font: 'Lato-Regular',
              fontSize: this.fontSizeDefault,
            },
            margin: this.margins,
            willDrawCell: (data) => {
              this.onTableBreak(data)
            },
            didDrawCell: (data) => {
              
              this.addVerticalBlueBarALongTheHeadline(data)
              this.addBlueBottomLineBetweenTheColumLabel(data)

            } // didDrawCell()

          }) // autoTable

          this.doc.lastAutoTable = 0
          
          if (tableIndex + 1 !== tables.length || headerIndex + 1 !== headers.length) {
            this.addPage()
          }

        }) // headers.forEach((head, tableIndex) 
        
        
      }) // tables.map((table, tableIndex)

      
  }  

  addTableIndexCaption({ colSpan, headerIndex, headers }) {
    return [
      {
        colSpan,
        content: `Table ${headerIndex + 1} of ${headers.length}`,
        styles: {
          cellPadding: { 
            top: this.fontSizeDefault,
            right: this.fontSizeDefault * 1.5,
            left: 0,
            bottom: 0
          },
          font: 'Lato-Regular',
          fontSize: this.fontSizeDefault * .75,
          textColor: '#76848A'
        }
      }
    ]
  }

  addBlueBottomLineBetweenTheColumLabel(data) {
    if (data.cell.raw?.isGroupKey) {
                  
      // Add the blue bottom line between the column label and colum key label
      // const xFinalPos = this.pageWidth - data.settings.margin.right
      const xFinalPos = data.cell.x + data.cell.width - 5
      const yPos = data.cell.y + data.cell.height + 1
      
      this.doc.setDrawColor(this.colors.blue05)
      this.doc.setLineWidth(1.4)
      this.doc.line(data.cell.x, yPos, xFinalPos, yPos) 
      

      // Add the gray bar below the table header columns
      // const pageSize = this.doc.internal.pageSize
      // const xFinalPosition = data.cell.x + data.cell.width - 5
      // const yPosition = data.cell.y + data.cell.height + 20
                     
      // this.doc.setDrawColor('#e1e2e6');
      // this.doc.setLineWidth(0.1);
      // this.doc.line(data.settings.margin.left, yPosition, xFinalPosition, yPosition); 

    } // if (data.cell.raw?.isGroupKey)
  }

  addVerticalBlueBarALongTheHeadline(data) {
    if (data.cell.raw?.isHeadline) {
              
      // Add the vertical blue bar along the headline and title
      this.doc.setDrawColor(this.colors.blue02)
      this.doc.setLineWidth(.9)
      this.doc.line(22, data.cell.y + 5, 22, (data.cell.y + 30))            
              
    } // if (data.cell.raw?.isHeadline)
  }

  onTableBreak(data) {
    if (data.pageCount > 1 ) {
      this.addContinuedAtTableTitle(data)      
    }
  }

  addContinuedAtTableTitle(data) {
    const sufix = '  (Continued)'
    const currentTitle = data.table.head[1].cells[0].text[0]
    const modifiedTitle = data.table.head[1].cells[0].text  + sufix
    
    if (currentTitle.indexOf(sufix) === -1) {
      data.table.head[1].cells[0].text = [modifiedTitle]
    }
  }


  formatTableRows(table) {
    return table.rows.map((row, indexRow) => {
      let rowArray = []      
      table.headers.map(({ key }, indexHeader ) => {
        let content = ''        
        switch (key) {
          case 'title':
            content = row.measure.title              

            break
        
          default:
            content = formatStudyData(key, row.data[key], row.data )
            break
        }


        let cell = { 
          content: content,
          key: key,
          styles: {
            font: 'Lato-Regular',
            textColor: this.colors.gray04,
            cellPadding: { 
              top: this.fontSizeDefault,
              right: this.fontSizeDefault * 1.5,
              left: 0,
              bottom: 0
            },
          }
        }
        
        // is First Column
        if (indexHeader === 0) {
          cell.styles.font = 'Lato-Bold'
          cell.styles.textColor = this.colors.gray01
        }

        if (indexRow === 0) {
          cell.styles.cellPadding.top = 12
        }

        rowArray.push(cell)
      })
      return rowArray
    })
  }

  getTableHeaderHeadline(headers, study) {
    return { 
      content: study.toUpperCase() , 
      colSpan: headers.length,
      isHeadline : true,
      styles: { 
        fontSize: 9,
        font: 'Lato-Regular',
        textColor: this.colors.gray03,
        cellPadding: { top: 8, right: 5, left: 12, bottom: 0 }
      } 
    }
  }

  getTableHeaderSubtitle(table) {    
    if(table.studyType.title === 'Existing Buildings') {
      return `${table.prototype} | ${table.key} (${ Number(table.item.study_data[0].building_stock_units).toLocaleString()} units)`
    } else {
      return `${table.prototype} | ${table.key}`
    }    
  }

  getTableHeaderTitle(table, headers = false) {
    if (!headers) {
      headers = table.headers
    }
    return { 
      content: this.getTableHeaderSubtitle(table), 
      colSpan: headers.length, 
      styles: { 
        fontSize: 14,
        font: 'Lato-Bold',
        textColor: this.colors.gray02,
        cellPadding: { top: 2, right: 5, left: 12, bottom: 5 }
      } 
    }
  }

  getTableHeaderGroupColumns(headers) {
    return [...headers].reduce((prev, header, index) => {
      
      if (!prev[header.groupKey]) {
        const key = header.groupKey ?? index
        const content = header.groupTitle ?? ''
        prev[key] = {
          content,
          colSpan: 1,
          groupKey: key,
          isGroupKey: true,
          styles: { 
            font: 'Lato-Bold',            
            valign: 'top',
            cellPadding: { 
              top: 6, 
              left: 0, 
              right: 10, 
              bottom: 5 
            }
          }
        }
      } else {
        prev[header.groupKey].colSpan = prev[header.groupKey].colSpan + 1
      }

      return prev

    }, {})
  }

  getTableHeaderColumns(table) {
    return [...table.headers]
      .map((item, index) => {

        let column = {
          content: `${item.label}`,
          groupKey : item.groupKey,
          groupTitle : item.groupTitle,
          key : item.key,
          styles: { 
            font: 'Lato-Bold',
            valign: 'top',
            cellPadding: { 
              top: this.fontSizeDefault,
              right: this.fontSizeDefault * 1.5, 
              left: 0,
              bottom: this.fontSizeDefault
            }
          }
        }

        // isFistColumn
        if (index === 0) {
          column.isFirstHeaderColumn = true
          // column.styles.cellPadding.left = 12
        }
        
        return column
      })
  }

  formatTableHeadersByColumnsGroup(table) {

    const tableHeadline = this.getTableHeaderHeadline(table.headers, table.study)
    const tableTitle = this.getTableHeaderTitle(table)       
    const tableHeaderColumns = this.getTableHeaderColumns(table)
    const tableHeaderGroupColumns = this.getTableHeaderGroupColumns(table.headers)
    
    // Clear the first group column
    const blankColumn = { ... tableHeaderGroupColumns[0] }
    delete tableHeaderGroupColumns[0]

    const tableHeaders = Object.values(tableHeaderGroupColumns).reduce((prev, groupColumn, index) => {      
      
      groupColumn.colSpan = groupColumn.colSpan + 1
      const tableHeader = []
      if (!index) {
        tableHeader.push([ tableHeadline ])
        tableHeader.push([ tableTitle ])
      }
      tableHeader.push([ blankColumn, groupColumn ])
      tableHeader.push([ ...tableHeaderColumns.filter((col) => col.groupKey == groupColumn.groupKey || col.key === 'title' ) ])
      
      prev.push(tableHeader)

      return prev
    }, [])
    
    return tableHeaders
  }

  formatTableRowsWithFixedColumns(table, maxColumsByTable) {

    const allRows = this.formatTableRows(table)
    const rows = []

    allRows.forEach((row) => {

      const titleColum = row.shift()      
      const maxColumsByTableWithoutTitle = maxColumsByTable - 1

      const rowChunks = new Array(Math.ceil(row.length / maxColumsByTableWithoutTitle)).fill().map(() => row.splice(0, maxColumsByTableWithoutTitle))
      // console.log('rowChunks', rowChunks)
      rowChunks.forEach((rowChunk, index) => {
        if (!rows[index]) {
          rows[index] = []
        }
        rows[index].push([ titleColum, ...rowChunk ] )
      })

    })

    return rows
  }

  formatTableHeadersWithFixedColumns(table, maxColumsByTable) {
     
    
    let tableHeaderColumns = this.getTableHeaderColumns(table)
    
    const tableHeaderColumnTitle = tableHeaderColumns.shift()
  
    const maxColumsByTableWithoutTitle = maxColumsByTable - 1
        
    const columnHeadersChunks = new Array(Math.ceil(tableHeaderColumns.length / maxColumsByTableWithoutTitle))
    .fill()
    .map(() => tableHeaderColumns.splice(0, maxColumsByTableWithoutTitle))
    
    return columnHeadersChunks.reduce((prev, columnHeaders) => {

      const columHeadersPlusTitle = [ tableHeaderColumnTitle, ...columnHeaders ]
      const headerGroupColumns = Object.values(this.getTableHeaderGroupColumns(columHeadersPlusTitle))
      const tableHeadline = this.getTableHeaderHeadline(columHeadersPlusTitle, table.study)
      const tableTitle = this.getTableHeaderTitle(table, columHeadersPlusTitle)
      
      prev.push([
        [ tableHeadline ],
        [ tableTitle ],
        headerGroupColumns,
        columHeadersPlusTitle
      ])

      return prev
    }, [])
    
  }

  formatTableHeader(table) {

    const tableHeadline = this.getTableHeaderHeadline(table.headers, table.study)
    const tableTitle = this.getTableHeaderTitle(table)       
    const tableHeaderGroupColumns = this.getTableHeaderGroupColumns(table.headers)
    const tableHeaderColumns = this.getTableHeaderColumns(table)

    return [ 
      [ tableHeadline ], 
      [ tableTitle ], 
      [ ...Object.values(tableHeaderGroupColumns)],
      [ ...tableHeaderColumns ]
    ]
  }

  getVintagesOrdered(vintages) {
    const vintagesOrdered = [...vintages]
    vintagesOrdered.sort((a,b) => a.type_vintage?.order - b.type_vintage?.order )
    return vintagesOrdered
  }

  getStudyGroupPrototypesFilteredByTypePrototypeAndSortedByStudyRelease ({ studyGroup, typePrototype }) {
    return this.getStudyGroupPrototypes(studyGroup)
      .filter((prototype) => parseInt(prototype.type_prototype_id) === parseInt(typePrototype.id) )
      .sort((a, b) => new Date(b.study.released_at) - new Date(a.study.released_at))
  }

  getStudyGroupPrototypes(study_group) {
    const studyGroup = { ...study_group }
    delete studyGroup.studies 
    return [...study_group.studies].reduce((prev, study) => {
        study.prototypes.forEach((prototype) => {
          prev.push({ ... prototype, study : { ...study, study_group: studyGroup } })
        })
      return prev
    }, [])
  
  }

  getFuelsOrdered(fuels) {
    const fuelsOrdered = [...fuels]
    fuelsOrdered.sort((a,b) => a.type_fuel?.order - b.type_fuel?.order )
    return fuelsOrdered
  }
  
  getStudyDataPayload({ prototype, vintage, fuel }) {
  
    const studyDataPayload = {
      climate_zone_raw : window.$vueInstance.$route.params.climate_zone_raw,
      prototype_id: prototype.id,        
    }
  
    if (vintage) studyDataPayload.vintage_id = vintage.id
    if (fuel) studyDataPayload.fuel_id = fuel.id
    
    return studyDataPayload
  }

}