import Api from '@/util/Api'

import { defaultImpactAssumptionValues } from '@/models/ImpactAssumptions'
import {
  applicability_rate,
  annual_penetration_rate,
  effective_date,
  delay_installation,
  clean_energy_progress,
  current_grid_renewable_level,
  baseline_installation_rate,
  current_kwh_emissions_factor,
  current_therms_emissions_factor,
} from './columns'

const NULLED_VALUE = 'nulled'

export default {

  getUserPolicies({ commit, rootGetters }) {
    if (rootGetters.getterLoggedUser) {
      return Api.get(`/api/policies/get`)
        .then(({ data }) => {
          if (data.policies.length > 0) {
            commit('setUserPolicies', data.policies)
            return data.policies
          } else {
            return false
          }
        })      
    } else {
      return false
    }
  },

  setComparisonAssumptionsGroupColumns(context, { policies }) {
    
    const assumptionKeys = Object.keys(defaultImpactAssumptionValues)    
    const assumptionsBetweenPolicies = mapAssumptionsBetweenPolicies(assumptionKeys, policies) 
    
    const differentAssumptionsBetweenPolicies = Object.entries(assumptionsBetweenPolicies).filter(entry => entry[1] === NULLED_VALUE).map(entry => entry[0])
    
    const assumptionsColumns = [
      applicability_rate(),
      annual_penetration_rate(),
      effective_date(),
      delay_installation(),
      clean_energy_progress(),
      current_grid_renewable_level(),
      baseline_installation_rate(),
      current_kwh_emissions_factor(),
      current_therms_emissions_factor(),
    ]

    const differentAssumptionColumnsBetweenPolicies = assumptionsColumns.filter((column) => {
      const intersectedKeysBetweenDifferentAssumptionColumns = column.assumption_keys.filter(value => differentAssumptionsBetweenPolicies.includes(value))
      return intersectedKeysBetweenDifferentAssumptionColumns.length > 0 ? true : false
    })
    const differentAssumptionColumnKeysBetweenPolicies = differentAssumptionColumnsBetweenPolicies.map(column => column.key )

    const sharedAssumptionColumnsBetweenPolicies = assumptionsColumns.filter((column) => !differentAssumptionColumnKeysBetweenPolicies.includes(column.key))

    context.commit('setAssumptionColumns', { different: differentAssumptionColumnsBetweenPolicies, shared: sharedAssumptionColumnsBetweenPolicies })
    // console.log('differentAssumptionColumnsBetweenPolicies', differentAssumptionColumnsBetweenPolicies)
    // console.log('sharedAssumptionColumnsBetweenPolicies', sharedAssumptionColumnsBetweenPolicies)

  }
}



function mapAssumptionsBetweenPolicies(assumptionKeys, policies) {
  
  return assumptionKeys.reduce((prev, key) => {
    
    policies.forEach((policy) => {
      if (!prev[key]) {
        prev[key] = policy[key]
      } else {
        prev[key] = prev[key] === policy[key] ? prev[key] : NULLED_VALUE
      }
    })

    return prev
  }, { })
}
