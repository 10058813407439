import { showPoliciesByOptions } from '@/business-logic/constants/policiesEnum'

export const initialState = () => ({
  loggedUser: null,
  loggedUserType: null,
  loggedUserToken: null,
  user_preferences: {
    lastJurisdictionVisited: false,
    lastClimateZonesVisited: false,
    policies: {
      myPolicies: {
        filterByAuthor: showPoliciesByOptions.ALL,
        filterByJurisdiction: [],
        orderByColumn: 'title',
        orderByDirection: 'asc',
      },
      comparison: {
        showOnlyDifferentAssumptionColumns: false,
      }
    },
    modulesVisited: []
  }
})

export default {
  ...initialState()
}

