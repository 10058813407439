<template>
  <div class="donut-chart psui-w-full psui-h-full psui-rounded-md psui-bg-white psui-shadow-elevation-5 psui-p-8 psui-flex psui-flex-col psui-justify-between">
    <div class="psui-w-full psui-flex psui-justify-between mb-6">
      <h4 class="psui-text-h5 psui-text-gray-80 psui-font-bold">
        Existing Units
      </h4>
      <PsCardInfos 
        title="Total"
        :subtitle="`as of 2020`"
        :total="formatUnits(getTotal)"
        @click="openAssumptionsDrawer()"
      />
    </div>

    <div
      class="psui-block"
      style="height: 300px;"
    >
      <div ref="chartExistingUnits" />
      <ApexChartGeneral 
        :options="getChartOptions()"
        :palette-color-index="paletteColorIndex"
      />
    </div>

    <div class="psui-flex psui-mx-auto psui-space-x-2">
      <PsChartLegend
        v-for="(type_prototype, index) in getTypePrototypesWithUnits" 
        :key="type_prototype.id"
        :text="type_prototype.title"
        :total="formatUnits(type_prototype.building_stock_units)"
        :percentage="`${formatNumber(getPercentage(type_prototype.building_stock_units))}`"
        :dot-color="{ backgroundColor: colors[index] }"
      />
    </div>
  </div>
</template>

<script>
import { colors } from '@/business-logic/constants/chartDefaultOptions'
import { MathHelpers } from '@igortrindade/lazyfy'
export default {
  name: 'ExistingUnitsDonutsChart',
  props: ['getTypePrototypesWithUnits', 'paletteColorIndex'],
  data() {
    return {
      colors
    }
  },
  computed: {
    getTotal() {
      return this.getTypePrototypesWithUnits.reduce((acc, i) => acc + i.building_stock_units, 0)
    }
  },
  methods: {
    getChartOptions() {
      const self = this
      return {
        series: this.getTypePrototypesWithUnits.map((item) => MathHelpers.getValueOrMinPercentage(this.getTotal, parseInt(item.building_stock_units), 1) ),
        colors: colors,
        chart: {
          type: 'donut',
          toolbar: { show: false, },
          events: {
            dataPointSelection: function(event, chartContext, { seriesIndex }) {
              const item = self.getTypePrototypesWithUnits[seriesIndex]
              console.log(item)
            }
          },
        },
        tooltip: {
          followCursor: false,
          fixed: {
            enabled: true,
            position: "topRight",
            offsetX: 0,
            offsetY: 0,
          },
          custom: function ({ seriesIndex }) {
            const type_prototype = self.getTypePrototypesWithUnits[seriesIndex]
            return `
              <div class="psui-flex" style="background-color: ${ colors[seriesIndex] } !important;">
                <div class="apexcharts-tooltip-content psui-w-full psui-bg-white psui-p-4">
                  <h4 class="psui-text-gray-80 psui-text-xsmall psui-font-bold">${ type_prototype.title }</h4>
                  <div class="psui-w-full psui-flex psui-items-center">
                    <h2 class="psui-text-gray-80 psui-text-small">${ self.formatUnits(type_prototype.building_stock_units) } Units</h2>
                    <span class="psui-text-gray-30 psui-mx-1">|</span>
                    <h2 class="psui-text-gray-50 psui-text-small">${ self.formatNumber(self.getPercentage(type_prototype.building_stock_units)) }%</h2>
                  </div>
                  <div class="w-full flex flex-wrap">
                    ${type_prototype ? `<p class="psui-text-gray-40 psui-text-xsmall psui-font-bold whitespace-normal">${ type_prototype.building_stock_helper }</p>` : ''}
                  </div>
                </div>
              </div>
            `
          }
        }
      }
    },

    goToStudyResults({ type_prototype_id, climate_zone }) {
      const type_prototypes = this.$store.getters['globalEntities/TypePrototype/getterGlobalTypePrototypes']
      const excludedTypePrototypes = type_prototypes.filter((type_prototype) => type_prototype.id != type_prototype_id)
      const forecastRoute = {
        name: 'StudyResults',
        params: { climate_zone_raw: climate_zone.raw },
        query: { exclude_type_prototype_ids : excludedTypePrototypes.map((item) => item.id).join(',') }
      }
      this.$router.push(forecastRoute)
    },

    getPercentage(value) {
      return 100 / this.getTotal * value
    },

    openAssumptionsDrawer() {
      this.$store.commit('assumptions/openDrawerAtBuildingEstimatesResidentialExisting')
    }
  }
}
</script>

<style scoped>
  .apexcharts-tooltip-content {
    max-width: 200px;
  }

  .apexcharts-tooltip-content.apexcharts-theme-dark {
    background: #fff;
  }
  .space-x-2>:not(template)~:not(template) {
    --margin-left: calc(0.35rem*(1 - var(--space-x-reverse)));
    margin-left: calc(0.35rem*(1 - var(--space-x-reverse)));
  }
  .apexcharts-tooltip-content {
    box-shadow: remove;
    /* border: 1px solid #E6ECF2; */
  }
</style>
