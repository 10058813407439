<template>
  <div class="utility-assumptions">
    <table>
      <thead>
        <th
          class="ts--accent--book--1"
          align="left"
        >
          Study
        </th>
        <th class="ts--accent--book--1">
          Electric
        </th>
        <th class="ts--accent--book--1">
          Gas
        </th>
      </thead>
      <tbody v-if="study_groups && study_groups.length > 0">
        <tr
          v-for="(study) in study_groups"
          :key="study.id"
        >
          <td>{{ study.title }}</td>
          <td>{{ utilitiesByStudies[`${study.id}_eletric`] }}</td>
          <td>{{ utilitiesByStudies[`${study.id}_gas`] }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

import utilityByCz from '@/data/defaultUtilityByClimateZone'
import utilityByStudies from '@/data/utilitiesByStudyByStandartUtility'
import { mapGetters } from 'vuex'
import StudyGroupApiService from '@/services/api/StudyGroupApiService'

export default {
  name: 'AppUtilityRatesTable',
  data: () => ({
    study_groups: []
  }),
  computed: {
    utilitiesByStudies() {
      let utilities = utilityByStudies.filter(item => item.utility === this.climateZone)
      if (utilities.length == 0) {
        const defaultCz = this.getDefaultCz(this.climateZone)
        utilities = utilityByStudies.filter(item => item.utility === defaultCz)
      }
      return utilities ? utilities[0] : false
    },
    climateZone() {
      return this.$route.params.climate_zone_raw ?? this.lastJurisdictionVisited.climate_zones?.[0]?.raw
    },
    ...mapGetters(['lastJurisdictionVisited'])
  },
  mounted() {
    StudyGroupApiService.all().then((study_groups => {
      this.study_groups = study_groups
    }))
  },
  methods: {
    getDefaultCz(cz) {
      return utilityByCz[cz]
    }
  }
}
</script>

<style scoped lang="scss">
  .utility-assumptions {
    margin: 30px 0;
    border-radius: 5px;
    max-width: 690px;
  }
  table {    
    width: 100%;
    thead {
      th {
        padding: 7px 18px;
        text-align: center;
        background-color: app-color('blue04');
        color: white;
        &:first-of-type {
          border-top-left-radius: 5px;
          text-align: left;
        }
        &:last-of-type {
          border-top-right-radius: 5px;
        }
      }
    }
    tr {      
      td {
        background-color: app-color('gray06');
        padding: 10px 18px;
        &:nth-of-type(1) {
          color: app-color('gray02');
          font-weight: bold;
          font-size: 16px;
          line-height: 26px;
        }
        &:nth-of-type(2), &:nth-of-type(3) {
          width: 150px;
          text-align: center;
        }
      }
    }
  }
</style>