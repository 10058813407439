<template>
  <div
    v-click-outside="close"
    class="item--select w-auto max-w-full overflow-x-none relative flex-shrink-0"
    :class="cssClass"
    @keydown.up="navigate('up')"
    @keydown.down="navigate('down')"
    @keydown.enter="selectOnEnter()"
    @keydown.esc="close()"
  >
    <InputWithLabel 
      ref="input"
      :label="label"
      :placeholder="placeholder"
      :input-class="inputClass"
      :value="getInputLabel"
      @focus="onFocus"
      @blur="onBlur"
      @input="onInput"
    />
    <transition
      enter-active-class="transition ease-out duration-100 transform"
      enter-class="opacity-0 scale-95"
      enter-to-class="opacity-100 scale-100"
      leave-active-class="transition ease-in duration-100 transform"
      leave-class="opacity-100 scale-100"
      leave-to-class="opacity-0 scale-95"
    >
      <div 
        v-if="search"
        class="origin-top-right absolute max-h-1/2 min-w-300 overflow-y-auto left-0 mt-2 rounded shadow-lg border border-blue05 p-4 z-40 bg-white"
        :style="{ width: `${width}px`}"
        role="menu" 
        aria-orientation="vertical"
      >
        <AppLoader v-if="!requestFetched && search && !items.length" />
        <div class="w-full flex flex-wrap bg-white">
          <div
            v-if="requestFetched && !items.length"
            class="w-full p-3 flex flex-col"
          >
            <h3 class="text-red">
              Hmmmm
            </h3>
            <p class="text-gray04">
              Please check the spelling and try again
            </p>
          </div>
          <ul
            v-else
            class="w-full"
          >
            <li
              v-for="(item, index) in items"
              :id="`item-list-item-${index}`"
              :key="`dropdown-item-${item.id}`"
              tabindex="0"
              :class="{'bg-gray06' : index == currentSelected}"
              class="w-full hover:bg-gray06 p-3 text-base cursor-pointer border-b border-gray06 focus:outline-none"
              @click="setItem(item)"
            >
              <span class="w-full flex justify-between items-center">
                <h3>{{ item.title }}</h3>
                <h3 class="text-gray04 text-sm">{{ item.type }}</h3>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { delayExecution } from '@/util/Functions'

export default {
  name: 'InputDropdownSearch',
  props: {
    item: {
      type: [Boolean, Object],
      required: true
    },
    width: {
      default: 244
    },
    focusOnMount: {
      default: false
    },
    shouldScroll: {
      default: false
    },
    inputClass: {
      type: String,
    },
    label: {
      type: String,
    },
    objKey: {
      type: String,
      default: 'title'
    },
    placeholder: {
      type: String,
    },
    dataHandler: {
      type: Function,
      require: true
    }
  },
  data() {
    return {
      search: '',
      showDropDown: true,
      items: [],
      requestFetched: false,
      cancelToken: null,
      currentSelected: 0,
      isFocused: false,      
    }
  },
  computed: {

    getInputLabel() {
      if (this.isFocused) {
        return this.search
      } else if (this.item?.[this.objKey]) {
        return this.item[this.objKey]
      } else {
        return ''
      }
    },
    cssClass() {
      let cssClass = []
      
      if (this.item) {
        cssClass.push('is-selected')
      }
      
      if (this.isFocused) {
        cssClass.push('is-focused')
      }
      
      if (this.showDropDown) {
        cssClass.push('is-searching')
      }

      return cssClass
    }
  },
  mounted() {
    if(this.focusOnMount) this.$refs.input.focus()
  },
  methods: {
    close() {
      this.search = ''
      this.requestFetched = false
      this.$emit('closeInput')
    },
    onBlur() {
      this.$emit('blur')
      this.isFocused = false
    },
    onFocus() {
      this.$emit('focus')
      this.isFocused = true
      if(!this.shouldScroll) return
      let bounds = this.$refs.input.getBoundingClientRect()
      const scrollTop = (document.documentElement.clientWidth - bounds['top']) -30
      document.getElementById('main-content').scrollTo({
        top: scrollTop,
        left: 0,
        behavior: 'smooth'
      })
    },

    selectOnEnter() {
      if(this.items.length) { 
        this.setItem(this.items[this.currentSelected])
      }
    },

    navigate (direction) {
      if(this.items.length) {
        if (direction == 'up') {
          if(this.currentSelected > 0) {
            this.currentSelected--
            document.getElementById(`item-list-item-${this.currentSelected}`).focus()
          }
        } else {
          if(this.currentSelected < this.items.length-1) {
            this.currentSelected++
            document.getElementById(`item-list-item-${this.currentSelected}`).focus()
          }
        }
      }
    },

    onInput(value) {
      this.search = value
      
      if (this.cancelToken) {
        this.cancelToken.cancel("Operation canceled due to new request.")
      }
      this.requestFetched = false
      this.items = []
      delayExecution(400, this.requestData)
    },
    
    async requestData() {
      this.cancelToken = this.$api.CancelToken.source()      
      this.items = await this.dataHandler({ search: this.search, cancelToken: this.cancelToken })
      this.requestFetched = true
      this.cancelToken = null
    },

    setItem(item) {
      this.$emit('update:item', item)
      this.close()
      this.search = ''
      this.requestFetched = false
      this.items = []
    }
  }
}
</script>

<style lang="scss">
  .input-wrapper {
    position: relative;
    .width-machine {
      opacity: 0;
      display: block;
      min-width: 100px;
      min-height: 29px;
    }
    input {
      position: absolute;
      width: 100%;
      left: 0;
      top: 0;
    }
  }
</style>