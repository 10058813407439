import { GREATER_THEN_ONE_NUMBER } from '@/business-logic/constants/helpersEnum'

export default (items, column, weightedBy) => {
  const sumWeighted = items.reduce((acc, item) => {
    const itemColumnValue = item[column] == GREATER_THEN_ONE_NUMBER ? 10 : item[column]
    const itemWeightedByValue = item[weightedBy] == GREATER_THEN_ONE_NUMBER ? 10 : item[weightedBy]

    // console.log('column', column)
    // console.log('itemColumnValue', itemColumnValue)
    // console.log('weightedBy', weightedBy)
    // console.log('itemWeightedByValue', itemWeightedByValue)

    return acc + (itemColumnValue * itemWeightedByValue)
  }, 0)
  const sum = items.reduce((acc, item) =>  {
    const itemWeightedByValue = item[weightedBy] == GREATER_THEN_ONE_NUMBER ? 10 : item[weightedBy]
    return acc + itemWeightedByValue
  }, 0)
  return sumWeighted / sum
}