<template>
  <div class="w-full flex psui-text-small flex-col psui-bg-red-10 rounded-sm p-4 my-2">
    <p class="psui-block">
      To help dev team solving the issue, trigger the QA modal following the steps:
    </p>
    <ul class="list-decimal ml-8 my-2">
      <li>Hold the keyboard "Left Alt or Option (mac)" + "Q"</li>
      <li>Click on the element that contains the issue or fix.</li>
    </ul>
    <i>Tip: sometimes you need to click near the element nor in the element itself.</i>
  </div>
</template>

<script>
export default {
  name: 'QualityAssuranceComponentRequest'
}
</script>

<style>

</style>