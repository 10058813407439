<template>
  <div class="w-full">
    <div class="w-full flex mb-2">
      <div class="w-3/5 pl-8">
        <h3 class="text-header-2 font-bold text-gray02">
          Policy Name
          <button class="focus:outline-none">
            <feather
              size="16"
              type="arrow-down"
              class="text-blue ml-2"
            />
          </button>
        </h3>
      </div>
      <div class="w-1/5">
        <h3 class="text-header-2 font-bold text-gray02">
          City/County
        </h3>
      </div>
      <div class="w-1/5">
        <h3 class="text-header-2 font-bold text-gray02">
          Last Update
        </h3>
      </div>
    </div>
    <div
      v-for="index in 3" 
      :key="index"
      class="w-full flex py-4 border-b border-deepsky-200 last:border-b-0 text-gray-04"
    >
      <div class="w-3/5 pl-8">
        <vue-skeleton-loader
          :width="Math.round((Math.random() * 100) + 200)"
          :height="27"
        />
      </div>
      <div class="w-1/5">
        <vue-skeleton-loader
          :width="Math.round((Math.random() * 100) + 100)"
          :height="27"
        />
      </div>
      <div class="w-1/5">
        <vue-skeleton-loader
          :width="Math.round((Math.random() * 100) + 100)"
          :height="27"
        />
      </div>      
    </div>
  </div>
</template>

<script>
export default {
  name: 'SkeletonPoliciesListTable',
}
</script>