<template>
  <div>
    <div class="flex gap-1 px-6 my-6 ">
      <PsRichTooltip 
        layout="blue" 
        title="New Construction policies are not available yet."
        class="inline-flex"
      >
        <template v-slot:trigger>
          <PsButton
            label="Save to policy"
            icon="add_circle"
            size="medium"
            disabled
          />
        </template>
      </PsRichTooltip>

      <PsButton
        label="Restore default"
        icon="restart_alt"
        size="medium"
        layout="ghost"
        :disabled="$store.getters['assumptions/buildingEstimates/getterCustomJurisdictionBuildingEstimates']({ type_prototype_id: building_type.type_prototypes.map(type_prototype => type_prototype.id) }).length == 0"
        @click="$store.commit('assumptions/buildingEstimates/clearCustomBuildingEstimates')"
      />
    </div>
    <AssumptionsGrowthWidget 
      :specify-by-options="specifyByOptions" 
      :specify-by.sync="specifyBy"
      header-title="Square Footage Growth"
    />
    <!-- <div
      v-if="jurisdiction"
      class="mx-7"
    >
      <h2 class="font-bold psui-text-gray-80 mb-1 psui-text-big">
        Total forecast for 2023-2025
      </h2>
      <ul class="flex justify-between psui-text-small psui-text-gray-60">
        <li>Total units in {{ jurisdiction.climate_zones.length }} climate zones</li>
        <li class="psui-text-gray-80">
          {{ formatUnits($store.getters['assumptions/buildingStocks/getterAllBuildingStocksUnits']({})) }}
        </li>
      </ul>
      <hr class="psui-border-blue-70 opacity-10 mt-7">
    </div> -->
    <div
      class="mx-7"
    >
      <h2 class="font-bold psui-text-gray-80 mb-1">
        Total forecast for 2023-2025
      </h2>

      <ul class="flex justify-between psui-text-small psui-text-gray-60">
        <li>Forecast units for {{ jurisdiction.climate_zones.length }} climate zones</li>
        <li class="psui-text-gray-80">
          {{ formatUnits($store.getters['assumptions/buildingEstimates/getterAllBuildingEstimateUnits'](getBaseFilters)) }}
        </li>
      </ul>

      <hr class="psui-border-blue-70 opacity-10 mt-7">
    </div>
    <div
      v-if="specifyByIncludesClimateZone"
      class="mx-7"
    >
      <AssumptionsBuildingEstimatesClimateZones       
        :climate_zone_prefix="getClimateZonePrefixSelected"
        :climate_zones="jurisdiction.climate_zones"
        :specify-by="specifyBy"
        :filters="{...getBaseFilters, climate_zone_prefix }"        
        @setClimateZonePrefix="climate_zone_prefix = $event"
      />
      <ul class="flex justify-between psui-text-small psui-text-gray-60">
        <li>Forecast units in selected climate zone</li>
        <li class="psui-text-gray-80">
          {{ formatUnits($store.getters['assumptions/buildingEstimates/getterAllBuildingEstimateUnits'](getFilters)) }}
        </li>
      </ul>
      <hr class="psui-border-blue-70 opacity-10 mt-7">
    </div>
    <div 
      :is="getComponent"
      :specify-by="specifyBy"
      :jurisdiction="jurisdiction"
      :climate_zones="jurisdiction.climate_zones" 
      :type_prototypes_ids="type_prototypes_ids"
      :type_prototypes="getTypePrototypeByBuildingType"
      :climate_zone_prefix="getClimateZonePrefixSelected"
      :filters="getFilters"
      class="mx-7"
    />
  </div>
</template>

<script>
import AssumptionsBuildingEstimatesClimateZones from '../AssumptionsBuildingEstimatesClimateZones.vue'
import AssumptionsNonResidentialNewBuildingTypes from './AssumptionsNonResidentialNewBuildingTypes.vue'
import AssumptionsNonResidentialNewOverall from './AssumptionsNonResidentialNewOverall.vue'
import AssumptionsNonResidentialNewYears from './AssumptionsNonResidentialNewYears.vue'
import AssumptionsGrowthWidget from '../AssumptionsGrowthWidget.vue'
import { RESIDENTIAL_NEW_SPECIFICATIONS, BUILDING_ESTIMATES_BASE_YEARS } from '@/modules/app/assumptions/shared/enums'

export default {
  name: 'AssumptionsNonResidentialNew',
  components: { 
    AssumptionsGrowthWidget, 
    AssumptionsNonResidentialNewBuildingTypes, 
    AssumptionsBuildingEstimatesClimateZones 
  },
  props: {
    jurisdiction: [ Boolean, Object ],
    building_type: [ Boolean, Object ],
    type_prototypes: [ Boolean, Array ]
  },
  data: () => ({
    specifyByOptions : RESIDENTIAL_NEW_SPECIFICATIONS,
    specifyBy: [],
    climate_zone_prefix: false
  }),
  computed: {
    getComponent() {
      if (this.specifyBy.length === 0 || this.specifyByIncludesOnlyClimateZone) {
        return AssumptionsNonResidentialNewOverall
      } else if (this.specifyByIncludesOnlyYear || (this.specifyByIncludesYear && !this.specifyByIncludesBuildingType) ) {
        return AssumptionsNonResidentialNewYears
      } else if (this.specifyByIncludesBuildingType) {
        return AssumptionsNonResidentialNewBuildingTypes
      } else {
        return false
      }
    },
    //old
    // type_prototypes_ids() {
    //   return this.building_type ? this.building_type.type_prototypes.map(i => i.id ) : false
    // },
    type_prototypes_ids(){
      return this.getTypePrototypeByBuildingType.map(tp => tp.id)
    },
    specifyByIncludesClimateZone() {
      return this.specifyBy.includes(RESIDENTIAL_NEW_SPECIFICATIONS.CLIMATE_ZONE.key)
    },
    specifyByIncludesOnlyClimateZone() {
      return this.specifyBy.length === 1 && this.specifyBy.includes(RESIDENTIAL_NEW_SPECIFICATIONS.CLIMATE_ZONE.key)
    },
    specifyByIncludesBuildingType() {
      return this.specifyBy.includes(RESIDENTIAL_NEW_SPECIFICATIONS.BUILDING_TYPE.key)
    },
    specifyByIncludesYear() {
      return this.specifyBy.includes(RESIDENTIAL_NEW_SPECIFICATIONS.YEAR.key)
    },
    specifyByIncludesOnlyYear() {
      return this.specifyBy.length === 1 && this.specifyBy.includes(RESIDENTIAL_NEW_SPECIFICATIONS.YEAR.key)
    },
    getBaseFilters() {
      return { type_prototype_id: this.type_prototypes_ids, year: BUILDING_ESTIMATES_BASE_YEARS }
    },
    getFilters() {
      const filters = { ...this.getBaseFilters }
      if (this.getClimateZonePrefixSelected) filters.climate_zone_prefix = this.getClimateZonePrefixSelected
      return filters
    },
    getClimateZonePrefixSelected() {
      if (!this.specifyByIncludesClimateZone) return false
      if(!this.climate_zone_prefix) return this.jurisdiction.climate_zones[0].prefix
      return this.climate_zone_prefix
    },
    getTypePrototypeByBuildingType(){
      return this.type_prototypes.filter(tp => tp.building_type_id === this.building_type.id)
    },
    getAssumptionsDrawerOptions(){
      return this.$store.getters['assumptions/getterAssumptionsDrawerOptions']
    },
    getterLastClimateZonesVisited(){
      return this.$store.getters['lastClimateZonesVisited']
    }
  },
  mounted(){
    if(this.getAssumptionsDrawerOptions.isEditingFromChart) {
      this.specifyBy.push(this.specifyByOptions["BUILDING_TYPE"].key, this.specifyByOptions["CLIMATE_ZONE"].key)
      this.climate_zone_prefix = this.getterLastClimateZonesVisited[0]["prefix"]
    }
  },
  methods: {
    openAssumptionsModal() {
      const eventName = 'openAssumptionsModal'
      const eventPayload = 'ESTIMATES'
      this.$eventBus.$emit(eventName, eventPayload)
      this.$store.commit('general/setRedirectTo', { route: this.$route.path, event: { name: eventName, payload: eventPayload } })
    }
  }
}
</script>

<style lang="scss" scoped>
  
  ::v-deep .psui-el-tooltip {
    flex-grow: 1;
    .psui-el-tooltip-wrapper {
      .psui-el-tooltip-dialog {
        width: 180px;
        z-index: 50;
      }
    }

    .psui-el-tooltip-trigger {
      display: flex;

      button {
        flex-grow: 1;
        display: flex;
        
      }
    }
  }

</style>
