var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative flex items-center",class:[ 
    _vm.position === 'right' ? 'flex-row-reverse justify-end' : '',
    _vm.visible ? '' : 'opacity-100-childrens-on-hover' ]},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.tooltip),expression:"tooltip"}],staticClass:"icon material-icons-round psui-text-h5 psui-leading-none order-2",class:( _obj = {
      'opacity-0 transition-all duration-300 ease-in' : !_vm.visible,
      'ml-2 mr-1 right-0' : _vm.position === 'right',
      'absolute' : _vm.absolute,        
      '-ml-4' : _vm.absolute && _vm.position === 'left',        
      '-mr-5' : _vm.absolute && _vm.position === 'right',
      'cursor-pointer mt-.5 transition-all' : true
    }, _obj[_vm.iconClass] = true, _obj ),attrs:{"size":_vm.iconSize,"type":"info"},on:{"click":function($event){return _vm.$emit('icon-click')}}},[_vm._v("info")]),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }