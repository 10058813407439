<template>
  <div class="logo-state-wrapper">
    <a
      href="https://localenergycodes.com"
      target="_blank"
      title="Statewide Reach Codes Program"
    >
      <img
        v-if="isDarkBackground"
        src="/logo/logo-state-program-dark-background.png"
        alt="California Energy Codes & Standards Logo"
      >
      <img
        v-else
        src="/logo/logo-state-program-light-background.png"
        alt="California Energy Codes & Standards Logo"
      >
    </a>
  </div>
</template>

<script>
export default {
  props: {
    isDarkBackground: {
      type: Boolean,
      default: true
    }
  },
}
</script>

<style lang="scss" scoped>
  .logo-state-wrapper {
    a {
      display: inline-block;
    }
  }
</style>