<template>
  <div class="w-full">
    <PsDataTable 
      :header="getTableHeaders"
      :data="getTableRows"
      :footer="getTableFooter"
      :type="'simple'"
      align="right"
      class="psui-w-full psui-mt-3"
    />
  </div>
</template>

<script>
export default {
  name: 'TypePrototypeExistingTable',
  props: ['getClimateZoneSelected', 'type_prototype'],
  computed: {
    getTableHeaders() {
      const headers = []
      headers.push(`Climate Zone`)
      this.$store.getters['globalEntities/TypeVintage/getterGlobalTypeVintages'].forEach(type_vintage => headers.push(type_vintage.title))
      headers.push(`Total`)
      return headers
    },

    getTableRows() {
      return this.getClimateZoneSelected.map((climate_zone) => {
        const row = this.$store.getters['globalEntities/TypeVintage/getterGlobalTypeVintages'].reduce((acc, type_vintage) => {
          acc[type_vintage.title] = this.formatUnits(this.$store.getters['assumptions/buildingStocks/getterAllBuildingStocksUnits']({
            type_vintage_id: type_vintage.id,
            climate_zone_prefix: climate_zone.prefix,
            type_prototype_id: this.type_prototype.id
          }))
          return acc
        }, {})
        row[`Climate Zone`] = climate_zone.prefix
        row[`Total`] = this.formatUnits(this.$store.getters['assumptions/buildingStocks/getterAllBuildingStocksUnits']({
          climate_zone_prefix: climate_zone.prefix,
          type_prototype_id: this.type_prototype.id
        }))
        return row
      })
    },

    getTableFooter() {
      const footer = this.$store.getters['globalEntities/TypeVintage/getterGlobalTypeVintages'].map((type_vintage) => {
        return this.formatUnits(this.$store.getters['assumptions/buildingStocks/getterAllBuildingStocksUnits']({
          type_vintage_id: type_vintage.id,
          type_prototype_id: this.type_prototype.id,
          climate_zone_prefix: this.getClimateZoneSelected.map(cz => cz.prefix)
        }))
      })
      footer.push(this.formatUnits(this.$store.getters['assumptions/buildingStocks/getterAllBuildingStocksUnits']({
        type_prototype_id: this.type_prototype.id,
        climate_zone_prefix: this.getClimateZoneSelected.map(cz => cz.prefix)
      })))
      footer.unshift(`All`)
      return footer
    }
  }
}
</script>