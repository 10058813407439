<template>
  <div class="w-full flex justify-center">
    <div class="flex items-center mt-10 p-2">
      <div class="flex flex-col items-start">
        <h1 class="text-header-6 text-bluebrand font-bold flex-shrink-0">
          Please log in to view your saved policies.
        </h1>
        <p class="text-gray03 text-header-3 mt-6">
          New here? Register to create policies and view their impact on your City/County.
        </p>
        <PsButton
          class="mt-6"
          label="REGISTER/LOG IN"
          size="medium"
          @click="$eventBus.$emit('openModalUserSign')"
        />
      </div>
      <div class="w-1/2 p-2">
        <img
          src="/images/no-policies.svg"
          alt=""
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PoliciesListNoUserLoggedIn'
}
</script>

<style>

</style>