<template>
  <div class="w-full">
    <div class="w-full flex justify-end">
      <router-link
        :to="{ name: 'AdminStudyGroupCreate' }"
      >
        <PsButton
          label="Create study group"
          size="big"
          icon="add"
        />
      </router-link>
    </div>

    <table class="table table-bordered mt-6 whitespace-no-wrap">
      <thead>
        <tr>
          <th>Study Group</th>
          <th>Studies</th>
          <th>Study Type</th>
          <!-- <th>ID</th>  -->
          <!-- <th>Created at</th> -->
          <th class="psui-w-48">
            Actions
          </th>
        </tr>
      </thead>
      <tbody>
        <tr 
          v-for="study_group in study_groups" 
          :key="study_group.id" 
          class="cursor-move opacity-100-all-childrens-on-hover"

          draggable="true"
          @dragstart="dragStart(index)"
          @dragover.prevent
          @dragend="dragEnd"
          @drop="dragFinish(index, items)"
        >
          <td>            
            <router-link 
              :to="{ name: 'AdminStudyGroupEdit', params: { study_group_id: study_group.id}}"
            >
              {{ study_group.title }}
            </router-link>
          </td>
          <td>{{ study_group.studies.length }}</td>
          <td>{{ study_group.study_type.title }}</td>
          <!-- <td class="opacity-0">{{study_group.id}}</td>
          <td class="opacity-0">{{study_group.created_at}}</td> -->
          <td class="psui-flex psui-space-x-2">
            <router-link 
              :to="{ name: 'AdminStudyGroupEdit', params: { study_group_id: study_group.id}}"
            >
              <PsButton 
                label="Edit" 
                size="medium"
                icon="edit"
              />
            </router-link>

            <PsButton 
              layout="caution"
              label="Delete"
              size="medium"
              icon="delete"
              @click="selectStudyGroupToDelete(study_group)"
            />
          </td>
        </tr>
      </tbody>
    </table>

    <BaseModal
      ref="deleteStudyGroupModal"
      modal-dialog-classes="w-full max-w-sm"
      title="Attention"
    >
      <div
        v-if="studyGroupSelected"
        class="w-full flex flex-col items-start mt-6"
      >
        <p class="text-base text-gray04">
          Delete <span class="text-red font-bold">{{ studyGroupSelected.title }}</span> study group and remove the relationship between the studies that are attached with this group?
        </p>

        <PsButton
          layout="caution"
          class="psui-mt-6"
          label="Confirm delete"
          size="big"
          @click="deleteStudyGroup()"         
        />
      </div>
    </BaseModal>
  </div>
</template>

<script>
export default {
  name: 'AdminStudyGroupList',
  data() {
    return {
      study_groups: [],
      studyGroupSelected: null,
      search: '',
      offset: 0,
      limit: 12,
    }
  },
  mounted() {
    // this.dragAndDropUrl = `/types/sync_order/${this.getTypeSelected}`
    this.getStudyGroups()
  },
  methods: {
    async getStudyGroups() {

      const query = `
        {
          study_groups (
            orderBy: [{column: "id", order: "asc"}]
          ){
            id
            title
            created_at
            study_type {
              id
              title
            }
            studies {
              title
            }
          }
        }
      `
      const { data } = await this.$graphql({ query })

      this.study_groups = data.study_groups
    },


    selectStudyGroupToDelete(study) {
      this.studyGroupSelected = study
      this.$refs.deleteStudyGroupModal.showModal = true
    },

    deleteStudyGroup() {
      this.$api.delete(`/api/study_group/delete/${this.studyGroupSelected.id}`, { loader: true})
        .then(() => {
          this.$refs.deleteStudyGroupModal.showModal = false
          this.getStudyGroups()
        })
    }
  }
}
</script>

