<template>
  <transition
    enter-active-class="transition ease-out duration-200 transform"
    enter-class="opacity-0 translate-x-full"
    enter-to-class="opacity-100"
    leave-active-class="transition ease-in duration-200 transform"
    leave-class="opacity-100"
    leave-to-class="opacity-0 translate-x-full"
    mode="out-in"
  >
    <div 
      v-if="checkDrawerVisibility()"
      class="assumptions-drawer origin-right psui-relative right-0 top-0 h-screen bg-white shadow-lg flex flex-col z-60 flex-shrink-0"
      :class="{ 'border-red opacity-60' : isUpdating }"
    >
      <div class="btn-trigger">
        <PsButton
          icon="chevron_right"
          size="small"
          text-color="gray-50"
          @click="$store.commit('assumptions/toggleDrawer')"
        />
      </div>

      <div class="wrapper scrolling-touch overflow-y-scroll over">
        <JurisdictionWideAssumptions />
      </div>
    </div>
  </transition>
</template>

<script>
import JurisdictionWideAssumptions from '@/modules/app/assumptions/shared/JurisdictionWideAssumptions.vue'
import { mapGetters } from 'vuex'
export default {
  name: 'AssumptionsDrawer',
  components: { JurisdictionWideAssumptions },
  computed: {
    ...mapGetters('assumptions',['getterAssumptionsDrawerOptions']),
    ...mapGetters('assumptions', ['isUpdating']),
  },
  mounted() {
    this.$eventBus.$on('openAssumptionDrawerFromAppBoot', this.openAssumptionDrawerFromAppBoot)
  },
  beforeDestroy() {
    this.$eventBus.$off('openAssumptionDrawerFromAppBoot')
  },
  methods: {
    checkDrawerVisibility() {
      const allowedRoutes = ['/policies', '/jurisdiction']
      return this.getterAssumptionsDrawerOptions.visibility && allowedRoutes.filter((route) => this.$route.path.includes(route)).length
    },
    openAssumptionDrawerFromAppBoot(drawerOptions) {
      this.$store.commit('assumptions/setDrawerOptions', drawerOptions)
    }
  }
}
</script>

<style lang="scss" scoped>
  .assumptions-drawer {
    -ms-overflow-style: none;
    scrollbar-width: none;
    width: 350px;

    .wrapper {
      padding-bottom: 60px;
      height: 100%;
    }
    

    .btn-trigger {
      position: absolute;
      top: 50px;
      left: -12px;

      ::v-deep .psui-el-button {
        padding: 0;
        background: white;
        border: 1px solid #79849038;
        border-radius: 50%;
        width: 24px;
        height: 24px;
        &:hover {
          background: #318FAC;
          > i {
            color: white;
          }
        }

        > i {
          color: #798490;
          margin: 0;
        }
      }
    }
     
  }

  
</style>
