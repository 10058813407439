import GraphQL from '@/util/GraphQL'
export default class BuildingStockApiService {

  static getJurisdictionUnits(jurisdiction_id) {
    const query = `
    {
      building_stocks (
        where: [
          { column: "jurisdiction_id", operation: "=", value: "${parseInt(jurisdiction_id)}" }      
        ]
      ) {
        jurisdiction_id
        climate_zone_prefix
        type_prototype_id
        type_vintage_id
        units
      }
    }
  `
  return GraphQL({ query })
    .then(({ data }) => {
      return data.building_stocks
    })
  }

}