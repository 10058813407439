import Api from '@/util/Api'

export default async (payload) => {

  const { query, caller = '' } = payload
  const response = await Api.post(`/api/graphql${caller ? `?caller=${caller}` : ``}`, {
    query: query
  }, { ...payload })

  return response.data
}