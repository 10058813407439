<script>
import RenderHtml from "./RenderHtml.vue"
import AppUtilityRatesTable from '@/components/general/AppUtilityRatesTable'
import ForecastAssumptionsWidget from '@/modules/app/jurisdiction/study-results/ForecastAssumptionsWidget'
export default {
	components: {
		AppUtilityRatesTable,
		ForecastAssumptionsWidget
	},
	...RenderHtml,
}
</script>