<template>
  <BaseDrawer
    ref="baseDrawer"
    title="FAQ's"
  >
    <div class="w-full w-full">
      <div
        v-if="!questions"
        class="w-full flex align-left mt-6"
      >
        <AppLoader text="Loading" />
      </div>
      <ul v-else>
        <li 
          v-for="({id, title, content }) in questions"
          :key="id"
          @click="expand(id)"
        >
          <icon-arrow-vertical :direction="id === questionSelected" />
          <h2>{{ title }}</h2>
          <div 
            v-show-slide="id == questionSelected"
            class="content"
            v-html="content"
          />
        </li>
      </ul>
    </div>
  </BaseDrawer>
</template>

<script>
export default {
  name: 'DrawerFaq',
  data() {
    return {
      questions: false,
      questionSelected: null
    }
  },
  mounted() {
    this.$eventBus.$on('openDrawerFaq', () => {
      this.$refs.baseDrawer.showDrawer = true
      this.getFaqs()
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('openDrawerFaq')
  },
  methods: {
    expand(index) {
      this.questionSelected = this.questionSelected === index ? null : index
    },
    closeDrawer() {
      this.showDrawer = false
      setTimeout(() => {
        this.title = ''
        this.content = ''
      }, 500)
    },

    async getFaqs() {
      this.showDrawer = true
      const query = `
        {
          frequent_questions {
            id
            title
            content
          }
        }
      `
      const { data } = await this.$graphql({ query })
      this.questions = data?.frequent_questions
    },
  }
}
</script>

<style lang="scss" scoped>
  ul {
    li {
      padding-left: 45px;
      position: relative;
      img {
        position: absolute;
        left: 0;
      }
      h2 {
        font-size: 24px;
        line-height: 30px;
        margin-bottom: .5em;
        cursor: pointer;
        color: black;
      }
      .content ::v-deep {
        margin-bottom: 2em;
        color: #404040;
        p {
          color: #404040;
        }        
        > p:first-of-type {
          padding-top: 11px;
        }
        > p:last-of-type {
          font-size: 16px;
          line-height: 26px;
          padding-bottom: 2em;
        }
      }
    }
  }
</style>