<template>
  <div :class="wrapperClass">
    <div class="flex items-center">
      <span
        v-if="icon"
        class="mr-2 flex items-center"
        :class="iconClass"
      ><feather
        :type="icon"
        :size="iconSize"
      /></span>      
      <p
        v-if="description"
        class="text-header-2"
        :class="descriptionClass"
      >
        {{ description }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppCardButton',
  props: {
    icon: {
      type: String
    },
    iconClass: {
      type: String,
      default: 'text-deepsky-900'
    },
    wrapperClass: {
      type: String,
      default: 'app-card-button p-2 transition opacity-100 hover:opacity-80'
    },
    iconSize: {
      type: Number,
      default: 24
    },
    description: {
      type: String,
    },
    descriptionClass: {
      type: String,
      default: 'text-ash-600'
    }
  }
}
</script>

<style lang="scss" scoped>

</style>