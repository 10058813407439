<template>
  <feather
    size="16"
    :type="orderBy.order.toLowerCase() == 'asc' ? 'arrow-up' : 'arrow-down'"
    class="text-blue cursor-pointer"
    @click="$emit('setOrderBy', column)"
  />
</template>

<script>
export default {
  name: 'OrderByArrowToggle',
  props: ['orderBy', 'column'],
}
</script>