<template>
  <div class="w-full flex justify-end">
    <BaseModal
      ref="customFieldForm"
      modal-dialog-classes="w-full max-w-sm"
      title="Edit custom field"
    >
      <div class="w-full flex flex-col">
        <InputWithLabel
          v-model="custom_field.item_type"
          class="mt-6"
          :label="'Type (no space allowed)'"
        />
        <InputWithLabel
          v-model="custom_field.item_id"
          class="mt-6"
          :label="'Ref ID (integer)'"
        />

        <vue-json-editor
          v-model="custom_field.data" 
          class="mt-6" 
          :expanded-on-start="true" 
          :mode="'code'" 
          :modes="['code', 'tree']"
        />
      </div>
      <PsButton
        class="mt-6"
        label="Update custom field"
        icon="update"
        :disabled="checkFormHasError"
        @click="update()"
      />
    </basemodal>
  </div>
</template>

<script>
import VueJsonEditor from 'vue-json-editor'
export default {
  name: 'CustomFieldAddKeyForm',
  components: { VueJsonEditor },
  props: ['item_type', 'item_id'],
  data() {
    return {
      custom_field: {
        id: '',
        item_type: '',
        item_id: '',
        data: '{}'
      }
    }
  },
  computed: {
    checkFormHasError() {
      if(
          !this.custom_field.item_type ||
          this.custom_field.item_type.includes(' ')
      ) return true
      return false
    }
  },
  mounted() {
    this.$eventBus.$on('openCustomFieldForm', (id) => {
      this.getCustomField(id)
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('openCustomFieldForm')
  },
  methods: {
    clearItem() {
      this.custom_field =  {
        item_type: '',
        key: '',
        defaultValue: '',
        options: ''
      }
    },
    getCustomField(id) {

      const query = `
        { 
          custom_field (findBy: { column: "id", value: "${id}"}) {
            id
            item_id
            item_type
            data
          }
        }
      `
      this.$graphql({ query })
        .then(({ data }) => {
          this.custom_field = data.custom_field
          this.$refs.customFieldForm.showModal = true
        })
    },

    update() {
      this.$api.post(`/api/custom_field/update/${this.custom_field.id}`, this.custom_field)
        .then(() => {
          this.$refs.customFieldForm.showModal = false
          this.$emit('change')
        })
    }

  }
}
</script>