<template>
  <div>
    <div class="mb-5 border border-deepsky-200 rounded-md flex">
      <div class="flex items-stretch border-r border-deepsky-200 p-5 card-options">
        <WizardToggle
          :item.sync="policy.is_auto_generated"
          true-label="Identify cost-effective measures for me"
          false-label="Let me choose the cost-effective measures"
        />
      </div>
      <div class="text-center bg-white w-full p-4 rounded card-content flex flex-col justify-center items-center">
        <img
          :src="`/images/policy_wizard/${getImage}`"
          width="150"
          class="max-w-full h-auto mb-4 inline-block"
        >

        <transition
          name="fade"
          mode="out-in"
        >
          <div
            v-if="policy.is_flexible_path && policy.is_auto_generated"
            class="w-full flex flex-col justify-center items-center"
          >
            <p class="text-deepsky-900 text-header-3 font-bold mb-3 px-8">
              Based on all cost-effective measures from the latest studies
            </p>
            <div class="text-ash-600 mb-2 text-header-3">
              Target Scores will be obtained from total annual energy savings estimated by a study when combining all compatible cost-effective measures for each building type and vintage.
            </div>
          </div>

          <div
            v-if="policy.is_flexible_path && !policy.is_auto_generated"
            class="w-full flex flex-col justify-center items-center"
          >
            <p class="text-deepsky-900 text-header-3 font-bold mb-3 px-8">
              Let me control which cost-effective measures are used to establish the target scores
            </p>
            <div class="text-ash-600 mb-2 text-header-3">
              Go through results vintage by vintage and choose measures from any study version
            </div>
          </div>

          <div
            v-if="!policy.is_flexible_path && policy.is_auto_generated"
            class="w-full flex flex-col justify-center items-center"
          >
            <p class="text-deepsky-900 text-header-3 font-bold mb-3 px-8">
              We will select all compatible cost-effective measures from the latest study results for you
            </p>
            <div class="text-ash-600 mb-2 text-header-3">
              Require people to install all generally applicable measures that are cost-effective <br>
              Based on results from the latest applicable studies <br>
              You can edit the required measures later
            </div>
          </div>

          <div
            v-if="!policy.is_flexible_path && !policy.is_auto_generated"
            class="w-full flex flex-col justify-center items-center"
          >
            <p class="text-deepsky-900 text-header-3 font-bold mb-3 px-8">
              I’ll review study results to select which cost-effective measures to require
            </p>
            <div class="text-ash-600 mb-2 text-header-3">
              Go through results vintage by vintage for all climate zones and building types covered by my policy <br>
              Control which cost-effective measures people will need to install
            </div>
          </div>
        </transition>

        <a
          target="_blank"
          href="https://intercom.help/explorer-local-energy-codes/en/articles/5701810-establish-target-scores"
          class="underline text-deepsky-500"
        >Learn more</a>
      </div>
    </div>
  </div>
</template>

<script>
import WizardToggle from './WizardToggle.vue'
export default {
  name: 'StepAutoGenerate',
  components: { WizardToggle },
  props: ['policy'],
  computed: {
    getImage() {
      if(this.policy.is_flexible_path && this.policy.is_auto_generated) return 'step_auto_generate_true_with_flexible_path_true.png'
      if(this.policy.is_auto_generated) return 'step_auto_generate_true_with_flexible_path_false.png'
      return 'step_auto_generate_false.png'
    }
  }
}
</script>
<style lang="scss" scoped>
  .card-options {
    min-height: 357.5px;
  }
</style>