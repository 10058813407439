<template>
  <div class="block w-full">
    <div class="relative">
      <VueNumberFormat
        :id="id"
        v-model="childValue"
        class="input-with-label block appearance-none w-full h-full border-b-2 border-gray06 bg-gray06 text-gray02 text-base px-3 pb-2 pt-6 font-normal"
        :class="[inputClass, {'input-has-error' : validation.hasError, 'filled' : value}]"
        :options="options"
        @focus="$emit('focus')"
        @blur="$emit('blur')"
      />
      <label
        v-if="label"
        :for="id"
        class="h-full absolute pin-l top-0 flex items-center text-gray04 text-xs pointer-events-none pl-3"
      >{{ label }}</label>
    </div>
    <p
      v-if="validation.hasError && validation.label"
      class="text-red w-full pl-3"
    >
      {{ validation.label }}
    </p>
  </div>
</template>

<script>
export default {
  name: "NumberFormatInput",
  props: {
    type: {
      type: String,
      default: 'text'
    },
    id: {
      type: String,
      required: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    value: {
      required: true,
    },
    label: {
      type: String,
      required: true
    },
    action: {
      type: Function,
      required: false
    },
    autocapitalize: {
      type: String,
      default: 'sentences'
    },
    autocomplete: {
      type: Boolean,
      default: true
    },
    required: {
      default: false
    },
    validation: {
      type: Object,
      default: () => {
        return { hasError: false, checked: false, filled: false, required: false }
      }
    },
    mask: {
      type: [Boolean, String],
      default: false
    },
    inputClass: {
      default: null
    },
    options: {
      type: Object,
      default() {
        return { prefix: 'US$ ', decimal: '.', thousand: ',' }
      }
    }
  },
  data() {
    return {
      showPassword: false
    }
  },
  computed: {
    getType() {
      if(this.type !== 'password') return this.type
      if(this.type === 'password' && this.showPassword) return 'text'
      return this.type
    },
    childValue: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue )
        this.$emit('change', newValue )
      }
    }
  },
  methods: {
    makeAction() {
      if(this.action && typeof(this.action) == 'function') this.action()
    }
  }
}
</script>

<style>

  .input-with-label + label {
    transition: flex 0.4s ease-out;
  }
  .input-with-label:focus + label {
    color: #4B8CA6 !important;
  }
  .input-with-label:focus + label, .input-with-label.filled + label {
    align-items: flex-start;
    margin-top: 6px;
  }

  .input-with-label:focus:not(.input-has-error) {
    border-color: #4B8CA6 !important;
  }

  .input-with-label.input-has-error + label {
    color: #ff0f4a !important;
  }

  .input-has-error {
    border-color: #ff0f4a !important;
  }
</style>
