
/**
 * projections / city wide impact columns
 */
export default [
  'forecast_units_affected', 
  'forecast_initial_cost',
  'forecast_kwh_savings',
  'forecast_therms_savings',
  'forecast_emissions_savings',
  'forecast_lifecycle_savings'
]