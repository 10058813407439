<template>
  <section>
    <div class="flex flex-col">
      <div class="flex flex-row justify-between items-center pb-2">
        <div class="text-base font-bolder pt-6">
          {{ title }}
        </div>        
        <PsButton 
          label="Add Item" 
          icon="add_circle" 
          icon-position="right"
          size="small"
          @click="addItem"
        />
      </div>
      <ul>
        <li 
          v-for="(item, index) in value" 
          :key="index"
          class="flex flex-row gap-1 border psui-border-gray-20 mb-1 justify-between items-center psui-p-2"
        >
          <div class="flex gap-4 w-full">
            <slot
              :item="item"
              :index="index"
            />
          </div>
          <PsButton 
            label="Delete" 
            icon="delete"
            icon-position="right"            
            size="small"
            @click="removeItem(index)"
          />
        </li>
      </ul>
    </div>
  </section>
</template>

<script>

export default {
  name:'AdminStudyBaseRepeatable',
  props: {
    title: {
      type: String,
      required: true,
    },
    value: {
      type: Array,
      required: true,
      default: () => []
    },
    baseItemValue: {
      type: Object,
      required: true
    }
  },
  methods: {
    addItem(){
      this.$emit('input', [ ...this.value , this.baseItemValue ])
    },
    removeItem(itemIndex) {
      this.$emit('input', this.value.filter((item, index) => index !== itemIndex))
    }
  }
}
</script>

<style>

</style>