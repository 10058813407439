<template>
  <div 
    class="vintage-card w-full h-auto flex flex-col justify-center items-start space-y-1 rounded-md border border-dashed border-deepsky-400 px-6 py-2 relative"
    :class="[disabled ? 'disabled opacity-50 pointer-none cursor-default' : 'cursor-pointer']"
    @click="storeClearCustomCombination()"
  >
    <div class="flex items-center">
      <h4 class="text-14 font-bold text-deepsky-800 mr-2">
        {{ type_vintage.title }}
      </h4>
      <div class="add text-sm  border rounded border-deepsky-300 text-deepsky-450 px-2 font-bold transition-all">
        <feather
          type="plus"
          size="14"
        /> Add Measures
      </div>
    </div>
  </div>
</template>

<script>

import CustomCombinationApiService from '@/services/api/CustomCombinationApiService'

export default {
  name: 'PolicyScopeEmptyCell',
  props: {
    type_prototype : {
      type: Object,
      required: true
    },
    type_vintage: {
      type: Object,
      required: true
    },
    climate_zone: {
      type: Object,
      required: true
    },
    policy: {      
      required: true,
      validator: prop => typeof prop === 'object' || prop === null
    }
  },  
  data() {
    return {
      latest_studies: [],
      latest_vintage: null,
      latest_prototype: null,
      isLoading : false
    }
  },
  computed: {
    disabled() {
      return !this.hasVintageAndPrototype || this.isLoading || !this.ownerPolicyUser
    },
    hasVintageAndPrototype() {
      return (this.latest_vintage  && this.latest_prototype) ? true : false
    },
    ownerPolicyUser() {
      return this.policy && (this.policy.user_id === this.$store.getters.getterLoggedUser?.id) ? true : false
    }    
  },  
  mounted() {
    this.getLatestStudies()
  },
  methods: {

    getLatestStudies() {
      const query = `{
        type_prototype (find: ${this.type_prototype.id}) {
          id
          title
          latest_prototype_by_study_published_at {
            id
            title
            study_id
            study {
              id
              title
              vintages {
                id
                type_vintage_id
              }
            }
          }
        }
      }`

      this.$graphql({ query })
        .then(({ data }) => {
          this.latest_prototype = data.type_prototype.latest_prototype_by_study_published_at ? data.type_prototype.latest_prototype_by_study_published_at : null
          
          const studyVintages = data.type_prototype.latest_prototype_by_study_published_at.study.vintages ?? null
          this.latest_vintage = studyVintages.find(vintage => parseInt(vintage.type_vintage_id) === this.type_vintage.id) ?? null          
          
        })
    },
    storeClearCustomCombination() {
      if (!this.ownerPolicyUser) return
      
      if (this.isLoading) return

      this.isLoading = true
      
      const payload = {
        policy_id: this.policy.id,
        jurisdiction_id:  this.policy.jurisdiction_id,
        climate_zone_raw: this.climate_zone.raw,
        study_id: this.latest_prototype.study_id,
        prototype_id: this.latest_prototype.id,
        vintage_id: this.latest_vintage.id
      }

      CustomCombinationApiService.store(payload)
        .then(({ id } ) => {
          if (id ) this.openPolicyEditCustomCombination(id)
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          this.$appToast({ message: err?.response?.data?.message ?? 'Sorry, try again later' })
        })
    },
    openPolicyEditCustomCombination(custom_combination_id) {
      this.$router.push({ ...this.$route, params: { policy_active_tab: 'edit_policy' }, query: { custom_combination_id }})
    }
  }
}
</script>

<style lang="scss" scoped>
  
  .vintage-card:not(.disabled):hover {
    .add {
      border-color: #82C0D9;
      background-color: #82C0D9;
      color: white;
    }
  }
</style>