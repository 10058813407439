import Vue from 'vue' 
import Vuex from 'vuex'
import packageJson from '@/../package.json'
import moduleGeneral from './general'
import moduleLogin from '@/modules/app/login/vuex'
import modulePolicy from '@/modules/app/policy/vuex'
import moduleComparison from '@/modules/app/comparison/vuex'
import moduleAssumptions from '@/modules/app/assumptions/vuex'
import moduleStudyResults from '@/modules/app/jurisdiction/study-results/vuex'
import moduleTutorial from '@/components/tutorial/vuex'
import moduleGlobalEntities from './global-entities'
import vuexSubscriptions from './subscriptions/index'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

/**
 * Check vuexVersion match with package.json version
 */
const currentVuexVersion = localStorage.getItem('currentVuexVersion')
if(!currentVuexVersion || currentVuexVersion !== packageJson.vuexVersion) {
  localStorage.removeItem('cee_app')
  localStorage.setItem("currentVuexVersion", packageJson.vuexVersion)
}
      
let plugins = [
  createPersistedState({
    key: 'cee_app',
    storage: localStorage,
    paths: ['auth', 'forecast', 'general', 'policy', 'tutorial', 'buildingStocks', 'globalEntities', 'assumptions']
  })
]

const Store = new Vuex.Store({
  modules: {
    auth: moduleLogin,
    general: moduleGeneral,
    policy: modulePolicy,
    comparison: moduleComparison,
    assumptions: moduleAssumptions,
    studyResults: moduleStudyResults,
    tutorial: moduleTutorial,
    globalEntities: moduleGlobalEntities
  },
  plugins
})

vuexSubscriptions(Store)

export default Store
