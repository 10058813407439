<template>
  <tbody>          
    <template v-for="studyDataRow of getStudyDataOrdered">
      <tr 
        :key="studyDataRow.id"
        :class="getTableRowClass(studyDataRow)"
        class="opacity-100-all-opacity-0-childrens-on-hover"
      >
        <td class="sticky z-10 py-2 left-0 flex items-center">
          <ForecastTableRowActions
            v-if="study.study_group.study_type_id === 1"
            :study-data-row="studyDataRow"
            @toggleMeasure="$parent.toggleMeasure(studyDataRow)"  
          />
          <div 
            :title="`RN: ${studyDataRow.study_row_number} | ID: ${studyDataRow.id}`"
            class="title-wrapper flex-grow-1"
          >
            <AppInfoHelper 
              :absolute="true" 
              position="right"                  
              @icon-click="$eventBus.$emit('openDescriptionModal', { type: 'measure', id: studyDataRow.measure.id })"
            >
              <span
                :ref="`measureTitle-measure-${studyDataRow.measure.id}`"
                class="ellipsis overflow-hidden psui-text-gray-80 psui-text-small"
              >
                {{ studyDataRow.measure.title }}
              </span>
            </AppInfoHelper>
          </div>
        </td>
        <template v-for="columnGroup of columnsSelectedForStudy.columnGroups">
          <td
            v-for="column of columnGroup.columns"
            :key="studyDataRow.id + '-' + columnGroup.key + '-' + column.key"
          >
            <ForecastTableContentCell 
              :column="column" 
              :study-data-row="studyDataRow"
              @openProjectionsModal="$emit('openProjectionsModal', { columnKey: column.key, measure: studyDataRow.measure } )"
            />
          </td>
        </template>
      </tr>            
    </template>

    <tr
      v-if="getMeasureCombination"
      class="row-customcombination psui-bg-blue-20 sticky z-10 bottom-0"
    >
      <td class="sticky z-10 left-0 psui-text-small psui-text-blue-60 font-bold">
        <span 
          class="pl-1 psui-flex psui-items-center" 
          v-html="getMeasureCombination.measure.title"
        />
      </td>
      <template v-for="columnGroup of columnsSelectedForStudy.columnGroups">
        <td 
          v-for="column of columnGroup.columns" 
          :key="getMeasureCombination.id + '-' + columnGroup.key + '-' + column.key"
          class="py-3 psui-text-small psui-text-blue-60"
        >
          <ForecastTableContentCell 
            :column="column" 
            :study-data-row="getMeasureCombination" 
            :is-custom-combination="true" 
            :prototype="prototype" 
            :vintage="vintage" 
          />
        </td>
      </template>
    </tr>
  </tbody>
</template>

<script>
import ForecastTableRowActions from './ForecastTableRowActions.vue'
import ForecastTableContentCell from './ForecastTableContentCell.vue'
export default {
  name: 'ForecastTableBody',
  components: { ForecastTableContentCell, ForecastTableRowActions },
  computed: {
    expanded() {
      return this.$parent.expanded
    },
    getMeasureCombination() {
      return this.$parent.getMeasureCombination
    },
    getStudyDataOrdered() {
      return this.$parent.getStudyDataOrdered
    },
    study() {
      return this.$parent.study
    },
    vintage() {
      return this.$parent.vintage
    },
    prototype() {
      return this.$parent.prototype
    },
    columnsSelectedForStudy() {
      return this.$parent.columnsSelectedForStudy
    },
  },
  methods: {
    showTootipRowMeasureTitle(measure, type) {
      const widthLimit = type === 'measure' ? 210 : 240
      const element = this.$refs?.[`measureTitle-${type}-${measure.id}`]?.[0] ?? null      
      return element && element.offsetWidth < widthLimit ? false : measure.title
    },
    getTableRowClass(studyDataRow) {
      return {
        'row-disabled' : this.expanded && (!studyDataRow.measure_could_be_combined || !studyDataRow.isCostEffective),
        'row-default' : this.expanded && !studyDataRow.measure_is_added,
        'row-added' : this.expanded && studyDataRow.measure_is_added
      }
    },    
  }
}
</script>

<style lang="scss" scoped>  
  tr.row-disabled ::v-deep td *:not(.opacity-0) {
    &:not(.material-icons) {
      color: #798490;
    }
  }

  .title-wrapper {
    max-width: 235px;
    padding-right: 14px;
  }
</style>