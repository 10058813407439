<template>
  <div class=" p-6">
    <div class="w-full flex flex-wrap">
      <div class="w-full lg:w-1/2 relative">
        <VueSelect 
          v-model="building_stock.jurisdiction_id"
          class="vue-select"
          placeholder="Select a jurisdiction"
          :class="building_stock.jurisdiction_id ? 'filled' : ''"
          label="title"
          :options="jurisdictions"
          :reduce="item => item.id"
        />
        <label>Jurisdiction</label>
      </div>
      <div class="w-full lg:w-1/2 lg:pl-4 relative">
        <VueSelect 
          v-model="building_stock.climate_zone_prefix"
          class="vue-select"
          placeholder="Select a climate zone"
          :class="building_stock.climate_zone_prefix ? 'filled' : ''"
          label="prefix"
          :options="climate_zones"
          :reduce="item => item.prefix"
        />
        <label class="lg:ml-4">Climate zone prefix</label>
      </div>
    </div>
    <div class="w-full flex flex-wrap mt-6">
      <div class="w-full lg:w-1/2 relative">
        <VueSelect 
          v-model="building_stock.type_prototype_id"
          class="vue-select"
          placeholder="Select a prototype"
          :class="building_stock.type_prototype_id ? 'filled' : ''"
          label="title"
          :options="type_prototypes"
          :reduce="item => item.id"
        />
        <label>Prototype</label>
      </div>

      <div class="w-full lg:w-1/2 lg:pl-4 relative">
        <VueSelect 
          v-model="building_stock.type_vintage_id"
          class="vue-select"
          placeholder="Select a vintage"
          :class="building_stock.type_vintage_id ? 'filled' : ''"
          label="title"
          :options="type_vintages"
          :reduce="item => item.id"
        />
        <label class="lg:ml-4">Vintage</label>
      </div>
    </div>
    

    <InputWithLabel
      v-model="building_stock.units"
      class="mt-6"
      :label="'Units'"
      :mask="'####'"
    />

    <hr class="border-b border-gray05 my-6">
    <div>
      <PsButton
        v-if="!$route.params.building_stock_id"
        label="Create"
        icon="add"
        size="big"
        @click="store()"
      />    
      <PsButton
        v-else
        label="Update"
        icon="update"
        size="big"
        @click="update()"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AdminBuildingStockForm',
  data() {
    return {
      jurisdictions: [],
      climate_zones: [],
      type_prototypes: [],
      type_vintages: [],
      building_stock: {
        id: '',
        jurisdiction_id: '',
        climate_zone_prefix: '',
        type_prototype_id: '',
        type_vintage_id: ''
      }
    }
  },
  mounted() {
    if(this.$route.params.building_stock_id) {
      this.getBuildingStock()
    }
    this.getResources()
  },
  methods: {

    async getResources() {
      const query = `
        {
          jurisdictions {
            id
            title
          }
          climate_zones {
            id
            raw
            prefix
          }
          type_prototypes {
            id
            title
          }
          type_vintages {
            id
            title
          }
        }
      `
      const { data } = await this.$graphql({ query })

      this.jurisdictions = data.jurisdictions
      this.climate_zones = data.climate_zones
      this.type_prototypes = data.type_prototypes
      this.type_vintages = data.type_vintages
    },

    async getBuildingStock() {
      const query = `
        {
          building_stock (
            find: ${this.$route.params.building_stock_id}
          ){
            id
            jurisdiction_id
            climate_zone_prefix
            type_prototype_id
            type_vintage_id
            units
          }
        }
      `
      const { data } = await this.$graphql({ query })

      this.building_stock = data.building_stock
    },

    store() {
      this.building_stock.raw = this.raw
      this.$api.post(`/building_stock`, this.building_stock, { loader: true })
        .then(() => {
          this.$toast.success('BuildingStock successfully created')
          this.$router.push({ name: 'AdminBuildingStockList' })
        })
    },

    update() {
      this.building_stock.raw = this.raw
      this.$api.put(`/building_stock/${this.building_stock.id}`, this.building_stock, { loader: true })
        .then(() => {
          this.$toast.success('BuildingStock successfully updated')
          this.$router.push({ name: 'AdminBuildingStockList' })
        })
    }
  }
}
</script>
