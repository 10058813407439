<template>
  <div
    id="study-results-index"
    class="w-full h-full flex flex-col overflow-y-auto"
  >
    <StudyResultsHeader class="sticky top-0 psui-bg-white psui-shadow-elevation-10 z-50" />
    <StudyResultsContent v-if="$route.params.climate_zone_raw" />
    
    <ModalAddToPolicy />
    <StudyResultsProjectionsDrawer />      
    <StudyResultsEditColumnsDrawer />
  </div>
</template>

<script>
import StudyResultsContent from './StudyResultsContent.vue'
import StudyResultsEditColumnsDrawer from './StudyResultsEditColumnsDrawer.vue'
import StudyResultsProjectionsDrawer from '@/modules/app/jurisdiction/study-results/StudyResultsProjectionsDrawer.vue'
import ModalAddToPolicy from '@/modules/app/jurisdiction/study-results/content/general/ModalAddToPolicy.vue'
import { mapGetters } from 'vuex'
import StudyResultsHeader from './StudyResultsHeader.vue'
import JurisdictionApiService from '@/services/api/JurisdictionApiService'

export default {
  name: 'StudyResultsIndex',
  components: {  StudyResultsHeader, ModalAddToPolicy, StudyResultsProjectionsDrawer, StudyResultsEditColumnsDrawer, StudyResultsContent },
  computed: {    
    ... mapGetters(['lastJurisdictionVisited', 'lastClimateZoneVisited'])
  },
  watch: {
    '$route.params.climate_zone_raw' : {
      async handler(raw) {
        if (raw && raw != this.lastClimateZoneVisited?.raw) {
          this.checkClimateZoneParam()
        }
      },
      immediate: true
    }
  },

  mounted() {
    this.checkClimateZoneParam()
  },

  methods: {

    checkClimateZoneParam() {
      const climateZoneBelongsToJurisdiction = this.checkClimateZoneBelongsToJurisdiction(this.$route.params.climate_zone_raw)
      if(climateZoneBelongsToJurisdiction) {
        this.$store.dispatch('setUserLastClimateZonesVisited', { value: [climateZoneBelongsToJurisdiction], context: 'updateLastClimateZoneVisitedFromStudyResults'})
      } else {
        this.checkRouteHasClimateZoneConflictAndNeedRedirect()
      }
    },

    checkClimateZoneBelongsToJurisdiction(climate_zone_raw) {
      return this?.lastJurisdictionVisited?.climate_zones ? this.lastJurisdictionVisited.climate_zones.findInArray({ raw: climate_zone_raw }) : false
    },

    checkRouteHasClimateZoneConflictAndNeedRedirect() {
      JurisdictionApiService.getBySlug(this.$route.params.jurisdiction_slug, 'StudyResultsIndex.checkRouteHasClimateZoneConflictAndNeedRedirect')
        .then(({ jurisdiction }) => {
          const climate_zone = jurisdiction?.climate_zones.findInArray({ raw: this.$route.params.climate_zone_raw })
          if(climate_zone) {
            this.$store.dispatch('setUserLastClimateZonesVisited', { value: [climate_zone], context: 'updateLastClimateZoneVisitedFromStudyResults'})
            if(this.$route.params.climate_zone_raw != climate_zone.raw) {
              this.$router.push({
                name: 'StudyResults',
                params: {
                  jurisdiction_slug: this.lastJurisdictionVisited.slug,
                  climate_zone_raw: climate_zone.raw
                }
              })
            }
          } else {
            this.$router.push({
              name: 'StudyResults',
              params: {
                jurisdiction_slug: this.lastJurisdictionVisited.slug,
                climate_zone_raw: this.lastJurisdictionVisited.climate_zones?.[0]?.raw
              }
            })
          }
        })

    }
  }
}
</script>