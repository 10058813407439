var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full h-full flex flex-col overflow-y-auto psui-bg-gray-10",attrs:{"id":"building-estimates-index"}},[_c('BuildingEstimatesHeader',{ref:"header",staticClass:"psui-sticky psui-top-0 psui-z-40 psui-bg-white psui-shadow-elevation-10",on:{"trigger-download":function($event){return _vm.download($event)}}}),_c('div',{staticClass:"psui-w-full psui-flex psui-flex-col psui-p-8 main-content"},[_c('div',{class:{
        'psui-w-full psui-grid psui-grid-cols-5 psui-gap-6': _vm.getBuildingTypeSelected.slug === _vm.path.RESIDENTIAL_BUILDINGS.slug,
        'psui-w-full': _vm.getBuildingTypeSelected.slug === _vm.path.NON_RESIDENTIAL_BUILDINGS.slug
      }},[(_vm.getBuildingTypeSelected.slug === _vm.path.RESIDENTIAL_BUILDINGS.slug)?_c('div',{staticClass:"--add-to-pdf",class:{
          'psui-col-span-full' : _vm.getterContentContainerWidth < 1000,
          'psui-col-span-2' : _vm.getterContentContainerWidth >= 1000,
        },attrs:{"attr-export-width":((_vm.html2canvasOptions.windowWidth/4) + "px")}},[_c('ExistingUnitsDonutsChart',{attrs:{"get-type-prototypes-with-units":_vm.getTypePrototypesWithUnits}})],1):_vm._e(),_c('div',{class:{
          'psui-col-span-full' : _vm.getterContentContainerWidth < 1000,
          'psui-col-span-3' : _vm.getterContentContainerWidth >= 1000,
        }},[_c('FutureUnitsForecastChart',{attrs:{"get-type-prototypes-with-units":_vm.getTypePrototypesWithUnits,"get-climate-zone-selected":_vm.getClimateZoneSelected,"get-building-type-selected":_vm.getBuildingTypeSelected}})],1)]),_c('div',{staticClass:"psui-w-full psui-mt-6"},[_c('TypePrototypeCardList',{ref:"prototypeCards",attrs:{"get-type-prototypes-with-units":_vm.getTypePrototypesWithUnits,"get-building-stock-total-units":_vm.getBuildingStockTotalUnits,"get-climate-zone-selected":_vm.getClimateZoneSelected,"get-building-type-selected":_vm.getBuildingTypeSelected}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }