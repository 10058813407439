<template>
  <div class="w-full p-6">
    <div class="w-full flex justify-end mb-3">
      <router-link
        :to="{ name: 'AdminTypeFilterCreate', params: { type: getTypeSelected } }"
      >
        <PsButton 
          label="Create Type"
          size="big"
          icon="add"
        />
      </router-link>
    </div>

    <div class="w-full relative">
      <VueSelect 
        v-model="getTypeSelected"
        class="vue-select"
        placeholder="Select a type"
        :class="getTypeSelected ? 'filled' : ''"
        label="label"
        :options="types"
        :reduce="item => item.value"
      />
      <label>Select a type</label>
    </div>

    <table class="table table-bordered mt-6 whitespace-no-wrap">
      <thead>
        <tr>
          <th width="10%">
            ID
          </th>
          <th width="50%">
            Title
          </th>
          <th width="50%">
            Slug (external id)
          </th>
          <th width="15%">
            Items
          </th>
          <th width="15%">
            Edit
          </th>
        </tr>
      </thead>
      <tbody>
        <tr 
          v-for="(item, index) in items"
          :key="item.id"
          draggable="true"
          class="cursor-move"
          @dragstart="dragStart(index)"
          @dragover.prevent
          @dragend="dragEnd"
          @drop="dragFinish(index, items, offset)"
        >
          <td>{{ item.id }}</td>
          <td>{{ item.title }}</td>
          <td>{{ item.slug }}</td>
          <td><span v-if="item[`${getTypeSelected}s`]">{{ item[`${getTypeSelected}s`].length }}</span></td>
          <td class="psui-flex psui-space-x-2">
            <router-link 
              :to="{ name: 'AdminTypeFilterEdit', params: { type: getTypeSelected, type_filter_id: item.id}}" 
            >
              <PsButton 
                label="Edit"
                size="medium"
                icon="edit"
              />
            </router-link>
            <PsButton 
              layout="caution"
              label="Delete"
              size="medium"
              icon="delete"
              @click="selectTypeFilterToDelete(item)"
            />
          </td>
        </tr>
      </tbody>
    </table>

    <BaseModal
      ref="deleteTypeFilterModal"
      modal-dialog-classes="w-full max-w-sm"
      title="Attention"
    >
      <div
        v-if="type_filter_selected"
        class="w-full flex flex-col items-start mt-6"
      >
        <p class="text-base text-gray04">
          Delete Type Filter <span class="text-red font-bold">{{ type_filter_selected.title }}</span>?
        </p>
        <PsButton
          layout="caution"
          class="psui-mt-6"
          label="Confirm delete"
          size="big"
          @click="deleteTypeFilter(type_filter_selected.id)" 
        />
      </div>
    </BaseModal>

    <Paginator
      v-model="offset"
      :length="items.length"
      :limit="limit"
      @getItems="getItems"
    />
  </div>
</template>

<script>
export default {
  name: 'AdminJurisdictionList',
  data() {
    return {
      search: '',
      types: [
        { label: 'Prototype Types', value: 'prototype' },
        { label: 'Vintage Types', value: 'vintage' },
        { label: 'Measure Types', value: 'measure' },
        { label: 'Fuel Types', value: 'fuel' },
      ],
      items: [],
      offset: 0,
      limit: 40,
      type_filter_selected: null,
    }
  },
  computed: {
    getTypeSelected: {
      get() {
        return this.$route.params.type ? this.$route.params.type : 'prototype'
      },
      set(value) {
        this.$router.push({ ... this.$route, params: { type: value }})
        this.getItems()
      }
    },
    getTableToOrdenateItems() {
      return `type_${this.getTypeSelected}s`
    }
  },
  mounted() {
    this.getItems()
  },
  methods: {
    async getItems() {
      const orderByColumn = (['prototype', 'vintage'].includes(this.getTypeSelected)) ? 'order' : 'id'
      const query = `
        {
          type_${this.getTypeSelected}s (
            orderBy: [{column: "${orderByColumn}", order: "asc"}], 
            paginate: { offset: ${this.offset}, limit: ${this.limit}}
          ){
            id
            title
            slug
            ${this.getTypeSelected}s {
              id
              title
            }
          }
        }
      `
      const { data } = await this.$graphql({ query })

      this.items = data[`type_${this.getTypeSelected}s`]
    },

    selectTypeFilterToDelete(item) {
      this.type_filter_selected = item
      this.$refs.deleteTypeFilterModal.showModal = true      
    },

    deleteTypeFilter(type_filter_selected_id) {
      this.$api.delete(`/types/${this.getTypeSelected}/${type_filter_selected_id}`)
        .then(() => {
          this.$toast.success('Filter successfully deleted')
          this.$refs.deleteTypeFilterModal.showModal = false
          this.type_filter_selected = null
          this.getItems()
        })
    },
    callbackDragAndDrop() {
      this.getItems()
    }
  }
}
</script>