<template>
  <div
    v-if="columns.columnGroups"
    class="px-5 flex"
  >
    <div class="wrapper bg-deepsky-100 rounded px-4 py-5">
      <h5 class="text-header-2 text-deepsky-500 font-bold mb-5">
        Other columns available:
      </h5>    
      <ul>
        <li 
          v-for="columnGroup in columns.columnGroups"
          :key="columnGroup.key"
          class="mt-6"
        >
          <p class="text-p-1 font-bold text-ash-500">
            {{ columnGroup.title }}
          </p>
          <ul>
            <li
              v-for="column in columnGroup.columns"
              :key="column.key"
              class="text-header-2 text-ash-600"
            >
              {{ column.title }}
            </li>
          </ul>
        </li>
      </ul>
            
      <button
        class="rounded-sm mt-5 bg-deepsky-400 text-white flex items-center px-5 py-1"
        @click="$emit('edit-columns')"
      >
        <feather
          type="columns"
          :size="16"
          class="mr-2"
        />
        <span class="text-accent-1 font-bold">Edit Columns</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OtherColumnsAvailableTableHelper',
  props: {
    columns: {
      type: Object,
      required: true
    }
  },
}
</script>

<style lang="scss" scoped>
</style>