<template>
  <BaseModal
    ref="baseModal"
    title="QA tickets"
    :disable-close="isLoading"
    modal-dialog-classes="max-w-md"
  >
    <QualityAssuranceComponentRequest v-if="!ticket.componentTreeStructure" />
    <div
      v-else
      class="w-full flex flex-col mt-6 space-y-4"
    >
      <div class="w-full flex space-x-4">
        <PsChips 
          v-for="ticketOption in ticketOptions"
          :key="ticketOption.value"
          type="radio"
          :label="ticketOption.label"
          :checked="ticket.ticketType == ticketOption.value"
          @click="ticket.ticketType = ticketOption.value"
        />
      </div>

      <div 
        v-if="ticket.ticketType"
        class="psui-block psui-text-blue-80 psui-bg-yellow-10 rounded-sm p-4 psui-text-small"
      >
        {{ ticketOptions.findInArray({ value: ticket.ticketType }).description }}
      </div>
      
      <div class="w-full flex flex-col space-y-4">
        <InputWithLabel 
          v-model="ticket.summary"
          label="Please, summarize the error in max 10 words"
        />
      </div>      

      <div class="w-full flex mt-8">
        <PsButton 
          :disabled="getErrors.length || isLoading ? true : false" 
          label="Generate ticket"
          @click="openJiraTicket()"
        />
        <PsRichTooltip
          v-if="getErrors.length"
          layout="red"
          class="mt-2"
        >
          <template v-slot:trigger>
            <PsIcon
              icon="warning"
              color="psui-text-red-20"
            />
          </template>
          <template v-slot:content>
            <p
              v-for="error, index in getErrors"
              :key="index"
            >
              {{ error }}
            </p>
          </template>
        </PsRichTooltip>
      </div>      
    </div>
    <div
      v-if="issuesCreated.length > 0"
      class="psui-pb-4"
    >
      <a 
        v-for="issueKey in issuesCreated" 
        :key="issueKey" 
        :href="`${issuesBaseUrl}${issueKey}`"
        class="psui-block psui-text-red-20 hover:psui-text-blue-20"
        target="_blank"
      >
        {{ issueKey }}
      </a>
    </div>
  </BaseModal>
</template>

<script>
import QualityAssuranceComponentRequest from './QualityAssuranceComponentRequest.vue'
import LogRocket from 'logrocket'

const initTicket = () => ({
  ticketType: null,
  summary: '',
  componentTreeStructure: '',
  logrocket_session_url: false,
})
export default {
  name: 'QualityAssuranceModal',
  components: { QualityAssuranceComponentRequest },
  data() {
    return {
      issueId: 'DDEV-477', // Product Requests > APP - Bugs & Maintenance
      issuesBaseUrl: 'https://epcgo.atlassian.net/browse/',
      ticketOptions: [
        { value: 'Bug', label: 'Bug/Error', description: `Use when something went wrong or is broken on the app.` },
        { value: 'BL', label: 'Business Logic', description: `Use when when you didn't get the results that you were expecting.` },
        { value: 'Design', label: 'Design', description: `Use when something didn't match with the design resources.` },
        { value: 'UX', label: 'UX', description: `Use when some user experience should be changed or rethinked.` },
        { value: 'Copy/Content', label: 'Copy/Content', description: `Use when you want copy updates.` },
      ],
      ticket: initTicket(),
      issuesCreated: [],
      isLoading: false
    }
  },
  computed: {
    getErrors() {
      const errors = []
      if(!this.ticket.ticketType) errors.push(`Select an issue type`)
      if(!this.ticket.componentTreeStructure) errors.push(`To help dev team find the issue, trigger the QA modal holding keyboard "Q" and click on issue element of the screen.`)
      if(!this.ticket.summary || this.ticket.summary.replaceAll('  ', ' ').split(' ').length < 2) errors.push(`Provide a summary with at least 2 words and less than 10`)
      return errors
    }
  },
  mounted() {
    this.$eventBus.$on('openQAModal', (componentTreeStructure = '') => {
      if (this.$refs.baseModal) {
        this.$refs.baseModal.open()
        this.ticket.componentTreeStructure = componentTreeStructure
        this.checkLogRocketSession()
      }
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('openQAModal')
    this.$refs.baseModal.showModal = false
  },
  methods: {

    checkLogRocketSession() {
      LogRocket.getSessionURL(sessionURL => {
        this.ticket.logrocket_session_url = sessionURL
      })
    },

    openJiraTicket() {
      if (this.isLoading) return false

      this.isLoading = true

      const url = 'https://a4030bd9-7fda-4b29-b92a-a8169df6a480.hello.atlassian-dev.net/x1/g41PIXdvkJbdpW43MwgRrGgnWGU'
      const body = {
        summary: `${this.ticket.ticketType} __ ${this.ticket.summary.replace(/"/g, "'")}`,
        projectId: '10011', // https://confluence.atlassian.com/jirakb/how-to-get-project-id-from-the-jira-user-interface-827341414.html
        issueType: '10011', // You can find the ID editing the issue here https://epcgo.atlassian.net/jira/settings/issues/issue-types
        epicSlug: this.issueId,
        description: [
          { key: 'SOURCE_LOGROCKET_URL', value: this.ticket.logrocket_session_url },
          { key: 'SOURCE_COMPONENT_TREE', value: this.ticket.componentTreeStructure },
          { key: 'SOURCE_EXPLORER_URL', value: window.location.href },
          { key: 'SOURCE_EXPLORER_USER', value: this.$store.getters['getterLoggedUser']?.email ?? false },
        ]
      }

      this.$api.post('/api/admin/jira_proxy/store', { url, body })
        .then((response) => {
          if (LogRocket._isInitialized) {
            LogRocket.track('JiraQaCreated', body)
            LogRocket.startNewSession()
          }
          this.$appToast({ message: `Ticket has been created`, type: 'success', duration: 3000 })
          this.issuesCreated.push(response.data.data.key)
          const issueKey = response.data.data.key
          this.isLoading = false
          this.sendSlackMessage({ ...this.ticket }, issueKey)
          this.$set(this, 'ticket', initTicket())
        })
        .catch((err) => {
          console.log(err)
          if (this.ticket.logrocket_session_url) {
            alert(`Something went wrong, please send this link to devs: ${this.ticket.logrocket_session_url}`)          
          } else {
            alert(`Something went wrong`)
          }
          this.isLoading = false
        })
    },

    sendSlackMessage(ticket, key) {

      const message = this.ticket.summary + ` \n Check the issue on: ${this.issuesBaseUrl}${key}`
      const data = {
        channel: 'qa-notifications',
        title: `New QA ticket created on ${this.issueId}`,
        message: message,
      }
      this.$api.post(`/api/admin/slack_proxy/send_message`, data)
    }
  }
}
</script>