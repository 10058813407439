<template>
  <div class="flex items-center">
    <PsTabHeader
      :items="getBuildingTypesItems"
      :selected="getBuildingTypeSelected"
      :key-label="'title'"
      :key-value="'slug'"
      @change="selectItem"
    />
  </div>
</template>

<script>
// import { ASSUMPTIONS_PATHS } from '@/modules/app/assumptions/shared/enums.js'
// const nonResidentialTooltip = 'Soon we will have data on Nonresidential Buildings.'
export default {
  name: 'FilterBuildingType',
  computed: {
    getBuildingTypesItems() {
      return this.getBuildingTypes.map(bt => {
        return {
          ...bt,
          // disabled: bt.slug === ASSUMPTIONS_PATHS.NON_RESIDENTIAL_BUILDINGS.slug ? true : false,
          // tooltip: bt.slug === ASSUMPTIONS_PATHS.NON_RESIDENTIAL_BUILDINGS.slug ? nonResidentialTooltip : false,
        }
      })
    },
    getBuildingTypes() {
      return this.$store.getters['globalEntities/BuildingType/getterGlobalBuildingTypes']
    },
    getBuildingTypeSelected() {
      return this.$store.getters['globalEntities/BuildingType/getterBuildingTypeSelected'](this.$route.fullPath)
    }
  },
  methods: {
    selectItem(item) {
      this.setQueryValue('only_building_type', item.slug)
    }
  }
}
</script>

<style lang="scss" scoped>
    ::v-deep .psui-el-tooltip {
        .psui-el-tooltip-wrapper {
          .psui-el-tooltip-dialog {
            transition-delay: 0.8s;
          }
        }
    }

    ::v-deep .psui-el-tab-header button.status-disabled {
      cursor: pointer;
    }
</style>
