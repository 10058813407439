import AdminBuildingEstimateIndex from './AdminBuildingEstimateIndex'
import baseAdminMeta from '@/modules/admin/baseAdminMeta'

export default [
  {
    name: 'AdminBuildingEstimateIndex',
    path: '/admin/building_estimate',
    component: AdminBuildingEstimateIndex,
    meta: { ...baseAdminMeta, header: false }
  },
]