<template>
  <AppCard class="p-8">
    <div class="grid grid-cols-3 gap-16">
      <div class="col-span-2">
        <div class="flex items-center justify-between mb-6">
          <h2 class="text-header-5 text-bluebrand font-bold ml-2">
            Cost-Effectiveness Studies
          </h2>
        </div>
        <vue-skeleton-loader
          v-for="index in 3"
          :key="index"
          class="mt-2 w-full"
          rounded
          :width="757"
          :height="100"
        />
        <vue-skeleton-loader
          class="mt-3"
          rounded
          :width="200"
          :height="52"
        />
      </div>
      <div class="">
        <vue-skeleton-loader
          rounded
          :width="467"
          :height="155"
        />
      </div>
    </div>
  </AppCard>
</template>

<script>
import AppCard from '@/components/general/AppCard'

export default {
  name: 'SkeletonCostEffectivenessStudies',
	components: { AppCard }
}
</script>