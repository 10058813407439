<template>
  <div class="w-full p-6 mb-20">
    <AppTabHeader      
      :items="tabs"
      :selected.sync="selectedTab"
    />

    <hr class="border-b border-ash-300 my-6">    

    <div v-show="selectedTab === 'Content'">      
      <InputWithLabel
        v-model="measure.title"
        class="mt-6"
        :label="'Base Title'"
      />
      
      <InputWithLabel
        v-model="measure.flexible_path_title"
        class="mt-6"
        :label="'Flexible Path Title'"
      />

      <div class="w-full relative mt-6">
        <VueSelect 
          v-model="measure.type_measures"
          class="vue-select"
          label="title"
          :options="type_measures"
          :reduce="item => item.id"
          :close-on-select="false"
          :multiple="true"
        />
        <label>Measures Type</label>
      </div>


      <ContentEditor
        label="Short Description"
        class="mt-6"
        :content.sync="measure.description"
      />
      
      <ContentEditor
        label="Description"
        class="mt-6"
        :content.sync="measure.content"
      />
    </div>
    
    <div v-show="selectedTab === 'Business Logic'">
      <div class="w-full flex flex-row space-x-3 my-6 items-start">
        <Checkbox
          class="border border-deepsky-200 px-2 rounded-sm mr-2 h-8"
          label="Single measure"
          :value="!measure.is_package"
          checkbox-classes="mb-0"
          type="radio"
          @input="setMeasureIsPackage(false)"
        />
        <Checkbox
          class="border border-deepsky-200 px-2 rounded-sm mr-2 h-8"
          label="Package"
          :value="measure.is_package"
          checkbox-classes="mb-0"
          type="radio"
          @input="setMeasureIsPackage(true)"
        />
      </div>

      <div
        v-if="measure.is_package || measure.measures.length"
        class="w-full relative my-6"
      >
        <VueSelect 
          v-model="measure.measures"
          class="vue-select"
          label="title"
          :options="measures"
          :close-on-select="false"
          :multiple="true"
          @input="checkMeasureIsPackage"
        >
          <template v-slot:option="option">                
            {{ option.id }} - {{ option.title }}
          </template>
          <template #selected-option="option">          
            <span class="text-ash-400 mr-2">{{ option.id }}</span>
            {{ option.title }}
          </template>
        </VueSelect>
        <label>Measures of the {{ measure.title }} Package</label>
      </div>      

      <div class="flex space-x-4">
        <Checkbox
          v-model="measure.include_in_combination"
          :disabled="measure.hide_in_results ? true : false"
          :label="'Include in combination'"
          class="inline-block"
        />

        <Checkbox
          v-model="measure.hide_in_results"
          :label="'Hide in Results'"
          :disabled="measure.include_in_combination ? true : false"
          class="inline-block"
        />

        <Checkbox
          v-model="measure.hide_in_flexible_path"
          :label="'Hide in Flexible Path'"
          class="inline-block"
        />

        <Checkbox
          v-model="measure.is_contingent"
          label="Contingent?"
          class="inline-block"
        />

        <Checkbox
          v-model="measure.is_other_child_measures"
          label="Includes child measure not in study?"
          class="inline-block"
        />
      </div>

      
      <InputWithLabel
        v-if="!measure.include_in_combination"
        v-model="measure.exclude_in_combination_reason"
        :label="'Tooltip to explain the user why this measure is not available to a custom combination'"
        class="mt-4"
      />          

      <div class="w-full relative mt-6">
        <VueSelect 
          v-model="measure.exclude_measures"
          class="vue-select"
          placeholder="Select measures to exclude from measures combined"
          :options="measures"
          :close-on-select="false"
          :multiple="true"
          :reduce="item => item.id"
          label="title"
        >
          <template v-slot:option="option">                
            {{ option.id }} - {{ option.title }}
          </template>

          <template #selected-option="option">          
            <span class="text-ash-400 mr-2">{{ option.id }}</span>
            {{ option.title }}
          </template>
        </VueSelect>
        <label>Exclude measures</label>
      </div>
      
      <div
        v-if="measuresDic && measure.measures && measuresHasExcludedMeasureNotExcluded(measure.measures)"
        class="p-4 border border-red rounded-sm mt-4"
      >
        <h2 class="text-header-3 text-red pb-2">
          Other Suggested Exclusions
        </h2>
        <template v-for="measure in measure.measures">
          <div
            v-if="measureHasExcludedMeasureNotExcluded(measure)"
            :key="measure.id"
            class="mt-2"
          >
            <ul class="border p-4 border-ash-300">
              <li class="pb-2">
                <strong>Source: </strong> {{ measure.title }}
                <span 
                  class="border rounded-sm px-3 py-1 text-accent-2 transition-all hover:bg-black hover:text-white cursor-pointer ml-2"
                  @click="addMeasureToExcludedMeasures(measure.exclude_measures)"
                >
                  Add all
                </span>
              </li>
              <ul class="text-header-2 pl-2">
                <template v-for="excluded_measure_id in measure.exclude_measures">
                  <li
                    v-if="measuresDic[excluded_measure_id] && !measureIsExcluded(excluded_measure_id)"
                    :key="`${measure.id}-${excluded_measure_id}`"
                  >                  
                    <span
                      class="border rounded-sm px-3 py-1 text-accent-2 transition-all hover:bg-black hover:text-white cursor-pointer mr-2"
                      @click="addMeasureToExcludedMeasures([excluded_measure_id])"
                    >
                      Add
                    </span>
                    {{ measuresDic[excluded_measure_id].title }}
                  </li>
                </template>
              </ul>
            </ul>
          </div>
        </template>
      </div>
      <!-- <pre>{{measure.measures}}</pre>
      <pre>{{measuresDic}}</pre> -->
    </div>

    <InputWithLabel
      v-show="selectedTab === 'Others'"
      v-model="measure.slug"
      class="mt-6"
      :label="'Slug (external id)'"
    />    

    <hr class="border-b border-gray05 my-6">

    <button
      class="mt-6 button button-blue04"
      @click="update()"
    >
      Update {{ $route.params.type }}
    </button>
  </div>
</template>

<script>
import AppTabHeader from '@/components/general/AppTabHeader.vue'
import Measure from '@/models/Measure'
export default {
  name: 'AdminStudyItemMeasureForm',
  components: { AppTabHeader },
  data() {
    return {
      tabs : ['Content', 'Business Logic', 'Others'],
      selectedTab: 'Business Logic',
      measure: new Measure(),
      types: [],
      measures: [],
      type_measures: [],
    }
  },
  computed: {
    measuresDic() {
      return this.measures.length > 0 ? this.measures.reduce((prev, cur) => {
        prev[cur.id] = cur
        return prev
      }, {}) : false
    }
  },
  mounted() {
    this.getBaseModel()
    this.getTypes()

    if(this.$route.params.type == 'measure') {
      this.getMeasuresAndTypes()
    }
  },
  methods: {
    async getBaseModel() {
      const query = `
        {
          measure (
            where: [
              { column: "id", value: "${this.$route.params.study_item_id}"}
            ]
          ){
            id
            study_id
            title
            slug
            description
            content
            order
            type_measure_id
            include_in_combination
            exclude_measures
            exclude_in_combination_reason
            hide_in_flexible_path
            hide_in_results
            flexible_path_title
            is_package
            is_contingent
            is_other_child_measures
            measures {
              id
              title
              exclude_measures
            }
            type_measures { 
              id 
              title 
            }
          }
        }
      `
      const { data } = await this.$graphql({ query })

      this.measure = new Measure(data.measure)
    },

    getMeasuresAndTypes() {
      const query = `
        {
          measure(
            where: [{column: "id", value: "${this.$route.params.study_item_id}"}]
          ) {
            study {
              measures (
                orderBy: [{column: "title", order: "ASC" }]
                where: [
                  { column: "id", operation: "<>", value: "${this.$route.params.study_item_id}"}
                ]
              ) {
                id
                title
                exclude_measures
              }
            }
          }
          type_measures(
            orderBy: [{column: "title", order: "ASC"}]
          ) {
            id
            title
          }
        }
      `
      this.$graphql({ query })
        .then(({ data }) => {
          this.measures = data.measure.study.measures
          this.type_measures = data.type_measures
        })
    },

    async getTypes() {      
      const query = `
        {
          type_measures{
            id
            title
          }
        }
      `
      const { data } = await this.$graphql({ query })
      this.types = data[`type_measures`]

    },

    update() {
      this.$api.put(`/studies/${this.measure.study_id}/measure/${this.$route.params.study_item_id}`, this.measure, { loader: true })
        .then(() => {
          this.$toast.success('Item updated successfully')
          this.$router.go(-1)
        })
    },

    setMeasureIsPackage(is_package) {
      this.measure.is_package = is_package
      if(!is_package) this.measure.measures = []
    },

    checkMeasureIsPackage() {
      if(this.measure.measures.length) {
        this.measure.is_package = true
      } else {
        this.measure.is_package = false
      }
    },

    measuresHasExcludedMeasureNotExcluded(measures) {
      if (!measures) return false
      
      for(const measure of measures) {
        if (this.measureHasExcludedMeasureNotExcluded(measure)) {
          return true
        }
      }

      return false
    },

    measureHasExcludedMeasureNotExcluded(measure) {
      if (!measure.exclude_measures) return false
      
      for ( const measure_id of this.sanitizeMeasureIds(measure.exclude_measures)) {        
        if (!this.measureIsExcluded(measure_id)) {
         return true
        }
      }

      return false
    },

    measureIsExcluded(measureID) {
      return this.measure.exclude_measures.includes(measureID) ? true : false
    },

    addMeasureToExcludedMeasures(measure_ids) {
      const sanitize_measure_ids = this.sanitizeMeasureIds(measure_ids)
      this.$set(this.measure, 'exclude_measures', [...new Set([...this.measure.exclude_measures, ...sanitize_measure_ids])])
    },

    sanitizeMeasureIds(measure_ids) {
      return measure_ids.filter((measure_id) => this.measuresDic[measure_id] ? true : false )
    }
  }
}
</script>

<style lang="scss">
  .vs__dropdown-menu {
    border-bottom: 1px solid gray !important;
    border-left: 1px solid gray !important;
    border-right: 1px solid gray !important;
    // box-shadow: 1px 1px 1px 1px gray;
  }
</style>