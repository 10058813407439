<template>
  <div class="w-full px-4 sm:px-10 py-7">
    <PoliciesListUserHasNotLoggedIn v-if="!getUser" />
    <PoliciesListUserPolicies
      v-else
    />
    
    <!-- <ModalUserSign @onLoginSuccess="getUserPolicies" /> -->
  </div>
</template>

<script>
import PoliciesListUserPolicies from './PoliciesListUserPolicies.vue'
import PoliciesListUserHasNotLoggedIn from './PoliciesListUserHasNotLoggedIn.vue'

export default {
  name: 'PoliciesList',
  components: { PoliciesListUserHasNotLoggedIn, PoliciesListUserPolicies },
  computed: {
    getUser() {
      return this.$store.state.auth.loggedUser
    }
  },
  mounted() {
    this.$store.dispatch('policy/getAllUserPolicies')
  }
}
</script>

<style lang="scss" scoped>
.actions-wrapper:hover {
  .policy-title {
    font-weight: bold;
    color: #4B8CA6 !important;
  }
}
</style>