<template>
  <BaseModal
    ref="baseModal"
    modal-dialog-classes="max-w-md"
  >
    <div
      v-if="study"
      class="w-full w-full"
    >
      <h4 class="w-full text-header-6 font-bold text-deepsky-900 text-left font-normal">
        A newer version of this study is available in PDF
      </h4>
      <div class="rounded-sm border border border-deepsky-300 bg-deepsky-100 px-6 py-4 mt-6 flex items-center">
        <div>
          <p class="text-accent-1 text-ash-500 mb-2">
            Study Update
          </p>
          <p class="text-p-2 font-bold text-deepsky-500">
            {{ study.title }}
          </p>
          <p
            v-if="study.released_at"
            class="text-p-2 text-ash-500"
          >
            Released on {{ formatDate({ date: study.released_at, format: 'MMMM D, YYYY', from: 'mm/dd/yyyy' }) }}.
          </p>
        </div>
        <div
          v-if="study.source_pdf"
          class="flex-shrink-0 items-center flex-end ml-auto"
        >
          <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            class="cursor-pointer transition-all hover:opacity-90"
            xmlns="http://www.w3.org/2000/svg"
            @click="openPdf()"
          >
            <rect
              width="48"
              height="48"
              rx="24"
              fill="#82C0D9"
            />
            <path
              d="M34 24V12H14V36H27"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M30 32.5L34 36.5M34 36.5L38 32.5M34 36.5V28"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M19.2924 19.403C19.6004 19.403 19.8221 19.3295 19.9574 19.1825C20.0927 19.0355 20.1604 18.8302 20.1604 18.5665C20.1604 18.4498 20.1429 18.3437 20.1079 18.248C20.0729 18.1523 20.0192 18.0707 19.9469 18.003C19.8769 17.933 19.7871 17.8793 19.6774 17.842C19.5701 17.8047 19.4417 17.786 19.2924 17.786H18.6764V19.403H19.2924ZM19.2924 16.9005C19.6517 16.9005 19.9597 16.9437 20.2164 17.03C20.4754 17.114 20.6877 17.2307 20.8534 17.38C21.0214 17.5293 21.1451 17.7055 21.2244 17.9085C21.3037 18.1115 21.3434 18.3308 21.3434 18.5665C21.3434 18.8208 21.3026 19.0542 21.2209 19.2665C21.1392 19.4788 21.0144 19.6608 20.8464 19.8125C20.6784 19.9642 20.4649 20.0832 20.2059 20.1695C19.9492 20.2535 19.6447 20.2955 19.2924 20.2955H18.6764V22H17.4934V16.9005H19.2924Z"
              fill="white"
            />
            <path
              d="M26.5706 19.4485C26.5706 19.8172 26.5064 20.1578 26.3781 20.4705C26.2521 20.7808 26.0736 21.0503 25.8426 21.279C25.6116 21.5053 25.3328 21.6827 25.0061 21.811C24.6818 21.937 24.3213 22 23.9246 22H21.9436V16.9005H23.9246C24.3213 16.9005 24.6818 16.9647 25.0061 17.093C25.3328 17.2213 25.6116 17.3987 25.8426 17.625C26.0736 17.8513 26.2521 18.1208 26.3781 18.4335C26.5064 18.7438 26.5706 19.0822 26.5706 19.4485ZM25.3596 19.4485C25.3596 19.1965 25.3269 18.969 25.2616 18.766C25.1963 18.563 25.1018 18.3915 24.9781 18.2515C24.8568 18.1092 24.7074 18.0007 24.5301 17.926C24.3528 17.849 24.1509 17.8105 23.9246 17.8105H23.1336V21.09H23.9246C24.1509 21.09 24.3528 21.0527 24.5301 20.978C24.7074 20.901 24.8568 20.7925 24.9781 20.6525C25.1018 20.5102 25.1963 20.3375 25.2616 20.1345C25.3269 19.9315 25.3596 19.7028 25.3596 19.4485Z"
              fill="white"
            />
            <path
              d="M28.4588 17.8105V19.102H30.2018V20.0155H28.4588V22H27.2688V16.9005H30.5518V17.8105H28.4588Z"
              fill="white"
            />
          </svg>
        </div>
      </div>
      
      <p
        v-if="study.version_updated_changes"
        class="text-p-2 text-ash-600 mt-5"
      > 
        <span 
          class="flex items-center cursor-pointer" 
          :class="{ 'font-bold text-ash-700' : showChanges }"
          @click="showChanges = !showChanges" 
        >
          <feather
            type="info"
            class="text-deepsky-500 mr-2"
            :size="20"
          />
          What changed? 
        </span>
      </p>

      <RenderHtmlExtended
        v-if="showChanges"
        class="app-drawer-content-styles mt-5"
        :html="study.version_updated_changes"
      />
      
      <div class="line" />
      <p class="text-header-3 font-bold text-deepsky-900 mt-2">
        Soon you'll have access through Cost-Effectiveness Explorer. 
      </p>
      <p class="mt-4 text-p-2 text-ash-600">
        <span v-if="study.expected_at">Expected {{ formatDate({ date: study.expected_at, format: 'MMMM D, YYYY', from: 'mm/dd/yyyy' }) }}. </span>
        For assistance incorporating the updated results into your policy, <a
          href="https://localenergycodes.com/content/contact-us"
          target="_blank"
          title="Click to get in touch!"
        >please contact the reach code team</a>.
      </p>
    </div>
  </BaseModal>
</template>

<script>
import RenderHtmlExtended from '@/components/general/RenderHtmlExtended.vue'
export default {
  name: 'StudyPDFVersionModal',
  components: { RenderHtmlExtended },
  data: () => ({
    study: false,
    showChanges: false
  }),
  mounted() {    
    this.$eventBus.$on('openNewStudyVersionModal', ({ study }) => {
      this.study = study
      this.showChanges = false
      this.$refs.baseModal.open()
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('openNewStudyVersionModal')
  },
  methods: {
    openPdf() {
      window.open(this.study.source_pdf)
    }
  },
}
</script>

<style scoped lang="scss">
  .line {
    padding-top: 24px;
    padding-bottom: 24px;
    &:before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      border: 1px solid #E3EEF2;
    }
  }
</style>