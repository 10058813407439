<template>
  <div
    :id="`forecast-prototype-${ prototype.id }`" 
    class="w-full flex-col pb-20"
  >
    <div class="mb-10 flex flex-wrap text-bluebrand items-center">
      <h4 class="w-auto mr-3">
        <span class="text-header-4 text-deepsky-800 font-bold">{{ study.title }}</span>
        <span 
          v-if="study.released_at"
          class="text-header-3 text-ash-500 ml-3 cursor-pointer transition hover:opacity-80"
          @click="$eventBus.$emit('openStudyGroupVersionsTimelineModal', { studyGroupId: studyGroup.id })"
        >
          ({{ formatDate({ date: study.released_at, format: 'MMMM D, YYYY', from: 'mm/dd/yyyy' }) }})
        </span>
        <PrototypeVersion 
          :study="study" 
          :prototype="prototype"
          :study-group="studyGroup"
        />
      </h4>
      <div class="xl:border-l-2 border-deepsky-800 h-5" />
      <h2 class="w-full xl:w-auto xl:pl-3 mr-4 text-header-4 font-bold">
        {{ prototype.type_prototype ? prototype.type_prototype.title : prototype.title }}
        <feather 
          size="18"
          type="info" 
          class="text-blue mr-3 -mb-1 cursor-pointer" 
          @click="openPrototypeDescriptionModal(prototype)"
        />
      </h2>
      <h3
        v-if="study.type_id == 2"
        class="text-medium font-medium text-gray03"
      >
        <vue-skeleton-loader
          v-if="!buildingStockUnits"
          :width="120"
          :height="20"
          :rounded="true"
        />
        <span v-else>{{ formatUnits(buildingStockUnits) }} Total Units </span>
      </h3>
    </div>

    <div class="w-full">
      <slot />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PrototypeVersion from './PrototypeVersion.vue'
import TypePrototype from '@/models/TypePrototype'

export default {
  name: 'StudyResultsContentPrototype',
  components: { PrototypeVersion },
  props: { 
    prototype: {
      type: Object,
      required: true
    },
    study: {
      type: Object,
      required: true
    },
    studyGroup: {
      type: Object,
      required: true
    },
  },
  computed: {
    typePrototype() {
      return this.prototype?.type_prototype ? new TypePrototype(this.prototype.type_prototype) : {}
    },
    buildingStockFilters() {
      const jurisdiction_id = this.lastJurisdictionVisited?.id ?? null
      const climate_zone_prefix = this.$route?.params?.climate_zone_raw?.split('-')?.[0] ?? null
      const type_prototype_id = this.prototype.type_prototype_id
      return jurisdiction_id && climate_zone_prefix && type_prototype_id ? { jurisdiction_id, climate_zone_prefix, type_prototype_id } : null
    },
    buildingStockUnits() {      
      return this.isUpdating ? false : this.$store.getters['assumptions/buildingStocks/getterAllBuildingStocksUnits'](this.buildingStockFilters)
    },
    ...mapGetters(['lastJurisdictionVisited']),
    ...mapGetters('assumptions', ['isUpdating']),
  },
  methods: {
    openPrototypeDescriptionModal(prototype) {
      this.$eventBus.$emit('openDescriptionModal', { type: 'prototype', id: prototype.id })
    },
  },
}
</script>