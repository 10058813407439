<template>
  <transition
    name="fade"
  >
    <div
      v-show="getModalIsShow"
      class="modal-wrapper"
    >
      <div
        class="modal-dialog"
        :class="[modalDialogClasses]"
      >
        <button 
          v-if="!disableClose"
          class="absolute top-0 right-0 text-ash-500 pr-4 pt-5 focus:outline-none"
          @click="close()"
        >
          <feather
            type="x"
            size="24"
          />
        </button>
        <h4
          v-if="title"
          class="w-full font-bold text-pri text-lg text-left font-normal tracking-wide"
        >
          {{ title }}
        </h4>
        <h3
          v-if="subtitle"
          class="text-base font-bold text-gray03 cursor-pointer"
        >
          {{ subtitle }}
        </h3>
        <div class="w-full">
          <slot />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'BaseModal',
  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    modalDialogClasses: {
      default: 'max-w-sm'
    },
    disableClose: {
      type: Boolean,
      default: false
    },
    showModalParent: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showModal: false,
    }
  },
  computed: {
    getModalIsShow() {
      return (this.showModalParent || this.showModal)
    }
  },
  mounted() {
    document.addEventListener("keyup", this.handleEsc)
  },
  beforeDestroy() {
    document.removeEventListener("keyup", this.handleEsc)
  },
  methods: {
    handleEsc(evt) {
      if (this.showModal && evt.keyCode === 27) this.close()
    },
    open() {
      this.$emit('open')
      this.showModal = true
      this.$emit('update:showModalParent', false)
    },
    close() {
      if (this.disableClose) return false

      this.$emit('close')
      this.showModal = false
      this.$emit('update:showModalParent', false)
    }
  }
}
</script>

<style>
</style>
