import GraphQL from '@/util/GraphQL'

export default {
  namespaced: true,
  
  state: {
    building_types: []
  },

  getters: {
    getterGlobalBuildingTypes(state) {
      return state.building_types
    },

    getterBuildingTypeSelected: state => routeFullPath => {
      const url = new URL(window.location.origin + routeFullPath)
      const building_type_selected_slug = url.searchParams.get('only_building_type')
      if(!building_type_selected_slug) return state.building_types.findInArray({ slug: 'residental-buildings' })
      return state.building_types.findInArray({ slug: building_type_selected_slug })
    },

    getterBuildingTypesWithTypePrototypes(state, getters, rootState) {
      const items =  state.building_types.map((building_type) => {
        const type_prototypes = rootState.globalEntities
          .TypePrototype.type_prototypes
          .filter((type_prototype) => type_prototype.building_type_id == building_type.id)
        return {
          type_prototypes,
          ...building_type
        }
      })
      return items
    }

  },

  actions: {
    loadGlobalBuildingTypes(context) {
      const query = `
        {
          building_types (
            orderBy: [{ column: "order", order: "ASC" }]
          ) {
            id
            title
            icon
            slug
          }
        }
      `
      GraphQL({ query })
        .then(({ data }) => {
          context.commit('setGlobalBuildingTypes', { building_types: data.building_types})
        })
    }
  },

  mutations: {
    setGlobalBuildingTypes(state, { building_types }) {
      state.building_types = building_types
    }
  }
}