<template>
  <div class="psui-w-full psui-h-full psui-min-h-screen psui-flex psui-flex-col psui-overflow-y-auto">
    <router-view />
    <ModalJurisdictionSelection />
  </div>
</template>

<script>
import ModalJurisdictionSelection from './ModalJurisdictionSelection.vue'
import { mapGetters } from 'vuex'
import JurisdictionApiService from '@/services/api/JurisdictionApiService'

export default {
  name: 'JurisdictionIndex',
  components: { ModalJurisdictionSelection },
  computed: {
    routeHasJurisdictionSlug() {
      return this.$route?.params?.jurisdiction_slug ?? false
    },
    ...mapGetters(['lastJurisdictionVisited', 'lastClimateZoneVisited'])
  },
  watch: {
    '$route.jurisdiction_slug' : {
      async handler() {
        if(this.$route.name === 'StudyResults') return this.getJurisdictionBySlug()
      },
      immediate: true
    }
  },
  mounted() {
    this.getJurisdictionBySlug()
  },
  methods: {

    getJurisdictionBySlug() {
      const jurisdiction_slug = this.$route.params.jurisdiction_slug
      if(!jurisdiction_slug) return this.$eventBus.$emit('openModalJurisdictionSelection')
      if (jurisdiction_slug && jurisdiction_slug != this.lastJurisdictionVisited?.slug) {
        JurisdictionApiService.getBySlug(jurisdiction_slug, 'JurisdictionIndex.getJurisdictionBySlug')
          .then(({ jurisdiction }) => {
            this.$store.dispatch('setUserLastJurisdictionAndFirstClimateZoneVisited', { value: jurisdiction, context: 'updateLastJurisdictionVisitedFromJurisdictionIndex'})
          })
      }
    }

  }
}
</script>