/**
 * Business logic at 
 * https://docs.google.com/spreadsheets/d/14pESWy9Jog30lxJxV9uIL78m4ltI5vIgWgyXLvk6QVA/edit#gid=755131244
 * @param {Number} maxValueRaw 
 * @param {Number} minValueRaw 
 */
 export default (maxValueRaw, minValueRaw = 0) => {

  const lookupTableOne = [
    // First Digit, MaxLabelFactor, NumberOfLabels
    [1,2,4],
    [2,3,3],
    [3,4,4],
    [4,6,3],
    [5,6,3],
    [6,8,4],
    [7,8,4],
    [8,10,4],
    [9,10,4]
  ]

  const lookupTableTwo = [
    // Row, Range Min, Range Max, Additional Labels, New Count, Increment Multiplier, Min Value Max, 
    [1,0  ,1/3,1,4,1,1/3],
    [2,1/3,2/3,2,5,1,2/3],
    [3,2/3,1  ,3,6,1,1  ],
  ]
  
  const lookupTableThree = [
    [1,0  ,1/4,1,5,1,0.25],
    [2,1/4,1/2,2,3,2,0.50],
    [3,1/2,3/4,3,4,2,1   ],
    [4,3/4,1  ,4,4,2,1   ]
  ]

  const maxValue = maxValueRaw < 0 ? 0 : maxValueRaw
  const minValue = minValueRaw > 0 ? 0 : minValueRaw

  const positiveOnly = maxValueRaw >= 0 && minValueRaw >= 0
  const negativeOnly = maxValueRaw <= 0 && minValueRaw <= 0
  
  const negativeAndPositive = positiveOnly === false && negativeOnly === false
  // const negativeBiggerThanPositive = negativeAndPositive && Math.abs(minValueRaw) > maxValueRaw ? true : false

  const largerAbsoluteValue = Math.abs(maxValue) > Math.abs(minValue) ? Math.abs(maxValue) : Math.abs(minValue)
  const smallerAbsoluteValue = Math.abs(maxValue) > Math.abs(minValue) ? Math.abs(minValue) : Math.abs(maxValue)

  // The Google Spreadsheet log is not the same as the Math.log 
  // https://stackoverflow.com/questions/3019278/how-can-i-specify-the-base-for-math-log-in-javascript
  const orderOfMagnitude = parseInt(Math.log(largerAbsoluteValue) / Math.LN10)

  const standardizedValue = largerAbsoluteValue / Math.pow(10, orderOfMagnitude)  
  const firstDigit = isNaN(standardizedValue) ? 1 : Number(standardizedValue.toString()[0])
  const maxLabelFirstDigit = lookupTableOne.find((row) => row[0] === firstDigit)[1]  
  const maxLabel = maxLabelFirstDigit * Math.pow(10, orderOfMagnitude)
  const numberOfLabels = lookupTableOne.find((row) => row[0] === firstDigit)[2]

  const increment = maxLabel/numberOfLabels
  
  let lookupRow
  if (smallerAbsoluteValue <= maxLabel * 1 / numberOfLabels) {
    lookupRow = 1
  } else {
    if (smallerAbsoluteValue <= 2 / numberOfLabels * maxLabel ) {
      lookupRow = 2
    } else {
      if (numberOfLabels == 3) {
        lookupRow = 3
      } else {
        if (smallerAbsoluteValue <= 3 / 4 * maxLabel) {
          lookupRow = 3
        } else {
          lookupRow = 4
        }
      }
    }
  }  
  
  let numberOfLabelsIfHasNegative = false
  if (negativeAndPositive) {
    const tableToLook = numberOfLabels === 3 ? lookupTableTwo : lookupTableThree
    const index = 5 - 1
    numberOfLabelsIfHasNegative = tableToLook.find((row) => row[0] === lookupRow)[index]
  }

  let incrementIfHasNegative = false
  if (negativeAndPositive) {
    const tableToLook = numberOfLabels === 3 ? lookupTableTwo : lookupTableThree
    const index = 6 - 1
    incrementIfHasNegative = tableToLook.find(row => row[0] === lookupRow)[index] * increment
  }

  let minLabelIfNegative = false
  if (negativeAndPositive) {
    const tableToLook = numberOfLabels === 3 ? lookupTableTwo : lookupTableThree
    const index = 7 - 1
    minLabelIfNegative = tableToLook.find(row => row[0] === lookupRow)[index] * -1 * maxLabel
  }


  const ifSameSignTable = [
    0,
    maxLabel / numberOfLabels,
    maxLabel / numberOfLabels * 2,
    maxLabel / numberOfLabels * 3,
    numberOfLabels === 4 ? maxLabel / numberOfLabels * 4 : ''
  ]

  const ifNegativeAndPositiveTable = [
   minLabelIfNegative,
   minLabelIfNegative + incrementIfHasNegative,
   minLabelIfNegative + (incrementIfHasNegative * 2),
   minLabelIfNegative + (incrementIfHasNegative * 3),
   numberOfLabelsIfHasNegative > 3 ? minLabelIfNegative + (incrementIfHasNegative * 4) : '',
   numberOfLabelsIfHasNegative > 4 ? minLabelIfNegative + (incrementIfHasNegative * 5) : ''
  ]

  let output = []
  // First Label
  if (positiveOnly) {
    output.push(ifSameSignTable[0])  
  } else {
    if (negativeOnly) {
      output.push(ifSameSignTable[0])
    } else {
      if (negativeAndPositive) {
        output.push(ifNegativeAndPositiveTable[0])
      } else {        
        output.push(ifNegativeAndPositiveTable[0] * -1)
      }
    }
  }

  // Second Label
  if (positiveOnly) {
    output.push(ifSameSignTable[1])
  } else {
    if (negativeOnly) {
      output.push(ifSameSignTable[1] * -1)
    } else {
      if (negativeAndPositive) {
        output.push(ifNegativeAndPositiveTable[1])
      } else {
        output.push(ifNegativeAndPositiveTable[1] * -1)
      }
    }
  }

  
  // Third Label
  if (positiveOnly) {
    output.push(ifSameSignTable[2])
  } else {
    if (negativeOnly) {
      output.push(ifSameSignTable[2] * -1)
    } else {
      if (negativeAndPositive) {
        output.push(ifNegativeAndPositiveTable[2])
      } else {
        output.push(ifNegativeAndPositiveTable[2] * -1)
      }
    }
  }

  
  // Fourth Label
  if (positiveOnly) {
    output.push(ifSameSignTable[3])
  } else {
    if (negativeOnly) {
      output.push(ifSameSignTable[3] * -1)
    } else {
      if (negativeAndPositive) {
        output.push(ifNegativeAndPositiveTable[3])
      } else {
        output.push(ifNegativeAndPositiveTable[3] * -1)
      }
    }
  }
  
  // Fifth Label
  if (positiveOnly) {
    output.push(ifSameSignTable[4])
  } else {
    if (negativeOnly && ifSameSignTable[4]) {
      output.push(ifSameSignTable[4] * -1)
    } else {
      if (negativeAndPositive) {
        output.push(ifNegativeAndPositiveTable[4])
      } else {
        if (ifNegativeAndPositiveTable[4] !== '') {
          output.push(ifNegativeAndPositiveTable[4] * - 1)
        }
      }
    }
  }
  
  // Sixth Label
  if (positiveOnly) {
    // output.push(ifSameSignTable[5])
  } else {
    if (negativeOnly) {
      // output.push(ifSameSignTable[5] * -1)
    } else {
      if (negativeAndPositive && ifNegativeAndPositiveTable[5]) {
        output.push(ifNegativeAndPositiveTable[5])
      } else {
        if (ifNegativeAndPositiveTable[5] !== '') {
          output.push(ifNegativeAndPositiveTable[5] * - 1)
        }
      }
    }
  }

  // console.table({
  //   maxValueRaw,
  //   minValueRaw,
  //   maxValue,
  //   minValue,
  //   positiveOnly,
  //   negativeOnly,
  //   negativeAndPositive,
  //   largerAbsoluteValue,
  //   smallerAbsoluteValue,
  //   orderOfMagnitude,
  //   standardizedValue,
  //   firstDigit,
  //   maxLabelFirstDigit,
  //   maxLabel,
  //   numberOfLabels,
  //   increment,
  //   lookupRow,
  //   numberOfLabelsIfHasNegative,
  //   incrementIfHasNegative,
  //   minLabelIfNegative,
  //   // negativeBiggerThanPositive,
  //   // ifSameSignTable,
  //   // ifNegativeAndPositiveTable,
  //   output
  // })

  return output.filter(el => el !== '' && isNaN(el) === false)
}