<template>
  <BaseModal 
    ref="baseModal"
    title="Building Type"
    modal-dialog-classes="max-w-lg"
    @close="close"
  >
    <div class="w-full flex flex-col">
      <InputWithLabel
        v-model="building_type.title"
        class="mt-6"
        label="Title"
      />

      <InputWithLabel
        v-model="building_type.slug"
        class="mt-6"
        label="Slug"
      />

      <div class="relative mt-6">
        <VueSelect 
          v-model="building_type.type_prototypes"
          class="vue-select"
          placeholder="Select"
          :class="building_type.type_prototypes.length ? 'filled' : ''"
          label="title"
          :options="type_prototypes"
          :multiple="true"
        />
        <label>Type prototypes</label>
      </div>

      <div class="w-full flex mt-6">
        <PsButton
          size="medium"
          :label="building_type.id ? `Update` : `Store`"
          @click="storeOrUpdate()"
        />
      </div>
    </div>
  </BaseModal>
</template>

<script>
import BuildingType from '@/models/BuildingType'
export default {
  name: "AdminBuildingTypeEditModal",
  data() {
    return {
      building_type: new BuildingType(),
      type_prototypes: []
    }
  },
  mounted() {
    this.$eventBus.$on('openBuildingTypeModal', ({ building_type_id = null } = {}) => {
      this.building_type.id = building_type_id
      this.$refs.baseModal.showModal = true
      if(building_type_id) this.getBuildingType()
    })
    this.getSelectOptions()
  },
  beforeDestroy() {
    this.$eventBus.$off('openBuildingTypeModal')
  },
  methods: {
    getBuildingType() {
      this.building_type.show()
        .then(({ data }) => {
          this.building_type = new BuildingType(data.building_type)
        })
    },

    getSelectOptions() {
      const query = `
        {
          type_prototypes {
            id
            title
          }
        }
      `
      this.$graphql({ query })
        .then(({ data }) => {
          this.type_prototypes = data.type_prototypes
        })
    },

    storeOrUpdate() {
      this.building_type.storeOrUpdate()
        .then(() => {
          this.$emit('getBuildingTypes')
          this.close()
        })
    },

    close() {
      this.building_type = new BuildingType()
      this.$refs.baseModal.showModal = false
    }
  }
}
</script>

<style>

</style>