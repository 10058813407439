<template>
  <BaseModal
    ref="baseModal"
    :modal-dialog-classes="isUserLoggedIn ? 'w-auto bg-ash-100' : 'w-auto bg-white'"
    :show-modal-parent.sync="showModalParent"
    @close="close"
  >
    <div
      v-if="!isReadyToUserForm && !isUserLoggedIn"
      class="info-wrapper text-center"
    >
      <img
        src="/images/add-to-policy-sign-in.jpg"
        class="max-w-full h-auto mt-5 mb-10 inline-block"
        alt="Sign in or register to design your own policies"
      >
      <h2 class="text-header-6 font-bold text-deepsky-900 mb-2">
        Please log in to generate your policies.
      </h2>
      <p class="text-header-3 text-ash-400">
        New here? Register to start building policies and view their impact on your City/County.
      </p>
      <div class="flex justify-center mt-8">
        <AppButton
          label="Register/Log In"
          @click="isReadyToUserForm = true"
        />
        <!-- <AppButton label="Close" @click="$refs.baseModal.close()" theme="bg-white text-deepsky-500 border-white" /> -->
      </div>
    </div>
    <UserSignForm
      v-else-if="!isUserLoggedIn && showModalParent"
      @close="close"
    />
    <PolicyWizard
      v-else
      ref="wizard"
      @close="close"
    />
  </BaseModal>
</template>

<script>
import PolicyWizard from '@/modules/app/policy/wizard/PolicyWizard.vue'
import UserSignForm from '@/modules/app/login/components/UserSignForm.vue'
import Policy from '@/models/Policy'
import { find } from '@/util/Functions'
import steps from '@/modules/app/policy/wizard/steps'
export default {
  name: 'ModalAutoGeneratePolicyWizard',
  components: { 
    PolicyWizard,
    UserSignForm
  },
  data() {
    return {
      isReadyToUserForm : false,
      showModalParent: false
    }
  },
  computed: {
    isUserLoggedIn() {
      return this.$store.state.auth.loggedUser
    }
  },
  mounted() {
    this.$eventBus.$on('openModalAutoGeneratePolicyWizard', ({ policy, stepId } = {}) => {
      this.open({ policy, stepId })
    })
    this.$eventBus.$on('closeModalAutoGeneratePolicyWizard', this.close)
  },
  beforeDestroy() {
    this.$eventBus.$off('openModalAutoGeneratePolicyWizard')
    this.$eventBus.$off('closeModalAutoGeneratePolicyWizard')
  },
  methods: {
    open({ policy = null, stepId = null } = {}) {
      this.$eventBus.$emit('cleanPolicyWizardBreadcrumbs')
      if(this.$refs.wizard) this.$refs.wizard.resetState()
      this.showModalParent = true
      
      if (policy && this.$refs.wizard) {
        this.$refs.wizard.policy = new Policy(policy)
        this.$refs.wizard.updateJustidiction()

        if(stepId) {
          const goToStep = find(steps, {id: stepId })
          this.$refs.wizard.currentStep = goToStep
        }
        
      }
      this.$store.commit('general/setRedirectTo', { route: window.location.pathname, event: { name: 'openModalAutoGeneratePolicyWizard', payload : { policy: policy } } })
    },
    close() {
      if(this.$refs.wizard) this.$refs.wizard?.resetState()
      this.showModalParent = false
      this.$store.commit('general/setRedirectTo')
      this.$eventBus.$emit('cleanPolicyWizardBreadcrumbs')
    }
  }
}
</script>
