<template>
  <div class="w-full">
    <label
      class="block appearance-none w-full h-full bg-gray06 text-gray04 text-base px-3 py-2 font-normal border-4 border-dashed border-gray05 hover:border-gray04 relative flex items-center box-sizing"
      :class="disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'"
    >
      <feather
        type="upload"
        class="mr-3 text-blue04 flex-shrink-0"
      />
      <span
        class="whitespace-no-wrap ellipsis"
        :class="value && value.name ? 'text-black font-bold' : ''"
      >{{ placeholder }}</span>
      <input
        tabindex="0"
        type="file"
        class="hidden-input-file"
        :accept="accept"
        :disabled="disabled"
        name="file"
        data-title="Clique para selecionar ou arraste aqui"
        @change="addFile($event)"
      >
    </label>
  </div>
</template>

<script>
export default {
  name: 'InputFile',
  props: {
    value: {
      type: [Object, File, String]
    },
    accept: {
      type: String,
      default: 'image/jpeg, image/png'
    },
    label: {
      type: String,
      default: 'Label'
    },
    placeholder: {
      type: String,
      default: 'Clique para selecionar ou arraste o arquivo aqui'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      fileName: ''
    }
  },
  methods: {
    addFile(event) {
      if (event.target.files && event.target.files[0]) {
        this.$emit('input', event.target.files[0])
      }
    }
  }
}
</script>

<style>

.hidden-input-file {
  opacity: 0 !important;
  z-index: -1;
  position: absolute;
}
</style>