export default class User {
  constructor(obj = {}) {
    this.id = obj.id || null
    this.email = obj.email || ''
    this.password = obj.password || ''
    this.password_confirmation = obj.password_confirmation || ''
    this.created_at = obj.created_at || ''
    this.updated_at = obj.updated_at || ''
    this.is_admin = obj.is_admin || false
    this.is_team = obj.is_team || false
    this.accept_features_newsletter = obj.accept_features_newsletter || false
    this.accept_periodic_newsletter = obj.accept_periodic_newsletter || false
  }

}
