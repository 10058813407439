<template>
  <div class="app--breadcrumb">
    <ul v-if="items.length > 0">
      <li
        v-for="item in items"
        :key="item.title"
        :class="liClass"
      >
        <router-link
          v-if="item.route"
          :to="item.route"
          @click.native="onNavigate(item)"
        >
          {{ item.title }}
        </router-link>
        <span v-else>
          {{ item.title }}
        </span>
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  props: {
    items: {
      type: [Array, Boolean],
    },
    liClass: {
      type: String,
      default: 'ts--accent--book--2'
    }
  },
  methods: {
    onNavigate(item) {       
      if (item.route === this.$route.path && this.$parent.closeDrawer) {
        this.$parent.closeDrawer()
      }
    },
  }
}
</script>

<style lang="scss" scoped>
ul {
  display: block;
  li {
    display: inline-block;
    &:after {
      content: "//";
      display: inline;
      color: app-color(blue05);
      margin-right: 5px;
    }

    &:last-of-type:after {
      display: none;
    }

    a {
      color: app-color(blue02);
      text-decoration: none;
      transition: 250ms ease-in-out all;

      &:hover {
        color: app-color(bluebrand);
      }
    }

    span {
      color: app-color(gray03);
    }
  }
}
</style>
