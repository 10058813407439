<template>
  <div class="w-full p-8 mt-6 flex flex-col">
    <div class="w-full flex justify-end mb-3">
      <PsButton
        size="medium"
        label="Create"
        icon="add"
        @click="$eventBus.$emit('openBuildingHeightModal')"
      />
    </div>

    <table class="table table-bordered table-hover table-striped">
      <thead>
        <tr>
          <td>ID</td>
          <td>Building Height</td>
          <td>Slug</td>
          <td class="psui-w-48">
            Actions
          </td>
        </tr>
      </thead>
      <tbody>
        <tr 
          v-for="(building_height, building_height_index) in building_heights"
          :key="`building_height_${building_height_index}`" 
          class="cursor-move"
          draggable="true"
          @dragstart="dragStart(building_height_index)"
          @dragover.prevent
          @dragend="dragEnd"
          @drop="dragFinish(building_height_index, building_heights)"
        >
          <td>{{ building_height.id }}</td>
          <td>{{ building_height.title }}</td>
          <td>{{ building_height.slug }}</td>
          <td class="psui-flex psui-space-x-2">
            <PsButton 
              size="medium" 
              label="Edit" 
              icon="edit"
              @click="$eventBus.$emit('openBuildingHeightModal', { building_height_id: building_height.id })" 
            />
            <PsButton 
              layout="caution"
              size="medium" 
              label="Delete" 
              icon="delete"
              theme="bg-red border-transparent rounded-full text-white"
              @click="selectBuildingHeightToDelete(building_height)"
            />
          </td>
        </tr>
      </tbody>
    </table>

    <AdminBuildingHeightEditModal @getBuildingHeights="getBuildingHeights" />

    <BaseModal
      ref="deleteModal"
      modal-dialog-classes="w-full max-w-sm"
      title="Attention"
    >
      <div
        v-if="building_height_selected"
        class="w-full flex flex-col items-start mt-6"
      >
        <p class="text-base text-gray04">
          Delete <span class="text-red font-bold">{{ building_height_selected.title }}</span>?
        </p>
        <PsButton
          class="psui-mt-6"        
          layout="caution"          
          label="Confirm delete"
          size="big"
          theme="bg-red border-transparent rounded-full text-white"
          @click="deleteBuildingHeight()"
        />
      </div>
    </BaseModal>
  </div>
</template>

<script>
import AdminBuildingHeightEditModal from './AdminBuildingHeightEditModal'
export default {
  name: 'AdminBuildingHeightList',
  components: { AdminBuildingHeightEditModal },
  data() {
    return {
      building_heights: [],
      building_height_selected: null
    }
  },
  computed: {
    getTableToOrdenateItems() {
      return `building_heights`
    }
  },
  mounted() {
    this.getBuildingHeights()
  },
  methods: {
    getBuildingHeights() {
      const query = `
        {
          building_heights (
            orderBy: [
              { column: "order" }
            ]
          ) {
            id
            title
            slug
          }
        }
      `
      this.$graphql({ query })
        .then(({ data }) => {
          this.building_heights = data.building_heights
        })
    },

    selectBuildingHeightToDelete(building_height) {
      this.building_height_selected = building_height
      this.$refs.deleteModal.showModal = true
    },

    deleteBuildingHeight() {
      this.$api.delete(`/api/admin/building_height/delete/${this.building_height_selected.id}`)
        .then(() => {
          this.getBuildingHeights()
          this.$refs.deleteModal.showModal = false
          this.building_height_selected = null
        })
    }
  }
}
</script>
