<template>
  <div class="relative psui-rounded-md psui-bg-blue-20 mx-6 mb-7 p-2">
    <p class="font-bold psui-text-small psui-text-blue-60 flex justify-between items-center mb-2 px-2 py-1">
      {{ headerTitle }}
    </p>
    <div class="psui-rounded-md psui-shadow-elevation-5 psui-bg-white p-3">
      <PsRadioButton
        v-model="specifyByRadioValue"
        label="Overall"
        input-value="overall"
        class="radio-button"
        size="small"
      />

      <hr class="psui-border-blue-70 opacity-10 my-4">

      <div class="flex">
        <div class="w-1/2">
          <PsRadioButton
            v-model="specifyByRadioValue"
            label="Specify by"
            input-value="specifyby"
            class="radio-button"
            size="small"
          />
        </div>
        <div class="checkbox-wrapper w-1/2 flex flex-col">
          <PsCheckbox
            v-for="(specify, key) in specifyByOptions"
            :key="key"
            v-model="specifications"
            :input-value="specify.key"
            :label="specify.label"
            size="small"
            class="checkbox-button"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AssumptionsGrowthRateWidget',
  props: {
    specifyByOptions: Object,
    specifyBy: [ Boolean, Array ],
    headerTitle: String
  },
  data() {
    return {
      specifications: [],
    }
  },
  computed:{
    specifyByRadioValue: {
      get() {
        return !this.specifyBy.length ? 'overall' :'specifyby'
      },
      set(value) {
        if (this.specifications.length === 0) {
          this.specifications = [ Object.keys(this.specifyByOptions)[0] ]
        }

        if(value === 'overall'){
          this.specifications = []
        }
      }
    }
  },
  watch: {
    specifications(value) {
      this.$emit('update:specifyBy', value.length > 0 ? value : [] )
    },
    specifyBy(value) {
      if (!value) this.specifications = []
    },
  },
  mounted(){
    this.specifications = this.specifyBy
  }
}
</script>
<style lang='scss' scoped>
  .radio-button {
    background: none;

    ::v-deep label span {
    font-weight: 700;
    color: #28323B;
    text-transform: capitalize;
    }
  } 
  
  .checkbox-button {
    background: none;
  }

  .checkbox-wrapper {
    gap: 2px;
  }
</style>
