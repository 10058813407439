<template>
  <div class="flex">
    <div class="font-semi-bold text-sm">
      <span
        v-if="value"
        class="rounded-full flex items-center justify-center px-4 py-1"
        :class="[bgPositive, colorPositive]"
      >{{ positiveLabel }}</span>
      <span
        v-else
        class="rounded-full flex items-center justify-center px-4 py-1"
        :class="[bgNegative, colorNegative]"
      >{{ negativeLabel }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BooleanLabel',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    bgPositive: {
      type: String,
      default: 'bg-blue04'
    },
    colorPositive: {
      type: String,
      default: 'text-white'
    },
    positiveLabel: {
      type: String,
      default: 'Yes'
    },
    bgNegative: {
      type: String,
      default: 'bg-gray'
    },
    colorNegative: {
      type: String,
      default: 'text-white'
    },
    negativeLabel: {
      type: String,
      default: 'No'
    }
  }
}
</script>