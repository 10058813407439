import accounting from 'accounting'

export const accountingFormatNumber = ({ number = '', precision = 2, thousand = '' }) => {

  accounting.settings = {
    currency: {
      symbol: "US$ ",
      format: "%s%v",
      decimal: ".",
      thousand: ",",
      precision: 2
    },
    number: {
      precision: precision,  // default precision on numbers is 0
      thousand: thousand,
      decimal: "."
    }
  }

  return parseFloat(accounting.formatNumber(parseFloat(number)))
}

export const toFixed = (num, places = 2) => {
  const numParsed = parseFloat(num), multiplier = Math.pow(10, places)
  return (Number(Math['round'](numParsed * multiplier) / multiplier))
}

export const hasCents = (number) => {
  return (Math.abs(number) % 100 ) > 0 ? true : false
}

export const convertToLocaleStringIfIsANumber = (value ) => {
  return typeof value === 'number' ? value.toLocaleString() : value
}