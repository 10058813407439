import BuildingHeightApiService from '@/services/api/BuildingHeightApiService'

export default class BuildingHeight extends BuildingHeightApiService {
  constructor(obj = {}) {
    super()
    this.id = obj.id ?? null
    this.title = obj.title ?? ''
    this.content = obj.content ?? ''
    this.slug = obj.slug ?? ''

    this.building_estimates = obj.building_estimates ?? []
    this.custom_building_estimates = obj.custom_building_estimates ?? []
  }
}