import {
  on_bill_cost_ratio,
  simple_payback,
  forecast_units,
  forecast_emissions_savings,
  forecast_kwh_savings,
  forecast_therms_savings,
  forecast_lifecycle_savings,
  forecast_initial_cost,
  climate_zone,
  building_type,
  vintage,
  tdv2022_benefit_to_cost_ratio
} from './columns'

export const comparisonColumns = {
  existingBuildings: {
    key: 'existingBuildings',
    studyType: 'Existing Buildings',
    columnGroups: [
      {
        order: 0,
        key: "different_assumptions",
        title: 'Different Assumptions',
        columns: [],
        hasHelper: {
          type: 'helper',
          slug: 'comparison-tables-different_assumptions'
        }
      },
      {
        order: 1,
        key: "shared_assumptions",
        title: 'Shared Assumptions',
        columns: [],
        hasHelper: {
          type: 'helper',
          slug: 'comparison-tables-shared_assumptions'
        }
      },
      {
        order: 2,
        key: "scope",
        title: 'Scope',
        columns: [
          climate_zone(),
          building_type(),
          vintage(),
        ],
        hasHelper: {
          type: 'helper',
          slug: 'comparison-tables-scope'
        }
      },
      {
        order: 3,
        key: "cost_effectiveness",
        title: 'Benefit/Cost Ratios',
        columns: [
          on_bill_cost_ratio(),
          simple_payback(),
          tdv2022_benefit_to_cost_ratio(),
        ],
        hasHelper: {
          type: 'helper',
          id: 23
        }
      },
      {
        order: 4,
        key: "city_wide_estimates",
        title: 'City-wide Estimates',
        columns: [
          forecast_units(),
          forecast_emissions_savings(),
          forecast_initial_cost(),
          forecast_lifecycle_savings(),
          forecast_kwh_savings(),
          forecast_therms_savings(),
        ],
        hasHelper: {
          type: 'helper',
          slug: 'comparison-tables-city_wide_estimates'
        }
      },
    ]
  },
  newBuildings: {
    key: 'newBuildings',
    studyType: 'New Buildings',
    columnGroups: []
  }
}