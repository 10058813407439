import sumItemsWeightedByAttr from '@/business-logic/services/calcs/sumItemsWeightedByAttr'
import getOnBillCostRatio from '@/business-logic/services/study-results/getOnBillCostRatio'
import { GREATER_THEN_ONE_NUMBER } from '@/business-logic/constants/helpersEnum'

export default ({ vintageData, studyDataItems }) => {

  const allItemsIsGreaterThanOneOnBillCost = studyDataItems.filter((item) => item.on_bill_cost_ratio == GREATER_THEN_ONE_NUMBER)
  const allItemsIsGreaterThanOneTdv = studyDataItems.filter((item) => item.tdv_benefit_to_cost_ratio == GREATER_THEN_ONE_NUMBER)

  // Per home cols
  vintageData.initial_cost = studyDataItems.reduce((acc, item) => acc + item.initial_cost, 0)
  vintageData.annual_bill_savings = studyDataItems.reduce((acc, item) => acc + item.annual_bill_savings, 0)
  vintageData.annual_bill_savings_avg = studyDataItems.reduce((acc, item) => acc + item.annual_bill_savings_avg, 0)
  vintageData.emissions_savings = studyDataItems.reduce((acc, item) => acc + item.emissions_savings, 0)
  vintageData.lifecycle_savings = studyDataItems.reduce((acc, item) => acc + item.lifecycle_savings, 0)
  vintageData.kwh_savings = studyDataItems.reduce((acc, item) => acc + item.kwh_savings, 0)
  vintageData.therms_savings = studyDataItems.reduce((acc, item) => acc + item.therms_savings, 0)
  vintageData.energy_savings_combined = studyDataItems.reduce((acc, item) => acc + item.energy_savings_combined, 0)
  // Cost effectiveness cols
  vintageData.on_bill_cost_ratio = allItemsIsGreaterThanOneOnBillCost.length == studyDataItems.length ? GREATER_THEN_ONE_NUMBER : getOnBillCostRatio({ initial_cost: vintageData.initial_cost, annual_bill_savings_avg: vintageData.annual_bill_savings_avg })
  vintageData.tdv_benefit_to_cost_ratio = allItemsIsGreaterThanOneTdv.length == studyDataItems.length ? GREATER_THEN_ONE_NUMBER :  getOnBillCostRatio({ initial_cost: vintageData.initial_cost, annual_bill_savings_avg: vintageData.annual_bill_savings })
  vintageData.tdv2022_benefit_to_cost_ratio = studyDataItems.reduce((acc, item) => acc + item.tdv2022_benefit_to_cost_ratio, 0)
  vintageData.simple_payback = sumItemsWeightedByAttr(studyDataItems, 'simple_payback', 'initial_cost')
  // City wide cols

  vintageData.forecast_units_affected = !studyDataItems.length ? 0 : studyDataItems.sort((a, b) => a.forecast_units_affected - b.forecast_units_affected)[0].forecast_units_affected
  vintageData.forecast_initial_cost = !studyDataItems.length ? 0 :  studyDataItems.reduce((acc, item) => acc + item.forecast_initial_cost, 0)
  vintageData.forecast_emissions_savings = !studyDataItems.length ? 0 :  studyDataItems.reduce((acc, item) => acc + item.forecast_emissions_savings, 0)
  vintageData.forecast_kwh_savings = !studyDataItems.length ? 0 :  studyDataItems.reduce((acc, item) => acc + item.forecast_kwh_savings, 0)
  vintageData.forecast_therms_savings = !studyDataItems.length ? 0 :  studyDataItems.reduce((acc, item) => acc + item.forecast_therms_savings, 0)
  vintageData.forecast_lifecycle_savings = !studyDataItems.length ? 0 :  studyDataItems.reduce((acc, item) => acc + item.forecast_lifecycle_savings, 0)
}
