
/**
 * Columns that should be formatted or aggregated in measures combinations
 * NOTICE: The order of columns alter the results of formatted items
 * Some may depends on other items
 * So organize the array in order of dependency
 */

 export default [
  'initial_cost', 
  'kwh_savings',
  'therms_savings',
  'annual_bill_savings',
  'annual_bill_savings_avg',
  'tdv_benefit_to_cost_ratio',
  'emissions_savings_pct',
  'lifecycle_savings',
  'baseline_fuel_type',
  'compliance_margin',
  'on_bill_cost_ratio',
  'emissions_savings',
  'simple_payback', 
  'tdv2022_benefit_to_cost_ratio',
  'energy_savings_combined',
]