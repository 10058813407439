<template>
  <div class="w-full">
    <div class="table-wrapper w-full whitespace-no-wrap rounded-sm text-left">      
      <table class="relative align-top">
        <ForecastTableHead          
          @openAssumptionsDrawer="openAssumptionsDrawer()"
          @openProjectionsModal="openProjectionsModal($event)"
        />
        <ForecastTableBody
          @openProjectionsModal="openProjectionsModal($event)"
        />
      </table>
    </div>
  </div>
</template>

<script>
import ForecastTableBody from './ForecastTableBody.vue'
import ForecastTableHead from './ForecastTableHead.vue'
import { getMeasuresLengthText, find, findIndex } from '@/util/Functions'
import StudyData from '@/models/StudyData'
import { mapGetters } from 'vuex'
export default {
  name: 'ForecastTable',
  components: { ForecastTableHead, ForecastTableBody },
  props: {
    study: {
      type: Object,
      required: true
    },
    study_data: {
      type: Array,
      required: true
    },
    measuresPreSelectedIds: {
      type: Array,
      required: false,
      default: function() {
        return []
      }
    },
    prototype: {
      type: Object
    },
    vintage: {
      type: Object
    },
    building_stock_units: {
      type: [Number, String, Boolean]
    },
    dataFilterSelected: {
      type: String,
      default: 'none'
    },
    climate_zone_raw: {
      type: String
    },
    expanded: {
      type: Boolean
    }
  },
  data() {
    return {
      measureCombinations: [],
      orderColumn: null,
      orderDirection: 'desc',
      studyDataHere: [],
      rowOnHover: false,
      allPossibleMeasures: [],
      shouldUpdateWhenExpanded : false
    }
  },
  computed: {    
    getMeasureCombination() {
      if(!this.measureCombinations.length) return false

      const custom_combination = this.measureCombinations[0]
      const climate_zone = this.$store.getters['globalEntities/ClimateZone/getterGlobalClimateZones'].findInArray({ raw: custom_combination.climate_zone_raw })
      const prototype = this.$store.getters['globalEntities/Prototype/getterGlobalPrototypes'].findInArray({ id: custom_combination.prototype_id })
      const vintage = this.$store.getters['globalEntities/Vintage/getterGlobalVintages'].findInArray({ id: custom_combination.vintage_id })

      const building_stock_units = this.$store.getters['assumptions/buildingStocks/getterAllBuildingStocksUnits']({
        climate_zone_prefix: climate_zone.prefix,
        type_vintage_id: vintage.type_vintage_id,
        type_prototype_id: prototype.type_prototype_id,
      })

      const measureCombination = new StudyData({
        study_data: this.measureCombinations[0].data, 
        building_stock_units: building_stock_units,
        assumption: this.getterAssumptionsSelected
      })
      this.measureCombinations.forEach((studyData, index) => {
        if(index < 1) return
        measureCombination.sumMeasureCombinations({ dataToAggregate: studyData.data })
      })
      measureCombination.measure = { title: getMeasuresLengthText(this.measureCombinations) }
      return measureCombination
    },

    columnsSelectedForStudy() {
      if(this.study.type_content == 'New Buildings') {
        return this.$store.getters['studyResults/getterNewBuildingsColumnsSelected']
      }
      return this.$store.getters['studyResults/getterExistingBuildingsColumnsSelected']
    },

    getStudyDataOrdered() {
      return this.studyDataHere
        .filter(this.filterBasedOnCostEffectiveFilter)
        .sort((a, b) => {
          if(this.orderDirection === 'asc') return a.data[this.orderColumn] - b.data[this.orderColumn]
          return b.data[this.orderColumn] - a.data[this.orderColumn]
        })
    },    
    ...mapGetters('assumptions', ['getterAssumptionsSelected'])
  },
  watch: {
    'getterAssumptionsSelected': {
      deep: true,
      handler() {
        if (this.studyDataHere.length > 0) {
          this.generateStudyDataWithProjections('getterAssumptionsSelected')          
        } else {
          this.shouldUpdateWhenExpanded = true
        }
      }
    },
    study_data: {
      handler() {
        this.generateStudyDataWithProjections('study_data')
      }
    },
    building_stock_units: {
      handler() {
        if (this.expanded) this.generateStudyDataWithProjections('building_stock_units')
      }
    },
    expanded: {
      handler(expanded) {
        if (expanded && this.shouldUpdateWhenExpanded) {
          this.generateStudyDataWithProjections('on_expanded')
        }
        this.getAllPossibleMeasures()
      }
    }
  },
  mounted() {
    this.setOrderColumnDefault()
    if(this.expanded) {
      this.getAllPossibleMeasures()
      this.generateStudyDataWithProjections('mounted')
    }
  },
  methods: {
    generateStudyDataWithProjections() {
      setTimeout(() => {
        this.studyDataHere = this.study_data.map((studyDataRow) => {
          return new StudyData({ 
              study_data: studyDataRow, 
              building_stock_units: this.building_stock_units,
              assumption: this.getterAssumptionsSelected
            }
          )
        }).sort((a, b) => {
          if(a.measure) return a.measure.order - b.measure.order
          return 1
        })
        this.addMeasuresPreselected()
        this.checkCombinedMeasures()
      }, 50)
    },
    filterBasedOnCostEffectiveFilter(study_data) {      

      if (this.checkQueryKeyValue('show_only_cost_effectiveness', 'onBill') && !study_data.isOnBillEffective) return false
      if (this.checkQueryKeyValue('show_only_cost_effectiveness', 'tdv') && !study_data.isTdvEffective) return false      

      return true
    },

    setOrderColumnDefault() {
      this.orderColumn = this.columnsSelectedForStudy.columnGroups[0].columns[0].key
      this.orderDirection = 'desc'
    },

    setOrderColumn({ orderColumn }) {
      if(orderColumn == this.orderColumn) {
        if(this.orderDirection == 'asc') {
          this.orderDirection = 'desc'
        } else {
          this.orderDirection = 'asc'
        }
      } else {
        this.orderColumn = orderColumn
        this.orderDirection = 'asc'
      }
    },

    toggleMeasure(studyDataRow) {
      const findedMeasureAddedIndex = findIndex(this.measureCombinations, { measure: studyDataRow.measure })
      if(findedMeasureAddedIndex > -1) {
        this.measureCombinations.splice(findedMeasureAddedIndex, 1)
        studyDataRow.measure_is_added = false
      } else {
        this.measureCombinations.push(studyDataRow)
        studyDataRow.measure_is_added = true
      }
      this.checkCombinedMeasures()
    },

    getAllPossibleMeasures() {
      if(!this.vintage || !this.prototype) return
      return this.$api.post(`/api/measure/get_all_possible`, { 
        vintage_id: this.vintage.id, 
        prototype_id:  this.prototype.id, 
        climate_zone_raw: this.climate_zone_raw
      })
        .then(({ data }) => {
          this.allPossibleMeasures = data.measures
          this.checkCombinedMeasures()
        })
    },

    toggleAllPossibleMeasures({ combinedMeasureFilterSelected }) {
      this.measureCombinations = []

      this.studyDataHere.forEach((studyDataRow) => {
        studyDataRow.measure_is_added = false
      })
      
      if(combinedMeasureFilterSelected == 'none')  {
        this.checkCombinedMeasures()
        return
      }

      for(const measureToAdd of this.allPossibleMeasures) {
        const studyDataRowFinded = find(this.studyDataHere, { measure_id: measureToAdd.id})
        if(studyDataRowFinded) {
          this.measureCombinations.push(studyDataRowFinded)
          studyDataRowFinded.measure_is_added = true
        }
      }
      this.checkCombinedMeasures()
    },

    checkCombinedMeasures() {
      
      this.studyDataHere.forEach((studyDataRow) => {
        if(!studyDataRow.measure) {
          studyDataRow.measure_could_be_combined = false
          return
        }
        
        if(!studyDataRow.measure.include_in_combination) {
          studyDataRow.measure_could_be_combined = false
          return
        }

        for(const item of this.measureCombinations) {
          if(item.measure.exclude_measures && item.measure.exclude_measures.includes(studyDataRow.measure.id)){            
            studyDataRow.measure_could_be_combined = false
            studyDataRow.measure_combination_conflict = item.measure
            return
          }
          if(studyDataRow.measure.exclude_measures && studyDataRow.measure.exclude_measures.includes(item.measure.id)){
            studyDataRow.measure_could_be_combined = false
            studyDataRow.measure_combination_conflict = item.measure
            return
          }
        }

        studyDataRow.measure_combination_conflict = false
        return studyDataRow.measure_could_be_combined = true
      })

      this.$emit('update:combined-measures-count', this.measureCombinations.length)
      this.checkMeasuresThatCouldBeCombinedWithCombinedMeasures()
      
    },

    getAllMeasuresIdThatCouldBeCombined() {

      const measures = []

      for(const studyDataRow of this.studyDataHere) {
        let could = true
        if(!studyDataRow.measure.include_in_combination) could = false

        for(const item of measures) {
          if(item.measure.exclude_measures && item.measure.exclude_measures.includes(studyDataRow.measure.id)) could = false
          if(studyDataRow.measure.exclude_measures && studyDataRow.measure.exclude_measures.includes(item.measure.id)) could = false
        }
        if(could) {
          measures.push(studyDataRow)
        }
      }

      return measures.map((i) => i.id)
    },

    checkMeasuresThatCouldBeCombinedWithCombinedMeasures() {
      if(this.study.type_content == 'New Buildings' || !this.measureCombinations.length) return this.$emit('updateDataFilterSelected', 'none')

      const combinedMeasuresIds = this.measureCombinations.map((item) => item.measure_id)

      const itemsMatched = this.allPossibleMeasures.filter((measure) => combinedMeasuresIds.includes(measure.id))

      const isEqual = itemsMatched.length === this.allPossibleMeasures.length ? true : false

      if(isEqual) return this.$emit('update:dataFilterSelected', 'combine_all_possible')
      if(!isEqual && !combinedMeasuresIds.length) return this.$emit('update:dataFilterSelected', 'none')
      return this.$emit('update:dataFilterSelected', 'custom')
    },

    addMeasuresPreselected() {
      this.measureCombinations = []
      for(const measureId of this.measuresPreSelectedIds) {
        const studyData = this.studyDataHere.findInArray({ measure_id: measureId })
        if(studyData) {
          studyData.measure_is_added = true
          this.measureCombinations.push(studyData)
        }
      }
      this.checkMeasuresThatCouldBeCombinedWithCombinedMeasures()
    },

    openAssumptionsDrawer() {
       this.$store.commit('assumptions/openDrawer')
    },
    openProjectionsModal({ columnKey, measure } = {}) {
      const selectedMeasure = measure ?? this.getStudyDataOrdered[0].measure
      
      this.$eventBus.$emit('openProjectionsModal', { 
        studyData: this.getStudyDataOrdered,
        columnSelectedKey: columnKey, 
        prototype: this.prototype,
        measure: selectedMeasure, 
        vintage: this.vintage,
        building_stock_units : this.building_stock_units 
      })
    }
  }
}
</script>

<style lang="scss">

  $cell-padding-vertical: 12px;
  $cell-padding-horizontal: 12px;
  $cell-padding-horizontal-half: 8px;

  .forecast-card {
    &.not-expanded {
      .table-wrapper {
        overflow: hidden;
      }
    }

    &--body {
      &:after {
        content: "";
        position: absolute;
        top: 0; left: 0; bottom: 0; right: 0;
        z-index: 30;
      }
    }

    &.is-expanded {
      .forecast-card--body {
        max-height: 100%;

        &::after {
          display: none;
        }
      }    
    }
  }

  .table-wrapper {
    min-height: 265px;
    max-height: calc(100vh - 300px);
    overflow: scroll;
    position: relative;

    tr {
      th, td {
        &:first-child {
          box-shadow: inset -1px 0px 0px #EBEEF0;
          max-width: 300px;
        }
      }
    }
    
    thead {
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.04), 5px 2px 5px rgba(0, 0, 0, 0.08);

      tr {
        th {
          padding-top: 8px;
          padding-bottom: 8px;
          padding-left: 24px;
          padding-right: 24px;

          &.psui-text-xsmall {
            line-height: 120%;
          }

          &:not(.sticky) {
            position: relative;
          }

          &:nth-child(1) {
            padding-left: 0;
          }

          &:not(:nth-child(2)) {
            padding-left: 0;
          }
        }

        &:first-of-type {

          th {
            &:after {
              content: "";
              position: absolute;
              left: 0; bottom: 0px;
              width: calc(100% - 24px); height: 1px;
              display: block;
              box-shadow: inset 0 -1px 0 #d6dde5;
            }

            &:nth-child(2) {
              padding-left: 24px;

              &:after {
                width: calc(100% - 48px);
                left: 24px;
              }
            }

            &:not(:nth-child(2)) {
              padding-left: 0;
            }

            &:last-child {

              &:after {
                left: 0;
                width: 100%;
              }
            }
          }
        }
        
        &:last-of-type {
          th {
            padding-bottom: $cell-padding-vertical;
          }
        } // &:last-of-type
      }
    }

    th {
      padding: 8px 24px 0 0;

      .info-helper {
        &[size="14"] {
          span {
            line-height: 14px;
            &.icon { 
              font-size: 14px; 
            }
          }
        }
        &[size="16"] {
          span {
            line-height: 16px;
            &.icon { 
              font-size: 16px;    
            }
          }
        }
      }
    }

    td {
      padding-left: 24px;
      padding-right: 24px;
    }

    tbody {
      tr {
        border-bottom: 1px solid #E6ECF2;
        
        td {
          background: #ffffff;

          &:first-child {
            padding-left: 12px;
          }
        }

        &:hover {
          td {
            background-color: #F3F6F9;
          }
        }

        &.row-customcombination {
          border-bottom: 0;
          td {
            background-color: #E0EFF6;

            .bars.line {
              background-color: #fff !important;
            }

            p {
              color: #318FAC;
            }

            &:first-of-type {
              box-shadow: inset -1px 0px 0px #C5DEE8;
            }
          }
        }

        &.row-added {
          td {
            transition: none;
            background-color: #ECF7FB;
          }
        }
      }
    }

    @media screen and (min-height: 900px) {
      max-height: 540px;
    }
  }    

</style>
