import numWords from "num-words"
import dayjs from 'dayjs'

export const parseClimateZonesToInteger = (items) => {
  return items.map((item) => parseInt(item.split("-").shift()))
}

export const parseIntegerToSentence = ({ value, valueToApplyMultiple }) => {
  if (value >= valueToApplyMultiple) {
    return "multiple"
  } else {
    return numWords(value)
  }
}

export const parsejurisdictionAsFullText = (jurisdiction, capitalize = false) => {
  if (jurisdiction) {
    if (jurisdiction.type == "City") {
      if(capitalize) return `City of ${jurisdiction.title}`
      return `city of ${jurisdiction.title}`
    } else {
      return `${jurisdiction.title} County`
    }
  } else {
    return false
  }
}


export const slugify = (value) => {
  let slug = ""
  if (Array.isArray(value)) {
    return slugify(value.map(slugify))
  } else {
    slug = value.toLowerCase().trim()
    slug = slug.replace(/[^\w\s-]/g, "")
    slug = slug.replace(/\s+/g, "-")
    return slug
  }
}

const defaultPercentageFormatter = {
  style: "percent",
  minimumFractionDigits: 1,
  maximumFractionDigits: 2,
}

export const convertToPercentageIfIsANumber = ( { value, maxValue, numberFormatter, maximumFractionDigitsIfHigherThanOne } = {}) => {
  
  if (typeof value === "number") {
    
    const valueAsPercentage = value / maxValue
    let formatter = { ...defaultPercentageFormatter, ...numberFormatter}

    if (maximumFractionDigitsIfHigherThanOne && valueAsPercentage > 0.01 ) {
      formatter = { ...formatter, maximumFractionDigits : 1 }
    }
    const percentageFormatter = new Intl.NumberFormat("en-US", formatter )

    return percentageFormatter.format(valueAsPercentage)
  } else {
    return value
  }

}

export const formatAsPercentage = (value) => {
  const percentageFormatter = new Intl.NumberFormat("en-US", defaultPercentageFormatter )
  return percentageFormatter.format(value)
}

const numberFormatter = new Intl.NumberFormat("en-US")
export const formatAsNumber = (value) => {
  return numberFormatter.format(value)
}

export const capitalizeString = (s) => {
  if (typeof s !== "string") return ""
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export const copyUrlToClipboard = () => {
  const dummy = document.createElement("input"),
    text = window.location.href
  document.body.appendChild(dummy)
  dummy.value = text
  dummy.select()
  document.execCommand("copy")
  document.body.removeChild(dummy)
}

export const copyToClipboard = (string) => {
  const dummy = document.createElement("input")
  document.body.appendChild(dummy)
  dummy.value = string
  dummy.select()
  document.execCommand("copy")
  document.body.removeChild(dummy)
}

export const scaleDimensions = ({ width, height, maxWidth, maxHeight } = { }) => {
  
  function dimensionsByScale(scale) {
    return { width: width * scale, height: height * scale }
  }

  let scale, dimensions

  if (width < maxWidth ) {
    scale = maxWidth / width
  } else {
    scale = maxHeight / height
  }

  dimensions = dimensionsByScale(scale)
  if (dimensions.width > maxWidth) {
    scale = maxWidth / width
    dimensions = dimensionsByScale(scale)
  } else if (dimensions.height > maxHeight) {
    scale = maxHeight / height
    dimensions = dimensionsByScale(scale)
  }
  
  // console.table({ width, height, scaledWidth : dimensions.width, scaledHeight: dimensions.height, maxWidth, maxHeight })
  return dimensions
}

export const getBaseUrlImageDimensions = (baseUrl) => {
  return new Promise(done => {
    const i = new Image
    i.onload = () => {
      done({ width: i.width, height: i.height })
    }
    i.src = baseUrl
  })
}


export const defaultManualTooltipOptions = { 
  html: true, 
  placement: 'top-center', 
  show: true, 
  trigger: 'manual', 
  hideOnTargetClick: false, 
  autoHide: false, 
  popperOptions : {
    modifiers: { 
      preventOverflow: { enabled: false },
      hide: { enabled: false } 
    }
  }
}

export const formatDate = ({date, format = 'MM/DD/YYYY', from = 'YYYY-MM-DD', lang = 'en'}) => {
  if (!date) return ''
  dayjs.locale(lang)
  return dayjs(date, from).format(format)
}