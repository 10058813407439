export default {
  setTutorialShow(state, { type, show = null }) {
    if(show != null) {
      state[type].show = show
    } else {
      state[type].show = !state[type].show
    }
    state[type].currentPosition = null
    state[type].contentShow = true
  },
  setContentShow(state, type, showContent = null) {
    if(showContent != null) {
      state[type].contentShow = showContent
    } else {
      state[type].contentShow = !state[type].contentShow
    }
    state[type].currentPosition = null
  },
  setTutorialCurrentPosition(state, { type, position = null }) {
    if (state[type].currentPosition === position) {
      state[type].currentPosition = null
    } else {
      state[type].currentPosition = position
    }
  }
}