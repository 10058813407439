import Vue from 'vue'
import * as Sentry from "@sentry/vue"
import { Integrations } from "@sentry/tracing"

export default () => {
  if (['production', 'staging'].includes(process.env.VUE_APP_ENV) && window.location.hostname !== 'localhost') {
    Sentry.init({
      Vue,
      dsn: "https://a9e93a06007640748301dd488f85a6bc@o524706.ingest.sentry.io/5637549",
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 1.0,
    })
  }
}