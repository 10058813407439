<template>
  <div class="w-full p-6">
    <table class="table table-bordered mt-6 whitespace-no-wrap">
      <thead>
        <tr>
          <td>
            Policy ID <OrderByArrowToggle
              column="id"
              :order-by="orderBy"
              @setOrderBy="setOrderBy"
            />
          </td>
          <td>User</td>
          <td>Custom combination qty</td>
          <td>
            Created at <OrderByArrowToggle
              column="id"
              :order-by="orderBy"
              @setOrderBy="setOrderBy"
            />
          </td>
          <td class="psui-w-32">
            Actions
          </td>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="policy in policies"
          :key="policy.id"
        >
          <td>{{ policy.id }}</td>
          <td>{{ policy.user.email }}</td>
          <td>{{ policy.custom_combinations.length }}</td>
          <td>{{ policy.created_at }}</td>
          <td class="psui-flex">
            <router-link 
              :to="{ name: 'PolicyShow', params: { policy_id: policy.id }}"
              target="_blank"
            >
              <PsButton 
                label="Show"
                size="medium"
                icon="open_in_new"
              />
            </router-link>
          </td>
        </tr>
      </tbody>
    </table>

    <Paginator
      v-model="offset"
      :length="policies.length"
      :limit="limit"
      @getItems="getPolicies({ offset })"
    />
  </div>
</template>

<script>
export default {
  name: 'AdminUsersList',
  data() {
    return {
      policies: [],
      offset: 0,
      limit: 12,
      orderBy: {
        column: "id",
        order: "ASC"
      }
    }
  },
  mounted() {
    this.getPolicies()
  },
  methods: {

    getPolicies({ offset = 0 } = {}) {
      this.offset = offset
      
      const query = `
        { 
          policies (
            orderBy: [{ column: "${this.orderBy.column}", order: "${this.orderBy.order}" }]
            ${this.$route.query.user_id ? `where: [{ column: "user_id", operation: "=", value: "${this.$route.query.user_id}"}]` : ''}
            paginate: { offset: ${offset}, limit: ${this.limit}}
          ) {
            id
            title
            created_at
            updated_at
            user { email }
            custom_combinations {
              id
            }
          }
        }
      `

      this.$graphql({ query })
        .then(({ data }) => {
          this.policies = data.policies
        })
    },

    setOrderBy(column) {
      console.log(column)
      if(this.orderBy.column == column) {
        this.orderBy.order = this.orderBy.order == 'ASC' ? 'DESC' : 'ASC'
      } else {
        this.orderBy.column = column
        this.orderBy.order = 'ASC'
      }
      this.getPolicies()
    }
  }
}
</script>

<style>

</style>