export default class DeviceInfo {
  constructor(obj = {}) {
    this.id = obj.id || null
    this.uid = obj.uid || null
    this.os = obj.os || null
    this.platform = obj.platform || null
    this.browser = obj.browser || null
    this.version = obj.version || null
    this.is_mobile = obj.is_mobile || false
    this.session_count = obj.session_count || 0
    this.last_session_at = obj.last_session_at || 0
    this.is_admin = obj.is_admin || false
    this.is_team = obj.is_team || false
  }
}