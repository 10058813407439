
/**
 * 
 * get amount of a given % of a value
 */
export const getAmountOfPercentage = (amount, percentage) => {
  return amount / 100 * percentage
}

/**
 * 
 * get the % of a given amount and value
 */
export const getPercentageOfAmount = (amount, percentage) => {
  return 100 / amount * percentage
}

/**
 * add a raw percentage value to a number
 */
export const addPercentage = (value, percentage) => {
  return value * (1 + (parseFloat(percentage) / 100))
}

/**
 * 
 * returns a min value using a percentage as references
 */
export const getMinPercentageOfAmount = (total, amount, percentage = 10) => {
  if((total / 100 * percentage) > amount) return getAmountOfPercentage(total, percentage)
  return amount
}

export default {
  getAmountOfPercentage,
  getPercentageOfAmount,
  addPercentage,
  getMinPercentageOfAmount
}