<template>
  <div>    
    <PsRichTooltip
      v-if="isDisabled"
      title="Please select at least one measure"
      layout="red"
    >
      <template v-slot:trigger>
        <PsButton
          size="big"
          icon="text_snippet"
          label="Save to Policy"
          :disabled="isDisabled"
        />
      </template>
    </PsRichTooltip>
    <PsButton
      v-else
      size="big"
      icon="text_snippet"
      label="Save to Policy"
      @click="$emit('click')"
    />
  </div>
</template>

<script>
export default {
  name: 'CustomCombinationToPolicyButton',
  props: {
    measuresCount: {
      type: Number,
      default: 0
    }
  },
  computed: {
    isDisabled() {
      return this.measuresCount <= 0
    },
    getTooltip() {
      return this.isDisabled ? false : ''
    }
  },
}
</script>
