<template>
  <BaseModal
    ref="baseModal"
    modal-dialog-classes="w-full max-w-sm"
    title="Duplicate to My Policies"
    :show-modal-parent.sync="showModalParent"
  >
    <UserSignForm
      v-if="!$store.state.auth.loggedUser && showModalParent"
      @close="close"
    />

    <div
      v-else-if="$store.state.auth.loggedUser && policy_id"
      class="w-full flex flex-col items-start mt-6"
    >
      <p class="text-deepsky-900 text-accent-1 font-bold mb-3">
        Rename Your Policy
      </p>
      <InputWithLabel
        v-model="title"
        :label="'Policy Name'"
        :validation="validationTitle"
        @keydown="duplicatePolicy"
      />

      <AppButton
        class="mt-6"
        label="Duplicate"
        size="big"
        @click="duplicatePolicy()" 
      />
    </div>
  </BaseModal>
</template>

<script>

import UserSignForm from '@/modules/app/login/components/UserSignForm.vue'
export default {
  name: 'ModalDuplicatePolicy',
  components: { UserSignForm },
  data: () => ({
    policy_id : false,
    title: '',
    showModalParent: false
  }),
  computed: {
    validationTitle() {
      if(!this.title) return { hasError: true, label: 'Please inform the Policy Name'}
      return { hasError: false }
    }
  },
  mounted() {
    this.$eventBus.$on('openDuplicatePolicyModal', ({ policy }) => {
      this.showModalParent = true
      this.policy_id = policy.id
      this.title = policy.title + ` (2)`
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('openDuplicatePolicyModal')
  },
  methods: {
    duplicatePolicy() {
      this.$api.post(`/api/policies/duplicate/${this.policy_id}`, { title: this.title })
        .then(({ data }) => {
          this.showModalParent = false
          this.policy_id = null
          this.$appToast({ message: 'Policy duplicated. Now you can edit it.' })
          this.$router.push(`/policies/${data.policy.id}`)
          this.$eventBus.$emit('getUserPolicies')
        })
    },
    close() {
      this.showModalParent = false
    }
  }
}
</script>