<template>
  <div class="w-full  flex flex-col">
    <h4 class="psui-text-h5 psui-text-gray-80 font-bold whitespace-no-wrap">
      Top visited jurisdictions
    </h4>
    <div class="block w-full">
      <ApexChartGeneral 
        :options="getChartOptions()"
        :palette-color-index="0"
      />
    </div>
  </div>
</template>

<script>
import { combinationColors } from '@/business-logic/constants/chartDefaultOptions'
import MathFunctions from '@/util/MathFunctions'
export default {
  name: 'AdminDashboardTopJurisdiction',
  data() {
    return {
      combinationColors,
      top_jurisdictions_visited: []
    }
  },
   computed: {
    
    getTotal() {
      return this.top_jurisdictions_visited.reduce((acc, item) => acc + parseInt(item.views_count), 0)
    },

    getChartSeries() {
      const data = this.top_jurisdictions_visited.map((jurisdiction, index) => {
        return {
          x: jurisdiction.title,
          y: jurisdiction.views_count,
          jurisdiction,
          fillColor: this.combinationColors[0][index]
        }
      })
      return [{ data }]
    }
  },

  mounted() {
    this.getData()
  },

  methods: {

    getData() {
      this.$api.get('/api/admin/dashboard/get_data/top_jurisdictions_visited')
        .then(({ data }) => {
          this.top_jurisdictions_visited = data.top_jurisdictions_visited
        })
    },

    getChartOptions() {
      const self = this
      return { 
        chart: {
          type: 'bar',
          stacked: false,
          toolbar: {
            show: true,
            tools: {
              download: true,
              selection: true
            }
          }
        },
        series: this.getChartSeries, 
        yaxis: {
          labels: {
            formatter: function(value) {
              return self.formatUnits(value)
            }
          },
        },
        tooltip: {
          custom: function ({ seriesIndex, dataPointIndex }) {
            const item = self.getChartSeries[seriesIndex].data[dataPointIndex]
            return `
              <div class="psui-flex" style="background-color: ${ item.fillColor } !important;">
                <div class="apexcharts-tooltip-content psui-w-full psui-bg-white psui-p-4">
                  <h4 class="psui-text-gray-80 psui-text-xsmall psui-font-bold">${ item.jurisdiction.title } ${item.jurisdiction.type}</h4>
                  <div class="psui-w-full psui-flex psui-items-center justify-between space-x-2">
                    <h2 class="psui-text-gray-80 psui-text-small">${ self.formatUnits(item.jurisdiction.views_count) } Views</h2>
                    <span class="psui-text-gray-30 psui-mx-1">|</span>
                    <h2 class="psui-text-gray-50 psui-text-small">${ self.formatNumber(MathFunctions.getPercentageOfAmount(self.getTotal, item.jurisdiction.views_count)) }%</h2>
                  </div>
                </div>
              </div>
            `
          }
        }
      }
    }
  }
}
</script>