<template>
  <div class="page-home w-full px-16 py-20 h-full flex flex-col overflow-y-auto">
    <router-link
      to="/"
      class="logo-wrapper"
    >    
      <img
        src="/logo/cee_black.svg"
        width="188px"
        height="auto"
        alt="Logo Cost Effectiveness Explorer"
      >
    </router-link>
    
    <div class="w-full wrapper">
      <h1 class="psui-text-h1 font-bold psui-text-blue-80 mb-10">
        Explore cost-effectiveness results, create policies, and forecast impacts for your city or county in California.
      </h1>
      <JurisdictionSelect 
        :should-scroll="true"
        :input-class="'text-header-5'"
        :width="300"
        label="Start typing the name of your city or county"
        icon="search"
        icon-class="psui-text-blue-60"
        @change="onJurisdictionSelected"
      />      
      <p class="psui-text-small psui-text-gray-50 ml-1">
        E.g.: Chula Vista
      </p>
    </div>

    <p class="text-p psui-text-gray-50 flex items-center">
      <span
        v-if="!jurisdictionsByPolicies"
        class="shimmer h-4 w-8 inline-block rounded-xs mr-2"
      />
      <template v-else>
        {{ jurisdictionsByPolicies }}
      </template>
      California jurisdictions have already created draft policies on this tool
    </p>
    
    
    <div class="wrapper-logo-program ml-auto mt-auto">
      <div class="w-full flex items-center justify-between">
        <p class="text-left text-header-2 psui-text-gray-70">
          Find more reach code resources at 
          <a
            class="psui-text-blue-60"
            href="https://localenergycodes.com"
            target="_blank"
            title="Statewide Reach Codes Program"
          >LocalEnergyCodes.com</a>
        </p>
        <div class="w-1/5 flex justify-center">
          <span class="vertical-divider h-12 bg-sec" />
        </div>
        <a
          class="logo"
          href="https://localenergycodes.com"
          target="_blank"
          title="Statewide Reach Codes Program"
        >
          <img
            src="/logo/logo-state-program-light-background.png"
            alt="California Energy Codes & Standards Logo"
          >
        </a>
      </div>
    </div>
  </div>  
</template>

<script>
import { quickGuideOptions } from '@/util/Drawers'
export default {
  name: 'AppHome',
  data: () => ({
    jurisdictionsByPolicies: null,
  }),
  mounted() {
    this.updateJurisdictionsByPolicies()
    this.redirectIfHasJurisdictionAndNotComingFromTheLogo()
  },
  methods: {
    onJurisdictionSelected(jurisdiction) {
      this.$store.dispatch('setUserLastJurisdictionAndFirstClimateZoneVisited', { value: jurisdiction, context: 'updateLastJurisdictionVisitedFromHome'})
      this.$router.push({ name: 'Summary', params: { jurisdiction_slug: jurisdiction.slug }})
    },
    openQuickGuide() {      
      this.$eventBus.$emit('openDrawerContent', quickGuideOptions )
    },
    async updateJurisdictionsByPolicies() {
      const query = `
        {
          policies (
            distinct : ["jurisdiction_id"]
          ) {
            jurisdiction_id
          }
        }
      `
      const { data } = await this.$graphql({ query })
      setTimeout(() => {
        if (data.policies) this.jurisdictionsByPolicies = data.policies.length
      }, 1500)
    },
    redirectIfHasJurisdictionAndNotComingFromTheLogo() {
      const jurisdiction = this.$store.getters['lastJurisdictionVisited']
      if (jurisdiction && this.$route.query.utm_source !== 'explorer-sidebar') {
        this.$router.push({ name: 'Summary', params: { jurisdiction_slug: jurisdiction.slug }})
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .wrapper-logo-program {
    max-width: 340px;
  }
  .logo-wrapper {
    margin-left: -4px;
  }
  .page-home {
    padding-left: 10%;
    padding-right: 10%;
  }
  .wrapper {
    max-width: 620px;
    margin-top: auto;
    padding-top: 115px;
    margin-bottom: 80px;
  }
  .page-home {
    ::v-deep {
      .jurisdiction--select {
        margin-top: 36px;
        .input-wrapper {
          min-height: 49px;
          input {
            width: 100%;
            padding: 8px;
            &::placeholder {
              color: #318FAC;
            }
          }
        }
      }
    }
  } 
  
</style>