import BaseModel from './BaseModel'

export default class StudyMap extends BaseModel {
  constructor(obj = {}) {
    super()
    this.id = obj.id || null
    this.study_id = obj.study_id || null
    this.type_prototype_id = obj.type_prototype_id || null
    this.type_vintage_id = obj.type_vintage_id || null
    this.study = obj.study || null
    this.type_prototype = obj.type_prototype || null
    this.type_vintage = obj.type_vintage || null
  }

  get requireds() {
    return [
      { item: 'study_id', label: 'study' },
      { item: 'type_prototype_id', label: 'type_prototype' },
      { item: 'type_vintage_id', label: 'type_vintage' }
    ]
  }

}