import ImpactAssumptions from '@/models/ImpactAssumptions'
import getProjectionsResume from '@/business-logic/services/projections/getProjectionsResume'

export default ({ policy, data, building_stock_units }) => {
      
  const assumption = new ImpactAssumptions(policy)

  const projectionsCityWideImpactValues = getProjectionsResume({ 
    assumption,
    building_stock_units: building_stock_units,
    kwh_savings: data.kwh_savings,
    therms_savings: data.therms_savings,
    annual_bill_savings: data.annual_bill_savings,
    initial_cost: data.initial_cost
  })

  Object.keys(projectionsCityWideImpactValues).map((col) => {
    data[col] = projectionsCityWideImpactValues[col]
  })

}