<template>
  <div 
    class="app--toggle"
    :class="{ active, 'app--toggle-disabled' : disabled }"
    @click="change()"
  >
    <div class="app--toggle--wrapper">      
      <input
        type="checkbox"
        :checked="active"
      >
      <div class="app--toggle--ui" />
    </div>
    <div
      v-if="text"
      class="app--toggle--label"
      :class="labelClass"
    >
      {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    'label-actived': String,
    active: {
      type: Boolean,
      default: false
    },
    labelClass: {
      type: String,
      default: 'ts--accent--2 flex-shrink-0'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    text() {
      if (this.label && this.labelActived) {
        return this.active ? this.labelActived : this.label
      } else if (this.label) {
        return this.label
      } else {
        return false
      }
    }
  },
  methods: {
    change() {
      if (!this.disabled) {
        this.$emit('update:active', !this.active)
        this.$emit('change', !this.active)        
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .app--toggle {    
    cursor: pointer;
    box-sizing: border-box;
    border-radius: 30px;
    display: flex;
    transition: 350ms ease-in-out all;
    
    &--wrapper {
      display: flex;
      align-items: center;
    }    

    &--ui {
      width: 35px;
      height: 14px;
      background: #E4EEF2;
      border-radius: 8px;
      position: relative;
      &:before {
        content: "";
        width: 21px;
        height: 21px;        
        background: #F7F7F7;
        border: 3px solid #4B8CA6;
        border-radius: 30px;
        display: block;
        position: absolute;
        top: -3px;
        left: 0;
        transition: 350ms ease-in-out all;
      }
    } // ui
    
    &--label {
      margin-left: 6px;
    }

    input {
      display: none;
      &:checked + .app--toggle--ui:before {
        left: calc(100% - 21px);
      }
    }
    

    &.active {
      
      .app--toggle--ui {
        background-color: #4B8CA6;
        &:before {
          border-color: #82C2DD;
          background-color: #F7F7F7;
        }
      }
    }

  
    &.app--toggle-disabled {
      cursor: default;
      .app--toggle--ui {
        background: #F5F5F5;
        &:before {
          left: 0 !important;
          border-color: #D4D8D9;
          background-color: #F5F5F5;
        }
      }
    }


  }
</style>