<template>
  <div class="w-full bg-gray06 py-7">
    <PolicyShowHeader
      :get-policy-selected="getPolicySelected"
      @onOpenAssumptionsSidebar="onOpenAssumptionsSidebar"
    />
    <div class="policy-show-tabs w-full flex flex-col px-4 sm:px-10 sticky z-20 bg-gray06">
      <div class="w-full flex space-x-4">          
        <router-link to="scope">
          <div
            class="text-header-2 font-bold py-3 border-b-3 transition-all flex items-center"
            :class="[( getActiveTab == 'scope') ? 'text-deepsky-450 border-deepsky-450' : 'text-ash-600 border-transparent hover:text-deepsky-450 hover:border-deepsky-450']" 
            @click="setPolicyActiveTab('scope')"
          >
            <feather
              type="edit"
              size="16"
              class="mr-2"
            /> Scope
          </div>
        </router-link>
        <router-link
          v-if="!getPolicyIsEmpty"
          to="impact"
        >
          <div
            class="text-header-2 font-bold py-3 border-b-3 transition-all" 
            :class="[( getActiveTab == 'impact') ? 'text-deepsky-450 border-deepsky-450' : 'text-ash-600 border-transparent hover:text-deepsky-450 hover:border-deepsky-450']" 
            @click="setPolicyActiveTab('impact')"
          >
            Impact and Details
          </div>
        </router-link>
        <ToolTip 
          v-if="!getPolicyIsEmpty"
          ref="flexiblePathNewModuleAlert" 
          dialog-classes="p-4 left-0 bg-lime-500 border border-blue05 max-w-sm"
          :arrow="{ borderBottomColor: '#62BC87'}"
          :ignore-dialog="hasVisitedFlexiblePaths"
          :keep-open="!hasVisitedFlexiblePaths"
        >
          <template v-slot:trigger>
            <router-link to="flexible_path">
              <div
                class="text-header-2 font-bold py-3 border-b-3 transition-all"
                :class="[( getActiveTab == 'flexible_path') ? 'text-deepsky-450 border-deepsky-450' : 'text-ash-600 border-transparent hover:text-deepsky-450 hover:border-deepsky-450']" 
                @click="setPolicyActiveTab('flexible_path')"
              >
                Flexible Paths
                <div
                  v-if="!hasVisitedFlexiblePaths"
                  id="badge-flexible-path"
                  class="bg-lime-200 rounded-sm p-1"
                >
                  <div class="bg-lime-300 rounded-sm p-1">
                    <div class="bg-lime-400 text-header-1 font-bold rounded-sm px-1">
                      <p class="text-white">
                        New
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </router-link>
          </template>
          <template v-slot:dialog>
            <div class="flex p-3">
              <div>
                <inline-svg
                  :width="150"
                  src="/images/flexible_paths/woman-show-graphs.svg"
                />
              </div>
              <div>
                <p class="text-header-2 text-ash-0 mb-3 w-260">
                  Provide some compliance flexibility to building owners allowing them to choose from a menu of measures and select the ones that work best for them
                </p>
                <span 
                  class="inline-block bg-lime-400 text-ash-0 rounded-lg px-3 font-bold cursor-pointer transition-all hover:bg-lime-600"
                  @click="onClickGotIt()"
                >
                  Got it!
                </span>
              </div>
            </div>
          </template>
        </ToolTip>
      </div>
    </div>

    <PolicyImpact 
      v-if="getActiveTab == 'impact' && !getPolicyIsEmpty" 
      :key="$route.fullPath" 
      :policy="getPolicySelected"
      :policy_summary_data="policy_summary_data"
      class="px-4 sm:px-10"
      @open-assumptions-applicability-rate="onOpenAssumptionsSidebar({ showCardOnInit: 'applicability_rate' })"
    />
    <PolicyFlexiblePath 
      v-if="getActiveTab == 'flexible_path'"
      :key="$route.fullPath"
      :policy="getPolicySelected"
    />
    
    <PolicyScope 
      v-if="getActiveTab == 'scope'"
      :key="$route.fullPath"
      :policy="getPolicySelected"
    />

    <PolicyEditCustomCombination 
      v-if="getActiveTab == 'edit_policy' && userCanEdit"
      :key="$route.fullPath"
      :policy="getPolicySelected"
    />
    <div
      v-else-if="getActiveTab == 'edit_policy' && !userCanEdit"
      class="p-20"
    >
      <h2>You are not able to edit this custom combination.</h2>
    </div>
  
    <DrawerPolicyEditColumns />
  </div>
</template>

<script>
import PolicyShowHeader from '@/modules/app/policy/show/PolicyShowHeader'
import DrawerPolicyEditColumns from './DrawerPolicyEditColumns'
import PolicyImpact from './impact/PolicyImpact'
import PolicyFlexiblePath from './flexible-path/PolicyFlexiblePath'
import PolicyScope from './scope/PolicyScope'
import PolicyEditCustomCombination from './PolicyEditCustomCombination'
import PolicyApiService from '@/services/api/PolicyApiService'
import generatePolicyItemsTotal from '@/business-logic/services/policies/generatePolicyItemsTotal'
export default {
  name: 'PolicyShowIndex',
  components: { 
    PolicyShowHeader,
    DrawerPolicyEditColumns, 
    PolicyImpact, 
    PolicyFlexiblePath,
    PolicyScope,
    PolicyEditCustomCombination,
  },
  metaInfo() {
    const getPageTitle = this.getPageTitle
    return {
      title() {
        return getPageTitle
      }
    }
  },
  data() {
    return {
      isLoading: false,
      policy_summary_data: []
    }
  },
  computed: {
    getActiveTab() {
      if(!this.$route.params.policy_active_tab) return 'scope'
      return this.$route.params.policy_active_tab
    },
    hasVisitedFlexiblePaths() {
      return this.$store.getters.hasVisitedModule('flexible_paths')
    },
    getLastJurisdictionVisited() {
      return this.$store.getters['lastJurisdictionVisited'] ?? false
    },
    getPageTitle() {
      if(!this.getLastJurisdictionVisited) return `Policy`
      if(this.policy) return `Policy ${this.policy.title} for ${this.getLastJurisdictionVisited?.title_type} | `
      return `Policy for ${this.getLastJurisdictionVisited?.title_type} | `
    },
    getPolicyIsEmpty() {
      return this.policy_summary_data[0]?.items?.length === 0
    },
    getPolicySelected() {
      return this.$store.getters['assumptions/getterPolicyImpactAssumptions']
    },
    userCanEdit() {
      return this.$store.getters['getterLoggedUser']?.id == this.getPolicySelected.user_id
    }
  },
  watch: {
    '$route.params.policy_id': {
      immediate: true,
      handler() {
        this.getPolicy()
        this.$store.commit('assumptions/setPolicyIdSelected', this.$route.params.policy_id)
      }
    }
  },
  mounted() {
    this.$eventBus.$on('policyUpdated', this.generatePolicyItems)
    this.$eventBus.$on('getPolicy', this.getPolicy)
    this.logUserEvent()
    // this.$store.dispatch('assumptions/storeBatchAllCustomBuildings')
  },
  updated() {
    if(this.$refs.flexiblePathNewModuleAlert) this.$refs.flexiblePathNewModuleAlert.open()
  },
  beforeDestroy() {
    this.$eventBus.$off('policyUpdated')
    this.$eventBus.$off('getPolicy')
  },
  methods: {

    getPolicy() {
      this.$store.dispatch('assumptions/getPolicy', this.$route.params.policy_id)
        .then(() => {
          this.generatePolicyItems()
          this.$store.dispatch('assumptions/getAllCustomBuildings')
        })
    },

    generatePolicyItems() {
      const policy = this.$store.getters['assumptions/getterPolicyImpactAssumptions']
      PolicyApiService.get_summary_data(this.$route.params.policy_id)
        .then(({ policy_summary_data }) => {
          generatePolicyItemsTotal({ policy, items: policy_summary_data }).then(() => {
            this.policy_summary_data = policy_summary_data
            this.$set(this, 'policy_summary_data', policy_summary_data)
            this.isLoading = false
          })
        })
    },

    onPolicyDuplicate({ policy }) {
      this.$appToast({ message: 'Policy duplicated. Now you can edit it.' })
      this.$router.push(`/policies/${policy.id}/impact`)
    },

    onClickGotIt() {
      this.$store.dispatch('setModuleVisited', 'flexible_paths')
      this.$refs.flexiblePathNewModuleAlert.close()
    },

    setPolicyActiveTab(tab) {
      this.$router.push({ ...this.$route, params: { ...this.$route.params, policy_active_tab: tab}})
    },

    onOpenAssumptionsSidebar() {
      this.$store.commit('assumptions/openDrawer')
    },

    logUserEvent() {
      this.$logUserEvent({ event: 'SHOW_POLICY', table: 'policies', table_item_id: this.$route.params.policy_id })
    }
  }
}
</script>

<style lang="scss" scoped>
  .policy-show-tabs {
    border-bottom: 1px solid #EBEEF0;
    top: 95px;
    button {
      position: relative;
      top: 1px;
    }
  }
  #badge-flexible-path {
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(110%) translateY(-50%);
  }
</style>
