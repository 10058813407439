
export default class BuildingEstimate {
  constructor(obj) {
    this.jurisdiction_id = obj.jurisdiction_id || null
    this.climate_zone_prefix = obj.climate_zone_prefix || null
    this.type_prototype_id = obj.type_prototype_id || null
    this.year = obj.year || null
    this.construction_implementation_type_id = obj.construction_implementation_type_id || null
    this.units = obj.units || 0
    this.building_height_id = obj.building_height_id || null
  }
}