<template>
  <BaseModal
    ref="baseModal"
    :show-modal-parent.sync="showModalParent"
    :modal-dialog-classes="isUserLoggedIn ? 'w-auto bg-ash-100' : 'w-auto bg-white'"
    @close="close"
  >    
    <UserSignForm
      v-if="!isUserLoggedIn && showModalParent"
      @close="close"
    />
    <AssumptionsModalSaveToPolicy
      v-else-if="!force_wizard && isUserLoggedIn && getterUserPoliciesByJurisdiction && getterUserPoliciesByJurisdiction.length > 0"
      :opened_from="open_modal_referrer"
      @force_wizard="do_force_wizard"
    />
    <PolicyWizard
      v-else
      ref="wizard"
      @close="close"
    />
  </BaseModal>
</template>

<script>
import AssumptionsModalSaveToPolicy from '@/modules/app/assumptions/shared/AssumptionsModalSaveToPolicy.vue'
import PolicyWizard from '@/modules/app/policy/wizard/PolicyWizard.vue'
import UserSignForm from '@/modules/app/login/components/UserSignForm.vue'
import { mapGetters } from 'vuex'
export default {
  name: 'AssumptionsModal',
  components: { 
    PolicyWizard,
    UserSignForm, AssumptionsModalSaveToPolicy
  },
  data: () => ({
    open_modal_referrer: false,
    force_wizard: false,
    showModalParent: false
  }),
  computed: {
    ...mapGetters(['isUserLoggedIn']),
    ...mapGetters('policy', ['getterUserPoliciesByJurisdiction']),
  },
  mounted() {
    this.$eventBus.$on('openAssumptionsModal', (clickReferrer) => {
      // console.log('openAssumptionsModal, Referrer:', clickReferrer)
      this.open_modal_referrer = clickReferrer
      this.open()
    })
    this.$eventBus.$on('closeModalAssumptions', this.close)
    this.$store.dispatch('policy/getUserPolicies')
      // .then((userPolicies) => {
      //   console.log('userPolicies', userPolicies)
      // })
  },
  beforeDestroy() {
    this.$eventBus.$off('openAssumptionsModal')
    this.$eventBus.$off('closeModalAssumptions')
  },
  methods: {
    open() {
      this.$eventBus.$emit('cleanPolicyWizardBreadcrumbs')
      if(this.$refs.wizard) this.$refs.wizard?.resetState()
      this.showModalParent = true
      this.$store.commit('general/setRedirectTo', { 
        route: window.location.pathname, 
        event: { name: 'openAssumptionsModal' }
      })
    },
    close() {
      if(this.$refs.wizard) this.$refs.wizard?.resetState()
      this.showModalParent = false
      this.force_wizard = false
      this.$store.commit('general/setRedirectTo')
      this.$eventBus.$emit('cleanPolicyWizardBreadcrumbs')
    },
    do_force_wizard() {
      this.force_wizard = true

      // Workaround to reset not created component
      const interval = setInterval(() => {
        if(this.$refs.wizard) {
          this.$refs.wizard?.resetState()
          clearInterval(interval)
        }
      }, 100)
    }
  }
}
</script>
