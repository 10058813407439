<template>
  <div>
    <div class="pt-12 px-6 pb-7 psui-shadow">
      <h1 class="psui-text-h6 font-bold psui-text-gray-80 mb-2 mt-1">
        Edit Assumptions
      </h1>
      <p class="psui-text-small psui-text-gray-50">
        Select and configure your assumptions.
      </p>
    </div>
    
    <div class="p-6">
      <h2 class="psui-text-gray-80 font-bold border-b psui-border-blue-70 border-opacity-10 pb-4">
        {{ getImpactAssumptionsTitle }}
      </h2>
      <ul class="psui-border-b psui-border-blue-70 border-opacity-10 psui-divide-y psui-divide-blue-70 psui-divide-opacity-10 mb-8">
        <li
          class="py-4 flex items-center hover:opacity-80 transition-all"
          @click="$emit('update:path', ASSUMPTIONS_PATHS.IMPACT_RESULTS)"
        >
          <span class="flex items-center justify-center psui-rounded psui-bg-gray-10 w-10 h-10 mr-3">
            <i class="material-icons psui-text-blue-60 psui-text-h1 leading-none">tune</i>
          </span>
          <span class="flex-auto leading-none">
            <p class="psui-text-small font-bold psui-text-gray-80">{{ getImpactBtnTitle }}</p>
            <small class="psui-text-xsmall psui-text-gray-50">{{ getImpactBtnSubtitle }}</small>
          </span>
          <i class="material-icons psui-text-gray-40">chevron_right</i>
        </li>
      </ul>

      <h2 class="psui-text-gray-80 font-bold border-b psui-border-blue-70 border-opacity-10 pb-4">
        Building Estimates
      </h2>
      <ul class="psui-border-b psui-border-blue-70 border-opacity-10 psui-divide-y psui-divide-blue-70 psui-divide-opacity-10 mb-8 psui-cursor-default">
        <li 
          class="py-4 flex items-center hover:opacity-80 transition-all"
          @click="onBuildingEstimatesClick(ASSUMPTIONS_PATHS.RESIDENTIAL_BUILDINGS)"          
        >
          <div class="flex flex-row items-center justify-between">
            <div class="flex items-center">
              <span class="flex items-center justify-center psui-rounded psui-bg-gray-10 w-10 h-10 mr-3">
                <i class="material-icons psui-text-blue-60 psui-text-h1 leading-none">{{ ASSUMPTIONS_PATHS.RESIDENTIAL_BUILDINGS.icon }}</i>
              </span>
              <span class="psui-flex psui-flex-col psui-leading-none">
                <p class="psui-text-small font-bold psui-text-gray-80">{{ ASSUMPTIONS_PATHS.RESIDENTIAL_BUILDINGS.title }}</p>
                <small class="psui-text-xsmall psui-text-gray-50">{{ ASSUMPTIONS_PATHS.RESIDENTIAL_BUILDINGS.description }}</small>
              </span>
            </div>
            <i class="material-icons psui-text-gray-40">chevron_right</i>
          </div>
        </li>

        <li 
          class="py-4 flex items-center hover:opacity-80 transition-all"
          @click="onBuildingEstimatesClick(ASSUMPTIONS_PATHS.NON_RESIDENTIAL_BUILDINGS)"
        >
          <div class="flex flex-row items-center justify-between">
            <div class="flex items-center">
              <span class="flex items-center justify-center psui-rounded psui-bg-gray-10 w-10 h-10 mr-3">
                <i class="material-icons psui-text-blue-60 psui-text-h1 leading-none">{{ ASSUMPTIONS_PATHS.NON_RESIDENTIAL_BUILDINGS.icon }}</i>
              </span>
              <span class="psui-flex psui-flex-col psui-leading-none">
                <p class="psui-text-small font-bold psui-text-gray-80">{{ ASSUMPTIONS_PATHS.NON_RESIDENTIAL_BUILDINGS.title }}</p>
                <small class="psui-text-xsmall psui-text-gray-50">{{ ASSUMPTIONS_PATHS.NON_RESIDENTIAL_BUILDINGS.description }}</small>
              </span>
            </div>
            <i class="material-icons psui-text-gray-40">chevron_right</i>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ASSUMPTIONS_PATHS, ASSUMPTIONS_DRAWER_TYPES } from '@/modules/app/assumptions/shared/enums'
import { mapGetters } from "vuex"
import { jurisdiction_TYPES } from "../../../../util/Enums"

export default {
  name: 'AssumptionsIndex',
  props: ['building_types'],
  data: () => ({
    ASSUMPTIONS_PATHS,
  }),
  computed: {
    ...mapGetters(['lastJurisdictionVisited']),
    getAssumptionsOptions() {
      return this.$store.getters['assumptions/getterAssumptionsDrawerOptions']
    },
    getImpactAssumptionsTitle() {
      return this.lastJurisdictionVisited.type === jurisdiction_TYPES.CITY ? 'City-wide Estimates' : 'County-wide Estimates'
    },
    getImpactBtnTitle() {
      return this.getAssumptionsOptions.type === ASSUMPTIONS_DRAWER_TYPES.POLICIES ? 'Policy Assumptions' :
          (this.lastJurisdictionVisited.type === jurisdiction_TYPES.CITY ?
              'City-wide Impact Assumptions' : 'County-wide Impact Assumptions')
    },
    getImpactBtnSubtitle() {
      return this.getAssumptionsOptions.type === ASSUMPTIONS_DRAWER_TYPES.POLICIES ? 'Policy Impacts' : 'Package and Measure Impacts'
    }
  },
    methods: {
    onBuildingEstimatesClick(assumptions_path) {
        this.$emit('update:path', assumptions_path)      
        this.$emit('update:building_type', this.building_types.find(bt => bt.slug === assumptions_path.slug) )
    }
  }
}
</script>

<style lang="scss" scoped>
li:hover {
  cursor: pointer;
}
  li.disabled,
  .disabled span,
  .disabled i,
  .disabled small,
  .disabled p {
    color: #D6DDE5 !important;
    cursor: default !important;
    display: flex;
  }

  ::v-deep li > div > div,
  ::v-deep li > div {
    width: 100%;
    
}

  ::v-deep div > div > p {
    font-weight: 700;
    width: 100%;
    text-align: center;
  }
</style>
