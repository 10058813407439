<template>
  <PsAccordionItem
    title="Active Policy Durations"
    :class="{ 'form-dirty' : hasValueUpdated }"
  >
    Requirements will remain in effect for a period of 
    <DropdownWithInputNumber
      :value="getActivePolicyDuration"
      :min-value="0.25"
      :max-value="60"
      :base-value="activePolicyDurationBaseValue"     
      :button-label="`${getActivePolicyDuration} years`"
      :suffix="`years`"
      :step="0.25"
      @update:value="setActivePolicyDuration"
    />.
  </PsAccordionItem>
</template>

<script>
import DropdownWithInputNumber from '@/components/general/DropdownWithInputNumber.vue'
import { defaultImpactAssumptionValues } from '@/models/ImpactAssumptions'

export default {
  name: 'AssumptionsImpactsActivePolicyDurations',
  components: { DropdownWithInputNumber },
  data: () => ({
    activePolicyDurationBaseValue : defaultImpactAssumptionValues.active_policy_duration,
  }),
  computed: {
    getActivePolicyDuration() {
      return this.$store.state.assumptions[this.$store.state.assumptions.drawerOptions.type]['active_policy_duration']
    },
    hasValueUpdated() {
      return this.getActivePolicyDuration != this.activePolicyDurationBaseValue
    }
  },
  methods: {
    setActivePolicyDuration(value) {
      this.$store.dispatch('assumptions/updateAssumptionsKeyValue', { key: 'active_policy_duration', value })
    }
  }
}
</script>
