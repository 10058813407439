<template>
  <h1 
    class="text-header-6 text-deepsky-900 font-bold mr-2 flex-shrink-0 pl-4 border-l-6 flex-none"
    :class="[cssClasses]"
  >
    {{ title }}
  </h1>
</template>

<script>
export default {
  name: 'PageTitle',
  props: {
    title: {
      type: String,
      required: true
    },
    cssClasses: {
      type: String,
      default: 'psui-border-blue-60'
    }
  }
}
</script>