<template>
  <div
    v-if="policy"
    class="w-full bg-white pt-8"
  >
    <AppAlert
      v-if="showImpactEstimatesAlert"
      description="Impact estimates do not account for flexible compliance paths."
      action="Edit applicability rate"
      icon="bar-chart"
      :icon-size="24"
      wrapper-class="py-0.5 px-2 mb-6 border rounded"
      class="w-full cursor-pointer mt-0"
      @click="onClickImpactEstimatesAlert()"
    />

    <PolicyChartCard
      :policy="policy"
      :policy_summary_data="policy_summary_data"
      :column-selected-key.sync="columnSelectedKey"
    />
    
    <PolicyTable
      class="mt-12"
      :policy="policy"
      :policy_summary_data="policy_summary_data"
    />
    
    <ModalDeleteCustomCombination />
  </div>
</template>

<script>
import PolicyChartCard from './PolicyChartCard'
import PolicyTable from './PolicyTable'
import ModalDeleteCustomCombination from '@/modules/app/policy/show/shared/ModalDeleteCustomCombination'

export default {
  name: 'PolicyShow',
  components: { PolicyChartCard, PolicyTable, ModalDeleteCustomCombination },
  props: ['policy', 'policy_summary_data'],
  data() {
    return {
      policyItemSelected: null,
      columnSelectedKey: false,
      showImpactEstimatesAlert: true
    }
  },
  mounted() {
    this.$eventBus.$on('setPolicyItemSelected', ({ item, columnSelectedKey }) => {
      const newItem = { ...item }
      delete newItem.items
      this.policyItemSelected = item
      this.columnSelectedKey = columnSelectedKey
      this.$store.dispatch('general/scrollContainerTop')
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('setPolicyItemSelected')
  },
  methods: {
    onClickImpactEstimatesAlert() {
      this.$emit('open-assumptions-applicability-rate')
      this.showImpactEstimatesAlert = false
    }
  },
}
</script>