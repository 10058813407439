import BuildingEstimatesMockupData from '@/modules/app/assumptions/shared/BuildingEstimatesMockupData'

export default (Store) => {
  Store.subscribe((mutation) => {

    if(mutation.type === 'setUserPreferences' && mutation?.payload?.path === 'lastJurisdictionVisited') {
      const jurisdiction = mutation.payload.value
      Store.dispatch('assumptions/buildingStocks/getJurisdictionBuildingStocks', jurisdiction.id)
      Store.dispatch('assumptions/buildingEstimates/getJurisdictionBuildingEstimates', jurisdiction.id)

      //@TODO - Delete after new constructions seed
      const buildingEstimatesMockupData = new BuildingEstimatesMockupData({ jurisdiction })
      Store.commit('assumptions/buildingEstimates/setJurisdictionBuildingEstimates', { 
        jurisdiction_id: jurisdiction.id, 
        building_estimates: buildingEstimatesMockupData.building_estimates
      })

    }
  })
}