<template>
  <div>
    <div class="mb-5 border border-deepsky-200 rounded-md flex">
      <div class="flex items-stretch border-r border-deepsky-200 p-5 card-options">
        <WizardToggle :item.sync="policy.is_flexible_path" />
      </div>
      <div class="text-center bg-white w-full p-4 rounded card-content flex flex-col justify-center items-center">
        <transition
          name="fade"
          mode="out-in"
        >
          <div v-if="policy.is_flexible_path">
            <img
              src="/images/policy_wizard/step_flexible_path_true.png"
              width="150"
              class="max-w-full h-auto mb-4 inline-block"
            >          
            <p class="text-deepsky-900 text-header-3 font-bold mb-3 px-8">
              People will be able to choose measures that work best for them as long as the measure scores add up to a required target score 
            </p>
            <div class="text-ash-600 mb-2 text-header-3">
              Your policy will include a table of optional measures, each with its own measure score.
            </div>
            <p class="text-ash-600 mb-2 text-header-3">
              Measure scores are based on annual energy savings estimated by a study for that measure.
            </p>
            <a
              target="_blank"
              href="https://intercom.help/explorer-local-energy-codes/en/articles/5701595-why-offer-a-flexible-compliance-path"
              class="underline text-deepsky-500"
            >Learn more</a>
          </div>

          <div v-else>
            <img
              src="/images/policy_wizard/step_flexible_path_false.png"
              width="150"
              class="max-w-full h-auto mb-4 inline-block"
            >            
            <p class="text-deepsky-900 text-header-3 font-bold mb-3">
              People will need to install all required measures
            </p>
            <p class="text-ash-600 mb-2 text-header-3">              
              Your policy will require people to install a list of specific measures to comply <br>
              Substituting measures will not be allowed
            </p>
            <!-- <p class="mb-3">
              <a href="#" class="underline text-deepsky-500">Learn how it works</a>
            </p> -->
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import WizardToggle from './WizardToggle.vue'
export default {
  name: 'StepAutoGenerate',
  components: { WizardToggle },
  props: ['policy'],
}
</script>
<style lang="scss" scoped>
  .card-options {
    min-height: 357.5px;
  }
</style>