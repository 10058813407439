<template>
  <ButtonAnchor
    class="button relative rounded border-2 border-gray05 flex items-center justify-center text-gray04 bg-white font-normal inline-block p-4 ts--p--1" 
    :to="redirectUrl"
    :disabled="redirectUrl == ''"
    label="Continue with Google"
    icon="google"
  />
</template>

<script>
export default {
  name: 'AppLoginGoogle',
  data() {
    return {
      redirectUrl: ''
    }
  },
  mounted() {
    this.getRedirectUrl()
    if (this.$route.query.code) {
      this.fetchProviderUser()
    }
  },
  methods: {
    getRedirectUrl() {
      const type = this.$route.name == 'AdminSignIn' ? 'admin' : 'user'
      const baseUrl = window.location.origin + `${type == 'admin' ? '/admin' : ''}/login`
      this.$api.get(`/auth/google/get_redirect_url?type=${type}&baseUrl=${baseUrl}`)
        .then(({ data }) => {
          this.redirectUrl = data.redirectUrl
        })
    },

    fetchProviderUser() {
      const code = this.$route.query.code
      const type = this.$route.name == 'AdminSignIn' ? 'admin' : 'user'
      const baseUrl = window.location.origin + `${type == 'admin' ? '/admin' : ''}/login`
      this.$api.get(`/auth/google/fetch_user?type=${type}&baseUrl=${baseUrl}&code=${code}`)
        .then(({ data }) => {
          const token = data.token
          this.$store.commit('setLoggedUserToken', { token, type })
          this.$toast.success('Login successful')
          this.$store.dispatch('getLoggedUser')
            .then(() => {
              if(!this.$store.state.general.redirectTo) {
                if (this.$route.name == 'AdminSignIn') {
                  this.$router.push('/admin')        
                } else {
                  this.$router.push('/policies')                
                }
              }
            })
        })
    }
  }
}
</script>