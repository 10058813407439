<template>
  <BaseModal
    ref="baseModal"
    modal-dialog-classes="w-auto max-w-sm"
    :show-modal-parent.sync="showModalParent"
    @close="close"
  >
    <div
      v-if="showModalParent"
      class="w-full"
    >
      <div
        v-if="!isReadyToUserForm && !isUserLoggedIn"
        class="info-wrapper text-center"
      >
        <img
          src="/images/add-to-policy-sign-in.jpg"
          class="max-w-full h-auto mt-5 mb-10 inline-block"
          alt="Sign in or register to design your own policies"
        >
        <h2 class="text-header-6 font-bold text-deepsky-900 mb-2">
          Please log in to view your policies.
        </h2>
        <p class="text-header-3 text-ash-400">
          New here? Register to start building policies and view their impact on your City/County.
        </p>
        <div class="flex justify-center mt-8">
          <AppButton
            label="Register/Log In"
            @click="isReadyToUserForm = true"
          />
          <!-- <PsButton
            label="Register/Log In"
            @click="isReadyToUserForm = true"
          /> -->
          <!-- <AppButton label="Close" @click="$refs.baseModal.close()" theme="bg-white text-deepsky-500 border-white" /> -->
        </div>
      </div>
      <UserSignForm
        v-else-if="!isUserLoggedIn && showModalParent"
        @loginSuccess="onLoginSuccess"
        @close="close"
      />
      <CustomCombinationToPolicyForm 
        v-else
        :measures="measuresIds"
        :study_id="study_id"
        :prototype_id="prototype_id"
        :vintage_id="vintage_id"
        @success="$refs.baseModal.close()"
      />
    </div>
  </BaseModal>
</template>

<script>
import CustomCombinationToPolicyForm from './CustomCombinationToPolicyForm.vue'
import UserSignForm from '@/modules/app/login/components/UserSignForm.vue'
export default {
  name: 'ModalAddToPolicy',
  components: { UserSignForm, CustomCombinationToPolicyForm },
  data: () => ({
    showModal: false,
    isReadyToUserForm : false,
    study_id : false,
    prototype_id: false,
    vintage_id: false,
    measuresIds: [],
    showModalParent: false
  }),
  computed: {
    isUserLoggedIn() {
      return this.$store.state.auth.loggedUser
    }
  },
  mounted() {
    this.$eventBus.$on('openAddToPolicyModal', (payload) => {
      const { measuresIds, study_id, prototype_id, vintage_id } = payload
      this.measuresIds = measuresIds
      this.study_id = study_id
      this.prototype_id = prototype_id
      this.vintage_id = vintage_id
      this.isReadyToUserForm = false
      this.showModalParent = true
      this.$store.commit('general/setRedirectTo', { route: window.location.pathname, event: { name: 'openAddToPolicyModal', payload }})
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('openAddToPolicyModal')
  },
  methods: {
    onLoginSuccess() {
      console.log('onLoginSuccess')
    },
    close() {
      this.showModalParent = false
      this.$store.commit('general/setRedirectTo')
    }
  }
}
</script>

<style scoped lang="scss">
.info-wrapper {
  max-width: 460px;
}
</style>