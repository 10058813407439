<template>
  <div class="w-full flex flex-col px-4 sm:px-10 py-7">
    <ComparisonHeader
      v-if="jurisdictions.length"
      :jurisdictions="jurisdictions"
      @update-jurisdiction="onUpdateJurisdiction"
      @show-only-different-assumptions="scrollToDifferentAssumptions"
    />
    <SkeletonComparisonHeader v-else />
    
    <ComparisonTable
      v-if="policies" 
      :policies="policies"
      @toggle-policy="togglePolicy"
      @update-policy-order="updatePolicyOrder"
    />
    <SkeletonComparisonTable v-else />

    <div
      v-if="policies && policiesToAdd"
      class="add-policies mt-8"
    >
      <div
        class="inline-flex items-center cursor-pointer"
        @click="$eventBus.$emit('openComparisonPoliciesSelectDrawer')"
      >
        <feather
          type="plus-circle"
          class="text-deepsky-400 mr-2"
        />
        <h2 class="text-accent-2 font-bold text-deepsky-500 mr-4">
          ADD POLICY
        </h2>
        <h2
          class="text-header-2 text-ash-500"
          :style="{ marginTop: '-2px'}"
        >
          ({{ policyQuantityText }})
        </h2>
      </div>
    </div>

    <DrawerComparisonEditColumns />
    <ComparisonPoliciesSelectDrawer
      :jurisdiction="jurisdiction"
      :jurisdictions="jurisdictions"
      @update-jurisdiction="onUpdateJurisdiction" 
      @toggle-policy="togglePolicy"
    />
  </div>
</template>

<script>
import ComparisonPoliciesSelectDrawer from './ComparisonPoliciesSelectDrawer.vue'
import ComparisonHeader from './ComparisonHeader.vue'
import DrawerComparisonEditColumns from './DrawerComparisonEditColumns.vue'
import ComparisonTable from './ComparisonTable'
import generatePolicyItemsTotal from '@/business-logic/services/policies/generatePolicyItemsTotal'
import { mapGetters } from 'vuex'
import JurisdictionService from '@/services/api/JurisdictionApiService'
import SkeletonComparisonHeader from './skeleton/SkeletonComparisonHeader'
import SkeletonComparisonTable from './skeleton/SkeletonComparisonTable'

export default {
  name: 'ComparisonShow',
  components: {
    ComparisonTable,
    DrawerComparisonEditColumns,
    ComparisonHeader,
    ComparisonPoliciesSelectDrawer,
    SkeletonComparisonHeader,
    SkeletonComparisonTable
  },
  metaInfo() {
    const title = this.jurisdiction ? `Comparing ${this.jurisdiction?.title_type}'s policies | ` : `Compare policies`
    return {
      title: title,
      meta: [
        { vmid: 'og:title', property: 'og:title', content: `Comparing ${this.jurisdiction?.title_type}'s policies` }
      ]
    }
  },
  data: () => ({
    policies: null,
    jurisdictions: [],
    log: false,
    jurisdiction_id: false,
    policies_ids: false
  }),
  computed: {
    policiesToAdd() {
      return this.getterLoggedUser && this.getterJurisdictionsByUserPolicies && this.jurisdiction && this.getterJurisdictionsByUserPolicies[this.jurisdiction.id]? 
        this.getterUserPoliciesByJurisdiction[this.jurisdiction.id].length - this.$route.query.policies_ids.split(',').length : false
    },
    jurisdiction() {
      return this.jurisdictions ? this.jurisdictions.find((jur) => parseInt(jur.id) === parseInt(this.$route.query.jurisdiction_id)) : false
    },
    policyQuantityText() {
      if(this.policiesToAdd == 1) return '1 more policy'
      return `${this.policiesToAdd} more policies`
    },
    ...mapGetters('comparison', ['getterJurisdictionsByUserPolicies', 'getterUserPoliciesByJurisdiction']),
    ...mapGetters(['getterLoggedUser'])
  },
  watch: {
    async $route(to, from) {
      
      const toPolicyIds = to.query.policies_ids ? to.query.policies_ids.split(',').sort() : []
      const fromPolicyIds = from.query.policies_ids ? from.query.policies_ids.split(',').sort() : []
      
      if (parseInt(to.query.jurisdiction_id) !== parseInt(from.query.jurisdiction_id)) {
        this.$store.commit('comparison/setPoliciesIdsByRoute', false)
        await this.init()
      } else if (toPolicyIds.join(',') !== fromPolicyIds.join(',')) {
        await this.loadData({ policies_ids : to.query.policies_ids })
      }

      if (to.query.jurisdiction_id) this.$store.commit('comparison/setJurisdictionIdByRoute', to.query.jurisdiction_id)
      if (to.query.policies_ids) this.$store.commit('comparison/setPoliciesIdsByRoute', to.query.policies_ids)

    },
  },
  async created() {
    await this.$store.dispatch('comparison/getUserPolicies')
    this.init()
  },
  mounted() {
    this.$eventBus.$on('policyUpdated', this.loadData)
  },
  beforeDestroy() {
    this.$eventBus.$off('policyUpdated')
  },
  methods: {    
    async init() {
      this.jurisdiction_id = this.getJurisdictionId()
      this.policies_ids = this.getPoliciesIds(this.jurisdiction_id)
            
      this.saveJurisdictionAndPoliciesToStore()
      
      if (this.hasNoPolicyNoJurisdictionAndNoUser()) {
        this.$router.push({ name: 'PolicyList' })
      } else if (this.hasDifferentJurisdictionOrPoliciesFromRoute()) {
        this.$router.replace({ ...this.$route, query : { jurisdiction_id : this.jurisdiction_id , policies_ids : this.policies_ids }})
      } else if (this.policies === null) {
        this.loadData()
      } 
      
    },
    async loadData() {
      if (this.policies_ids) {
        this.policies = await this.getPolicies(this.policies_ids)
      } else {
        this.policies = []
      }
      await this.setJurisdictions()

      this.updateAssumptionsGroupColumnsIfPoliciesAreDifferent()      
    },
    saveJurisdictionAndPoliciesToStore() {
      if (this.jurisdiction_id) this.$store.commit('comparison/setJurisdictionIdByInit', this.jurisdiction_id)
      if (this.policies_ids) this.$store.commit('comparison/setPoliciesIdsByInit', this.policies_ids)
    },
    getJurisdictionId() {
      if (this.$route.query.jurisdiction_id) {
        this.logger('getJurisdictionId', 'query.jurisdiction_id', this.$route.query.jurisdiction_id)
        return this.$route.query.jurisdiction_id
      } else if (this.$store.state.comparison.jurisdictionId) {
        this.logger('getJurisdictionId', 'state.comparison.jurisdictionId', this.$store.state.comparison.jurisdictionId)
        return this.$store.state.comparison.jurisdictionId
      } else if (this.$store.state.auth?.user_preferences?.lastJurisdictionVisited) {
        this.logger('getJurisdictionId', 'state.auth?.user_preferences?.lastJurisdictionVisited', this.$store.state.auth?.user_preferences?.lastJurisdictionVisited)
        return this.$store.state.auth?.user_preferences?.lastJurisdictionVisited?.id
      } else if (this.getterLoggedUser && this.$store.getters['comparison/getterJurisdictionsByUserPolicies']) {
        this.logger('getJurisdictionId', 'state.auth?.user_preferences?.lastJurisdictionVisited', this.$store.state.auth?.user_preferences?.lastJurisdictionVisited)
        return Object.values(this.$store.getters['comparison/getterJurisdictionsByUserPolicies'])[0].id 
      } else {
        this.logger('getJurisdictionId', 'false')
        return false
      }
    },
    getPoliciesIds(jurisdiction_id) {
      if (this.$route.query?.policies_ids) {
        return this.$route.query.policies_ids
      } else if (this.$store.state.comparison.policiesIds) {
        return this.$store.state.comparison.policiesIds
      } else {
        const policiesByJurisdiction = this.$store.getters['comparison/getterUserPoliciesByJurisdiction'][jurisdiction_id]
        return policiesByJurisdiction ? policiesByJurisdiction.map((policy) => policy.id ).join(',') : false
      }
    },
    hasNoPolicyNoJurisdictionAndNoUser() {
      return !this.jurisdiction_id && !this.policies_ids && !this.getterLoggedUser
    },
    hasDifferentJurisdictionOrPoliciesFromRoute(){
      return this.$route.query.jurisdiction_id !== this.jurisdiction_id || this.$route.query.policies_ids !== this.policies_ids
    },
    togglePolicy(policyId) {
      const currentPoliciesIds = this.$route.query.policies_ids.split(',')
      let newPoliciesIds
      if (currentPoliciesIds.includes(String(policyId))) {
        newPoliciesIds = currentPoliciesIds.filter(el => el != null && el != '').filter((id) => parseInt(id) !== parseInt(policyId)).join(',')
      } else {
        newPoliciesIds = [...currentPoliciesIds, policyId ].filter(el => el != null && el != '').join(',')
      }
      this.policies_ids = newPoliciesIds
      let routeArgs = { query: {...this.$route.query, policies_ids: newPoliciesIds } }
      this.$router.push(routeArgs)
    },
    updatePolicyOrder({ from, to }) {
      this.policies.splice(to, 0, this.policies.splice(from, 1)[0])
      
      let routePolicyIds = this.$route.query.policies_ids.split(',')
      routePolicyIds.splice(to, 0, routePolicyIds.splice(from, 1)[0])

      this.$router.push({ query: { ...this.$route.query, policies_ids: routePolicyIds.join(',') }})      
      
    },
    onUpdateJurisdiction(jurisdiction_id) {
      this.$router.push({ name: 'ComparisonShow', query: { jurisdiction_id }})
    },
    getPolicies(policies_ids) {
      return this.$api.get(`/api/comparison/get?policy_ids=${policies_ids}`)
        .then(({ data }) => {
          return data.policies.map((policy) => {
            generatePolicyItemsTotal({ policy, items: policy.policy_summary_data })
            return policy
          })
        })
    },
    async setJurisdictions() {
      const jurisdiction_ids = new Set([ ... Object.keys(this.getterJurisdictionsByUserPolicies).map(id => parseInt(id)), parseInt(this.$route.query.jurisdiction_id) ])
      this.jurisdictions = await JurisdictionService.getByIds(jurisdiction_ids)      
    },
    scrollToDifferentAssumptions() {
      const comparisonTable = document.querySelector('.comparison-table')
      const columGroupFixedWidth = document.querySelector('.table-fixed-col').offsetWidth
      const columnGroupFixedAssumption = document.querySelector('.column-group-different_assumptions')
      
      comparisonTable.scroll({ left: columnGroupFixedAssumption.offsetLeft - columGroupFixedWidth - 30, behavior: 'smooth' })
    },
    logger(...message) {
      if (this.log) console.log(...message)
    },
    updateAssumptionsGroupColumnsIfPoliciesAreDifferent() {
      // if (this.policies_ids !== this.$store.state.comparison.policiesIds) {
        this.logger('updateAssumptionsGroupColumnsIfPoliciesAreDifferent')
        this.$store.dispatch('comparison/setComparisonAssumptionsGroupColumns', { policies: this.policies })        
      // }
    }
  }
}
</script>