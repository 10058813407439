
import Graphql from '@/util/GraphQL'
import Jurisdiction from '@/models/Jurisdiction'

export default class JurisdictionApiService {

  static getBySlug(jurisdiction_slug, caller = null) {

    const query = `
      {
        jurisdiction(where: [{column: "slug", operation: "", value: "${jurisdiction_slug}"}]) {
          id
          key
          title
          type
          title_type
          county
          slug
          climate_zones (
            orderBy: [{column: "prefix", order: "asc"}]
          ) {      
            prefix
            suffix
            raw
            id
          }
        }
      }
    `
    return Graphql({ query, shouldCache: true, caller }).then(({ data }) => {
      return data 
    })
  }

  static getByIds(jurisdictionIds) {
    const jurisdiction_ids_as_string = [...jurisdictionIds].map(id => `"${id}"`).join(',')      
    const query = `{
      jurisdictions (
        whereIn: [{ column: "id", value: [${jurisdiction_ids_as_string}] }]
      ) {
        id
        title
        title_type
        city
        slug
        type
        county
        climate_zones {
          id
          prefix
          suffix
          raw
        }
      }
    }`
    return Graphql({ query, shouldCache: true }).then(({ data }) => {
      return data.jurisdictions.map(i => new Jurisdiction(i))
    })
  }

}