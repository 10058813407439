import MainRouter from '@/router'
import UserDeviceApiService from '@/services/api/UserDeviceApiService'

export default {

  scrollContainerTop() {
    setTimeout(() => {
      const mainContent = document.getElementById('main-content')
      const firstChild = mainContent.firstChild
      if(firstChild) {
        firstChild.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
      }
    }, 10)
  },

  checkRedirectTo(context) {
    if(context.state.redirectTo) {
      MainRouter.push(context.state.redirectTo)
      const event = context.state.redirectToEventToDispatchAfter
      if(event) {
        setTimeout(() => {
          if(typeof event == 'string') {
            window.$eventBus.$emit(event)
          } else {
            window.$eventBus.$emit(event.name, event.payload)
          }
        }, 500)
      }
      setTimeout(() => {
        context.commit('setRedirectTo')
      }, 1000)
    }
  },

  checkDeviceInfo({ commit }) {
    UserDeviceApiService.check()
      .then(({ data }) => {
        const { user_device, is_admin, is_team } = data
        commit('setDeviceInfo', { user_device, is_admin, is_team })
      })
  }

}
