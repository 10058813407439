<template>
  <div class="flex items-center ml-3">
    <Dropdown
      ref="dropdown"
      :button-classes="!isFiltering ? 'bg-transparent rounded-full border border-gray05 text-gray04' : 'bg-blue05-50 text-blue02 border border-blue03 rounded-full'"
      :min-width-dropdown="400"
      :dropdown-dialog-classes="'min-w-300 bg-white p-6'"
      title="Vintage"
    >
      <template v-slot:buttonLabel>
        <div class="w-full flex justify-between items-center whitespace-no-wrap px-2">
          <span>Vintage</span>
        </div>
      </template>
      <template v-slot:items>
        <div class="w-full flex flex-col font-medium text-gray02">
          <template v-for="type_vintage in type_vintages">
            <Checkbox
              v-if="type_vintage.id != 14"      
              :key="`filter-vintage-${type_vintage.id}`"
              class="w-full"              
              label-classes="ts--accent--2 text-gray04"
              :label="type_vintage.title"
              :value="!checkQueryKeyValue('exclude_type_vintage_ids', type_vintage.id)"
              @change="toggleQueryKeyValue('exclude_type_vintage_ids', type_vintage.id)"
            />
          </template>
        </div>
      </template>
    </Dropdown>
  </div>
</template>

<script>
export default {
  name: 'FilterTypeVintage',
  data() {
    return {
      type_vintages: []
    }
  },
  computed: {
    isFiltering() {
      return this.$route.fullPath.includes('exclude_type_vintage_ids')
    }
  },
  mounted() {
    this.getVintages()
  },
  methods: {
    getVintages() {
      const query = `
        {
          type_vintages {
            id
            title
          }
        }
      `
      this.$graphql({ query, shouldCache: true })
        .then(({ data }) => {
          this.type_vintages = data.type_vintages
        })
    }
  }
}
</script>