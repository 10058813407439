<template>
  <div class="flex items-center">
    <p class="text-header-2 text-ash-700">
      Filter by:
    </p>
    <Dropdown 
      v-if="jurisdictions"
      ref="dropdownJurisdictionFilter"
      :button-classes="jurisdictionDropdownButtonClasses"
      class="ml-2"
    >
      <template v-slot:buttonLabel>
        <div class="w-full flex justify-between items-center whitespace-no-wrap px-2">
          <span>City/County</span>
          <feather 
            v-if="hasNotJurisdctionSelected"
            :size="20"
            type="alert-circle" 
            class="ml-2 text-sunrise-400"
          />
        </div>
      </template>
      <template v-slot:items>
        <div class="w-full flex flex-col font-medium text-gray02">          
          <CheckboxSelectAll
            class="w-full mt-1 mb-3 ts--accent--2"
            label-classes="ts--accent--2 text-gray02"
            :label="`${isSelectingAllJurisdictions ? 'Unselect all' : 'Select all'}`"
            :value="isSelectingAllJurisdictions"
            @change="toggleFilterByAllJurisdictions()"
          />
          <Checkbox
            v-for="(jurisdiction) in jurisdictions"
            :key="`filterby-${jurisdiction.id}`"
            class="w-full pl-6"
            :label="jurisdiction.title_type"
            :value="filterByJurisdiction && !filterByJurisdiction.includes(jurisdiction.id)"
            label-classes="ts--accent--2 text-gray02"
            @change="filterByJurisdiction = jurisdiction"
          />
        </div>
      </template>
    </Dropdown>
    <Dropdown                 
      ref="dropdownShowPoliciesBy"
      :button-classes="!isFilteringByAuthor ? 'bg-transparent rounded-full border border-gray05 text-gray04' : 'bg-blue05-50 text-blue02 border border-blue03 rounded-full'"
      dropdown-dialog-classes="left-0 bg-white"
      class="ml-2"
    >
      <template v-slot:buttonLabel>
        <div class="w-full flex justify-between items-center whitespace-no-wrap px-2">
          <span>Author</span>
        </div>
      </template>
      <template v-slot:items>
        <DropdownMenuList 
          :items="showPoliciesByOptions"
          :selected.sync="filterByAuthor"
          @update:selected="$refs.dropdownShowPoliciesBy.close()"
        />
      </template>
    </Dropdown>
  </div>
</template>

<script>
import { showPoliciesByOptions, POLICY_LIST_JURISDICTION_FILTER_ALL } from '@/business-logic/constants/policiesEnum'

export default {
  name: 'PoliciesListFilters',
  props: {
    jurisdictions: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    showPoliciesByOptions: Object.values(showPoliciesByOptions),
    showPoliciesBy: showPoliciesByOptions.ALL,
  }),
  computed: {
    filterByAuthor: {
      get() {
        return this.$store.state.auth.user_preferences?.policies?.myPolicies?.filterByAuthor
      },
      set(value) {
        console.log(value)
        this.$store.dispatch('setUserPreferences', { payload : { path: 'policies.myPolicies.filterByAuthor', value } })
      }
    },
    filterByJurisdiction: {
      get() {
        return this.$store.state.auth.user_preferences?.policies?.myPolicies?.filterByJurisdiction
      },
      set(jurisdiction) {
        this.$store.dispatch('setUserPreferences', {
          mutation: 'setUserPreferencesFilterByJurisdiction',
          payload : { jurisdiction, jurisdictions: this.jurisdictions }
        })
        if (jurisdiction && jurisdiction.id !== POLICY_LIST_JURISDICTION_FILTER_ALL.id) {
          this.$store.dispatch('setUserLastJurisdictionAndFirstClimateZoneVisited', { value: jurisdiction, context: 'updateLastJurisdictionVisitedFromPoliciesListFilter'})
        } 
      }
    },
    hasNotJurisdctionSelected() {
      return this.filterByJurisdiction === null || this.jurisdictions.length === this.filterByJurisdiction.length
    },
    isSelectingAllJurisdictions() {
      return Array.isArray(this.filterByJurisdiction) && this.filterByJurisdiction.length === 0
    },
    isFilteringByAuthor() {
      return this.filterByAuthor !== showPoliciesByOptions.ALL ?? false
    },
    jurisdictionDropdownButtonClasses() {
      const defaultClasses = 'rounded-full border '
      if (this.isSelectingAllJurisdictions) {
        return defaultClasses + 'bg-transparent border-gray05 text-gray04'
      } else if (this.hasNotJurisdctionSelected) {
        return defaultClasses + 'bg-sunrise-100 border-sunrise-400 text-sunrise-500'
      } else {
        return defaultClasses + 'bg-blue05-50 text-blue02 border-blue03'
      }
    }
  }, // computed
  methods: {
    toggleFilterByAllJurisdictions() {
      this.filterByJurisdiction = this.isSelectingAllJurisdictions ? null : POLICY_LIST_JURISDICTION_FILTER_ALL
    },
  }
}
</script>

<style>

</style>