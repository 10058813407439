<template>
  <div>
    <div class="psui-shadow psui-pt-4">
      <AssumptionsBreadcrumb 
        :path="path"
        @update:path="$emit('update:path', $event)"
      />

      <h2 class="psui-text-h6 psui-text-gray-80 font-bold px-6">
        Nonresidential Buildings
      </h2>

      <PsTabHeader
        layout="underline"
        :items="getTabItems"
        :selected.sync="getTabSelected"
        class="mx-6 border-none"
      />
    </div>    
    <div 
      :is="getComponent"
      :building_types="building_types"
      :building_type="building_type"
      :type_vintages="type_vintages"
      :type_prototypes="type_prototypes"
      :jurisdiction="jurisdiction"
      :study_type="getTabSelected"
    />
  </div>
</template>

<script>
import AssumptionsBreadcrumb from '@/modules/app/assumptions/shared/AssumptionsBreadcrumb.vue'
import AssumptionsNonResidentialNew from './AssumptionsNonResidentialNew.vue'
import AssumptionsNonResidentialExisting from './AssumptionsNonResidentialExisting.vue'
import { STUDY_TYPES } from '@/modules/app/assumptions/shared/enums'
export default {
  name: 'AssumptionsResidential',
  components: { AssumptionsBreadcrumb, AssumptionsNonResidentialNew, AssumptionsNonResidentialExisting},
  props: ['building_types', 'building_type', 'type_vintages', 'path', 'type_prototypes' ],
  data: () => ({    
    STUDY_TYPES : STUDY_TYPES,
  }),
  computed: {    
    getComponent() {
      return {
        [STUDY_TYPES.EXISTING] : AssumptionsNonResidentialExisting,
        [STUDY_TYPES.NEW] : AssumptionsNonResidentialNew,
      }[this.getTabSelected] ?? AssumptionsNonResidentialExisting
    },
    jurisdiction() {
      return this.$store.getters['lastJurisdictionVisited'] ?? false
    },
    getTabItems() {
      return Object.values(STUDY_TYPES).map(st => {
        if(st === 'Existing Units') {
          return {
            label: st,
            value: st,
            tooltip: 'Not available.',
            disabled: true
          }
        } else {
          return {
            label: st,
            value: st
          }
        }
      })
    },
    getTabSelected: {
      get() {
        const seletectedTab = this.$store.getters['assumptions/getterAssumptionsDrawerOptions'].tab
        return seletectedTab && Object.values(STUDY_TYPES).includes(seletectedTab) ? seletectedTab : Object.values(STUDY_TYPES)[1]
      },
      set(tab) {
        this.$store.commit('assumptions/updateDrawerTab', tab)
      }
    }
  }
}
</script>

<style scoped lang="scss">
  h2 {
    margin-bottom: 10px;
  }
  ::v-deep .psui-el-input {
    width: 140px;
  }

  ::v-deep .psui-el-tooltip {
    .psui-el-tooltip-wrapper {
      .psui-el-tooltip-dialog {
        transition-delay: 0.8s;
        min-width: 180px;
        width: 200px;
      }      
    }
  }

  ::v-deep .psui-el-tab-header.status-resting.layout-underline {
      button:not(:first-child) {
        margin-left: 20px;
      }
  }

  ::v-deep .psui-el-tooltip {
    .psui-el-tooltip-trigger {
      button {
        cursor: pointer;
        color: rgba(121, 132, 144, var(--text-opacity));
      }
    }
  }
</style>
