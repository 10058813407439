<template>
  <div>
    <div class="w-full flex flex-wrap mt-6 pt-1 pb-6">
      <div class="app-title flex items-center flex-shrink-0 w-auto mr-auto">
        <h1 class="text-header-6 text-deepsky-900 font-bold mr-2 flex-shrink-0">
          Your account
        </h1> 
      </div>

      <div class="actions flex-shrink-0 pt-6 lg:pt-2">
        <ul class="flex items-end">          
          <li
            class="ts--accent--book--2 text-blue02 cursor-pointer transition hover:opacity-70"
            @click="logoutUser()"
          >
            Log Out
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserAccountHeader',
  methods: {
    logoutUser() {
      this.$store.dispatch('logoutUser')
    },
  },
}
</script>

<style>
.route-myaccount #base-header {
  border-bottom: 0;
}
</style>