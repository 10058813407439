<template>
  <Dropdown
    ref="dropdown"
    class="ml-3"
    :button-classes="'rounded-full border border-blue05 px-2 py-1 flex items-center justify-center'"
    :dropdown-dialog-classes="'left-0 bg-white'"
  >
    <template v-slot:buttonLabel>
      <div class="w-full flex justify-between items-center">
        <h4 class="text-header-2 text-ash">
          {{ item.filter_title }}
        </h4>
      </div>
    </template>
    
    <template v-slot:items>
      <div class="w-full flex flex-col justify-start items-start font-medium p-4">
        <h2 class="ts--accent--book--3 mb-2 text-gray02">
          {{ item.filter_title }}
        </h2>
        <div 
          v-for="(childItem, index) in item.items" 
          :key="index"
          class="w-full"
        >
          <Checkbox
            v-model="childItem.is_selected"              
            class="w-full mt-1"
            :label="childItem.title"
            :disabled="childItem.is_selected && getChildItemsUnselectedQuantity == item.items.length-1"
            label-classes="ts--accent--2 text-gray04"
            @change="toggleItem(childItem)"
            @click.native="checkTryUnselectAll(childItem)"
          />
        </div>
      </div>
    </template>
  </Dropdown>
</template>

<script>
export default {
  name: 'PolicyFilterItem',
  props: ['item', 'filterItemsUnselected'],
  computed: {
    getChildItemsUnselectedQuantity() {
      let unselecteds = 0
      for(const childItem of this.item.items) {
        if(this.filterItemsUnselected.indexOf(childItem) > -1) unselecteds++
      }
      return unselecteds
    }
  },
  methods: {
    checkChildItemIsUnselected(childItem) {
      if(this.filterItemsUnselected.indexOf(childItem) > -1) {
        return false
      }
      return true
    },
    toggleItem(item) {
      const index = this.filterItemsUnselected.indexOf(item)
      if(index > -1) {
        this.filterItemsUnselected.splice(index, 1)
        if(item.items) this.toggleAllChildren(item.items, false)
      } else {
        this.filterItemsUnselected.push(item)
        if(item.items) this.toggleAllChildren(item.items, true)
      }
    },
    checkTryUnselectAll(childItem) {
      if(childItem.is_selected && this.getChildItemsUnselectedQuantity == this.item.items.length-1) {
        this.$appToast({ type: 'info', message: 'Please, select at least one item.', duration: 5000 })
      }
    },

    toggleAllChildren(items, state) {
      for(const item of items) {
        console.log(item)
        if(!item.items) continue
        item.is_selected = state
        if(item.items) {
          this.toggleAllChildren(item.items, state)
        }
      }
    }

  },
  
}
</script>

<style>

</style>