import Api from '@/util/Api'
import { DeviceUUID } from 'device-uuid'
export default class UserDeviceApiService {

  static check() {
    
    const device = new DeviceUUID()
    const deviceInfo = device.parse()

    var dua = [
      deviceInfo.language,
      deviceInfo.platform,
      deviceInfo.os,
      deviceInfo.cpuCores,
      deviceInfo.isAuthoritative,
      deviceInfo.silkAccelerated,
      deviceInfo.isKindleFire,
      deviceInfo.isDesktop,
      deviceInfo.isMobile,
      deviceInfo.isTablet,
      deviceInfo.isWindows,
      deviceInfo.isLinux,
      deviceInfo.isLinux64,
      deviceInfo.isMac,
      deviceInfo.isiPad,
      deviceInfo.isiPhone,
      deviceInfo.isiPod,
      deviceInfo.isSmartTV,
      deviceInfo.pixelDepth,
      deviceInfo.isTouchScreen,
      navigator.isJavaEnabled
    ]
    const uid = deviceInfo.hashMD5(dua.join(':'))

    const { cpuCores: cpu_cores, browser, os, platform, version, isMobile: is_mobile, resolution } = deviceInfo
    if(deviceInfo.isBot || [os, platform].includes('unknown')) return
    const resolution_width = resolution[0]
    const resolution_height = resolution[1]
    return Api.post(`/api/users/user_devices/check`, { uid, cpu_cores, os, platform, browser, version, is_mobile, resolution_width, resolution_height })
  }

}