import BaseLayoutWithHeaderAndSideBar from '@/layout/BaseLayoutWithHeaderAndSideBar'
import AdminSignIn from '@/modules/admin/auth/AdminSignIn'
import AdminSignInSidebar from '@/modules/admin/auth/AdminSignInSidebar'
export default [

  {
    path: '/admin/login',
    component: BaseLayoutWithHeaderAndSideBar,
    children: [
      {
        name: 'AdminSignIn',
        path: '',
        component: AdminSignIn,
        meta: {
          sidebar: {
            size: 'large',
            content: AdminSignInSidebar
          }
        }
      }
    ]
  }
]