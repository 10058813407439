import Graphql from '@/util/GraphQL'
import Api from '@/util/Api'
import TypePrototype from '@/models/TypePrototype'
import Tier from '@/models/MeasureMenuTier'
import MeasureMenuGeneralService from '@/services/measure-menu/MeasureMenuGeneralService'
import { find } from '@/util/Functions'
import { DEFAULT_REQUIREMENT_LEVEL } from '@/business-logic/constants/flexiblePathSetup'
export default class FlexiblePathService {
  
  constructor({ policy_id }) {
    this.policy_id = policy_id
  }

  getSetup() {
        
    const query = `
      {
        flexible_path_setup (
          where: [
            { column: "policy_id", operation: "=", value: "${this.policy_id}"}
          ]
        ) {
          id
          policy_id
          data
        }
      }
    `
    return Graphql({ query, shouldCache: false }).then(({ data }) => {
      this.setup = data.flexible_path_setup ? data.flexible_path_setup.data : this.getDefaultSetup()
      return this.checkSetupVersion()
    })
  }

  updateSetup(flexible_path_setup) {
    this.setup = flexible_path_setup
    return Api.post(`/api/flexible_path_setup/updateOrCreate`, { policy_id: this.policy_id, data: this.setup }, { ignoreFeedback: true })
  }

  getDefaultSetup() {
    return {
      version: 2,
      tiers: []
    }
  }

  getTypePrototypes() {
    return Api.get(`/api/measure_menu/get_type_prototypes/${this.policy_id}`, { shouldCache: false })
        .then(({ data }) => data.type_prototypes.map(obj => new TypePrototype(obj)))
  }

  getTypePrototypeItems({ type_prototype_id }) {    
    return Api.get(`/api/measure_menu/get_prototypes/${this.policy_id}/${type_prototype_id}`, { shouldCache: false })
      .then(({ data }) => data )
  }

  checkSetupVersion() {
    if (!this.setup.version) {
      return this.migrateFromVersionOneToTwo() 
    }
    return this.setup
  }

  async migrateFromVersionOneToTwo() {
    let tiers = []
    const _version_1_data = {...this.setup }
    
    const hasAnyCustomSetupConfiguration = () => {
      return (this.setup.type == 'global' && this.setup.global_target_score_agressiveness != DEFAULT_REQUIREMENT_LEVEL) || (this.setup.type == 'climate_zone' && this.setup.climate_zones_target_score_setups.length > 0) ? true : false
    }

    const getTargetScore = ({ prototype, climate_zone, type_vintage_index }) => {
      const maxValue = MeasureMenuGeneralService.getMaxTargetScore({ climate_zone, prototype, type_vintage_index, flexible_path_setup: this.setup })
      const requirement_level = this.setup.type == 'global' ? this.setup.global_target_score_agressiveness : find(this.setup.climate_zones_target_score_setups, { prototype_id: prototype.id, climate_zone_id : climate_zone.id })?.target_score_agressiveness ?? null
      return requirement_level ? Math.round(maxValue * (requirement_level/100) ) : false
    }

    if (hasAnyCustomSetupConfiguration()) {
      
      const type_prototypes = await this.getTypePrototypes()
      for( let type_prototype of type_prototypes) {
        const { prototypes, type_vintages } = await this.getTypePrototypeItems({ type_prototype_id: type_prototype.id })        
        for ( const prototype of prototypes) {                
          for ( const climate_zone of prototype.climate_zones) {            
            const tier = new Tier({ 
              prototype_id: prototype.id, 
              climate_zone_id : climate_zone.id,
              excluded_measures: _version_1_data.excluded_measures.filter(o => o.climate_zone_id == climate_zone.id && o.prototype_id == prototype.id),
              mandatory_measures: _version_1_data.pinned_measures.filter(o => o.climate_zone_id == climate_zone.id && o.prototype_id == prototype.id)
            })
            for ( const type_vintage_index in type_vintages) {     
              const value = getTargetScore({ climate_zone, prototype, type_vintage_index })
              if (value) tier.updateTargetScore({ type_vintage_id: type_vintages[type_vintage_index].id, value })
            }
            if (tier.target_scores.length > 0) {
              tiers.push(tier)
            }
          }
        }
      }

    }
  
    const setup = {
      version: 2,
      tiers,
      from_version: 1,
      _version_1_data
    }
   this.updateSetup(setup)
   return setup
  }

}