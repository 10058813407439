<template>
  <div 
    class="app-seemore flex items-center cursor-pointer hover:opacity-60 transition transition-300"
    :class="{ 'is-expanded' : expanded }"
  >
    <span class="mr-2">{{ expanded ? 'See Less' : 'See More' }}</span>
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 10L8 6L12 10"
        stroke="#4B8CA6"
        stroke-width="1.6"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    expanded: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
  span {
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #76848A;
  }
  svg {
    transform: rotate(180deg);
  }
  .is-expanded {
    svg {
      transform: rotate(0deg);
    }
  }
</style>