<template>
  <div class="w-full flex justify-center px-4 mt-2">
    <PsButton
      :disabled="value <= 0"
      label="prev"
      layout="outline"
      size="medium"
      class="psui-mx-2"
      @click="prev()"
    />

    <PsButton
      :disabled="length < limit"
      label="next"
      layout="outline"
      size="medium"
      class="psui-mx-2"
      @click="next()"
    />
  </div>
</template>

<script>
export default {
  name: 'Paginator',
  props: {
    length: {
      type: Number,
      default: 0
    },
    value: {
      type: Number,
      default: 0
    },
    limit: {
      type: Number,
      default: 12
    }
  },
  methods: {
    next() {
      const newOffset = this.value + (this.limit)
      this.$emit('input', newOffset)
      this.$emit('getItems')
    },
    prev() {
      if(this.offset <= 0) return false
      const newOffset = this.value - this.limit
      this.$emit('input', newOffset)
      this.$emit('getItems')
    }
  }
}
</script>