<template>
  <div class="p-3 pb-2 text-header-2 font-bold">
    <p class="font-bold text-white">
      This is the maximum score you <br> can set for this vintage
    </p>
    <div class="flex space-x-2 mt-3 text-white">
      <span
        v-close-popover
        class="rounded-lg inline-block py-1 px-3 bg-deepsky-700 cursor-pointer hover:bg-deepsky-600 transition-all"
      >Got it</span>
      <span 
        v-close-popover
        class="rounded-lg inline-block py-1 px-3 bg-deepsky-700 cursor-pointer hover:bg-deepsky-600 transition-all"
        @click="$eventBus.$emit('openDescriptionModal', { type: 'helper', slug: 'policy-flexiblepath-max-cost-effective-score' })"
      >
        More info
      </span>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>