<template>
  <div
    :id="`forecast-study-type-${studyType.id}`"
    class="w-full mt-10"
  >
    <div class="w-full">
      <div class="w-full">
        <div class="flex space-x-2">
          <h1 class="text-header-5 font-bold text-ash-450 flex items-center">            
            {{ studyType.title }}            
          </h1>
          <PsButton                
            v-if="studyType.id === 1"
            size="small"
            label="Create a policy draft"
            icon="add_circle"
            icon-position="left"
            @click="openModalAutoGeneratePolicyWizard()"
          />
          <span 
            v-else 
            v-tooltip="'Soon you will be able to create policy drafts for new construction.'" 
            class="inline-block"
          >
            <PsButton                
              size="small"
              label="Create a policy draft"
              icon="add_circle"
              icon-position="left"
              :disabled="true"
            />
          </span>
        </div>
        <PsDialog
          v-if="studyType.id === 2 && showFutureStudiesWarning"
          message="The studies for 2022 cycle are not ready yet. Soon you will be able to explore them in the tool."
          class="mt-3 mb-1"
          @close="showFutureStudiesWarning = false"
        >
          <template v-slot:action>
            <div class="psui-flex psui-space-x-5">
              <a 
                class="psui-text-blue-60 psui-text-small psui-font-bold"
                target="_blank"
                href="https://intercom.help/explorer-local-energy-codes/en/articles/5789919-considering-a-new-construction-reach-code-for-your-city-or-county-in-california"
                @click="gaEvent(GA_LABELS.RESULTS_HOW_TO_GET_READY, { value: 'NEW BUILDINGS' })"
              >
                How to get ready              
              </a>
              <a 
                class="psui-text-blue-60 psui-text-small psui-font-bold"
                target="_blank"
                href="https://eepurl.com/hSvOov"
                @click="gaEvent(GA_LABELS.RESULTS_SEND_ME_A_REMINDER, { value: 'NEW BUILDINGS' })"
              >
                Send me a reminder
              </a>
            </div>
          </template>
        </PsDialog>
      </div>
      <div class="w-full pt-5 px-3 md:px-0 flex flex-col">        
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import { GA_LABELS } from '@/mixins/GaEventsMixin'
export default {
  name: 'StudyResultsContentStudyType',
  props: {
    studyType: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    showFutureStudiesWarning : true,
    GA_LABELS
  }),
  methods: {
    openModalAutoGeneratePolicyWizard() {
      this.$eventBus.$emit('openModalAutoGeneratePolicyWizard')
      this.$store.commit('general/setRedirectTo', { route: this.$route.path, event: { name: 'openModalAutoGeneratePolicyWizard' } })
    }
  }
}
</script>