<template>
  <div class="w-full">
    <div
      v-for="(impact, key) of impacts"
      :key="key"
      class="flex"
    >
      <div class="w-full h-10 flex items-center pl-10 bg-deepsky-100 text-header-2 opacity-100-childrens-on-hover">          
        <AppInfoHelper 
          :visible="true"
          :absolute="true"
          position="right"
          :icon-size="16"
          icon-class="text-deepsky-500 hover:text-deepsky-900"
          @icon-click="$eventBus.$emit('openDescriptionModal', { type: 'helper', slug: impact.tooltipSlug })"
        >
          <span class="pr-1 text-deepsky-700">{{ impact.title }} <span class="text-header-1 text-ash-500">{{ impact.description }}</span></span>
        </AppInfoHelper>
      </div>
      <div 
        v-for="(type_vintage, type_vintage_index) in type_vintages" 
        :key="`tier-impact-${key}-${type_vintage.id}`"
        class="cell-width bg-deepsky-100 h-10 flex items-center flex-shrink-0 text-ash-600 text-header-2 pl-4"
      >
        <span v-if="!isLoading">
          {{ complianceCostRangeByVintage({ type_vintage, type_vintage_index }) }}
        </span>

        <div
          v-else
          class="shimmer w-20 h-4 ml-2"
        />
      </div>
    </div>
  </div>
</template>

<script>

import { getTierTargetScore, getMaxTargetScore, getExcludedMeasuresByVintage, getMandatoryMeasuresByVintage } from '@/services/measure-menu/MeasureMenuGeneralService'
import { getComplianceCostRangeImpacts, formatImpactRange } from '@/services/measure-menu/MeasureMenuImpactsService'

export default {
  name: 'MeasureMenuImpacts',
  props: ['tier', 'policy', 'prototype', 'climate_zone', 'type_vintages', 'flexible_path_setup', 'showImpacts'],

  data: () => ({
    impacts : {
      complianceCost : {
        title: 'Compliance Cost',
        description: '(lifecycle)',
        tooltipSlug: 'policy-flexiblepath-measuremenu-impacts-compliance-cost',
        ranges: false
      }
    },
    isLoading : true,
    shouldProcessWhenShow : false,
  }),
  watch: {
    showImpacts(value) {
      if(value && !this.impacts.complianceCost.ranges || value && this.shouldProcessWhenShow) {
        this.getTierComplianceCostRangeImpacts()
      }
    },
    flexible_path_setup() {
      if (this.showImpacts) {
        this.getTierComplianceCostRangeImpacts()        
      } else {
        this.shouldProcessWhenShow = true
      }
    }
  },
  methods: {
    
    complianceCostRangeByVintage({ type_vintage, type_vintage_index }) {
      const targetScore = getTierTargetScore({ ...this.$props, type_vintage, type_vintage_index })
      return targetScore ? formatImpactRange(targetScore, this.impacts.complianceCost.ranges[type_vintage_index]) : '--'
    },

    getExcludedMeasureIdsByVintage(type_vintage_index) {
      const { climate_zone, prototype, flexible_path_setup } = this
      return getExcludedMeasuresByVintage({ climate_zone, type_vintage_index, prototype, flexible_path_setup }).map(m => m.id)
    },
    
    getMandatoryMeasureIdsByVintage(type_vintage_index) {
      const { climate_zone, prototype, flexible_path_setup } = this
      return getMandatoryMeasuresByVintage({ climate_zone, type_vintage_index, prototype, flexible_path_setup }).map(m => m.id)
    },

    async getTierComplianceCostRangeImpacts() {

      const { type_vintages, climate_zone, prototype, flexible_path_setup } = this

      const promises = type_vintages.map((type_vintage, type_vintage_index) => {        
        const studyDataArgs = { vintage_ids: type_vintage.vintage_ids, prototype_id: prototype.id, climate_zone_raw: climate_zone.raw }
        return getComplianceCostRangeImpacts({ 
          studyDataArgs,
          minTargetScore: getMaxTargetScore({ climate_zone, prototype, type_vintage_index, flexible_path_setup }),
          measuresExcludedIds: this.getExcludedMeasureIdsByVintage(type_vintage_index), 
          measuresMandatoryIds: this.getMandatoryMeasureIdsByVintage(type_vintage_index), 
        })
      })

      Promise.all(promises).then((ranges) => {
        this.impacts.complianceCost.ranges = ranges
        this.isLoading = false
      })      
      
    }
  }
}
</script>