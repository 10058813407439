<template>
  <PsAccordionItem
    title="Penetration Rate"
    :class="{ 'form-dirty' : hasValueUpdated }"
  >
    Each year
    <DropdownWithInputNumber
      :value="getValue"
      :min-value="1"
      :max-value="100"
      :step="0.1"
      :base-value="baseValue"      
      :button-label="`${getValue}%`"
      suffix="%"
      @update:value="setValue"
    />
    of units within {{ getJurisdictionSelected.type === 'City' ? 'the' : '' }} {{ getJurisdictionSelected.title_type }} (for the relevant vintage, housing type and climate zone) will be subject to the requirements.    
  </PsAccordionItem>
</template>

<script>
import DropdownWithInputNumber from '@/components/general/DropdownWithInputNumber.vue'
import { defaultImpactAssumptionValues } from '@/models/ImpactAssumptions'

export default {
  name: 'AssumptionsCardPenetrationRate',
  components: { DropdownWithInputNumber },
  data: () => ({
    baseValue: defaultImpactAssumptionValues.annual_penetration_rate
  }),
  computed: {
    getValue() {
      return this.$store.state.assumptions[this.$store.state.assumptions.drawerOptions.type]['annual_penetration_rate']
    },
    getJurisdictionSelected() {
      return this.$store.getters['lastJurisdictionVisited']
    },
    hasValueUpdated() {
      return this.getValue != this.baseValue
    }
  },
  methods: {
    setValue(value) {
      this.$store.dispatch('assumptions/updateAssumptionsKeyValue', { key: 'annual_penetration_rate', value })
    }
  }
}
</script>
