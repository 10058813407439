import CustomBuildingStock from "@/models/CustomBuildingStock"
import BuildingStockApiService from "@/services/api/BuildingStockApiService"
import CustomBuildingStockApiService from "@/services/api/CustomBuildingStockApiService"
import { ERRORS } from "@/util/Enums"
import generateEmptyBuildingStocks from './generateEmptyBuildingStocks'
import attachPolicyToBuildingEstimates from '@/modules/app/assumptions/shared/attachPolicyToBuildingEstimates'
import updateUnits from '@/modules/app/assumptions/shared/updateUnits'

export default {

  async getJurisdictionBuildingStocks(context, jurisdiction_id) {
    const jurisdiction_building_stocks = await BuildingStockApiService.getJurisdictionUnits(jurisdiction_id)
    const building_stocks = generateEmptyBuildingStocks(jurisdiction_building_stocks)
    context.commit('setJurisdictionBuildingStocks', { jurisdiction_id, building_stocks })
  },

  getCustomBuildingStocks(context) {
    context.commit('buildingStocksInit')
    new CustomBuildingStockApiService().get()
      .then(({ data }) => {
        const custom_building_stocks = data.currentUser.custom_building_stocks.map((item) => new CustomBuildingStock(item))
        const policy_id_selected = context.rootState.assumptions.policy_id_selected
        context.commit('setCustomBuildingStocks', { custom_building_stocks, policy_id_selected })
        context.commit('buildingStocksFinish')
      })
      .catch(() => {
        context.commit('buildingStocksFail')
      })
  },

  addOrUpdateCustomBuildingStocks({ commit, dispatch, rootState }, { custom_building_stocks, to, from }) {
    const policy_id_selected = rootState.assumptions.policy_id_selected
    custom_building_stocks = attachPolicyToBuildingEstimates(JSON.parse(JSON.stringify(custom_building_stocks)), policy_id_selected)
    custom_building_stocks = updateUnits({ items: custom_building_stocks, from, to })
    commit('setCustomBuildingStocks', { custom_building_stocks, policy_id_selected  })
    setTimeout(() => {
      dispatch('assumptions/storeBatchAllCustomBuildings', null, { root: true })
    }, 200)
  },

  addCustomBuildingStocksToPolicy({  commit, dispatch, rootState }, {  current_custom_building_stocks, policy_id }) {
    const newCustomBuildingStocks = JSON.parse(JSON.stringify(current_custom_building_stocks)).map(custom_building_stock => {
      delete custom_building_stock.id
      return {
        ...custom_building_stock,
        policy_id
      }
    })
    dispatch('clearCustomBuildingStocks', newCustomBuildingStocks)
      .then(() => {
        const policy_id_selected = policy_id ?? rootState.assumptions.rootState
        commit('setCustomBuildingStocks', { custom_building_stocks: newCustomBuildingStocks, policy_id_selected })
        dispatch('assumptions/storeBatchAllCustomBuildings', null, { root: true })
          .then(() => {
            dispatch('getCustomBuildingStocks')
          })
      })
  },

  storeBatchCustomBuildingStocks({ state }, custom_building_stocks = null ) {
    const payload = custom_building_stocks ?? state.custom_building_stocks
    return CustomBuildingStockApiService
      .store_batch(payload)
  },

  clearCustomBuildingStocks(context, custom_building_stocks ) {
    return CustomBuildingStockApiService
      .delete_batch(custom_building_stocks)
      .then(({ data }) => {
        if (data.deleted) {
          context.commit('clearCustomBuildingStocks', custom_building_stocks)
        }
      })
      .catch((e) => {
        if (e === ERRORS.USER_NOT_LOGGED_IN_DELETE_FAIL) {
          context.commit('clearCustomBuildingStocks', custom_building_stocks)
        } else {
          throw new Error(e)
        }
      })
  }
  
}
