<template>
  <BaseModal
    ref="baseModal"
    modal-dialog-classes="w-full max-w-sm"
    :title="`Attention`"
  >
    <div
      v-if="vintage"
      class="w-full flex flex-col items-start mt-6"
    >
      <p class="text-base text-gray04">
        Delete all {{ vintage.items ? vintage.items.length : vintage.measures.length }} combined measures for vintage {{ vintage.title }}?
      </p>

      <AppButton
        class="mt-6"
        label="Confirm delete"
        size="big"
        @click="deletePolicy()" 
      />
    </div>
  </BaseModal>
</template>

<script>

export default {
  name: 'ModalDeleteCustomCombination',
  data: () => ({
    vintage: null
  }),
  mounted() {
    this.$eventBus.$on('openModalDeleteCustomCombination', ({ vintage }) => {
      this.vintage = vintage
      this.$refs.baseModal.showModal = true
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('openModalDeleteCustomCombination')
  },
  methods: {
    deletePolicy() {
      this.$api.delete(`/api/custom_combinations/delete/${this.vintage.custom_combination_id}`)
        .then(() => {
          this.$toast.success('Combined measure deleted sucessfuly!')
          this.$eventBus.$emit('getPolicy')
          this.$emit('getPolicyMap')
          this.$refs.baseModal.showModal = false
        })
    }
  }
}
</script>