
<template>
  <div class="w-full rounded-sm border border-blue05 p-6">
    <div class="w-full flex items-center justify-between">
      <h3 class="text-header-3 text-bluebrand font-bold">
        Policy Summary Details
      </h3>
      <AppCardButton
        icon="columns"
        description="Add/Hide Columns"
        icon-class="text-deepsky-500"
        class="cursor-pointer"
        @click.native="$eventBus.$emit('openDrawerPolicyEditColumns', 'Existing Buildings')"
      />
    </div>
    <div class="w-full mt-5 whitespace-no-wrap flex">
      <table
        v-if="!getPolicyIsEmpty"
        ref="table"
        class="policytable relative align-top block border-collapse"
      >
        <PolicyTableHead
          :column-groups="policyExistingColumnGroupsSelected.columnGroups"
        />
        <PolicyTableBody
          :policy="policy"
          :policy_summary_data="policy_summary_data"
          :column-groups="policyExistingColumnGroupsSelected.columnGroups"
          :items-hidden-indexes="itemsHiddenIndexes"
        />
      </table>
    </div>
  </div>
</template>

<script>
import { handleTableScroll } from '@/util/Functions'
import PolicyTableHead from './PolicyTableHead.vue'
import PolicyTableBody from './PolicyTableBody.vue'
export default {
  name: 'PolicyTable',
  components: { PolicyTableHead, PolicyTableBody },
  props: ['policy', 'policy_summary_data'],
  computed: {
    policyExistingColumnGroupsSelected() {
      return this.$store.getters['policy/getterPolicyExistingBuildingsColumnsSelected']
    },
    itemsHiddenIndexes() {
      return [...this.$store.getters['policy/getterHiddenItems']].map(item => item.index)
    },
    getPolicyIsEmpty() {
      return this.policy_summary_data[0]?.items?.length === 0
    },
  },
  mounted() {
    this.$refs.table.addEventListener('scroll', handleTableScroll)
  },
  beforeDestroy() {
    this.$refs.table.removeEventListener('scroll', handleTableScroll)
  }
}
</script>

<style lang="scss" scoped>
  .policytable {
    min-height: 265px;
    max-height: calc(100vh - 300px);
    max-width: 100%;
    overflow: scroll;

    @media screen and (min-height: 900px) {
      max-height: 540px;
    }

    thead {
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.04), 5px 2px 5px rgba(0, 0, 0, 0.08);
      tr {
        &:first-of-type {
          th {
            padding-top: 8px;
            padding-bottom: 16px;
            vertical-align: bottom;
            &:after {
              position: relative;
              top: 8px;
              content: "";
              width: 100%;
              display: block;
              border-bottom: 2px solid #CFDFE5;
            }
          }
        } // &:first-of-type
      }
    }
    &.table-has-scroll-left ::v-deep tbody tr td:first-of-type::after {
      position: absolute;
      content: '';
      top: 0;
      right: -30px;
      bottom: 0;
      width: 30px;
      background: rgb(235,235,235);
      background: -moz-linear-gradient(90deg, rgba(235,235,235,0.7875525210084033) 10%, rgba(255,255,255,0) 100%);
      background: -webkit-linear-gradient(90deg, rgba(235,235,235,0.7875525210084033) 10%, rgba(255,255,255,0) 100%);
      background: linear-gradient(90deg, rgba(235,235,235,0.7875525210084033) 10%, rgba(255,255,255,0) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ebebeb",endColorstr="#ffffff",GradientType=1);
    }
  }
</style>