<template>
  <div 
    :class="isCalculating ? 'pointer-events-none opacity-30 cursor-not-allowed' : ''"
    class="w-full h-full flex flex-col overflow-y-auto p-8"
  >
    <h1 class="text-header-6">
      Inputs
    </h1>
    <div class="flex border p-3 space-x-6">
      <div
        class="w-full relative"
        style="max-width: 200px"
      >
        <VueSelect 
          v-model="userInputs.climate_zone"
          class="vue-select"
          label="raw"
          :options="climate_zones"
        />
        <label>Climate Zone</label>
      </div>
      
      <div class="w-full relative">
        <VueSelect 
          v-model="userInputs.study"
          class="vue-select"
          :options="studies"
        />
        <label>Study</label>
      </div>

      <div
        v-if="userInputs.study"
        class="w-full relative"
        style="max-width: 350px"
      >
        <VueSelect 
          v-model="userInputs.prototype"
          class="vue-select"
          label="title"
          :options="prototypes"
        />
        <label>Prototype</label>
      </div>
      
      <div
        v-if="userInputs.study"
        class="w-full relative"
        style="max-width: 350px"
      >
        <VueSelect 
          v-model="userInputs.vintage"
          class="vue-select"
          label="title"
          :options="vintages"
        />
        <label>Vintage</label>
      </div>
    </div>
    
    <div
      v-if="hasEnoughForTargetScore"
      class="flex border border-ash-200 p-3 space-x-6 mt-6"
    >
      <!-- <AppButton
        @click="getBaseTargetScore()"
        label="Get Target Score" 
      /> -->
      <AppButton
        v-if="baseTargetScore"
        label="Get Compliance Cost Range"
        @click="getMeasureMenuImpacts()" 
      />
      <AppButton
        v-if="baseTargetScore"
        label="Get Chart Forecast"
        @click="getMeasureMenuImpactsForecast()" 
      />
      <InputWithLabel
        v-if="baseTargetScore"
        type="number"
        label="Base Target Score"
        :value="baseTargetScore"
        :disabled="true"
        class="max-w-xs"
        :tooltip="`This target score represents all possible measures from the selected CZ, Prototype and Vintage selected`"
      />
      <InputWithLabel
        v-if="baseTargetScore"
        v-model="targetScore"
        type="number"
        label="Target Score"
        class="max-w-xs"
      />
      <div>
        <Checkbox
          v-model="userInputs.debug"
          label="Debug Combinations?"
        />
      </div>
    </div>
    
    <div class="grid grid-cols-4 my-8 gap-8">
      <div
        v-if="studyDataForAllPossibleMeasures.length > 0"
        class="flex flex-col space-y-8"
      >
        <table          
          class="border-collapse border text-left w-full"
        >
          <thead>
            <tr>
              <th
                colspan="4"
                class="font-normal text-header-6"
              >
                All Possible Measures ({{ studyDataForAllPossibleMeasures.length }}) <br>
                <small class="italic">{{ userInputs.prototype.title }} / {{ userInputs.vintage.title }} / {{ userInputs.climate_zone.raw }}</small>
              </th>
            </tr>
            <tr>
              <th class="text-header-4 font-bold italic">
                Measure
              </th>
              <th
                class="text-header-4 font-bold italic"
                width="40"
              >
                Score
              </th>
              <th
                class="text-header-4 font-bold italic"
                width="20"
              >
                Excluded
              </th>
              <th
                class="text-header-4 font-bold italic"
                width="20"
              >
                Mandatory
              </th>
            </tr>
          </thead>
          <tbody>
            <tr 
              v-for="study_data in studyDataForAllPossibleMeasures" 
              :key="study_data.id"
              class="hover:bg-ash-200"
              :class="`measure-${study_data.measure_id}`"
              @mousedown="blinkMeasureRow(study_data.measure_id, true)"
              @mouseup="blinkMeasureRow(study_data.measure_id, false)"
            >
              <td>{{ study_data.measure.title }}</td>
              <td>{{ study_data.measure_score }}</td>
              <td 
                :class="userInputs.measures_excluded.includes(study_data.measure_id) ? 'bg-red' : ''"
                @click="toggleMeasure('measures_excluded', study_data.measure_id)"
              />
              <td 
                :class="userInputs.measures_mandatory.includes(study_data.measure_id) ? 'bg-deepsky-300' : ''"
                @click="toggleMeasure('measures_mandatory', study_data.measure_id)"
              />
            </tr>
          </tbody>
        </table>

        <table 
          v-if="studyDataWithoutAllPossibleMeasures.length > 0"
          class="border-collapse border text-left w-full"
        >
          <thead>
            <tr>
              <th
                colspan="4"
                class="font-normal text-header-6"
              >
                Measures without the All Possible ({{ studyDataWithoutAllPossibleMeasures.length }})
              </th>
            </tr>
            <tr>
              <th class="text-header-4 font-bold italic">
                Measure
              </th>
              <th
                class="text-header-4 font-bold italic"
                width="40"
              >
                Score
              </th>
              <th
                class="text-header-4 font-bold italic"
                width="20"
              >
                Excluded
              </th>
              <th
                class="text-header-4 font-bold italic"
                width="20"
              >
                Mandatory
              </th>
            </tr>
          </thead>
          <tbody>
            <tr 
              v-for="study_data in studyDataWithoutAllPossibleMeasures" 
              :key="study_data.id"
              class="hover:bg-ash-200"
              :class="`measure-${study_data.measure_id}`"
              @mousedown="blinkMeasureRow(study_data.measure_id, true)"
              @mouseup="blinkMeasureRow(study_data.measure_id, false)"
            >
              <td>{{ study_data.measure.title }}</td>
              <td>{{ study_data.measure_score }}</td>
              <td
                :class="userInputs.measures_excluded.includes(study_data.measure_id) ? 'bg-red' : ''"
                @click="toggleMeasure('measures_excluded', study_data.measure_id)"
              />
              <td 
                :class="userInputs.measures_mandatory.includes(study_data.measure_id) ? 'bg-deepsky-300' : ''"
                @click="toggleMeasure('measures_mandatory', study_data.measure_id)"
              />
            </tr>
          </tbody>
        </table>
      </div> <!-- grid-col-1-->

      <div
        v-if="complianceCostRangeStudyDataSorted.length > 0"
        class=""
      >
        <table class="border-collapse text-left w-full">
          <thead>
            <tr>
              <th
                colspan="9"
                class="font-normal text-header-6"
              >
                Measures Used for Combinations ({{ complianceCostRangeStudyDataSorted.length }}) <br>
                <small class="italic">All measures from the related Study Data whithout the excluded and mandatory measures</small>
              </th>
            </tr>
            <tr>
              <!-- <th @click="toogleSortBy('study_row_number')" class="text-header-4 font-bold italic">Row</th> -->
              <th
                class="text-header-4 font-bold italic"
                @click="toogleSortBy('id')"
              >
                ID
              </th>
              <th
                class="text-header-4 font-bold italic"
                @click="toogleSortBy('title')"
              >
                Measure
              </th>
              <th
                class="text-header-4 font-bold italic"
                @click="toogleSortBy('score')"
              >
                Score
              </th>
              <th class="text-header-4 font-bold italic">
                Cost/Score
              </th>
              <th
                class="text-header-4 font-bold italic"
                @click="toogleSortBy('initial_cost')"
              >
                Initial Cost
              </th>
              <th
                class="text-header-4 font-bold italic"
                @click="toogleSortBy('is_contingent')"
              >
                Is Contigent?
              </th>
              <th
                class="text-header-4 font-bold italic"
                @click="toogleSortBy('isFullyRepresentedPackage')"
              >
                Is isFRP?
              </th>
              <th
                class="text-header-4 font-bold italic"
                @click="toogleSortBy('is_package')"
              >
                Is Package?
              </th>
              <th class="text-header-4 font-bold italic">
                Exclude Measures
              </th>
            </tr>
          </thead>
          <tbody>
            <tr 
              v-for="study_data in complianceCostRangeStudyDataSorted" 
              :key="study_data.id"
              class="hover:bg-ash-200"
              :class="`measure-${study_data.measure_id}`"
              @mousedown="blinkMeasureRow(study_data.measure_id, true)"
              @mouseup="blinkMeasureRow(study_data.measure_id, false)"
            >
              <!-- <td>{{study_data.id}}</td> -->
              <td>{{ study_data.measure_id }}</td>
              <td>{{ study_data.measure.title }}</td>
              <td>{{ getMeasureScore(study_data) }}</td>          
              <td>{{ formatCurrency(study_data.initial_cost/getMeasureScore(study_data)) }}</td>          
              <td>{{ formatCurrency(study_data.initial_cost) }}</td>          
              <td>{{ study_data.measure.is_contingent ? 'Yes' : '' }}</td>
              <td>{{ study_data.measure.isFullyRepresentedPackage ? 'Yes' : '' }}</td>
              <td>{{ study_data.measure.is_package ? 'Yes' : '' }}</td>
              <td>{{ study_data.measure.exclude_measures }}</td>          
            </tr>
          </tbody>
        </table>
      </div>
      <!-- grid-col-2 -->

      <div
        v-if="complianceCostRange"
        class="border-4 border-red space-y-8 p-4"
      >
        <table
          v-for="(method, index) in complianceCostRange"
          :key="index"
          class="border-collapse text-left w-full"
        >
          <thead>
            <tr>
              <th
                colspan="4"
                class="font-normal text-header-6 text-red"
              >
                Ordered Combinations - Method ({{ index + 1 }}) {{ !index ? '(Contingents)' : '(Without Contingents)' }} <br>  <br>
                <small class="italic">
                  <strong>Lowest Implementation Cost</strong> {{ formatCurrency(method.lowestCombinationIncrementalCost) }} <br>
                  <strong>All Combinations</strong> {{ method.combinationsCount }} <br>
                  <strong>Valid Combinations</strong> {{ method.combinationsValidCount }} <br>
                  Min Target Score {{ method.minTargetScore }} <br>
                </small>
              </th>
            </tr>
            <tr>
              <th class="text-header-4 font-bold italic" />
              <th class="text-header-4 font-bold italic">
                Measure
              </th>
              <th class="text-header-4 font-bold italic">
                Score
              </th>
              <th class="text-header-4 font-bold italic">
                Initial cost
              </th>
            </tr>
          </thead>
          <tbody>
            <tr 
              v-for="study_data in method.combinationWithLowestIncrementalCost" 
              :key="study_data.id"
              class="hover:bg-ash-200"
              :class="`measure-${study_data.measure_id}`"
              @mousedown="blinkMeasureRow(study_data.measure_id, true)"
              @mouseup="blinkMeasureRow(study_data.measure_id, false)"
            >
              <td>{{ study_data.measure_id }}</td>
              <td>{{ study_data.measure.title }}</td>
              <td>{{ getMeasureScore(study_data) }}</td>          
              <td>{{ formatCurrency(study_data.initial_cost) }}</td>
            </tr>
            <tr>              
              <td colspan="2">
                Total
              </td>
              <td>{{ getCombinationTargetScore(method.combinationWithLowestIncrementalCost) }}</td>
              <td>{{ formatCurrency(getCombinationIncrementalCost(method.combinationWithLowestIncrementalCost)) }}</td>
            </tr>
          </tbody>
        </table>
        <!-- <pre>{{complianceCostRange}}</pre> -->
      </div>
      <!-- grid-col-3 -->
      
      <div
        v-if="permutations"
        class="border-4 border-lime-600 space-y-8 p-4"
      >
        <table
          v-for="(method, index) in permutations"
          :key="index"
          class="border-collapse text-left w-full"
        >
          <thead>
            <tr>
              <th
                colspan="4"
                class="font-normal text-header-6 text-lime-600"
              >
                Permutations - Method ({{ index + 1 }}) {{ !index ? '(Contingents)' : '(Without Contingents)' }} <br>  <br>
                <small class="italic">
                  <strong>Lowest Implementation Cost</strong> {{ formatCurrency(method.lowestCombinationIncrementalCost) }} <br>
                  <strong>All Combinations</strong> {{ method.combinationsCount }} <br>
                  <strong>Valid Combinations</strong> {{ method.combinationsValidCount }} <br>
                  Min Target Score {{ method.minTargetScore }} <br>
                </small>
              </th>
            </tr>
            <tr>
              <th class="text-header-4 font-bold italic" />
              <th class="text-header-4 font-bold italic">
                Measure
              </th>
              <th class="text-header-4 font-bold italic">
                Score
              </th>
              <th class="text-header-4 font-bold italic">
                Initial cost
              </th>
            </tr>
          </thead>
          <tbody>
            <tr 
              v-for="study_data in method.combinationWithLowestIncrementalCost" 
              :key="study_data.id"
              class="hover:bg-ash-200"
              :class="`measure-${study_data.measure_id}`"
              @mousedown="blinkMeasureRow(study_data.measure_id, true)"
              @mouseup="blinkMeasureRow(study_data.measure_id, false)"
            >
              <td>{{ study_data.measure_id }}</td>
              <td>{{ study_data.measure.title }}</td>
              <td>{{ getMeasureScore(study_data) }}</td>          
              <td>{{ formatCurrency(study_data.initial_cost) }}</td>
            </tr>
            <tr>              
              <td colspan="2">
                Total
              </td>
              <td>{{ getCombinationTargetScore(method.combinationWithLowestIncrementalCost) }}</td>
              <td>{{ formatCurrency(getCombinationIncrementalCost(method.combinationWithLowestIncrementalCost)) }}</td>
            </tr>
          </tbody>
        </table>
        <!-- <pre>{{complianceCostRange}}</pre> -->
      </div>
      <!-- grid-col-4 -->

      <div
        v-if="impactForecastByTargetScore"
        class="border-4 border-lime-600 space-y-8 p-4"
      >
        <table class="border-collapse text-left w-full">
          <thead>
            <tr>
              <th
                colspan="4"
                class="font-normal text-header-6 text-lime-600"
              >
                Implementation Cost Forecast <br>  <br>
              </th>
            </tr>
            <tr>              
              <th class="text-header-4 font-bold italic">
                Score
              </th>
              <th class="text-header-4 font-bold italic">
                Low <br><small class="font-normal">(Permutations, Contingent/M1)</small>
              </th>
              <th class="text-header-4 font-bold italic">
                High <br><small class="font-normal">(Permutations, NoContingent/M2)</small>
              </th>
              <th class="text-header-4 font-bold italic">
                Range
              </th>
            </tr>
          </thead>
          <tbody>
            <tr 
              v-for="index in Number(baseTargetScoreForRange)" 
              :key="index"
              class="hover:bg-ash-200"              
            >
              <td>{{ index }}</td>
              <td>{{ formatCurrency(impactForecastByTargetScore[0][index]) }}</td>
              <td>{{ formatCurrency(impactForecastByTargetScore[1][index]) }}</td>
              <td>{{ formatCurrency(roundToRange(impactForecastByTargetScore[0][index])) }} - {{ formatCurrency(roundToRange(impactForecastByTargetScore[1][index])) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>


    <div
      v-if="complianceCostRange"
      class="grid grid-cols-2 gap-8"
    >
      <div class="border-4 border-red p-4">
        <h1 class="text-header-6 py-4">
          Ordered Combinations
        </h1>
        <div class="grid grid-cols-2 gap-8">
          <div
            v-for="(method, index) in complianceCostRange"
            :key="index"
          >
            <table class="border-collapse text-left w-full">
              <thead>
                <tr>
                  <th
                    colspan="4"
                    class="font-normal text-header-6 text-red"
                  >
                    Method ({{ index + 1 }}) {{ !index ? '(Contingents)' : '(Without Contingents)' }} <br>  <br>
                    <small class="italic">                
                      <strong>All Combinations</strong> {{ method.combinationsCount }}                
                      <strong>Valid Combinations</strong> {{ method.combinationsValidCount }}                
                    </small>
                  </th>
                </tr>
                <tr>
                  <th class="text-header-4 font-bold italic">
                    Index
                  </th>
                  <th class="text-header-4 font-bold italic">
                    Measures
                  </th>
                  <th class="text-header-4 font-bold italic">
                    Score
                  </th>
                  <th class="text-header-4 font-bold italic">
                    Initial cost
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr 
                  v-for="(combination, combinationIndex) in method.combinations" 
                  :key="combinationIndex"
                  class="hover:bg-ash-200"            
                >
                  <td>{{ index + 1 }}</td>
                  <td><pre>{{ combination.map(s => s.measure.title) }}</pre></td>
                  <td>{{ getCombinationTargetScore(combination) }}</td>          
                  <td>{{ formatCurrency(getCombinationIncrementalCost(combination)) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      
      <div class="border-4 border-lime-600 p-4">
        <h1 class="text-header-6 py-4">
          Permutations
        </h1>
        <div class="grid grid-cols-2 gap-8">
          <div
            v-for="(method, index) in permutations"
            :key="index"
          >
            <table class="border-collapse text-left w-full">
              <thead>
                <tr>
                  <th
                    colspan="4"
                    class="font-normal text-header-6 text-lime-600"
                  >
                    Method ({{ index + 1 }}) {{ !index ? '(Contingents)' : '(Without Contingents)' }} <br>  <br>
                    <small class="italic">                
                      <strong>All Combinations</strong> {{ method.combinationsCount }}                
                      <strong>Valid Combinations</strong> {{ method.combinationsValidCount }}                
                    </small>
                  </th>
                </tr>
                <tr>
                  <th class="text-header-4 font-bold italic">
                    Index
                  </th>
                  <th class="text-header-4 font-bold italic">
                    Measures
                  </th>
                  <th class="text-header-4 font-bold italic">
                    Score
                  </th>
                  <th class="text-header-4 font-bold italic">
                    Initial cost
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr 
                  v-for="(combination, combinationIndex) in method.combinations" 
                  :key="combinationIndex"
                  class="hover:bg-ash-200"            
                >
                  <td>{{ combinationIndex + 1 }}</td>
                  <td><pre>{{ combination.map(s => s.measure.title) }}</pre></td>
                  <td>{{ getCombinationTargetScore(combination) }}</td>          
                  <td>{{ formatCurrency(getCombinationIncrementalCost(combination)) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MeasureApiService from '@/services/api/MeasureApiService'
import MeasureMenuImpactsService from '@/services/measure-menu/MeasureMenuImpactsService'

export default {
  name: 'TargetScoreComplianceCostPlayground',
  data: () => ({
    climate_zones : [],
    studies : [],    
    userInputs: {
      climate_zone : false,
      study: false,
      prototype : false,
      vintage: false,
      measures_excluded : [],
      measures_mandatory : [],
      debug: false
    },
    
    isCalculating : false,  
    sortBy: 'target_score',
    sortDirection : 0,
    targetScore : 0,

    studyDataByMeasures: false,
    allPossibleMeasuresIds: [],
    complianceCostRange : false,
    complianceCostRangeStudyData: [],
    permutations: {},

    impactForecastByTargetScore: false
  }),
  computed: {
    prototypes() {
      return this.userInputs.study ? this.userInputs.study.prototypes : []
    },
    vintages() {
      return this.userInputs.study ? this.userInputs.study.vintages : []
    },
    measures() {
      return this.userInputs.study ? this.userInputs.study.measures : []
    },
    hasEnoughForTargetScore() {
      return this.userInputs.climate_zone && this.userInputs.prototype && this.userInputs.vintage
    },
    studyDataForAllPossibleMeasures() {
      if (this.allPossibleMeasuresIds) {
        return [...Object.values(this.studyDataByMeasures)].filter(study_data => this.allPossibleMeasuresIds.includes(study_data.measure_id))
      }
      return false
    },
    studyDataWithoutAllPossibleMeasures() {
      if (this.allPossibleMeasuresIds) {
        return [...Object.values(this.studyDataByMeasures)].filter(study_data => this.allPossibleMeasuresIds.includes(study_data.measure_id) ? false : true)
      }
      return false
    },
    complianceCostRangeStudyDataSorted() {
      return [...this.complianceCostRangeStudyData]
      .map((study_data) => {
        study_data.title = study_data.measure.title
        study_data.is_contingent = study_data.measure.is_contingent
        study_data.score = this.getMeasureScore(study_data)
        return study_data
      })
      .sort((a, b) => {
        return this.sortDirection ? b[this.sortBy] - a[this.sortBy] : a[this.sortBy] - b[this.sortBy]
      })
    },
    baseTargetScore() {
      if (this.studyDataForAllPossibleMeasures) {
        return this.studyDataForAllPossibleMeasures.reduce((acc, { measure, measure_score }) => {
          acc = this.userInputs.measures_excluded.includes(measure.id) || this.userInputs.measures_mandatory.includes(measure.id) ? acc : acc + measure_score
          return acc
        }, 0)
      }
      return false
    },
    baseTargetScoreForRange() {
      return this.targetScore > 0 ? this.targetScore : this.baseTargetScore
    },
    userInputPayload() {
      const { vintage, prototype, climate_zone } = this.userInputs
      return vintage && prototype && climate_zone ? { 
        vintage_id : vintage.id , 
        prototype_id: prototype.id, 
        climate_zone_raw : climate_zone.raw 
      } : false
    },
    complianceCostRangePayload() {
      if (!this.userInputPayload || !this.baseTargetScoreForRange || !this.userInputs.measures_excluded || !this.userInputs.measures_mandatory) return false
      return { 
        studyDataArgs : this.userInputPayload, 
        minTargetScore: this.baseTargetScoreForRange,
        measuresExcludedIds: this.userInputs.measures_excluded,
        measuresMandatoryIds : this.userInputs.measures_mandatory,
        debug : this.userInputs.debug,
      }
    }
  },
  watch: {
    'userInputs.study'(study) {
      this.userInputs.prototype = study.prototypes[0] ?? false
      this.userInputs.vintage = study.vintages[0] ?? false
    },
    userInputPayload: {
      handler(payload) {
        if (payload) this.getBaseTargetScore()      
      },
      immediate: true
    },
    complianceCostRangePayload(payload) {
      if (payload) this.getMeasureMenuImpacts()
    },
  },
  async mounted(){
    this.getInitialDataForInputs()
  },
  methods: {
    getInitialDataForInputs() {
      this.getStudies()
      this.getClimateZones()
    },
    async getStudies() {
      const query = `
        {
          studies(
            orderBy: [{ column: "released_at" , order: "desc"}]
          ) {
            id
            title
            released_at
            prototypes {
              id
              title
            }
            vintages {
              id
              title
              type_vintage_id
            }
            measures {
              id
              title
              exclude_measures
            }
          }
        }
        `
      const { data } = await this.$graphql({ query })
      this.studies = data.studies.map(study => {
        study.label = `${study.title} - ${study.released_at}`
        return study
      })
      this.userInputs.study = data.studies[0]
    },
    async getClimateZones() {
      const climate_zones = this.$store.getters['globalEntities/ClimateZone/getterGlobalClimateZones']
      this.climate_zones = climate_zones
      this.userInputs.climate_zone = climate_zones[0]
    },
    async getBaseTargetScore() {
      if (!this.userInputPayload) return false 
      this.studyDataByMeasures = await this.getStudyDataByMeasures()
      const getAllPossibleQuery = await MeasureApiService.getAllPossible(this.userInputPayload)
      this.allPossibleMeasuresIds = getAllPossibleQuery.data.measures.map(i => i.id)
    },    
    async getStudyDataByMeasures() {
      const studyData = await this.getStudyData(this.userInputPayload)
      return studyData.reduce((acc, study_data) => {
        study_data.measure_score = this.getMeasureScore(study_data)
        acc[study_data.measure_id] = study_data
        return acc
      },{})
    },
    toggleMeasure(collection, measure_id) {
      if (this.userInputs[collection].includes(measure_id)) {        
        this.userInputs[collection] = [...this.userInputs[collection].filter(i => i !== measure_id)]
      } else {
        this.$set(this.userInputs, collection, [...this.userInputs[collection], measure_id ])
      }
    },    
    getMeasureMenuImpacts() {

      this.isCalculating = true
      this.complianceCostRange = false
      this.complianceCostRangeStudyData = [] 

      this.getComplianceCostRange(this.complianceCostRangePayload)
        .then(({ methods, studyDataRows }) => {
        this.complianceCostRangeStudyData = studyDataRows
        this.isCalculating = false
        this.permutations = methods
      })
    },
    
    getMeasureMenuImpactsForecast() {

      this.isCalculating = true
      this.impactForecastByTargetScore = false
      this.getComplianceCostRangeImpacts(this.complianceCostRangePayload)
        .then((impacts) => {
          this.impactForecastByTargetScore = impacts
          this.isCalculating = false
        })
      
    },

    blinkMeasureRow(measure_id, status) {
      document.querySelectorAll(`.measure-${measure_id}`).forEach(item => {
        item.style.backgroundColor = status ? 'red' : 'transparent'
      })
    },
    toogleSortBy(sortBy) {
      if (this.sortBy === sortBy) {
        this.sortDirection = !this.sortDirection
      } else {
        this.sortBy = sortBy
      }
    },
    ...MeasureMenuImpactsService
  },
  
  
}
</script>

<style scoped lang="scss">
  table {    
    th {
      text-align: left;
    }

    td, th {
      padding: 10px;
      border: 1px solid lightgray;
    }
  }
</style>