<template>
  <BaseModal
    ref="baseModal"
    modal-dialog-classes="w-full max-w-sm"
  >
    <div class="w-full flex flex-col items-start mt-6">
      <h2 class="text-header-6 mx-auto">
        Delete policy <span class="text-deepsky-700">{{ title }}</span>?
      </h2>
      <AppButton
        class="mt-6 mx-auto"
        label="Confirm delete"
        size="big"
        @click="deletePolicy()" 
      />
    </div>
  </BaseModal>
</template>

<script>

export default {
  name: 'ModalDeletePolicy',
  data: () => ({
    policy_id : false,
    title: ''
  }),
  computed: {
    validationTitle() {
      if(!this.title) return { hasError: true, label: 'Please inform the Policy Name'}
      return { hasError: false }
    }
  },
  mounted() {
    this.$eventBus.$on('openModalDeletePolicy', ({ policy }) => {
      this.$refs.baseModal.showModal = true
      this.policy_id = policy.id
      this.title = policy.title
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('openModalDeletePolicy')
  },
  methods: {
    onLoginSuccess() {
      console.log('onLoginSuccess')
    },
    deletePolicy() {
      this.$api.delete(`/api/policies/delete/${this.policy_id}`)
        .then((response) => {
          if (response.status === 200) {
            this.$appToast('Policy deleted sucessfuly!')
            this.$refs.baseModal.showModal = false
            this.$store.commit('policy/closePolicy', { id: this.policy_id })            
            this.$store.dispatch('policy/getAllUserPolicies')
            if (this.$route.name !== 'PolicyList') {
              this.$router.push({ name: 'PolicyList' })
            }
          }
        })
    }
  }
}
</script>