<template>
  <div v-if="building_type && climate_zone_prefix">
    <div class="pt-6 px-6">
      <div class="flex justify-between mb-7">
        <PsButton
          label="Save to policy"
          icon="add_circle"
          size="medium"
          layout="solid"
          icon-position="left"
          @click="openAssumptionsModal()"
        />
        <PsButton
          label="Restore default"
          icon="restart_alt"
          size="medium"
          layout="ghost"
          icon-position="left"
          :disabled="$store.getters['assumptions/buildingStocks/getterCustomJurisdictionBuildingStocks']({ type_prototype_id: building_type.type_prototypes.map(type_prototype => type_prototype.id) }).length == 0"
          @click="restoreToDefault()"
        />
      </div>

      <PsDialog
        v-if="shouldShowNotLoggedWarning"
        class="mt-3 mb-5"
        theme="alert"
        :has-close="false"
        message="Be sure to register or login so we can save changes to your workspace"
        layout="vertical"
        @close="$store.commit('assumptions/showNotLoggedWarning', false, { root: true })"
      >
        <template #action>
          <div>
            <p
              class="psui-font-bold mt-1 hover:opacity-80 transition-all cursor-pointer inline-block"
              @click="openModalUserSign()"
            >
              Register / Login
            </p>
          </div>
        </template>
      </PsDialog>
    

      <h2 class="font-bold psui-text-gray-80 mb-1">
        Residential Units as of 2020
      </h2>
      <ul class="flex justify-between psui-text-small psui-text-gray-60">
        <li>Total units in {{ climateZones.length }} climate zones </li>
        <li class="psui-text-gray-80">
          {{ formatUnits($store.getters['assumptions/buildingStocks/getterAllBuildingStocksUnits']({ type_prototype_id: building_type.type_prototypes.map(type_prototype => type_prototype.id) })) }}
        </li>
      </ul>

      <hr class="psui-border-blue-70 opacity-10 my-7">

      <AssumptionsBuildingEstimatesClimateZones
        :climate_zone_prefix="climate_zone_prefix"
        :climate_zones="climateZones"
        @setClimateZonePrefix="climate_zone_prefix = $event"
      />
      <ul class="flex justify-between psui-text-small psui-text-gray-60 mb-7">
        <li>Units in selected climate zone</li>
        <li class="psui-text-gray-80">
          {{ formatUnits($store.getters['assumptions/buildingStocks/getterAllBuildingStocksUnits']({ type_prototype_id: building_type.type_prototypes.map(type_prototype => type_prototype.id), climate_zone_prefix: climate_zone_prefix })) }}
        </li>
      </ul>

      <div 
        v-for="type_prototype in building_type.type_prototypes" 
        :key="type_prototype.id"
        class="mb-7"
      >
        <h2 class="font-bold psui-text-gray-80 mb-4">
          {{ type_prototype.title }}
        </h2>

        <div
          v-for="type_vintage in type_vintages"
          :key="`${type_prototype.id}-${type_vintage.id}`"
          class="flex justify-between"
        >
          <AssumptionsBuildingStocksInput
            :label="type_vintage.title"
            :filters="{
              climate_zone_prefix: climate_zone_prefix,
              type_vintage_id: type_vintage.id,
              type_prototype_id: type_prototype.id
            }"
          />
        </div>
        <div class="w-full flex justify-between items-center mb-2">
          <p class="psui-text-small psui-text-gray-60">
            Total
          </p>
          <PsInput
            :value="formatUnits($store.getters['assumptions/buildingStocks/getterAllBuildingStocksUnits']({ 
              type_prototype_id: type_prototype.id,
              climate_zone_prefix: climate_zone_prefix
            }))"
            layout="mini"
            disabled
          />
        </div>
      </div>
    </div>
  </div>  
</template>

<script>
import AssumptionsBuildingStocksInput from '../AssumptionsBuildingStocksInput.vue'
import AssumptionsBuildingEstimatesClimateZones from '../AssumptionsBuildingEstimatesClimateZones.vue'
export default {
  name: 'AssumptionsResidentialExisting',
  components: { AssumptionsBuildingEstimatesClimateZones, AssumptionsBuildingStocksInput },
  props: ['building_type' , 'type_vintages' , 'study_type'],
  data: () => ({
    climate_zone_prefix : false,
  }),
  computed: {
    climateZones() {
      return this.$store?.getters['lastJurisdictionVisited']?.climate_zones ?? []
    },
    shouldShowNotLoggedWarning() {
      return !this.$store.getters.getterLoggedUser && this.$store.getters['assumptions/buildingStocks/getterCustomJurisdictionBuildingStocks']().length
    },
  },
  watch: {
    climateZones: {
      handler(czs) {
        if (czs) this.climate_zone_prefix = czs[0].prefix
      },
      immediate: true
    }    
  },
  methods: {
    openModalUserSign() {
      this.$eventBus.$emit('openModalUserSign')
      const event = {
        name: 'openAssumptionDrawerFromAppBoot',
        payload: this.$store.getters['assumptions/getterAssumptionsDrawerOptions']
      }
      this.$store.commit('general/setRedirectTo', { route: this.$route.path, event })
    },
    openAssumptionsModal() {
      const eventName = 'openAssumptionsModal'
      const eventPayload = 'STOCKS'
      this.$eventBus.$emit(eventName, eventPayload)
      this.$store.commit('general/setRedirectTo', { route: this.$route.path, event: { name: eventName, payload: eventPayload } })
    },
    restoreToDefault() {
      this.$store.dispatch('assumptions/buildingStocks/clearCustomBuildingStocks', this.getBuildingStocks)
    },
  }
}
</script>

<style lang="scss" scoped>

  ::v-deep .psui-el-input {
    width: 165px;
  }

</style>