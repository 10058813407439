<template>
  <div class="p-6">
    <InputWithLabel
      v-model="climate_zone.prefix"
      class="mt-6"
      :label="'Prefix (only numbers)'"
      :mask="'##'"
    />

    <InputWithLabel
      v-model="climate_zone.suffix"
      class="mt-6"
      :label="'Suffix (only letters)'"
      :mask="'AAAAAA'"
    />

    <div class="w-full relative mt-6">
      <VueSelect 
        v-model="climate_zone.fallback_climate_zone_id"
        class="vue-select"
        placeholder="Select a fallback climate zone"
        :class="climate_zone.fallback_climate_zone_id ? 'filled' : ''"
        label="raw"
        :options="climate_zones"
        :reduce="item => item.id"
      />
      <label>Fallback climate zone</label>
    </div>

    <hr class="border-b border-gray05 my-6">
    <div>
      <PsButton
        v-if="!$route.params.climate_zone_id"
        label="Create"
        size="big"
        icon="add"
        @click="store()"
      />
        
      <PsButton
        v-else
        label="Update"
        size="big"
        icon="update"
        @click="update()"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AdminClimateZoneForm',
  data() {
    return {
      climate_zones: [],
      climate_zone: {
        id: '',
        prefix: '',
        suffix: '',
        raw: '',
        fallback_climate_zone_id: null
      }
    }
  },
  computed: {
    raw() {
      return this.climate_zone.prefix + '-' + this.climate_zone.suffix.trim()
    }
  },
  mounted() {
    if(this.$route.params.climate_zone_id) {
      this.getClimateZone()
    }

    this.getClimateZones()
  },
  methods: {

     async getClimateZones({ offset = 0 } = {}) {
      this.offset = offset
      const query = `
        {
          climate_zones(
            orderBy: [{column: "raw", order: "asc"}],
          ) {
            id
            prefix
            suffix
            raw
          }
        }
      `
      const { data } = await this.$graphql({ query })

      this.climate_zones = data.climate_zones
    },

    async getClimateZone() {
      const query = `
        {
          climate_zone (
            where: [
              { column: "id", operation: "=", value: "${this.$route.params.climate_zone_id}"}
            ]
          ){
            id
            prefix
            suffix
            raw
            fallback_climate_zone_id
            fallback_climate_zone {
              id
              raw
            }
          }
        }
      `
      const { data } = await this.$graphql({ query })

      this.climate_zone = data.climate_zone
    },

    store() {
      this.climate_zone.raw = this.raw
      this.$api.post(`/climate_zone`, this.climate_zone, { loader: true })
        .then(() => {
          this.$toast.success('ClimateZone successfully created')
          this.$router.push({ name: 'AdminClimateZoneList' })
        })
    },

    update() {
      this.climate_zone.raw = this.raw
      this.$api.put(`/climate_zone/${this.climate_zone.id}`, this.climate_zone, { loader: true })
        .then(() => {
          this.$toast.success('ClimateZone successfully updated')
          this.$router.push({ name: 'AdminClimateZoneList' })
        })
    }
  }
}
</script>
