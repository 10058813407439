<template>
  <div class="app--card p-6">
    <slot />
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
/* Card */
/* ----------------------------------------- */
.app--card {
  border: 1px solid app-color(blue05);
  border-radius: 10px;
  background-color: white;
}  
/* ----------------------------------------- Card */

</style>