<template>
  <div
    v-click-outside="close"
    class="jurisdiction--select w-auto max-w-full overflow-x-none relative flex-shrink-0"
    :class="cssClass"
    @keydown.up="navigate('up')"
    @keydown.down="navigate('down')"
    @keydown.enter="selectOnEnter()"
    @keydown.tab="selectOnEnter()"
    @keydown.esc="close()"
  >
    <div class="input-wrapper relative">
      <span
        class="width-machine"
        :class="inputClass"
      >{{ search ? search : placeholder }}</span>
      <input
        :id="randomStringToInputIdAndName"
        ref="input"
        v-model="search"
        type="text"
        :label="label"
        :placeholder="placeholder"
        :class="inputClass"
        :name="randomStringToInputIdAndName"
        class="block appearance-none bg-transparent"
        autocomplete="off"
        autocorrect="off"
        autocapitalize="off"
        spellcheck="false"
        @input="handleRequest()"
        @focus="onFocus"
        @blur="onBlur"
      >
      <div
        v-if="icon"
        class="icon material-icons"
        :class="iconClass"
      >
        {{ icon }}
      </div>
    </div>
    <transition
      enter-active-class="transition ease-out duration-100 transform"
      enter-class="opacity-0 scale-95"
      enter-to-class="opacity-100 scale-100"
      leave-active-class="transition ease-in duration-100 transform"
      leave-class="opacity-100 scale-100"
      leave-to-class="opacity-0 scale-95"
    >
      <div 
        v-if="search"
        class="origin-top-right absolute max-h-1/2 min-w-300 overflow-y-auto left-0 mt-2 rounded shadow-lg border border-blue05 p-4 z-40 bg-white"
        :style="{ width: `${width}px`}"
        role="menu" 
        aria-orientation="vertical"
      >
        <AppLoader v-if="!requestFetched && search && !jurisdictions.length" />
        <div class="w-full flex flex-wrap bg-white">
          <div
            v-if="requestFetched && !jurisdictions.length"
            class="w-full p-3 flex flex-col"
          >
            <h3 class="text-red">
              Hmmmm
            </h3>
            <p class="text-gray04">
              Please check the spelling and try again
            </p>
          </div>
          <ul
            v-else
            class="w-full"
          >
            <li
              v-for="(jurisdiction, index) in jurisdictions"
              :id="`jurisdiction-list-item-${index}`"
              :key="`dropdown-jurisdiction-${jurisdiction.id}`"
              tabindex="0"
              :class="{'bg-gray06' : index == currentSelected}"
              class="w-full p-3 text-base cursor-pointer border-b border-gray06 focus:outline-none"
              @click="setJurisdiction(jurisdiction)"
              @mouseover="currentSelected = index"
            >
              <span class="w-full flex justify-between items-center">
                <h3>{{ jurisdiction.title }}</h3>
                <h3 class="text-gray04 text-sm">{{ jurisdiction.type }}</h3>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { delayExecution } from '@/util/Functions'
import { randomString } from '@/util/Functions'
export default {
  name: 'JurisdictionSelect',
  props: {
    width: {
      default: 244
    },
    focusOnMount: {
      default: false
    },
    shouldScroll: {
      default: false
    },
    inputClass: {
      default: 'text-gray02'
    },
    label: {
      type: String,
      default: 'Type a City or County'
    },
    selectOnlyFirstClimateZone: {
      type: Boolean,
      default: false
    },
    jurisdictionSelected: {
      type: [ Object, Boolean ],
      default: false
    },
    icon: {
      type: String,
      default: ''
    },
    iconClass: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      search: '',
      showDropDown: true,
      jurisdictions: [],
      requestFetched: false,
      cancelToken: null,
      currentSelected: 0,
      isFocused: false,
      randomStringToInputIdAndName: randomString(16)
    }
  },
  computed: {
    getLastJurisdictionVisited() {
      return this.$store.getters['lastJurisdictionVisited']
    },
    placeholder() {
      return this.jurisdictionAsText && !this.isFocused ? this.jurisdictionAsText : this.label
    },
    jurisdictionAsText() {
      return this.jurisdictionSelected?.title_type ?? false
    },
    cssClass() {
      let cssClass = []
      
      if (this.jurisdictionSelected) {
        cssClass.push('is-selected')
      }
      
      if (this.isFocused) {
        cssClass.push('is-focused')
      }
      
      if (this.showDropDown) {
        cssClass.push('is-searching')
      }

      return cssClass
    },
  },
  mounted() {
    if(this.focusOnMount) this.$refs.input.focus()
  },
  methods: {

    async getJurisdictions() {
      this.cancelToken = this.$api.CancelToken.source()
      this.$api.get(`/app/jurisdictions/${this.search}`, {cancelToken: this.cancelToken.token })
        .then(({ data }) => {
          this.jurisdictions = data.jurisdictions
          this.requestFetched = true
          this.cancelToken = null
          this.currentSelected = 0
        })
        .catch( () => {})
    },

    close() {
      this.search = ''
      this.requestFetched = false
      this.$emit('closeInput')
      this.currentSelected = -1
      this.jurisdictions = []
    },
    onBlur() {
      this.isFocused = false
    },
    onFocus() {
      this.isFocused = true
      if(!this.shouldScroll) return
      let bounds = this.$refs.input.getBoundingClientRect()
      const scrollTop = (document.documentElement.clientWidth - bounds['top']) -30
      document.getElementById('main-content').scrollTo({
        top: scrollTop,
        left: 0,
        behavior: 'smooth'
      })
    },

    selectOnEnter() {
      if(this.currentSelected < 0) return
      const jurisdictionSelected = this.jurisdictions[this.currentSelected]
      if(this.jurisdictions.length) this.setJurisdiction(jurisdictionSelected)
    },

    setJurisdiction(jurisdiction) {
      this.$emit('change', jurisdiction)
      this.close()
      this.search = ''
      this.requestFetched = false
    },

    navigate (direction) {
      if(this.jurisdictions.length) {
        if (direction == 'up') {
          if(this.currentSelected > 0) {
            this.currentSelected--
            document.getElementById(`jurisdiction-list-item-${this.currentSelected}`).focus()
          }
        } else {
          if(this.currentSelected < this.jurisdictions.length-1) {
            this.currentSelected++
            document.getElementById(`jurisdiction-list-item-${this.currentSelected}`).focus()
          }
        }
      }
    },

    handleRequest() {
      if (this.cancelToken) {
        this.cancelToken.cancel("Operation canceled due to new request.")
      }
      if(!this.search || this.search == '') {
        this.jurisdictions = []
        return
      }
      this.requestFetched = false
      delayExecution(300, this.getJurisdictions)
    },
    
    

    
  }
}
</script>

<style lang="scss" scoped>
  .icon {
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -12px;
  }
</style>
<style lang="scss">
  .jurisdiction--select {
    input {
      border-bottom: 1.3px solid #798490;
      transition: 300ms ease-in all;
      &::placeholder {
        font-size: inherit;
      }
    }
    &.is-selected input::placeholder {
      color: app-color('blue02');
    }
    &.is-selected.is-focused input::placeholder {
      color: app-color('gray03');
    }
  }

  .input-wrapper {
    position: relative;
    .width-machine {
      opacity: 0;
      display: block;
      min-width: 100px;
      min-height: 29px;
    }
    input {
      position: absolute;
      width: 100%;
      left: 0;
      top: 0;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus,
  input:-webkit-credentials-autofill:focus,
  input:-webkit-credentials-autofill:focus,
  input::-webkit-contacts-auto-fill {
    visibility: hidden !important;
  }
</style>