<template>
  <BaseModal
    ref="baseModal"
    modal-dialog-classes="w-auto bg-ash-100"
    @close="close"
  >
    <div class="info-wrapper text-center mt-4">
      <div class="text-header-5 text-ash-700 mb-6 font-bold">
        We are preparing your measure menu tables
      </div>
      <div class="text-p-2 flex items-center justify-center">
        <Checkbox
          v-model="downloadModelOrdinance"
          size="24"
          checkbox-classes="mb-0"
        >
          <span class="text-ash-700">Also download the model ordinance template</span>
        </Checkbox>
      </div>
      <AppButton
        size="medium"
        class="mt-6"
        label="Continue"
        @click="download()"
      />
    </div>
  </BaseModal>
</template>

<script>

export default {
  name: 'PolicyFlexiblePathDownloadModal',
  data() {
    return {
      downloadModelOrdinance: false
    }
  },
  mounted() {
    this.$eventBus.$on('openModalPolicyFlexiblePathDownload', this.open)
  },
  beforeDestroy() {
    this.$eventBus.$off('openModalPolicyFlexiblePathDownload')
  },
  methods: {
    download() {
      this.$api.get(process.env.VUE_APP_BASE_API_URL + `/api/measure_menu/export/${this.$route.params.policy_id}`, { responseType: 'blob' })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `Flexible_Path_Policy-${this.$route.params.policy_id}.xlsx`)
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        })

      if (this.downloadModelOrdinance) {
        this.$api.get(`/api/download/get_file/${encodeURIComponent('https://localenergycodes.com/download/895/file_path/fieldList/Model%20Ord%20%20-%20SF%20Renovations.docx')}`, { responseType: 'blob'})
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'Model_Ord_SF_Renovations.docx')
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          })
      }
      this.close()
    },
    open() {
      this.$refs.baseModal.showModal = true
    },
    close() {
      this.$refs.baseModal.showModal = false
    }
  }
}
</script>