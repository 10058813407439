<template>
  <div class="text-center">
    <img
      src="/images/tutorial/flexible_path/generating_policy.svg"
      class="mx-auto mb-11"
    >
    <transition
      name="slide-fade"
      mode="out-in"
    >          
      <h3
        :key="getCaptions[captionIndex]"
        class="text-header-4 text-ash-600"
      >
        {{ getCaptions[captionIndex] }}
      </h3>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'StepGeneratePolicyLoading',
  props: ['policy'],
  data: () => ({
    interval: false,
    stepIntervalTime: 3000,
    captionIndex: 0,
  }),
  computed: {
    getCaptions() {
      if(this.policy.is_auto_generated && this.policy.is_flexible_path) return [
        'Calculating measure scores based on energy savings (MMBTU/yr) . . .', 
        'Finding all compatible cost-effective measures and calculating the total potential energy savings (MMBTUs/year) . . .',
        'Converting total potential enery savings (MMBTUs/year) into maximum cost-effective target scores . . .',
        'Generating Measure Menu Tables . . .',
      ]
      if(this.policy.is_auto_generated) return [
        'Getting study results . . .', 
        'Finding cost-effective measures . . .',
        'Checking for measure compatibility . . .'
      ]
      return ['']
    },
  },
  mounted() {
    this.start()
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  methods: {
    start() {
      this.interval = setInterval(() => {
        if ((this.captionIndex === this.getCaptions.length - 1)) {
          setTimeout(() => { 
            this.$emit('nextStep')
          }, this.stepIntervalTime)
          clearInterval(this.interval)          
        } else {
          this.captionIndex++
        }
      }, this.stepIntervalTime)
    }
  }
}
</script>