import state from './state'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import moduleBuildingStocks from '@/modules/app/assumptions/vuex/stocks'
import moduleBuildingEstimates from '@/modules/app/assumptions/vuex/estimates'

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
  modules: {
    buildingStocks: moduleBuildingStocks,
    buildingEstimates: moduleBuildingEstimates,
  }
}