<template>
  <div
    id="sidebar-container"
    :style="{ width: sidebarWith }"
    class="bg-pri h-screen flex items-start z-50"
  >
    <div class="flex flex-col h-full">
      <div class="w-full p-4 flex items-center justify-end flex absolute lg:hidden">
        <button
          v-if="sideMenuIsOpen"
          aria-label="Close side menu"
          class="cursor-pointer focus:outline-none flex text-blue02 md:hidden"
          @click="close()"
        >
          <feather type="x" />
        </button>
        <!-- <button
          v-else
          @click="open()"
          aria-label="open side menu"
          class="cursor-pointer focus:outline-none text-blue02 md:hidden"
        >
          <feather type="menu"></feather>
        </button> -->
      </div>

      <div class="h-full w-full p-4 max-w-full max-h-full overflow-y-auto flex hide-scrollbar">
        <div :is="getSidebarContent" />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'NewAppSideBar',
  props: {
    sidebarWith: String,
    sidebar: Object
  },
  data() {
    return {
      sideMenuIsOpen: false,
      windowWidth: 0,
    }
  },
  computed: {    
    showSideMenu() {
      return (this.windowWidth > 768  || this.windowWidth < 768 && this.sideMenuIsOpen) ? true : false
    },
    getSidebarContent() {
      return this.sidebar?.content ?? false
    },
  },
  mounted() {
    this.windowWidth = window.innerWidth
    this.$eventBus.$on('openSideMenu', ({ open }) => {
      this.sideMenuIsOpen = open
    })
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('openSideMenu')
  },
  methods: {
    close() {
      this.sideMenuIsOpen = false
    },
    open() {
      this.sideMenuIsOpen = true
    }
  }
}
</script>

<style scoped lang="scss">
  #sidebar-container {
    top: 0;
    position: absolute;
  }
</style>