<template>
  <div class="w-full p-6">
    <div class="w-full flex">
      <div class="w-full relative mr-3">
        <VueSelect 
          v-model="getTypeSelected"
          class="vue-select"
          placeholder="Select a type"
          :class="getTypeSelected ? 'filled' : ''"
          label="label"
          :options="types"
          :reduce="item => item.value"
        />
        <label>Select a type</label>
      </div>
      <div class="w-full relative ml-3">          
        <VueSelect 
          v-model="getStudySelected"
          class="vue-select"
          placeholder="Filter by study"
          :class="getStudySelected ? 'filled' : ''"
          label="title"
          :options="studies"
          :reduce="item => item.id"
        >
          <template v-slot:option="option">               
            <span class="text-deepsky-500 mr-2">{{ option.released_at }} - </span>
            <span>{{ option.title }}</span>
          </template>
          <template #selected-option="option">          
            <span class="text-deepsky-500 mr-2">{{ option.released_at }} - </span>
            {{ option.title }}
          </template>
        </VueSelect>
        <label>Select a study to filter</label>
      </div>
    </div>

    <AdminStudyItemTable
      class="mt-6"
      :type="getTypeSelected"
      :title="'Edit type'"
      :items="items"
      :show-study-column="true"
      @getItems="getItems"
    />
  </div>
</template>

<script>
import AdminStudyItemTable from '@/modules/admin/study_item/AdminStudyItemTable'
export default {
  name: 'AdminStudyItemList',
  components: { AdminStudyItemTable },
  data() {
    return {
      search: '',
      types: [
        { label: 'Prototype', value: 'prototype' },
        { label: 'Vintage', value: 'vintage' },
        { label: 'Measure', value: 'measure' },
        { label: 'Fuel', value: 'fuel' },
      ],
      items: [],
      studies: [],
    }
  },
  computed: {
    getTypeSelected: {
      get() {
        return this.$route.params.type ? this.$route.params.type : 'measure'
      },
      set(value) {
        this.$router.push({ ... this.$route, params: { ...this.$route.params, type: value }})
        this.getItems()
      }
    },
    getStudySelected: {
      get() {
        return this.$route.params.study_id ? parseInt(this.$route.params.study_id) : null
      },
      set(value) {
        this.$router.push({ ... this.$route, params: { type: this.getTypeSelected, study_id: value }})
        this.getItems()
      }
    }
  },
  mounted() {
    this.getItems()
    this.getStudies()
    this.$eventBus.$on('dragAndDropOrdenationUpdated', this.getItems)
  },
  beforeDestroy() {
    this.$eventBus.$off('dragAndDropOrdenationUpdated')
  },
  methods: {
    async getItems() {
      const query = `
        {
          ${this.getTypeSelected}s (
            orderBy: [{column: "order", order: "asc"}],
            ${this.getStudySelected ? `where: [ { column: "study_id", operation: "=", value: "${this.getStudySelected}" } ]` : ''}
          ){
            order
            id
            title
            slug
            ${
              this.getTypeSelected == 'measure' ? 
                'is_package type_measures { id title }' :
                `type_${this.getTypeSelected} { id title }`
            }
            study {
              title
              released_at
            }
          }
        }
      `
      const { data } = await this.$graphql({ query })

      this.items = data[`${this.getTypeSelected}s`]
    },

    getStudies() {
      const query = `
        {
          studies (
            orderBy: [
              { column: "released_at", order: "DESC" }
            ]
          ) {
            id
            title
            released_at
          }
        }
      `
      this.$graphql({ query })
        .then(({ data }) => {
          this.studies = data.studies
        })
    },

    deleteItem(type_filter_id) {
      this.$api.delete(`/types/${this.getTypeSelected}/${type_filter_id}`)
        .then(() => {
          this.$toast.success('Filter successfully deleted')
          this.getItems()
        })
        .catch(() => {
          this.$toast.error('Error deleting filter')
        })
    }
  }
}
</script>