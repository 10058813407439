import CustomBuildingEstimate from '@/models/CustomBuildingEstimate'
import DataAndObjectFunctions from '@/util/DataAndObjectFunctions'

export default {

  /**
   * Old one: getterJurisdictionBuildingEstimates
   */
  getterDefaultJurisdictionBuildingEstimates: (state, getters, rootState, rootGetters) => (filters = null) => {
    const getterFilters = {
      ...DataAndObjectFunctions.filterObjectKeys(CustomBuildingEstimate.filtrableKeys, filters),
      jurisdiction_id : rootGetters['lastJurisdictionVisited']?.id ?? null,
    }
    // console.log(state, getterFilters, state.jurisdiction_building_estimates.findAllInArray(getterFilters))
    return state.jurisdiction_building_estimates.findAllInArray(getterFilters)
  },

  /**
   * old one: getterJurisdictionBuildingEstimateUnits
   */
  getterDefaultJurisdictionBuildingEstimateUnits: (state, getters) => (filters = null) => {
    getters['getterDefaultJurisdictionBuildingEstimates'](filters)
        .reduce((acc, building_estimate) => {
          console.log('Estimate units: ', building_estimate, building_estimate.units)
          return acc + building_estimate.units
        }, 0)

    return getters['getterDefaultJurisdictionBuildingEstimates'](filters)
        .reduce((acc, building_estimate) => acc + building_estimate.units, 0)
  },

  /**
   * Old one: NO ONE
   */
  getterCustomJurisdictionBuildingEstimates: (state, getters, rootState, rootGetters) => (filters = null) => {
    const getterFilters = {
      ...DataAndObjectFunctions.filterObjectKeys(CustomBuildingEstimate.filtrableKeys, filters),
      jurisdiction_id : rootGetters['lastJurisdictionVisited']?.id ?? null,
      policy_id : rootState.assumptions.policy_id_selected ?? null,
    }
    // console.log('custom jurisdiction', state, state.custom_building_estimates, state.custom_building_estimates.findAllInArray(getterFilters))
    return state.custom_building_estimates.findAllInArray(getterFilters)
  },

  /**
   * Old one: NO ONE
   */
  getterCustomJurisdictionBuildingEstimateUnits: (state, getters) => (filters = null) => {
    getters['getterCustomJurisdictionBuildingEstimates'](filters)
        .reduce((acc, building_estimate) => {
          console.log('Custom Estimate units: ', building_estimate, building_estimate.units)
          return acc + building_estimate.units
        }, 0)

    return getters['getterCustomJurisdictionBuildingEstimates'](filters)
        .reduce((acc, building_estimate) => acc + building_estimate.units, 0)
  },

  /**
   * Old one: getterFilteredBuildingEstimates
   */
  getterAllBuildingEstimates: (state, getters) => (filters = null) => {
    let jurisdictionBuildingEstimate = []

    const defaultJurisdictionBuildingEstimates = getters['getterDefaultJurisdictionBuildingEstimates'](filters)
    const customJurisdictionBuildingEstimates = getters['getterCustomJurisdictionBuildingEstimates'](filters)

    if(defaultJurisdictionBuildingEstimates.length) {
      for(const defaultJurisdictionBuildingEstimate of defaultJurisdictionBuildingEstimates) {

        const customBuildingEstimate = customJurisdictionBuildingEstimates.findInArray({
          climate_zone_prefix: defaultJurisdictionBuildingEstimate.climate_zone_prefix,
          type_prototype_id: defaultJurisdictionBuildingEstimate.type_prototype_id,
          year: defaultJurisdictionBuildingEstimate.year,
          construction_implementation_type_id: defaultJurisdictionBuildingEstimate.construction_implementation_type_id,
          building_height_id: defaultJurisdictionBuildingEstimate.building_height_id,
        })

        if (customBuildingEstimate) {
          jurisdictionBuildingEstimate.push(customBuildingEstimate)
        } else {
          jurisdictionBuildingEstimate.push(defaultJurisdictionBuildingEstimate)
        }
      }
    } else {
      jurisdictionBuildingEstimate = customJurisdictionBuildingEstimates
    }

    return [...jurisdictionBuildingEstimate ]

  },

  /**
   * Old one: getterFilteredBuildingEstimatesUnits
   */
  getterAllBuildingEstimateUnits: (state, getters) => (filters = null) => {
    const jurisdictionBuildingEstimate = getters['getterAllBuildingEstimates'](filters)
    return jurisdictionBuildingEstimate.length > 0 ? jurisdictionBuildingEstimate.reduce((acc, building_estimate) => acc + building_estimate.units, 0) : null
  },

  /**
   * Old one: NO ONE
   */
  getterCustomBuildingEstimateByPolicyId: (state) => (policy_id) => {
    return state.custom_building_estimates.findAllInArray({ policy_id })
  }
}
