<template>
  <div class="w-full flex items-center justify-between">
    <a
      class="logo"
      href="https://localenergycodes.com"
      target="_blank"
      title="Statewide Reach Codes Program"
    >
      <img
        v-if="background === 'dark'"
        src="/logo/logo-state-program-dark-background.png"
        alt="California Energy Codes & Standards Logo"
      >
      <img
        v-else
        src="/logo/logo-state-program-light-background.png"
        alt="California Energy Codes & Standards Logo"
      >
      
    </a>
    <div class="w-1/5 flex justify-center">
      <span class="vertical-divider h-12 bg-sec" />
    </div>
    <p class="text-left text-xs psui-text-white">
      Find more reach code resources at 
      <a
        href="https://localenergycodes.com"
        class="psui-text-blue-60 hover:underline"
        target="_blank"
        title="Statewide Reach Codes Program"
      >LocalEnergyCodes.com</a>
    </p>
  </div>
</template>

<script>
export default {
  name: 'LocalEnergyLogo',
  props: {
    background: {
      type: String,
      default: 'dark',
      validator: (value) => ['dark', 'light'].indexOf(value) !== -1
    } 
  },
}
</script>

<style lang="scss">
  .logo {
    max-width: 120px;
  }
</style>