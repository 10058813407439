<template>
  <div class="w-full h-full flex flex-col overflow-y-auto">
    <div class="w-full px-3 md:pl-12">
      <UserAccountHeader class="pb-6 pr-6" />

      <div class="w-full">
        <h2 class="text-header-4 font-bold text-deepsky-900">
          Profile
        </h2>
      </div>
      <div class="max-w-1/2 mt-6">
        <div class="w-full">
          <InputWithLabel
            v-model="user.email"
            :label="'Email'"
            :validation="validation.email"
            @input="checkEmail(true)"
            @blur="checkEmail(false)"
          />

          <div
            v-if="user.email != $store.getters.getterLoggedUser.email"
            class="w-full flex mt-2"
          >
            <button
              :disabled="!validation.email.updated" 
              class="button-sm mt-2 rounded-full bg-sec text-white shadow-sm transition duration-300"
              @click="updateUser({ email: user.email })"
            >
              Change email
            </button>
            <button 
              class="button-sm rounded-full bg-white text-blue04 shadow-sm ml-3"
              @click="user.email = $store.getters.getterLoggedUser.email"
            >
              Cancel
            </button>
          </div>
        </div>

        <button 
          v-if="!validation.password.updated"
          class="button-sm mt-2 rounded-full bg-sec text-white shadow-sm mt-6"
          @click="validation.password.updated = true"
        >
          Change password
        </button>
        <div
          v-else
          class="w-full mt-8 "
        >
          <InputWithLabel
            v-model="user.password"
            type="password"
            :label="'Password'"
          />

          <div class="w-full mt-3">
            <p
              v-if="user.password.length >= 8"
              class="italic text-header-2 text-gray03 flex items-center"
            >
              Your password must contain 8 characters <feather
                type="check"
                size="14"
              />
            </p>
            <p
              v-else
              class="italic text-header-2 text-red flex items-center"
            >
              Your password must contain 8 characters <feather
                type="x"
                size="14"
              />
            </p>
          </div>

          <InputWithLabel
            v-model="user.password_confirmation"
            type="password"
            class="mt-6"
            :label="'Confirm Password'"
          />
          <div
            class="w-full mt-3"
            :class="!user.password_confirmation ? 'invisible' : ''"
          >
            <p
              v-if="user.password == user.password_confirmation"
              class="italic text-header-2 text-gray03 flex items-center"
            >
              Confirm your password <feather
                type="check"
                size="14"
              />
            </p>
            <p
              v-else
              class="italic text-header-2 text-red flex items-center"
            >
              Confirm your password <feather
                type="x"
                size="14"
              />
            </p>
          </div>
          <div class="w-full flex mt-2">
            <button 
              class="button-sm rounded-full bg-sec text-white shadow-sm"
              @click="updatePassword()"
            >
              Save password
            </button>
            <button 
              class="button-sm rounded-full bg-white text-blue04 shadow-sm ml-3"
              @click="validation.password.updated = false"
            >
              Cancel
            </button>
          </div>
        </div>

        <div class="max-w-sm mt-5 mb-12 flex flex-wrap  transition duration-300 relative">
          <Toggle 
            label="I would like to receive emails about new features and other updates about the Cost Effectiveness Explorer Tool." 
            label-class="ts--accent--2 pl-1 text-gray04"
            :active.sync="user.accept_features_newsletter"
            @change="updateUser({ accept_features_newsletter: user.accept_features_newsletter })"
          />
          <br>
          <Toggle 
            label="I would like to receive the LocalEnergyCodes.com monthly newsletter and periodic announcements." 
            label-class="ts--accent--2 pl-1 text-gray04"
            :active.sync="user.accept_periodic_newsletter"
            @change="updateUser({ accept_periodic_newsletter: user.accept_periodic_newsletter })" 
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserAccountHeader from './UserAccountHeader.vue'
import User from '@/models/User'
import { emailValidator } from '@/util/Validators'
export default {
  name: 'UserAccountIndex',
  components: { UserAccountHeader },
  data() {
    return {
      user: new User(),
      validation: {
        email: { hasError: false, label: '', updated: false, timeout: null },
        password: { hasError: false, label: '', updated: false },
        password_confirmation: { hasError: false, label: '', updated: false },
      }
    }
  },
  mounted() {
    this.setUserToEdit()
  },
  methods: {
    setUserToEdit() {
      this.user = new User(this.$store.getters.getterLoggedUser)
    },

    checkEmail(timeout = false) {

      if(this.user.email == this.$store.getters.getterLoggedUser.email) {
        this.validationClear()
        return
      }

      if(timeout) {
        if(this.validation.email.timeout) {
          return
        } else {
          this.validation.email.timeout = setTimeout(() => {
            this.validation.email.timeout = null
          }, 200)
        }
      }

      if(!this.user.email || !emailValidator(this.user.email)) {
        this.validation.email.hasError = true
        this.validation.email.updated = false
        this.validation.email.label = 'Please, fill a valid email'
        return
      }

      this.$api.get(`/users/checkEmail/${this.user.email}`)
      .then(({ data }) => {
        const { user_finded } = data

        if(user_finded) {
          this.validation.email.hasError = true
          this.validation.email.label = 'This email was taken'
          this.validation.email.updated = false
        } else {
          this.validation.email.hasError = false
          this.validation.email.label = ''
          this.validation.email.updated = true
        }

      })

    },

    updatePassword() {
      if(!this.user.password) {
        this.validation.password.hasError = true
        this.validation.password.label = 'Please insert a new password'
        return
      }
      if(this.user.password != this.user.password_confirmation) {
        this.validation.password_confirmation.hasError = true
        this.validation.password_confirmation.label = 'Confirmation doesnt match'
        return
      }

      this.updateUser({ password: this.user.password })

    },

    updateUser(payload) {
      this.$api.post(`/api/users/update/${this.user.id}`, payload)
        .then(() => {
          this.$toast.success('Changes saved')
          this.$store.dispatch('getLoggedUser')
            .then(() => {
              this.setUserToEdit()
              this.validationClear()
            })
        })
    }
  }
}
</script>

<style>

</style>