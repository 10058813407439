<template>
  <ul>
    <li 
      class="border-b border-deepsky-200 px-6 transition-all text-deepsky-900 hover:text-deepsky-500 cursor-pointer" 
      @click="openUrl('https://localenergycodes.com/content/map')"
    >
      <h2 class="text-header-6 font-bold py-8 text-current">
        See Map of Ordinances Adopted
      </h2>
    </li>

    <li 
      class="border-b border-deepsky-200 px-6 transition-all text-deepsky-900 hover:text-deepsky-500 cursor-pointer" 
      @click="openUrl('https://localenergycodes.com/content/contact-us')"
    >
      <h2 class="text-header-6 font-bold py-8 text-current">
        Ask the Reach Code Team
      </h2>
    </li>

    <li 
      class="border-b border-deepsky-200 px-6 transition-all text-deepsky-900 hover:text-deepsky-500 cursor-pointer" 
      @click="openUpcomingFeatures()"
    >
      <h2 class="pt-8 text-header-6 font-bold text-current">
        Upcoming Features
      </h2>
      <p class="text-header-4 text-ash-500 pt-4 pb-8">
        What we are building for you
      </p>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'SidebarMenuNextSteps',
  methods: {
    openUpcomingFeatures() {
      this.$eventBus.$emit('openUpcomingFeaturesDrawer')
    },
    openUrl(url) {
      window.open(url)
    }
  }
}
</script>