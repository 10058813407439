import DeviceInfo from "@/models/DeviceInfo"

export default {
  vuexVersion: '2.0.1',
  loaderShow: false,
  contentContainerWidth: 1280,
  sidebar: {
    width : 610,
    menu: false,
    mode: 'large'
  },
  filtersToWaitForResults: ['jurisdiction', 'climateZone', 'study', 'prototype', 'vintage', 'fuel', 'package'],
  sidebarPolicyTooltipShouldHide: false,
  redirectTo: '',
  redirectToEventToDispatchAfter: '',
  deviceInfo: new DeviceInfo(),
  qaHotKeyPressed: false,
  componentStructureTracing: false
}
