<template>
  <div class="block w-full">
    <div class="relative">
      <textarea
        :id="id"
        :autocapitalize="autocapitalize"
        :class="{'input-has-error' : validation.hasError, 'filled' : value}"
        :aria-required="required"
        :aria-invalid="validation.hasError"
        :value="value"
        :rows="rows"
        class="block appearance-none w-full h-full border-b-2 border-gray06 bg-gray06 text-gray04 text-base px-3 pb-2 pt-6 font-normal"
        @blur="$emit('blur')"
        @input="$emit('input', $event.target.value)"
      />
      <label
        v-if="label"
        :for="id"
        class="h-full absolute pin-l top-0 flex items-center text-gray04 text-xs pointer-events-none pl-3"
      >{{ label }}</label>
    </div>
    <p
      v-if="validation.hasError && validation.label"
      class="text-red w-full pl-3"
    >
      {{ validation.label }}
    </p>
  </div>
</template>

<script>
export default {
  name: "InputWithIcon",
  props: {
    id: {
      type: String,
      required: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    value: {
      required: true,
    },
    label: {
      type: String,
      required: true
    },
    action: {
      type: Function,
      required: false
    },
    autocapitalize: {
      type: String,
      default: 'sentences'
    },
    autocomplete: {
      type: Boolean,
      default: true
    },
    required: {
      default: false
    },
    validation: {
      type: Object,
      default: () => {
        return { hasError: false, checked: false, filled: false, required: false }
      }
    },
    rows: {
      type: Number,
      default: 5
    }
  },
  methods: {
    makeAction() {
      if(this.action && typeof(this.action) == 'function') this.action()
    }
  }
}
</script>

<style>

  textarea + label {
    transition: flex 0.4s ease-out;
  }
  textarea:focus + label {
    color: #4B8CA6 !important;
  }
  textarea:focus + label, textarea.filled + label {
    align-items: flex-start;
    margin-top: 6px;
  }

  textarea:focus:not(.input-has-error) {
    border-color: #4B8CA6 !important;
  }

  textarea.input-has-error + label {
    color: #ff0f4a !important;
  }

  .input-has-error {
    border-color: #ff0f4a !important;
  }
</style>
