<template>
  <div>
    <div class="p-6">
      <h1 class="text-header-5 font-bold text-deepsky-900">
        {{ baseModel.title }}
      </h1>
    </div>

    <AdminStudyItemMeasureForm v-if="$route.params.type === 'measure'" />
    <div
      v-else
      class="w-full p-6 mb-20"
    >
      <div
        v-if="$route.params.type != 'measure'"
        class="w-full relative"
      >
        <VueSelect 
          v-model="baseModel[`type_${$route.params.type}_id`]"
          class="vue-select"
          placeholder="Select a filter to group searchs"
          :class="baseModel[`type_${$route.params.type}_id`] ? 'filled' : ''"
          label="title"
          :options="types"
          :reduce="item => item.id"
        />
        <label>Filter group</label>
      </div>

      <InputWithLabel
        v-model="baseModel.title"
        class="mt-6"
        :label="'Title'"
      />

      <InputWithLabel
        v-model="baseModel.slug"
        class="mt-6"
        :label="'Slug (external id)'"
      />

      <div
        v-if="$route.params.type == 'prototype'"
        class="w-full flex flex-col"
      >
        <InputWithLabel
          v-model="baseModel.title_abbreviated"
          class="mt-6"
          :label="'Abbreviated title'"
        />

        <p class="text-gray mt-3">
          Icon
        </p>
        <div class="w-full flex mt-2 mx-2">
          <button 
            v-for="icon in prototypesIconsAvailable" 
            :key="icon" 
            class="mr-4"
            :class="[icon == baseModel.icon ? 'shadow' : 'opacity-50']"
            @click="baseModel.icon = icon"
          >
            <img
              class="h-12"
              :src="`/prototypes/${icon}`"
              alt=""
            >
          </button>
        </div>

        <div class="w-full flex items-end space-x-2">
          <div class="relative mt-6 w-full">
            <VueSelect 
              v-model="typePrototypeSelected"
              class="vue-select filled"
              placeholder="Select at least one Type Prototype"
              label="title"
              :options="getTypePrototypesFiltered"
            />
            <label>Add proportional Type Prototypes</label>
          </div>
          <PsButton  
            label="Add" 
            size="medium"
            icon="add"
            @click="addTypePrototype()"
          />
        </div>

        <AppAlert
          v-if="!baseModel.proportional_type_prototypes.length"
          class="mt-6"
          :description="`No building estimate prototypes attached to the ${baseModel.title}`"
        />

        <div class="w-full flex flex-col">
          <div 
            v-for="(type_prototype, type_prototype_index) in baseModel.proportional_type_prototypes" 
            :key="`prototype_${type_prototype_index}`" 
            class="w-full flex space-x-2 items-end"
          >
            <div class="w-full relative bg-ash-100 cursor-not-allowed">
              <label class="absolute pin-l flex items-center text-gray04 text-xs pointer-events-none pl-3">Building Estimate Prototype</label>
              <div class="input-with-label block appearance-none w-full h-full text-header-3 px-3 pb-2 pt-6 font-normal">
                <span>{{ type_prototype.title }}</span>
              </div>
            </div>

            <div class="w-1/2 flex items-end space-x-2">
              <NumberFormatInput
                v-model="type_prototype.pivot.percentage"
                class="mt-6"
                label="Percentage"
                :options="{ precision: 2, prefix: '', suffix: '', decimal: '.', thousand: '', acceptNegative: false, isInteger: false }"
              />
              <PsButton 
                layout="caution"
                size="medium" 
                label="Remove" 
                icon="delete"
                theme="bg-red border-transparent rounded-full text-white"
                @click="baseModel.proportional_type_prototypes.splice(type_prototype_index, 1)"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="$route.params.type == 'vintage'"
        class="w-full"
      >
        <InputWithLabel
          v-model="baseModel.title_long"
          class="mt-6"
          :label="'Title Long'"
        />
      </div>
      
      <ContentEditor
        label="Short Description"
        class="mt-6"
        :content.sync="baseModel.description"
      />
      
      <ContentEditor
        label="Description"
        class="mt-6"
        :content.sync="baseModel.content"
      />

      <hr class="border-b border-gray05 my-6">

      <button
        class="mt-6 button button-blue04"
        @click="update()"
      >
        Update {{ $route.params.type }}
      </button>
    </div>
  </div>  
</template>

<script>
import AdminStudyItemMeasureForm from './AdminStudyItemMeasureForm.vue'
export default {
  name: 'AdminStudyItemForm',
  components: { AdminStudyItemMeasureForm },
  data() {
    return {
      baseModel: {
        id: '',
        study_id: '',
        title: '',
        title_long: '',
        title_abbreviated: '',
        slug: '',
        description: '',
        content: '',
        icon: '',
        include_in_combination: '',
        exclude_measures: '',
        exclude_in_combination_reason: '',
        type_prototype_id: '',
        type_vintage_id: '',
        type_measure_id: '',
        type_fuel_id: '',
        order: 999,
        hide_in_flexible_path : false,
        hide_in_results : false,
        flexible_path_title : '',
        proportional_type_prototypes: []
      },
      types: [],
      measures: [],
      type_measures: [],
      prototypesIconsAvailable: ['low-rise-residential.svg', 'high-rise-residential.svg'],
      type_prototypes: [],
      typePrototypeSelected: null
    }
  },
  computed: {
    getTypePrototypesFiltered() {
      return this.type_prototypes
        .filter((item) => !this.baseModel.proportional_type_prototypes.map(i => i.id).includes(item.id))
    }
  },
  mounted() {
    this.getBaseModel()
    this.getTypes()

    if(this.$route.params.type == 'measure') this.getMeasuresAndTypes()
    if(this.$route.params.type == 'prototype') this.getTypePrototypes()
  },
  methods: {
    async getBaseModel() {
      const query = `
        {
          ${this.$route.params.type} (
            where: [
              { column: "id", operation: "=", value: "${this.$route.params.study_item_id}"}
            ]
          ){
            id
            study_id
            title
            slug
            description
            content
            order
            type_${this.$route.params.type}_id
            ${this.$route.params.type == 'prototype' ? 'icon title_abbreviated proportional_type_prototypes { id title pivot { prototype_id type_prototype_id percentage } }' : ''}
            ${this.$route.params.type == 'measure' ? 'include_in_combination exclude_measures exclude_in_combination_reason hide_in_flexible_path hide_in_results flexible_path_title' : ''}
            ${this.$route.params.type == 'vintage' ? 'title_long' : ''}

            ${
              this.$route.params.type == 'measure' ? 
                'type_measures { id title }' :
                `type_${this.$route.params.type} { id title }`
            }
          }
        }
      `
      const { data } = await this.$graphql({ query })

      this.baseModel = data[this.$route.params.type]
    },

    getMeasuresAndTypes() {
      const query = `
        {
          measure(where: [{column: "id", operation: "=", value: "${this.$route.params.study_item_id}"}]) {
            study {
              measures (
                orderBy: [{column: "title", order: "ASC" }]
              ) {
                id
                title
              }
            }
          }
        }
      `
      this.$graphql({ query })
        .then(({ data }) => {
          this.measures = data.measure.study.measures
          this.type_measures = data.type_measures
        })
    },

    getTypePrototypes() {
      const query = `
        {
          type_prototypes {
            id
            title
          }
        }
      `
      this.$graphql({ query })
        .then(({ data }) => {
          this.type_prototypes = data.type_prototypes
        })
    },

    async getTypes() {      
      const query = `
        {
          type_${this.$route.params.type}s{
            id
            title
          }
        }
      `
      const { data } = await this.$graphql({ query })
      this.types = data[`type_${this.$route.params.type}s`]

    },

    update() {
      this.$api.put(`/studies/${this.baseModel.study_id}/${this.$route.params.type}/${this.$route.params.study_item_id}`, this.baseModel, { loader: true })
        .then(() => {
          this.$toast.success('Item updated successfully')
          this.$router.go(-1)
        })
    },

    addTypePrototype() {
      const typePrototype = {
        ...this.typePrototypeSelected,
        pivot: {
          percentage: 100
        }
      }
      this.baseModel.proportional_type_prototypes.push(typePrototype)
    }
  }
}
</script>

<style lang="scss">
  .vs__dropdown-menu {
    border-bottom: 1px solid gray !important;
    border-left: 1px solid gray !important;
    border-right: 1px solid gray !important;
    // box-shadow: 1px 1px 1px 1px gray;
  }
</style>