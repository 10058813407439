<template>
  <BaseModal
    ref="baseModal"
    modal-dialog-classes="max-w-md"
  >
    <div
      v-if="studyGroup"
      class="w-full w-full"
    >
      <h4 class="w-full text-header-6 font-bold text-deepsky-900 text-left font-normal">
        {{ studyGroup.title }} Study Version History
      </h4>
      
      <div class="timeline mt-10">
        <div 
          v-for="study in studyGroup.studies"
          :key="study.id"
          class="
            timeline-item w-auto flex items-center ml-6 mr-14 mb-4 p-8 border rounded flex-wrap last:mb-0
            text-ash-450 border-dashed border-ash-300 bg-ash-100
            first:border-solid first:bg-deepsky-100 first:border-deepsky-450 first:text-deepsky-500
          "
        >
          <div>
            <h3 class="text-p-3 font-bold">
              {{ study.released_at_formatted_version }}
            </h3>
            <h4 class="text-header-2 font-bold">
              {{ study.title_by_prototypes }}
            </h4>
          </div>
          <div class="ml-auto flex-shrink-0 text-center">
            <AppButton 
              v-if="!study.published_at && study.source_pdf" 
              label="Download PDF"
            />
            <h6 
              v-if="study.version_updated_changes"
              class="text-header-1 font-bold mt-2 text-inherit cursor-pointer transition-all hover:opacity-80"
              :class="{ 'text-ash-450' : study.showChanges, 'mr-11' : study.published_at }"
              @click="$set(study, 'showChanges', !study.showChanges)"
            >
              {{ !study.showChanges ? 'Changes in this version' : 'Hide version changes' }}
            </h6>
          </div>
            
          <transition
            name="fade"
            mode="out-in"
          >
            <div 
              v-if="study.showChanges"
              class="w-full flex-shrink-0 border-t-2 border-ash-200 mt-4"
            >
              <h5 class="text-header-2 text-ash-600 font-bold mt-4">
                What's new in this version:
              </h5>
              <RenderHtmlExtended  
                class="app-drawer-content-styles mt-3"
                :html="study.version_updated_changes"
              />
            </div>
          </transition>
        </div>
      </div>
    </div>
  </BaseModal>
</template>

<script>
import RenderHtmlExtended from '@/components/general/RenderHtmlExtended.vue'
import Study from '@/models/Study'

export default {
  name: 'StudyGroupVersionsTimelineModal',
  components: { RenderHtmlExtended },
  data: () => ({
    studyGroup: false,
    showChanges: false
  }),
  mounted() {    
    this.$eventBus.$on('openStudyGroupVersionsTimelineModal', ({ studyGroupId }) => {
      this.getStudyGroup(studyGroupId)
      this.showChanges = false
      this.$refs.baseModal.open()
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('openStudyGroupVersionsTimelineModal')
  },
  methods: {
    openPdf() {
      window.open(this.study.source_pdf_update)
    },

    async getStudyGroup(studyGroupId) {

      const isPrivate = this.$store.getters.getterLoggedUser && this.$store.getters.getterLoggedUserType == 'admin'
      const isPrivateQuery  = isPrivate ? `(orderBy: [{column: "released_at", order: "desc"}])` : `(orderBy: [{column: "released_at", order: "desc"}], where: [{ column: "is_private", operation: "=", value: "false" }])`

      const query = `
        {
          study_group (
            where: [
              { column: "id", operation: "=", value: "${studyGroupId}"},
            ]
          ) {
            id
            title
            order
            studies ${isPrivateQuery} {
              id
              title
              released_at
              published_at
              source_pdf
              version_updated_changes
              custom_fields {
                item_id
                data
              }
            }
          }
        }
      `
      const { data } = await this.$graphql({ query })
      if (data.study_group) {            
        data.study_group.studies = data.study_group.studies.map(study => new Study(study))
        this.studyGroup = data.study_group
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .timeline-item {
    position: relative;
    h3:before {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      box-sizing: content-box;
      background-color: #D4D8D9;
      border-width: 4px;
      border-color: white;    
      border-radius: 50%;
      left: -34px;
      margin-top: 5px;
      z-index: 1;
    }
    &:after {
      content: "";
      position: absolute;
      width: 2px;
      height: calc(100% + 18px);
      background-color: #D4D8D9;      
      left: -26px;
      top: 0;
    }

    &:last-of-type {
      &:after {
        height: 100%;
      }
    }
    &:first-of-type {
      &:after {
        height: calc(100% - 60px);
        top: 60px;
      }
      h3:before {
        border-color: #69A7BF;
        background-color: #69A7BF;
      }
      + div::after {
        top: -18px;
        height: calc(100% + 36px);
      }
    } // :first-of-type
  }
  .timeline-item ::v-deep {    
    .app-drawer-content-styles * {
      color: #81878A;
    }
  }
</style>