<template>
  <div class="w-full flex flex-col">
    <button v-if="policies === null" />
    
    <div
      v-else-if="!policies.length"
      class="w-full flex justify-center"
    >
      <div class="max-w-lg flex items-center mt-10 p-2">
        <div class="w-1/2 flex flex-col items-start">
          <h1 class="text-lg text-bluebrand font-bold">
            Compare multiple policies to find the best option for your city/county.
          </h1>
          <AppButton
            class="mt-6"
            label="Add Policies to Compare"
            size="big"
            @click="$eventBus.$emit('openComparisonPoliciesSelectDrawer')"
          />
        </div>
        <div class="w-1/2 p-2">
          <img
            src="/images/impact-comparison.jpg"
            alt=""
          >
        </div>
      </div>
    </div>
    <div
      v-else-if="policies.length > 0"
      class="w-full flex flex-col"
    >
      <div class="w-full mt-5 whitespace-no-wrap">
        <div 
          ref="table" 
          class="comparison-table text-base w-full max-w-full flex psui-overflow-x-auto psui-scrolling-auto" 
        >
          <ComparisonTableGroupFixedColumn 
            :policies="policies"
            :items-row-height="itemsRowHeight"
            @toggle-policy="$emit('toggle-policy', $event)"
            @update-policy-order="$emit('update-policy-order', $event)"
          />
          <template v-for="columnGroup in existingColumnGroup.columnGroups">
            <ComparisonTableGroupAssumptions
              v-if="['different_assumptions', 'shared_assumptions'].includes(columnGroup.key)"
              :key="`comparison-column-${columnGroup.key}`"
              :policies="policies"
              :column-group-key="columnGroup.key"
              :items-row-height="itemsRowHeight"
              @checkScopeRowHeight="checkScopeRowHeight"
            />
            <ComparisonTableGroupScope
              v-else-if="columnGroup.key == 'scope'"
              :key="`comparison-column-${columnGroup.key}`"
              :policies="policies"
              :items-row-height="itemsRowHeight"
              @checkScopeRowHeight="checkScopeRowHeight"
            />
            <ComparisonTableGroupStudyData
              v-else-if="['cost_effectiveness', 'city_wide_estimates'].includes(columnGroup.key)"
              :key="`comparison-column-${columnGroup.key}`"
              :policies="policies"
              :items-row-height="itemsRowHeight"
              :column-group="columnGroup"
              @checkScopeRowHeight="checkScopeRowHeight"
            />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ComparisonTableGroupScope from './ComparisonTableGroupScope.vue'
import ComparisonTableGroupAssumptions from './ComparisonTableGroupAssumptions.vue'
import ComparisonTableGroupFixedColumn from './ComparisonTableGroupFixedColumn'
import ComparisonTableGroupStudyData from './ComparisonTableGroupStudyData'
import { handleTableScroll } from '@/util/Functions'
export default {
  name: 'ComparisonTable',  
  components: { 
    ComparisonTableGroupFixedColumn, 
    ComparisonTableGroupAssumptions,
    ComparisonTableGroupScope,
    ComparisonTableGroupStudyData
  },
  props: ['policies'],
  data() {
    return {
      isLoading: true,
      itemsRowHeight: 120,
    }
  },
  computed: {
    existingColumnGroup() {
      return this.$store.getters['comparison/getterExistingBuildingsColumnsSelected']
    }
  },
  watch: {
    policies() {
      this.handleTableScroll()
    }
  },
  mounted() {
    this.handleTableScroll()
  },
  beforeDestroy() {
    if (this.$refs.table) this.$refs.table.removeEventListener('scroll', handleTableScroll)
  },
  methods: {
    handleTableScroll() {
      setTimeout(() => {
        if(this.$refs.table) {
          this.$refs.table.addEventListener('scroll', handleTableScroll)
        }
      }, 200)
    },
    checkScopeRowHeight() {
      setTimeout(() => {
        const allColumnsScope = document.querySelectorAll('.row-scope-item')
        for(const columnScope of allColumnsScope) {
          if(this.itemsRowHeight < columnScope.offsetHeight) {
            this.itemsRowHeight = columnScope.offsetHeight
          }
        }
      }, 200)
    },
    getStudyDataColumnGroup(columnGroupToFilter) {
      return this.$store.getters['comparison/getterExistingBuildingsColumnsSelected'].columnGroups.find((columnGroup) => columnGroup.key === columnGroupToFilter)
    },
  }
}
</script>

<style lang="scss" scoped>
  .comparison-table {
    max-height: calc(100vh - 250px);

    ::v-deep {

      div {
        flex-shrink: 0;
      }
      .comparison-table-row {
        border-color: #E3EEF2;
        border-style: solid;
        border-top-width: 1px;
        border-bottom-width: 1px;
        margin-bottom: 12px;
        &:first-of-type {
          margin-top: 15px;
        }
      }
      .column-group {
        padding-bottom: 6px;
      }
      
      > div:last-of-type .comparison-table-column:last-of-type .comparison-table-row {
        border-right-width: 1px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }

      .column-group-city_wide_estimates .comparison-table-row {
        min-width: 168px;
      }
    }
  }
</style>
