export const initial_cost = (() => {
  return { 
    isActive: true,
    key: 'initial_cost',
    title: 'Incremental Cost',
    label: 'Incremental Cost',
    hasHelper: {
      type: 'helper',
      id: 1
    }
  }
})

export const annual_bill_savings = (() => {
  return { 
    isActive: false,
    key: 'annual_bill_savings',
    title:'Annual Bill Savings',
    description: '(on-bill)',
    hasHelper: {
      type: 'helper',
      id: 2
    }
  }
})

export const annual_bill_savings_avg = (() => {
  return { 
    isActive: false,
    key: 'annual_bill_savings_avg',
    title:'Annual Bill Savings AVG',
    description: '',
    hasHelper: {
      type: 'helper',
      id: 2
    }
  }
})

export const simple_payback = (() => {
  return {
    isActive: false,
    key: 'simple_payback',
    title:'Simple Payback',
    description: '(Years)',
    hasHelper: {
      type: 'helper',
      id: 3
    }
  }
})

export const emission_savings = (() => {
  return {
    isActive: false,
    key: 'emissions_savings',
    title: 'Emissions Savings',
    description: '(MTCO2e/year)',
    hasHelper: {
      type: 'helper',
      id: 4
    }
  }
})

export const on_bill_cost_ratio = (() => {
  return {
    isActive: true,
    key: 'on_bill_cost_ratio',
    title:'On-Bill',
    description: '≥ 1.0 is cost effective',
    isChart: true,
    hasHelper: {
      type: 'helper',
      id: 5
    }
  }
})


export const kwh_savings = (() => {
  return { 
    isActive: false,
    key: 'kwh_savings',
    title:'Electricity Savings',
    description: '(kWh/year)',
    hasHelper: {
      type: 'helper',
      id: 6
    }
  }
})


export const therms_savings = (() => {
  return {
    isActive: false,
    key: 'therms_savings',
    title:'Gas Savings',
    description: '(therms/year)',
    hasHelper: {
      type: 'helper',
      id: 7
    }
  }
})

export const lifecycle_savings = (() => {
  return {
    isActive: false,
    key: 'lifecycle_savings',
    title: 'Lifecycle Savings',
    description: '(on-bill)',
    hasHelper: {
      type: 'helper',
      id: 13
    }
  }
})

export const compliance_margin = (() => {
  return { 
    isActive: false,
    key: 'compliance_margin',
    title:'Compliance Margin',
    description: '',
    hasHelper: {
      type: 'helper',
      id: 15
    }
  }
})

export const tdv_benefit_to_cost_ratio = (() => {
  return {  
    isActive: false,
    key: 'tdv_benefit_to_cost_ratio',
    title:'TDV',
    description: '≥ 1.0 is cost effective',
    isChart: true,
    hasHelper: {
      type: 'helper',
      id: 1
    }
  }
})

export const baseline_fuel_type = (() => {
  return { 
    isActive: false,
    key: 'baseline_fuel_type',
    title:'Baseline Fuel Type',
    description: '',
    hasHelper: {
      type: 'helper',
      id: 14
    }
  }
})

/**
 * City wide estimates columns
 */
export const forecast_units = (() => {
  return { 
    isActive: true,
    key: 'forecast_units_affected',
    title:'Affected Units',
    description: '(lifecycle)',
    hasProjections: true,
    hasHelper: {
      type: 'helper',
      id: 17
    },
    chartProjection: {
      title: 'Number of Affected Units',
      subtitle: ''
    }
  }

})
export const forecast_emissions_savings = (() => {
  return { 
    isActive: true,
    key: 'forecast_emissions_savings',
    title:'Emissions Savings',
    description: '(lifecycle MTCO2e)',
    hasProjections: true,
    hasHelper: {
      type: 'helper',
      id: 18
    },
    chartProjection: {
      title: 'Emissions Savings',
      subtitle: 'MTCO2e'
    }
  }
})
export const forecast_therms_savings = (() => {
  return { 
    isActive: false,
    key: 'forecast_therms_savings',
    title:'Gas Savings',
    description: '(lifecycle therms)',
    hasProjections: true,
    hasHelper: {
      type: 'helper',
      id: 22
    },
    chartProjection: {
      title: 'Gas Savings',
      subtitle: 'therms'
    },
  }
})
export const forecast_kwh_savings = (() => {
  return { 
    isActive: false,
    key: 'forecast_kwh_savings',
    title:'Electricity Savings',
    description: '(lifecycle kWh)',
    hasProjections: true,
    hasHelper: {
      type: 'helper',
      id: 21
    },
    chartProjection: {
      title: 'Electricity Savings',
      subtitle: 'kWh'
    },
  }
})


export const forecast_lifecycle_savings = (() => {
  return { 
    isActive: true,
    key: 'forecast_lifecycle_savings',
    title:'Lifecycle Savings',
    description: '(on-bill)',
    hasProjections: true,
    hasHelper: {
      type: 'helper',
      id: 19
    },
    chartProjection: {
      title: 'Bill Savings',
      subtitle: ''
    },
  }
})
export const forecast_initial_cost = (() => {
  return { 
    isActive: true,
    key: 'forecast_initial_cost',
    title:'Compliance Cost',
    description: '(lifecycle)',
    hasProjections: true,    
    hasHelper: {
      type: 'helper',
      id: 20
    },
    chartProjection: {
      title: 'Compliance Cost',
      subtitle: ''
    },
  }
})

export const tdv2022_benefit_to_cost_ratio = (() => {
  return {  
    isActive: true,
    key: 'tdv2022_benefit_to_cost_ratio',
    title:'2022 TDV',
    description: '≥ 1.0 is cost effective',
    isChart: true,
    hasHelper: {
      type: 'helper',
      id: 1
    }
  }
})

export const energy_savings_combined = (() => {
  return { 
    isActive: false,
    key: 'energy_savings_combined',
    title:'Energy Savings',
    description: 'site MMBTU/year',
    hasProjections: true,
    hasHelper: {
      type: 'helper',
      id: 62
    },
    chartProjection: {
      title: 'Energy Savings',
      subtitle: 'site MMBTU/year'
    },
  }
})

