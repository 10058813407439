

/**
 * get projections in year
 */

export default ({
  currentYearDate,
  projectionsByQuarter
}) => {

  const projectionsInCurrentYear = projectionsByQuarter.filter((item) => item.year == currentYearDate.year())
  const projectionsCummulativeUntilThisYear = projectionsByQuarter.filter((item) => item.year <= currentYearDate.year())

  // forecast column key: forecast_units_affected
  // forecast column key: forecast_emissions_savings
  // forecast column key: forecast_therms_savings
  // forecast column key: forecast_kwh_savings
  // forecast column key: forecast_lifecycle_savings
  // forecast column key: forecast_initial_cost

  return {
    year: currentYearDate.year(),
    projectionsInCurrentYear,
    projectionsCummulativeUntilThisYear,
    forecast_units_affected: {
      current: projectionsInCurrentYear.reduce((acc, item) => acc + item.installationsStarted, 0),
      cummulative: projectionsCummulativeUntilThisYear.reduce((acc, item) => acc + item.installationsStarted, 0)
    },
    forecast_emissions_savings: {
      current: projectionsInCurrentYear.reduce((acc, item) => acc + item.ghgSavings, 0),
      cummulative: projectionsCummulativeUntilThisYear.reduce((acc, item) => acc + item.ghgSavings, 0)
    },
    forecast_therms_savings: {
      current: projectionsInCurrentYear.reduce((acc, item) => acc + item.thermsSaved, 0),
      cummulative: projectionsCummulativeUntilThisYear.reduce((acc, item) => acc + item.thermsSaved, 0)
    },
    forecast_kwh_savings: {
      current: projectionsInCurrentYear.reduce((acc, item) => acc + item.kwhSaved, 0),
      cummulative: projectionsCummulativeUntilThisYear.reduce((acc, item) => acc + item.kwhSaved, 0)
    },
    forecast_lifecycle_savings: {
      current: projectionsInCurrentYear.reduce((acc, item) => acc + item.billSavings, 0),
      cummulative: projectionsCummulativeUntilThisYear.reduce((acc, item) => acc + item.billSavings, 0)
    },
    forecast_initial_cost: {
      current: projectionsInCurrentYear.reduce((acc, item) => acc + item.complianceCost, 0),
      cummulative: projectionsCummulativeUntilThisYear.reduce((acc, item) => acc + item.complianceCost, 0)
    }
  }

}