<template>
  <BaseModal
    ref="baseModal"
    :title="title"
    @close="reInitValues()"
  >
    <div class="w-full w-full">
      <div
        v-if="isLoading"
        class="w-full flex justify-start my-6"
      >
        <AppLoader />
      </div>
      <div
        v-else
        class="mt-4"
      >
        <div
          class="description"
          v-html="description"
        />
        <button
          v-if="content"
          class="uppercasefont-bold mt-10"
          @click="openDrawerContent()"
        >
          More Info
        </button>
      </div>
    </div>
  </BaseModal>
</template>


<script>



export default {
  name: 'DescriptionModal',
  data() {
    return {
      isLoading: true,
      title: '',
      description: '',
      content: '',
      type: '',
      key: '',
      keyValue: ''
    }
  },
  mounted() {
    this.$eventBus.$on('openDescriptionModal', ({ type, id, slug }) => {
      this.isLoading = true
      this.$refs.baseModal.showModal = true
      this.getModalDescription({ type, id, slug })
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('openDescriptionModal')
  },
  methods: {
    reInitValues() {
      Object.assign(this.$data, this.$options.data())
    },
    close() {
      this.$refs.baseModal.showModal = false
      this.isLoading = true
      setTimeout(() => {
        this.title = ''
        this.description = ''
        this.content = ''
      }, 500)
    },

    async getModalDescription({ type, id = null, slug = null }) {
      this.$refs.baseModal.showModal = true
      this.type = type
      this.key = (id) ? 'id' : 'slug'
      this.keyValue = id || slug

      const query = `
        {
          ${type} (
            where: [
              {column: "${this.key}", operation: "=", value: "${this.keyValue}"},
            ]
          ) {
            id
            title
            description
            content
          }
        }
      `
      const { data } = await this.$graphql({ query })

      this.title = data[type].title
      this.description = data[type].description
      this.content = data[type].content
      this.isLoading = false
    },

    openDrawerContent() {
      this.close()
      const payload = { [this.key] : this.keyValue, type: this.type }
      this.$eventBus.$emit('openDrawerContent', payload )
    }
  }
}
</script>

<style lang="scss" scoped>
  button {
    color: #2493BF;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
  .description ::v-deep {
    font-size: 16px;
    line-height: 20px;
    color: app-color(gray04); 
    *:not(a) {
      color: app-color(gray04) !important;
      font-size: 16px;
      line-height: 20px;
    }
  }

</style>