<template>
  <div class="app-policies-header w-full flex flex-col mt-1 px-12">
    <div class="app-title w-full py-1 my-2 px-14 flex items-center justify-between">
      <h1 class="text-header-6 text-deepsky-900 font-bold flex-shrink-0">
        Policy Design
      </h1>
      <div
        v-if="policyInView && isPolicyShow && !userCanEditPolicy"
        class="ml-auto flex-shrink-0"
      >
        <AppAlert
          class="w-auto cursor-pointer absolute top-0 right-0 z-10 mr-4 mt-3"
          wrapper-class="py-2 px-3 border rounded"
          description="Viewing a shared policy" 
          icon="lock"
          :icon-size="14"
          action="Duplicate to Edit"
          @click.native="openPolicyDuplicateModal"
        />
      </div>
    </div>

    <div class="w-full px-14 relative">
      <ul class="flex items-end space-y-2">
        <router-link 
          to="/policies"
          class="mr-3 transition-all page text-header-2 font-bold text-ash-600 whitespace-no-wrap"
          tag="li"
        >
          My Policies
        </router-link>
        <router-link 
          :to="{ name: 'ComparisonShow' }"
          :class="{ 'router-link-exact-active' : $route.name == 'ComparisonShow'}"
          class="mr-3 transition-all page  text-header-2 font-bold text-ash-600 whitespace-no-wrap" 
          tag="li"
        >
          Compare Policies
        </router-link>
        <div class="w-auto flex items-end">
          <router-link 
            v-for="(policy, index) in getterPoliciesOpened"
            :key="policy.id"
            :to="`/policies/${policy.id}`"
            tag="li"
            class="tab text-header-1 font-bold text-ash-500 transition-all flex-none"
            :class="[ checkPolicyIsSelected(policy) ? 'active text-deepsky-900 bg-white' : '']"
          >
            <DropdownPolicyActions 
              v-if="$route.params.policy_id == policy.id" 
              :user-can-edit-policy="userCanEditPolicy"
              :policy="policyInView"
            />
            <span> {{ policy.title }}</span>
            <feather
              type="x"
              size="16"
              class="text-ash-400 ml-2 transition-all hover:text-ash-700"
              @click.prevent="closePolicy(index)"
            />
          </router-link>
        </div>
      </ul>
    </div>
  </div>
</template>

<script>
import DropdownPolicyActions from '@/modules/app/policy/shared/DropdownPolicyActions.vue'
import { mapGetters } from 'vuex'

export default {
  components: { DropdownPolicyActions },
  data() {
    return {
      userPoliciesIds: ''
    }
  },
  computed: {
    getUser() {
      return this.$store.state.auth.loggedUser
    },
    isPolicyShow() {
      return this.$route.name === 'PolicyShow'
    },
    userCanEditPolicy() {
      return this.policyInView && (this.policyInView.user_id === this.$store.getters.getterLoggedUser?.id) ? true : false
    },
    policyInView() {
      return [...this.$store.state.policy.policiesOpened ].find((policy) => policy.id == this.$route.params.policy_id)
    },    
    ...mapGetters( 'policy', ['getterPoliciesOpened'])
  },
  methods: {
    closePolicy(index) {
      this.$store.commit('policy/closePolicy', { index })
      this.$router.push({ name: 'PolicyList' }, () => {})
    },
    openPolicyDuplicateModal() {
      this.$eventBus.$emit('openDuplicatePolicyModal', { policy : this.policyInView })
    },
    checkPolicyIsSelected(policy){
      return policy?.id == this.$route.params.policy_id
    }
  }
}
</script>

<style lang="scss" scoped>

  .app-policies-header {
    border-bottom: 1px solid #E4EEF2;
    background-color: rgb(242, 243, 244);
    &:after {
      content: '';
      width: calc(100% - 2px);
      position: absolute;
      left: -10px;
      bottom: -32px;
      height: 31px;
      background: linear-gradient(180deg, #FFFFFF 26.56%, transparent 100%);
      z-index: 21;
    }
  }
  .noedit-alert {
    position: relative;
    top: -10px
  }
  ul {
    li {
      cursor: pointer;
      display: flex;
      align-items: center;
    }
    .page {
      padding: 1px 8px;
      border-radius: 6px;
      margin-top: 8px;
      margin-bottom: 8px;
      &:hover, &.router-link-exact-active {
        background: rgba(75, 140, 166, 0.2);
        color: #4B8CA6;
      }
    }
    .tab {      
      padding: 8px 16px 10px;
      border: 1px solid #E4EEF2;
      background-color: #E8EBEE;
      border-top-width: 2px;
      margin-right: 2px;
      border-bottom: 0;
      position: relative;
      top: 2px;
      overflow: hidden;
      
      ::v-deep i {
        position: relative;
        z-index: 2;
      }
      span {
        max-width: 140px;
        display: block;        
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        // margin-right: 10px;
      }

      &:hover, &.active {
        border-top-color: #4B8CA6;
        border-bottom-color: transparent;        
        
        .app-checkbox {
          height: 10px;
          display: flex;
          align-items: center;
          margin-left: -5px;
        }
      }
      &.active {
        top: 3px;
        span {
          max-width: 30vw;
        }
      }
    }
  }

</style>