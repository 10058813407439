<template>
  <thead class="sticky z-30 top-0 py-2 text-header-2 bg-white">
    <tr class="h10">
      <th class="sticky z-30 left-0 bg-white">
        <div class="superheader-border border-b-2 pb-3 text-white">
          -
        </div>
      </th>
      <th
        v-for="columnGroup of columnGroups" 
        :key="columnGroup.key"
        :colspan="columnGroup.columns.reduce( (prev, cur) => cur.isActive ? prev + 1 : prev ,0)"
        class="p-2"
      >
        <div class="superheader-border w-full border-b-2 text-left column-group-header relative flex">
          <button class="column-group-helper-button focus:outline-none absolute">
            <feather
              size="16"
              type="info"
              class="text-blue mr-2 cursor-pointer"
              @click="$eventBus.$emit('openDrawerContent', { type: columnGroup.hasHelper.type, id: columnGroup.hasHelper.id })"
            />
          </button>
          <h2 class="text-deepsky-900 text-header-2 font-bold pb-3 ml-8">
            {{ columnGroup.title }}
          </h2>
        </div>
      </th>
      <th />
    </tr>
    <tr>
      <th class="sticky z-10 left-0 bg-white" />
      <template v-for="columnGroup of columnGroups">
        <th 
          v-for="column of columnGroup.columns" 
          :key="`${columnGroup.key}-${column.key}`" 
          class="text-deepsky-900 font-bold opacity-100-all-childrens-on-hover align-top pb-2 comparison-table-column w-auto"
        >
          <div class="w-full h-12 flex items-center py-2 px-4 column-group-header">
            <button class="column-group-helper-button focus:outline-none">
              <feather
                size="16"
                type="info"
                class="text-blue mr-2 cursor-pointer"
                @click="$eventBus.$emit('openDrawerContent', { type: column.hasHelper.type, id: column.hasHelper.id })"
              />
            </button>
            <div class="text-left">
              <p class="text-deepsky-900 font-bold">
                {{ column.title }}
              </p>
              <p class="text-ash-500">
                {{ column.description }}
              </p>
            </div>
          </div>
        </th>
      </template>
      <th />
    </tr>
  </thead>
</template>

<script>

export default {
	name: 'PolicyTableHeader',
	props: {
    columnGroups: {
      type: Array,
      required: true
    },
	},
  data() {
    return {
      hiddenItems: []
    }
  }
}
</script>

<style lang="scss" scoped>
 .superheader-border {
    border-color: #CFDFE5;
  }  
</style>