<template>
  <div class="w-full">
    <div class="w-full flex mb-3">
      <div class="w-3/5 pl-8">
        <h3 class="text-header-2 font-bold text-gray02 opacity-100-all-childrens-on-hover">
          Policy Name
          <button
            class="focus:outline-none"
            :class="{ 'opacity-0' : orderByColumn != 'title' }"
          >
            <feather
              size="16"
              :type="orderByDirection == 'asc' ? 'arrow-down' : 'arrow-up'"
              class="text-blue ml-2 cursor-pointer"
              @click="setOrderColumn('title')"
            />
          </button>
        </h3>
      </div>
      <div class="w-1/5">
        <h3 class="text-header-2 font-bold text-gray02 opacity-100-all-childrens-on-hover">
          City/County
          <button
            class="focus:outline-none "
            :class="{ 'opacity-0' : orderByColumn != 'jurisdiction_title' }"
          >
            <feather
              size="16"
              :type="orderByDirection == 'asc' ? 'arrow-down' : 'arrow-up'"
              class="text-blue ml-2 cursor-pointer"
              @click="setOrderColumn('jurisdiction_title')"
            />
          </button>
        </h3>
      </div>
      <div class="w-1/5">
        <h3 class="text-header-2 font-bold text-gray02 opacity-100-all-childrens-on-hover">
          Last Update
          <button
            class="focus:outline-none "
            :class="{ 'opacity-0' : orderByColumn != 'updated_at' }"
          >
            <feather
              size="16"
              :type="orderByDirection == 'asc' ? 'arrow-down' : 'arrow-up'"
              class="text-blue ml-2 cursor-pointer"
              @click="setOrderColumn('updated_at')"
            />
          </button>
        </h3>
      </div>
    </div>
    <div 
      v-for="policy in policiesOrdered" 
      :key="policy.id"
      class="w-full flex py-4 border-b border-deepsky-200 last:border-b-0 text-gray-04 actions-wrapper opacity-100-all-childrens-on-hover"
    >
      <div class="w-3/5 pl-8 flex relative">
        <router-link :to="`/policies/${policy.id}`">
          <h3
            class="policy-title text-p-2 text-gray04 cursor-pointer"
            @click="openPolicy(policy)"
          >
            {{ policy.title }}
          </h3>
        </router-link>
        <div class="opacity-0 flex flex ml-10 transition duration-500 ease-in-out">
          <router-link :to="`/policies/${policy.id}`">
            <button
              class="mx-2 text-ash-500 transition-all hover:text-blue02"
              title="View"
              @click="openPolicy(policy)"
            >
              <ToolTip class="text-ash-500">
                <template v-slot:trigger>
                  <feather
                    type="eye"
                    size="22"
                  />
                </template>
                <template v-slot:dialog>
                  <span>View</span>
                </template>
              </ToolTip>
            </button>
          </router-link>
          <button
            v-if="getUser && (getUser.id == policy.user_id)"
            class="mx-2 text-ash-500 transition-all hover:text-blue02"
            title="Edit name"
            @click="$eventBus.$emit('openModalPolicyCreateEdit', policy.id )"
          >
            <ToolTip class="text-ash-500">
              <template v-slot:trigger>
                <feather
                  type="edit"
                  size="22"
                />
              </template>
              <template v-slot:dialog>
                <span>Edit name</span>
              </template>
            </ToolTip>
          </button>
          <button 
            class="mx-2 text-ash-500 transition-all hover:text-blue02"
            @click="$eventBus.$emit('openDuplicatePolicyModal', { policy: policy })"
          >
            <ToolTip class="text-ash-500">
              <template v-slot:trigger>
                <feather
                  type="copy"
                  size="22"
                />
              </template>
              <template v-slot:dialog>
                Duplicate
              </template>
            </ToolTip>
          </button>
          <button 
            class="mx-2 text-ash-500 transition-all hover:text-blue02" 
            @click="sharePolicy(policy)"
          >
            <ToolTip class="text-ash-500">
              <template v-slot:trigger>
                <feather
                  type="share-2"
                  size="22"
                />
              </template>
              <template v-slot:dialog>
                Share
              </template>
            </ToolTip>
          </button>
          <button
            class="mx-2 text-ash-500 transition-all hover:text-blue02" 
            title="Delete"
            @click="$eventBus.$emit('openModalDeletePolicy', { policy: policy })"
          >
            <ToolTip class="text-ash-500">
              <template v-slot:trigger>
                <feather
                  type="trash"
                  size="22"
                />                
              </template>
              <template v-slot:dialog>
                Delete
              </template>
            </ToolTip>
          </button>
        </div>
      </div>
      <div class="w-1/5">
        <h3 class="text-p-2 text-ash-500">
          {{ parsejurisdictionAsFullText(policy.jurisdiction, true) }}
        </h3>
      </div>
      <div class="w-1/5 flex items-center">
        <h3 class="text-p-2 text-ash-500">
          {{ formatDate({ date: policy.updated_at}) }}
        </h3>      
        <span
          v-if="getUser && getUser.id != policy.user_id"
          class="flex items-center text-header-1 px-3 py-1 rounded text-ash-500 ml-4 flex-shrink-0"
        >
          <feather
            class="text-ash-500 mr-2"
            type="users"
            size="14"
          />
          Shared With Me
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { copyToClipboard } from '@/util/Helpers'

export default {
  name: 'PoliciesListTable',
  props: ['policies', 'filter'],
  computed: {
    orderByColumn: {
      get() {
        return this.$store.state.auth.user_preferences?.policies?.myPolicies?.orderByColumn
      },
      set(value) {
        this.$store.dispatch('setUserPreferences', { payload: { path: 'policies.myPolicies.orderByColumn', value } })
      }
    },
    orderByDirection: {
      get() {
        return this.$store.state.auth.user_preferences?.policies?.myPolicies?.orderByDirection
      },
      set(value) {
        this.$store.dispatch('setUserPreferences', { payload: { path: 'policies.myPolicies.orderByDirection', value } })
      }
    },  
    getUser() {
      return this.$store.state.auth.loggedUser
    },
    policiesOrdered() {
      return this.policies.map(item => item).sort((a, b) => {
        return (a[this.orderByColumn].toLowerCase() < b[this.orderByColumn].toLowerCase() ? -1 : 1) * (this.orderByDirection == 'asc' ? 1 : -1)
      })
    }
  },
  methods: {
    openPolicy(policy) {
      this.$store.commit('policy/openPolicy', { policy })
    },
    sharePolicy(policy) {
      copyToClipboard(window.location.origin + '/policies/' + policy.id)
      this.$toast.success('Policy url copied to clipboard!')
    },
    setOrderColumn(column) {
      if(this.orderByColumn == column) {
        this.orderByDirection = (this.orderByDirection == 'asc') ? 'desc' : 'asc'
      } else {
        this.orderByColumn = column
        this.orderByDirection = 'asc'
      }
    },
  }
}
</script>

<style scoped lang="scss">
  .actions-wrapper:hover .policy-title {
    color: #4B8CA6 !important;
  }
</style>