<template>
  <BaseModal
    ref="baseModal"
    modal-dialog-classes="w-auto bg-ash-100"
    @close="close"
  >
    <div class="info-wrapper text-center mt-4">
      <iframe
        v-if="video_id"
        width="720"
        height="484"
        :src="`https://www.youtube.com/embed/${video_id}`"
        :title="title"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      />
    </div>
  </BaseModal>
</template>

<script>
export default {
  name: 'ModalVideos',
  data() {
    return {
      title: '',
      video_id: '',
    }
  },
  mounted() {
    this.$eventBus.$on('openModalVideos', this.open)
  },
  beforeDestroy() {
    this.$eventBus.$off('openModalVideos')
  },
  methods: {
    open({ video_id, title = null}) {
      this.$refs.baseModal.showModal = true
      this.video_id = video_id
      this.title = title
    },
    close() {
      this.$refs.baseModal.showModal = false
      this.video_id = null
      this.title = null
    }
  }
}
</script>