<template>
  <ul>
    <li 
      class="pt-8 px-6 border-b border-deepsky-200 transition-all text-deepsky-900 hover:text-deepsky-500 cursor-pointer" 
      @click="openFaq()"
    >
      <h2 class="text-header-6 font-bold  text-current">
        FAQ
      </h2>
      <p class="text-header-4 text-ash-500 pb-8 pt-4">
        We help you understand what a cost-effectiveness study is and why it matters.
      </p>
    </li>

    <li 
      class="pt-8 px-6 border-b border-deepsky-200 transition-all text-deepsky-900 hover:text-deepsky-500 cursor-pointer" 
      @click="openQuickGuide()"
    >
      <h2 class="text-header-6 font-bold text-current ">
        Quick Guide
      </h2>
      <p class="text-header-4 text-ash-500 pt-4 pb-8">
        We help you understand what a cost-effectiveness study is and why it matters.
      </p>
    </li>
    <!-- <li @click="openTutorial()">
      <h2>Tutorial</h2>
      <p>We’ve built a tutorial to walk you through the findings.</p>
    </li> -->
    <li 
      class="pt-8 px-6 border-b text-deepsky-900 border-deepsky-200 hover:text-deepsky-500 transition-all cursor-pointer" 
      @click="openUrl('https://localenergycodes.com/content/contact-us')"
    >
      <h2 class="text-header-6 font-bold text-current ">
        Help
      </h2>
      <p class="text-header-4 text-ash-500 pt-4 pb-8">
        Contact us for assistance with this tool.
      </p>
    </li>
    <!-- <li @click="openChangeLog()">
      <h2>Changelog</h2>
      <p>Be updated on the tool releases!</p>
    </li> -->
  </ul>
</template>

<script>
import { quickGuideOptions } from '@/util/Drawers'

export default {
  name: 'SidebarMenuHelp',
  methods: {
    openQuickGuide() {     
      this.$eventBus.$emit('openDrawerContent', quickGuideOptions )
    },    
    openChangeLog() {     
      this.$eventBus.$emit('openDrawerChangelog')
    },    
    openUrl(url) {
      window.open(url)
    },
    openFaq() {
      this.$eventBus.$emit('openDrawerFaq')
    },
  }
}
</script>

<style scoped>
  p {
    max-width: 300px;
  }
</style>