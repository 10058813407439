import dayjs from 'dayjs'
import getProjectionsInQuarter from './getProjectionsInQuarter'
import ImpactAssumptions from '@/models/ImpactAssumptions'

/**
 * Get advanced projections BY QUARTER
 */
export default ({ 
  assumption, 
  building_stock_units,
  kwh_savings,
  therms_savings,
  annual_bill_savings,
  initial_cost  
}) => {

  if (!(assumption instanceof ImpactAssumptions)) assumption = new ImpactAssumptions(assumption)

  const projectionsByQuarter = []
  let index = 1
  for(let currentQuarter = assumption.start_at; dayjs(currentQuarter).isBefore(dayjs(assumption.getAdvancedFinishAt)); currentQuarter = dayjs(currentQuarter).add(1, 'quarter').format('MM/DD/YYYY')) {
    const currentQuarterDate = dayjs(currentQuarter)
    projectionsByQuarter.push(getProjectionsInQuarter({
      assumption,
      projectionsByQuarter,
      building_stock_units, 
      currentQuarterDate, 
      index,
      kwh_savings,
      therms_savings,
      annual_bill_savings,
      initial_cost
  }))
    index++
  }
  return projectionsByQuarter
}