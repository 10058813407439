<template>
  <div
    v-if="!column.isChart" 
    class="flex items-center opacity-100-all-childrens-on-hover justify-end psui-text-small relative"
    :class="isNotCustomCombination ? 'psui-text-gray-80 font-normal' : 'font-bold'"
  >
    <transition
      name="fade"
      mode="out-in"
    >
      <span :key="studyDataRow.data[column.key]">{{ formatStudyData(column.key, studyDataRow.data[column.key], studyDataRow.data) }}</span>
    </transition>
    <button 
      v-if="column.hasProjections && isNotCustomCombination"
      class="icon-projections transition-all opacity-0 absolute top-1 right-0 -mr-6"
      @click="$emit('openProjectionsModal')"
    >
      <img
        class="ml-2"
        src="/icons/chart_table.svg"
        alt=""
      >
    </button>    
  </div>
  <div
    v-else
    class="w-full flex justify-end items-center"
  >
    <p 
      :class="isNotCustomCombination ? 'psui-text-gray-80 font-normal' : 'psui-text-gray-80 font-bold'"
      class="mr-2" 
    >
      {{ formatStudyData(column.key, studyDataRow.data[column.key], studyDataRow.data) }}
    </p>
    <SimpleProgressBar :value="studyDataRow.data[column.key]" />
  </div>
</template>

<script>
export default {
  name: 'ForecastTableContentCell',
  props: ['column', 'studyDataRow'],
  computed: {
    isNotCustomCombination() {
      return this.studyDataRow.id ? true : false
    }
  }
}
</script>

<style>

</style>