import { GREATER_THEN_ONE_NUMBER } from '@/util/Enums'

import getProjectionsResume from '@/business-logic/services/projections/getProjectionsResume'
import sumProjectionData from '@/business-logic/services/projections/sumProjectionData'
import getStudyDataColumns from '@/business-logic/services/study-results/getStudyDataColumns'
import sumStudyResultData from '@/business-logic/services/study-results/sumStudyResultData'
export default class StudyData {
  constructor({ study_data = {}, building_stock_units = 0, assumption = null } = {}) {

    this.id = study_data.id || null
    this.study_row_number = study_data.study_row_number || 0
    this.study_id = study_data.study_id || null
    this.prototype_id = study_data.prototype_id || null
    this.measure_id = study_data.measure_id || null
    this.vintage_id = study_data.vintage_id || null
    this.fuel_id = study_data.fuel_id || null
    this.climate_zone_raw = study_data.climate_zone_raw || ''
    this.baseline_fuel_type = study_data.baseline_fuel_type || ''

    //Relationships
    this.study = study_data.study || null
    this.climate_zone = study_data.climate_zone || null
    this.prototype = study_data.prototype || null
    this.vintage = study_data.vintage || null
    this.measure = study_data.measure || null
    this.fuel = study_data.fuel || null
    this.sync = study_data.sync || null

    this.study_data = study_data
    this.building_stock_units = building_stock_units
    this.assumption = assumption

    this.generateStudyResultData()

    //Virtual attrs
    this.measure_combination_conflict = false
    this.measure_is_added = false
    
    this.measure_could_be_combined = false
    if(this.measure && this.measure.include_in_combination) {
      this.measure_could_be_combined = true
    }
    if (this.measure && !this.isCostEffective) {
      this.measure_could_be_combined = false 
    }

  }

  get isCostEffective() {
    if (this.isExistingBuildingStudy) {
      return this.isOnBillEffective
    } else {
      return ( this.isTdvEffective || this.isOnBillEffective )
    }
  }

  get isTdvEffective() {
    if (this.isExistingBuildingStudy) {
      return this.data.tdv2022_benefit_to_cost_ratio === null || this.data.tdv2022_benefit_to_cost_ratio >= 1 ? true : false
    } else {
      return this.data.tdv_benefit_to_cost_ratio >= 1 || this.data.tdv_benefit_to_cost_ratio == GREATER_THEN_ONE_NUMBER ? true : false 
    }
  }

  get isOnBillEffective() {
    return this.data.on_bill_cost_ratio >= 1 || this.data.on_bill_cost_ratio == GREATER_THEN_ONE_NUMBER ? true : false
  }

  get isExistingBuildingStudy() {
    return this.fuel_id === null
  }
  

  generateStudyResultData() {
    this.data = getStudyDataColumns({ study_data: this.study_data })
    if(this.building_stock_units > 0) {
      this.generateStudyResultProjections()
    }
  }

  // Source methodology : https://docs.google.com/spreadsheets/d/16PIVWUg9dK3Uz-42CvEvG6QPeiTzEsVO0lhiaq8a7yI/edit#gid=2054033701
  generateStudyResultProjections() {
    const projectionsCityWideImpactValues = getProjectionsResume({ 
      assumption: this.assumption,
      building_stock_units: this.building_stock_units,
      kwh_savings: this.data.kwh_savings,
      therms_savings: this.data.therms_savings,
      annual_bill_savings: this.data.annual_bill_savings,
      initial_cost: this.data.initial_cost
    })
    Object.keys(projectionsCityWideImpactValues).map((col) => {
      this.data[col] = projectionsCityWideImpactValues[col]
    })
  }

  /**
   * Function that aggregate study_data columns to generate a new measure combined
   */
  sumMeasureCombinations({ dataToAggregate }) {
    sumStudyResultData({ data: this.data, dataToAggregate: dataToAggregate })
    sumProjectionData({ data: this.data, dataToAggregate: dataToAggregate })
  }

}