<template>
  <transition
    enter-active-class="transition ease-out duration-200 transform"
    enter-class="opacity-0 translate-x-full"
    enter-to-class="opacity-100"
    leave-active-class="transition ease-in duration-200 transform"
    leave-class="opacity-100"
    leave-to-class="opacity-0 translate-x-full"
    mode="out-in"
  >
    <div 
      v-if="showDrawer" 
      ref="baseDrawer"
      :class="[`layout--${layout}`, drawerClasses]" 
      class="app-drawer origin-right fixed top-0 w-auto h-screen bg-white shadow-lg flex flex-col z-60"
      :style="{ right: getRightPosition, maxWidth: getMaxWidth }"
    >
      <slot name="header">
        <div class="w-auto flex flex-col app-drawer-header pt-6">
          <div class="w-auto flex justify-between items-center">
            <AppBreadcrumb
              v-if="breadcrumbs"
              :items="breadcrumbs"
              class="mt-2"
            />
            <AppButtonClose
              class="ml-auto mr-12"
              @click.native="closeDrawer()"
            />
          </div>        
        </div>
      </slot>
      
      <div class="w-auto overflow-y-auto px-12">
        <div
          v-if="title || subtitle"
          class="w-auto flex flex-col app-drawer-title-wrapper"
        >
          <h1 
            v-if="title"
            v-tooltip="{ content: `Helper id: ${id}`, delay: { show: 1500, hide: 0 }}"            
            class="app-drawer-title row-onhover"
          >
            {{ title }}
          </h1>
          <slot name="subtitle">
            <h4
              v-if="subtitle"
              class="app-drawer-subtitle"
              v-html="subtitle"
            />
          </slot>
        </div>

        <div class="app-drawer-content">
          <slot name="default" />
        </div>
      </div>
      <slot name="footer" />
    </div>
  </transition>
</template>

<script>
import AppBreadcrumb from '@/components/general/AppBreadcrumb.vue'
import AppButtonClose from '@/components/general/AppButtonClose.vue'
export default {
  name: 'BaseDrawer',
  components: { AppButtonClose, AppBreadcrumb },
  props: {
    title: {
      type: String,
    },
    id: {
      type: [String, Number],
      default: ''
    },
    subtitle: {
      type: String,
      required: false
    },
    layout: {
      type: String,
      required: false,
      default: 'default'
    },
    drawerClasses: {
      type: String,
      required: false,
      default: ''
    },
    breadcrumbs: {
      type: [Array, null],
      required: false
    },
    /** You should pass a HTML element ID */
    alignToElement: {
      type: [Boolean, String],
      default: 'main-content'
    }
  },
  data() {
    return {
      showDrawer: false,
      alignToElementObserver : null
    }
  },
  computed: {
    getRightPosition() {
      if(!this.$store.state.assumptions.drawerOptions.visibility) return '0px'
      return '340px !important'
    },
    getMaxWidth() {
      const alignToElement = document.getElementById(this.alignToElement)
      const boundings = alignToElement.getBoundingClientRect()
      const right = parseInt(this.getRightPosition.replace(/\D/g, ''))
      const maxWidth = document.body.clientWidth - (boundings.left + right)
      return `${maxWidth}px`
    }
  },
  mounted() {
    document.addEventListener("keyup", this.handleEsc)
  },
  beforeDestroy() {
    document.removeEventListener("keyup", this.handleEsc)
    if (this.alignToElementObserver) this.alignToElementObserver.disconnect()
  },
  methods: {
    handleEsc(evt) {
      if (this.showDrawer && evt.keyCode === 27) this.closeDrawer()
    },
    openDrawer() {      
      this.showDrawer = true
    },
    closeDrawer() {
      this.showDrawer = false
    },
    checkAlignToElement() {      
      if (this.alignToElement && this.$refs?.baseDrawer) {
        const alignToElement = document.getElementById(this.alignToElement)        
        const boundings = alignToElement.getBoundingClientRect()

        console.log(boundings)
        console.log(document.body.clientWidth)
        this.$refs.baseDrawer.style.right = `${document.body.clientWidth - boundings.right}px`
      }
    },
    initAlignToElementObserver() {
      if (this.alignToElement) {
        const alignToElement = document.getElementById(this.alignToElement)
        if(alignToElement) {
          this.alignToElementObserver = new ResizeObserver(() => {
            this.checkAlignToElement()
          })
          this.alignToElementObserver.observe(alignToElement)
        }
    }
    }
  }
}
</script>

<style lang="scss">
  
  .app-drawer-title {
    margin-top: 72px;      
  }
  .app-drawer-subtitle, .app-drawer-subtitle * {
    margin-top: .8rem;
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    color: #64666d;
  }
  .app-drawer-content {
    margin-top: 48px;
  }

  .app-drawer {
    &.layout--default {

      .app-drawer-title {
        font-size: 28px;
        line-height: 36px;
        font-weight: bold;
        color: app-color(bluebrand);
      }

    }
  }
</style>
