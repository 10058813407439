<template>
  <div class="w-full p-8 mt-6">
    <CustomFieldAddKeyForm @change="getCustomFields()" />
    <CustomFieldEditModal @change="getCustomFields()" />

    <table class="table table-bordered table-hover table-striped mt-6">
      <thead>
        <tr>
          <td>ID</td>
          <td>Item ID</td>
          <td>Item type</td>
          <td class="psui-w-48">
            Actions
          </td>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="custom_field of custom_fields"
          :key="custom_field.id"
        >
          <td>{{ custom_field.id }}</td>
          <td>{{ custom_field.item_id }}</td>
          <td>{{ custom_field.item_type }}</td>
          <td>
            <div class="psui-flex psui-space-x-2">
              <PsButton
                label="Edit"
                icon="edit"
                size="medium"
                @click="$eventBus.$emit('openCustomFieldForm', custom_field.id)"
              />
              <PsButton
                layout="caution"
                label="Delete"
                icon="delete"
                size="medium"
                @click="selectCustomFieldToDelete(custom_field)"
              />
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <BaseModal
      ref="deleteModal"
      modal-dialog-classes="w-full max-w-sm"
      title="Attention"
    >
      <div
        v-if="custom_field_selected"
        class="w-full flex flex-col items-start mt-6"
      >
        <p class="text-base text-gray04">
          Delete Custom Field <span class="text-red font-bold">{{ custom_field_selected.item_type }}</span>?
        </p>
        <PsButton
          layout="caution"
          class="psui-mt-6"
          label="Confirm delete"
          size="big"
          @click="deleteCustomField()" 
        />
      </div>
    </BaseModal>

    <Paginator
      v-model="offset"
      :length="custom_fields.length"
      :limit="limit"
      @getItems="getCustomFields({ offset })"
    />
  </div>
</template>

<script>
import CustomFieldAddKeyForm from './CustomFieldAddKeyForm'
import CustomFieldEditModal from './CustomFieldEditModal'
export default {
  name: 'CustomFieldList',
  components: { CustomFieldAddKeyForm, CustomFieldEditModal },
  data() {
    return {
      custom_fields: [],
      custom_field_selected: null,
      offset: 0,
      limit: 12,
    }
  },
  mounted() {
    this.getCustomFields()
  },
  methods: {
    getCustomFields({ offset = 0 } = {}) {
      this.offset = offset
      const query = `
        {
          custom_fields(
             orderBy: [{column: "id", order: "asc"}],
            paginate: { offset: ${this.offset}, limit: ${this.limit} }
          ) {
            id
            item_id
            item_type
            data
          }
        }
      `
      this.$graphql({ query }).then(({ data }) => {
        this.custom_fields = data.custom_fields
      })
    },

    selectCustomFieldToDelete(custom_field) {
      this.custom_field_selected = custom_field
      this.$refs.deleteModal.showModal = true
    },

    deleteCustomField() {
      this.$api
        .delete(
          `/api/custom_field/delete/${this.custom_field_selected.id}`
        )
        .then(() => {
          this.getCustomFields()
          this.$refs.deleteModal.showModal = false
          this.custom_field_selected = null
        })
    },
  },
}
</script>
