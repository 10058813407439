<template>
  <div>
    <div class="flex gap-1 px-6 my-6 ">
      <PsRichTooltip 
        layout="blue" 
        title="New Construction policies are not available yet."
        class="inline-flex"
      >
        <template v-slot:trigger>
          <PsButton
            label="Save to policy"
            icon="add_circle"
            size="medium"
            disabled
            @click="openAssumptionsModal()"
          />
        </template>
      </PsRichTooltip>

      <PsButton
        label="Restore default"
        icon="restart_alt"
        size="medium"
        layout="ghost"
        :disabled="$store.getters['assumptions/buildingEstimates/getterCustomJurisdictionBuildingEstimates']({ type_prototype_id: building_type.type_prototypes.map(type_prototype => type_prototype.id) }).length == 0"
        @click="restoreToDefault()"
      />
    </div>

    <PsDialog
      v-if="shouldShowNotLoggedWarning"
      class="mt-3 mb-5 mx-6"
      theme="alert"
      :has-close="false"
      message="Be sure to register or login so we can save changes to your workspace"
      layout="vertical"
      @close="$store.commit('assumptions/showNotLoggedWarning', false, { root: true })"
    >
      <template #action>
        <div>
          <p
            class="psui-font-bold mt-1 hover:opacity-80 transition-all cursor-pointer inline-block"
            @click="openModalUserSign()"
          >
            Register / Login
          </p>
        </div>
      </template>
    </PsDialog>

    <AssumptionsGrowthWidget 
      :specify-by-options="specifyByOptions" 
      :specify-by.sync="specifyBy"
      header-title="Dwelling Unit Growth"
    />
    <div
      v-if="jurisdiction"
      class="mx-7"
    >
      <h2 class="font-bold psui-text-gray-80 mb-1 psui-text-big">
        Residential Units as of 2020
      </h2>
      <ul class="flex justify-between psui-text-small psui-text-gray-60">
        <li>Total units in {{ jurisdiction.climate_zones.length }} climate zones</li>
        <li class="psui-text-gray-80">
          {{ formatUnits($store.getters['assumptions/buildingStocks/getterAllBuildingStocksUnits']({})) }}
        </li>
      </ul>
      <hr class="psui-border-blue-70 opacity-10 my-7">
    </div>
    
    <div
      v-if="specifyBy.length !== 0"
      class="mx-7"
    >
      <h2 class="font-bold psui-text-gray-80 mb-1">
        New units forecasted for 2023-2025
      </h2>

      <ul class="flex justify-between psui-text-small psui-text-gray-60">
        <li>Forecast units for {{ jurisdiction.climate_zones.length }} climate zones</li>
        <li class="psui-text-gray-80">
          {{ formatUnits($store.getters['assumptions/buildingEstimates/getterAllBuildingEstimateUnits'](getBaseFilters)) }}
        </li>
      </ul>

      <hr class="psui-border-blue-70 opacity-10 mt-7">
    </div>

    <div
      v-if="specifyByIncludesClimateZone"
      class="mx-7"
    >
      <AssumptionsBuildingEstimatesClimateZones       
        :climate_zone_prefix="getClimateZonePrefixSelected"
        :climate_zones="jurisdiction.climate_zones"
        :specify-by="specifyBy"
        :filters="{...getBaseFilters, climate_zone_prefix }"        
        @setClimateZonePrefix="climate_zone_prefix = $event"
      />
      <ul class="flex justify-between psui-text-small psui-text-gray-60">
        <li>Forecast units in selected climate zone</li>
        <li class="psui-text-gray-80">
          {{ formatUnits($store.getters['assumptions/buildingEstimates/getterAllBuildingEstimateUnits'](getFilters)) }}
        </li>
      </ul>
    </div>

    <div 
      :is="getComponent"
      :specify-by="specifyBy"
      :jurisdiction="jurisdiction"
      :climate_zones="jurisdiction.climate_zones" 
      :type_prototypes_ids="type_prototypes_ids"
      :type_prototypes="building_type.type_prototypes"
      :climate_zone_prefix="getClimateZonePrefixSelected"
      :filters="getFilters"
      class="mx-7"
    />
  </div>
</template>

<script>
import AssumptionsBuildingEstimatesClimateZones from '../AssumptionsBuildingEstimatesClimateZones.vue'
import AssumptionsResidentialNewBuildingTypes from './AssumptionsResidentialNewBuildingTypes.vue'
import AssumptionsResidentialNewOverall from './AssumptionsResidentialNewOverall.vue'
import AssumptionsResidentialNewYears from './AssumptionsResidentialNewYears.vue'
import AssumptionsGrowthWidget from '../AssumptionsGrowthWidget.vue'
import { RESIDENTIAL_NEW_SPECIFICATIONS, BUILDING_ESTIMATES_BASE_YEARS } from '@/modules/app/assumptions/shared/enums'

export default {
  name: 'AssumptionsResidentialNew',
  components: { 
    AssumptionsGrowthWidget, 
    AssumptionsResidentialNewBuildingTypes, 
    AssumptionsBuildingEstimatesClimateZones 
  },
  props: {
    jurisdiction: [ Boolean, Object ],
    building_type: [ Boolean, Object ],
  },
  data: () => ({
    specifyByOptions : RESIDENTIAL_NEW_SPECIFICATIONS,
    specifyBy: [],
    climate_zone_prefix: false
  }),
  computed: {
    getComponent() {
      if (this.specifyBy.length === 0 || this.specifyByIncludesOnlyClimateZone) {
        return AssumptionsResidentialNewOverall
      } else if (this.specifyByIncludesOnlyYear || (this.specifyByIncludesYear && !this.specifyByIncludesBuildingType) ) {
        return AssumptionsResidentialNewYears
      } else if (this.specifyByIncludesBuildingType) {
        return AssumptionsResidentialNewBuildingTypes
      } else {
        return false
      }
    },
    type_prototypes_ids() {
      return this.building_type ? this.building_type.type_prototypes.map(i => i.id ) : false
    },
    specifyByIncludesClimateZone() {
      return this.specifyBy.includes(RESIDENTIAL_NEW_SPECIFICATIONS.CLIMATE_ZONE.key)
    },
    specifyByIncludesOnlyClimateZone() {
      return this.specifyBy.length === 1 && this.specifyBy.includes(RESIDENTIAL_NEW_SPECIFICATIONS.CLIMATE_ZONE.key)
    },
    specifyByIncludesBuildingType() {
      return this.specifyBy.includes(RESIDENTIAL_NEW_SPECIFICATIONS.BUILDING_TYPE.key)
    },
    specifyByIncludesYear() {
      return this.specifyBy.includes(RESIDENTIAL_NEW_SPECIFICATIONS.YEAR.key)
    },
    specifyByIncludesOnlyYear() {
      return this.specifyBy.length === 1 && this.specifyBy.includes(RESIDENTIAL_NEW_SPECIFICATIONS.YEAR.key)
    },
    getBaseFilters() {
      return { type_prototype_id: this.type_prototypes_ids, year: BUILDING_ESTIMATES_BASE_YEARS }
    },
    getFilters() {
      const filters = { ...this.getBaseFilters }
      if (this.getClimateZonePrefixSelected) filters.climate_zone_prefix = this.getClimateZonePrefixSelected
      return filters
    },
    getClimateZonePrefixSelected() {
      if (!this.specifyByIncludesClimateZone) return false
      if(!this.climate_zone_prefix) return this.jurisdiction.climate_zones[0].prefix
      return this.climate_zone_prefix
    },
    shouldShowNotLoggedWarning() {
      return !this.$store.getters.getterLoggedUser && this.$store.getters['assumptions/buildingEstimates/getterCustomJurisdictionBuildingEstimates']().length
    },
    getAssumptionsDrawerOptions(){
      return this.$store.getters['assumptions/getterAssumptionsDrawerOptions']
    },
    getterLastClimateZonesVisited(){
      return this.$store.getters['lastClimateZonesVisited']
    },
    getterCustomJurisdictionBuildingEstimates() {
      const policy_id = (this.$route.params.policy_id) ? this.$route.params.policy_id : null
      return this.$store.getters['assumptions/buildingEstimates/getterCustomJurisdictionBuildingEstimates']({ policy_id })
    },

  },
  mounted(){
    if(this.getAssumptionsDrawerOptions.isEditingFromChart) {
      this.specifyBy.push(this.specifyByOptions["BUILDING_TYPE"].key, this.specifyByOptions["CLIMATE_ZONE"].key)
      this.climate_zone_prefix = this.getterLastClimateZonesVisited[0]["prefix"]
    }
  },
  methods: {
    openAssumptionsModal() {
      const eventName = 'openAssumptionsModal'
      const eventPayload = 'ESTIMATES'
      this.$eventBus.$emit(eventName, eventPayload)
      this.$store.commit('general/setRedirectTo', { route: this.$route.path, event: { name: eventName, payload: eventPayload } })
    },
    openModalUserSign() {
      this.$eventBus.$emit('openModalUserSign')
      const event = {
        name: 'openAssumptionDrawerFromAppBoot',
        payload: this.$store.getters['assumptions/getterAssumptionsDrawerOptions']
      }
      this.$store.commit('general/setRedirectTo', { route: this.$route.path, event })
    },
    restoreToDefault() {
      this.$store.dispatch('assumptions/buildingEstimates/clearCustomBuildingEstimates', this.getterCustomJurisdictionBuildingEstimates)
    },
  }
}
</script>

<style lang="scss" scoped>
  
  ::v-deep .psui-el-tooltip {
    flex-grow: 1;
    .psui-el-tooltip-wrapper {
      .psui-el-tooltip-dialog {
        width: 180px;
        z-index: 50;
      }
    }

    .psui-el-tooltip-trigger {
      display: flex;

      button {
        flex-grow: 1;
        display: flex;
        
      }
    }
  }

</style>
