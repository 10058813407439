import formatNumber from './formatNumber'

export default (value, data) => {
  let formatted
  const valueParsed = parseFloat(value)  
  const initial_cost = parseFloat(data['initial_cost'])
  const annual_bill_savings = parseFloat(data['annual_bill_savings'])

  if( initial_cost < 0 && annual_bill_savings < 0) {
    formatted = '--'
  } else if(initial_cost == 0 && annual_bill_savings > 0) {
    formatted = 'Immediate'
  } else if(initial_cost > 0 && annual_bill_savings <= 0) {
    return 'Never'
  } else if(valueParsed < 0) {
    formatted = 'Immediate'
  } else {
    formatted = formatNumber(valueParsed)
  }
  return formatted
}