<template>
  <StudyResultsContentCard
    v-if="lastJurisdictionVisited && lastClimateZoneVisited"
    :expanded.sync="expanded"
    :prototype="prototype"
    :fuel="fuel"
    :vintage="false"
    :climate_zone="lastClimateZoneVisited"
    :jurisdiction="lastJurisdictionVisited"
    @study-data-update="onStudyDataUpdate($event)"
  >
    <template v-slot:header>
      <div class="w-full flex items-center">
        <div
          class="w-auto whitespace-no-wrap mr-4 show-helper-on-hover flex cursor-pointer"
          @click.stop="$eventBus.$emit('openDescriptionModal', { type: 'fuel', id: fuel.id })"
        >
          <h4 class="font-bold text-bluebrand text-base">
            {{ fuel.type_fuel ? fuel.type_fuel.title : fuel.title }}
          </h4>
          <AppHelperButton />
        </div>
        <div
          v-if="expanded"
          class="w-full flex flex-wrap justify-end"
        >
          <AppCardButton
            icon="columns"
            description="Add/Hide Columns"
            icon-class="text-deepsky-500"
            class="cursor-pointer"
            @click.native="$eventBus.$emit('openStudyResultsDrawerEditColumns', 'New Buildings')"
          />
        </div>
      </div>
    </template>

    <template v-slot:body>
      <ForecastTable 
        :study="study"
        :prototype="prototype"
        :study_data="studyData"
      />
    </template>
  </StudyResultsContentCard>
</template>

<script>
import AppHelperButton from '@/components/general/AppHelperButton.vue'
import StudyResultsContentCard from './StudyResultsContentCard.vue'
import ForecastTable from './table/ForecastTable'
import { mapGetters } from 'vuex'

export default {
  name: 'StudyResultsContentFuel',
  components: { 
    ForecastTable, 
    StudyResultsContentCard, 
    AppHelperButton, 
  },
  props: {
    prototype: {
      type: Object,
      required: true
    },
    fuel: {
      type: Object,
      required: true,
    },
    study: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      expanded: false,
      studyData: [],
    }
  },
  computed: {
    ...mapGetters(['lastJurisdictionVisited', 'lastClimateZoneVisited'])
  },
  methods: {
   onStudyDataUpdate($event) {
      this.studyData = $event
    }
  },
}
</script>