<template>
  <div class="forecast-assumptions-widget w-auto flex items-center ts--accent--2">
    <p class="text-gray02 font-bold flex">
      <span>{{ jurisdictionType }}-wide estimates use these </span>
      <button
        class="ml-1 text-blue02 border-b border-gray05 font-bold flex items-center"
        @click="openAssumptionsDrawer()"
      >
        Assumptions
        <img
          src="/icon-arrow-down.svg"
          class="ml-2"
        >
      </button>
    </p>
  </div>
</template>

<script>
export default {
  name: 'AssumptionsWidget',  
  computed: {
    jurisdictionType() {
      return this.$store.getters['lastJurisdictionVisited']?.type ?? 'City'
    },
  },
  methods: {
    openAssumptionsDrawer() {
      this.$store.commit('assumptions/toggleDrawer')
    }
  }
}
</script>
