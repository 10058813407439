import calculateBuildingStocks from './calculateBuildingStocks'
import generatePolicyVintageData from './generatePolicyVintageData'
import generatePolicyOtherItemsData from './generatePolicyOtherItemsData'
import sumStudyResultData from '../study-results/sumStudyResultData'
import generatePolicyProjections from './generatePolicyProjections'
import Store from '@/store'

export default ({ policy, items }) => {
  return new Promise( (resolve, reject) => {
    try {
      for( const [index, total] of items.entries()) {
        for(const studyType of total.items) {
          for(const study of studyType.items) {
            for(const prototype of study.items) {
              for(const climateZone of prototype.items) {
                for(const vintage of climateZone.items) {
                  vintage.building_stock_units = Store.getters['assumptions/buildingStocks/getterAllBuildingStocksUnits']({
                    jurisdiction_id: policy.jurisdiction_id,
                    climate_zone_prefix: climateZone.climate_zone_prefix,
                    type_vintage_id: vintage.type_vintage_id,
                    type_prototype_id: prototype.type_prototype_id,
                  })
                  if(index > 0) calculateBuildingStocks({ total, studyType, study, prototype, climateZone, vintage })
                  for(const studyData of vintage.items) {
                    // sum here is just to initializate the studyData.data object
                    sumStudyResultData({ data: studyData.data, dataToAggregate: studyData })
                    generatePolicyProjections({ policy, data: studyData.data, building_stock_units: vintage.building_stock_units })
                  }
                  generatePolicyVintageData({ vintageData: vintage.data, studyDataItems: vintage.items.map((item) => { return { ...item.data }} )  })
                } 
                generatePolicyOtherItemsData({ itemData: climateZone.data, itemsRef: climateZone.items.map((item) => { return { ...item.data }} )  })
              }
              generatePolicyOtherItemsData({ itemData: prototype.data, itemsRef: prototype.items.map((item) => { return { ...item.data }} )  })
            }
            generatePolicyOtherItemsData({ itemData: study.data, itemsRef: study.items.map((item) => { return { ...item.data }} )  })
          }
          generatePolicyOtherItemsData({ itemData: studyType.data, itemsRef: studyType.items.map((item) => { return { ...item.data }} )  })
        }
        generatePolicyOtherItemsData({ itemData: total.data, itemsRef: total.items.map((item) => { return { ...item.data }} )  })
        
        if(index == items.length-1) resolve()
      }
    } catch (error) {
      reject(error)
    }
  })
}