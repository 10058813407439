<template>
  <div class="w-full flex flex-col p-8 relative">
    <div class="w-full flex mb-10">
      <InputWithLabel
        v-model="studyDataId"
        label="Study data id"
        placeholder="Insert the study data id"
        :style="{ width: '250px' }"
      />
      <!-- <AppButton
        css-class="text-center ml-3"
        label="Load"
        size="medium"
        @click="loadStudyData()"
      />
      <AppButton
        css-class="text-center ml-auto"
        label="Assumptions"
        size="medium"
        @click="openAssumptionsDrawer()"
      /> -->
      <PsButton
        class="text-center ml-3"
        label="Load"
        size="medium"
        @click="loadStudyData()"
      />
      <PsButton
        class="text-center ml-auto"
        label="Assumptions"
        size="medium"
        @click="openAssumptionsDrawer()"
      />
    </div>
    
    <template v-if="study_data && study_data.climate_zone_raw">
      <h4
        v-if="!jurisdictionSelected || projectionsByYear.length === 0"
        class="w-full flex-shrink-0 text-red font-bold my-5"
      >
        The projections requires a building stock estimate, please select a jurisdiction.
      </h4>
      <div class="w-2/5 w-full relative mb-10">
        <VueSelect
          v-model="jurisdictionSelected"
          class="vue-select"
          placeholder="Select a jurisdiction"
          :class="jurisdictionSelected ? 'filled' : ''"
          label="title_type"
          :options="jurisdictions"
          @option:selected="onJurisdictionSelected"
        />
        <label>Jurisdiction</label>
      </div>
    </template>
    
    <!-- <pre>{{study_data}}</pre> -->
    <div
      v-if="study_data"
      class="w-full mt-6 flex flex-wrap"
    >      
      <div class="w-1/2">
        <h4 class="font-bold">
          Climate Zone: {{ study_data.climate_zone_raw }}
        </h4>
        <h4 class="">
          Vintage: {{ study_data.vintage.title }}
        </h4>
        <h4 class="">
          Prototype: {{ study_data.prototype.title }}
        </h4>
        <h4 class="">
          Measure: {{ study_data.measure.title }}
        </h4>
        <h4 class="">
          Study Row Number: {{ study_data.study_row_number }}
        </h4>
      </div>
      <div class="w-1/2">
        <h4 class="">
          Emissions savings: {{ study_data.emissions_savings }}
        </h4>
        <h4 class="">
          Kwh savings: {{ study_data.kwh_savings }}
        </h4>
        <h4 class="">
          Therms savings: {{ study_data.therms_savings }}
        </h4>
        <h4 class="">
          Annual bill savings: {{ study_data.annual_bill_savings }}
        </h4>
        <h4 class="">
          Building units: {{ building_stock_units }}
        </h4>
      </div>
    </div>
    
    <div v-if="projectionsByYear.length">
      <h4 class="font-bold my-3">
        Visible Items
      </h4>
      <Checkbox 
        v-for="item in Object.keys(visibleItems)"
        :key="'checkbox' + item"
        v-model="visibleItems[item]"
        :label="item"
        class="mr-5"
      />    
      <div
        v-if="visibleItems.specialProps"
        class="mt-4"
      >
        <h4 class="font-bold my-3">
          Special Props
        </h4>
        <Checkbox
          v-for="prop in Object.keys(specialProps)"
          :key="'selector' + prop"
          v-model="specialProps[prop]"
          :label="prop"
          class="mr-3"
        />
      </div>
      <div
        v-if="visibleItems.assumptions"
        class="mt-4"
      >
        <pre>{{ $store.state.forecast.assumptions }}</pre>
      </div>
    </div>
    <!-- <pre v-if="projectionsByYear">{{projectionsByYear[0]}}</pre> -->
    <div
      v-if="projectionsByYear.length && visibleItems.quarters"
      class="w-full mt-6 max-w-full overflow-x-auto table-wrapper"
    >
      <h4 class="font-bold my-3">
        Projections by Years/Quarters
      </h4>
      <table class="table table-bordered table-hover table-striped">
        <thead>
          <tr>
            <td
              v-for="prop in selectedSpecialProps"
              :key="prop"
            >
              {{ prop }}
            </td>
          </tr>
        </thead>
        <tbody
          v-for="(projection) in projectionsByYear"
          :key="projection.year + 'quarters'"
        >
          <tr
            v-for="(projectionQuarter) in getProjectionsInYear(projection.year)"
            :key="projectionQuarter.year + projectionQuarter.quarter"
          >
            <td
              v-for="prop in selectedSpecialProps"
              :key="prop"
            >
              {{ projectionQuarter[prop] }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      v-if="projectionsByYear.length && !visibleItems.quarters"
      class="table-wrapper w-full mt-6 max-w-full overflow-x-auto"
    >
      <h4 class="font-bold my-3">
        Projections by Years
      </h4>
      <table class="table table-bordered table-hover table-striped mt-3">
        <thead>
          <tr>
            <td>Year</td>
            <td>Units affected (cur/cum)</td>
            <td>Emission savings (cur/cum)</td>
            <td>Therms savings (cur/cum)</td>
            <td>Kwh savings (cur/cum)</td>
            <td>Compliance cost (cur/cum)</td>
            <td>Lifecycle savings (cur/cum)</td>
            <td>GHG Savings from kWh</td>
          </tr>
        </thead>
        <tbody
          v-for="projection in projectionsByYear"
          :key="projection.year"
        >
          <tr>
            <td>{{ projection.year }}</td>
            <td>
              <div class="flex flex-col">
                <span @click="addValueToSum(projection.forecast_units_affected.current, `${projection.year}: forecast_units_affected.current`)">{{ projection.forecast_units_affected.current }} </span>
                <span @click="addValueToSum(projection.forecast_units_affected.cummulative, `${projection.year}: forecast_units_affected.cummulative`)">{{ projection.forecast_units_affected.cummulative }} </span>
              </div>
            </td>
            <td>
              <div class="flex flex-col">
                <span @click="addValueToSum(projection.forecast_emissions_savings.current, `${projection.year}: forecast_emissions_savings.current`)">{{ projection.forecast_emissions_savings.current }} </span>
                <span @click="addValueToSum(projection.forecast_emissions_savings.cummulative, `${projection.year}: forecast_emissions_savings.cummulative`)">{{ projection.forecast_emissions_savings.cummulative }} </span>
              </div>
            </td>
            <td>
              <div class="flex flex-col">
                <span @click="addValueToSum(projection.forecast_therms_savings.current, `${projection.year}: forecast_therms_savings.current`)">{{ projection.forecast_therms_savings.current }} </span>
                <span @click="addValueToSum(projection.forecast_therms_savings.cummulative, `${projection.year}: forecast_therms_savings.cummulative`)">{{ projection.forecast_therms_savings.cummulative }} </span>
              </div>
            </td>
            <td>
              <div class="flex flex-col">
                <span @click="addValueToSum(projection.forecast_kwh_savings.current, `${projection.year}: forecast_kwh_savings.current`)">{{ projection.forecast_kwh_savings.current }} </span>
                <span @click="addValueToSum(projection.forecast_kwh_savings.cummulative, `${projection.year}: forecast_kwh_savings.cummulative`)">{{ projection.forecast_kwh_savings.cummulative }} </span>
              </div>
            </td>
            <td>
              <div class="flex flex-col">
                <span @click="addValueToSum(projection.forecast_initial_cost.current, `${projection.year}: forecast_initial_cost.current`)">{{ projection.forecast_initial_cost.current }} </span>
                <span @click="addValueToSum(projection.forecast_initial_cost.cummulative, `${projection.year}: forecast_initial_cost.cummulative`)">{{ projection.forecast_initial_cost.cummulative }} </span>
              </div>
            </td>
            <td>
              <div class="flex flex-col">
                <span @click="addValueToSum(projection.forecast_lifecycle_savings.current, `${projection.year}: forecast_lifecycle_savings.current`)">{{ projection.forecast_lifecycle_savings.current }} </span>
                <span @click="addValueToSum(projection.forecast_lifecycle_savings.cummulative, `${projection.year}: forecast_lifecycle_savings.cummulative`)">{{ projection.forecast_lifecycle_savings.cummulative }} </span>
              </div>
            </td>
            <td>
              <div class="flex flex-col">
                {{ projection.projectionsInCurrentYear.reduce((acc, item) => acc + item.ghgSavingsFromKwh, 0) }}
              </div>
            </td>
          </tr>
          <tr v-if="visibleItems.specialProps">
            <td colspan="8">
              <table class="table table-bordered table-hover table-striped">
                <thead>
                  <tr>
                    <td
                      v-for="prop in selectedSpecialProps"
                      :key="prop"
                    >
                      {{ prop }}
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      v-for="prop in selectedSpecialProps"
                      :key="prop"
                    >
                      {{ projection.projectionsInCurrentYear[0][prop] }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>          
        </tbody>
      </table>
    </div>

    <InAppCalculator ref="inAppCalculator" />
  </div>
</template>

<script>

import getProjectionsByQuarter from '@/business-logic/services/projections/getProjectionsByQuarter'
import getProjectionsByYear from '@/business-logic/services/projections/getProjectionsByYear'
import InAppCalculator from './InAppCalculator.vue'
export default {
  name: 'ProjectionsPlayground',
  components: { InAppCalculator },
  data() {
    return {
      studyDataId: '12300',
      study_data: null,
      building_stock_units: 0,
      jurisdictions: [],
      jurisdictionSelected: null,
      projectionsByQuarter: [],
      projectionsByYear: [],

      visibleItems: {
        quarters: true,
        assumptions: false,
        specialProps: false,
      },
      specialProps: {
        mechanicsYearQuarter: true,
        installationsStarted: true,
        baselineInstallationsStarted: true,
        netInstallationsStarted: true,
        installationsCompleted: true,
        cummulativeInstallationsCompleted: true,
        installationsAgedOut: true,
        cummulativeInstallationsAgedOut: true,
        activeInstallations: true,
        baselineActiveInstallations: true,
        netActiveInstallations: true,
        thermsSaved: true,
        ghgSavingsFromTherms: true,
        ghgSavingsFromKwh: true,
        ghgSavings: true,
        kwhSaved: true,
        gridRenewableShare: true,
        kwhEmissionsFactor: true,
        cumulativeGhgSavings: true,
        billSavings: true,
        complianceCost: true,
        
        virtualFirstInstallationsComplete: true,
        virtualLastInstallationsComplete: true,
        baseUnitChanged: false,
        baselineInstallationsStartedPercentage: false,
        baselineInstallationsCompleted: false,
        baselineInstallationsCompletedPercentage: false,
        netInstallationsCompleted: false,
        zeroPctRnwKwhEmissionsFactor: false,
        startYearGridRenewableLevel: false,
        gridRenewableShareChangePerYear: false,
      }
    }
  },
  computed: {
    getSumTotal() {
      return this.valuesToSum.reduce((acc, item) => acc + item.value, 0)
    },
    selectedSpecialProps() {
      return Object.entries(this.specialProps).filter(val => val[1]).map(entry => entry[0])
    }
  },

  methods: {
    onJurisdictionSelected() {
      this.loadStudyData()
    },
    async getJurisdictions() {
      const query = `
        {
          jurisdictions(
            whereHas: [
              { 
                relationship: "climate_zones", 
                items: [
                  { column: "raw", operation: "=", value: "${this.study_data.climate_zone_raw}"}
                ]
              }
            ]
          ) {
            id
            title
            title_type
            climate_zones {
              raw
            }
          }
        }
      `
      const { data } = await this.$graphql({ query })
      this.jurisdictions = data.jurisdictions
    },

    loadStudyData() {
      const query = `
        {
          study_data (
            findBy: { column: "id", value: "${this.studyDataId}" }
          ) {
            id
            study_row_number
            study_id
            prototype_id
            measure_id
            vintage_id
            fuel_id
            climate_zone_raw
            baseline_fuel_type
            initial_cost
            kwh_savings
            therms_savings
            annual_bill_savings
            simple_payback
            tdv_benefit_to_cost_ratio
            on_bill_cost_ratio
            emissions_savings
            emissions_savings_pct
            compliance_margin
            lifecycle_savings

            vintage { id title type_vintage_id }
            prototype { id title type_prototype_id }
            measure { id title }
            fuel { id title }
            climate_zone { id prefix raw}
          }
        }
      `

      this.$graphql({ query })
        .then(({ data }) => {
          this.study_data = data.study_data[0]

          const selectedJurisdictionsClimateZones = this.jurisdictionSelected?.climate_zones.map(cz => cz.raw) ?? []
          
          if (!selectedJurisdictionsClimateZones.includes(this.study_data?.climate_zone_raw)) {
            this.jurisdictionSelected = null
            this.projectionsByYear = []
            this.getJurisdictions()
          } else {
            this.getBuildingStock()
          }

        })
    },
    getBuildingStock() {
      if (!this.jurisdictionSelected) return 
      
      const query = `
        {
          building_stock (
            where: [
              { column: "jurisdiction_id", operation: "=", value: "${ this.jurisdictionSelected.id }" }
              { column: "climate_zone_prefix", operation: "=", value: "${ this.study_data.climate_zone.prefix }" }
              { column: "type_prototype_id", operation: "=", value: "${ this.study_data.prototype.type_prototype_id }" }
              { column: "type_vintage_id", operation: "=", value: "${ this.study_data.vintage.type_vintage_id }" }
            ]
          ) {
            units
          }
        }
      `

      this.$graphql({ query })
        .then(({ data }) => {
          this.building_stock_units = data.building_stock.units
          this.generateProjectionsByQuarter()
          this.generateProjectionsByYear()
        })
    },

    generateProjectionsByQuarter() {
      const projectionsByQuarter = getProjectionsByQuarter({ 
        assumption: this.$store.getters['forecast/getterAssumption'],
        building_stock_units: this.building_stock_units,
        kwh_savings: this.study_data.kwh_savings,
        therms_savings: this.study_data.therms_savings,
        annual_bill_savings: this.study_data.annual_bill_savings,
        initial_cost: this.study_data.initial_cost
      })
      this.projectionsByQuarter = projectionsByQuarter
    },
    
    generateProjectionsByYear() {
      const projectionsByYear = getProjectionsByYear({ 
        assumption: this.$store.getters['forecast/getterAssumption'] ,
        building_stock_units: this.building_stock_units,
        kwh_savings: this.study_data.kwh_savings,
        therms_savings: this.study_data.therms_savings,
        annual_bill_savings: this.study_data.annual_bill_savings,
        initial_cost: this.study_data.initial_cost
      })
      this.projectionsByYear = projectionsByYear
    },

    getProjectionsInYear(year) {
      return this.projectionsByQuarter.filter((item) => item.year == year)
    },

    addValueToSum(value, label) {
      this.$refs.inAppCalculator.addValueToSum(value, label)
    },

    openAssumptionsDrawer() {
      this.$eventBus.$emit('openAssumptionsSidebar', { 
        assumptions: this.$store.state.forecast.assumptions, 
        onSave : this.onSaveAssumptions
      })
    },
    onSaveAssumptions({ assumptions }) {      
      this.$store.commit('forecast/updateAssumptions', assumptions)
      this.projectionsByYear = []
      this.loadStudyData()
    },
    
  }
}
</script>

<style lang="scss" scoped>
  .table-wrapper {
    height: 500px;
    overflow: scroll;
  }

</style>