<template>
  <div class="w-full flex items-center justify-center h-screen py-6 overflow-y-auto block p-3">
    <div class="login-card-w rounded">
      <div class="apply-card bg-white flex flex-wrap justify-center">
        <UserSignForm
          @loginSuccess="onSuccess"
          @register-success="onSuccess"
        /> 
      </div>
    </div>
  </div>
</template>

<script>
import UserSignForm from './UserSignForm.vue'
export default {
  name: 'SignIn',
  components: { UserSignForm },
  methods: {
    onSuccess() {
      this.$router.push('/policies')
    }
  }
}
</script>