<template>
  <div>
    <p class="jurisdiction psui-text-p text-accent-2 uppercase color psui-text-blue-60 absolute top-0 left-0 pt-6">
      {{ lastJurisdictionVisited.title_type }}
    </p>
    <h1 class="text-center mt-6 psui-text-h5 font-bold psui-text-gray-80">
      Save custom building data to a policy
    </h1>
    <h2 class="psui-text-gray-50 psui-text-small text-center mt-3 mb-6">
      Policy impact forecasts will reflect the updated building data you provided.
    </h2>

    <div class="table-container">
      <table class="table">
        <thead>
          <tr>
            <th class="psui-text-gray-80 psui-text-small font-bold">
              Policy name
            </th>
            <th class="psui-text-gray-80 psui-text-small font-bold">
              Last updated
            </th>
            <th class="psui-text-gray-80 psui-text-small font-bold">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="policy in getterUserPoliciesByJurisdiction"
            :key="policy.id"
          >
            <td class="psui-text-gray-60 psui-text-small">
              {{ policy.title }}
            </td>
            <td class="psui-text-gray-60 psui-text-small">
              {{ formatDate({ date: policy.updated_at}) }}
            </td>
            <td>
              <div class="flex space-x-2 justify-center relative has-icon">
                <div 
                  v-if="loadings[policy.id] === true"
                  class="loader"
                />
                <PsIcon
                  v-if="loadings[policy.id] === undefined && isPolicyWithCurrentCustomData(policy)"
                  icon="done"
                  color="psui-text-green-20"
                  class="inline h-7 icon-center"
                />
                <PsButton
                  v-if="isPolicyWithCurrentCustomData(policy) || !isPolicyWithCustomData(policy)"
                  :disabled="loadings[policy.id] === true || isPolicyWithCurrentCustomData(policy)"
                  label="Save"
                  size="small"
                  class="pl-4 pr-4 inline"
                  @click="save(policy)"
                />
                <PsButton
                  v-else
                  label="Update"
                  size="small"
                  class="pl-4 pr-4 inline"
                  :disabled="loadings[policy.id] === true"
                  @click="save(policy)"
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="footer flex space-x-4 justify-between mt-6 items-center">
      <div>
        <h3 class="psui-text-gray-50 psui-text-small font-bold">
          You may also <span
            class="inline-block ml-1 psui-text-blue-60 cursor-pointer"
            @click="$emit('force_wizard')"
          >Create a new policy</span>
        </h3>
      </div>
      <div>
        <PsButton 
          label="Finish and close"
          size="medium"
          @click="$eventBus.$emit('closeModalAssumptions')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DataAndObjectFunctions from '@/util/DataAndObjectFunctions'
export default {
  name: 'AssumptionsModalSaveToPolicy',
  props: ['opened_from'],
  data: () => ({
    loadings: {}
  }),
  computed: {
    ...mapGetters(['lastJurisdictionVisited']),
    ...mapGetters('policy', ['getterUserPoliciesByJurisdiction']),
    getCurrentCustomBuildingStock() {
      const policy_id = (this.$route.params.policy_id) ? this.$route.params.policy_id : null
      return this.getAllCustomBuildingStockByExistingOrNew.filter((cbs) => cbs.policy_id == policy_id )
    },
    getAllCustomBuildingStockByExistingOrNew() {
      return (this.opened_from === 'EXISTING' ? [] :
        this.$store.state.assumptions.buildingStocks.custom_building_stocks)
    },
  },
  methods: {
    getPolicyCustomData(policy) {
      return (this.opened_from === 'EXISTING' ? [] :
          this.$store.getters['assumptions/buildingStocks/getterCustomBuildingStockByPolicyId'](policy.id))
    },
    isPolicyWithCustomData(policy) {
      return this.getPolicyCustomData(policy).length > 0
    },
    isPolicyWithCurrentCustomData(policy) {
      const policyCustomData = this.getPolicyCustomData(policy)
      // console.log(policyCustomData)
      const checkData = () => {
        let allCustomDataIsSame = true

        this.getCurrentCustomBuildingStock.forEach((custom_building_stock) => {

          const cbs_keys_to_filter = ['climate_zone_prefix', 'jurisdiction_id', 'type_prototype_id', 'type_vintage_id', 'units']
          const filters = DataAndObjectFunctions.filterObjectKeys(cbs_keys_to_filter, custom_building_stock)
          // console.log(filters)

          if (!policyCustomData.findInArray(filters)) {
            allCustomDataIsSame = false
          }

        })
        return allCustomDataIsSame
      }
      return this.getCurrentCustomBuildingStock.length === policyCustomData.length && checkData()
    },
    save(policy) {
      this.loadings[policy.id] = true
      this.$forceUpdate()
      this.$store.dispatch('assumptions/buildingStocks/addCustomBuildingStocksToPolicy', {
        current_custom_building_stocks: this.getCurrentCustomBuildingStock,
        policy_id: policy.id
      }).then(() => {
        setTimeout(() => {
          delete this.loadings[policy.id]
          this.$forceUpdate()
        }, 1000)
      })
    },
  },
}
</script>

<style lang="scss" scoped>

$border-color: rgba(0, 70, 95, 0.1);

.table-container {
  width: 100% !important;
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: calc(100vh - 9rem - 205px);
  position: relative;
  border-radius: 10px;
  border: 1px solid $border-color;

  .table {
    margin-bottom: 0 !important;

    thead {
      background: white;
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      border: none !important;

      tr {
        border: none !important;

        th {
          border: none !important;
          border-bottom: none !important;
          box-shadow: inset 0 -1px 0 $border-color;
          padding-top: 15px;
          padding-bottom: 15px;

          &:first-of-type {
            padding-left: 1rem;
          }

          &:nth-child(2) {
            width: 120px;
          }

          &:last-of-type {
            padding-right: 1rem;
            text-align: center !important;
            width: 150px !important;
          }
        }
      }
    }

    tbody {
      border-left: 2rem solid transparent;
      border-right: 2rem solid transparent;

      tr {
        td {
          border-top: none !important;
          border-bottom: 1px solid $border-color;
          background-color: #F3F6F9;
          vertical-align: middle;

          &:first-of-type {
            padding-left: 0;
          }

          &:last-of-type {
            padding-right: 0;
          }
        }

        &:last-of-type {
          td {
            border-bottom: none !important;
          }
        }
      }
    }
  }
}

.jurisdiction {
  font-weight: 700;
  padding-left: 2.5rem;
}

.footer {
  margin-top: 2.5rem;
}

.loader {
  border: 2px solid $border-color;
  border-top: 2px solid #318fac;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.icon-center::v-deep, .loader {
  position: absolute;
  justify-content: center;
  left: -5px;
  top: 4px;
  display: block !important;

  span {
    display: flex;
    align-items: center;
    font-size: 18px;
  }

  &.icon-center {
    width: 100%;
  }
}

td > div > .psui-el-button::v-deep {
  min-width: 70px;
  margin-left: 0;
}
</style>
