import GraphQL from '@/util/GraphQL'

export default {
  namespaced: true,
  
  state: {
    study_groups: []
  },

  getters: {
    getterGlobalStudyGroups(state) {
      return state.study_groups
    },

  },

  actions: {
    loadGlobalStudyGroups(context) {
      const query = `
        {
          study_groups (
            orderBy: [{ column: "order", order: "ASC" }]
          ) {
            id
            title
            order
            study_type_id
          }
        }
      `
      GraphQL({ query })
        .then(({ data }) => {
          context.commit('setGlobalStudyGroups', { study_groups: data.study_groups})
        })
    }
  },

  mutations: {
    setGlobalStudyGroups(state, { study_groups }) {
      state.study_groups = study_groups
    }
  }
}