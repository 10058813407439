import GraphQL from '@/util/GraphQL'

export default {
  namespaced: true,
  
  state: {
    studies: []
  },

  getters: {
    getterGlobalStudies(state) {
      return state.studies
    },

  },

  actions: {
    loadGlobalStudies(context) {
      const query = `
        {
          studies (
            orderBy: [{ column: "order", order: "ASC" }]
          ) {
            id
            title
            order
            study_group_id
          }
        }
      `
      GraphQL({ query })
        .then(({ data }) => {
          context.commit('setGlobalStudies', { studies: data.studies})
        })
    }
  },

  mutations: {
    setGlobalStudies(state, { studies }) {
      state.studies = studies
    }
  }
}