import AdminStudyMapList from '@/modules/admin/study_map/AdminStudyMapList'
import AdminStudyMapForm from '@/modules/admin/study_map/AdminStudyMapForm'
import baseAdminMeta from '@/modules/admin/baseAdminMeta'

export default [
  {
    name: 'AdminStudyMapList',
    path: 'study_map',
    component: AdminStudyMapList,
    meta: { ...baseAdminMeta, title: 'Study maps' }
  },
  {
    name: 'AdminStudyMapCreate',
    path: 'study_map/create',
    component: AdminStudyMapForm,
    meta: { ...baseAdminMeta, title: 'Study map create' }
  },
  {
    name: 'AdminStudyMapEdit',
    path: 'study_map/:study_map_id/edit',
    component: AdminStudyMapForm,
    meta: { ...baseAdminMeta, title: 'Study map edit' }
  }
]