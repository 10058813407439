<template>
  <div class="flex flex-col mt-4">
    <!-- <div 
      v-if="filters.year" 
      class="w-full flex justify-between items-center mb-4"
    >
      <p class="psui-text-small psui-text-gray-60">
        Forecasted units: <span class="font-semi-bold">{{ formatUnits(getBuildingEstimatesUnitsBasedOnYear()) }}</span>
      </p>
    </div> -->
    <template v-if="getAllBuildingStockUnits !== null">
      <div
        v-if="!getIsFilteringByYear && getDrawerPath.slug !== path.NON_RESIDENTIAL_BUILDINGS.slug"
        class="w-full"
      >     
        <AssumptionsBuildingStocksInput 
          :is-input-with-edit-icon="true"
          label="Existing Units"
          :filters="getBuildingStockFilters"
        />
      </div>
      <div 
        v-if="getDrawerPath.slug !== path.NON_RESIDENTIAL_BUILDINGS.slug"
        class="w-full flex justify-between items-center mb-2"
      >
        <p class="psui-text-small psui-text-gray-60">
          Annual growth rate
        </p>
        <PsInput
          id="ps-input-annual-growth"
          class="building-estimates-input"
          layout="mini"
          :active="isNotDefaultBuildingEstimates"
          :value="getGrowthRate"
          @input="onInputGrowthRate"
          @change="onChangeGrowthRate"
          @mouseenter="onMouseEnter"
          @mouseleave="onMouseLeave"
        >
          <template v-slot:append>
            <PsTooltip
              v-if="showResetButtonAnnualGrowth"
              class="stock-input"
            >
              <template v-slot:trigger>
                <i
                  class="material-icons  psui-text-gray-40 psui-cursor-pointer hover:psui-text-blue-60 " 
                  @click="resetToDefault"
                >
                  restart_alt
                </i>
              </template>
              <template v-slot:content>
                Reset to default
              </template>
            </PsTooltip>
            <i 
              v-else
              class="material-icons-round"
            >percent</i>
          </template>
        </PsInput>
      </div>
    </template>  
    <div class="w-full flex justify-between items-center">
      <p class="psui-text-small psui-text-gray-60">
        {{ unitMeasurement }}
      </p>
      <PsInput
        id="ps-input-units"
        class="building-estimates-input"
        layout="mini"
        :active="isNotDefaultBuildingEstimates"
        :value="getAllBuildingEstimatesUnitsFormated"
        @input="onInputBuildingEstimates"
        @change.prevent="onChangeBuildingEstimatesUnits"
        @mouseenter="onMouseEnter"
        @mouseleave="onMouseLeave"
      >
        <template
          v-slot:append
        >
          <PsTooltip
            v-if="showResetButton"
            class="stock-input"
          >
            <template v-slot:trigger>
              <i
                class="material-icons psui-text-gray-40 psui-cursor-pointer" 
                @click="resetToDefault"
              >
                restart_alt
              </i>
            </template>
            <template v-slot:content>
              Reset to default
            </template>
          </PsTooltip>
          <span
            v-else
            class="psui-absolute psui-text-xsmall psui-text-gray-40 psui-cursor-text psui-right-0 psui-width-0 psui-height-0 psui-not-italic psui-pr-2"
          >
            {{ unitMeasurement == 'Square feet' ? 'ft&sup2;' : unitMeasurement }}
          </span>
        </template>
      </PsInput>
    </div>
    <!-- <pre>{{getGrowthRate}}</pre> -->
    <!-- <pre class="bg-red">{{buildingStocks}}</pre> -->
    <!-- <pre>{{buildingEstimates}}</pre> -->
    <!-- <pre>{{getAllBuildingStockUnits}}</pre> -->
  </div>
</template>

<script>
import AssumptionsBuildingStocksInput from './AssumptionsBuildingStocksInput.vue'
import { BUILDING_ESTIMATES_BASE_YEARS, ASSUMPTIONS_PATHS } from '@/modules/app/assumptions/shared/enums'
import { MathHelpers } from '@igortrindade/lazyfy'
export default {
  name: 'AssumptionsBuildingEstimatesInput',
  components: { AssumptionsBuildingStocksInput },
  props: {
    unitMeasurement: {
      type: String,
      default: 'Units'
    },
    filters: {
      type: Object,
      required: true,
    },
    jurisdiction: {
      type: Object
    }
  },
  data(){
    return {
      unitsRaw: false,
      growthRateRaw: false,
      isEditing: false,
      years: BUILDING_ESTIMATES_BASE_YEARS,
      path: ASSUMPTIONS_PATHS,
      isHovering:{"ps-input-annual-growth": false, "ps-input-units": false}
    }
  },
  computed: {
    getIsFilteringByYear() {
      return this.filters?.year && !Array.isArray(this.filters.year)
    },
    getTypePrototypeIds() {
      const type_prototypes_ids = Array.isArray(this.filters.type_prototype_id) ? this.filters.type_prototype_id : [this.filters.type_prototype_id]
      return this.$store.getters['globalEntities/TypePrototype/getterGlobalTypePrototypes']
        .filter((type_prototype) => type_prototypes_ids.includes(type_prototype.id))
        .map(type_prototype => type_prototype.id)
    },
    getBuildingStockFilters() {
      const filters = { 
        type_prototype_id: this.getTypePrototypeIds 
      }
      if(this.filters.climate_zone_prefix) {
        filters.climate_zone_prefix = this.filters.climate_zone_prefix
      }
      return filters
    },
    getAllBuildingStocks() {
      return this.$store.getters['assumptions/buildingStocks/getterAllBuildingStocks'](this.getBuildingStockFilters)
    },
    getAllBuildingStockUnits() {
      return this.$store.getters['assumptions/buildingStocks/getterAllBuildingStocksUnits'](this.getBuildingStockFilters)
    },
    getAllBuildingEstimates() {
      return this.$store.getters['assumptions/buildingEstimates/getterAllBuildingEstimates'](this.filters)
    },
    getAllBuildingEstimatesUnits() {
      return this.$store.getters['assumptions/buildingEstimates/getterAllBuildingEstimateUnits'](this.filters)
    },
    getGrowthRate() {
      let baseValue = this.getBuildingEstimatesUnitsBasedOnYear()
      let total = this.filters.year ? this.getAllBuildingEstimatesUnits : this.getAllBuildingEstimatesUnits / 3 // 3 year code cycle
      return MathHelpers.getPercentageOfAmount(baseValue, total, true)
    },
    getAllBuildingEstimatesUnitsFormated(){
      return this.vueNumberFormat(this.unitsRaw, { prefix: '', acceptNegative: false, isInteger: false, precision: 0 })
    },
    getDrawerPath(){
      return this.$store.state.assumptions.drawerOptions.path
    },
    unitsFormated() {
      return this.vueNumberFormat(this.unitsRaw, { prefix: '', acceptNegative: false, isInteger: false, precision: 0 })
    },
    isNotDefaultBuildingEstimates() {
      return this.$store.getters['assumptions/buildingEstimates/getterCustomJurisdictionBuildingEstimates'](this.filters).length > 0
    },
    showResetButton() {
      return this.isNotDefaultBuildingEstimates && this.isHovering["ps-input-units"]
    },
    showResetButtonAnnualGrowth() {
      return this.isNotDefaultBuildingEstimates && this.isHovering["ps-input-annual-growth"]
    },
    getPolicyId() {
      return (this.$route.params.policy_id) ? this.$route.params.policy_id : null
    },

  },
  watch:{

    getAllBuildingEstimatesUnits: {      
      handler(value) {
        this.unitsRaw = value 
      },
      immediate: true
    },
  },
  methods: {
    
    resetToDefault() {
      this.$store.dispatch('assumptions/buildingEstimates/clearCustomBuildingEstimates', this.getAllBuildingEstimates)
      this.isHovering["ps-input-annual-growth"] = false
      this.isHovering["ps-input-units"] = false
    },

    onChangeBuildingStockUnits(e) {
      this.$store.dispatch('assumptions/buildingStocks/addOrUpdateCustomBuildingStocks', { to: e.target.value, from: this.buildingStockUnits, custom_building_stocks: this.getAllBuildingStocks })
    },

    onChangeBuildingEstimatesUnits() {
      const to = this.unitsRaw
      const from = this.getAllBuildingEstimatesUnits
      this.$store.dispatch('assumptions/buildingEstimates/addOrUpdateCustomBuildingEstimates', { custom_building_estimates: this.getAllBuildingEstimates, to, from })
    },

    onInputBuildingEstimates($event) {
      this.unitsRaw = parseFloat($event.target.value.replace(/\D+/g, '')) || 0
    },

    onInputGrowthRate($event) {
      this.growthRateRaw = parseFloat($event.target.value)
      console.log(this.growthRateRaw)
    },
    onChangeGrowthRate() {
      this.unitsRaw = MathHelpers.getAmountOfPercentage(this.getBuildingEstimatesUnitsBasedOnYear(), this.growthRateRaw)
      this.onChangeBuildingEstimatesUnits()
    },
    onMouseEnter(event){
      if(event.srcElement.firstElementChild.id === "ps-input-units") this.isHovering["ps-input-units"] = true 
      if(event.srcElement.firstElementChild.id === "ps-input-annual-growth") this.isHovering["ps-input-annual-growth"] = true 
    },
    onMouseLeave(event){
      if(event.srcElement.firstElementChild.id === "ps-input-units") this.isHovering["ps-input-units"] = false 
      if(event.srcElement.firstElementChild.id === "ps-input-annual-growth") this.isHovering["ps-input-annual-growth"] = false 
    },

    getBuildingEstimatesUnitsBasedOnYear(currentYear = null) {
      const filters = { ...this.filters }
      let baseValue = this.getAllBuildingStockUnits
      let year = currentYear || this.filters.year
      if(year) {
        year = [ ...this.years ].splice(0, this.years.indexOf(year))
        filters.year = year
        const aggregatedBuildingEstimateUnits = this.$store.getters['assumptions/buildingEstimates/getterAllBuildingEstimateUnits'](filters)
        baseValue += aggregatedBuildingEstimateUnits
      }
      return baseValue
    }

  },
}
</script>

<style lang='scss' scoped>
input {
  max-width: 100px;
}

.building-estimates-input {
  i {
    font-size: 12px;
    right: 8px;
    line-height: 120%;
    color: #798490;
  }
}

  ::v-deep .psui-el-tooltip.stock-input {
    .psui-el-tooltip-wrapper {
      .psui-el-tooltip-dialog {
        min-width: 20px;
        width: fit-content;
      }
    }
  }

</style>
