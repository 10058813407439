<template>
  <div class="flex justify-between items-center mb-1 mt-7">
    <h2 class="font-bold psui-text-gray-80">
      Climate Zone
    </h2>
    <div>
      <PsToggle 
        :items="climate_zones.map(i => i.prefix)"
        :selected="climate_zone_prefix"
        @change="updateClimateZonePrefix"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AssumptionsBuildingEstimatesClimateZones',
  props: {
    climate_zones: Array,
    climate_zone_prefix: [Number, Boolean],
    filters: Object
  },
  methods: {
    updateClimateZonePrefix(prefix) {
      if(typeof(prefix) == 'number') {
        this.$emit('setClimateZonePrefix', prefix)
      } else {
        throw new Error(`updateClimateZonePrefix should pass prefix as parameter`)
      }
    }
  }
}
</script>

<style>
/* ::-webkit-scrollbar {
  display: none;
} */
</style>