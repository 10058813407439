<template>
  <Dropdown 
    ref="dropdownEl"
    button-classes="dynamic"
    :toggle-when-active="false"
    :dropdown-dialog-classes="''"
    :class="{ 'different-from-base-value' : baseValue !== 'undefined' && value !== baseValue}"
    @click.native="onDropdownElClick"
  >
    <template v-slot:buttonLabel>
      {{ buttonLabel ? buttonLabel : placeholder }}
    </template>
    <template v-slot:buttonLabelOnShow>
      <div
        class="wrapper"
        :style="wrapperStyles"
      >
        <input
          ref="inputEl"
          v-model="input"
          v-autowidth="{minWidth: '50px', comfortZone: 5}"
          v-tooltip="getTooltip"
          type="number"
          class="text-center appearance-none bg-transparent"
          :placeholder="getPlaceholder"
          :min="minValue" 
          :max="maxValue"
          @blur.self="onBlurInput($event)"
          @keyup.enter="$refs.dropdownEl.close()"
        >
      </div>
    </template>
    <template v-slot:items>
      <InputRangeSlider 
        :min="minValue" 
        :max="maxValue" 
        :value="value"
        :step="step"
        :base-value="baseValue"
        @update:value="$emit('update:value', $event)"
      />
    </template>
  </Dropdown>
</template>

<script>
import { defaultManualTooltipOptions } from '@/util/Helpers'
import { randomString, getParentScrollableEl } from '@/util/Functions'

export default {
  name: 'DropdownWithInputNumber',
  props: {
    value: {
      type: Number,
      required: true
    },
    placeholder: {
      type: String,
    },
    suffix: {
      type: String,
    },
    minValue: {
      type: Number
    },
    maxValue: {
      type: Number
    },
    buttonLabel: {
      type: String
    },
    step: {
      type: Number,
      default: 1
    },
    baseValue: {
      type: [Number, Boolean]
    }
  },
  data: () => ({
    input : '',
    scrollableParentEl : null,
    tooltipKey : false
  }),
  computed: {
    errorMessage() {
      if (
        (this.input && this.minValue && this.input < this.minValue) ||
        (this.input && this.maxValue && this.input > this.maxValue)
      ) {                      
        return `Choose a value between ${this.minValue} and ${this.maxValue}.`
      } else {
        return false
      }
    },
    getPlaceholder() {
      return this.placeholder ? this.placeholder : this.value
    },
    getTooltip() {
      let options = defaultManualTooltipOptions
      // Only a key to reforce update the tooltip when the parent element scrolls
      options.tooltipKey = this.tooltipKey

      if (this.errorMessage) {
        options.content = this.errorMessage
      } else if (!this.input) {
        options.content = 'Type-in a value or use the slider to change'
      } else {
        options.content = false
      }

      return options.content ? options : false
    },

    wrapperStyles() {
      let styles = {}
      if (this.suffix) {
        styles['--suffix'] = '"' + this.suffix + '"'
      }
      return styles
    }
  },
  methods: {

    watchParentScrolling() {
      this.scrollableParentEl = getParentScrollableEl(this.$refs.inputEl)
      if (this.scrollableParentEl) {
        this.scrollableParentEl.addEventListener('scroll', this.updateTooltipPosition)
      }
    },
    unwatchParentScrolling() {
      if (this.scrollableParentEl) {
        this.scrollableParentEl.removeEventListener('scroll', this.updateTooltipPosition)
      }
    },
    updateTooltipPosition() {
      this.tooltipKey = randomString(8)
    },
    onDropdownElClick() {
      const dropdownIsOpen = this.$refs.dropdownEl.show
      if (dropdownIsOpen) {
        this.$refs.inputEl.focus()
        this.watchParentScrolling()
      } else {
        this.unwatchParentScrolling()
        this.input = ''
        setTimeout(() => {
          this.$refs.inputEl.focus()
        }, 300)
      }
    },

    onBlurInput(event) {
      
      if (this.errorMessage) {
        this.$appToast({ type: 'info', message: this.errorMessage, duration: 5000 })
        this.input = ''
        this.$refs.inputEl.focus()
      } else if ( this.input && this.input != 0 ) {
        this.$emit('update:value', Number(this.input))
        this.$refs.dropdownEl.close()
        this.input = ''
      }

      if (!event.relatedTarget) {
        this.$emit('updateValue')
      }
    },
      
  }
}
</script>

<style lang="scss" scoped>
  .wrapper::after {
    content: var(--suffix);
  }
</style>