import { find, findIndex } from '@/util/Functions'

import toggleColumnIsActive from '@/business-logic/services/columns/toggleColumnIsActive'
import moveColumnItem from '@/business-logic/services/columns/moveColumnItem'
import moveColumnGroup from '@/business-logic/services/columns/moveColumnGroup'

export default {
  
  openPolicy(state, { policy }) {
    const policyFinded = find(state.policiesOpened, { id: policy.id })
    if(!policyFinded) {
      policy.timestamp = Math.round(new Date().getTime()/1000)
      state.policiesOpened.push(policy)
    } else {
      policyFinded.timestamp = Math.round(new Date().getTime()/1000)
    }
  },

  closePolicy(state, { index, id }) {
    if(typeof index != 'undefined') {
      state.policiesOpened.splice(index, 1)
    } else {
      const findedIndex = findIndex(state.policiesOpened, { id })
      if(findedIndex > -1) state.policiesOpened.splice(findedIndex, 1)
    }
  },

  clearPoliciesOpened(state) {
    state.policiesOpened = []
  },

  setUserPolicies(state, policies) {
    state.userPolicies = policies
  },

  setUserSharedPolicies(state, shared_policies) {
    state.userSharedPolicies = shared_policies
  },
  
  toggleHiddenItem(state, item) {
    const index = state.hiddenItems.indexOf(item)
    if(index > -1) {
      state.hiddenItems.splice(index, 1)
    } else {
      state.hiddenItems.push(item)
    }
  },

  toggleColumnIsActive,

  moveColumnItem,
  
  moveColumnGroup,
  
}