import GraphQL from '@/util/GraphQL'

export default {
  namespaced: true,
  
  state: {
    type_vintages: []
  },

  getters: {
    getterGlobalTypeVintages(state) {
      return state.type_vintages
    }
  },

  actions: {
    loadGlobalTypeVintages(context) {
      const query = `
        {
          type_vintages (
            orderBy: [{ column: "order", order: "asc"}]
          ) {
            id
            title
            slug
            color
            title_long
            flexible_path_title
          }
        }
      `
      GraphQL({ query })
        .then(({ data }) => {
          context.commit('setGlobalTypeVintages', { type_vintages: data.type_vintages})
        })
    }
  },

  mutations: {
    setGlobalTypeVintages(state, { type_vintages }) {
      state.type_vintages = type_vintages
    }
  }
}