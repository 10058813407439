import EventBus from '@/util/EventBus'
export default (Store) => {
  Store.subscribe((mutation) => {
    const mutationsToWatch = [
      'assumptions/clearImpactAssumptions',
      'assumptions/updateAssumptionsKeyValue',
      'assumptions/buildingStocks/setCustomBuildingStocks',
      'assumptions/buildingStocks/clearCustomBuildingStocks',
    ]
    if(mutationsToWatch.includes(mutation.type)) {
      EventBus.$emit('policyUpdated')
    }   
  })
}