<template>
  <div class="w-full flex items-center flex-wrap mb-5 px-4 sm:px-10">
    <div class="flex-shrink-0 flex items-center mr-auto mr-auto pr-8">
      <template v-if="getPolicySelected && getPolicySelected.title">
        <h2 class="text-header-4 font-bold text-deepsky-900">
          {{ getPolicySelected.title }}
        </h2>          
        <h2 class="text-header-4 font-medium text-ash-500 ml-2">
          {{ getPolicySelected.jurisdiction.type }} of {{ getPolicySelected.jurisdiction.title }}
        </h2>
      </template>
      <vue-skeleton-loader
        v-else
        :width="Math.round((Math.random() * 100) + 150)"
        :height="18"
      />
    </div>

    <div class="flex items-center space-x-8">
      <button
        class="text-header-2 flex items-center transition-all hover:opacity-70"
        @click="$emit('onOpenAssumptionsSidebar')"
      >
        <feather
          type="sliders"
          class="text-deepsky-500"
        />
        <span class="ml-3 text-header-2 text-deepsky-900">Policy Assumptions</span>
      </button>  
      <button
        class="text-header-2 flex items-center transition-all hover:opacity-70"
        @click="copyLink()"
      >
        <feather
          type="share-2"
          class="text-deepsky-500"
        />
        <span class="ml-3 text-header-2 text-deepsky-900">Share Policy</span>
      </button>     
    </div>
  </div>
</template>

<script>
import { copyUrlToClipboard } from '@/util/Helpers'
export default {
  name: 'PolicyShowHeader',
  props: ['getPolicySelected'],
  methods: {
    copyLink() {
      copyUrlToClipboard()
      this.$appToast({ message: 'Link copied to clipboard!' })
    },
  }
}
</script>