import PolicyApiService from '@/services/api/PolicyApiService'
import ImpactAssumptions from '@/models/ImpactAssumptions'
let waitingToUpdatePolicy, waitingToUpdateAssumptions

export default { 

  initBuildingsData({ dispatch, rootGetters }) {
    if(rootGetters['lastJurisdictionVisited']?.id) {
      dispatch('assumptions/buildingStocks/getJurisdictionBuildingStocks', rootGetters['lastJurisdictionVisited']?.id, { root: true })
      dispatch('assumptions/buildingEstimates/getJurisdictionBuildingEstimates', rootGetters['lastJurisdictionVisited']?.id, { root: true })
    }
  },

  resetCustomBuildings({ dispatch }) {
    dispatch('buildingStocks/clearCustomBuildingStocks')
    dispatch('buildingEstimates/clearCustomBuildingEstimates')
  },

  storeBatchAllCustomBuildings({ dispatch }) {
    console.log('Starting storeBatchAllCustomBuildings')
    return Promise.all([
      dispatch('assumptions/buildingStocks/storeBatchCustomBuildingStocks', null, { root: true }),
      dispatch('assumptions/buildingEstimates/storeBatchCustomBuildingEstimates', null, { root: true })
    ])
    .then(res => res.map(r => r.data))
    .catch((error) => {
      console.log(error)
    })
  },

  getAllCustomBuildings({ dispatch }) {
    console.log('Starting getAllCustomBuildings')
    return Promise.all([
      dispatch('assumptions/buildingEstimates/getCustomBuildingEstimates', null, { root: true }),
      dispatch('assumptions/buildingStocks/getCustomBuildingStocks', null, { root: true })
    ])
  },

  getPolicy({ commit, dispatch }, policy_id) {
    return PolicyApiService.get(policy_id)
      .then(({ policy }) => {
        commit('setPolicyImpactAssumptions', policy)
        commit('policy/openPolicy', { policy: { id: policy.id, title: policy.title, user_id : policy.user_id } }, { root: true })
        dispatch('setUserLastJurisdictionAndFirstClimateZoneVisited', { value: policy.jurisdiction, context: 'updateLastJurisdictionVisitedFromPoliciesShow' }, { root: true })
      })
  },

  clearImpactAssumptions({ commit, state, getters }) {
    
    if (getters['getterIsEditingPolicyAssumptions']) {      
      if(getters['getterUserCanEditTheSelectedPolicy']) {
        PolicyApiService.update(state.policy_id_selected, new ImpactAssumptions())
      }
      commit('clearPolicyImpactAssumptions')
    }
    commit('clearResultsImpactAssumptions')
  },

  updateAssumptionsKeyValue({ state, commit, getters }, { key, value }) {
    
    if ( getters['getterIsEditingPolicyAssumptions'] && getters['getterUserCanEditTheSelectedPolicy']) {
      clearTimeout(waitingToUpdatePolicy)
      waitingToUpdatePolicy = setTimeout(() => {
        PolicyApiService.update(state.policy_id_selected, state.policyImpactAssumptions)
      }, 1000)
    }
    
    clearTimeout(waitingToUpdateAssumptions)
    waitingToUpdateAssumptions = setTimeout(() => {
      commit('updateAssumptionsKeyValue', { key, value })
    }, 200)

  },
  
}