<template>
  <div class="w-full p-10 h-auto">
    <h1>Components</h1>

    <AppTabHeader 
      :selected.sync="tab"
      class="mb-10"
      :items="['ProgressBarValue', 'AppChartPie', 'JurisdictionInput', 'AppInfoHelper']"
    />
    
    <template v-if="tab === 'ProgressBarValue'">
      <h2>BarValue</h2>
      <div :style="{ height: '25px' }">
        <ProgressBarValue
          :value="35"
          :max-value="100"
          label="35%"
        />
      </div>
      <br>

      <h2>BarValue with parent height</h2>
      <div :style="{ height: '25px' }">
        <ProgressBarValue
          :value="56601951"
          :max-value="56601951"
          label="$56,601,951"
        />
      </div>
      <br>

      <h2>BarValue with different colors</h2>
      <div :style="{ height: '25px' }">
        <ProgressBarValue
          :value="35"
          :max-value="100"
          bar-class="bg-sunrise-200"
          label-class="text-sunrise-800"
          label="35%"
        />
      </div>
      <br>
    </template>
    
    <template v-else-if="tab === 'AppChartPie'">
      <h2>AppChartPie</h2>
      <div :style="{ width: '49px', height: '49px' }">
        <AppChartPie
          :slices="[{ value: 10, color: '#4B8CA6'}]"
          :is-donut="true"
          background-color="#E3EEF2"
          text="50%"
        />
      </div>
      <br>
    </template>
    
    <template v-else-if="tab === 'AppSwitcher'">
      <h2>AppSwitcher with Label</h2>
      <AppSwitcher
        :items="['First', 'Second', 'Third', 'Fourth']"
        :selected="appSwitcherSeleted.value"
        label="What is the position?"
      />
      <br>
      
      <h2>AppSwitcher without Label, with Objects</h2>
      <AppSwitcher
        :items="[{ label: 'First', value: 'first'}, {label: 'Second', value: 'second'}, { label: 'Third', value: 'third' }]"
        :selected.sync="appSwitcherSeleted"
        label="What is the position?"
        class="pt-2"
      />
      <br>
    </template>
  
    <template v-else-if="tab === 'JurisdictionInput'">
      <h2>JurisdictionSelector</h2>
      <JurisdictionInput
        :jurisdiction.sync="jurisdiction"
        input-class="border-b-2 border-ash-300"
      />
      <br>
    </template>
    
    <template v-else-if="tab === 'AppInfoHelper'">
      <AppInfoHelper @icon-click="onAppInfoHelperClick"> 
        <h2>Left not visible</h2>
      </AppInfoHelper>
      <br>
      
      <AppInfoHelper :absolute="true"> 
        <h2>Left not visible and absolute</h2>
      </AppInfoHelper>
      <br>
      
      <AppInfoHelper :visible="true"> 
        <h2>Left visible</h2>
      </AppInfoHelper>
      <br>
      
      <AppInfoHelper
        :visible="true"
        :absolute="true"
      > 
        <h2>Left visible</h2>
      </AppInfoHelper>
      <br>
      
      <div class="flex flex-col">
        <AppInfoHelper
          position="right"
          class="mr-auto"
        > 
          <h2>Right not visible</h2>
        </AppInfoHelper>
        <br>
        <AppInfoHelper
          position="right"
          :visible="true"
          class="mr-auto"
        > 
          <h2>Right visible</h2>
        </AppInfoHelper>
        <br>
      </div>
    </template>
  </div>
</template>

<script>
import AppSwitcher from '@/components/general/AppSwitcher.vue'
import JurisdictionInput from '@/components/general/JurisdictionInput.vue'
import AppChartPie from '@/components/charts/AppChartPie.vue'
import ProgressBarValue from '@/components/general/ProgressBarValue.vue'
export default {
  name: 'ComponentsIndex',
  components: { 
    ProgressBarValue, 
    AppChartPie, 
    JurisdictionInput,
    AppSwitcher
  },
  data: () => ({
    jurisdiction : false,
    appSwitcherSeleted: { label: 'First', value: 'first' },
    tab: 'AppInfoHelper', 
  }),
  methods: {
    onAppInfoHelperClick() {
      window.alert('onAppInfoHelperClick')
    }
  }
}
</script>

<style>

</style>