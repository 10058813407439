<template>
  <div class="w-full mt-6 grid grid-cols-2 gap-4 xl:grid-cols-1 xl:gap-0">
    <button 
      v-for="index in 2"
      :key="index"
      class="w-full flex items-center space-x-4 xl:my-2 p-3 border-b-4 xl:border-b-0 xl:border-r-4 xl:rounded-l-md transition duration-300 bg-ash-200 text-ash-500 border-ash-300 hover:bg-deepsky-100 hover:text-deepsky-450 hover:border-deepsky-450"
    >
      <div class="p-3 rounded-full block xl:hidden 2xl:block bg-ash-200">
        <vue-skeleton-loader
          :size="48"
          type="circle"
        />
      </div>
      <h3 class="text-header-3 font-bold">
        <vue-skeleton-loader
          :width="148"
          :height="24"
        />
      </h3>
    </button>
  </div>
</template>

<script>
export default {
  name: 'SkeletonTypePrototypeList'
}
</script>

<style>

</style>