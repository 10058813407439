<template>
  <div class="psui-w-full psui-flex psui-flex-col psui-p-6">
    <VueSelect 
      v-model="jurisdiction.type"
      class="vue-select"
      placeholder="Select a type"
      :class="jurisdiction.type ? 'filled' : ''"
      label="type_content"
      :options="jurisdictionTypeEnum"
    />

    <InputWithLabel
      v-model="jurisdiction.title"
      class="mt-6"
      :label="'Title'"
    />

    <InputWithLabel
      v-model="jurisdiction.city"
      class="mt-6"
      :label="'City'"
    />

    <InputWithLabel
      v-model="jurisdiction.county"
      class="mt-6"
      :label="'County'"
    />

    <VueSelect 
      v-model="jurisdiction.climate_zones"
      class="vue-select mt-6"
      placeholder="Select climate zones"
      :class="jurisdiction.climate_zones.length ? 'filled' : ''"
      label="raw"
      :options="climate_zones"
      :multiple="true"
    />

    <hr class="border-b border-gray05 my-6">
    <div>
      <PsButton
        v-if="!$route.params.jurisdiction_id"
        label="Create"
        icon="add"
        size="big"
        @click="store()"
      /> 
      <PsButton
        v-else
        label="Update"
        icon="update"
        size="big"
        @click="update()"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AdminJurisdictionForm',
  data() {
    return {
      jurisdictionTypeEnum: ['City', 'County'],
      jurisdiction: {
        id: '',
        title: '',
        type: '',
        city: '',
        county: '',
        slug: '',
        content: '',
        climate_zones: []
      },
      climate_zones: []
    }
  },
  mounted() {
    this.getClimateZones()
    if(this.$route.params.jurisdiction_id) {
      this.getJurisdiction()
    }
  },
  methods: {
    
    async getClimateZones() {
      const query = `
        {
          climate_zones {
            id
            raw
            prefix
          }
        }
      `
      const { data } = await this.$graphql({ query })

      this.climate_zones = data.climate_zones
    },
    async getJurisdiction() {
      const query = `
        {
          jurisdiction (
            where: [
              { column: "id", operation: "=", value: "${this.$route.params.jurisdiction_id}"}
            ]
          ){
            id
            title
            type
            city
            county
            slug
            created_at
            climate_zones {
              id
              raw
            }
          }
        }
      `
      const { data } = await this.$graphql({ query })

      this.jurisdiction = data.jurisdiction
    },

    store() {
      this.$api.post(`/jurisdiction`, this.jurisdiction, { loader: true })
        .then(() => {
          this.$toast.success('Jurisdiction successfully created')
          this.$router.push({ name: 'AdminJurisdictionList' })
        })
    },

    update() {
      this.$api.put(`/jurisdiction/${this.jurisdiction.id}`, this.jurisdiction, { loader: true })
        .then(() => {
          this.$toast.success('Jurisdiction successfully updated')
          this.$router.push({ name: 'AdminJurisdictionList' })
        })
    }
  }
}
</script>
