<template>
  <div class="py-6 pl-2">
    <div
      v-if="!isLoading"
      class="flex"
    >
      <div class="flex-none">        
        <AppTabHeader
          :items="tabs"
          :selected="tabSelected"
          class="mb-8 mr-8 flex-col text-header-3 text-ash-700"
          notification-class="absolute top-1/2 transform -translate-y-1/2 left-0 rounded-full text-header-1 bg-red flex items-center justify-center w-4 h-4 text-white -ml-3 -mt-2"
          @change="selectTab"
        />
      </div>

      <div class="flex-auto">
        <template v-if="tabSelected">
          <AdminStudyForm v-show="tabSelected.value === 'study_info'" />
          <AdminStudyGroupStudyFlexiblePaths v-show="tabSelected.value === 'flexiblepaths'" />
          
          <div
            v-for="collectionTab in getCollectionTabs"
            :key="collectionTab.key"
            class="w-full flex flex-col"
          >
            <template v-if="tabSelected.key == collectionTab.key">              
              <ul
                v-if="tabSelected.errors.length"
                class="list-inside w-full bg-red rounded p-4 px-6 flex flex-col text-white mb-4 max-h-1/4 overflow-y-auto"
              >
                <li
                  v-for="error in tabSelected.errors"
                  :key="error"
                  class="my-1"
                >
                  {{ error }}
                </li>
              </ul>
              <AdminStudyItemTable 
                :type="collectionTab.key" 
                :title="collectionTab.label" 
                :items="study[collectionTab.collection]" 
                :show-study-column="false" 
                @getItems="getStudy"
              />              
            </template>
          </div>
        </template>
      </div>
    </div>
    <div v-else>
      <h3 class="text-header-4 font-bold">
        Loading Study...
      </h3>
    </div>
  </div>
</template>

<script>
import AdminStudyGroupStudyFlexiblePaths from './AdminStudyGroupStudyFlexiblePaths.vue'
import AdminStudyForm from '../study/AdminStudyForm.vue'
import AdminStudyItemTable from '@/modules/admin/study_item/AdminStudyItemTable'
import Study from '@/models/Study'
import { find } from '@/util/Functions'

export default {
  name: 'AdminStudyGroupStudy',
  components: { AdminStudyItemTable, AdminStudyForm, AdminStudyGroupStudyFlexiblePaths },
  data() {
    return {
      study: new Study(),
      tabs: [
        { label: 'Study Info', value: 'study_info', notifications: 0, errors: [] },
        { label: 'Prototypes', value: 'prototypes', notifications: 0, errors: [], key: 'prototype', collection: 'prototypes' },
        { label: 'Vintages', value: 'vintages', notifications: 0, errors: [], key: 'vintage', collection: 'vintages' },
        { label: 'Measures', value: 'measures', notifications: 0, errors: [], key: 'measure', collection: 'measures' },
        { label: 'Fuels', value: 'fuels', notifications: 0, errors: [], key: 'fuel', collection: 'fuels' },
        { label: 'Flexible Paths', value: 'flexiblepaths', notifications: 0, errors: [] },
      ],
      tabSelected: { label: 'Flexible Paths', value: 'flexiblepaths', notifications: 0, errors: [] },
      isLoading : true
    }
  },
  computed: {
    getCollectionTabs() {
      return this.tabs.filter((item) => item.key)
    }
  },
  mounted() {
    if(this.$route.params.study_id) {
      this.getStudy()
    }
    const tabSelected = this.tabs.find((item) => item.value == this.$route.params.tabSelected ) 
    if (tabSelected) this.tabSelected = tabSelected

  },
  methods: {

    selectTab(item) {
      this.tabSelected = item
      // this.$router.push({ ...this.$route, params: { ...this.$route.params, tabSelected: item.value }})
    },
    async getStudy() {
      
      this.isLoading = true

      const query = `
        {
          study (
            where: [
              { column: "id", operation: "=", value: "${this.$route.params.study_id}"}
            ]
          ){
            id
            title
            content
            type_id
            type_content
            source_pdf
            source_pdf_update
            version
            version_updated_at
            version_updated_changes
            version_updated_expected_at
            spreadsheet_id
            worksheet_name
            impact_period
            created_at
            is_private
            prototypes(orderBy: [{column: "title", order: "asc"}]) { id title slug description content title_abbreviated icon type_prototype_id type_prototype { id title } }
            vintages(orderBy: [{column: "title", order: "asc"}]) { id title slug description content title_long type_vintage_id type_vintage { id title } }
            measures(orderBy: [{column: "order", order: "asc"}]) { id title slug description content include_in_combination is_package exclude_in_combination_reason type_measure_id order hide_in_flexible_path type_measures { id title } }
            fuels(orderBy: [{column: "title", order: "asc"}]) { id title slug description content type_fuel_id type_fuel { id title } }
          }
        }
      `
      const { data } = await this.$graphql({ query })
      this.study = {...data.study}
      
      this.checkStudyErrors()

      this.isLoading = false
    },

    update() {
      this.$api.put(`/studies/${this.study.id}`, this.study, { loader: true })
        .then(() => {
          this.$toast.success('Study updated')
          this.$router.push({ name: 'AdminStudyList' })
        })
    },

    updateStudyData() {
      const formData = new FormData()
      formData.append('study_csv', this.studyDatumFile)

      this.$api.post(`/studies/${this.study.id}/study-data`, formData, {
        loader: true,
        headers: { 'Content-Type': 'multipart/form-data;' }
      }).then(() => {
        this.$toast.success('Study data updated')
        this.studyDatumFile = new File(["foo"], "", { type: "text/plain" })
      })
      .catch(() => {
        this.$toast.error('Error on update study data file')
      })
    },

    checkStudyErrors() {

      // Check prototypes
      this.study.prototypes.forEach((prototype) => {
        const itemTab = find(this.tabs, { key: 'prototype' })
        const attrs = ['title', 'content', 'description', 'slug', 'title_abbreviated', 'icon', 'type_prototype_id']
        attrs.map((attr) => {
          if(!prototype[attr]) {
            itemTab.notifications++
            itemTab.errors.push(`${prototype.title} doesnt have ${attr}`)
          }
        })
      })

      // Check vintages
      this.study.vintages.forEach((vintage) => {
        const itemTab = find(this.tabs, { key: 'vintage' })
        const attrs = ['title', 'content', 'description', 'slug', 'title_long', 'type_vintage_id']
        attrs.map((attr) => {
          if(!vintage[attr]) {
            itemTab.notifications++
            itemTab.errors.push(`${vintage.title} doesnt have ${attr}`)
          }
        })
      })

      // Check Measures
      this.study.measures.forEach((measure) => {
        const itemTab = find(this.tabs, { key: 'measure' })
        const attrs = ['title', 'content', 'description', 'slug', 'type_measure_id']
        attrs.map((attr) => {
          if(!measure[attr]) {
            itemTab.notifications++
            itemTab.errors.push(`${measure.title} doesnt have ${attr}`)
          }
        })
        if(!measure.include_in_combination && !measure.exclude_in_combination_reason) {
          itemTab.notifications++
          itemTab.errors.push(`${measure.title} cannot be combined and should have have reason properly filled`)
        }
      })

      // Check fuels
      this.study.fuels.forEach((fuel) => {
        const itemTab = find(this.tabs, { key: 'fuel' })
        const attrs = ['title', 'content', 'description', 'slug', 'type_fuel_id']
        attrs.map((attr) => {
          if(!fuel[attr]) {
            itemTab.notifications++
            itemTab.errors.push(`${fuel.title} doesnt have ${attr}`)
          }
        })
      })

    }
  }
}
</script>