export default {
  '1-PGE':	'1-PGE',
  '2-PGE':	'2-PGE',
  '3-PGE':	'3-PGE',
  '4-PGE':	'4-PGE',
  '4-CPAU':	'4-PGE',
  '5-PGE':	'5-PGE',
  '5-SCG':	'5-PGE',
  '6-SCE':	'6-SCE',
  '6-LADWP':	'6-SCE',
  '7-SDGE':	'7-SDGE',
  '8-SCE':	'8-SCE',
  '8-LADWP':	'8-SCE',
  '9-SCE':	'9-SCE',
  '9-LADWP':	'9-SCE',
  '10-SCE':	'10-SCE',
  '10-SDGE':	'10-SCE',
  '11-PGE':	'11-PGE',
  '12-PGE':	'12-PGE',
  '12-SMUD':	'12-PGE',
  '13-PGE':	'13-PGE',
  '14-SCE':	'14-SCE',
  '14-SDGE':	'14-SCE',
  '15-SCE':	'15-SCE',
  '16-PGE':	'16-PGE',
  '16-LADWP':	'16-PGE',
  '01':	'1-PGE',
  '02':	'2-PGE',
  '03':	'3-PGE',
  '04':	'4-PGE',
  '05':	'5-PGE',
  '06':	'6-SCE',
  '07':	'7-SDGE',
  '08':	'8-SCE',
  '09':	'9-SCE',
  '10':	'10-SCE',
  '11':	'12-PGE',
  '12':	'12-PGE',
  '13':	'13-PGE',
  '14':	'14-SCE',
  '15':	'15-SCE',
  '16':	'16-PGE',
}