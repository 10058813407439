
import formatEmissionsSavings from './formatEmissionsSavings'
import formatSimplePayback from './formatSimplePayback'
import formatCurrency from './formatCurrency'
import formatNumber from './formatNumber'

export default (col, value, data) => {

  if(typeof value == 'undefined') return value
  if(col == 'emissions_savings') return formatEmissionsSavings(value, data)

  if(col == 'simple_payback') return formatSimplePayback(value, data)

  if([
    'initial_cost',
    'annual_bill_savings',
    'annual_bill_savings_avg',
    'lifecycle_savings',
    'forecast_initial_cost',
    'forecast_lifecycle_savings'
  ].includes(col)) {
    return formatCurrency(value)
  }
  
  if([
    'forecast_emissions_savings',
    'forecast_emissions_savings_pct',
    'forecast_kwh_savings',
    'forecast_therms_savings',
    'emissions_savings_pct',
    'forecast_units_affected',
    'kwh_savings',
    'therms_savings',
    'tdv_benefit_to_cost_ratio',
    'compliance_margin',
    'on_bill_cost_ratio',
    'tdv2022_benefit_to_cost_ratio',
    'energy_savings_combined',
  ].includes(col)) {
    return formatNumber(value)
  }

  if([
    'forecast_units',
    'units',
    'units_affected',
  ].includes(col)) {
    return new Intl.NumberFormat().format(value)
  }

  // if(col == 'tdv2022_benefit_to_cost_ratio') {
  //   // if(value == 0) return '--'
  //   return formatNumber(value)
  // }

  return value
}