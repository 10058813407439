import { render, staticRenderFns } from "./StudyGroupVersionsTimelineModal.vue?vue&type=template&id=8359072e&scoped=true&"
import script from "./StudyGroupVersionsTimelineModal.vue?vue&type=script&lang=js&"
export * from "./StudyGroupVersionsTimelineModal.vue?vue&type=script&lang=js&"
import style0 from "./StudyGroupVersionsTimelineModal.vue?vue&type=style&index=0&id=8359072e&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8359072e",
  null
  
)

export default component.exports