<template>
  <BaseModal
    ref="baseModal"
    :show-modal-parent.sync="showModalParent"
    modal-dialog-classes="w-auto max-w-full"
  >
    <UserSignForm
      v-if="showModalParent"
      ref="userSignForm"
      @success="onLoginSuccess"
      @close="close"
    />
  </BaseModal>
</template>

<script>
import UserSignForm from '@/modules/app/login/components/UserSignForm.vue'
export default {
  name: 'ModalUserSign',
  components: { UserSignForm },
  data() {
    return {
      callback: null,
      showModalParent: false
    }
  },
  mounted() {
    this.$eventBus.$on('openModalUserSign', ({ callback = null } = {}) => {
      this.showModalParent = true
      this.callback = callback
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('openModalUserSign')
  },
  methods: {
    onLoginSuccess() {
      this.showModalParent = false
      if(this.callback) this.callback()
    },
    close() {
      this.showModalParent = false
    }
  }
}
</script>