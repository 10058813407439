
import { getStudyColumnsBySelectedStatus } from '@/util/Functions'

export default {
  
  getterStudyResultsColumns: (state, getters, rootState, rootGetters) => {
    const columns = { ... state.studyResultsColumns}
    const jurisdictionType = rootGetters['lastJurisdictionVisited']?.type
    if (jurisdictionType) {
      const cityWideColumnGroup = columns.existingBuildings.columnGroups.find((columnGroup) => columnGroup.key === 'city_wide_impact')
      cityWideColumnGroup.title = `${jurisdictionType}-wide Estimates`
      if(jurisdictionType == 'City') {
        cityWideColumnGroup.hasHelper = {
          type: 'helper',
          id: 25
        }
      } else {
        cityWideColumnGroup.hasHelper = {
          type: 'helper',
          id: 26
        }
      }
    }
    return columns
  },
  getterExistingBuildingsColumns: (state, getters) => getters.getterStudyResultsColumns['existingBuildings'],
  getterExistingBuildingsColumnsSelected(state, getters) {
    return getStudyColumnsBySelectedStatus(getters.getterStudyResultsColumns['existingBuildings'], true)
  },
  getterExistingBuildingsColumnsUnselected(state, getters) {
    return getStudyColumnsBySelectedStatus(getters.getterStudyResultsColumns['existingBuildings'], false)
  },

  getterNewBuildingsColumns: (state, getters) => getters.getterStudyResultsColumns['newBuildings'],
  getterNewBuildingsColumnsSelected(state, getters) {
    return getStudyColumnsBySelectedStatus(getters.getterStudyResultsColumns['newBuildings'], true)
  },
  getterNewBuildingsColumnsUnselected(state, getters) {
    return getStudyColumnsBySelectedStatus(getters.getterStudyResultsColumns['newBuildings'], false)
  }

}