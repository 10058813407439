<template>
  <div class="flex items-center">
    <div class="mr-3 ts--accent--2 text-gray02 whitespace-no-wrap">
      Climate Zone:
    </div>
    <div
      v-if="climate_zones.length > 0"
      class="flex flex-nowrap font-medium text-gray02"
    >
      <Checkbox
        v-for="climate_zone in climate_zones"
        :key="`dropdown-climate_zone-${climate_zone.prefix}`"
        class="border border-deepsky-200 px-2 rounded-sm mr-2 h-8"
        :label="`${climate_zone.prefix}`"
        :value="climate_zone.raw == $route.params.climate_zone_raw"
        checkbox-classes="mb-0"
        type="radio"
        @input="setClimateZone(climate_zone)"
      />
    </div>
    <div
      v-else
      class="flex space-x-2"
    >
      <vue-skeleton-loader
        :width="30"
        :height="30"
        :rounded="true"
      />
      <vue-skeleton-loader
        :width="30"
        :height="30"
        :rounded="true"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'FilterClimateZone',
  computed: {
    climate_zones() {
      const { climate_zones } = this.$store.getters['lastJurisdictionVisited']
      return climate_zones ? [...climate_zones] : false
    }
  },
  methods: {
    setClimateZone(climate_zone) {
      this.$store.dispatch('setUserPreferences', { payload: { path: 'lastClimateZonesVisited', value: [climate_zone ]}})
      this.pushToParams('climate_zone_raw', climate_zone.raw)
    }
  }
}
</script>

<style>

</style>