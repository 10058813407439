import Vue from 'vue'

import PolicyStudioUiVue from '@policystudio/policy-studio-ui-vue'


/**
 * Shared components
 */
import StateProgramReferral from '@/components/general/StateProgramReferral'
import StateProgramReferralText from '@/components/general/StateProgramReferralText'
import AppLoader from '@/components/general/AppLoader'
import AppAlert from '@/components/general/AppAlert'
import AppSwitcher from '@/components/general/AppSwitcher'
import AppButton from '@/components/general/AppButton'
import AppTitleWithIcon from '@/components/general/AppTitleWithIcon'

import ButtonAnchor from '@/components/general/ButtonAnchor'
import InputWithLabel from '@/components/general/InputWithLabel'
import InputRangeSlider from '@/components/general/InputRangeSlider'
import NumberFormatInput from '@/components/general/NumberFormatInput'
import TextAreaWithLabel from '@/components/general/TextAreaWithLabel'
import Checkbox from '@/components/general/Checkbox'
import CheckboxSelectAll from '@/components/general/CheckboxSelectAll'
import BooleanLabel from '@/components/general/BooleanLabel'
import Paginator from '@/components/general/Paginator'
import ContentEditor from '@/components/general/ContentEditor'
import InputFile from '@/components/general/InputFile'
import PageLoader from '@/components/general/PageLoader'
import Dropdown from '@/components/general/Dropdown'
import DropdownWithInputNumber from '@/components/general/DropdownWithInputNumber'
import DropdownMenuList from '@/components/general/DropdownMenuList'
import RadioButton from '@/components/general/RadioButton'
import LocalEnergyLogo from '@/components/general/LocalEnergyLogo'
import JurisdictionSelect from '@/components/general/JurisdictionSelect'
import NativeSelectInput from '@/components/general/NativeSelectInput'
import SimpleProgressBar from '@/components/general/SimpleProgressBar'
import RenderHtml from '@/components/general/RenderHtml'
import Toggle from '@/components/general/Toggle'
import ToolTip from '@/components/general/ToolTip'
import AppCardButton from '@/components/general/AppCardButton'
import AppTabHeader from '@/components/general/AppTabHeader'
import DragAndDropEditColumnsList from '@/components/general/DragAndDropEditColumnsList'
import AppLoginGoogle from '@/modules/app/login/components/AppLoginGoogle'
import OrderByArrowToggle from '@/components/general/OrderByArrowToggle'
import DebugPre from '@/components/general/DebugPre'
import AppInfoHelper from '@/components/general/AppInfoHelper'
import ApexChartGeneral from '@/components/charts/ApexChartGeneral'
import PageTitle from '@/components/general/PageTitle'

import BaseLayoutWithHeaderAndSideBar from '@/layout/BaseLayoutWithHeaderAndSideBar'
import ModalUserSign from '@/modules/app/login/components/ModalUserSign'
import JurisdictionInput from '@/components/general/JurisdictionInput'
import BaseTutorial from '@/components/tutorial/BaseTutorial'
import TutorialItem from '@/components/tutorial/TutorialItem'

import BaseDrawer from '@/layout/BaseDrawer'
import BaseModal from '@/layout/BaseModal'
import DrawerContent from '@/components/general/DrawerContent'
import DescriptionModal from '@/components/modal/DescriptionModal'
import LoadingModal from '@/components/modal/LoadingModal'
import BaseDrawerLeft from '@/layout/BaseDrawerLeft'


/**
 * Vendors
 */
import VShowSlide from 'v-show-slide'
import VTooltipPlugin from 'v-tooltip'
import VueToast from 'vue-toast-notification'
import VueFeather from 'vue-feather'
import VueSelect from 'vue-select'
import VueTheMask from 'vue-the-mask'
import VueMeta from 'vue-meta'
import { ResizeObserver } from 'vue-resize'

import VueNumberFormat from 'vue-number-format'
import dayjs from 'dayjs'
import InlineSvg from 'vue-inline-svg'
import VueSkeletonLoader from 'skeleton-loader-vue'

const isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
const isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)

/**
 * Directives
 */
import { moneyDirective, numberDirective, helperDirective} from '@/directives'
import '@/util/Prototypes'
import '@/util/ArrayPrototypeHelpers'
import VueInputAutowidth from 'vue-input-autowidth'


/**
 * Mixins
 */
import GeneralMixin from '@/mixins/GeneralMixin'
import GaEventsMixin from '@/mixins/GaEventsMixin'
import ValidationMixin from '@/mixins/ValidationMixin'
import FiltersMixin from '@/mixins/FiltersMixin'
import DragAndDropMixin from '@/mixins/DragAndDropMixin'
import RouterHelperMixin from '@/mixins/RouterHelperMixin'
import GetComponentNameMixin from '@/mixins/GetComponentNameMixin'

export default (() => {

  Vue.use(PolicyStudioUiVue)
  
  /**
   * General components
   */
  Vue.component('ButtonAnchor', ButtonAnchor)
  Vue.component('InputWithLabel', InputWithLabel)
  Vue.component('NumberFormatInput', NumberFormatInput)
  Vue.component('AppButton', AppButton)
  Vue.component('AppSwitcher', AppSwitcher)
  Vue.component('AppAlert', AppAlert)
  Vue.component('AppLoader', AppLoader)
  Vue.component('AppTitleWithIcon', AppTitleWithIcon)
  Vue.component('Checkbox', Checkbox)
  Vue.component('CheckboxSelectAll', CheckboxSelectAll)
  Vue.component('BooleanLabel', BooleanLabel)
  Vue.component('Paginator', Paginator)
  Vue.component('TextAreaWithLabel', TextAreaWithLabel)
  Vue.component('ContentEditor', ContentEditor)
  Vue.component('InputFile', InputFile)
  Vue.component('InputRangeSlider', InputRangeSlider)
  Vue.component('PageLoader', PageLoader)
  Vue.component('Dropdown', Dropdown)
  Vue.component('DropdownWithInputNumber', DropdownWithInputNumber)
  Vue.component('DropdownMenuList', DropdownMenuList)
  Vue.component('RadioButton', RadioButton)
  Vue.component('Toggle', Toggle)
  Vue.component('ToolTip', ToolTip)
  Vue.component('NativeSelectInput', NativeSelectInput)
  Vue.component('SimpleProgressBar', SimpleProgressBar)
  Vue.component('RenderHtml', RenderHtml)
  Vue.component('AppCardButton', AppCardButton)
  Vue.component('AppTabHeader', AppTabHeader)
  Vue.component('DragAndDropEditColumnsList', DragAndDropEditColumnsList)
  Vue.component('AppLoginGoogle', AppLoginGoogle)
  Vue.component('OrderByArrowToggle', OrderByArrowToggle)
  Vue.component('DebugPre', DebugPre)
  Vue.component('AppInfoHelper', AppInfoHelper)
  Vue.component('ApexChartGeneral', ApexChartGeneral)
  Vue.component('PageTitle', PageTitle)
  

  /***
   * App Components
   */
  
  Vue.component('StateProgramReferral', StateProgramReferral)
  Vue.component('StateProgramReferralText', StateProgramReferralText)
  Vue.component('LocalEnergyLogo', LocalEnergyLogo)
  Vue.component('JurisdictionSelect', JurisdictionSelect)
  Vue.component('BaseLayoutWithHeaderAndSideBar', BaseLayoutWithHeaderAndSideBar)
  Vue.component('ModalUserSign', ModalUserSign)
  Vue.component('JurisdictionInput', JurisdictionInput)
  Vue.component('BaseTutorial', BaseTutorial)
  Vue.component('TutorialItem', TutorialItem)

  /**
   * Base layout components
   */
  Vue.component('BaseModal', BaseModal)
  Vue.component('BaseDrawer', BaseDrawer)
  Vue.component('DrawerContent', DrawerContent)
  Vue.component('DescriptionModal', DescriptionModal)
  Vue.component('LoadingModal', LoadingModal)
  Vue.component('BaseDrawerLeft', BaseDrawerLeft)

  
  /**
   * Vendor
   */
  Vue.component('VueSelect', VueSelect)
  Vue.use(VShowSlide)
  Vue.use(VTooltipPlugin)
  Vue.use(VueToast, { position: 'top', duration: 5500 })
  Vue.use(VueMeta)
  Vue.component('inline-svg', InlineSvg)
  Vue.component('vue-skeleton-loader', VueSkeletonLoader)
  
  let myResizeObserver = ResizeObserver
  myResizeObserver.props.emitOnMount.default = true
  Vue.component('resize-observer', myResizeObserver)

  Vue.prototype.$appToast = function (params) {
    const { anchor, message } = params 
    if (anchor && message) {
      params.message += `<a class="v-toast__anchor ts--accent--book--2" href="${anchor.href}">${anchor.title}</a>`
    }
    const instance = Vue.$toast.open(params)
    
    return instance
  }
  
  Vue.use(VueFeather)
  Vue.use(VueTheMask)
  Vue.use(VueNumberFormat, { prefix: 'US$ ', decimal: '.', thousand: ',' })
  
  
  
  /**
   * Directives
   */
  Vue.use(VueInputAutowidth)
  Vue.directive('money', moneyDirective)
  Vue.directive('number', numberDirective)
  Vue.directive('helper', helperDirective)
  Vue.directive('click-outside', {
    bind: function (el, binding, vnode) {
      el.clickOutsideEvent = function (event) {

        // here I check that click was outside the el and his children
        if (!(el == event.target || el.contains(event.target))) {
          // and if it did, call method provided in attribute value
          vnode.context[binding.expression](event)
        }
      }
      setTimeout(() => {
        document.body.addEventListener('click', el.clickOutsideEvent)
      }, 100)
    },
    unbind: function (el) {
      document.body.removeEventListener('click', el.clickOutsideEvent)
    },
  })

  /**
   * Mixins
   */
  Vue.mixin(GeneralMixin)
  Vue.mixin(GaEventsMixin)
  Vue.mixin(ValidationMixin)
  Vue.mixin(FiltersMixin)
  Vue.mixin(DragAndDropMixin)
  Vue.mixin(RouterHelperMixin)
  Vue.mixin(GetComponentNameMixin)
  
})()
