<template>
  <div class="w-full p-6">
    <div class="w-full flex justify-end">
      <PsButton
        class="psui-mr-3"
        label="Sync jurisdiction and climate zones"
        icon="sync"
        size="big"
        @click="sync()"
      />
      <router-link
        :to="{ name: 'AdminJurisdictionCreate' }"
      >
        <PsButton 
          label="Create"
          icon="add"
          size="big"
        />
      </router-link>
    </div>

    <InputWithLabel
      v-model="search"
      class="mt-6"
      :label="'Search jurisdictions by title'"
      @keydown="getJurisdictions({ offset: 0 })"
    />

    <div class="w-full overflow-x-auto">
      <table class="table table-bordered mt-6 whitespace-no-wrap">
        <thead>
          <tr>
            <th>ID</th>
            <th>Title</th>
            <th>City</th>
            <th>County</th>
            <th>Climate Zones</th>
            <th>Created at</th>
            <th class="psui-w-32">
              Edit
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="jurisdiction in jurisdictions"
            :key="jurisdiction.id"
          >
            <td>{{ jurisdiction.id }}</td>
            <td>{{ jurisdiction.title }}</td>
            <td>{{ jurisdiction.city }}</td>
            <td>{{ jurisdiction.county }}</td>
            <td>
              <router-link
                v-for="climate_zone in jurisdiction.climate_zones"
                :key="`jurisdiction-cz-${climate_zone.id}`"
                class="font-bold text-blue02"
                :to="{ name: 'AdminClimateZoneEdit', params: { climate_zone_id: climate_zone.id}}"
              >
                {{ climate_zone.raw }}
              </router-link>
            </td>
            <td>{{ jurisdiction.created_at }}</td>
            <td class="psui-flex">
              <router-link 
                :to="{ name: 'AdminJurisdictionEdit', params: { jurisdiction_id: jurisdiction.id}}" 
              >
                <PsButton 
                  label="Edit" 
                  size="medium"
                  icon="edit"
                />
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <Paginator
      v-model="offset"
      :length="jurisdictions.length"
      :limit="limit"
      @getItems="getJurisdictions({ offset })"
    />
  </div>
</template>

<script>
export default {
  name: 'AdminJurisdictionList',
  data() {
    return {
      search: '',
      jurisdictions: [],
      offset: 0,
      limit: 12,
    }
  },
  mounted() {
    this.getJurisdictions()
  },
  methods: {
    async getJurisdictions({ offset = 0 } = {}) {
      this.offset = offset
      const query = `
        {
          jurisdictions(
            where: [{column: "title", operation: "ILIKE", value: "%${this.search}%"}], 
            orderBy: [{column: "id", order: "asc"}], 
            paginate: { offset: ${offset}, limit: ${this.limit}}
          ) {
            id
            title
            city
            county
            created_at
            climate_zones {
              id
              raw
            }
          }
        }
      `
      const { data } = await this.$graphql({ query })

      this.jurisdictions = data.jurisdictions
    },
    sync() {
      this.$api.get('/jurisdiction/sync')
        .then(() => {
          this.$toast.success('Sync jurisdiction and climate zone done')
        })
    }
  }
}
</script>