<template>
  <Dropdown
    ref="dropdown"
    :button-classes="'px-2 bg-transparent w-full'"
    :dropdown-dialog-classes="'left-0 bg-white'"
  >
    <template v-slot:buttonLabel>            
      <feather
        type="more-horizontal"
        size="14"
        class="text-ash-400 mr-1"
      />
    </template>
    <template v-slot:items>
      <DropdownMenuList 
        :items="dropdownItems" 
        @update:selected="onUpdateSelected"
      />
    </template>
  </Dropdown>
</template>

<script>

import { copyToClipboard } from '@/util/Helpers'

export default {
  name: 'DropdownPolicyActions',
  props: {
    userCanEditPolicy : {
      type: Boolean,
      default: false
    },
    policy: {
      type: Object,
      required: true
    },
    actions: {
      type: Array,
      default: () => (['Edit', 'Share', 'Duplicate', 'Delete'])
    },
    shouldRedirectAfterPolicyEdit: {
      default: true
    }
  },
  computed: {
    dropdownItems() {
      return this.userCanEditPolicy ? this.actions : this.actions.filter((item) => item !== 'Edit')
    },
  },
  methods: {
    onUpdateSelected(value) {

      switch (value) {
        case 'Edit':
          this.$eventBus.$emit('openModalPolicyCreateEdit', this.policy.id, this.shouldRedirectAfterPolicyEdit)
          break
      
        case 'Share':
          copyToClipboard(window.location.origin + '/policies/' + this.policy.id)
          this.$toast.success('Policy url copied to clipboard!')
          break
      
        case 'Duplicate':
          this.$eventBus.$emit('openDuplicatePolicyModal', { policy: this.policy })
          break
      
        case 'Delete':
          this.$eventBus.$emit('openModalDeletePolicy', { policy: this.policy })
          break      
      }

      this.$refs.dropdown.close()
    }, // onUpdateSelected
  }
}
</script>

<style>

</style>