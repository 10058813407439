<template>
  <div
    v-if="study_group"
    class="p-6"
  >
    <InputWithLabel
      v-model="study_group.title"
      class="mt-6"
      :label="'Title'"
    />
    
    <InputWithLabel
      v-model="study_group.forecast_filters_source_study_title"
      class="mt-6"
      :label="'Forecast Source Study Filter Title'"
    />

    <div class="w-full relative mt-6">
      <VueSelect 
        v-model="study_group.studies"
        class="vue-select"
        placeholder="Select studies related with this study group"
        label="title"
        :options="studies"
        :multiple="true"
      />
      <label>Studies</label>
    </div>

    <div class="w-full relative mt-6">
      <VueSelect 
        v-model="study_group.study_type_id"
        class="vue-select"
        placeholder="Select a filter to group searchs"
        :class="study_group.study_type_id ? 'filled' : ''"
        label="title"
        :options="study_types"
        :reduce="item => item.id"
      />
      <label>Study type</label>
    </div>

    <hr class="border-b border-gray05 my-6">

    <div
      v-if="study_group.hasError"
      v-html="study_group.validationPhraseHtml"
    />
    
    <!-- <PsButton
      v-if="!$route.params.study_group_id"
      :disabled="study_group.hasError"
      label="Create"
      icon="add"
      size="big"
      class="psui-mt-6 psui-justify-center"
      @click="store()"
    />   
    <PsButton
      v-else
      :disabled="study_group.hasError"
      class="psui-mt-6 psui-justify-center"
      label="Update"
      icon="update"
      size="big"
      @click="update()"
    /> -->
    <button
      v-if="!$route.params.study_group_id"
      :disabled="study_group.hasError"
      class="mt-6 button button-blue04"
      @click="store()"
    >
      Create
    </button>    
    <button
      v-else
      :disabled="study_group.hasError"
      class="mt-6 button button-blue04"
      @click="update()"
    >
      Update
    </button>
  </div>
</template>

<script>
import StudyGroup from '@/models/StudyGroup'
export default {
  name: 'AdminStudyGroupForm',
  data() {
    return {
      study_group: new StudyGroup(),
      studies: [],
      study_types: [],
    }
  },
  mounted() {
    if(this.$route.params.study_group_id) {
      this.getStudyGroup()
    }

    this.getStudies()
    this.getStudyTypes()
  },
  methods: {

    getStudies() {
      const query = `
        {
          studies {
            id
            title
          }
        }
      `
      this.$graphql({ query })
        .then(({ data }) => {
          this.studies = data.studies
        })
    },

    getStudyTypes() {
      const query = `
        {
          study_types {
            id
            title
          }
        }
      `
      this.$graphql({ query })
        .then(({ data }) => {
          this.study_types = data.study_types
        })
    },

    async getStudyGroup() {
      const query = `
        {
          study_group (
            findBy: { column: "id", value: "${this.$route.params.study_group_id}"}
          ){
            id
            title
            study_type_id
            last_study_released_at
            last_study_published_at
            studies {
              id
              title
            }
          }
        }
      `
      const { data } = await this.$graphql({ query })

      this.study_group = new StudyGroup(data.study_group)
    },

    store() {
      this.$api.post(`/api/study_group/store`, this.study_group, { loader: true })
        .then(() => {
          this.$router.push({ name: 'AdminStudyGroupList' })
        })
    },

    update() {
      this.study_group.raw = this.raw
      this.$api.post(`/api/study_group/update/${this.study_group.id}`, this.study_group, { loader: true })
        .then(() => {
          this.$router.push({ name: 'AdminStudyGroupList' })
        })
    }
  }
}
</script>
