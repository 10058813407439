<template>
  <button class="app-helper-button opacity-0 focus:outline-none transition duration-300 ease-in-out">
    <feather
      size="16"
      type="info"
      class="text-blue ml-2 cursor-pointer"
    />
  </button>
</template>

<script>
export default {

}
</script>

<style lang="scss">
  .show-helper-on-hover:hover {
    .app-helper-button {
      opacity: 1;
    }
  }
</style>