import GraphQL from '@/util/GraphQL'

export default {
  namespaced: true,
  
  state: {
    type_prototypes: []
  },

  getters: {
    getterGlobalTypePrototypes(state) {
      return state.type_prototypes
    },
    getterGlobalTypePrototypeFilteredByBuildingType: state => building_type_id => {
      return state.type_prototypes.findAllInArray({ building_type_id })
    }
  },

  actions: {
    loadGlobalTypePrototypes(context) {
      const query = `
        {
          type_prototypes (
            orderBy: [{ column: "order", order: "ASC" }]
          ) {
            id
            title
            slug
            color
            icon
            building_stock_title
            building_stock_helper
            building_type_id
            hide_in_building_estimates
          }
        }
      `
      GraphQL({ query })
        .then(({ data }) => {
          context.commit('setGlobalTypePrototypes', { type_prototypes: data.type_prototypes})
        })
    }
  },

  mutations: {
    setGlobalTypePrototypes(state, { type_prototypes }) {
      state.type_prototypes = type_prototypes
    }
  }
}