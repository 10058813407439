export const blueColorPalette = [
  '#69A7BF',
  '#2B4552',
  '#DDE2E5',
  '#87E8F7',
  '#B4BAA8',
  '#E7E193',
  '#B2D483',
  '#52BAE3',
]

/* Cost-Effectiveness Results */
/* ----------------------------------------- */
  export const costEffectivenessResultsPdfSources = [
    {
      title: '2019 Cost-Effectiveness Study: Existing Low-Rise Residential Building Efficiency Upgrade',
      url: 'https://localenergycodes.com/download/96/file_path/fieldList/2019%20Res%20Retrofit%20Cost-eff%20Report.pdf',
      subtitle: 'California Energy Codes and Standards Program, PG&E. Produced by:  Frontier Energy, Inc, Misti Bruceri & Associates.'
    },
    {
      title: '2019 Cost-Effectiveness Study: Low-Rise Residential New Construction',
      url: 'https://localenergycodes.com/download/73/file_path/fieldList/2019%20Res%20NC%20Cost-eff%20Report',
      subtitle: 'California Energy Codes and Standards Program, PG&E. Produced by:  Frontier Energy, Inc, Misti Bruceri & Associates.'
    },
    {
      title: '2019 Nonresidential New Construction Reach Code Cost-Effectiveness Study',
      url: 'https://localenergycodes.com/download/74/file_path/fieldList/2019%20NR%20NC%20Cost%20Effectiveness%20Report',
      subtitle: 'California Energy Codes and Standards Program, SoCal Edison. Produced by:  TRC, EnergySoft.'
    },      
  ]
/* ----------------------------------------- Cost-Effectiveness Results */



/* Building Stocks */
/* ----------------------------------------- */  
export const BUILDING_STOCKS_CLIMATE_ZONE_ALL = {
  label: 'all zones',
  value: 'all'
}

export const SINGLE_FAMILY_UNITS = 'Single Family'
export const MULTIFAMILY_UNITS = 'Multifamily'
export const STOCK_PROTOTYPES = [ SINGLE_FAMILY_UNITS, MULTIFAMILY_UNITS ]

// @TODO - This can be enhanced if we pull this ordered from the API
export const STOCK_VINTAGES = ['Pre-1978', '1978-1991', '1992-2005', 'Post 2005' ]

/**
 * Creates a static map between the prototype_id and the prototype string created at the building stock
 */
export const BUILDING_STOCKS_PROTOTYPES = {
  1: SINGLE_FAMILY_UNITS,
  2: MULTIFAMILY_UNITS,
}

/**
 * Creates a static map to relate the columns from the building stock table produced by Shaun 
 */
export const BUILDING_STOCKS_KEYS_TO_PROTOTYPE = {
  single_family_1970_1977: {
    prototype: SINGLE_FAMILY_UNITS,
    vintage: 'Pre 1978',
    prototype_id : 1,
    vintage_id: 1,
  },
  single_family_1978_1991: {
    prototype: SINGLE_FAMILY_UNITS,
    vintage: '1978-1991',
    prototype_id : 1,
    vintage_id: 2,
  },
  single_family_1992_2005: {
    prototype: SINGLE_FAMILY_UNITS,
    vintage: '1992-2005',
    prototype_id : 1,
    vintage_id: 3,
  },
  single_family_2006_2020: {
    prototype: SINGLE_FAMILY_UNITS,
    vintage: 'Post 2005',
    prototype_id : 1,
    vintage_id: false,
  },
  multi_family_1970_1977: {
    prototype: MULTIFAMILY_UNITS,
    vintage: 'Pre 1978',
    prototype_id : 2,
    vintage_id: 1,
  },
  multi_family_1978_1991: {
    prototype: MULTIFAMILY_UNITS,
    vintage: '1978-1991',
    prototype_id : 2,
    vintage_id: 2,
  },
  multi_family_1992_2005: {
    prototype: MULTIFAMILY_UNITS,
    vintage: '1992-2005',
    prototype_id : 2,
    vintage_id: 3,
  },
  multi_family_2006_2020: {
    prototype: MULTIFAMILY_UNITS,
    vintage: 'Post 2005',
    prototype_id : 2,
    vintage_id: 0,
  }
}

export const buildingStockPdfSources = [
  {
    title: 'Source and Methodology notes are under construction.',
    url: 'https://explorer.localenergycodes.com',
    subtitle: ''
  },
]

/* ----------------------------------------- Building Stocks */


export const studyTypes = {
  NEW_BUILDINGS : 'New Buildings',
  EXISTING_BUILDINGS : 'Existing Buildings'
}

/* jurisdictions */
/* ----------------------------------------- */
  export const jurisdiction_TYPES = {
    CITY : 'City',
    COUNTY : 'County'
  }  
/* ----------------------------------------- jurisdictions */

/* Forecast */
/* ----------------------------------------- */
  // Our API parse the strings >1 & <1 from the spreasheet to this number below
  export const GREATER_THEN_ONE_NUMBER = 9876543210  
/* ----------------------------------------- Forecast */

/* Policies */
/* ----------------------------------------- */
  export const POLICIES_COMPARISON_SHOW_ONLY_DIFFERENT_ASSUMPTION_COLUMNS_PATH = 'policies.comparison.showOnlyDifferentAssumptionColumns'
/* ----------------------------------------- Policies */

export const ERRORS = {
  USER_NOT_LOGGED_IN: 'USER_NOT_LOGGED_IN',
  USER_NOT_LOGGED_IN_DELETE_FAIL: 'USER_NOT_LOGGED_IN_DELETE_FAIL',
  CUSTOM_BUILDING_STOCK_NOT_SAVED: 'CUSTOM_BUILDING_STOCK_NOT_SAVED',
  CUSTOM_BUILDING_ESTIMATES_NOT_SAVED: 'CUSTOM_BUILDING_ESTIMATES_NOT_SAVED',
}
