<template>
  <ul 
    class="cursor-pointer psui-text-gray-40 psui-text-xsmall font-bold flex items-center space-x-0.5 mb-4 px-6 transition-all" 
    @click="updatePath"
  >
    <li>Edit Assumptions</li>
    <li class="flex">
      <i class="material-icons psui-text-p leading-4">chevron_right</i>
    </li>
    <li>{{ drawer }}</li>
    <li v-if="isOnBuildingAssumptions">
      Building Estimates
    </li>
  </ul>
</template>

<script>
import { ASSUMPTIONS_PATHS } from '@/modules/app/assumptions/shared/enums'

export default {
  name: 'AssumptionsBreadcrumb',
  props: ['path', 'drawer'],
  computed:{ 
    isOnBuildingAssumptions() {
      return this.path?.slug !== ASSUMPTIONS_PATHS.IMPACT_RESULTS.slug
    },
  },
  methods:{
    updatePath(){
      this.$emit('update:path', false)
            this.$store.commit('assumptions/updateDrawerIsEditingFromChart', false)

    }
  },
}
</script>

<style scoped>
  li:hover {
    transition: inherit;
    opacity: .8;
  }
</style>
