import { objArrayToCsv, downloadRawData } from '@igortrindade/lazyfy'
export default class BuildingEstimatesGenerateCsvService {
  csvFiles = []
  constructor(prototypesInfo, filename, header = null) {
    this.prototypesInfo = prototypesInfo
    this.filename = filename
    this.header = header
  }

  async export() {

    const csvRows = []
    this.prototypesInfo.forEach((file) => {
      Object.keys(file.climateZones).forEach((climateZone) => {
        Object.keys(file.climateZones[climateZone]).forEach((vintate) => {
          csvRows.push({
            climateZone: Number(climateZone),
            buildingTypeTitle: file.title,
            vintageTitle: file.climateZones[climateZone][vintate].title,
            units: file.climateZones[climateZone][vintate].building_stock_units
          })
        })
      })
    })

    let csvString = ''
    if (this.header) csvString += this.header + '\n'
    csvString += objArrayToCsv(csvRows, ',')

    downloadRawData(csvString, this.filename)
  }

}
