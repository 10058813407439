import policyColumns from './policyColumns'
import Policy from '@/models/Policy'

export default {
  policyColumns, 
  policiesOpened: [],
  userPolicies: [],
  userSharedPolicies: [],
  policySelected: new Policy(),
  lastPolicyOrder : 0,
  hiddenItems: []
}