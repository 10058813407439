<template>
  <div
    class="shadow-inner text-deepsky-800 hover:text-deepsky-500 w-full flex flex-col last:rounded-b-sm hover:bg-white"
    :class="[isOpened ? 'bg-white' : 'bg-ash-100']"
  >
    <div 
      class="w-full flex items-center pl-6 p-4 cursor-pointer"
      @click="$store.commit('tutorial/setTutorialCurrentPosition', { type, position })"
    >
      <div class="mr-2">
        <span class="bg-deepsky-500 text-white flex items-center justify-center h-7 w-7 rounded-full font-bold">
          {{ position }}
        </span>
      </div>
      <div class="w-full text-16 font-bold transition-all">
        {{ title }}
      </div>
      <feather
        :type="isOpened ? 'chevron-up' : 'chevron-down'"
        size="16"
        class="text-current"
      />
    </div>

    <div
      v-show-slide="isOpened"
      class="tutorial-content w-full text-ash-500 pr-3"
    >
      <slot />
      
      <button
        v-if="showCloseButton"
        class="text-deepsky-450 my-3 font-14" 
        @click="$store.commit('tutorial/setTutorialShow', { type: 'flexiblePath', show: false })"
      >
        Close Tutorial
      </button>
      <button 
        v-if="showDoneButton"
        class="text-deepsky-450 my-3 font-14" 
        @click="setNextTutorialCurrentPosition()"
      >
        Next step
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TutorialItem',
  props: ['type', 'position', 'title', 'content', 'showCloseButton', 'showDoneButton'],
  computed: {
    isOpened() {
      return this.$store.state.tutorial[this.type].currentPosition == this.position
    }
  },
  methods: {
    setNextTutorialCurrentPosition() {
      this.$store.commit('tutorial/setTutorialCurrentPosition', { type: 'flexiblePath', position: this.$store.state.tutorial[this.type].currentPosition + 1 })
    }
  }
}
</script>
<style scoped>
.shadow-inner {
  box-shadow: 0px 1px 0px 0px rgba(235, 238, 240, 1) inset;
}
.tutorial-content {
  padding-left: 60px;
}


</style>