import BaseLayoutWithHeaderAndSideBar from '@/layout/BaseLayoutWithHeaderAndSideBar'
import SidebarDefault from '@/components/general/SidebarDefault.vue'
import UserAccountIndex from '@/modules/app/user-account/components/UserAccountIndex'
import Vue from 'vue'
import mainVuexStore from '@/store'

export default [
  {
    path: '/my-account',    
    component: BaseLayoutWithHeaderAndSideBar,
    meta: {
      auth: {
        required: true
      }
    },
    beforeEnter: (to, from, next) => {
      if(!mainVuexStore.getters.getterLoggedUser) {
        Vue.$toast.error('Please login')
        next('/login')
      } else {
        next()
      }
    },
    children: [
      {
        path: '',
        name: 'UserAccount',
        component: UserAccountIndex,
        meta: {
          auth: {
            required: true
          },
          sidebar: {
            size: 'medium',
            content: SidebarDefault
          },
        }
      }
    ]
  }
]