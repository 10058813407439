import Vue from "vue"
import VueGtag from "vue-gtag"
import { bootstrap as bootstrapGtag } from 'vue-gtag'

export default (Store) => {

  const isMainURI = window.location.origin == 'https://explorer.localenergycodes.com' ? true : false

  if(isMainURI) {
    
    Vue.use(VueGtag, {
      config: { id: 'G-1XH49KPBGC' },
      bootstrap: false
    })

    // Add timeout to avoid tracking before the redirect of admin and team users to the domain https://explorer-production-team.vercel.app/
    Store.subscribe((mutation) => {
      if(
        mutation.type === 'general/setDeviceInfo' && 
        !(!Store.getters['general/getterDeviceInfo']?.is_admin || !Store.getters['general/getterDeviceInfo']?.is_team)
      ) {
        bootstrapGtag().then(() => {
          console.log('Gtag successfuly started!')
        })
      }
    })
    
  }

}