<template>
  <div class="w-full h-full flex flex-col overflow-y-auto">
    <PoliciesHeader class="sticky top-0 z-50" />

    <router-view />

    <ModalPolicyCreateEdit />

    <ModalDuplicatePolicy />

    <ModalDeletePolicy />
  </div>
</template>

<script>
import PoliciesHeader from '@/modules/app/policy/shared/PoliciesHeader.vue'
import ModalPolicyCreateEdit from '@/modules/app/policy/shared/ModalPolicyCreateEdit.vue'
import ModalDuplicatePolicy from '@/modules/app/policy/shared/ModalDuplicatePolicy'
import ModalDeletePolicy from '@/modules/app/policy/shared/ModalDeletePolicy'
export default {
  name: 'PoliciesIndex',
  components: { ModalPolicyCreateEdit, ModalDuplicatePolicy, ModalDeletePolicy, PoliciesHeader },
  metaInfo() {
    return {
      title: 'My Policies | '
    }
  },
  mounted() {
    this.$store.commit('general/setSidebarPolicyTooltipShouldHide', true)
  }
}
</script>