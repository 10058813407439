<template>
  <BaseModal 
    ref="baseModal"
    title="Building Height"
    modal-dialog-classes="max-w-lg"
    @close="close"
  >
    <div class="w-full flex flex-col">
      <InputWithLabel
        v-model="building_height.title"
        class="mt-6"
        label="Title"
      />

      <InputWithLabel
        v-model="building_height.slug"
        class="mt-6"
        label="Slug"
      />

      <ContentEditor
        label="Content"
        class="mt-6"
        :content.sync="building_height.content"
      />

      <div class="w-full flex mt-6">
        <PsButton
          size="medium"
          :label="building_height.id ? `Update` : `Store`"
          @click="storeOrUpdate()"
        />
      </div>
    </div>
  </BaseModal>
</template>

<script>
import BuildingHeight from '@/models/BuildingHeight'
export default {
  name: "AdminBuildingHeightEditModal",
  data() {
    return {
      building_height: new BuildingHeight()
    }
  },
  mounted() {
    this.$eventBus.$on('openBuildingHeightModal', ({ building_height_id = null } = {}) => {
      this.building_height.id = building_height_id
      this.$refs.baseModal.showModal = true
      if(building_height_id) this.getBuildingHeight()
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('openBuildingHeightModal')
  },
  methods: {
    getBuildingHeight() {
      this.building_height.show()
        .then(({ data }) => {
          this.building_height = new BuildingHeight(data.building_height)
        })
    },

    storeOrUpdate() {
      this.building_height.storeOrUpdate()
        .then(() => {
          this.$emit('getBuildingHeights')
          this.close()
        })
    },

    close() {
      this.building_height = new BuildingHeight()
      this.$refs.baseModal.showModal = false
    }
  }
}
</script>

<style>

</style>