<template>
  <svg
    height="24"
    width="24"
    viewBox="0 0 24 24"
  >
    <circle
      r="12"
      cx="12"
      cy="12"
      fill="#E4EEF2"
    />
    <circle
      r="6"
      cx="12"
      cy="12"
      fill="transparent"
      stroke="#4B8CA6"
      stroke-width="12"
      :stroke-dasharray="dashArray"
      transform="rotate(-90) translate(-24)" 
    />
  </svg>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      required: true
    }
  },
  computed: {
    dashArray() {
      return `${this.value * 37.7 / 100} 37.7`
    }
  }
}
</script>

<style>

</style>