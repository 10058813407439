<template>
  <div
    class="app-alert"
    :class="getWrapperClass"
    @click="$emit('click')"
  >
    <div class="flex items-center">
      <div class="flex items-center">
        <feather
          v-if="icon"
          :type="icon"
          :size="iconSize"
          class="mr-2 flex-shrink-0"
        />
        <span
          class="text-header-2 font-bold"
          v-html="description"
        />
      </div>
      <div
        v-if="action"
        class="flex items-center pl-5 ml-auto"
      >
        <span class="text-accent-1">{{ action }}</span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'AppAlert',
  props: {
    description: {
      type: String,
      required: true,      
    },
    action: {
      type: String,
    },
    icon: {
      type: String
    },
    iconSize: {
      type: Number,
      default: 24
    },
    wrapperClass: {
      type: String,
      default: 'p-3 border rounded'
    },
    fill: {
      type: String,
      default: 'soft',
      validator: (value) => ['soft', 'intense'].indexOf(value) !== -1,
    },
    type: {
      type: String,
      default: 'sunrise',
      validator: (value) => ['candyapple', 'sunrise', 'lime'].indexOf(value) !== -1,
    }
  },
  computed: {
    getWrapperClass() {
      let themeClasses = {
        soft: 'text-basecolor-500 bg-basecolor-100 border-basecolor-300 hover:border-basecolor-500 hover:bg-white',
        intense: 'text-white bg-basecolor-500 border-basecolor-500'
      }[this.fill].replaceAll('basecolor', this.type)

      if (this.type === 'sunrise') {
        themeClasses.replaceAll('text-sunrise-500', 'text-sunrise-600')
      }
      return [ 'transition-all', themeClasses, this.wrapperClass]
    }
  }
}
</script>