<template>
  <div class="w-full flex flex-col px-10 pb-10 pt-5 bg-white">
    <h3 class="text-p text-ash-500 mb-16">
      View/Edit your policy scope below.
    </h3>
    <template v-if="study_types.length">
      <div
        v-for="study_type in study_types"
        :key="`study_type_${study_type.id}`"
        class="w-full flex flex-col"
      >
        <h3 class="text-deepsky-800 font-16 font-bold">
          {{ study_type.title }}
        </h3>

        <div class="w-full flex flex-col space-y-4 mt-4">
          <div
            v-for="type_prototype in study_type.type_prototypes"
            :key="`type_prototype_${type_prototype.id}`"
            class="w-full rounded bg-deepsky-100 border border-deepsky-200 p-6"
          >
            <div class="w-full flex items-center relative pl-12">
              <inline-svg
                class="absolute left-0"
                :src="`/icons/type-prototypes-slug/${type_prototype.slug}.svg`"
                width="32"
                height="32"
                stroke="#69A7BF"
              />
              <h3 class="text-16 font-bold text-deepsky-800">
                {{ type_prototype.title }}
              </h3>
            </div>

            <div class="w-full pl-12 flex flex-col mt-4 space-y-4">
              <div
                v-for="climate_zone in type_prototype.climate_zones"
                :key="`climate_zone_${climate_zone.id}`"
                class="w-full flex flex-col"
              >
                <h5 class="text-14 text-deepsky-800">
                  Climate zone {{ climate_zone.prefix }}
                </h5>

                <div class="w-full flex flex-grow space-x-6 mt-3">
                  <div
                    v-for="type_vintage in climate_zone.type_vintages"
                    :key="`type_vintage_${type_vintage.id}`"
                    class="flex-1 flex flex-col space-y-3 last:hidden"
                  >
                    <div 
                      v-for="vintage in type_vintage.vintages" 
                      :key="`vintage_${vintage.id}`"
                      class="vintage-card w-full h-auto text-deepsky-800 rounded-md bg-white shadow-sm relative opacity-100-all-childrens-on-hover transition-all" 
                      :class="{ 'hover:text-deepsky-450 cursor-pointer': userIsPolicyOwner }"
                    >
                      <div
                        class="w-full h-full justify-center px-6 py-7"
                        @click="openPolicyEditCustomCombination(vintage.custom_combination_id)"
                      >
                        <div class="w-8 float-left">
                          <div class="flex items-center justify-center h-6 w-6 rounded-full bg-deepsky-200">
                            <feather
                              v-tooltip="{ content: 'This vintage is in your policy.', placement: 'bottom-center' }"
                              class="text-deepsky-450"
                              type="check"
                              size="16"
                            />
                          </div>
                        </div>
                        <div class="text-14 font-bold text-current float-left half-width">
                          {{ vintage.title_long }}
                        </div>

                        <PolicyScopeMeasuresIcon
                          v-check-env="['hide']"
                          :vintage="vintage"
                          :climate_zone="climate_zone"
                        />
                      </div>

                      <div 
                        v-if="userIsPolicyOwner"
                        class="absolute right-0 top-0 h-full flex flex-col justify-center space-y-1 pr-4"
                      >
                        <button
                          class="text-ash-300 transition-all hover:text-deespky-500 action-button cursor-pointer"
                          @click="openPolicyEditCustomCombination(vintage.custom_combination_id)"
                        >
                          <feather
                            type="edit"
                            size="16"
                          />
                        </button>
                        <button
                          class="text-ash-300 transition-all hover:text-deespky-500 action-button cursor-pointer"
                          @click="$eventBus.$emit('openModalDeleteCustomCombination', { vintage })"
                        >
                          <feather
                            type="trash"
                            size="16"
                          />
                        </button>
                      </div>
                    </div>

                    <PolicyScopeEmptyCell
                      v-if="!type_vintage.vintages.length"
                      :type_prototype="type_prototype"
                      :type_vintage="type_vintage"
                      :climate_zone="climate_zone"
                      :policy="policy"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <SkeletonPolicyShowScope v-else />
    <!-- <SkeletonPolicyScope v-else /> -->

    <ModalDeleteCustomCombination @getPolicyMap="getPolicyMap" />
  </div>
</template>

<script>
import SkeletonPolicyShowScope from '../skeleton/SkeletonPolicyShowScope.vue'
import PolicyScopeEmptyCell from './PolicyScopeEmptyCell.vue'
import PolicyScopeMeasuresIcon from './PolicyScopeMeasuresIcon'
import ModalDeleteCustomCombination from '@/modules/app/policy/show/shared/ModalDeleteCustomCombination'
export default {
  name: 'PolicyScope',
  components: {
    PolicyScopeMeasuresIcon,
    ModalDeleteCustomCombination,
    PolicyScopeEmptyCell,
    SkeletonPolicyShowScope
  },
  props: ['policy'],
  data() {
    return {
      study_types: []
    }
  },
  computed: {
    userIsPolicyOwner() {
      return this.policy && (this.policy.user_id === this.$store.getters.getterLoggedUser?.id) ? true : false
    }
  },
  mounted() {
    this.getPolicyMap()
  },
  methods: {
    getPolicyMap() {
      this.$api.get(`/api/policy/get_policy_map/${this.$route.params.policy_id}`)
        .then(({ data }) => {
          this.study_types = data.study_types
        })
    },

    openPolicyEditCustomCombination(custom_combination_id) {
      if (!this.userIsPolicyOwner) return
      this.$router.push({ ...this.$route, params: { policy_active_tab: 'edit_policy' }, query: { custom_combination_id }})
    }
  }
}
</script>

<style>

.vintage-card {
  min-height: 75px;
}
.action-button:hover {
  color:rgba(105, 167, 191, 1);
}
</style>