<template>
  <div class="w-full h-full flex flex-col justify-between pl-20 pr-10 py-10">
    <router-link
      to="/"
      class="w-full text-center mb-6 inline-block"
    >
      <img
        src="/logo/cee.svg"
        width="180px"
        alt=""
      >
    </router-link>

    <div class="w-full h-3/4 flex items-center">
      <h2 class="text-bigtitle-3 font-bold text-white">
        Sign in or register to create custom combinations of measures and more!
      </h2>
    </div>

    <LocalEnergyLogo class="mb-3" />
  </div>
</template>

<script>

export default {
  data: function() {
    return {
      show: true,
    }
  }
}
</script>

<style lang="scss" scoped>  

#sidebar--welcome {
  display: flex;
  flex-direction: column;
  padding-left: 160px;
  padding-right: 75px;

  > * {
    flex-shrink: 0;
  }

	.my-auto {
		margin-top: auto;
		margin-bottom: auto;
  }
}

</style>