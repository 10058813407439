<template>
  <div class="w-full flex items-center justify-center h-screen py-6 overflow-y-auto block p-3">
    <div
      v-if="!showConfirmation"
      class="login-card-w my-auto"
    >
      <div class="apply-card flex flex-wrap justify-center bg-white">
        <h3 class="mb-6 w-full text-left text-pri text-lg font-semi-bold">
          Recover your password
        </h3>
        <h4 class="mb-6 w-full text-left text-gray04">
          Please enter your email and we will send you a link to recover your password
        </h4>
        <InputWithLabel
          v-model="user.email"
          :label="'Email'"
          @keydown="sendRecoverLink"
        />
        <div class="w-full flex justify-between items-center mt-6">
          <button
            class="button bg-sec text-white rounded-full uppercase"
            @click="sendRecoverLink()"
          >
            Continue
          </button>
          <router-link
            to="/"
            class="text-blue02 inline-block"
          >
            Go back to homepage
          </router-link>
        </div>
      </div>
      <h4 class="w-full text-left text-gray mt-6 text-sm">
        You can also
        <router-link
          class="text-sec"
          :to="{ name: 'UserSignup'}"
        >
          Create a new account
        </router-link>
      </h4>
    </div>

    <div
      v-else
      class="login-card-w my-auto"
    >
      <div class="apply-card flex flex-wrap justify-center">
        <h3 class="mb-6 w-full text-left text-pri text-lg font-semi-bold">
          Recover your password
        </h3>
        <h4 class="text-gray04 text-left leading-2 mt-6">
          Thank you! We sent you an email with the instructions to recover your password.
        </h4>
        <h4 class="text-gray04 text-left leading-2 mt-6">
          Didn't receive any email? Check your spam or <button
            class="text-blue02"
            @click="showConfirmation = false"
          >
            try again
          </button>.
        </h4>
      </div>
    </div>
  </div>
</template>

<script>
import { emailValidator } from '@/util/Validators'
export default {
  name: 'Recover',
  data() {
    return {
      showConfirmation: false,
      user: {
        email: '',
      },
      validation: {
        email: {
          hasError: false,
          label: ''
        },
      }
    }
  },
  mounted() {
    if(this.$route.query.email) {
      this.user.email = this.$route.query.email
    }
  },
  methods: {
    async checkEmail() {
      if(!this.user.email|| !emailValidator(this.user.email)) {
        this.validation.email.hasError = true
        this.validation.email.label = 'Please, fill a valid email'
        return
      }
      this.$api.get(`/users/checkEmail/${this.user.email}`)
      .then(({ data }) => {
        const { user_finded } = data

        if(!user_finded) {
          this.$toast.error('Hmmm. We don’t have that email. Try again or register.')
          this.validation.email.hasError = true
          this.validation.email.label = 'We don’t have that email. Try again or register.'
          setTimeout(() => {
            document.getElementById('signin-email').focus()
          }, 200)
        } else {
          this.showConfirmation = true
          this.validationClear('email')
        }

      })

    },

    sendRecoverLink() {
      this.$api.post(`/recover/generate`, { email: this.user.email })
        .then(({ data }) => {
          this.$toast.success(data.message)
          this.showConfirmation = true
        })
    }
  }
}
</script>