export default (state, { columnsStateKey, studyKey, indexColumnGroup, indexColumn, columnGroupKey }) => {
  state[columnsStateKey][studyKey].columnGroups[indexColumnGroup].columns[indexColumn].isActive = !state[columnsStateKey][studyKey].columnGroups[indexColumnGroup].columns[indexColumn].isActive

  const columns = document.getElementsByClassName(`column-group-${columnGroupKey}`)
  if(columns && columns.length) {
    for(const column of columns) {
      column.classList.add('blink')
    }
    setTimeout(() => {
      for(const column of columns) {
        column.classList.remove('blink')
      }
    }, 900)
  }

}