<template>
  <div class="w-full p-6">
    <div class="w-full flex justify-end">
      <router-link
        :to="{ name: 'AdminStudyTypeCreate' }"
      >
        <PsButton
          label="Create study type"
          size="big"
          icon="add"
        />
      </router-link>
    </div>

    <table class="table table-bordered mt-6 whitespace-no-wrap">
      <thead>
        <tr>
          <th>ID</th>
          <th>Study Type</th>
          <th>Study groups</th>
          <th>Created at</th>
          <th class="psui-w-48">
            Actions
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="study_type in study_types"
          :key="study_type.id"
        >
          <td>{{ study_type.id }}</td>
          <td>{{ study_type.title }}</td>
          <td>{{ study_type.study_groups.length }}</td>
          <td>{{ study_type.created_at }}</td>
          <td class="psui-flex psui-space-x-2">
            <router-link 
              :to="{ name: 'AdminStudyTypeEdit', params: { study_type_id: study_type.id}}"
            >
              <PsButton 
                label="Edit"
                size="medium" 
                icon="edit"
              />
            </router-link>

            <PsButton 
              layout="caution" 
              label="Delete"
              size="medium"
              icon="delete"
              @click="selectStudyTypeToDelete(study_type)"
            />
          </td>
        </tr>
      </tbody>
    </table>

    <BaseModal
      ref="deleteStudyTypeModal"
      modal-dialog-classes="w-full max-w-sm"
      title="Attention"
    >
      <div
        v-if="studyTypeSelected"
        class="w-full flex flex-col items-start mt-6"
      >
        <p class="text-base text-gray04">
          Delete <span class="text-red font-bold">{{ studyTypeSelected.title }}</span> study type and remove the relationship between the study groups that are attached with this study type?
        </p>
        <PsButton
          layout="caution"
          class="psui-mt-6"
          label="Confirm delete"
          size="big"
          @click="deleteStudyType()" 
        />
      </div>
    </BaseModal>
  </div>
</template>

<script>
export default {
  name: 'AdminStudyTypeList',
  data() {
    return {
      study_types: [],
      studyTypeSelected: null,
      search: '',
      offset: 0,
      limit: 12,
    }
  },
  mounted() {
    this.getStudyTypes()
  },
  methods: {
    async getStudyTypes() {

      const query = `
        {
          study_types (
            orderBy: [{column: "id", order: "asc"}]
          ){
            id
            title
            created_at
            study_groups {
              id
              title
            }
          }
        }
      `
      const { data } = await this.$graphql({ query })

      this.study_types = data.study_types
    },


    selectStudyTypeToDelete(study) {
      this.studyTypeSelected = study
      this.$refs.deleteStudyTypeModal.showModal = true
    },

    deleteStudyType() {
      this.$api.delete(`/api/study_type/delete/${this.studyTypeSelected.id}`, { loader: true})
        .then(() => {
          this.$refs.deleteStudyTypeModal.showModal = false
          this.getStudyTypes()
        })
    }
  }
}
</script>