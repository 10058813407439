<template>
  <div class="w-full h-full flex flex-col justify-start pb-4">
    <router-link
      :to="{ name: 'AppHome', query: { utm_source : 'explorer-sidebar' } }"
      class="w-full text-center mb-6 inline-block"
    >
      <img
        src="/logo/cee.svg"
        width="180px"
        alt=""
      >
    </router-link>
    
    <SidebarDefaultNavigationMain />

    <SidebarDefaultNavigationFooter />
    
    <StateProgramReferralText />

    <SidebarMenu />
    <DrawerFaq />
    <DrawerChangelog />
    <DrawerUpcomingFeatures />
  </div>
</template>

<script>

import DrawerChangelog from './DrawerChangelog.vue'
import SidebarDefaultNavigationFooter from './SidebarDefaultNavigationFooter.vue'
import SidebarDefaultNavigationMain from './SidebarDefaultNavigationMain.vue'
import DrawerUpcomingFeatures from './DrawerUpcomingFeatures.vue'
import DrawerFaq from './DrawerFaq.vue'
import SidebarMenu from './SidebarMenu.vue'

export default {
  name: 'SidebarDefault',
  components: { 
    SidebarMenu, 
    DrawerFaq, 
    DrawerUpcomingFeatures, 
    SidebarDefaultNavigationMain, 
    SidebarDefaultNavigationFooter, 
    DrawerChangelog
  },
  computed: {
    showNewFeatures() {
      return this.$store.state.auth.loggedUser && this.$store.getters.getterLoggedUserType == 'admin' && window.location.host != 'explorer.localenergycodes.com'
    }
  }
}
</script>