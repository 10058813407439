<template>
  <div class="bar-value-wrapper relative p-3 flex items-center h-full">
    <div
      class="bar rounded-sm absolute top-0 left-0 h-full"
      :class="barClass"
      :style="getBarStyles"
    />
    <span
      v-if="label"
      class="relative"
      :class="labelClass"
    >{{ label }}</span>
  </div>  
</template>

<script>
export default {
  name: 'BarValue',
  props: {
    value : {
      type: Number,
      required: true
    },
    maxValue : {
      type: Number,
      required: true
    },
    label: {
      type: String,
    },
    showValue: {
      type: Boolean,
      default: true
    },
    barClass : {
      type: String,
      default: 'bg-deepsky-300'
    },
    labelClass: {
      type: String,
      default: 'text-deepsky-800'
    }
  },
  computed: {
    getBarStyles() {
      const width = 100 / this.maxValue * this.value
      return {
        width: `${width}%`
      }
    },    
  }
}
</script>

<style lang="scss" scoped>

</style>