<template>
  <div class="w-full mb-7 text-ash-400 h-4">
    <div
      v-if="currentStep.breadcrumbTitle"
      class="w-full flex items-center"
    >
      <feather
        v-if="stepsNavigation.length > 1"
        class="cursor-pointer mr-1 relative hover:text-deepsky-700"
        type="chevron-left"
        size="16"
        @click="goBack()"
      />
      <template v-for="(step, index) in stepsNavigation">
        <span 
          :key="step.id + index"
          class="text-header-1 transition-all"
          :class="{ 'hover:text-deepsky-700 cursor-pointer' : index < stepsNavigation.length-1 }"
          @click="goToStep(step, index)"
        >
          {{ step.breadcrumbTitle }}
        </span>
      </template>
    </div>
  </div>
</template>

<script>
import { find, findIndex } from '@/util/Functions'
import steps from './steps'
export default {
  name: 'PolicyBreadcrumb',
  props: ['currentStep'],
  data() {
    return {
      stepsNavigation: []
    }
  },
  mounted() {
    this.stepsNavigation = [steps[0]]
    if(this.currentStep) this.addStepToNavigation({ ...this.currentStep })
    this.$eventBus.$on('cleanPolicyWizardBreadcrumbs', () => {
      this.stepsNavigation = [steps[0]]
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('cleanPolicyWizardBreadcrumbs')
    this.stepsNavigation = [steps[0]]
  },
  
  methods: {

    goBack() {
      const currentStepIndex = findIndex(this.stepsNavigation, { id: this.currentStep.id })
      if(currentStepIndex > 0) {
        const stepToGoBack = find(this.stepsNavigation, { id: this.stepsNavigation[currentStepIndex-1].id })
        this.$emit('update:currentStep', stepToGoBack)
        setTimeout(() => {
          this.stepsNavigation.splice(currentStepIndex)
        },100)
      }
    },
    addStepToNavigation(step) {
      this.stepsNavigation.push(step)
    },
    
    goToStep(step, index) {
      if(this.currentStep.id == step.id) return
      this.$emit('update:currentStep', step)
      this.stepsNavigation.splice(index+1)
    }
  }
}
</script>

<style lang="scss" scoped>
  span {
    &::after {
      content: "/";
      display: inline-block;
      padding: 0 5px;
    }
    &:last-of-type::after {
      display: none;
    }
  }
</style>
