import baseAdminMeta from '@/modules/admin/baseAdminMeta'
import BaseLayoutAdmin from '@/layout/BaseLayoutAdmin'
import AdminUserList from '@/modules/admin/users/AdminUserList'
import AdminUserForm from '@/modules/admin/users/AdminUserForm'
import AdminStudyItemList from '@/modules/admin/study_item/AdminStudyItemList'
import AdminStudyItemForm from '@/modules/admin/study_item/AdminStudyItemForm'

import AdminDashboardIndex from '@/modules/admin/dashboard/AdminDashboardIndex'
import AdminFrequentQuestionList from '@/modules/admin/frequent_question/AdminFrequentQuestionList'
import AdminFrequentQuestionForm from '@/modules/admin/frequent_question/AdminFrequentQuestionForm'
import AdminHelperList from '@/modules/admin/helper/AdminHelperList'
import AdminHelperForm from '@/modules/admin/helper/AdminHelperForm'
import AdminJurisdictionList from '@/modules/admin/jurisdiction/AdminJurisdictionList'
import AdminJurisdictionForm from '@/modules/admin/jurisdiction/AdminJurisdictionForm'
import AdminClimateZoneList from '@/modules/admin/climate_zone/AdminClimateZoneList'
import AdminClimateZoneForm from '@/modules/admin/climate_zone/AdminClimateZoneForm'
import AdminBuildingStockList from '@/modules/admin/building_stock/AdminBuildingStockList'
import AdminBuildingStockForm from '@/modules/admin/building_stock/AdminBuildingStockForm'
import AdminSyncList from '@/modules/admin/sync/AdminSyncList'
import AdminTypeFilterList from '@/modules/admin/type_filter/AdminTypeFilterList'
import AdminTypeFilterForm from '@/modules/admin/type_filter/AdminTypeFilterForm'
import AdminBuildingHeightList from '@/modules/admin/building_height/AdminBuildingHeightList'
import AdminBuildingTypeList from '@/modules/admin/building_type/AdminBuildingTypeList'
import AdminConstructionImplementationTypeList from '@/modules/admin/construction_implementation_type/AdminConstructionImplementationTypeList'
import CustomFieldList from '@/modules/admin/custom_field/CustomFieldList'

import studyGroupRoutes from '@/modules/admin/study-group/routes'
import studyTypeRoutes from '@/modules/admin/study-type/routes'
import studyMapRoutes from '@/modules/admin/study_map/routes'
import policyRoutes from '@/modules/admin/policy/routes'
import adminAuthRoutes from '@/modules/admin/auth/routes'
import buildingEstimateRoutes from '@/modules/admin/building_estimate/routes'

import Vue from 'vue'
import mainVuexStore from '@/store'

export default [
  
  ...adminAuthRoutes,

  {
    path: '/admin',
    component: BaseLayoutAdmin,
    beforeEnter: (to, from, next) => {
        if(!mainVuexStore.getters.getterLoggedUser) {
          Vue.$toast.error('Please login')
          next('/admin/login')
        } else if(mainVuexStore.getters.getterLoggedUserType != 'admin'){
          Vue.$toast.error(`You don't have access, please sign in as ADMIN`)
          next('/admin/login')
        } else {
          next()
        }
    },
    children: [

      ...studyGroupRoutes,
      ...studyTypeRoutes,
      ...policyRoutes,
      ...studyMapRoutes,
      ...buildingEstimateRoutes,
      
      {        
        path: '',
        redirect : { name: 'AdminStudyGroupList'}
      },
      {
        name: 'AdminDashboardIndex',
        path: 'dashboard',
        component: AdminDashboardIndex,
        meta: { ...baseAdminMeta, title: 'Dashboard' }
      },
      {
        name: 'AdminUserList',
        path: 'users',
        component: AdminUserList,
        meta: { ...baseAdminMeta, title: 'Users' }
      },
      {
        name: 'AdminUserEdit',
        path: 'users/:user_id/edit',
        component: AdminUserForm,
        meta: { ...baseAdminMeta, title: 'Users' }
      },     
      {
        name: 'AdminFrequentQuestionList',
        path: 'frequent_questions',
        component: AdminFrequentQuestionList,
        meta: { ...baseAdminMeta, title: 'Frequent Questions' }
      },
      {
        name: 'AdminFrequentQuestionCreate',
        path: 'frequent_questions/create',
        component: AdminFrequentQuestionForm,
        meta: { ...baseAdminMeta, title: 'Create Frequent Question' }
      },
      {
        name: 'AdminFrequentQuestionEdit',
        path: 'frequent_questions/:frequent_question_id/edit',
        component: AdminFrequentQuestionForm,
        meta: { ...baseAdminMeta, title: 'Edit Frequent Question' }
      },

      {
        name: 'AdminHelperList',
        path: 'helpers',
        component: AdminHelperList,
        meta: { ...baseAdminMeta, title: 'Helpers' }
      },
      {
        name: 'AdminHelperCreate',
        path: 'helpers/create',
        component: AdminHelperForm,
        meta: { ...baseAdminMeta, title: 'Create Helper' }
      },
      {
        name: 'AdminHelperEdit',
        path: 'helpers/:helper_id/edit',
        component: AdminHelperForm,
        meta: { ...baseAdminMeta, title: 'Edit Helper' }
      },

      {
        name: 'AdminJurisdictionList',
        path: 'jurisdictions',
        component: AdminJurisdictionList,
        meta: { ...baseAdminMeta, title: 'Jurisdictions' }
      },
      {
        name: 'AdminJurisdictionCreate',
        path: 'jurisdictions/create',
        component: AdminJurisdictionForm,
        meta: { ...baseAdminMeta, title: 'Create Jurisdiction' }
      },
      {
        name: 'AdminJurisdictionEdit',
        path: 'jurisdictions/:jurisdiction_id/edit',
        component: AdminJurisdictionForm,
        meta: { ...baseAdminMeta, title: 'Edit Jurisdiction' }
      },

      {
        name: 'AdminClimateZoneList',
        path: 'climate_zones',
        component: AdminClimateZoneList,
        meta: { ...baseAdminMeta, title: 'Climate Zones' }
      },
      {
        name: 'AdminClimateZoneCreate',
        path: 'climate_zones/create',
        component: AdminClimateZoneForm,
        meta: { ...baseAdminMeta, title: 'Create Climate Zone' }
      },
      {
        name: 'AdminClimateZoneEdit',
        path: 'climate_zones/:climate_zone_id/edit',
        component: AdminClimateZoneForm,
        meta: { ...baseAdminMeta, title: 'Edit Climate Zone' }
      },

      {
        name: 'AdminBuildingStockList',
        path: 'building_stocks',
        component: AdminBuildingStockList,
        meta: { ...baseAdminMeta, title: 'Building Stocks' }
      },
      {
        name: 'AdminBuildingStockCreate',
        path: 'building_stocks/create',
        component: AdminBuildingStockForm,
        meta: { ...baseAdminMeta, title: 'Create Building Stock' }
      },
      {
        name: 'AdminBuildingStockEdit',
        path: 'building_stocks/:building_stock_id/edit',
        component: AdminBuildingStockForm,
        meta: { ...baseAdminMeta, title: 'Edit Building Stock' }
      },

      {
        name: 'AdminSyncList',
        path: 'syncs',
        component: AdminSyncList,
        meta: { ...baseAdminMeta, title: 'Syncs' }
      },
      
      {
        name: 'AdminTypeFilterList',
        path: 'item_category/:type?',
        component: AdminTypeFilterList,
        meta: { ...baseAdminMeta, title: 'Types' }
      },
      {
        name: 'AdminTypeFilterCreate',
        path: 'item_category/:type/create',
        component: AdminTypeFilterForm,
        meta: { ...baseAdminMeta, title: 'Create Filter group' }
      },
      {
        name: 'AdminTypeFilterEdit',
        path: 'item_category/:type/:type_filter_id/edit',
        component: AdminTypeFilterForm,
        meta: { ...baseAdminMeta, title: 'Edit Filter group' }
      },

      {
        name: 'AdminStudyItemList',
        path: 'study_item/:type?/:study_id?',
        component: AdminStudyItemList,
        meta: { ...baseAdminMeta, title: 'Study item list' }
      },
      {
        name: 'AdminStudyItemEdit',
        path: 'study_item/:type/:study_item_id/edit',
        component: AdminStudyItemForm,
        meta: { ...baseAdminMeta, title: 'Edit study item' }
      },

      {
        name: 'AdminBuildingHeightList',
        path: 'building_height',
        component: AdminBuildingHeightList,
        meta: { ...baseAdminMeta, title: 'Building Heights' }
      },

      {
        name: 'AdminBuildingTypeList',
        path: 'building_type',
        component: AdminBuildingTypeList,
        meta: { ...baseAdminMeta, title: 'Building Types' }
      },

      {
        name: 'AdminConstructionImplementationTypeList',
        path: 'construction_implementation_type',
        component: AdminConstructionImplementationTypeList,
        meta: { ...baseAdminMeta, title: 'Construction Implementation Type' }
      },

      {
        name: 'CustomFieldList',
        path: 'custom_fields',
        component: CustomFieldList,
        meta: { ...baseAdminMeta, title: 'Custom fields' }
      },
    ]
  }
]