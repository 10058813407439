import toggleColumnIsActive from '@/business-logic/services/columns/toggleColumnIsActive'
import moveColumnItem from '@/business-logic/services/columns/moveColumnItem'
import moveColumnGroup from '@/business-logic/services/columns/moveColumnGroup'

export default {

  setJurisdictionIdByInit(state, jurisdictionId) {
    state.jurisdictionId = jurisdictionId
  },
  
  setJurisdictionIdByRoute(state, jurisdictionId) {
    state.jurisdictionId = jurisdictionId
  },
    
  setPoliciesIdsByInit(state, policies_ids) {
    state.policyIds = policies_ids
  },  

  setPoliciesIdsByRoute(state, policies_ids) {
    state.policyIds = policies_ids
  },

  setUserPolicies(state, policies) {
    state.userPolicies = policies
  },

  toggleColumnIsActive: toggleColumnIsActive,

  moveColumnItem,
  
  moveColumnGroup,

  setAssumptionColumns(state, { different, shared }) {

    const existingColumns = {...state.comparisonColumns.existingBuildings}
    const differentAssumptionsGroup = existingColumns.columnGroups.find((columnGroup) => columnGroup.key === 'different_assumptions')
    const sharedAssumptionsGroup = existingColumns.columnGroups.find((columnGroup) => columnGroup.key === 'shared_assumptions')
    const remainColumnGroups = existingColumns.columnGroups.filter((columnGroup) => !['shared_assumptions', 'different_assumptions'].includes(columnGroup.key))

    const differentColumns = different.map((column) => { column.isActive = true; return column })
    differentAssumptionsGroup.columns = differentColumns
    
    const sharedColumnsWithCurrentState = shared.map((column) => { 
      const stateColumn = sharedAssumptionsGroup.columns.find((obj) => obj.key === column.key)
      column.isActive = stateColumn?.isActive ?? false
      return column 
    })    
    sharedAssumptionsGroup.columns = sharedColumnsWithCurrentState

    existingColumns.columnGroups = [ differentAssumptionsGroup, sharedAssumptionsGroup, ...remainColumnGroups ]

    state.comparisonColumns = { ...state.comparisonColumns, existingBuildings : existingColumns }
    
  },

  // toggleOnlyDifferentAssumptionColumns(state, { toggle, studyType = 'existingBuildings' }) {
  //   if (toggle) {
      
  //     state.comparisonColumns[studyType]
  //       ?.columnGroups.find((columnGroup) => columnGroup.key === 'different_assumptions')
  //       ?.columns.map((col) => {
  //         col.isActive = true
  //         return col
  //       })
      
  //     state.comparisonColumns[studyType]
  //       ?.columnGroups.find((columnGroup) => columnGroup.key === 'shared_assumptions')
  //       ?.columns.map((col) => {
  //         col.isActive = false
  //         return col
  //       })

  //   } else {

  //     state.comparisonColumns[studyType]
  //       ?.columnGroups.find((columnGroup) => columnGroup.key === 'different_assumptions')
  //       ?.columns.map((col) => {
  //         col.isActive = true
  //         return col
  //       })
      
  //     state.comparisonColumns[studyType]
  //       ?.columnGroups.find((columnGroup) => columnGroup.key === 'shared_assumptions')
  //       ?.columns.map((col) => {
  //         col.isActive = true
  //         return col
  //       })
        
  //   }
  // }
}