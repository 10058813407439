<template>
  <ul class="">
    <li class="text-deepsky-500 flex items-center">
      <feather type="settings" />
      <span 
        class="text-header-1 font-bold text-deepsky-200 cursor-pointer transition-all hover:opacity-70"
        @click="onClickYourAccount()"
      >
        YOUR ACCOUNT
      </span>
    </li>

    <li class="text-deepsky-500 flex items-center">
      <feather type="compass" />
      <span 
        class="text-header-1 font-bold text-deepsky-200 cursor-pointer transition-all hover:opacity-70"
        @click="onClickNextSteps()"
      >
        NEXT STEPS
      </span>
    </li>

    <li class="text-deepsky-500 flex items-center">
      <feather type="info" />
      <span 
        class="text-header-1 font-bold text-deepsky-200 cursor-pointer transition-all hover:opacity-70"
        @click="onClickInfo()"
      >
        INFO
      </span>
    </li>

    <li class="text-deepsky-500 flex items-center">
      <feather type="help-circle" />
      <span 
        class="text-header-1 font-bold text-deepsky-200 cursor-pointer transition-all hover:opacity-70"
        @click="onClickHelp()"
      >
        HELP
      </span>
    </li>

    <li
      v-if="getUser && $store.getters.getterLoggedUserType == 'admin'"
      class="text-deepsky-500 flex items-center"
    >
      <feather type="database" />
      <span 
        class="text-header-1 font-bold text-deepsky-200 cursor-pointer transition-all hover:opacity-70"
        @click="onClickAdmin()"
      >
        ADMIN DASHBOARD
      </span>
    </li>
    <li
      v-if="$store.getters['general/getterDeviceIsAdminOrTeam']"
      class="text-deepsky-500 flex items-center"
    >
      <feather type="tool" />
      <span 
        class="text-header-1 font-bold text-deepsky-200 cursor-pointer transition-all hover:opacity-70"
        @click="$eventBus.$emit('openQAModal')"
      >
        QA TICKETS
      </span>
    </li>
  </ul>
</template>

<script>
export default {
  computed: {
    getUser() {
      return this.$store.state.auth.loggedUser
    }
  },
  methods: {
    onClickNextSteps() {
      this.$eventBus.$emit('openSidebarMenu', { menu : 'SidebarMenuNextSteps' })
    },
    onClickInfo() {
      this.$eventBus.$emit('openSidebarMenu', { menu : 'SidebarMenuInfo' })
    },
    onClickHelp() {
      this.$eventBus.$emit('openSidebarMenu', { menu : 'SidebarMenuHelp' })
    },
    onClickYourAccount() {
      if(this.getUser) return this.$router.push({ name: 'UserAccount' })
      this.$router.push({ name: 'UserSignin' })
    },
    onClickAdmin() {
      this.$router.push('/admin')
    }
  }
}
</script>

<style lang="scss" scoped>
  ul {
    margin-bottom: 70px;
    padding-left: 4px;
    li {
      display: flex;
      padding-bottom: 12px;
      letter-spacing: 2px;
      span {
        margin-left: 8px;
      }
    }
  }
</style>