import GraphQL from '@/util/GraphQL'

export default {
  namespaced: true,
  
  state: {
    vintages: []
  },

  getters: {
    getterGlobalVintages(state) {
      return state.vintages
    },

    getterPrototypeSelected: state => routeFullPath => {
      const url = new URL(window.location.host + routeFullPath)
      const vintage_selected = url.searchParams.get('only_vintage')
      if(!vintage_selected) return state.vintages[0]
      return state.vintages.findInArray({ slug: url.searchParams.get('only_vintage')})
    },

    getterPrototypeById: state => vintage_id => {
      return state.vintages.findInArray({ id: vintage_id })
    }

  },

  actions: {
    loadGlobalVintages(context) {
      const query = `
        {
          vintages (
            orderBy: [{ column: "order", order: "ASC" }]
          ) {
            id
            title
            slug
            type_vintage_id
          }
        }
      `
      GraphQL({ query })
        .then(({ data }) => {
          context.commit('setGlobalVintages', { vintages: data.vintages})
        })
    }
  },

  mutations: {
    setGlobalVintages(state, { vintages }) {
      state.vintages = vintages
    }
  }
}