<template>
  <div class="transition">
    <div      
      :is="getComponent"
      :building_types="building_types"
      :building_type="building_type"
      :type_vintages="type_vintages"
      :type_prototypes="type_prototypes"
      :path.sync="path"
    />
  </div>
</template>

<script>
import AssumptionsBreadcrumb from '@/modules/app/assumptions/shared/AssumptionsBreadcrumb.vue'
import AssumptionsIndex from '@/modules/app/assumptions/shared/AssumptionsIndex.vue'
import AssumptionsResidential from '@/modules/app/assumptions/building-estimates/residential/AssumptionsResidential.vue'
import AssumptionsNonResidential from '@/modules/app/assumptions/building-estimates/non-residential/AssumptionsNonResidential.vue'
import AssumptionsImpacts from '@/modules/app/assumptions/impacts/AssumptionsImpacts.vue'

import { mapGetters } from 'vuex'

export default {
  name: 'JurisdictionWideAssumptions',
  components: { 
    AssumptionsIndex, 
    AssumptionsBreadcrumb, 
    AssumptionsResidential, 
    AssumptionsNonResidential, 
    AssumptionsImpacts
  },
  computed: {
    path: {
      get() {
        // return this.$store.state.assumptions.drawerPath
        return this.$store.state.assumptions.drawerOptions.path
      },
      set(path) {
        this.$store.commit('assumptions/updateDrawerPath', path)
      }
    },
    building_types() {
      return this.$store.getters['globalEntities/BuildingType/getterBuildingTypesWithTypePrototypes']
    },
    building_type() {
      return this.building_types.find((bt) => bt.slug === this.path.slug) ?? false
    },
    getComponent() {
      return this.path?.component ?? AssumptionsIndex
    },
    ...mapGetters({
      type_vintages : 'globalEntities/TypeVintage/getterGlobalTypeVintages',
      type_prototypes : 'globalEntities/TypePrototype/getterGlobalTypePrototypes'
    })
  },  
}
</script>

<style>

</style>
