<template>
  <div
    v-if="study_map"
    class="p-6"
  >
    <div class="w-full relative mt-6">
      <NativeSelectInput
        v-model="study_map.study_id"
        :items="studies"
        label="Select a study"
        key-label="title"
        key-value="id"
      />
    </div>

    <div class="w-full relative mt-6">
      <NativeSelectInput
        v-model="study_map.type_prototype_id"
        :items="type_prototypes"
        label="Select a type prototype"
        key-label="title"
        key-value="id"
        :disabled="true"
      />
    </div>

    <div class="w-full relative mt-6">
      <NativeSelectInput
        v-model="study_map.type_vintage_id"
        :items="type_vintages"
        label="Select a type vintage"
        key-label="title"
        key-value="id"
        :disabled="true"
      />
    </div>

    <hr class="border-b border-gray05 my-6">

    <div
      v-if="study_map.hasError"
      v-html="study_map.validationPhraseHtml"
    />

    <AppAlert
      v-if="isChecked && !studies.length"
      class="mt-4 noedit-alert w-auto ml-auto cursor-pointer"
      wrapper-class="py-2 px-3 border rounded" 
      description="This study map has reached all studies"
      icon="alert-triangle"
      :icon-size="14"
    />

    <button
      :disabled="study_map.hasError"
      class="mt-6 button button-blue04"
      @click="store()"
    >
      Create
    </button>
  </div>
</template>

<script>
import StudyMap from '@/models/StudyMap'
export default {
  name: 'AdminStudyMapForm',
  data() {
    return {
      study_map: new StudyMap(),
      study_maps: [],
      studies: [],
      type_prototypes: [],
      type_vintages: [],
      isChecked: false
    }
  },
  mounted() {
    if(this.$route.params.study_map_id) {
      this.getStudyMap()
    }

    this.getSelectItems()

    if(this.$route.query.type_prototype_id) this.study_map.type_prototype_id = this.$route.query.type_prototype_id
    if(this.$route.query.type_vintage_id) this.study_map.type_vintage_id = this.$route.query.type_vintage_id
    if(this.$route.query.study_type_id) this.study_map.study_type_id = this.$route.query.study_type_id

  },
  methods: {

    getSelectItems() {
      const query = `
        {
          study_types (
            orderBy: [{ column: "id", order: "ASC"}]
            ${this.$route.query.study_type_id ? `where: [{column: "id", operation: "=", value: "${this.$route.query.study_type_id}"}]` : ''}
          ) {
            id
            study_groups {
              studies {
                id title
              }
            }
          }
          type_prototypes { id title }
          type_vintages { id title }
        }
      `

      this.$graphql({ query })
        .then(({ data }) => {
          data.study_types.map((study_type) => {
            study_type.study_groups.map((study_group) => {
              this.studies = [...this.studies, ...study_group.studies]
            })
          })
          this.type_prototypes = data.type_prototypes
          this.type_vintages = data.type_vintages
          this.checkSelectedStudies()
        })
    },

    async checkSelectedStudies() {

      if(!this.$route.query.type_prototype_id && !this.$route.query.type_vintage_id) return
      const query = `
        {
          study_maps (
            where: [
              { column: "type_prototype_id", value: "${this.$route.query.type_prototype_id}"}
              { column: "type_vintage_id", value: "${this.$route.query.type_vintage_id}"}
            ]
          ){
            id
            study_id
            type_prototype_id
            type_vintage_id
          }
        }
      `
      const { data } = await this.$graphql({ query })
      if(data.study_maps) {
        this.isChecked = true
        data.study_maps.forEach((study_map) => {
          this.studies = this.studies.filter((study) => study.id != study_map.study_id)
        })
      }
    },

    store() {
      this.$api.post(`/study_map/store`, this.study_map, { loader: true })
        .then(() => {
          this.$router.push({ name: 'AdminStudyMapList' })
        })
    },

    update() {
      this.$api.post(`/study_map/update/${this.study_map.id}`, this.study_map, { loader: true })
        .then(() => {
          this.$router.push({ name: 'AdminStudyMapList' })
        })
    }
  }
}
</script>
