<template>
  <div class="flex items-center ml-3">
    <Dropdown
      ref="dropdown"
      :button-classes="!isFiltering ? 'bg-transparent rounded-full border border-gray05 text-gray04' : 'bg-blue05-50 text-blue02 border border-blue03 rounded-full'"
      :min-width-dropdown="400"
      :dropdown-dialog-classes="'min-w-300 bg-white p-6'"
      title="Building Type"
    >
      <template v-slot:buttonLabel>
        <div class="w-full flex justify-between items-center whitespace-no-wrap  px-2">
          <span>Building Type</span>
        </div>
      </template>
      <template v-slot:items>
        <div class="w-full flex flex-col font-medium text-gray02">
          <div
            v-for="studyType in study_types"
            :key="studyType.id"
            class="mb-2 last:mb-0"
          >
            <h2 class="ts--accent--book--3 mb-2">
              {{ studyType.title }}
            </h2>
                          
            <div
              v-for="type_prototype in studyType.type_prototypes"
              :key="`dropdown-study-group-${studyType.id}-study-${type_prototype.id}`"
              class="pl-3"
            >
              <Checkbox
                class="w-full"
                :label="type_prototype.title"
                :value="!checkQueryKeyValue('exclude_type_prototype_ids', type_prototype.id)"
                label-classes="ts--accent--2 text-gray04"
                @change="toggleQueryKeyValue('exclude_type_prototype_ids', type_prototype.id)"
              />
            </div>
          </div>
        </div>
      </template>
    </Dropdown>
  </div>
</template>

<script>

import StudyTypeApiService from '@/services/api/StudyTypeApiService'
export default {
  name: 'FilterStudy',
  data() {
    return {
      study_types: []
    }
  },
  computed: {
    isFiltering() {
      return this.$route.fullPath.includes('exclude_type_prototype_ids') 
    }
  },
  mounted() {
    StudyTypeApiService.getWithTypePrototypes()
      .then(({ data }) => {
        this.study_types = data.study_types
      })
  }
}
</script>