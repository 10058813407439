import GraphQL from '@/util/GraphQL'
import MainVuex from '@/store'

export default class StudyResultApiService {

  static get() {

    const isPrivateQuery  = () => {
      const isPrivate = MainVuex.getters.getterLoggedUser && MainVuex.getters.getterLoggedUserType == 'admin'
      return isPrivate ? `` : `where: [{ column: "is_private", operation: "=", value: "false" }]`
    }
    
    const query = `
        {
          type_prototypes(orderBy: [{column: "order", order: "asc"}]) {
            id
            title
            building_stock_title
            custom_fields {
              item_id
              data
            }
          }
          study_types(orderBy: [{column: "order", order: "asc"}]) {
            id
            title
            order
            created_at
            custom_fields {
              item_id
              data
            }
            study_groups(orderBy: [{column: "order", order: "asc"}]) {
              id
              title
              study_type_id
              custom_fields {
                item_id
                data
              }
              studies(
                orderBy: [{column: "study_group_order", order: "asc"}]
                ${isPrivateQuery()}
              ) {
                id
                title
                type_id
                type_content
                released_at
                published_at
                custom_fields {
                  item_id
                  data
                }
                vintages {
                  id
                  title
                  title_long
                  type_vintage_id
                  type_vintage {
                    id
                    order
                  }
                }
                prototypes {
                  id
                  title
                  type_prototype_id
                  type_prototype {
                    id
                    title
                    custom_fields {
                      item_id
                      data
                    }
                  }
                }
                fuels {
                  id
                  title
                  type_fuel_id
                  type_fuel {
                    id
                    title
                    order
                  }
                }
              }
            }
          }
        }
      `
    return GraphQL({ query }).then(({ data }) => data )  
  }

  /**
   * getStudyResultsUrl
   */
  static async getStudyResultsUrl({ jurisdiction_id , type_prototype_id, study_id }) {    

    const query = `
      {
        studies(
          where:[{column: "id", operation: "!=", value: "${study_id}"}]
        ){
          id
        },
        type_prototypes(
          where:[{column: "id", operation: "!=", value: "${type_prototype_id}"}]
        ){
          id
        },
        jurisdiction(
          where:[{column: "id", operation: "=", value: "${jurisdiction_id}"}]
        ){
          slug
          climate_zones(orderBy: [ {column: "prefix", order: "asc"}]) {
            raw
          }
        }
      }
    `
    const { data : { jurisdiction, studies, type_prototypes }} = await GraphQL({ query })
    if (!jurisdiction?.slug) return false
    
    const route = { name: 'StudyResults', params: {}, query: {} }
    route.params.jurisdiction_slug = jurisdiction.slug
    route.params.climate_zone_raw = jurisdiction?.climate_zones?.[0]?.raw
    route.query.exclude_study_ids = studies.map(obj => obj.id).join(',')
    route.query.exclude_type_prototype_ids = type_prototypes.map(obj => obj.id).join(',')
    
    return route
  }

}