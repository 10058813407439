<template>
  <div>
    <div class="w-full">
      <router-link
        :to="{ name: 'PolicyShow', params: { policy_id: this.$route.params.policy_id }}"
        class="ml-4 text-p-1 text-ash-450 flex items-center space-x-2"
      >
        <feather
          type="chevron-left"
          size="16"
        />
        <span>Impact estimates / Edit measures in</span>
      </router-link>
    </div>
    <div class="w-full rounded border border-blue05 p-6 mt-6 bg-white">
      <div class="w-full flex flex-col text-bluebrand text-base font-bold">
        <h3 class="mb-6">
          <vue-skeleton-loader
            :width="200"
            :height="39"
          />
        </h3>
        <div class="w-full py-4 xl:py-0">
          <div class="w-full flex items-center space-x-1 text-ash-450 text-p-2 font-light mb-4">
            <feather
              type="folder"
              size="18"
            />
            <vue-skeleton-loader
              :width="600"
              :height="18"
            />
          </div>
        </div>
        <div class="grid grid-cols-7 gap-4">
          <vue-skeleton-loader
            v-for="index in 42"
            :key="index"
            :width="Math.round((Math.random() * 60) + 100)"
            :height="24"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SkeletonPolicyEditCustomCombination'
}
</script>