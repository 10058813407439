import Vue from 'vue'
import VueIntercom from '@mathieustan/vue-intercom'

export const getIntercomUser = (user) => {
  if (user) {
    return {
      user_id : user.id,
      email : user.email
    }
  }
  return false
}

export default (Store) => {

  Vue.use(VueIntercom, { appId: 'm73wzwth' })
  
  /**
   * Watch user change
   */
  Store.subscribe((mutation) => {

    
    if(mutation.type === 'setLoggedUser' ) {
      const intercom = Store._vm.$intercom
      if (!intercom.isBooted) {
        intercom.boot(getIntercomUser(Store.getters['getterLoggedUser']))
      } else {
        intercom.update(getIntercomUser(Store.getters['getterLoggedUser']))
      }
    }
    
    if(mutation.type === 'setLogout') {
      const intercom = Store._vm.$intercom
      intercom.update(false)
    }
    
    setTimeout(() => {
      const intercom = Store._vm.$intercom
      intercom.boot(getIntercomUser(Store.getters['getterLoggedUser']))
    }, 1000)
    
  })

}