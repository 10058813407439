
import MainVuex from '@/store'
import Graphql from '@/util/GraphQL'

const isAdmin = () => {
  return MainVuex.getters.getterLoggedUser && MainVuex.getters.getterLoggedUserType == 'admin'
}
export default class PrototypeApiService {

  static async getPrototypesByTypePrototypeAndStudyGroupOrderedByStudyReleasedAt({ typePrototype, studyGroup }) {
        
    const studyGroupStudiesIds = [...studyGroup.studies].map(study => `"${study.id}"`).join(',')

    const query = `
      {
        prototypes(
          where: [{column: "type_prototype_id", operation: "=", value: "${typePrototype.id}"}],
          whereIn: [{column: "study_id", value: [${studyGroupStudiesIds}]}]
        ) {
          id
          title
          type_prototype_id
          study_id
          study {
            id
            released_at
            published_at
            is_private
          }
        }
      }
    `
    const { data } = await Graphql({ query })
    
    if (data.prototypes) {
      const allPrototypesByTypePrototypeAndStudyGroup = data.prototypes
      const filteredByPrivateMode = allPrototypesByTypePrototypeAndStudyGroup.filter(prototype => prototype.study.is_private && !isAdmin ? false : true)
      const orderedByStudyReleseadAt = filteredByPrivateMode.sort((a, b) => new Date(b.study.released_at) - new Date(a.study.released_at))
      return orderedByStudyReleseadAt
    }

    return []
    
  }

  
}