import { MathHelpers } from '@igortrindade/lazyfy'

export default ({ items, from, to }) => {
  const itsAllZeros = items.reduce((acc, cb) => acc + cb.units, 0)
  for(const custom_buildings of items) {
    if(to == 0) custom_buildings.units = 0
    if(itsAllZeros === 0) {
      custom_buildings.units = (to / items.length)
    } else if(items.length === 1) {
      custom_buildings.units = to
    } else {
      let oldPercentage = MathHelpers.getPercentageOfAmount(from, custom_buildings.units, false, 10)
      if(isNaN(oldPercentage)) oldPercentage = 0
      custom_buildings.units = MathHelpers.getAmountOfPercentage(to, oldPercentage)
    }
  }

  return items

}
