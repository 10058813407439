<template>
  <div class="w-full h-full">
    <div
      v-if="policy && policy.is_flexible_path"
      class="w-full grid grid-cols-12"
    >
      <div 
        class="flexiblepath-sidebar flex flex-col pt-11 bg-white border-t border-ash-200 px-4"
        :class="{
          'col-span-12 pl-10 pr-10' : getterContentContainerWidth <= 1080,
          'col-span-3 pr-0' : getterContentContainerWidth > 1080,
          'pb-7 pl-10' : getterContentContainerWidth >= 1380,
        }"
      >
        <div
          class="w-full flex justify-start flex-wrap"
          :class="{'space-y-2' : getterContentContainerWidth > 1080 && getterContentContainerWidth < 1250,}"
        >
          <button 
            class="text-header-2 text-ash-700 flex items-center transition-all hover:opacity-70 mr-5"
            @click="$eventBus.$emit('openModalPolicyFlexiblePathDownload')"
          >
            <feather
              type="download"
              class="text-deepsky-450 mr-2 flex-shrink-0"
            />
            Download tables
          </button>
          <button 
            class="text-header-2 text-ash-700 flex items-center transition-all hover:opacity-70 mr-5"
            @click="$store.commit('tutorial/setTutorialShow', { type: 'flexiblePath', show: true })"
          >
            <feather
              type="info"
              class="text-deepsky-450 mr-2 flex-shrink-0"
            />
            Quick Tutorial
          </button>
        </div>
        <div class="w-full flex flex-col pr-6">
          <p class="text-header-2 text-ash-500 mt-4">
            Allow permit applicants to choose the measures that work best for them from a menu.
          </p>
          <button
            class="text-header-2 flex justify-start text-deepsky-500 text-sm font-bold mt-2"
            @click="$store.commit('tutorial/setTutorialShow', { type: 'flexiblePath', show: true })"
          >
            Learn more
          </button>
        </div>
        <template v-if="type_prototypes.length > 0">
          <div 
            class="w-full mt-6 grid"
            :class="{
              'grid-cols-2 gap-4' : getterContentContainerWidth <= 1080,
              'grid-cols-1 gap-0' : getterContentContainerWidth > 1080,
            }"
          >
            <button
              v-for="type_prototype of type_prototypes" 
              :key="`type_prototype-${type_prototype.id}`"              
              class="w-full flex items-center space-x-4 p-3 transition duration-300"
              :class="[ 
                type_prototype.id == type_prototype_selected.id ? 'bg-deepsky-100 text-deepsky-450 border-deepsky-450' : 'bg-ash-200 text-ash-500 border-ash-300 hover:bg-deepsky-100 hover:text-deepsky-450 hover:border-deepsky-450',
                {
                  'border-b-4' : getterContentContainerWidth <= 1080,
                  'border-b-0 border-r-4 my-2 rounded-l-md' : getterContentContainerWidth > 1080,
                }
              ]"
              @click="setTypePrototype(type_prototype)"
            >
              <div                 
                class="p-3 rounded-full"
                :class="[
                  { 'hidden' : getterContentContainerWidth > 1080 && getterContentContainerWidth < 1380 }, 
                  type_prototype.id == type_prototype_selected.id ? 'bg-deepsky-200' : 'bg-ash-200'
                ]"
              >
                <inline-svg 
                  :src="`${type_prototype.icon}`"
                  width="48" 
                  height="48"
                  aria-label="Type prototype"
                  :stroke="type_prototype.id == type_prototype_selected.id ? '#69A7BF' : '#A9AEB0'"
                />
              </div>
              <h3 class="text-header-3 font-bold">
                {{ type_prototype.title }}
              </h3>
            </button>
            <p class="ts--accent--2 mt-5 mb-8 text-gray03">
              *Please note that the Flexible Paths feature is still in beta. Please <a
                class="underline text-gray03"
                target="_blank"
                href="https://localenergycodes.com/content/contact-us"
                title="Contact us"
              >email us</a> any feedback or corrections.
            </p>
          </div>
        </template>
        <SkeletonTypePrototypeList v-else />
      </div>

      <div
        class="flexiblepath-content w-full flex flex-col p-10 bg-ash-200 overflow-y-auto"
        :class="{
          'col-span-12' : getterContentContainerWidth <= 1080,
          'col-span-9' : getterContentContainerWidth > 1080,
        }"
      >    
        <AppAlert
          v-if="type_prototype_selected && type_prototype_selected.id === 1"
          class="w-full cursor-pointer mt-4"
          icon="alert-circle"
          wrapper-class="py-5 px-6 mb-6 border rounded"
          description="Please note: a significant revision of this study is coming soon for multifamily! <br />This will include electrification and PV measures, as well as additional efficiency measures."
          :icon-size="24"
        />
        <template v-if="prototypes.length && flexible_path_setup">
          <PolicyFlexiblePathMeasureMenuTables
            v-for="(prototype, index) of prototypes"
            :key="`prototype-${prototype.id}-${index}`"
            :policy="policy"
            :type_vintages="type_vintages"
            :prototype="prototype" 
            :flexible_path_setup="flexible_path_setup"
            :flexible-path-service="flexiblePathService"
            class="mx-auto xl:mr-auto"
            @updateFlexiblePathSetup="updateFlexiblePathSetup"
          />
        </template>
        <SkeletonPolicyFlexiblePathMeasureMenuTables v-else />

        <BaseTutorial 
          title="Creating Flexible Paths"
          type="flexiblePath"
        >
          <TutorialItem
            :position="1"
            type="flexiblePath"
            title="Why offer a flexible compliance path?"
            show-done-button="true"
          >
            <p class="text-ash-500 mb-3">
              Watch this quick video to learn about flexible compliance policies, measure menu tables, and target scores.
            </p>
            <button
              class="w-full mt-2"
              @click="$eventBus.$emit('openModalVideos', { video_id: '_DSofCEeqEQ', title: 'Flexible Paths' })"
            >
              <img src="/images/tutorial/flexible_path/1_understand_how_it_works.png">
            </button>
          </TutorialItem>
          <TutorialItem
            :position="2"
            type="flexiblePath"
            title="How to customize my Flexible Compliance Tables?"
            show-done-button="true"
          >
            <button
              class="w-full mt-2"
              @click="$eventBus.$emit('openModalVideos', { video_id: 'ZJuHMXFnIiA', title: 'Flexible Paths' })"
            >
              <img src="/images/tutorial/flexible_path/1_understand_how_it_works.png">
            </button>            
            <p class="text-ash-500 mb-3">
              After reviewing and editing these tables, you can download them to include with your policy documents, or share them with a colleague for discussion via a link.
            </p>
            <p class="text-ash-500 mb-3">
              To comply with your policy, permit applicants will first locate the Target Score required for their home.
            </p>
            <p class="text-ash-500 mb-3">
              Then, they are free to install any combination of measures from the table that add up to the Target Score. Measures marked ‘Mandatory’ must also be installed.
            </p>
            <p class="text-ash-500">
              Watch this quick video to understand all the available setup options.
            </p>
          </TutorialItem>
          <TutorialItem
            :position="3"
            type="flexiblePath"
            title="Adjust the target score"
            show-done-button="true"
          >
            <p class="text-ash-500 mb-3">
              Target Scores are initially set to half of the maximum cost-effective score.
            </p>
            <p class="text-ash-500 mb-3">
              Are these Target Scores too hard to achieve, or not hard enough? Adjust them by clicking directly on the icon next to a Target Score value, or clicking on the value directly.
            </p>
            <p class="text-ash-500">
              The maximum cost-effective score equals the total annual energy savings (MMBtus) from the cost-effective measures included in your policy.
            </p>
          </TutorialItem>
          <TutorialItem
            :position="4"
            type="flexiblePath"
            title="Delete unwanted measures"
            show-done-button="true"
          >
            <img
              src="/images/tutorial/flexible_path/delete.gif"
              class="mb-3"
            >
            <p class="text-ash-500 mb-3">
              See any measures that you don't want to include in your menu?
            </p>
            <p class="text-ash-500">
              Hover over a cell and click on the trash icon. You can restore it at any time by clicking on the icon again.
            </p>
          </TutorialItem>
          <TutorialItem
            :position="5"
            type="flexiblePath"
            title="Make measures mandatory"
            show-done-button="true"
          >
            <img
              src="/images/tutorial/flexible_path/mandatory.gif"
              class="mb-3"
            >
            <p class="text-ash-500 mb-3">
              You will notice some measures are already set as mandatory. These measures do not save enough energy to earn a score of at least 1.
            </p>
            <p class="text-ash-500 mb-3">
              See any other measures that you want to require in your menu?
            </p>
            <p class="text-ash-500">
              Hover over a cell and click on the star icon. You can restore it at any time by clicking on the icon again.
            </p>
          </TutorialItem>
          <TutorialItem
            :position="6"
            type="flexiblePath"
            title="Download your menu"
            show-close-button="true"
          >
            <img
              src="/images/tutorial/flexible_path/download.gif"
              class="mb-3"
            >
            <p class="text-ash-500">
              Now that everything is set, you can download this document and send it to stakeholders or colleagues to get feedback on what you built!
            </p>
          </TutorialItem>
        </BaseTutorial>
      </div>
      
      <PolicyFlexiblePathDownloadModal />
    </div>

    <PolicyFlexiblePathNotAvailable
      v-else-if="policy"
      :policy="policy"
    />
  </div>
</template>

<script>
import SkeletonTypePrototypeList from './skeleton/SkeletonTypePrototypeList.vue'
import SkeletonPolicyFlexiblePathMeasureMenuTables from './skeleton/SkeletonPolicyFlexiblePathMeasureMenuTables.vue'
import AppAlert from '@/components/general/AppAlert.vue'
import PolicyFlexiblePathMeasureMenuTables from './PolicyFlexiblePathMeasureMenuTables.vue'
import FlexiblePathService from '@/services/api/FlexiblePathService'
import PolicyFlexiblePathDownloadModal from './PolicyFlexiblePathDownloadModal'
import PolicyFlexiblePathNotAvailable from './PolicyFlexiblePathNotAvailable'
import { mapGetters } from 'vuex'


const DEFAULT_FLEXIBLE_PATH_SETUP = {
  version: 2,
  tiers: []
}   
export default {
  name: 'PolicyFlexiblePath',
  components: { 
    PolicyFlexiblePathMeasureMenuTables, 
    PolicyFlexiblePathDownloadModal, 
    AppAlert,
    SkeletonTypePrototypeList,
    SkeletonPolicyFlexiblePathMeasureMenuTables,
    PolicyFlexiblePathNotAvailable,
  },
  props: ['policy'],
  data() {
    return {
      type_prototypes: [],
      type_prototype_selected: null,
      prototypes: [],
      type_vintages: [],
      flexible_path_setup: DEFAULT_FLEXIBLE_PATH_SETUP,
      showModalPolicyFlexiblePathDownload: false,
      flexiblePathService: new FlexiblePathService({ policy_id : this.$route.params.policy_id }),
    }
  },
  computed: {
    ...mapGetters({getterContentContainerWidth: 'general/getterContentContainerWidth'})
  },
  mounted() {
    this.getSetup()
    this.getTypePrototypes()
    this.$store.dispatch('setModuleVisited', 'flexible_paths')
    this.$eventBus.$on('updateFlexiblePathSetup', this.updateFlexiblePathSetup)
  },
  beforeDestroy() {
    this.$store.commit('tutorial/setTutorialCurrentPosition', { type: 'flexiblePath', position: null })
    this.$eventBus.$off('updateFlexiblePathSetup')
  },
  methods: {
   
    async getSetup() {
      const setup = await this.flexiblePathService.getSetup()
      this.flexible_path_setup = { ...setup }
    },

    getTypePrototypes() {
      this.flexiblePathService.getTypePrototypes().then((type_prototypes) => {          
        this.type_prototypes = type_prototypes
        this.setTypePrototype(this.type_prototypes[0])
      })
    },

    setTypePrototype(type_prototype) {
      this.type_prototype_selected = type_prototype
      this.setTypePrototypeItems(type_prototype.id)
    },

    setTypePrototypeItems(type_prototype_id) {
      this.prototypes, this.type_vintages = []
      this.flexiblePathService.getTypePrototypeItems({ type_prototype_id }).then(({ prototypes, type_vintages }) => {
        this.prototypes = prototypes ?? []
        this.type_vintages = type_vintages ?? []
      })
    },

    updateFlexiblePathSetup(flexible_path_setup) {
      this.flexible_path_setup = { ...flexible_path_setup }
      if(this.$store.getters.getterLoggedUser && this.$store.getters.getterLoggedUser.id == this.policy.user_id) {
        this.flexiblePathService.updateSetup(this.flexible_path_setup)
      }
    },

    resetFlexiblePathSetup() {
      const setup  = { version: 2, tiers: [] }
      if(this.$store.getters.getterLoggedUser && this.$store.getters.getterLoggedUser.id == this.policy.user_id) {
        this.$api.post(`/api/flexible_path_setup/updateOrCreate`, { policy_id: this.policy.id, data: setup }, { ignoreFeedback: true })
      }
      this.flexible_path_setup = { ...setup }
    },

    exportFile() {
      window.open(process.env.VUE_APP_BASE_API_URL + `/api/measure_menu/export/${this.$route.params.policy_id}`, "_blank")
    }
  }
}
</script>

<style scoped lang="scss">
  // .flexiblepath-sidebar {
  //   max-width: 476px;
  // }
  .app-alert {
    max-width: 837px;
    margin-left: auto;
    margin-right: auto;
  } 
</style>