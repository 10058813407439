<template>
  <div
    class="savetopolicy-form"
    :class="{ 'pointer-events-none transition-all': !storeRequestIsFinished }"
  >
    <h2 class="ts--accent--1 text-bluebrand font-bold">
      Save selection to a policy
    </h2>
    <p
      v-if="jurisdictionSelected"
      class="text-header-1 text-gray03 mt-1 mb-5"
    >
      {{ jurisdictionSelected.label }}
    </p>
    
    <AppLoader
      v-if="isLoadingUserPolicies"
      class="mt-6"
    />

    <div
      v-else
      class="w-full flex flex-col"
    >
      <div
        v-if="userPolicies.length"
        class="policies-list w-full flex flex-col overflow-y-auto pr-3"
      >
        <button 
          v-for="policy in userPolicies"
          :key="policy.id"
          class="policy-item transition-all px-2 py-3 border relative rounded-sm mb-3 text-left"
          :class="{ 
            'border-blue04 bg-blue04-0 opacity-100 is-selected' : policy.isSelected,
            'border-blue05' : !policy.isSelected,
            'cursor-pointer' : !checkUserHasCustomCombination(policy),
          }"
          @click="selectPolicy(policy)"
        >
          <span class="px-6 flex flex-col">
            <Checkbox
              :value="policy.isSelected"
              :label="policy.title" 
              :class="{ 'font-bold' : policy.isSelected }"
              :prevent="true"
              checkbox-classes="mb-0"
            />
            <span
              v-if="policy.updated_at"
              class="text-gray03 text-p-1"
            >Edited: {{ formatDate({ date: policy.updated_at}) }}</span>
          </span>
          <div 
            v-if="!policy.isSelected && checkUserHasCustomCombination(policy)" 
            class="absolute top-0 right-0 mr-6 h-full flex items-center text-gray04"
          >
            <ToolTip
              :has-action="true"
              dialog-classes="p-4 right-0 bg-ash-100 border border-ash-300 max-w-xs"
            >
              <template v-slot:trigger>
                <feather
                  type="info"
                  size="16"
                  class="text-gray03"
                />
              </template>
              <template v-slot:dialog>
                <div v-html="getPolicyToolTipText(policy)" />
              </template>
            </ToolTip>
          </div>
          <div 
            v-if="policy.isSelected && checkUserHasCustomCombination(policy)"
            class="w-full flex-shrink-0 mt-2 pl-6 text-header-1 text-ash-500"
          >
            The previously saved measures will be replaced.
          </div>
        </button>
      </div>

      <transition
        name="fade"
        mode="out-in"
      >
        <div
          v-if="userPoliciesSelected.length"
          class="add-to-policy-wrapper flex item-center flex-col mt-4"
        >
          <span class="my-auto">
            <AppButton 
              :label="userPoliciesSelected.length > 1 ? 'Save To Policies' : 'Save To Policy'"
              size="medium"
              @click="saveToSelectedPolicies()" 
            />
          </span>
        </div>
      </transition>

      <p
        v-if="userPolicies.length"
        class="mt-6 mb-8 w-full or-separator border-blue05 text-gray03"
      >
        <span>or</span>
      </p>

      <PoliciesFormCreate @store-success="onPolicyStoreSuccess" />
    </div>
  </div>
</template>

<script>
import PoliciesFormCreate from '@/modules/app/policy/shared/PoliciesFormCreate.vue'
import AppButton from '@/components/general/AppButton.vue'
import Checkbox from '@/components/general/Checkbox.vue'
import CustomCombination from '@/models/CustomCombination'
import Policy from '@/models/Policy'
import Jurisdiction from '@/models/Jurisdiction'
import CustomCombinationApiService from '@/services/api/CustomCombinationApiService'

export default {
  name: 'CustomCombinationToPolicyForm',
  components: { Checkbox, AppButton, PoliciesFormCreate },
  props: {
    measures : {
      type: Array,
      required: true
    },
    study_id: {
      type: Number,
      required: true
    },
    prototype_id: {
      type: Number,
      required: true,
    },
    vintage_id: {
      type: Number,
      required: true
    },
  },
  data: ()=> ({
    userPolicies : [],
    storeRequestIsFinished : true,
    isLoadingUserPolicies: false,
  }),
  computed: {
    userPoliciesSelected() {
      return this.userPolicies.filter(policy => policy.isSelected)
    },
    jurisdictionSelected() {
      return new Jurisdiction(this.getJurisdictionSelected)
    },
    getJurisdictionSelected() {
      return this.$store.getters['lastJurisdictionVisited']
    }
  },
  mounted() {
    this.getUserPolicies()    
  },
  methods: {
    async getUserPolicies() {
      this.isLoadingUserPolicies = true
      const query = `
        {
          currentUser {
            policies (
              where: [
                { column: "jurisdiction_id", operation: "=", value: "${this.getJurisdictionSelected.id}"}
              ]
            ) {
              id
              title
              annual_penetration_rate
              active_policy_duration
              updated_at
              jurisdiction_id
              jurisdiction {
                title
                type
              }
              custom_combinations {
                id
                climate_zone_raw
                study_id
                prototype_id
                vintage_id
                jurisdiction {
                  title
                  type
                }
                climate_zone { raw }
                vintage { title }
                prototype { title }
                measures {
                  id
                  title
                }
              }
            }
          }
        }
      `

      const { data } = await this.$graphql({ query })

      // data.currentUser.policies = [ ... data.currentUser.policies, ...data.currentUser.policies, ...data.currentUser.policies ]
      this.userPolicies = data.currentUser.policies.map(policy => {
        return { ...new Policy({ ...policy }), isSelected: false } 
      })
      this.isLoadingUserPolicies = false
    },

    selectPolicy(policy) {
      policy.isSelected = !policy.isSelected
    },

    saveToSelectedPolicies() {
      this.storeRequestIsFinished = false
      const promises = this.userPoliciesSelected.map(policy => this.storeCustomCombination(policy) )
      Promise.all(promises)
        .then(() => {
          this.userPoliciesSelected.forEach(policy => this.onStoreCombinationSuccess(policy))
          this.storeRequestIsFinished = true
          this.$emit('success')
        })
    },    
    onPolicyStoreSuccess(policy) {  
      this.storeCustomCombination(policy)
        .then((res) => {
          console.log(res)
          if (res) this.onStoreCombinationSuccess(policy)
          this.$emit('success')
        })
    },
    storeCustomCombination(policy) {
      const customCombination = new CustomCombination({
        policy_id : policy.id,
        jurisdiction_id : policy.jurisdiction_id,
        climate_zone_raw : this.$store.getters['lastClimateZoneVisited'].raw,
        study_id : this.study_id,
        prototype_id: this.prototype_id,
        vintage_id: this.vintage_id,
        measures: this.measures
      })
      return CustomCombinationApiService.store(customCombination)    
    },
    onStoreCombinationSuccess(policy) {
      this.$appToast({
        message: `${this.measures.length} measure${this.measures.length > 1 ? 's' : ''} added to ${policy.title}`,
        feather: 'check',
        anchor: {
          title: 'GO TO POLICY',
          href: `/policies/${policy.id}`
        },
        duration: 5500
      })
    },
    checkUserHasCustomCombination(policy) {
      for(const custom_combination of policy.custom_combinations) {
        if(
          custom_combination.climate_zone_raw == this.$store.getters['lastClimateZoneVisited'].raw &&
          custom_combination.study_id == this.study_id &&
          custom_combination.prototype_id == this.prototype_id &&
          custom_combination.vintage_id == this.vintage_id
        ) return true
      }
      return false
    },

    getPolicyToolTipText(policy) {
      let climate_zone_raw, prototype, vintage

      for(const custom_combination of policy.custom_combinations) {
        if(custom_combination.climate_zone_raw == this.$store.getters['lastClimateZoneVisited'].raw) climate_zone_raw = custom_combination.climate_zone_raw
        if(custom_combination.prototype_id == this.prototype_id) prototype = custom_combination.prototype.title
        if(custom_combination.vintage_id == this.vintage_id) vintage = custom_combination.vintage.title
      }

      return `
        <p class="font-bold text-ash-700">
          You already have measures saved for ${prototype.toLowerCase()} built ${vintage.toLowerCase()}
          in climate zone ${climate_zone_raw.replace(/\D/g, '')}.
        </p>
        <p class="mt-1 text-ash-700">Those measures will be overwritten if you proceed.</p>
      `
    },
  }
}
</script>

<style lang="scss" scoped>
.savetopolicy-form {
  min-width: 395px;
}
.policies-list {
  max-width: 395px;
  max-height: 274px;
}
.is-selected {
  background-color: #F2F7F9;
}

::v-deep {
  .policy-item {
    &.is-selected, &:hover {
      .app-checkbox-item-input {
        opacity: 1;
      }
    }
  }
  .app-checkbox-item-input {
    position: absolute;
    left: 8px;
    opacity: 0;
    + span {
      margin-left: 0;
    }
  }
}
</style>