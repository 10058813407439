import AdminStudyGroupIndex from '@/modules/admin/study-group/AdminStudyGroupIndex'
import AdminStudyGroupEdit from '@/modules/admin/study-group/AdminStudyGroupEdit'
import AdminStudyGroupList from '@/modules/admin/study-group/AdminStudyGroupList'
import AdminStudyGroupStudies from '@/modules/admin/study-group/AdminStudyGroupStudies'
import AdminStudyGroupForm from '@/modules/admin/study-group/AdminStudyGroupForm'
import AdminStudyGroupStudy from '@/modules/admin/study-group/AdminStudyGroupStudy'
import AdminStudyForm from '@/modules/admin/study/AdminStudyForm'

import baseAdminMeta from '@/modules/admin/baseAdminMeta'

export default [
  {
    path: 'study-groups',
    component: AdminStudyGroupIndex,
    meta: { ...baseAdminMeta, title: 'StudyGroups', header: false },
    children: [
      {
        name: 'AdminStudyGroupList',
        path: '',
        component: AdminStudyGroupList,
        meta: { ...baseAdminMeta, header: false }
      },
      {
        name: 'AdminStudyGroupEdit',
        path: ':study_group_id',
        component: AdminStudyGroupEdit,
        meta: { ...baseAdminMeta, header: false, tabTitle: 'Edit StudyGroup' }
      },
      {
        name: 'AdminStudyGroupStudies',
        path: ':study_group_id/studies',
        component: AdminStudyGroupStudies,
        meta: { ...baseAdminMeta, header: false, tabTitle: 'StudyGroup Studies' }
      },
      {
        name: 'AdminStudyGroupStudyAdd',
        path: ':study_group_id/studies/add',
        component: AdminStudyForm,
        meta: { ...baseAdminMeta, header: false, tabTitle: 'Add StudyGroup Study' }
      },
      {
        name: 'AdminStudyGroupStudyEdit',
        path: ':study_group_id/studies/:study_id',
        component: AdminStudyGroupStudy,
        meta: { ...baseAdminMeta, header: false, tabTitle: 'Editing StudyGroup Study' }
      },
    ]
  },
  {
    name: 'AdminStudyGroupCreate',
    path: 'create',
    component: AdminStudyGroupForm,
    meta: { ...baseAdminMeta, title: 'Study group create' }
  },
  
]