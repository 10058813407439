import getStudyColumnsBySelectedStatus from '@/business-logic/services/columns/getStudyColumnsBySelectedStatus'
export default {
  getterPolicyColumns: (state, getters, rootState, rootGetters) => {
    const columns = { ... state.policyColumns}
    const jurisdictionType = rootGetters['lastJurisdictionVisited']?.type
    if (jurisdictionType) {
      const cityWideColumnGroup = columns.existingBuildings.columnGroups.find((columnGroup) => columnGroup.key === 'city_wide_impact')
      cityWideColumnGroup.title = `${jurisdictionType}-wide Estimates`
      if(jurisdictionType == 'City') {
        cityWideColumnGroup.hasHelper = {
          type: 'helper',
          id: 25
        }
      } else {
        cityWideColumnGroup.hasHelper = {
          type: 'helper',
          id: 26
        }
      }
    }
    return columns
  },
  getterPolicyExistingBuildingsColumns: (state, getters) => getters.getterPolicyColumns['existingBuildings'],
  getterPolicyExistingBuildingsColumnsSelected: (state, getters) => getStudyColumnsBySelectedStatus(getters.getterPolicyColumns['existingBuildings'], true),
  getterPolicyExistingBuildingsColumnsUnselected: (state, getters) => getStudyColumnsBySelectedStatus(getters.getterPolicyColumns['existingBuildings'], false),
  getterPolicyNewBuildingsColumns: (state, getters) => getters.getterPolicyColumns['newBuildings'],
  getterPoliciesOpened: (state) => state.policiesOpened.sort((a, b) => { return b.timestamp - a.timestamp }),
  getterHiddenItems: state => state.hiddenItems,
  getterUserPoliciesByJurisdiction(state, getters, rootState, rootGetters) {
    const jurisdiction = rootGetters['lastJurisdictionVisited']
    return jurisdiction ? state.userPolicies.filter((policy) => policy.jurisdiction_id == jurisdiction.id) : []
  },
  getterAllUserPolicies: state => filters => {
    if(!filters) return [...state.userPolicies, ...state.userSharedPolicies]
    return [...state.userPolicies, ...state.userSharedPolicies]
      .findAllInArray(filters)
  }
}
