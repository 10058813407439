<template>
  <div class="mx-auto xl:mr-auto">
    <div class="flexiblepath-prototypecard w-full bg-white flex flex-col p-10 mb-10">
      <h4 class="text-accent-2 inline-block text-ash-450">
        MEASURE MENU TABLES
      </h4>
      <vue-skeleton-loader
        class="mt-1"
        :width="300"
        :height="26"
      />
      <vue-skeleton-loader
        class="mt-3"
        :width="757"
        :height="15"
      />
      <vue-skeleton-loader
        class="my-6"
        :width="757"
        :height="179"
      />
      <vue-skeleton-loader
        v-for="index in 12"
        :key="index"
        class="mt-2 w-full"
        :width="757"
        :height="26"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SkeletonPolicyFlexiblePathMeasureMenuTables'
}
</script>