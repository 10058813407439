<template>
  <div class="w-full h-full flex flex-col justify-center items-center p-6">
    <div class="max-w-xl w-full bg-white rounded">
      <div
        class="w-full rounded-t bg-white p-8 flex space-x-6"
        :class="{'rounded-b' : !policy.is_auto_generated}"
      >
        <div class="w-3/4">
          <h3 class="text-deepsky-900 font-bold text-header-5 my-3">
            How does flexible compliance work?
          </h3>

          <p class="my-px text-deepsky-900 text-header-4">
            People will be able to choose measures that work best for them as long as the <b>measure scores</b> add up to a required <b>target</b> score
          </p>
          <p class="mt-3 text-ash-600 text-header-3">
            Your policy will include a table of optional measures, each with its own measure score. Measure scores are based on annual energy savings estimated by a study for that measure.
          </p>
        </div>
        <button @click="$eventBus.$emit('openModalVideos', { video_id: '_DSofCEeqEQ', title: 'Why offer a flexible compliance path?' })">
          <img
            src="/images/flexible_paths/video-thumbnail-example.png"
            width="332"
          >
        </button>
      </div>
      <div
        v-if="policy.is_auto_generated"
        class="w-full rounded-b bg-ash-100 p-4 px-8 flex space-x-4"
      >
        <AppButton
          label="CREATE A FLEXIBLE PATH FOR THIS POLICY"
          size="medium"
          @click="updatePolicyAndOpenPolicyWizard()"
        />

        <button
          class="text-header-1 text-ash-450 font-bold flex items-center space-x-2"
          @click="$refs.modalSupportedStudyVersions.open()"
        >
          <feather
            type="alert-circle"
            size="18"
            class="mr-2"
          />
          SUPPORTED STUDY VERSIONS
        </button>
      </div>
    </div>

    <div
      v-if="!policy.is_auto_generated"
      class="max-w-xl w-full bg-deepsky-300 rounded p-3 px-4 mt-4 text-deepsky-700 flex items-center font-bold"
    >
      <div class="block flex items-center w-7  mr-3">
        <feather
          class="block "
          size="26"
          type="alert-circle"
        />
      </div>
      <h5 class="text-header-2 ml-0">
        Flexible paths are not available for the measures you selected in this policy. You can try this feature creating a new flexible compliance policy.
      </h5>
      
      <div class="flex space-x-4 items-end ml-auto whitespace-no-wrap">
        <button 
          class="text-accent-1 flex items-center space-x-2" 
          @click="$eventBus.$emit('openModalAutoGeneratePolicyWizard', { policy: { is_auto_generated: true, is_flexible_path: true }})"
        >
          CREATE A FLEXIBLE COMPLIANCE POLICY
        </button>
        <button 
          class="text-accent-1 flex items-center space-x-2"
          @click="$refs.modalSupportedStudyVersions.open()"
        >
          STUDIES SUPPORT
        </button>
      </div>
    </div>
    <ModalSupportedStudyVersions ref="modalSupportedStudyVersions" />
  </div>
</template>

<script>
import ModalSupportedStudyVersions from './ModalSupportedStudyVersions.vue'
import PolicyApiService from '@/services/api/PolicyApiService'
export default {
  name: 'PolicyFlexiblePathNotAvailable',
  components: { ModalSupportedStudyVersions },
  props: ['policy'],
  methods: {
    async updatePolicyAndOpenPolicyWizard() {
      await PolicyApiService.update(this.policy.id, { is_flexible_path: true })
      this.$eventBus.$emit('openModalAutoGeneratePolicyWizard', { stepId: 'generating-policy-loader', policy: { ...this.policy, is_auto_generated: true, is_flexible_path: true, policy_active_tab: 'flexible_path' }})
    }
  }
}
</script>

<style>

</style>