<template>
  <div class="text-14 text-ash-450 flex items-center space-x-2">
    <span>{{ getVintageMeasuresText }}</span>
    <div
      v-tooltip="getTooltip"
      class="rounded-full h-6  w-6 flex items-center justify-center"
      :class="[getMeasuresIsAllPossible ? 'bg-lime-200 text-lime-500' : 'bg-transparent text-ash-450']"
    >
      <feather
        type="layers"
        size="15"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PolicyScopeMeasuresIcon',
  props: ['vintage', 'climate_zone'],
  data() {
    return {
      allPossibleMeasures: []
    }
  },
  computed: {
    getTooltip() {
      return this.getMeasuresIsAllPossible ? 'You\'ve combined all possible cost-effective measures for this vintage!' : false
    },
    getVintageMeasuresText() {
      if(this.vintage.measures.length == 1) return '1 measure'
      return this.vintage.measures.length + ' measures'
    },
    getMeasuresIsAllPossible() {
      const allPossibleMeasuresIds = this.allPossibleMeasures.map( item => item.id)
      const measuresIds = this.vintage.measures.map( item => item.id).filter(item => allPossibleMeasuresIds.includes(item))
      return measuresIds.length == allPossibleMeasuresIds.length
    }
  },
  mounted() {
    this.getAllPossibleMeasure()
  },
  methods: {

    getAllPossibleMeasure() {
      this.$api.post(`/api/measure/get_all_possible`, { 
        vintage_id: this.vintage.id, 
        prototype_id:  this.vintage.prototype_id, 
        climate_zone_raw: this.climate_zone.raw
      })
        .then(({ data }) => {
          this.allPossibleMeasures = data.measures
        })
    }
  }
}
</script>