<template>
  <div class="policy-form">
    <h2 
      class="ts--accent--book--3 mb-3"
      :class="`${newPolicyTitle ? 'text-bluebrand' : 'text-gray03'}`"
    >
      Create a new policy
    </h2>
    <InputWithLabel
      v-model="newPolicyTitle"
      label="Policy Name"
      :validation="validation.title"
      @keyup.enter="submit"
    />
    <transition
      name="fade"
      mode="out-in"
    >
      <AppButton 
        v-if="newPolicyTitle.length > 0" 
        class="mt-5" 
        size="medium" 
        :label="submitFormLabel"
        @click="submit()"
      />
    </transition>
  </div>
</template>

<script>
import InputWithLabel from '@/components/general/InputWithLabel.vue'
import Policy from '@/models/Policy'
import PolicyApiService from '@/services/api/PolicyApiService'

function initialState() {
  return {
    newPolicyTitle: '',
    isSubmiting : false,
    validation: {
      title: {
        hasError: false,
        label: ''
      },
    }
  }
}
export default {
  components: { InputWithLabel },
  props: {
    submitFormLabel: {
      type: String,
      default: 'Create and Save'
    }
  },
  data: () => {
    return initialState()
  },
  methods: {
    reInitState() {
      Object.assign(this.$data, initialState())
    },
    submit() {      
      if (this.isSubmiting) return
      this.isSubmiting = true
      this.validationClear('title')     

      if (!this.newPolicyTitle) {
        this.validation.title.hasError = true
        this.validation.title.label = 'Please add a policy name.'
        this.isSubmiting = false
      } else if (this.newPolicyTitle && this.newPolicyTitle.length < 3) {
        this.validation.title.hasError = true
        this.validation.title.label = 'A policy name should have a least three characters.'
        this.isSubmiting = false
      } else {
        this.storePolicy()  
      }    
    },
    
    storePolicy() {
      const policy = new Policy({
        title: this.newPolicyTitle,
        user_id : this.$store.state.auth?.loggedUser?.id,
        jurisdiction_id : this.$store.getters['lastJurisdictionVisited'].id,
      }) 
      PolicyApiService.store(policy)
        .then((policyStored) => { 
            this.$emit('store-success', policyStored)
            this.isSubmiting = false
          })
        .catch((error) => {
          console.log(error)
          this.isSubmiting = false
        })
    }

  }
}
</script>

<style>

</style>