<template>
  <div class="flexiblepath-prototypecard w-full bg-white flex flex-col p-10 mb-10">
    <div class="flex">
      <AppInfoHelper
        :visible="true"
        :absolute="true"
        position="right"
        @icon-click="$eventBus.$emit('openDescriptionModal', { type: 'helper', slug: 'policy-flexiblepath' })"
      >
        <h4 class="text-accent-2 inline-block text-ash-450">
          Flexible Compliance Tables
        </h4>
      </AppInfoHelper>
    </div>        
    
    <h2 class="text-header-5 font-bold text-deepsky-900">
      {{ prototype.title }}
    </h2>    
    <router-link
      class="text-p-1 text-ash-600 italic mt-1 hover:underline"
      :to="resultsRouteArgs"
    >
      Source Study: {{ prototype.study.title }} - {{ formatDate({ date: prototype.study.released_at, format: 'MMMM D, YYYY', from: 'mm/dd/yyyy' }) }}
    </router-link>   

    <AppAlert
      v-if="flexible_path_setup.from_version == 1"
      class="w-full cursor-pointer mt-4"
      icon="alert-circle"
      wrapper-class="py-5 px-6 mb-6 border rounded"
      description="&quot;Requirement Level&quot;!? As a result of feedback, we simplified things by eliminating the requirement level control, instead of allowing you to directly edit Target Scores. By default, we set Target Scores to half of the Max Cost-Effective Score for your policy. This is equivalent to a 50% requirement level."
      :icon-size="24"
      @click="$emit('removeOldVersionAlert')"
    />

    <div 
      v-for="climate_zone in prototype.climate_zones"
      :key="climate_zone.id"
      class="w-full mt-6"
    >
      <h3 class="text-header-3 font-bold text-deepsky-900">
        Climate Zone {{ climate_zone.prefix }}
      </h3>

      <div class="w-full overflow-x-auto mb-4">
        <div class="w-full text-right py-1">
          <p class="text-header-1 font-bold italic text-ash-600">
            Target Score Table
          </p>
        </div>
        <!-- TYPE_VINTAGE HEADER -->
        <div class="w-full flex justify-end">
          <div 
            v-for="type_vintage in type_vintages" 
            :key="`type_vintage-${type_vintage.id}`"
            class="
              cell-width h-8 flex items-center pl-4 flex-shrink-0
              text-header-2 bg-deepsky-400 text-deepsky-900 font-bold
              border-white border-b"
          >
            {{ type_vintage.title }}
          </div>
        </div>        
        
        <!-- TIERS -->
        <MeasureMenuTier 
          v-for="(tier, tier_index) in getTiers({ climate_zone_id: climate_zone.id, prototype_id : prototype.id, flexible_path_setup })"
          :key="`tier-${tier_index}`"
          :tier="tier"
          :policy="policy"
          :prototype="prototype"
          :climate_zone="climate_zone"
          :type_vintages="type_vintages"
          :flexible_path_setup="flexible_path_setup"
        />
      </div>

      <!-- MEASURES LIST -->
      <div class="w-full overflow-x-auto">
        <div class="w-full text-right py-1">
          <p class="text-header-1 font-bold italic text-ash-600">
            Measures Score Table
          </p>
        </div>
        
        <!-- TYPE_VINTAGE HEADER -->
        <div class="w-full flex justify-end">
          <div 
            v-for="type_vintage in type_vintages" 
            :key="`type_vintage-${type_vintage.id}`"
            class="
              cell-width h-8 flex items-center pl-4 flex-shrink-0
              text-header-2 bg-ash-300 text-ash-600 font-bold
              border-white border-b"
          >
            {{ type_vintage.title }}
          </div>
        </div>
        
        <div class="w-full flex flex-col text-header-2">
          <template v-for="type_measure in climate_zone.type_measures">
            <div
              v-if="type_measure.measures.length > 0"
              :key="type_measure.id"
            >
              <div class="w-full pb-2">
                <h1 
                  class="text-header-1 font-bold text-ash-450 bg-ash-200 py-1 flex items-center cursor-pointer transition-all hover:opacity-70"
                  @click="toggleTypeMeasure(climate_zone.id, type_measure.id)"
                >
                  <span class="pl-8 flex items-center">
                    <feather
                      :type="typeMeasuresClosed.includes(getTypeMeasureClimateZonePrefix(climate_zone.id, type_measure.id)) ? 'chevron-right' : 'chevron-down'"
                      class="text-ash-300"
                      :size="16"
                    />
                  </span>
                  {{ type_measure.title }}
                </h1>

                <div
                  v-show="!typeMeasuresClosed.includes(getTypeMeasureClimateZonePrefix(climate_zone.id, type_measure.id))"
                  class="w-full"
                >
                  <template v-for="measure in type_measure.measures">
                    <div
                      v-if="shouldBeIncludedAtMeasureMenuListInAnyTypeVintage({ measure, climate_zone, prototype, flexible_path_setup })"      
                      :key="`measure-${type_measure.id}-${measure.id}`"
                      class="w-full flex"
                    >
                      <div class="cell-width measure-title w-full h-10 flex pl-7 items-center bg-white text-ash-700 opacity-100-childrens-on-hover">
                        <feather 
                          size="16"
                          type="info" 
                          class="cursor-pointer mr-1 opacity-0 transition-all duration-300 ease-in text-ash-400 hover:text-deepsky-800" 
                          @click="$eventBus.$emit('openDescriptionModal', { type: 'measure', id: measure.id })"
                        />
                        <span>{{ measure.flexible_path_title || measure.title }}</span>
                      </div>

                      <div 
                        v-for="(type_vintage, type_vintage_index) in measure.type_vintages" 
                        :key="`measure-type_vintage-${type_vintage.id}`"
                        class="measure-cell relative transition-all cell-width h-10 flex items-center px-4 flex-shrink-0 text-ash-600 opacity-100-all-opacity-0-childrens-on-hover"
                        :data-measure-score="String(getMeasureScore({ measure, type_vintage_index }))"
                      >
                        <template v-if="shouldBeIncludedInTypeVintage({ climate_zone, type_vintage_index })">
                          <span :data-measure-score-value="getMeasureScoreTextValue({ measure, climate_zone, prototype, type_vintage_index, flexible_path_setup })" />
                          <div 
                            v-if="userCanEditPolicy && getMeasureScoreRaw({ measure, type_vintage_index })"
                            class="measure-actions absolute top-0 right-0 w-full h-full flex items-center justify-end space-x-1 pr-1 text-ash-400"
                          >
                            <feather
                              v-if="checkMeasureIsExcluded({ measure, climate_zone, prototype, type_vintage_index, flexible_path_setup})"
                              class="action-restore transition-all opacity-0 cursor-pointer hover:text-deepsky-450"
                              type="rotate-ccw"
                              :size="24"
                              @click="toggleExcludedMeasures({ measure, climate_zone, prototype, type_vintage, type_vintage_index, flexible_path_setup})" 
                            />
                            <feather 
                              v-else-if="!checkMeasureIsExcluded({ measure, climate_zone, prototype, type_vintage_index, flexible_path_setup}) && getMeasureScore({ measure, type_vintage_index }) || checkMeasureIsMandatory({ climate_zone, measure, prototype, type_vintage_index, flexible_path_setup })"
                              v-tooltip="getExcludedTooltip({ climate_zone, measure, type_vintage_index})"
                              class="action-exclude transition-all opacity-0"
                              type="trash"
                              :size="24"
                              :class="{
                                'hover:text-deepsky-450 cursor-pointer' : checkMeasureCanBeExcluded({ climate_zone, measure, type_vintage_index })
                              }"
                              @click="checkMeasureCanBeExcluded({ climate_zone, measure, type_vintage_index }) ? toggleExcludedMeasures({ measure, climate_zone, prototype, type_vintage, type_vintage_index, flexible_path_setup}) : false"
                            />
                            <feather 
                              v-if="!checkMeasureIsExcluded({ measure, climate_zone, prototype, type_vintage_index, flexible_path_setup}) && !checkMeasureIsMandatoryByDefault({ climate_zone, measure, type_vintage_index }) && getMeasureScore({ measure, type_vintage_index })"
                              v-tooltip="getMandatoryTooltip({ climate_zone, measure, prototype, type_vintage_index})"
                              class="action-pin transition-all opacity-0"
                              type="star"
                              :size="24"
                              :class="{
                                'hover:text-deepsky-450 cursor-pointer' : checkMeasureCanBeMandatory({ climate_zone, prototype, measure, type_vintage_index, flexible_path_setup}),
                                'opacity-100 text-deepsky-450' : checkMeasureIsMandatory({ climate_zone, measure, prototype, type_vintage_index, flexible_path_setup })
                              }" 
                              @click="checkMeasureCanBeMandatory({ climate_zone, prototype, measure, type_vintage_index, flexible_path_setup}) ? toggleMandatoryMeasures({ climate_zone, measure, prototype, type_vintage, type_vintage_index, flexible_path_setup }) : false"
                            />                            
                          </div>
                        </template>
                        <template v-else>
                          --
                        </template>                          
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </template>                    
        </div>
      </div>
      
      <MeasureMenuCreditsList
        :climate_zone="climate_zone"
        :type_vintages="type_vintages"
        :prototype="prototype"
        :flexible_path_setup="flexible_path_setup"
        :policy="policy"
      />
      <MeasureMenuCustomCombinationMeasuresList
        :climate_zone="climate_zone"
        :type_vintages="type_vintages"
        :prototype="prototype"
        :flexible_path_setup="flexible_path_setup"
        :policy="policy"
      />
    </div>
  </div>
</template>

<script>
import MeasureMenuTier from './measure-menu/MeasureMenuTier.vue'
import MeasureMenuCreditsList from './measure-menu/MeasureMenuCreditsList.vue'
import MeasureMenuCustomCombinationMeasuresList from './measure-menu/MeasureMenuCustomCombinationMeasuresList.vue'
import MeasureMenuService from '@/services/measure-menu/MeasureMenuGeneralService'
import StudyResultApiService from '@/services/api/StudyResultApiService'

export default {
  name: 'PolicyFlexiblePathPrototypeCard',
  components: { MeasureMenuCustomCombinationMeasuresList, MeasureMenuCreditsList, MeasureMenuTier },
  props: ['type_vintages', 'prototype', 'flexible_path_setup', 'policy', 'flexiblePathService'],
  data: () => ({
    resultsRouteArgs: '',
    typeMeasuresClosed : [],
  }),
  computed: {
    userCanEditPolicy() {
      return this.policy && (this.policy.user_id === this.$store.getters.getterLoggedUser?.id) ? true : false
    },
    isAdminAndNotProduction() {
      return this.$store.state.auth.loggedUser && this.$store.getters.getterLoggedUserType == 'admin' && window.location.host != 'explorer.localenergycodes.com'
    },
    isLocalHost() {
      return window.location.host == 'localhost:8080'
    },
  },
  created() {
    StudyResultApiService.getStudyResultsUrl({ jurisdiction_id: this.policy.jurisdiction_id, study_id: this.prototype.study.id, type_prototype_id: this.prototype.type_prototype_id })
      .then((route) => this.resultsRouteArgs = route)
  },
  methods: {      
    ...MeasureMenuService,        

    openPolicyDuplicateModal() {
      this.$eventBus.$emit('openDuplicatePolicyModal', { policy : this.policy })
    },

    getExcludedTooltip({ climate_zone, measure, type_vintage_index }) {
      if (this.checkMeasureIsMandatoryByDefault({ climate_zone, measure, type_vintage_index })) {
        return 'Clicking here will exclude this measure. This measure can only be mandatory or excluded as the energy savings are insufficient to earn a score of 1.'
      } else if (!this.checkMeasureCanBeExcluded({ climate_zone, measure, type_vintage_index })) {
        return 'This measure cannot be excluded since it is used to establish cost-effectiveness.'
      } else {
        return false
      }
    },

    getMandatoryTooltip( { climate_zone, prototype, measure, type_vintage_index }) {    
      
      if (!this.checkMeasureIsCostEffectiveness({measure, type_vintage_index})) {
        return 'Measures that are not cost-effective cannot be mandatory.'
      } else if (this.getOverlapedMandatoryMeasures({ climate_zone , prototype, measure, type_vintage_index, flexible_path_setup: this.flexible_path_setup })) {
        return 'This measure cannot be made mandatory as it would conflict with another mandatory measure.'
      } else if (this.getOverlapedCustomComtinationMeasures({ climate_zone, measure, type_vintage_index })) {
        return 'This measure cannot be made mandatory as it would conflict with another measure used to establish cost-effectiveness.'
      } else if (!measure.include_in_combination) {
        return 'This measure cannot be made mandatory since the cost-effectiveness analysis did not account for the full measure cost, as it assumed that other work was already being completed.'
      } else {
        return false
      }
    },
    
    getTypeMeasureClimateZonePrefix(climate_zone_id, type_measure_id) {
      return `${climate_zone_id}-${type_measure_id}`
    },

    toggleTypeMeasure(climate_zone_id, type_measure_id) {
      const prefix = this.getTypeMeasureClimateZonePrefix(climate_zone_id, type_measure_id)
      if (this.typeMeasuresClosed.includes(prefix)) {
        this.typeMeasuresClosed = [...this.typeMeasuresClosed.filter(id => id === prefix ? false : true)]
      } else {
        this.typeMeasuresClosed = [...this.typeMeasuresClosed, prefix ]
      }
    },

    
        
  }
}
</script>

<style scoped lang="scss">
  ::v-deep .cell-width {
    min-width: 144px;
  }
  .measure-title span {
    max-height: 100%;
    width: 100%;
    display: block;
    overflow-y: auto;
  }

  .flexiblepath-prototypecard {
    max-width: 837px;
    box-shadow: 0px 0px 5px rgba(0, 42, 58, 0.05), 0px 3px 10px rgba(0, 42, 58, 0.05);
  }
  
  ::v-deep .measure-cell {
        
    span:before {
      content: attr(data-measure-score-value);      
    }    

    i {
      // cursor: pointer;
      // color: transparent;
    }

    // .action-restore {
    //   display: none;
    // }

    // &:hover {
    //   background-color: #F6F9FB;

    //   i {
    //     color: #A9AEB0;
    //     &:hover {
    //       color: #69A7BF;
    //     }      
    //   }
    // }    
    // &.has-not-measure-score {
    //   color: #A9AEB0;
    // }

    // &.can-not-be-mandatory {
    //   .action-pin {        
    //     cursor: default !important;
    //     &:hover {
    //       color: #A9AEB0 !important;
    //     }
    //   }  
    // }
    
    // &.can-not-be-excluded {      
    //   .action-exclude {
    //     cursor: default !important;
    //     &:hover {
    //       color: #A9AEB0 !important;
    //     }
    //   }  
    // }

    // &.is-pinned {
    //   .action-pin { 
    //     color: #69A7BF;
    //   }
    // }

    // &.is-excluded {
    //   background-color: transparent;      
      
    //   .action-exclude, .action-pin {
    //     display: none;
    //   }

    //   .action-restore {
    //     display: block;
    //   }
    // }

    // &.is-mandatory-by-default {
      
    //   .action-pin {
    //     display: none;
    //   }
      
    //   &.is-pinned:not(.is-excluded) {
    //     .action-exclude {
    //       display: none;
    //     }
      
    //     &:hover {
    //       .action-pin {
    //         display: none;
    //       }
    //       .action-exclude {
    //         display: block;
    //       }
    //     }
    //   }

    //   &.is-excluded {

    //     .action-exclude, .action-pin {
    //       display: none;
    //     }

    //     .action-restore {
    //       display: block;
    //     }
    //   }

    // }

  }

  
</style>