import BuildingEstimate from '@/models/BuildingEstimate'
import {  BUILDING_ESTIMATES_BASE_YEARS } from '@/modules/app/assumptions/shared/enums'

const type_prototypes = () => ([  
  { 
    id: 2,
    title: 'Single Family Units',
    building_heights : [],
    building_type_id : 1,
    type_prototype_id: 3,
    prototypes: [
      {
        id: 24,
        title: "Single Family",
        type_prototype_id: 3
      },
      {
        id: 27,
        title: "Single Family",
        type_prototype_id: 3
      },
      {
        id: 21,
        title: "Single Family Homes",
        type_prototype_id: 3
      },
      {
        id: 1,
        title: "Single Family Homes",
        type_prototype_id: 3
      },
      {
        id: 29,
        title: "Single Family",
        type_prototype_id: 3
      }
    ]
  },
  { 
    id: 1,
    title: 'MultiFamily Units',
    building_heights : building_heights(),
    building_type_id : 1,
    type_prototype_id: 1,
    prototypes: [
      {
        id: 3,
        title: "Multifamily Units",
        type_prototype_id: 1
      },
      {
        id: 2,
        title: "Multifamily Units",
        type_prototype_id: 1
      },
    ]
  },
  { 
    id: 3,
    title: 'Hotels',
    building_heights : building_heights(),
    building_type_id : 2,
  },
  { 
    id: 4,
    title: 'Offices',
    building_heights : [],
    building_type_id : 2,
  },
])

const building_heights = () => ([
  { id: 1, title: 'Low-rise', slug: 'low_rise' },
  { id: 2, title: 'Medium-rise', slug: 'medium_rise' }
])

const building_types = () => ([
  { id: 1, title: 'Residential', slug: 'residental-buildings' },
  { id: 2, title: 'Non-Residential', slug: 'non-residental-buildings' }
])

const construction_implementation_types = () => ([
  { id: 2, title: 'Ground up'},
  { id: 3, title: 'Additions & alterations'}
])
export default class BuildingEstimatesMockupData {
  constructor(obj) {
    if (!obj.jurisdiction) {
      console.error(obj)
      throw Error('BuildingEstimatesMockupData failed, please add required args')
    }

    this.jurisdiction = obj.jurisdiction
    this.constructionImplementationTypes = construction_implementation_types()
    this.generate()
  }

  getConstructionImplementationTypeId() {
    return this.constructionImplementationTypes[Math.round(Math.random())].id
  }

  generate() {

    const output = []
    for (const climate_zone of this.jurisdiction.climate_zones) {
      for (const building_estimates_prototype of type_prototypes()) {
        for(const year of BUILDING_ESTIMATES_BASE_YEARS) {
          if (building_estimates_prototype.building_heights.length > 0) {
            for( const building_height of building_estimates_prototype.building_heights) {
              output.push(new BuildingEstimate({
                jurisdiction_id : this.jurisdiction.id,
                climate_zone_prefix : climate_zone.prefix,
                type_prototype_id : building_estimates_prototype.id,
                year : year,
                units : Math.round(Math.random()*1000),
                building_height_id : building_height.id,
                construction_implementation_type_id: this.getConstructionImplementationTypeId()
              }))
            }
          } else {
            output.push(new BuildingEstimate({
              jurisdiction_id : this.jurisdiction.id,
              climate_zone_prefix : climate_zone.prefix,
              type_prototype_id : building_estimates_prototype.id,
              year : year,
              units : Math.round(Math.random()*1000),
              building_height_id : false,
              construction_implementation_type_id: this.getConstructionImplementationTypeId()
            }))
          }
        }
      }
    }
    this.building_estimates = output
  } // generate

}

const buildingEstimatesPrototypes = type_prototypes()
const buildingTypes = building_types()
const constructionImplementationTypes = construction_implementation_types()

export {
  buildingEstimatesPrototypes,
  buildingTypes,
  constructionImplementationTypes
  // building_heights: building_heights(),
  // years: years(),
  // building_types: building_types()
}