import { render, staticRenderFns } from "./PolicyTableRow.vue?vue&type=template&id=babce5e6&scoped=true&"
import script from "./PolicyTableRow.vue?vue&type=script&lang=js&"
export * from "./PolicyTableRow.vue?vue&type=script&lang=js&"
import style0 from "./PolicyTableRow.vue?vue&type=style&index=0&id=babce5e6&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "babce5e6",
  null
  
)

export default component.exports