<template>
  <span    
    class="cursor-pointer text-header-1 font-bold rounded-sm p-1 ml-3 whitespace-no-wrap duration-300 transition"
    :class="status.class"
    @click="onClick()"
  >
    {{ status.label }}
  </span>  
</template>

<script>
import PrototypeApiService from '@/services/api/PrototypeApiService'
export default {
  name: 'StudyResultsContentPrototypeVersion',
  props: {
    studyGroup: {
      type: Object,
      required: true
    },
    prototype: {
      type: Object,
      required: true
    },
    study: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    prototypesByTypePrototypeAndStudyGroupOrderedByStudyReleasedAt: []
  }),
  computed: {
    prototypeIndex() {
      return this.prototypesByTypePrototypeAndStudyGroupOrderedByStudyReleasedAt.map(p => parseInt(p.id)).indexOf(parseInt(this.prototype.id))
    },
    nextPrototype() {
      return this.prototypesByTypePrototypeAndStudyGroupOrderedByStudyReleasedAt[this.prototypeIndex - 1]
    },
    isNewestVersion() {
      return this.prototypeIndex === 0
    },
    newestPrototypeId() {
      return this.prototypesByTypePrototypeAndStudyGroupOrderedByStudyReleasedAt ? this.prototypesByTypePrototypeAndStudyGroupOrderedByStudyReleasedAt?.[0]?.id : false
    },
    newestStudyId() {
      return this.prototypesByTypePrototypeAndStudyGroupOrderedByStudyReleasedAt ? this.prototypesByTypePrototypeAndStudyGroupOrderedByStudyReleasedAt?.[0]?.study_id : false
    },
    hasNewerVersionReleased() {      
      return this.nextPrototype && this.nextPrototype.study.published_at ? true : false
    },
    hasNewerVersionOnlyInPdf() {      
      return this.nextPrototype && !this.nextPrototype.study.published_at ? true : false
    },
    status() {
      if (this.hasNewerVersionOnlyInPdf) {
        return {
          label: 'Newer Version Released',
          class: 'bg-ash-400 text-white'
        }
      } else if (this.isNewestVersion) {
        return {
          label: 'Newest Version',
          class: 'border text-lime-500 border-lime-500'
        }
      } else if (this.hasNewerVersionReleased) {
        return {
          label: 'Newer Version Available',
          class: 'bg-deepsky-400 text-white'
        }
      } else {
        return false
      }
    }
  },
  mounted() {
    PrototypeApiService
      .getPrototypesByTypePrototypeAndStudyGroupOrderedByStudyReleasedAt({ typePrototype: this.prototype.type_prototype, studyGroup: this.studyGroup })
      .then((prototypes) => this.prototypesByTypePrototypeAndStudyGroupOrderedByStudyReleasedAt = prototypes)
  },
  methods: {
    onClick() {
      if (this.hasNewerVersionReleased) {
        const route_exclude_study_ids = this.$route?.query?.exclude_study_ids ? this.$route?.query?.exclude_study_ids.split(',') : []
        if (!route_exclude_study_ids.includes(`${this.newestStudyId}`)) {
          this.scrollToNewestPrototype()
        } else {
          console.log('')
          this.$eventBus.$emit('openForecastStudyFilter')
        }
      } else if (this.isNewestVersion) {
        this.$eventBus.$emit('openStudyGroupVersionsTimelineModal', { studyGroupId: this.studyGroup.id })
      } else if (this.hasNewerVersionOnlyInPdf) {        
        this.$eventBus.$emit('openNewStudyVersionModal', { study: this.nextPrototype.study })                
      } else {
        return false
      }
    },
    scrollToNewestPrototype() {
      const studyResultsIndexEl = document.getElementById(`study-results-index`)
      const studyResultsHeaderEl = document.getElementById(`study-results-header`)
      const prototypeEl = document.getElementById(`forecast-prototype-${this.newestPrototypeId}`)
      studyResultsIndexEl.scrollTo({ top: prototypeEl.offsetTop - studyResultsHeaderEl.offsetHeight - 30, left: 0, behavior: 'smooth' })
    }
  },
}
</script>

<style>

</style>