<template>
  <div class="w-full h-full flex flex-col overflow-y-auto bg-ash-100">
    <SummaryHeader :jurisdiction="jurisdiction" />

    <div class="w-full block p-10 pt-6 pb-7">
      <AppCard class="card-create-policy px-6 py-8">
        <div class="grid grid-cols-3 gap-10">
          <div class="py-8 mx-2">
            <p class="font-bold psui-text-h4 text-deepsky-900 psui-mb-2.5">
              Create a policy and forecast the impact of reach codes in your city
            </p>
            <p class="text-header-2 psui-text-gray-60 mt-3">
              Create a policy requirement in minutes, based on cost-effective measures and packages from the latest studies.
            </p>
          </div>

          <div class="shadows psui-rounded p-8 mx-2">
            <p class="font-bold text-header-3 text-deepsky-800">
              Existing Buildings
            </p>
            <p class="text-header-2 psui-text-gray-60 mb-3">
              Start drafting a policy for existing single family homes and multifamily dwelling units.
            </p>
            <PsButton
              label="Create a policy draft"
              size="small"
              class="psui-bg-blue-60"
              @click="openModalAutoGeneratePolicyWizard()"
            />
          </div>

          <div class="shadows psui-rounded p-8 mx-2">
            <p class="font-bold text-header-3 text-deepsky-800">
              New Construction
            </p>
            <p class="psui-text-small psui-text-gray-60 psui-mb-3">
              2022 code cycle studies are not yet available. You will be able to explore them in the tool soon.
            </p>

            <div class="psui-flex psui-items-center psui-flex-wrap">
              <a 
                target="_blank"
                href="https://intercom.help/explorer-local-energy-codes/en/articles/5789919-considering-a-new-construction-reach-code-for-your-city-or-county-in-california"
                @click="gaEvent(GA_LABELS.SUMMARY_HOW_TO_GET_READY, { value: 'PREVIEW_CARD' })"
              >
                <PsButton                
                  label="How to get ready"
                  size="small"
                  class="psui-bg-blue-60 mr-3"
                  target="_blank"                  
                />
              </a>

              <a 
                target="_blank"
                href="https://eepurl.com/hSvOov"
                @click="gaEvent(GA_LABELS.SUMMARY_SEND_ME_A_REMINDER, { value: 'PREVIEW_CARD' })"
              >
                <PsButton
                  label="Send me a reminder"
                  size="small"
                  layout="onlytext"
                  icon="notifications_active"
                  icon-position="left"
                />
              </a>
            </div>
          </div>
        </div>
      </AppCard>
    </div>

    <div class="w-full block p-10 pt-0">
      <AppCard
        v-if="studyGroups.length > 0"
        class="p-8"
      >
        <div class="grid grid-cols-3 gap-16">
          <div class="col-span-2">
            <div class="flex items-center justify-between mb-6">                  
              <router-link
                class="cursor-pointer text-header-5 text-bluebrand font-bold ml-2"
                tag="h2"
                :to="{ name: 'StudyResults' }"
              >
                Cost-Effectiveness Studies
              </router-link>
              <AppSwitcher
                :items="['Latest', 'All']"
                :selected.sync="studyVersionsFilter"
                label="Study Versions:"
              />
            </div>
            <ul class="mb-5 ml-2">
              <li
                v-for="studyGroup in studyGroups"
                :key="studyGroup.id"
                class="bg-deepsky-100 mb-2 p-4 flex rounded"
              >
                <div class="flex-shrink-0 mr-3">
                  <svg
                    width="32"
                    height="32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21 3v20H4V3h17z"
                      stroke="#82C0D9"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6 26h18V5.25"
                      stroke="#82C0D9"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8 29.5h19v-22M17 8.9H8m9 4H8m4.814 4H8"
                      stroke="#82C0D9"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div class="w-full">
                  <span 
                    class="flex items-center transition-all"
                    :class="studyGroup.studies.length > 1 ? 'opacity-100-all-opacity-0-childrens-on-hover hover:bg-deepsky-200' : ''"
                  >
                    <h3 class="text-header-3 font-bold text-deepsky-800 mt-2 mb-3">{{ studyGroup.title }} Study</h3>
                    <span class="ml-auto flex actions text-deepsky-500 space-x-4 mr-3">
                      <span
                        v-if="studyGroup.studies.length > 1"
                        class="cursor-pointer hover:text-deepsky-700 opacity-0"
                        @click="$eventBus.$emit('openStudyGroupVersionsTimelineModal', { studyGroupId: studyGroup.id })"
                      >
                        VERSION HISTORY
                      </span>
                      <span
                        v-if="studyGroup.studies.length > 1"
                        class="cursor-pointer hover:text-deepsky-700 always-visible"
                        @click="$eventBus.$emit('openModalAutoGeneratePolicyWizard')"
                      >
                        CREATE POLICY
                      </span>
                    </span>
                  </span>                                          
                  <transition-group
                    name="list"
                    tag="ul"
                    class="transition-all"
                  >
                    <template v-for="(study) in studyGroup.studies">
                      <template>
                        <div :key="`${studyGroup.id}-${study.id}`">
                          <li
                            v-if="study.summary_recommended || studyVersionsFilter === 'All'"
                            :key="`${studyGroup.id}-${study.id}`"
                            class="flex items-center transition-all hover:bg-deepsky-200 py-2 -ml-2 pl-2 opacity-100-childrens-on-hover"
                          >
                            <span class="text-header-2 text-deepsky-700 mr-2 flex-none">{{ study.getTitle }}</span>
                            <span class="text-header-1 text-ash-400 font-bold">{{ study.title_by_prototypes }}</span>
                            <span
                              v-if="!study.published_at && study.source_pdf"
                              class="text-header-1 text-lime-400 border rounded-xs p-1 border-lime-400 font-bold mx-4 flex-none"
                            >PDF Only</span>
                            <span
                              v-if="study.summary_message_tag"
                              class="text-header-1 text-lime-400 border rounded-xs p-1 border-lime-400 font-bold mx-4 flex-none"
                            >{{ study.summary_message_tag }}</span>
                            <span class="ml-auto flex actions text-deepsky-500 space-x-4 opacity-0 mr-3 flex-none">
                              <router-link 
                                v-if="study.published_at"
                                class="cursor-pointer hover:text-deepsky-700"
                                :to="{ name: 'StudyResults', params: { jurisdiction_slug: jurisdictionSlug, climate_zone_raw: climateZoneRaw }, query: { exclude_study_ids : getExcludedStudyResultIds(study.id)}}"
                                @click.native="onClickStudySeeResults(study)"
                              >SEE RESULTS</router-link>
                              <span 
                                v-if="study.source_pdf"
                                class="cursor-pointer hover:text-deepsky-700" 
                                @click="onClickStudyDownloadPdf(study)"
                              >
                                DOWNLOAD PDF</span>
                              <span
                                v-if="study.has_content"
                                class="cursor-pointer hover:text-deepsky-700" 
                                @click="onClickStudyMoreInfo(study)"
                              >
                                MORE INFO</span>
                              <span 
                                v-for="(link, index) of study.custom_links"
                                :key="index"
                                class="cursor-pointer hover:text-deepsky-700"
                              >  <a 
                                :href="link.href" 
                                :target="link.target" 
                                :title="link.title"
                                @click="gaEvent(GA_LABELS.SUMMARY_CUSTOM_LINKS, { value: link.href, event_category: GA_EVENT_CATEGORIES.USER_INTERACTION, event_label: study.title })"
                              >
                                {{ link.title }}
                              </a>
                              </span> 
                            </span>
                          </li>
                        </div>
                      </template>
                    </template>
                  </transition-group>
                </div>
              </li>
            </ul>
            <router-link
              :to="getLinkSeeResults()"
              class="inline-block"
            >
              <PsButton
                label="SEE RESULTS"
                size="medium"
              />
            </router-link>
          </div>
          <div class="">
            <div class="seealso">
              <h2 class="text-header-3 text-deepsky-900 font-bold">
                See also
              </h2>
              <ul class="mt-2">
                <li class="ts--accent--2 py-2 pl-2">
                  <span
                    class="cursor-pointer hover:opacity-80 transition"
                    @click="onClickUtilityRates()"
                  >Utility Rate Info</span>
                </li>            
                <li class="ts--accent--2 py-2 pl-2">
                  <span
                    class="cursor-pointer hover:opacity-80 transition"
                    @click="onClickResultsSources()"
                  >Results Sources</span>
                </li>            
              </ul>
            </div>
          </div>
        </div>
      </AppCard>
      <SkeletonCostEffectivenessStudies v-else />
    </div>      
  </div>
</template>

<script>
import Jurisdiction from '@/models/Jurisdiction'
import JurisdictionApiService from '@/services/api/JurisdictionApiService'
import StudyGroupApiService from '@/services/api/StudyGroupApiService'
import SummaryHeader from './SummaryHeader.vue'
import AppCard from '@/components/general/AppCard'
import { utilityRatesOptions, resultsSourcesOptions } from '@/util/Drawers'
import { GA_LABELS, GA_EVENT_CATEGORIES } from '@/mixins/GaEventsMixin'
import { POLICY_METHOD_AUTO_GENERATE, POLICY_METHOD_DEFAULT } from '@/business-logic/constants/policiesEnum'
import SkeletonCostEffectivenessStudies from './skeleton/SkeletonCostEffectivenessStudies'

export default {
  components: { AppCard, SummaryHeader, SkeletonCostEffectivenessStudies },
  metaInfo() {
    return {
      title: this.jurisdiction?.title_type ? `Summary for ${this.jurisdiction?.title_type} | ` : ''
    }
  },
  data: () => ({
    studyGroups: [],
    jurisdiction: false,
    studyVersionsFilter: 'Latest',
    POLICY_METHOD_AUTO_GENERATE,
    POLICY_METHOD_DEFAULT,
    GA_LABELS,
    GA_EVENT_CATEGORIES,
  }),
  computed: {
    allStudiesIds() {
      return this.studyGroups.reduce((prev, cur) => {
        cur.studies.forEach(study => prev.push(study.id.toString()))
        return prev
      }, [])
    },
    notRecommendedStudyIds() {
      return this.studyGroups.reduce((prev, cur) => {
        cur.studies.forEach(study => {
          if (!study.summary_recommended) {
            prev.push(study.id.toString())
          }
        })
        return prev
      }, [])
    },
    climateZoneRaw() {
      return this.jurisdiction.climate_zones?.[0]?.raw ?? false
    },
    jurisdictionSlug() {
      return this.$route.params.jurisdiction_slug
    },
  },
  watch : {
    '$route.params.jurisdiction_slug' : {
      handler(slug) {
        if (slug) {
          JurisdictionApiService.getBySlug(slug).then(({ jurisdiction }) => {
            this.jurisdiction = new Jurisdiction(jurisdiction)
          })
        }
        
      },
      immediate: true
    }
  },
  mounted() {
    StudyGroupApiService.all()
      .then(res => this.studyGroups = res)
  },
  beforeDestroy() {
    this.$eventBus.$off('filtersUpdated')
  },
  methods: {
    openMailchimp() {
      window.alert('openMailchimp')
    },
    getExcludedStudyResultIds(notExcludedId) {
      return this.allStudiesIds.filter(id => parseInt(id) !== parseInt(notExcludedId)).join(',')
    },
    getLinkSeeResults() {
      const payload = { 
          name: 'StudyResults', 
          params: { 
            jurisdiction_slug: this.jurisdictionSlug, 
            climate_zone_raw: this.climateZoneRaw 
          },
          query: {}
        }       
      
      if (this.studyVersionsFilter === 'Latest') {
        payload.query.exclude_study_ids = this.notRecommendedStudyIds.join(',')
      }

      return payload
    },
    onClickUtilityRates() {
      this.$eventBus.$emit('openDrawerContent', utilityRatesOptions(this.$route))
    },
    onClickResultsSources() {
      this.$eventBus.$emit('openDrawerContent', resultsSourcesOptions(this.$route))
    },
    onClickBuidingStock() {
      const jurisdiction_slug = this.$route.params.jurisdiction_slug
      this.$router.push({ name: 'BuildingStock', params: { jurisdiction_slug }})
    },
    onClickStudyDownloadPdf(study) {
      this.gaEventDownloadFile(GA_LABELS.SUMMARY_STUDY_DOWNLOAD_PDF, window.location.href, study.id)
      window.open(study.source_pdf)
    },
    onClickStudyMoreInfo(study) {
      this.gaEvent(GA_LABELS.SUMMARY_STUDY_MORE_INFO, { value: study.id })
      this.$eventBus.$emit('openDrawerContent', { type: 'study', id: study.id })
    },
    onClickStudySeeResults(study) {
      this.gaEvent(GA_LABELS.SUMMARY_STUDY_SEE_RESULTS, { value: study.id })          
    },
    openModalAutoGeneratePolicyWizard() {
      this.$eventBus.$emit('openModalAutoGeneratePolicyWizard')
      this.$store.commit('general/setRedirectTo', { route: this.$route.path, event: { name: 'openModalAutoGeneratePolicyWizard' } })
    }
  }
}
</script>

<style lang="scss" scoped>
  .btn.border-0 {
    border-width: 0 !important;
  }

  .shadows {
    box-shadow: 0px 4px 4px rgba(118, 132, 138, 0.05),
      0px 4px 10px rgba(118, 132, 138, 0.16),
      1px -1px 0px rgba(19, 61, 77, 0.03);
  }

  .card-create-policy p {
    min-height: 36px;
  }

  .badge-recommended-summary {
    right: -10px;
    top: 10px;
    padding: 4px 5px;
    font-size: 12px;
  }
  .leading-normal {
    line-height: 1.5 !important;
  }

  .align-texts * {
    line-height: 30px;
  }

  .seealso {
    padding: 28px 31px;
    background: #F6F9FB;
    border: 1px solid #D4E3EA;
    box-sizing: border-box;
    border-radius: 10px;

    li {
      border-bottom: 1px solid #D4E3EA;
    }
  }

  .buildingstock-charts {
    text-align: center;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;    
    flex-direction: row;
    position: relative;

    &-wrapper {
      margin-left: 60px;
    }

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      left: 0;
      height: 1px;
      background-color: #D8D8D8;
      top: 140px;
    }
    .chart {
      margin-left: 30px;
      margin-right: 30px;
      max-width: 180px;
    }
    .chart-bar {
      height: 140px;
      display: flex;      
      justify-content: flex-end;

      &-fill {
        margin-top: auto;
        width: 100%;
        background-color: #466673;
      }
    }
    .chart-caption {     
      padding-top: 20px;
    }
  }

  .actions, ::v-deep .actions {
    font-weight: bold;
    font-size: 10px;
    line-height: 100%;
    display: flex;
    align-items: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }

  // ::-webkit-scrollbar {
  //   display: none;
  // }

</style>
