<template>
  <div v-if="study_types.length">
    <div
      v-for="study_type in study_types"
      :key="`study_type_${study_type.id}`"
      class="w-full flex flex-col"
    >
      <h3 class="text-deepsky-800 font-16 font-bold">
        {{ study_type.title }}
      </h3>

      <div class="w-full flex flex-col space-y-4 mt-4">
        <div
          v-for="type_prototype in study_type.type_prototypes"
          :key="`type_prototype_${type_prototype.id}`"
          class="w-full rounded bg-deepsky-100 border border-deepsky-200 p-6"
        >
          <div class="w-full flex items-center relative pl-12">
            <inline-svg
              class="absolute left-0"
              :src="`/icons/type-prototypes-slug/${type_prototype.slug}.svg`"
              width="32"
              height="32"
              stroke="#69A7BF"
            />
            <h3 class="text-16 font-bold text-deepsky-800">
              {{ type_prototype.title }}
            </h3>
          </div>

          <div class="w-full pl-12 flex flex-col mt-4 space-y-4">
            <div
              v-for="climate_zone in 2"
              :key="`climate_zone_${climate_zone}`"
              class="w-full"
            >
              <h5 class="text-14 text-deepsky-800 flex items-center">
                Climate zone 
                <vue-skeleton-loader
                  class="ml-2"
                  :size="16"
                />
              </h5>
              <div class="grid grid-cols-3 gap-4 mt-2">
                <vue-skeleton-loader
                  class=""
                  :width="300"
                  :height="70"
                  rounded
                />
                <vue-skeleton-loader
                  class=""
                  :width="300"
                  :height="70"
                  rounded
                />
                <vue-skeleton-loader
                  class=""
                  :width="300"
                  :height="70"
                  rounded
                />
              </div>

              <div class="w-full flex flex-grow space-x-6 mt-3">
                <div class="flex-1 flex flex-col space-y-3 last:hidden">
                  <div 
                    v-for="vintage in 3" 
                    :key="`vintage_${vintage}`" 
                    class="vintage-card w-full h-auto text-deepsky-800 rounded-md bg-white shadow-sm relative border transition-all" 
                  >     
                    <div class="vintage-card w-full h-auto flex flex-col justify-center items-start space-y-1 rounded-md border border-dashed border-deepsky-400 px-6 py-2 relative disabled opacity-50 pointer-none cursor-default">
                      <div class="flex items-center">
                        <h4 class="text-14 font-bold text-deepsky-800 mr-2">
                          {{ }}
                        </h4>
                        <div class="add text-sm  border rounded border-deepsky-300 text-deepsky-450 px-2 font-bold transition-all">
                          <feather
                            type="plus"
                            size="14"
                          /> Add Measures
                        </div>
                      </div>
                    </div>                   
                  </div>               
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SkeletonPolicyShowScope',
  data: () => ({
    study_types: [
      {
        title: 'Existing Buildings',
        type_prototypes: [
          {
            title: 'Single Family Homes',
            id: '3',
            slug: 'single-family-homes'
          },
          {
            title: 'Multifamily Units',
            id: '4',
            slug: 'multifamily-units'
          },
        ]
      },
    ]
  })
}
</script>

<style lang="scss" scoped>
.policyshow-tabs {
    border-bottom: 1px solid #EBEEF0;
    top: 95px;
    button {
      position: relative;
      top: 1px;
    }
  }
  #badge-flexiblepath {
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(110%) translateY(-50%);
  }
</style>