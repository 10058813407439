<template>
  <div class="p-6">
    <InputWithLabel
      v-model="frequent_question.title"
      class="mt-6"
      :label="'Title'"
    />

    <ContentEditor
      class="mt-6"
      :content.sync="frequent_question.content"
    />

    <hr class="border-b border-gray05 my-6">
    <div>
      <PsButton
        v-if="!$route.params.frequent_question_id"
        label="Create"
        icon="add"
        size="big"
        @click="store()"
      />   
      <PsButton
        v-else
        label="Update"
        icon="update"
        size="big"
        @click="update()"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AdminFrequentQuestionForm',
  data() {
    return {
      frequent_question: {
        id: '',
        title: '',
        content: ''
      }
    }
  },
  mounted() {
    if(this.$route.params.frequent_question_id) {
      this.getFrequentQuestion()
    }
  },
  methods: {
    async getFrequentQuestion() {
      const query = `
        {
          frequent_question (
            where: [
              { column: "id", operation: "=", value: "${this.$route.params.frequent_question_id}"}
            ]
          ){
            id
            title
            content
            created_at
          }
        }
      `
      const { data } = await this.$graphql({ query })

      this.frequent_question = data.frequent_question
    },

    store() {
      this.$api.post(`/frequent_questions`, this.frequent_question, { loader: true })
        .then(() => {
          this.$toast.success('Frequent Question created')
          this.$router.push({ name: 'AdminFrequentQuestionList' })
        })
    },

    update() {
      this.$api.put(`/frequent_questions/${this.frequent_question.id}`, this.frequent_question, { loader: true })
        .then(() => {
          this.$toast.success('Frequent Question updated')
          this.$router.push({ name: 'AdminFrequentQuestionList' })
        })
    }
  }
}
</script>
