import Vue from 'vue'
import UserEventApiService from '@/services/api/UserEventApiService'

export default (router) => {

  const isMainURI = process.env.VUE_APP_ENV == 'production' && window.location.origin == 'https://explorer.localenergycodes.com' ? true : false

  router.beforeEach((to, from, next) => {
    
    /**
     * Redirect is_explorer_team users to another environment
     */
    if (isMainURI && window.localStorage.is_explorer_team) {
      location.href = `https://explorer-production-team.vercel.app${to.path}`
    }

    /**
     * Google Analytics tracking
     */
     if(isMainURI) {
      Vue.$gtag.pageview(to.path)
    }
    
    /**
     * Scroll to top on route change
     */
    setTimeout(() => {
      const mainContent = document.getElementById('main-content')
      if(mainContent) {
        document.getElementById('main-content').scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
      }
    }, 200)

    /**
     * Log user event navigation
     */
    UserEventApiService.log({ event: 'NAVIGATION', notes: to.path })
  
    next()
    
  })
}