import Api from '@/util/Api'
import Graphql from '@/util/GraphQL'
export default class MeasureApiService {
  
  static getAllPossible({ vintage_id, prototype_id, climate_zone_raw }) {
    return Api.post(`/api/measure/get_all_possible`, {
      vintage_id,
      prototype_id,
      climate_zone_raw
    })
  }

  static async byStudy(study_id) {
    const query = `
      {
        measures(where: [{column: "study_id", operation: "=", value: "${study_id}"}]) {
          id
          title
          type_measures {
            id
            title
          }
        }
      }
    `
    return await Graphql({ query }).then(({ data }) => {
      return data.measures
    })
  }
  
}