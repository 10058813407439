import Vue from 'vue'
import VueRouter from 'vue-router'

import appRoutes from '@/modules/app/routes'
import adminRoutes from '@/modules/admin/routes'
import loginRoutes from '@/modules/app/login/routes'
import userAccountRoutes from '@/modules/app/user-account/routes'
import helpersRoutes from '@/modules/helpers/routes'
import routerHooks from './hooks'

Vue.use(VueRouter)

const routes = [
  
  ...adminRoutes,
  ...loginRoutes,
  ...userAccountRoutes,
  ...helpersRoutes,
  ...appRoutes,
  {
    path: '*',
    redirect: { path: '/' }
  }
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

routerHooks(router)

export default router
