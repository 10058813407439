<template>
  <div class="filters w-full mt-4 flex flex-wrap items-center">
    <FilterClimateZone />
    <FilterSourceStudy />
    <FilterBuildingType />
    <FilterTypeVintage />
    <FilterTypeFuel />
    <FilterCostEffective />
  </div>
</template>

<script>
import FilterCostEffective from './FilterCostEffective.vue'
import FilterTypeFuel from './FilterTypeFuel.vue'
import FilterClimateZone from './FilterClimateZone'
import FilterSourceStudy from './FilterSourceStudy'
import FilterBuildingType from './FilterBuildingType'
import FilterTypeVintage from './FilterTypeVintage'
export default {
  name: 'FiltersWrapper',
  components: { 
    FilterClimateZone,
    FilterSourceStudy,
    FilterBuildingType,
    FilterTypeVintage, 
    FilterTypeFuel,
    FilterCostEffective
  }
}
</script>

<style>

</style>