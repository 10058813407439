import Vue from 'vue'
import AppHelper from '@/components/general/AppHelper.vue'
import formatCurrency from '@/formatters/formatCurrency'
import formatNumber from '@/formatters/formatNumber'

export const moneyDirective = (el, { value }) => {    
  el.setAttribute('title', value)
  const moneyValue = formatCurrency(value)
  el.innerHTML = moneyValue
}

export const numberDirective = (el, { value }) => {
  
  const number = typeof value === 'object' ? value.value : value
  const numberFormated = formatNumber(number)
  const onNegative = typeof value === 'object' && value?.onNegative ? value.onNegative : false
  
  el.setAttribute('title', number)

  if (onNegative && numberFormated < 0) {
    el.innerHTML = onNegative
  } else {
    el.innerHTML = numberFormated
  }
}

export const helperDirective = (el, { arg, value }) => {
  if (value) {

    if(el.childNodes[1]) {
      el.removeChild(el.childNodes[1])
    }

    const ComponentClass = Vue.extend(AppHelper)
    const instance = new ComponentClass({ propsData: { args: value, type: arg } })
    instance.$mount()

    if (value?.cssClass) {
      el.classList.add(value.cssClass)
    } else {
      el.classList.add('--helper')      
    }
    el.appendChild(instance.$el)
  }    
}

Vue.filter('striphtml', function (value) {
  if (value) {
    var div = document.createElement("div")
    div.innerHTML = value
    var text = div.textContent || div.innerText || ""
    return text    
  }
})


Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his children
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event)
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  },
})

const updateCheckEnvVisibility = (el, binding) => {
  if(Array.isArray(binding.value)) {
    el.style.display = ( binding.value.includes(process.env.VUE_APP_ENV)  ) ? "" : "none"
  } else {
    el.style.display = ( binding.value === process.env.VUE_APP_ENV  ) ? "" : "none"
  }
}

Vue.directive('check-env', {
  bind: updateCheckEnvVisibility,
  update: updateCheckEnvVisibility,
})