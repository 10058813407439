import ImpactAssumptions from '@/models/ImpactAssumptions'

export default () => ({
  drawerOptions:{
    visibility: false,
    path: false,
    tab: false,
    type: false, // enum ASSUMPTIONS_DRAWER_TYPES
    isEditingFromChart: false, // sets if the drawer was opened from building estimates chart
    typePrototypeEditing: false
  },
  resultsImpactAssumptions: new ImpactAssumptions(),
  policyImpactAssumptions: {},
  policy_id_selected: null,
  showNotLoggedWarning : false,
}) 