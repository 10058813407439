<template>
  <div 
    v-if="columnGroup && !(columnGroup.key === 'shared_assumptions' && isShowingOnlyDifferentAssumptions)" 
    class="w-auto flex flex-col" 
    :class="[ 'column-group', `column-group-${columnGroup.key}`]"
  >
    <!-- Super header -->
    <div class="w-full px-2 py-1 h-10 relative">
      <div class="superheader-border w-full border-b-2 border-deepsky-200 h-full column-group-superheader relative">
        <button class="column-group-helper-button focus:outline-none -ml-2 absolute">
          <feather 
            size="16" 
            type="info" 
            class="text-blue mr-2 cursor-pointer" 
            @click="openColumnsHelper(columnGroup.hasHelper)"
          />
        </button>
        <h2 class="pl-6 text-deepsky-900 text-header-2 font-bold">
          {{ columnGroup.title }}
        </h2>
      </div>
    </div>
    <div class="w-full flex">
      <div 
        v-for="(column, colIndex) in columnGroup.columns"
        :key="colIndex"
        class="comparison-table-column w-auto"
      >
        <!-- HEADER -->
        <div class="w-full h-12 flex items-start py-1 px-4 column-group-header">
          <!-- column-group-helper-button -->
          <feather 
            size="16" 
            type="info" 
            class="column-group-helper-button text-blue -ml-2 mr-2 cursor-pointer" 
            @click="openColumnsHelper(column.hasHelper)"
          />
          <div class="flex flex-col items-start justify-start">
            <p class="text-deepsky-900 font-bold">
              {{ column.title }}
            </p>
            <p class="text-ash-500">
              {{ column.description }}
            </p>
          </div>
        </div>

        <!-- ITEM -->
        <div class="w-full flex flex-col">
          <div 
            v-for="policy in policies"
            :key="`cell-${column.key}-${policy.id}`"
            class="comparison-table-row w-full flex flex-col text-gray04 py-2 justify-center row-scope-item border-box pl-8"
            :style="{ minHeight: `${itemsRowHeight}px` }"
          >
            <template v-if="['applicability_rate', 'annual_penetration_rate'].includes(column.key)">
              <AppChartPie
                :slices="[{ value: policy[column.key], color: '#4B8CA6'}]"
                :is-donut="true"
                background-color="#E3EEF2"
                :text="`${policy[column.key]}%`"
                :style="{ width: '47px', height: '47px' }"
                class="mx-auto"
              />
            </template>
            
            <template v-else-if="'effective_date' === column.key">
              <span class="text-header-2">
                <span class="text-deepsky-500"> {{ formatDate({ date: policy.start_at, format: 'MMMM D, YYYY', from: 'MM/DD/YYYY' }) }}</span> 
                <br>for a period of 
                <span class="text-deepsky-500">{{ policy.active_policy_duration }} years</span>
              </span>
            </template>
            
            <template v-else-if="'delay_installation' === column.key">
              <span class="text-header-2">                
                <span class="text-deepsky-500"> {{ policy[column.key] }} </span> years
              </span>
            </template>
            
            <template v-else-if="'clean_energy_progress' === column.key">
              <span class="text-header-2">
                <span class="text-deepsky-500">{{ policy.grid_max_renewables_level }}%</span> by <span class="text-deepsky-500">{{ policy.grid_max_renewables_year }}</span>
              </span>
            </template>
            
            <template v-else-if="'current_grid_renewable_level' === column.key">
              <span class="text-header-2 text-deepsky-500">
                {{ policy.current_grid_renewable_level }}%
              </span>
            </template>
            
            <template v-else-if="'current_kwh_emissions_factor' === column.key">
              <span class="text-header-2 text-deepsky-500">
                {{ policy.current_kwh_emissions_factor }}
              </span>
            </template>
            
            <template v-else-if="'baseline_installation_rate' === column.key">
              <span class="text-header-2">
                <span class="text-deepsky-500">{{ policy.likelihood_installations_first_third }}%</span> first 10 years  <br>
                <span class="text-deepsky-500">{{ policy.likelihood_installations_second_third }}%</span> second 10 years  <br>
                <span class="text-deepsky-500">{{ policy.likelihood_installations_final_third }}%</span> remaining years  <br>
              </span>
            </template>
            
            <template v-else-if="'current_therms_emissions_factor' === column.key">
              <span class="text-header-2">
                <span class="text-deepsky-500">{{ policy.current_therms_emissions_factor }}</span>
              </span>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppChartPie from '@/components/charts/AppChartPie.vue'
import { POLICIES_COMPARISON_SHOW_ONLY_DIFFERENT_ASSUMPTION_COLUMNS_PATH } from '@/util/Enums'

export default {
  name: 'ComparisonTableGroupAssumptions',
  components: { AppChartPie },
  props: {
    policies: {
      type: Array,
      required: true
    },
    columnGroupKey: {
      type: String,
      required: true
    },
    itemsRowHeight: {
      type: Number,
      required: true
    }
  },
  computed: {
    isShowingOnlyDifferentAssumptions() {
      return this.$store.getters['getterUserPrefferenceByPath'](POLICIES_COMPARISON_SHOW_ONLY_DIFFERENT_ASSUMPTION_COLUMNS_PATH)
    },
    columnGroup() {
      return this.$store.getters['comparison/getterExistingBuildingsColumnsSelected'].columnGroups.find((columnGroup) => columnGroup.key === this.columnGroupKey)
    }
  },
  updated() {
    this.$emit('checkScopeRowHeight')
  },
}
</script>
