<template>
  <div class="sm:sticky left-0 w-auto bg-white flex flex-col z-20 table-fixed-col">
    <!-- Super header -->
    <div class="w-full px-2 py-1 h-10">
      <div class="superheader-border w-full border-b-2 border-deepsky-200 h-full" />
    </div>

    <!-- BLANK ITEM TO COLUMN HEADERS -->
    <div class="w-full px-6 pl-12 py-1 h-12">
      <h3 class="text-header-2 text-bluebrand font-bold ml-8 pl-1">
        Policy Name
      </h3>
    </div>

    <!-- ITEM -->
    <div class="w-full flex flex-col">
      <div
        v-for="(policy, indexPolicy) in policies"
        :key="policy.id"
        class="comparison-table-row w-full flex items-center rounded-l-sm border-l pl-3 pr-8 row-scope-item border-box white-space-normal cursor-grab opacity-100-childrens-on-hover"
        :style="{ height: `${itemsRowHeight}px` }"
        :class="[checkColumnIsDragOver({ indexPolicy }) ? 'is-dragging-out' : '']"
        draggable="true"
        @dragstart="dragStart({ indexPolicy })"
        @dragover="dragOver({ indexPolicy })"
        @dragend="dragEnd"
      >
        <img 
          draggable="false" 
          class="mx-2 flex-shrink-0 opacity-0 transition-all" 
          src="/icons/move-balls.svg" 
          alt="Drag to move the order"
        >
        <feather 
          draggable="false"
          type="minus-circle"
          class="text-ash-300 mr-4 cursor-pointer transition-all hover:text-deepsky-400 flex-shrink-0"
          @click="$emit('toggle-policy', policy.id)"
        />
        <router-link
          draggable="false"
          :to="{ name: 'PolicyShow', params: { policy_id: policy.id }}"
          class="text-header-3 font-bold text-deepsky-900"
        >
          <span>{{ policy.title }}</span>
        </router-link>        
        <DropdownPolicyActions
          v-if="getterLoggedUser && (policy.user_id === getterLoggedUser.id)"
          class="ml-2 mt-1 opacity-0 transition-all"
          :policy="policy"
          :actions="['Edit']"
          :user-can-edit-policy="true"
          :should-redirect-after-policy-edit="false"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DropdownPolicyActions from '@/modules/app/policy/shared/DropdownPolicyActions.vue'
import { mapGetters } from 'vuex'
export default {
  name: 'ComparisonFixedColumn',
  components: { DropdownPolicyActions },
  props: ['policies', 'itemsRowHeight'],
  data: () => ({    
    dragOverPolicyIndex: -1,
    dragPolicyIndex: -1
  }),
  computed: {
    ...mapGetters(['getterLoggedUser'])
  },
  methods: {
    /* Drag And Drop */
    /* ----------------------------------------- */
    dragStart({ indexPolicy }) {
      this.dragPolicyIndex = indexPolicy      
    },
    dragEnd() {
      this.$emit('update-policy-order', { from: this.dragPolicyIndex, to: this.dragOverPolicyIndex })
      this.dragLeave()
    },
    dragOver({ indexPolicy }) {
      this.dragOverPolicyIndex = indexPolicy
    },
    checkColumnIsDragOver({ indexPolicy }) {
      return this.dragOverPolicyIndex == indexPolicy
    },
    dragLeave() {
      this.dragPolicyIndex = -1
      this.dragOverPolicyIndex = -1
    },
    moveItem({ studyKey, trashColumnGroup, from, to}) {
      this.$store.commit('studyResults/moveForecastColumnItem', { studyKey, trashColumnGroup, from, to})
      const colToBlink = document.getElementById(`edit-columns-column-${trashColumnGroup}-${to}`)
      if(colToBlink) {
        colToBlink.classList.add('blink')
        setTimeout(() => colToBlink.classList.remove('blink'), 800)
      }
    },
    /* ----------------------------------------- Drag And Drop */
    
  }
}
</script>

<style lang="scss" scoped>
  .comparison-table-row a {
    max-width: 180px;
    min-width: 160px;
    display: inline-block;
    // width: 100%;
    span {
      white-space: break-spaces;
    }
  }

  .is-dragging-out {
    position: relative;
    &:before {
      content: "";
      position: absolute;
      width: 100%;
      left: 0;
      height: 4px;
      top: -9px;
      background-color: #C5DEE8;
    }
  }
</style>