import Store from '@/store'
import { ASSUMPTIONS_DRAWER_TYPES } from '@/modules/app/assumptions/shared/enums'

export default (router) => {

  /**
   * Update assumptions drawerType on route change
   */
  router.beforeEach((to, from, next) => {
    if(to.params.policy_id) {
      Store.commit('assumptions/updateDrawerType', ASSUMPTIONS_DRAWER_TYPES.POLICIES)
      Store.commit('assumptions/setPolicyIdSelected', parseInt(to.params.policy_id))
    } else {
      Store.commit('assumptions/updateDrawerType', ASSUMPTIONS_DRAWER_TYPES.RESULTS)
      Store.commit('assumptions/setPolicyIdSelected', null)
    }
    next()
  })
}