
/* Custom Assumptions */
/* ----------------------------------------- */
  export const applicability_rate = (() => {
    return { 
      isActive: false,
      key: 'applicability_rate',
      title: 'Applicability Rate',
      assumption_keys: ['applicability_rate'],
      hasHelper: {
        type: 'helper',
        slug: 'assumptions-applicability_rate'
      }
    }
  })

  export const annual_penetration_rate = (() => {
    return { 
      isActive: false,
      key: 'annual_penetration_rate',
      title: 'Penetration Rate',
      assumption_keys: ['annual_penetration_rate'],
      hasHelper: {
        type: 'helper',
        slug: 'assumptions-annual_penetration_rate'
      }
    }
  })

  export const effective_date = (() => {
    return {
      isActive: false,
      key: 'effective_date',
      title: 'Effective Date',
      assumption_keys: ['start_at', 'active_policy_duration' ],
      hasHelper: {
        type: 'helper',
        slug: 'assumptions-effective_date'
      }
    }
  })

  export const delay_installation = (() => {
    return {
      isActive: false,
      key: 'delay_installation',
      title: 'Installation Time',
      assumption_keys: ['delay_installation'],
      hasHelper: {
        type: 'helper',
        slug: 'assumptions-delay_installation'
      }
    }
  })
  
  export const clean_energy_progress = (() => {
    return {
      isActive: false,
      key: 'clean_energy_progress',
      title: 'Clean Energy Progress',
      assumption_keys: ['grid_max_renewables_level', 'grid_max_renewables_year'],
      hasHelper: {
        type: 'helper',
        slug: 'assumptions-clean_energy_progress'
      }
    }
  })
  
  export const current_grid_renewable_level = (() => {
    return {
      isActive: false,
      key: 'current_grid_renewable_level',
      title: 'Current Grid Renewable Level',
      assumption_keys: ['current_grid_renewable_level'],
      hasHelper: {
        type: 'helper',
        slug: 'assumptions-current_grid_renewable_level'
      }
    }
  })
  
  export const current_kwh_emissions_factor = (() => {
    return {
      isActive: false,
      key: 'current_kwh_emissions_factor',
      title: 'Current Electric Emissions Factor',
      description: '(MTCO2/kWh)',
      assumption_keys: ['current_kwh_emissions_factor'],
      hasHelper: {
        type: 'helper',
        slug: 'assumptions-current_kwh_emissions_factor'
      }
    }
  })
  
  export const baseline_installation_rate = (() => {
    return {
      isActive: false,
      key: 'baseline_installation_rate',
      title: 'Baseline Installation Rate',
      assumption_keys: ['likelihood_installations_first_third', 'likelihood_installations_second_third', 'likelihood_installations_final_third'],
      hasHelper: {
        type: 'helper',
        slug: 'assumptions-baseline_installation_rate'
      }
    }
  })
  
  export const current_therms_emissions_factor = (() => {
    return {
      isActive: false,
      key: 'current_therms_emissions_factor',
      title: 'Natural Gas Emissions Factor',
      description: '(MTCO2/therm)',
      assumption_keys: ['current_therms_emissions_factor'],
      hasHelper: {
        type: 'helper',
        slug: 'assumptions-current_therms_emissions_factor'
      }
    }
  })

/* ----------------------------------------- Custom Assumptions */

/* Scope */
/* ----------------------------------------- */
  export const climate_zone = (() => {
    return {
      isActive: true,
      key: 'climate_zone',
      title: 'Climate Zones',
      hasHelper: {
        type: 'helper',
        slug: 'comparison-tables-scope-climatezone'
      }
    }
  })

  export const building_type = (() => {
    return {
      isActive: true,
      key: 'building_type',
      title: 'Building Types',
      hasHelper: {
        type: 'helper',
        slug: 'comparison-tables-scope-buildingtype'
      }
    }
  })

  export const vintage = (() => {
    return {
      isActive: true,
      key: 'vintages',
      title: 'Vintages',
      hasHelper: {
        type: 'helper',
        slug: 'comparison-tables-scope-vintage'
      }
    }
  })
/* ----------------------------------------- Scope */


/* City-wide Estimates */
/* ----------------------------------------- */
export const simple_payback = (() => {
  return {
    isActive: false,
    key: 'simple_payback',
    title:'Simple Payback',
    description: '(Years)',
    hasHelper: {
      type: 'helper',
      id: 3
    }
  }
})

export const on_bill_cost_ratio = (() => {
  return {
    isActive: true,
    key: 'on_bill_cost_ratio',
    title:'On-Bill',
    description: '≥ 1.0 is cost effective',
    hasHelper: {
      type: 'helper',
      id: 5
    }
  }
})

/* City-wide Estimates */
/* ----------------------------------------- */
export const forecast_units = (() => {
  return { 
    isActive: true,
    key: 'forecast_units_affected',
    title:'Affected Units',
    description: '(lifecycle)',
    hasProjections: true,
    hasHelper: {
      type: 'helper',
      id: 17
    },
  }

})
export const forecast_emissions_savings = (() => {
  return { 
    isActive: true,
    key: 'forecast_emissions_savings',
    title:'Emissions Savings',
    description: '(lifecycle MTCO2e)',
    hasProjections: true,
    hasHelper: {
      type: 'helper',
      id: 18
    },
  }
})
export const forecast_therms_savings = (() => {
  return { 
    isActive: false,
    key: 'forecast_therms_savings',
    title:'Gas Savings',
    description: '(lifecycle therms)',
    hasProjections: true,
    hasHelper: {
      type: 'helper',
      id: 22
    },
  }
})
export const forecast_kwh_savings = (() => {
  return { 
    isActive: false,
    key: 'forecast_kwh_savings',
    title:'Electricity Savings',
    description: '(lifecycle kWh)',
    hasProjections: true,
    hasHelper: {
      type: 'helper',
      id: 21
    },
  }
})

export const forecast_lifecycle_savings = (() => {
  return { 
    isActive: true,
    key: 'forecast_lifecycle_savings',
    title:'Lifecycle Savings',
    description: '(on-bill)',
    hasProjections: true,
    hasHelper: {
      type: 'helper',
      id: 19
    },
  }
})
export const forecast_initial_cost = (() => {
  return { 
    isActive: false,
    key: 'forecast_initial_cost',
    title:'Compliance Cost',
    description: '(lifecycle)',
    hasProjections: true,    
    hasHelper: {
      type: 'helper',
      id: 20
    },
  }
})


export const tdv2022_benefit_to_cost_ratio = (() => {
  return {  
    isActive: true,
    key: 'tdv2022_benefit_to_cost_ratio',
    title:'2022 TDV',
    description: '≥ 1.0 is cost effective',
    isChart: true,
    hasHelper: {
      type: 'helper',
      id: 1
    }
  }
})
