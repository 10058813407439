<template>
  <div
    v-if="isAdminAndNotProduction || isLocalHost"
    class="w-full flex flex-col mt-4 border-b border-ash-300 text-header-2 mb-10"
  >
    <h2 class="text-header-4 my-5">
      Custom Combination
    </h2>
    <template v-for="measure in climate_zone.measures">
      <div
        v-if="checkMeasureIsInCustomCombinatationByAnyTypeVintage({ climate_zone, measure })"
        :key="`measure-${measure.id}`"
        class="w-full flex" 
      >
        <div
          class="
          cell-width measure-title w-full h-10 flex items-center
          bg-white text-ash-700
          border border-ash-300 border-b-0 border-r-0 opacity-100-childrens-on-hover border"
        >
          <feather 
            size="16"
            type="info" 
            class="ml-1 mr-1 cursor-pointer opacity-0 transition-all duration-300 ease-in text-ash-400 hover:text-deepsky-800" 
            @click="$eventBus.$emit('openDescriptionModal', { type: 'measure', id: measure.id })"
          />
          <span>{{ measure.flexible_path_title || measure.title }}</span>
        </div>

        <div 
          v-for="(type_vintage, type_vintage_index) in measure.type_vintages" 
          :key="`measure-type_vintage-${type_vintage.id}`"
          class="
            measure-cell relative transition-all
            cell-width h-10 flex items-center p-2 flex-shrink-0
            text-ash-600
            border border-ash-300 border-b-0 border-r-0 last:border-r"
          :class="{
            'is-pinned' : checkMeasureIsMandatory({ measure, climate_zone, prototype, type_vintage, type_vintage_index, flexible_path_setup }),
          }"
          :data-measure-score="String(getMeasureScore({ measure, type_vintage_index }))"
        >                            
          <span :data-measure-score-value="getMeasureScore({ measure, type_vintage_index })" />                
        </div>
      </div>
    </template>        
  </div>  
</template>

<script>
import MeasureMenuService from '@/services/measure-menu/MeasureMenuGeneralService'
export default {
  name: 'MeasureMenuCustomCombinationMeasuresList',
  props: ['climate_zone', 'type_vintages', 'prototype', 'flexible_path_setup', 'policy'],
  computed: {
    isAdminAndNotProduction() {
      return this.$store.state.auth.loggedUser && this.$store.getters.getterLoggedUserType == 'admin' && window.location.host != 'explorer.localenergycodes.com'
    },
    isLocalHost() {
      return window.location.host == 'localhost:8080'
    },
  },
  methods: {
    ...MeasureMenuService
  }
}
</script>

<style>

</style>