
import JurisdictionIndex from '@/modules/app/jurisdiction/JurisdictionIndex.vue'
import SummaryIndex from '@/modules/app/jurisdiction/summary/SummaryIndex.vue'
import StudyResultsIndex from '@/modules/app/jurisdiction/study-results/StudyResultsIndex.vue'
import BuildingEstimatesIndex from '@/modules/app/jurisdiction/building-estimates/BuildingEstimatesIndex.vue'

export default [
  {
    path: 'jurisdiction/:jurisdiction_slug?',
    component: JurisdictionIndex,
    children: [
      {
        path: 'summary',
        name: 'Summary',
        component: SummaryIndex,
      },
      {
        path: 'study-results/:climate_zone_raw?',
        name: 'StudyResults',
        component: StudyResultsIndex,
      },
      {
        path: 'building-estimates',
        name: 'BuildingEstimates',
        component: BuildingEstimatesIndex
      }
    ]
  }
]