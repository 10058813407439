import BaseModel from './BaseModel'

export default class TypePrototype extends BaseModel {
  constructor(obj = {}) {
    super(obj)

    this.id = obj.id || null
    this.title = obj.title || ''
    this.slug = obj.slug || ''
    this.building_stock_title = obj.building_stock_title || ''   
    this.building_stock_helper = obj.building_stock_helper || ''   
    this.color = obj.color || ''   
    this.order = obj.order || 9999
    this.building_type_id = obj.building_type_id || null
    this.hide_in_building_estimates = obj.hide_in_building_estimates || false
    
    // Queries
    this.latest_studies_published_by_study_type = obj.latest_studies_published_by_study_type || []
    this.building_stocks = obj.building_stocks || false
    this.prototypes = obj.prototypes || []
    this.type_prototypes = obj.type_prototypes || []
    
    // Custom fields
    this.custom_field_item_type = 'type_prototypes'
    this.custom_fields_keys = ['icon', 'description', 'unit_measure']
    this.addCustomFields()
  }

}