<template>
  <div class="">    
    <div class="w-full flex justify-between items-center whitespace-no-wrap">
      <img
        class="mr-2"
        src="/icons/combo-measures.svg"
        alt="combo measures"
      >
      <span class="text-header-2 text-ash-600">Measures Combined</span>
      <Dropdown
        ref="dropdown"
        :button-classes="'px-2 bg-transparent w-full'"
        :dropdown-dialog-classes="'left-full bg-white'"
      >
        <template v-slot:buttonLabel>
          <span class="ts--accent--2 text-sec font-bold ml-2 border-b-4 border-dotted border-gray05 pb-1 flex items-center mt-2">
            {{ getFilterSelected.label }}
            <feather
              class="ml-2"
              type="chevron-down"
              size="14"
            />
          </span>
        </template>
        <template v-slot:items>
          <DropdownMenuList
            :items="options"
            :selected="value"
            key-label="label"
            key-value="value"
            @change="setFilterApplied($event)"
          />
        </template>
      </Dropdown>
    </div>
  </div>
</template>

<script>

import { randomString, find } from '@/util/Functions'
export default {
  name: 'StudyResultsContentMeasuresDropdown',
  props: {
    options: {
      type: Array,
      required: true
    },
    value: {
      type: String,
      required: true
    },
  },
  computed: {
    getItemKey() {
      return randomString(10)
    },
    getFilterSelected() {
      return find(this.options, { value: this.value })
    }
  },
  methods: {
    setFilterApplied(option) {
      if(option.value == this.value) return
      this.$emit('input', option.value)
      this.$emit('forecastTableFilterChanged', { combinedMeasureFilterSelected : option.value })
      this.$refs.dropdown.close()
    }
  }
}
</script>

<style scoped lang="scss">
  button {
    transition: 300ms ease-in-out all;
    color: #979797;
    &.is-selected, &:hover {
      color: #002A3A;
    }
  }
</style>