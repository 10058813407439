
/**
 * calculate building stocks items total
 */
export default ({ total, studyType, study, prototype, climateZone, vintage }) => {

  console.log('total.building_stock_units', total.building_stock_units)
  climateZone.building_stock_units+= vintage.building_stock_units
  prototype.building_stock_units+= vintage.building_stock_units
  studyType.building_stock_units+= vintage.building_stock_units
  study.building_stock_units+= vintage.building_stock_units
  total.building_stock_units+= vintage.building_stock_units
  console.log('total.building_stock_units', total.building_stock_units)
}