<template>
  <div
    class="w-full h-full overflow-hidden"
    :class="`route-${routeNameClass}`"
  >
    <BaseSidebar
      v-if="getSidebar"
      :sidebar-with="getSideBarWidth"
      :sidebar="getSidebar"
    />
    <div
      class="w-full h-full flex overflow-hidden relative z-10"
      :style="getStyle"
    >
      <div
        id="main-content"
        class="w-full overflow-hidden relative z-10"
      >
        <resize-observer @notify="$store.commit('general/setContentContainerWidth', $event.width)" />
        <router-view />
      </div>
      <AssumptionsDrawer />
    </div>

    <!-- GENERIC AND GLOBAL COMPONENTS -->
    <DrawerContent />
    <DescriptionModal />
    <LoadingModal />
    <BaseDrawerLeft />    
    <FooterNotesByDomain />
    <ModalAutoGeneratePolicyWizard />
    <StudyPDFVersionModal />
    <StudyGroupVersionsTimelineModal />
    <ModalVideos />
    <ModalUserSign />
    <AssumptionsModal />
    <AssumptionsOverwriteDataModal />
  </div>
</template>

<script>
import AssumptionsModal from '@/modules/app/assumptions/shared/AssumptionsModal.vue'
import AssumptionsOverwriteDataModal from '@/modules/app/assumptions/shared/AssumptionsOverwriteDataModal.vue'
import AssumptionsDrawer from '@/modules/app/assumptions/shared/AssumptionsDrawer.vue'
import StudyPDFVersionModal from '@/components/modal/StudyPDFVersionModal.vue'
import ModalAutoGeneratePolicyWizard from '@/modules/app/jurisdiction/study-results/content/general/ModalAutoGeneratePolicyWizard.vue'
import StudyGroupVersionsTimelineModal from '@/components/modal/StudyGroupVersionsTimelineModal.vue'
import FooterNotesByDomain from '@/components/general/FooterNotesByDomain.vue'
import ModalVideos from '@/components/modal/ModalVideos.vue'
import BaseSidebar from './BaseSidebar'
import SidebarDefault from '@/components/general/SidebarDefault.vue'

export default {
  name: 'BaseLayoutWithHeaderAndSideBar',
  components: { 
    BaseSidebar,
    FooterNotesByDomain,
    StudyGroupVersionsTimelineModal, 
    ModalAutoGeneratePolicyWizard, 
    StudyPDFVersionModal,
    ModalVideos, 
    AssumptionsDrawer, 
    AssumptionsModal,
    AssumptionsOverwriteDataModal,
  },
  metaInfo: {
    titleTemplate: '%sCost Effectiveness Explorer'
  },
  computed: {
    routeNameClass() {
      return (this.$route.name ?? this.$route.path).toLocaleLowerCase()
    },
    getSidebar() {
      const parentRouteWithSidebar = this.$route.matched.find(m => m.meta.sidebar && m.meta.sidebar.content)
			if (this.$route.meta.sidebar === null) {
       return false
			} else if (this.$route.meta.sidebar) {
       return this.$route.meta.sidebar
      } else if (parentRouteWithSidebar) {        
        return parentRouteWithSidebar.meta.sidebar
      } else {
        return SidebarDefault
      }
    },

    getStyle() {
      if (this.getSidebar) {
        return { paddingLeft: this.getSideBarWidth }
      } else {
        return false
      }
    },

    getSideBarWidth() {
      if(this.windowWidth < 768) return '100%'
        const mapSizes = {
          small : 40,
          medium: 190,
          large: 610
        }
      return this.getSidebar?.size ? mapSizes[this.getSidebar.size] + 'px' : `190px`      
    }
  },
}
</script>
