import ImpactAssumptions from '@/models/ImpactAssumptions'
import { ASSUMPTIONS_PATHS, STUDY_TYPES } from '@/modules/app/assumptions/shared/enums.js'

import Policy from '@/models/Policy'

export default { 
  closeDrawer(state) {
    state.drawerOptions.visibility = false
  },
  openDrawer(state) {
    state.drawerOptions = {
      ...state.drawerOptions,
      visibility: true,
      path: false,
      tab: false
    }
  },
  openDrawerAtImpactTab(state) {
    state.drawerOptions = {
      ...state.drawerOptions,
      visibility: true,
      path: ASSUMPTIONS_PATHS.IMPACT_RESULTS
    }
  },
  openDrawerAtBuildingEstimatesResidentialExisting(state) {
    state.drawerOptions = {
      ...state.drawerOptions,
      visibility: true,
      path: ASSUMPTIONS_PATHS.RESIDENTIAL_BUILDINGS,
      tab: STUDY_TYPES.EXISTING
    }
  },
  openDrawerAtBuildingEstimatesResidentialNew(state, payload = ASSUMPTIONS_PATHS.RESIDENTIAL_BUILDINGS) {

    for(const key in ASSUMPTIONS_PATHS ){
      if(ASSUMPTIONS_PATHS[key].slug === payload.slug) {
        state.drawerOptions = {
          ...state.drawerOptions,
          visibility: true,
          path: ASSUMPTIONS_PATHS[key],
          tab: STUDY_TYPES.NEW
        }
      }
    }

    // state.drawerOptions = {
    //   ...state.drawerOptions,
    //   visibility: true,
    //   path: Object.keys(ASSUMPTIONS_PATHS),
    //   tab: STUDY_TYPES.NEW
    // }
  },
  toggleDrawer(state, forceVisibility = null) {
    if(forceVisibility !== null) {
      state.drawerOptions.visibility = forceVisibility
    } else {
      state.drawerOptions.visibility = !state.drawerOptions.visibility
    }
  },
  updateDrawerPath(state, path) {
    state.drawerOptions.tab = false
    state.drawerOptions.path = path
  },
  updateDrawerTab(state, tab) {
    state.drawerOptions.tab = tab
  },
  updateDrawerType(state, type) {
    state.drawerOptions.type = type
  },
  setDrawerOptions(state, drawerOptions) {
    state.drawerOptions = drawerOptions
  },
  updateAssumptionsKeyValue(state, { key, value }) {
    state[state.drawerOptions.type][key] = value
  },
  setPolicyIdSelected(state, policy_id = null) {
    state.policy_id_selected = policy_id
  },
  setPolicyImpactAssumptions(state, policy = {}) {
    state.policyImpactAssumptions = new Policy(policy)
  },
  clearResultsImpactAssumptions(state) {
    state.resultsImpactAssumptions = new ImpactAssumptions()
  },
  clearPolicyImpactAssumptions(state) {
    state.policyImpactAssumptions = new Policy({ ...state.policyImpactAssumptions, ...new ImpactAssumptions() })
  },
  showNotLoggedWarning(state, showNotLoggedWarning) {
    state.showNotLoggedWarning = showNotLoggedWarning
  },
  updateDrawerIsEditingFromChart(state, isEditingFromChart){
    state.drawerOptions.isEditingFromChart = isEditingFromChart
  },
  updateTypePrototypeEditing(state, typePrototype){
    state.drawerOptions.typePrototypeEditing = typePrototype
  }
}