<template>
  <PsAccordionItem
    title="Baseline Installation Rate"
    :class="{ 'form-dirty' : hasValueUpdated }"
  >
    Absent this policy requirement, the required measures would have a
    <DropdownWithInputNumber
      :value="getValue('likelihood_installations_first_third')"
      :min-value="0"
      :max-value="100"
      :step="0.1"
      :base-value="firstThirdBaseValue"
      :button-label="`${getValue('likelihood_installations_first_third')}%`"
      suffix="%"
      @update:value="setValue('likelihood_installations_first_third', $event)"
    />
    likelihood of being installed anyway during the first 10 years, a 
    <DropdownWithInputNumber
      :value="getValue('likelihood_installations_second_third')"
      :min-value="0"
      :max-value="100"
      :step="0.1"
      :base-value="secondThirdBaseValue"
      :button-label="`${getValue('likelihood_installations_second_third')}%`"
      suffix="%"
      @update:value="setValue('likelihood_installations_second_third', $event)"
    />
    likelihood during the second 10 years, and a 
    <DropdownWithInputNumber
      :value="getValue('likelihood_installations_final_third')"
      :min-value="0"
      :max-value="100"
      :step="0.1"
      :base-value="finalThirdBaseValue"
      :button-label="`${getValue('likelihood_installations_final_third')}%`"
      suffix="%"
      @update:value="setValue('likelihood_installations_final_third', $event)"
    />
    likelihood during the remaining years of the policy lifecycle.     
  </PsAccordionItem>
</template>

<script>
import DropdownWithInputNumber from '@/components/general/DropdownWithInputNumber.vue'
import { defaultImpactAssumptionValues } from '@/models/ImpactAssumptions'

export default {
  name: 'AssumptionsCardCleanEnergyProgress',
  components: { DropdownWithInputNumber },
  data: () => ({
    firstThirdBaseValue: defaultImpactAssumptionValues.likelihood_installations_first_third,
    secondThirdBaseValue: defaultImpactAssumptionValues.likelihood_installations_second_third,
    finalThirdBaseValue: defaultImpactAssumptionValues.likelihood_installations_final_third,
  }),
  computed: {
    hasValueUpdated() {
      return (this.firstThirdBaseValue != this.getValue('likelihood_installations_first_third')) ||
      (this.secondThirdBaseValue != this.getValue('likelihood_installations_second_third')) ||
      (this.finalThirdBaseValue != this.getValue('likelihood_installations_final_third'))
    }
  },
  methods: {
    getValue(key) {
      return this.$store.state.assumptions[this.$store.state.assumptions.drawerOptions.type][key]
    },
    setValue(key, value) {
      this.$store.dispatch('assumptions/updateAssumptionsKeyValue', { key, value })
    }
  }
}
</script>
