<template>
  <div class="w-full rounded-sm border border-blue05 p-6">
    <div class="w-full flex justify-between">
      <h3 class="text-header-3 font-bold whitespace-no-wrap text-bluebrand mr-3">
        {{ policy.jurisdiction.type }}-Wide Policy Impact
      </h3>
      <div class="w-full flex justify-end">
        <h4 class="text-header-2 text-bluebrand font-bold mr-3">
          Filter impact by:
        </h4>
        <PolicyFilterItem
          v-for="(item, index) of filtersByType"
          :key="index"
          :item="item"
          :filter-items-unselected.sync="filterItemsUnselected"
        />
      </div>
    </div>
    <CardProjection
      v-if="getPolicyItemSelected"
      class="mt-6"
      :study_data="getPolicyItemSelected.data"
      :assumption="$store.getters['assumptions/getterPolicyImpactAssumptions']"
      :building_stock_units="getPolicyItemSelected.building_stock_units"
      :column-selected-key="columnSelectedKey"
      @update:columnSelectedKey="$emit('update:columnSelectedKey', $event)"
    />
  </div>
</template>

<script>

import CardProjection from '@/components/projections/CardProjection'
import PolicyFilterItem from './PolicyFilterItem'
import { find } from '@/util/Functions'
export default {
  name: 'PolicyChartCard',
  components: { PolicyFilterItem, CardProjection },
  props: ['policy', 'policy_summary_data', 'columnSelectedKey'],
  data() {
    return {
      filtersByType: [],
      filterItemsUnselected: [],
    }
  },
  computed: {
    getPolicyItemSelected() {
      return this.policy_summary_data[0]
    }
  },
  mounted() {
    this.getFiltersByType()
  },
  methods: {

    getFiltersByType() {
      this.filtersByType = []
      for( const total of JSON.parse(JSON.stringify(this.policy_summary_data))) {
        this.checkFilterItem(total.items, this.filtersByType)
      }
    },

    checkFilterItem(items, filtersByType) {
      for(const item of items) {
        if(!item.items) continue
        const finded = find(filtersByType, { type: item.type })
        if(!finded) {
          filtersByType.push(item)
        } else {
          if(item.items) this.checkSubItems(item.items, finded, item.type == 'vintage' ? 'measure_id' : 'id')
        }
        if(item.items) {
          this.checkFilterItem(item.items, filtersByType)
        }
      }
    },

    checkSubItems(items, finded, attr = 'id') {
      for(const subItem of items) {
        const subItemFinded = find(finded.items, { [attr]: subItem[attr]})
        if(!subItemFinded) finded.items.push(subItem)
      }
    }
    
  }
}
</script>