<template>
  <div class="w-full flex justify-center">
    <div class="max-w-lg flex items-center mt-10 p-2">
      <div class="w-1/2 flex flex-col items-start">
        <h1 class="text-header-6 text-bluebrand font-bold">
          Policies are the easy way to see how combinations of measures can impact your city/county.
        </h1>
        <p class="text-header-3 text-gray03 mt-6">
          Let's create your first one.
        </p>
        <PsButton
          class="mt-6"
          label="CREATE MY FIRST POLICY"
          size="medium"
          @click="$eventBus.$emit('openModalAutoGeneratePolicyWizard')"
        />
      </div>
      <div class="w-1/2 p-2">
        <img
          src="/images/no-policies.svg"
          alt=""
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PoliciesListUserHasNoPolicies'
}
</script>

<style>

</style>