export default {

  data() {
    return {
      dragAndDropDraggingIndex: -1,
      tableToOrdenationItems: '',
      dragAndDropOrdenationUrl: '/api/ordenation/sync_order'
    }
  },
  methods: {
    /**
    * Drag and drop methods
    */
    dragStart(which) {
      this.dragAndDropDraggingIndex = which
    },
    dragEnd() {
      this.dragAndDropDraggingIndex = -1
    },
    dragFinish(index, items, offset = 0){
      this.moveItem(this.dragAndDropDraggingIndex, index, items, offset)
    },
    moveItem(from, to, items, offset) {
      items.splice(to, 0, items.splice(from, 1)[0])
      this.remapItems(items, offset)
    },
    remapItems(items, offset) {
      if(!this.getTableToOrdenateItems) {
        throw new Error(`Missing computed getTableToOrdenateItems returning the name of the table to reordenate items eg: "prototypes"`)
      }
      const orderedItems = items.map( (item, index) => {
        const order = offset > 0 ? (offset + index) : index
        return {
          id: item.id,
          order: order
        }
      })
      this.$api.post(this.dragAndDropOrdenationUrl, { items: orderedItems, table: this.getTableToOrdenateItems, order_column: this.getTableToOrdenateColumn })
        .then(() => {
          this.$eventBus.$emit('dragAndDropOrdenationUpdated')
        })
    },
  }
}