<template>
  <div class="w-full">    
    <!-- MAX COST-EFFECTIVE SCORES ROW -->
    <div class="w-full flex border-b border-white">
      <div
        class="
        w-full h-10 flex items-center pl-10
        bg-deepsky-400 text-header-2 text-deepsky-800 font-bold
        opacity-100-childrens-on-hover"
      >
        <AppInfoHelper 
          :visible="true" 
          :absolute="true" 
          position="right"
          :icon-size="16"
          icon-class="text-deepsky-500 hover:text-deepsky-900"
          @icon-click="$eventBus.$emit('openDescriptionModal', { type: 'helper', slug: 'policy-flexiblepath-max-cost-effective-score' })"
        >
          <span class="pr-1">Max Cost-Effective Score</span>
        </AppInfoHelper>
      </div>

      <v-popover 
        v-for="(type_vintage, type_vintage_index) in type_vintages" 
        :key="`type_vintage-${type_vintage.id}`"
        :open="showMaxScoreTooltip && showMaxScoreTooltip[type_vintage.id]"
        trigger="manual"
        :auto-hide="false"
        placement="right-center"
      >
        <div
          :id="`max-target-score-type_vintage-${type_vintage.id}`"
          class=" cell-width h-10 flex items-center pl-4 flex-shrink-0 bg-deepsky-400 text-deepsky-900 text-header-2 font-bold"
        >
          <span>
            {{ getMaxTargetScore({ climate_zone, prototype, type_vintage_index, flexible_path_setup }) }}
          </span>
        </div>
        <MeasureMenuMaxScoreTooltip slot="popover" />
      </v-popover>
    </div>

    <div
      id="teste"
      class="w-full"
    >
      <div class="flex bg-deepsky-300 hover:bg-deepsky-200 transition-all">
        <div 
          class="
            w-full h-10 flex items-center pl-4
            text-header-2 text-deepsky-700 font-bold
            opacity-100-childrens-on-hover cursor-pointer"
          @click="showImpacts = !showImpacts"
        >
          <feather
            :type="!showImpacts ? 'chevron-right' : 'chevron-down'"
            class="text-deepsky-450 mr-2"
            :size="16"
          />
          <AppInfoHelper 
            :visible="true"
            :absolute="true"
            position="right"
            :icon-size="16"
            icon-class="text-deepsky-500 hover:text-deepsky-900"
            @icon-click="$eventBus.$emit('openDescriptionModal', { type: 'helper', slug: 'policy-flexiblepath-measuremenu-targetscore' })"
          >
            <span class="pr-1">{{ tier.title ? tier.title : 'Target Score' }}</span>
          </AppInfoHelper>
        </div>
        <div 
          v-for="(type_vintage, type_vintage_index) in type_vintages" 
          :key="`tier_type_vintage-${type_vintage.id}`"
          class="cell-width h-10 flex items-center flex-shrink-0"
        >
          <TargetScoreInput
            v-if="shouldBeIncludedInTypeVintage({ climate_zone, type_vintage_index })"
            :policy="policy"
            :prototype="prototype"
            :climate_zone="climate_zone"
            :tier="tier"
            :type_vintage="type_vintage"
            :type_vintage_index="type_vintage_index"
            :flexible_path_setup="flexible_path_setup"
            @showMaxScoreTooltip="onShowMaxScoreTooltip(type_vintage.id, $event)"
          />
          <span
            v-else
            class="pl-4"
          >--</span>
        </div>
      </div>
      
      <MeasureMenuImpacts
        v-show="showImpacts"
        :show-impacts="showImpacts"
        :tier="tier"
        :policy="policy"
        :prototype="prototype"
        :climate_zone="climate_zone"
        :type_vintages="type_vintages"
        :flexible_path_setup="flexible_path_setup"
      />
    </div>
  </div>
</template>

<script>
import MeasureMenuMaxScoreTooltip from './MeasureMenuMaxScoreTooltip.vue'
import MeasureMenuImpacts from './MeasureMenuImpacts.vue'
import TargetScoreInput from '../TargetScoreInput.vue'
import { getMaxTargetScore, shouldBeIncludedInTypeVintage } from '@/services/measure-menu/MeasureMenuGeneralService'

export default {
  name: 'MeasureMenuTier',
  components: { TargetScoreInput, MeasureMenuImpacts, MeasureMenuMaxScoreTooltip },
  props: ['tier', 'policy', 'prototype', 'climate_zone', 'type_vintages', 'flexible_path_setup'],
  data() {
    return {
      showImpacts: false,
      showMaxScoreTooltip: false
    }
  },
  created() {
    this.initShowMaxScoreTooltip()
  },
  methods: {
    getMaxTargetScore,
    shouldBeIncludedInTypeVintage,
    initShowMaxScoreTooltip() {
      this.showMaxScoreTooltip = this.type_vintages.reduce((acc, type) => {
        acc[type.id] = false
        return acc
      }, {})
    },
    onShowMaxScoreTooltip(type_vintage_id, value) {
      this.showMaxScoreTooltip[type_vintage_id] = value
    }
  }
}
</script>