<template>
  <div class="w-full flex items-center justify-center h-screen py-6 overflow-y-auto block p-3">
    <div class="login-card-w rounded">
      <div class="apply-card bg-white flex flex-wrap justify-center">
        <h3 class="mb-6 w-full text-center text-pri ts--title--5 font-bold">
          <span>Administrative Sign-In</span>
        </h3>
        <AppLoginGoogle class="w-full text-sm inline-block" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AdminSignIn',
  computed: {
    ...mapGetters(['getterLoggedUser'])
  },
  methods: {
    onSuccess() {
      if (this.$route.name == 'AdminSignIn') {
        this.$router.push({ name: 'AdminStudyList' })        
      } else {
        this.$router.push({ name: 'UserAccount' })                
      }
    }
  }
}
</script>