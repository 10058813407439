<template>
  <div>
    <div
      v-for="typePrototype in typePrototypes"
      :key="typePrototype.id"
      class="relative w-full flex flex-nowrap mb-4"
    >
      <div
        class="w-full border rounded py-4 pl-6 pr-12 grid grid-cols-6 gap-4 last:mb-0 cursor-pointer transition duration-300 opacity-100-all-childrens-on-hover"
        :class="typePrototypeIsSelected(typePrototype) ? 'border-deepsky-400 bg-deepsky-200' : 'bg-white border-deepsky-200 hover:border-deepsky-400 hover:bg-deepsky-200'"
        @click.prevent="toggleTypePrototype(typePrototype)"
      >
        <div 
          class="rounded-full h-20 w-20 flex items-center justify-center block"
          :class="typePrototypeIsSelected(typePrototype) ? 'bg-deepsky-100 text-deepsky-450' : 'bg-ash-200 text-ash-400'"
        >
          <inline-svg 
            :width="45" 
            :src="`/icons/type-prototypes-slug/${typePrototype.slug}.svg`"
            :stroke="typePrototypeIsSelected(typePrototype) ? `#69A7BF` : `#A9AEB0`"
          />
        </div>
        <div class="flex flex-col w-auto justify-center col-span-3">
          <div class="mb-2">
            <AppInfoHelper
              position="right"
              class="ml-auto"
              :tooltip="typePrototype.description"
            > 
              <h2 class="text-header-3 font-bold text-deepsky-800">
                {{ typePrototype.building_stock_title }}
              </h2>
            </AppInfoHelper>
          </div>          
          <span class="w-auto inline-flex bg-deepsky-100 pr-2 rounded-lg mr-auto">
            <AppChartPie
              :slices="[{ value: typePrototype.building_stocks * 100 / totalUnits, color: '#4B8CA6'}]"
              background-color="#C5DEE8"
              class="h-6 w-6 mr-2 float-left"
            />
            <div>
              <span class="font-bold mr-2">{{ Number(typePrototype.building_stocks * 100 / totalUnits).toFixed(0) }}%</span>
              <span class="text-ash-500 font-bold text-header-1">{{ Number(typePrototype.building_stocks).toLocaleString() }} {{ typePrototype.unit_measure }} </span>
            </div>
          </span>
        </div>
        <div class="flex flex-col w-auto justify-center col-span-2">
          <div
            class="bg-deepsky-100 rounded p-2 mr-3 opacity-0 transition-all"
            :class="{ 'opacity-100' : typePrototypeIsSelected(typePrototype) }"
          >
            <!-- <p class="text-p-1 text-deepsky-450 font-bold">Study Source:</p> -->
            <p
              class="text-p-1 text-deepsky-450 leading-3"
              v-html="getLatestStudyPublishedTitleFormatted(typePrototype)"
            />
          </div>
        </div>
      </div>
      <div class="absolute top-o right-0 h-full flex justify-end items-center pr-3">
        <Checkbox
          :prevent="true"
          size="22"
          class="transition-none"
          checkbox-classes="mb-0"
          :value="typePrototypeIsSelected(typePrototype)"
          @click.native.prevent="toggleTypePrototype(typePrototype)" 
        />
      </div>
    </div>
    <p class="text-ash-400 text-p-1 italic mt-2 mt-4 text-center">
      Soon you'll be able to auto-generate policies for other building types.
    </p>
  </div>
</template>

<script>
import AppChartPie from '@/components/charts/AppChartPie.vue'
import Study from '@/models/Study'
import TypePrototype from '@/models/TypePrototype'
export default {
  name: 'PolicyWizardStepThree',
  components: { AppChartPie },
  props: ['policy', 'currentStep'],
  data() {
    return {
      typePrototypes: []
    }
  },
  computed: {
    totalUnits() {
      return this.typePrototypes.reduce((prev, cur) => { return prev + cur.building_stocks }, 0)
    },    
  },
  mounted() {
    this.getTypePrototypes()
  },
  methods: {
    getUnitsPercentage() {
      return this
    },
    typePrototypeIsSelected(typePrototype) {
      return this.policy.typePrototypes.find(obj => obj.id === typePrototype.id)
    },
    toggleTypePrototype(typePrototype) {
      const typePrototypes = this.typePrototypeIsSelected(typePrototype) ?
        [ ... this.policy.typePrototypes.filter(obj => obj.id !== typePrototype.id ) ] :
        [ ... this.policy.typePrototypes, typePrototype ]
      
      this.$emit('update:policy', { ...this.policy, typePrototypes })
    },
    getLatestStudyPublishedTitleFormatted(typePrototype) {
      const latestStudy = new Study(typePrototype.latest_studies_published_by_study_type.find(study => study.study_group.study_type.id == 1))
      return latestStudy ?  '<b>Source Study: </b>' + latestStudy.title + '<br /> <b>Version</b>: ' + latestStudy.released_at_formatted  + '': ''
    },
    getTypePrototypes() {

      const query = `
        {
          type_prototypes {
            id
            building_stock_title
            slug
            latest_studies_published_by_study_type {
              title
              released_at
              study_group {
                title
                study_type {
                  title
                  id
                }
              }
            }
            custom_fields {
              item_id
              data
            }
            building_stocks (
              where: [{
                column: "jurisdiction_id",
                operation: "=",
                value: "${this.policy.jurisdiction_id}"
              }]
              sum: [
                { column: "units", as: "units_total" }
              ]
            ) {
              units_total
            }
          }
        }`

      this.$graphql({ query })
        .then(({ data }) => {          
          this.typePrototypes = [ ...data
            .type_prototypes            
            .map( obj => {        
              return { ...obj, building_stocks: obj.building_stocks[0].units_total }
            })
            .filter(typePrototype => typePrototype.building_stocks)
            .map( type_prototype => new TypePrototype(type_prototype))
          ]
        })
    },
  }
}
</script>