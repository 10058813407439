import { find, findIndex, findAll, removeAll } from './Functions'

/**
 * Check conflict in Array.prototype to avoid error
 */
const arrayPrototypesToCheckConflict = ['findInArray', 'findIndexInArray', 'findAllInArray', 'removeAllInArray']
for(const prototypeToCheck of arrayPrototypesToCheckConflict) {
  if(Array.prototype[prototypeToCheck]) 
    console.warn(`Overriding existing Array.prototype.${prototypeToCheck} - It is possible that some library or previously setup used this key [prototypeToCheck]`)
}

Array.prototype.findInArray = function(obj) {
  return find(this, obj)
}

Array.prototype.findIndexInArray = function(obj) {
  return findIndex(this, obj)
}

Array.prototype.findAllInArray = function(obj) {
  return findAll(this, obj)
}

Array.prototype.removeAllInArray = function(obj) {
  return removeAll(this, obj)
}
