<template>
  <tr
    class="opacity-100-all-childrens-on-hover text-p-2 text-gray04"
    :class="[
      {
        'bg-deepsky-100 p-7 my-3 text-header-3 font-bold text-gray04' : item.type == 'total',
        'font-bold text-blue02 text-base' : item.type == 'vintage',
        'hidden' : checkIndexIsHidden(item),
        'cell-pb-20' : item.type == 'vintage' && !checkItemIsVisible(item) || item.type == 'study_data' && item.is_last,
        'is-last' : item.is_last
      },
      `type-${item.type}`
    ]"
  >
    <td 
      class="sticky left-0 mt-1 bg-white"
      :class="dropdownIsOpen ? 'z-50': 'z-10'"
    >
      <div
        class="flex relative z-30 ml-4"
        :style="{ marginLeft: `${item.deep * 16}px` }"
      >
        <button
          class="flex items-center px-8"
          @click="$store.commit('policy/toggleHiddenItem', item)"
        >
          <feather
            v-if="item.deep > 0 && !item.last_deep || item.type === 'total'"
            :type="checkItemIsVisible(item) ? 'chevron-down' : 'chevron-right'"         
            size="16"
            class="text-gray mr-1 -mb-1 absolute -ml-6"
          />
          {{ item.title }}
        </button>
        <Dropdown
          v-if="item.type == 'vintage' && userCanEditPolicy"
          ref="dropdown"
          class="ml-3"
          :button-classes="'rounded-sm border border-blue05 px-2 py-1 flex items-center justify-center'"
          :dropdown-dialog-classes="'left-0 bg-white'"
          @open="dropdownIsOpen = true"
          @close="dropdownIsOpen = false"
        >
          <template v-slot:buttonLabel>
            <div class="w-full flex justify-between items-center">
              <feather
                class="text-gray04"
                type="more-horizontal"
                size="16"
              />
            </div>
          </template>
          <template v-slot:items>
            <div class="w-full flex flex-col justify-start items-start font-medium p-6 px-8">
              <button 
                class="text-black"
                @click="openPolicyEditCustomCombination(item.custom_combination_id)"
              >
                Edit
              </button>
              <button 
                class="text-red mt-4"
                @click="$eventBus.$emit('openModalDeleteCustomCombination', { vintage: item })"
              >
                Delete
              </button>
            </div>
          </template>
        </Dropdown>
      </div>
    </td>
    <template v-for="(columnGroup, index2) of columnGroups">
      <td
        v-for="column of columnGroup.columns"
        :key="index2 + '-' + columnGroup.key + '-' + column.key"
        class="relative"
      >
        <div
          v-if="!column.isChart"
          class="opacity-100-childrens-on-hover relative z-5"
        >
          <span>{{ formatStudyData(column.key, item.data[column.key], item.data) }}</span>
          <button
            v-if="column.hasProjections"
            class="opacity-0 trasition duration-300 easy-in-out"
            @click="$eventBus.$emit('setPolicyItemSelected', { item, columnSelectedKey: column.key })"
          >
            <img
              class="ml-2"
              src="/icons/chart_table.svg"
              alt=""
            >
          </button>
        </div>

        <div
          v-else
          class="w-full flex justify-end items-center"
        >
          <span class="mr-auto">{{ formatStudyData(column.key, item.data[column.key], item.data) }}</span>
          <SimpleProgressBar
            class="mr-6"
            :value="item.data[column.key]"
          />
        </div>
      </td>
    </template>
    <td
      v-if="indexRow === 0"
      :rowspan="rowsLength"
      class="align-top"
    >
      <OtherColumnsAvailableTableHelper
        :columns="$store.getters['policy/getterPolicyExistingBuildingsColumnsUnselected']"
        style="padding:0;"
        @edit-columns="$eventBus.$emit('openDrawerPolicyEditColumns', 'Existing Buildings')"
      />
    </td>
  </tr>
</template>

<script>
import OtherColumnsAvailableTableHelper from './OtherColumnsAvailableTableHelper.vue'
export default {
  name:'PolicyTableRow',
  components: { OtherColumnsAvailableTableHelper },
  props:  {
    item: {
      type: Object,
      required: true
    },
    columnGroups: {
      type: Array,
      required: true
    },
    policy: {
      type: Object,
      required: true
    },
    itemsHiddenIndexes: {
      type: Array,
      required: true
    },
    indexRow: {
      type: Number,
      required: true
    },
    rowsLength: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    dropdownIsOpen: false
  }),
  computed: {
    hiddenItems() {
      return this.$store.getters['policy/getterHiddenItems']
    },
    userCanEditPolicy() {
      return this.policy && (this.policy.user_id === this.$store.getters.getterLoggedUser?.id) ? true : false
    },
    
  },
  methods: {
    checkItemIsVisible(item) {
      return this.hiddenItems.indexOf(item) < 0
    },
    openPolicyEditCustomCombination(custom_combination_id) {
      this.$router.push({ ...this.$route, params: { policy_active_tab: 'edit_policy' }, query: { custom_combination_id }})
    },
    checkIndexIsHidden(item) {
      let isHidden = false
      for (let index = 0; index < this.itemsHiddenIndexes.length; index++) {
        const item_hidden = this.itemsHiddenIndexes[index]
        if (item.index.startsWith(`${item_hidden}-`)) {
          isHidden = true
          break
        }
      }
      return isHidden
    }
  }
}
</script>

<style scoped lang="scss">
  @mixin pseudo {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
  }
  tr {
    &.type-total {      
      td {
        &:before {
          @include pseudo;
          border-right-width: 0;
          border-left-width: 0;
        }
        &:first-of-type::before {
          background: #f6f9fb;
          border-color: #e4eef2;
          border-left-width: 1px;
          border-top-width: 1px;
          border-bottom-width: 1px;
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }        
      }
    }

    &.type-vintage {      

      td {
        &:before {
          @include pseudo;
          border: 1px solid #82C2DD50;
          border-right-width: 0;
          border-left-width: 0;
        }
        &:first-of-type::before {
          border-left-width: 1px;
          left: 60px;
          border-top-left-radius: 4px;
        }
        &:last-of-type::before {
          border-right-width: 1px;
          right: 20px;
          border-top-right-radius: 4px;
        }
      }

      &.cell-pb-20 {
        td {          
          &:first-of-type::before {
            border-bottom-left-radius: 4px;
          }
          &:last-of-type::before {
            border-bottom-right-radius: 4px;
          }
        }
      }

    }

    &.type-study_data {
      &.is-last td::before {
        @include pseudo;
      }      
      td:first-of-type {
        &:before {
          @include pseudo;
          left: 60px !important;
          border-left: 1px solid #82C2DD50;
        }
      }
      td:last-of-type {
        &:before {
          @include pseudo;
          right: 20px;
          border-right: 1px solid #82C2DD50;
        }
      }      
    }

    &.cell-pb-20 {
      td {
        padding-bottom: 15px;
        &:before {
          bottom: 10px !important;
          border-bottom: 1px solid #82C2DD50;
        }
        &:last-of-type::before {
          right: 20px;
        }
      }
    }      
  }
</style>