<template>
  <BaseDrawer
    :id="id"
    ref="baseDrawer"
    :title="title"
    :subtitle="subtitle"
    :layout="layout"
    :breadcrumbs="breadcrumbs"
  >
    <div class="app-drawer-content-styles w-full w-full">
      <p
        v-if="!isLoading && getterLoggedUser && $store.getters.getterLoggedUserType == 'admin'"
        class="block border border-red px-4 py-1"
      >
        {{ slug ? `slug: ${slug}` : '' }}
        {{ id ? `id: ${id}` : '' }}
      </p>
      <div
        v-if="isLoading"
        class="w-full flex mt-6 max-w-full w-screen overflow-hidden"
      >
        <AppLoader />
      </div>      
      <RenderHtmlExtended
        v-else-if="!isLoading && content"
        :html="content"
      />
      <div
        v-else
        class="rounded bg-gray06 rounded-sm p-3"
      >
        <p class="text-gray">
          No content available
        </p>
      </div>
    </div>
  </BaseDrawer>
</template>

<script>
import { mapGetters } from 'vuex'
import RenderHtmlExtended from './RenderHtmlExtended.vue'

function stateInitial() {
  return {
    isLoading: true,
    id: '',
    title: '',
    subtitle: '',
    content: '',
    breadcrumbs: null,
    layout: undefined,
    slug : ''
  }
}

export default {
  name: 'DrawerContent',
  components: { RenderHtmlExtended },
  data() {
    return stateInitial()
  },
  mounted() {
    this.$eventBus.$on('openDrawerContent', ({ type, id, slug, layout, breadcrumbs }) => {
      this.stateReset()
      this.$refs.baseDrawer.openDrawer()
      this.layout = layout ? layout : undefined
      this.breadcrumbs = breadcrumbs ? breadcrumbs : null
      this.getDrawerContent({ type, id, slug })
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('openDrawerContent')
  },
  computed: {
    ...mapGetters(['getterLoggedUser'])
  },
  methods: {

    stateReset() {
      Object.assign(this.$data, stateInitial())
    },

    closeDrawer() {
      this.showDrawer = false
      this.isLoading = true
      setTimeout(() => {
        this.title = ''
        this.content = ''
      }, 500)
    },

    applyVariablesInString(string) {
      if (string) {
        const matchs = string.match((/{{(.*?)}}/g))        
        if (matchs) {          
          matchs.forEach( source => {
            const variableName = source.match((/((?!\})(?!\{).)+/g))
            if (this[variableName]) {
              string = string.replace(source, this[variableName])
            }
          })
        }
      }
      return string
    },
    
    async getDrawerContent({ type, id, slug }) {

      this.showDrawer = true
      const column = slug ? 'slug' : 'id'
      const value = slug ?? id 
      const query = `
        {
          ${type} (
            where: [
              {column: "${column}", operation: "=", value: "${value}"},
            ]
          ) {
            id
            title
            content
            ${type === 'page' ? 'subtitle' : ''}
            ${['measure'].includes(type) ? 'description' : ''}
          }
        }
      `
      const { data } = await this.$graphql({ query })

      if(!data[type]) {
        this.$toast.error(`Sorry, we can't find the requested resource :(`)
        this.$refs.baseDrawer.closeDrawer()
        return
      }

      this.title = data[type].title
      this.id = id
      this.slug = slug
      const subtitle = data[type].subtitle ? data[type].subtitle : data[type].description
      this.subtitle = this.applyVariablesInString(subtitle)
      this.content = data[type].content
      this.isLoading = false
    },
  }
}
</script>

<style lang="scss" scoped>  
  ::v-deep {
    @media screen and (min-width: 920px) {
      &.app-drawer {
        max-width: 873px;
        padding: 24px 77px 0 109px;
      }
      .app-drawer-content, .app-drawer-header {
        padding: 0;
      }
    }
  }  
</style>
<style lang="scss">  
  .app-drawer-content-styles {
    @import '@/assets/vendor/tinyMceStyles';    
  }
</style>
