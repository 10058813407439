export const quickGuideOptions = {
  type: 'helper',
  slug: "page-quick-guide-cost-effectiveness-and-reach-codes",
  breadcrumbs: [
    // { title: 'Welcome', route: '/' },
    { title: 'Quick Guide' }
  ]
}

export const utilityRatesOptions = () => { 
  return {
    type: 'helper', 
    slug: "page-what-utility-rates-were-assumed-for-these-calculations?",
    breadcrumbs: [
      // { title: 'Welcome', route: '/' },
      // { title: $route.meta.title ?? $route.name , route: { ...$route} },
      { title: 'Utility Rate Info' }
    ]
  }
}

export const resultsSourcesOptions = () => { 
  return {
    type: 'helper', 
    slug: "page-where-do-the-cost-effectiveness-results-in-this-tool-come-from?",
    breadcrumbs: [
      // { title: 'Welcome', route: '/' },
      // { title: $route.meta.title ?? $route.name , route: { ...$route} },
      { title: 'Results Sources' }
    ]
  }
}