<template>
  <div id="loader">
    <img
      src="/loading.png"
      alt="loading"
    >    
    <span v-if="text">{{ text }}</span>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: [String, Boolean]
    }
  }
}
</script>

<style scoped lang="scss">
  #loader {
    display: flex;
    align-items: center;
  }
  img {
    animation: spin 1s infinite linear ;
    transform-origin: center;
  }
  span {
    font-weight: bold;
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 3px;
    text-transform: uppercase;
    color: app-color(sec);
    margin-left: 20px;
  }  
  @keyframes spin {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>