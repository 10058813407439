import getProjectionsByYear from './getProjectionsByYear'

/**
 * get projections resume / cummulative last value to use in table
 */
export default ({ 
  assumption,
  building_stock_units,
  kwh_savings,
  therms_savings,
  annual_bill_savings,
  initial_cost
}) => {

  const projectionsByYear = getProjectionsByYear({ 
    assumption,
    building_stock_units: building_stock_units,
    kwh_savings,
    therms_savings,
    annual_bill_savings,
    initial_cost
  })

  const lastItemOfProjection = projectionsByYear.length - 1

  const forecast_emissions_savings = projectionsByYear[lastItemOfProjection].forecast_emissions_savings.cummulative
  const forecast_initial_cost = projectionsByYear[lastItemOfProjection].forecast_initial_cost.cummulative
  const forecast_kwh_savings = projectionsByYear[lastItemOfProjection].forecast_kwh_savings.cummulative
  const forecast_lifecycle_savings = projectionsByYear[lastItemOfProjection].forecast_lifecycle_savings.cummulative
  const forecast_therms_savings = projectionsByYear[lastItemOfProjection].forecast_therms_savings.cummulative
  const forecast_units_affected = projectionsByYear[lastItemOfProjection].forecast_units_affected.cummulative

  return { 
    forecast_emissions_savings,
    forecast_initial_cost,
    forecast_kwh_savings,
    forecast_lifecycle_savings,
    forecast_therms_savings,
    forecast_units_affected
  }

}