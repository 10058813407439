<template>
  <div class="flex items-center ml-3">
    <div class="mr-3 ts--accent--2 text-gray02 whitespace-no-wrap">
      Filter by:
    </div>

    <Dropdown
      ref="dropdown"
      :button-classes="!getExcludeStudyIds.length ? 'bg-transparent rounded-full border border-gray05 text-gray04' : 'bg-blue05-50 text-blue02 border border-blue03 rounded-full'"
      :min-width-dropdown="400"
      :dropdown-dialog-classes="'min-w-300 bg-white p-6'"
      title="Source Study"
    >
      <template v-slot:buttonLabel>
        <div class="w-full flex justify-between items-center whitespace-no-wrap  px-2">
          <span>Source Study</span>
        </div>
      </template>
      <template v-slot:items>
        <div @click.stop>
          <AppAlert            
            v-if="showHelper"
            type="lime" 
            description="Use the filters to select <br />versions of each study"
            icon="info"
            action="OK"
            class="mb-4 text-lime-600 cursor-pointer"
            @click="showHelper = false"
          />
        </div>

        <div class="w-full flex flex-col font-medium text-gray02">
          <div
            v-for="studyType in study_types"
            :key="studyType.id"
            class="mb-2 last:mb-0"
          >
            <h2 class="ts--accent--book--3 mb-2">
              {{ studyType.title }}
            </h2>
            
            <div
              v-for="studyGroup in studyType.study_groups"
              :key="`dropdown-study-group-${studyGroup.id}`"
              class="pl-2"
            >
              <h3 class="text-header-2 font-bold text-gray02 mb-1">
                {{ studyGroup.forecast_filters_source_study_title }}
              </h3>
              <div
                v-for="(study, study_index) in studyGroup.studies"
                :key="`dropdown-study-group-${studyGroup.id}-study-${study.id}`"
                class="pl-3"
              >
                <Checkbox
                  class="w-full"
                  :label="`${study.getTitle} ${study.getPdfTag}`"
                  :value="study.published_at && !checkStudyIsSelected(study.id, study_index)"
                  label-classes="ts--accent--2 text-gray04"
                  :disabled="!study.published_at"
                  @change="toggleStudy(study.id)"
                > 
                  <AppDotNotification
                    v-if="studyGroup.lastPublishedVersion() && studyGroup.lastPublishedVersion().id == study.id"
                    tooltip="Latest published version" 
                    class="-mt-1"
                  />
                </Checkbox>
              </div>
            </div>
          </div>
        </div>
      </template>
    </Dropdown>
  </div>
</template>

<script>
import AppDotNotification from '@/components/general/AppDotNotification.vue'
import StudyGroupApiService from '@/services/api/StudyGroupApiService'
import StudyGroup from '@/models/StudyGroup'
import Study from '@/models/Study'

export default {
  name: 'FilterStudy',
  components: { AppDotNotification },
  data() {
    return {
      showHelper : false,
      study_types: []
    }
  },
  computed: {
    isFiltering() {
      return this.$route.fullPath.includes('exclude_study_ids')
    },
    getExcludeStudyIds() {
      return this.study_types.reduce((acc, study_type) => {
        study_type.study_groups.map((study_group) => {
          study_group.studies.map((study, index) => {
            if(this.checkStudyIsSelected(study.id, index)) {
              acc.push(study.id)
            }
          })
        })
        return acc
      }, [])
    }
  },
  mounted() {
    StudyGroupApiService.byStudyType()
      .then(study_types => { 
        this.study_types = study_types.map(study_type => {
          study_type.study_groups = study_type.study_groups.map(study_group => {            
            study_group.studies = study_group.studies.map(study => new Study(study))
            study_group.studies = study_group.studies.filter(study => study.is_upcoming ? false : true)
            return new StudyGroup(study_group)
          })
          return study_type
        }) 
      })

    this.$eventBus.$on('openForecastStudyFilter', () => {
      setTimeout(() => { this.$refs.dropdown.open() }, 150)
      this.showHelper = true
    })

  },

  methods: {
    checkStudyIsSelected(study_id, study_index) {
      if( !this.checkQueryHasKey('exclude_study_ids') ) return study_index !== 0
      return this.checkQueryKeyValue('exclude_study_ids', study_id)
    },

    toggleStudy(study_id) {
      const exclude_study_ids = this.getExcludeStudyIds.join(',')
      const newQuery = this.onlyToggleQueryKeyValue('exclude_study_ids', study_id, { exclude_study_ids })
      if(!newQuery['exclude_study_ids']) newQuery['exclude_study_ids'] = 'all'
      this.$router.push({ query: newQuery })
    }
  }
}
</script>
