import AdminSideBar from '@/modules/admin/layout/AdminSideBar'
import AdminHeader from '@/modules/admin/layout/AdminHeader'

export default {
  header: {
    content: AdminHeader
  },
  sidebar: {
    size: 'medium',
    content: AdminSideBar
  },
  auth: {
    required: true,
    is_admin: true
  }
}