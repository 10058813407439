<template>
  <div class="w-full flex flex-col bg-white p-6">
    <template v-if="custom_combination">
      <div class="w-full">
        <router-link
          :to="{ name: 'PolicyShow', params: { policy_id: custom_combination.policy.id }}"
          class="ml-4 text-p-1 text-ash-450 flex items-center space-x-2"
        >
          <feather
            type="chevron-left"
            size="16"
          />
          <span>Impact estimates / Edit measures in</span>
        </router-link>
      </div>

      <div class="w-full rounded border border-blue05 p-6 mt-6 bg-white">
        <div class="w-full flex flex-col text-bluebrand text-base font-bold">
          <div class="w-full flex flex-wrap mb-2 items-center">
            <h3 class="psui-text-gray-50 psui-space-x-2 psui-text-xsmall whitespace-no-wrap">
              <span>{{ custom_combination.vintage.title_long }}</span>
              <span>/</span>
              <span>{{ custom_combination.prototype.title }}</span>
              <span>/</span>
              <span :title="custom_combination.climate_zone_raw">Climate Zone {{ custom_combination.climate_zone.prefix }}</span>
              <span>/</span>
              <span>{{ custom_combination.study.title }}</span>
              <span>/</span>
              <span>{{ custom_combination.study.study_group.study_type.title }}</span>
            </h3>
            <div class="psui-w-auto psui-ml-auto flex flex-wrap xl:justify-end py-4 xl:py-0">
              <CombineMeasuresDropdown
                v-model="dataFilterSelected"
                :options="getDataFilters"
                :combined-measures-count="combinedMeasuresCount"
                @forecastTableFilterChanged="toggleAllPossibleMeasures"
              />
              <div class="divider ml-3 mr-4 hidden xl:block" />
              <button
                class="bg-transparent flex justify-between items-center whitespace-no-wrap focus:outline-none leading-none"
                @click="$eventBus.$emit('openStudyResultsDrawerEditColumns', 'Existing Buildings')"
              >
                <img
                  class="mr-2"
                  src="/icons/edit-columns.svg"
                  alt="combo measures"
                >
                <span class="text-gray02 ts--accent--2">Add/Hide Columns</span>
              </button>
            </div>
          </div>          

          <ForecastTable
            ref="forecastTable"
            :combined-measures-count.sync="combinedMeasuresCount"
            :study="custom_combination.study"
            :prototype="custom_combination.prototype"
            :building_stock_units="$store.getters['assumptions/buildingStocks/getterAllBuildingStocksUnits']({
              jurisdiction_id: custom_combination.jurisdiction_id,
              climate_zone_prefix: custom_combination.climate_zone.prefix,
              type_vintage_id: custom_combination.vintage.type_vintage_id,
              type_prototype_id: custom_combination.prototype.type_prototype_id,
            })"
            :vintage="custom_combination.vintage"
            :study_data="custom_combination.study_data"
            :measures-pre-selected-ids="custom_combination.measures.map(item => item.id)"
            :data-filter-selected.sync="dataFilterSelected"
            :climate_zone_raw="custom_combination.climate_zone_raw"
            :expanded="true"
            class="edit-custom-combination-forecast-table"
          />

          <div class="w-full flex justify-end mt-4">
            <CustomCombinationToPolicyButton
              :measures-count="combinedMeasuresCount" 
              @click="updateCustomCombination()"
            />
          </div>
        </div>
      </div>
      <StudyResultsDrawerEditColumns />
    </template>
    <SkeletonPolicyEditCustomCombination v-else />
  </div>
</template>

<script>
import StudyResultsDrawerEditColumns from '@/modules/app/jurisdiction/study-results/content/general/StudyResultsDrawerEditColumns.vue'
import ForecastTable from '@/modules/app/jurisdiction/study-results/content/table/ForecastTable'
import CombineMeasuresDropdown from '@/modules/app/jurisdiction/study-results/content/general/CombineMeasuresDropdown.vue'
import CustomCombinationToPolicyButton from '@/modules/app/jurisdiction/study-results/content/general/CustomCombinationToPolicyButton.vue'
import SkeletonPolicyEditCustomCombination from './skeleton/SkeletonPolicyEditCustomCombination'
export default {
  name: 'PolicyEditCustomCombination',
  components: { ForecastTable, CombineMeasuresDropdown, CustomCombinationToPolicyButton, StudyResultsDrawerEditColumns, SkeletonPolicyEditCustomCombination },
  data() {
    return {
      custom_combination: null,
      dataFilterSelected: 'none',
      combinedMeasuresCount: 0,
    }
  },
  computed: {
    getDataFilters() {
      const filtersAvailable = []
      filtersAvailable.push({ value: 'none', isSelected: true, label: `None` })
      filtersAvailable.push({ value: 'combine_all_possible', isSelected: false, label: `All Possible` })
      if(this.combinedMeasuresCount && this.dataFilterSelected != 'combine_all_possible') {
        filtersAvailable.push({ value: 'custom', isSelected: false, label: `Custom` })
      }
      return filtersAvailable
    }
  },
  mounted() {
    this.getCustomCombination()
  },
  methods: {
    getCustomCombination() {
      this.$api.get(`/api/custom_combinations/show/${this.$route.query.custom_combination_id}`)
        .then(({ data }) => {
          this.custom_combination = data.custom_combination
        })
    },
    toggleAllPossibleMeasures({ combinedMeasureFilterSelected }) {
      this.$refs.forecastTable.toggleAllPossibleMeasures({ combinedMeasureFilterSelected })
    },

    updateCustomCombination() {
      const measures = this.$refs.forecastTable.measureCombinations.map(item => item.measure_id)
      this.$api.post(`/api/custom_combinations/update/${this.$route.query.custom_combination_id}`, { measures })
        .then(() => {
          this.$router.push(`/policies/${this.custom_combination.policy_id}`)
          this.$eventBus.$emit('getPolicy')
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  .edit-custom-combination-forecast-table ::v-deep {
    .city-wide-actions {
      display: none !important;
    }
  }
</style>