<template>
  <div
    v-if="columnGroup"
    class="w-auto flex flex-col"
    :class="[ 'column-group', `column-group-${columnGroup.key}`]"
  >
    <!-- Super header -->
    <div class="w-full px-2 py-1 h-10 relative">
      <div class="superheader-border w-full border-b-2 border-deepsky-200 h-full column-group-header relative">
        <button class="column-group-helper-button focus:outline-none -ml-2 absolute">
          <feather 
            size="16" 
            type="info" 
            class="text-blue mr-2 cursor-pointer" 
            @click="openColumnsHelper(columnGroup.hasHelper)"
          />
        </button>
        <h2 class="pl-6 text-deepsky-900 text-header-2 font-bold">
          {{ columnGroup.title }}
        </h2>
      </div>
    </div>
    <div class="w-full flex">
      <div 
        v-for="(column, colIndex) in columnGroup.columns"
        :key="colIndex"
        class="comparison-table-column w-auto"
      >
        <!-- HEADER -->
        <div class="w-full h-12 flex items-start py-1 px-4 column-group-header">
          <feather 
            size="16" 
            type="info" 
            class="column-group-helper-button text-blue -ml-2 mr-2 cursor-pointer" 
            @click="openColumnsHelper(column.hasHelper)"
          />
          <div class="flex flex-col items-start justify-start">
            <p class="text-deepsky-900 font-bold">
              {{ column.title }}
            </p>
            <p class="text-ash-500">
              {{ column.description }}
            </p>
          </div>
        </div>

        <!-- ITEM -->
        <div class="w-full flex flex-col">
          <div 
            v-for="policy in policies"
            :key="`cell-${column.key}-${policy.id}`"
            class="comparison-table-row w-full flex flex-col text-gray04 text-center p-2 row-scope-item  border-box justify-center  pl-8"
            :style="{ minHeight: `${itemsRowHeight}px` }"
          >
            <!-- {{column.key}} -->
            <template v-if="'building_type' === column.key">
              <!-- <pre>{{policy.prototypes}}</pre> -->
              <div class="wrapper">
                <div
                  v-for="prototype in policy.prototypes"
                  :key="prototype.id"
                  class="prototype-wrapper p-1 border rounded-sm mb-1/2 last:mb-0"
                  :style="{ '--prototype-color' : prototype.color }"
                >
                  <div class="bg-ash-600 text-header-2 font-bold text-white rounded-sm px-2 py-1">
                    {{ prototype.title_abbreviated ? prototype.title_abbreviated : prototype.title }}
                  </div>
                </div>
              </div>
            </template>
            
            <template v-else-if="'vintages' === column.key">
              <div
                v-for="vintageType in availableVintageTypes" 
                :key="vintageType.id"
                class="px-2 py-1 mb-1 first:mt-1 rounded-full text-header-2 border font-medium"
                :class="{ 
                  'border-ash-300 bg-ash-100 text-ash-400' : !policyHasVintageType(policy, vintageType),
                  'border-deepsky-450 bg-deepsky-450 text-white' : policyHasVintageType(policy, vintageType)
                }"
              >
                {{ vintageType.title }}
              </div>
            </template>

            <template v-else-if="'climate_zone' === column.key">
              <div class="flex flex-nowrap justify-start">
                <div 
                  v-for="climateZonePrefix in availableClimateZones"
                  :key="`${policy.id}-${climateZonePrefix}`"
                  class="text-header-2 flex-shrink-0 mr-2 last:mr-0 text-ash-600 font-bold rounded-full w-10 h-10 border border-ash-300 flex items-center justify-center opacity-20"
                  :class="{ 'opacity-100' : policyHasClimateZone(policy, climateZonePrefix)}"
                >
                  {{ climateZonePrefix }}
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import GraphQL from '@/util/GraphQL'

export default {
  name: 'ComparisonTableGroupScope',
  props: [
    'policies',
    'itemsRowHeight'
  ],
  data: () => ({
    availableVintageTypes : []
  }),
  computed: {
    columnGroup() {
      return this.$store.getters['comparison/getterExistingBuildingsColumnsSelected'].columnGroups.find((columnGroup) => columnGroup.key === 'scope')
    },
    availableClimateZones() {
      const climateZonesPrefixByPolicies = this.policies.map(policy => policy.climate_zones.map(cz => cz.prefix))
      const climateZones = [].concat(...climateZonesPrefixByPolicies)      
      return [...new Set(climateZones)].sort((a, b) => a - b)
    }
  },
  created() {
    this.setAvailableVintageTypes()
  },
  updated() {
    this.$emit('checkScopeRowHeight')
  },
  methods: {
    setAvailableVintageTypes() {
      const query = `{
        type_vintages(
          orderBy: [{ column: "order", order: "asc" }]
          has: ["vintages"]
        ) {
          id,
          title
        }
      }`
      GraphQL({ query }, { shouldCache: true })
        .then(({ data }) => {
          if (data.type_vintages) {
            this.availableVintageTypes = data.type_vintages
          }
        })
    },
    policyHasVintageType(policy, vintageType) {
      const policyVintageTypeIds = policy.vintages.map((vintage) => parseInt(vintage.type_vintage_id))
      return policyVintageTypeIds.includes(parseInt(vintageType.id))
    },
    policyHasClimateZone(policy, climateZonePrefix) {
      return policy.climate_zones.map(cz => parseInt(cz.prefix)).includes(parseInt(climateZonePrefix))
    }
  }
}
</script>

<style scoped lang="scss">
  .prototype-wrapper {
    border-color : var(--prototype-color);
    > div {
      background-color : var(--prototype-color);
    }
  }
</style>