<template>
  <div class="psui-divide-y psui-divide-blue-20">
    <PsAccordion>
      <div 
        v-for="type_prototype in getTypePrototypeByHideInBuildingEstimates"
        :key="`type-prototype-assumption-${type_prototype.id}`"
      >
        <PsAccordionItem
          :title="type_prototype.title"
          layout="medium"
          :opened="getAssumptionsDrawerOptions.typePrototypeEditing.id === type_prototype.id"
        >
          <!-- CONSTRUCTION IMPLEMENTATION TYPE -->
          <template
            v-slot:default
            :type_prototype="type_prototype"
          >
            <BreakDownWidget
              switch_title="Breakdown by construction types"
              :items="getConstructionImplementationTypes"
              key_label="title"
              key_value="id"
              :selected="getConstructionImplementationTypes.find(it => it.id === breakdownByConstructionImplementationType[type_prototype.id]) "
              :is-switch-active="checkSwitch('breakdownByConstructionImplementationType', type_prototype.id)"
              @update:isSwitchActive="toggleBreakdown('breakdownByConstructionImplementationType', type_prototype.id, getConstructionImplementationTypes )"
              @update:selected="toggleBreakdownItem('breakdownByConstructionImplementationType', type_prototype.id, $event)"
            />

            <AssumptionsNonResidentialNewYears 
              v-if="specifyByYear" 
              :filters="getFilters(type_prototype, { type_prototype_id : type_prototype.id })" 
            />
            <AssumptionsBuildingEstimatesInputs
              v-else 
              :filters="getFilters(type_prototype, { type_prototype_id : type_prototype.id })"
              unit-measurement="Square feet"
            />
          </template>
        </PsAccordionItem>
      </div>
    </PsAccordion>
  </div>
</template>

<script>
import AssumptionsNonResidentialNewYears from './AssumptionsNonResidentialNewYears.vue'
import AssumptionsBuildingEstimatesInputs from '../AssumptionsBuildingEstimatesInputs.vue'
import { RESIDENTIAL_NEW_SPECIFICATIONS, BUILDING_ESTIMATES_BASE_YEARS } from '@/modules/app/assumptions/shared/enums'
import { constructionImplementationTypes } from '@/modules/app/assumptions/shared/BuildingEstimatesMockupData'
import BreakDownWidget from '@/components/general/BreakDownWidget.vue'

export default {
  name: 'AssumptionsResidentialNewBuildingTypes',
  components: { AssumptionsBuildingEstimatesInputs, AssumptionsNonResidentialNewYears, BreakDownWidget },
  props: {
    specifyBy: [ Boolean, Array ],
    type_prototypes: Array,
    climate_zone_prefix: [Boolean, Number],
  },
  data: () => ({
    breakdownByConstructionImplementationType: {},
    breakdownByBuildingHeight: {},
    construction_implementation_types : constructionImplementationTypes,
    years: BUILDING_ESTIMATES_BASE_YEARS
  }),
  computed: {
    specifyByYear() {
      return this.specifyBy.includes(RESIDENTIAL_NEW_SPECIFICATIONS.YEAR.key) 
    },
    getConstructionImplementationTypes() {
      return this.$store.getters['globalEntities/ConstructionImplementationType/getterGlobalConstructionImplementationTypes']
    },
    getBuildingHeights() {
      return this.$store.getters['globalEntities/BuildingHeight/getterGlobalBuildingHeights']
    },
    getTypePrototypeByHideInBuildingEstimates(){
      return this.type_prototypes.filter(tp => tp.hide_in_building_estimates === false)
    },
    getAssumptionsDrawerOptions() {
      return this.$store.getters['assumptions/getterAssumptionsDrawerOptions']
    }
    // getConstructionImplementationTypeSelected(implementation_type_selected){
    //   return this.getConstructionImplementationTypes.find(it => it.id === implementation_type_selected)
    // }
  },
  methods: {
    getFilters(type_prototype, filters) {
      return {
        ...this.getBaseFilters(type_prototype),
        ...filters
      }
    },
    getBaseFilters(type_prototype) {
      const filters = {}

      if (this.breakdownByBuildingHeight[type_prototype.id]) 
        filters.building_height_id =  this.getSelectedBuildingHeight(type_prototype.id)
      
      if (this.breakdownByConstructionImplementationType[type_prototype.id]) 
        filters.construction_implementation_type_id = this.getSelectedConstructionType(type_prototype.id)

      if (this.specifyBy.includes(RESIDENTIAL_NEW_SPECIFICATIONS.CLIMATE_ZONE.key) && this.climate_zone_prefix) 
        filters.climate_zone_prefix = this.climate_zone_prefix
      
      filters.type_prototype_id = type_prototype.id
      
      return filters
    },

    getSelectedBuildingHeight(type_prototype_id) {
      return this.breakdownByBuildingHeight[type_prototype_id] ?? this.breakdownByBuildingHeight[type_prototype_id].id
    },
    getSelectedConstructionType(type_prototype_id) {
      return this.breakdownByConstructionImplementationType[type_prototype_id] ?? this.breakdownByConstructionImplementationType[type_prototype_id].id
    },

    // New methods
    checkSwitch(breakdownObjKey, type_prototype_id) {
      return this[breakdownObjKey][type_prototype_id] ? true : false
    },
    toggleBreakdown(breakdownObjKey, type_prototype_id, items) {
      if(!this[breakdownObjKey][type_prototype_id]) {
        this.$set(this[breakdownObjKey], type_prototype_id, items[0].id)
      } else {
        this[breakdownObjKey][type_prototype_id] = false
      }
    },
    toggleBreakdownItem(breakdownObjKey, type_prototype_id, selected) {
      this[breakdownObjKey][type_prototype_id] = selected.id
    },

  },
}
</script>

<style lang="scss" scoped>
  .breakdown-switch {
    padding: 6px 12px;
    border-radius: 4px 4px 0px 0px;
    border-bottom: 1px solid white;
    
  }

  ::v-deep .psui-el-toggle {
    border-radius: 0px 0px 4px 4px;
    padding: 3px 4px;
  } 

</style>