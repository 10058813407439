<template>
  <div
    v-tooltip="inputTooltip"
    class="w-full h-full flex items-center border border-transparent transition-all"
    :class="[
      getTargetScoreHasError ? 
        'bg-candy-200 border border-candy-500' : 
        'hover:border-deepsky-450 text-deepsky-900',
      inputFocus ? 'border-deepsky-450' : ''
    ]"
  > 
    <input 
      ref="input" 
      v-model="targetScoreInput"
      v-mask="['#', '##', '###']"
      :min="1"
      :max="maxTargetScore"
      type="tel" 
      class="w-12 bg-transparent text-header-2 h-full pl-4 z-10 placeholder-deepsky-450 target-score-input"
      :class="{'caret-candy-500 text-candy-500' : getTargetScoreHasError }"
      :disabled="!userCanEditPolicy"
      @change="checkTargetScore()"
      @focus="onInputFocus"
      @blur="onInputBlur"
      @keyup.enter="$refs.input.blur()"
    >
    <feather
      v-if="!inputFocus || (inputFocus && !getTargetScoreHasError)"
      :class="{ 'text-deepsky-450' : !getTargetScoreHasError }"
      class="target-score-slider-icon text-transparent cursor-pointer" 
      size="16"
      :type="!inputFocus ? 'sliders' : 'check'"
      @click.capture="onIconClick()"
    />
  </div>
</template>

<script>
import { getMaxTargetScore, getTierTargetScore, setTierTargetScore } from '@/services/measure-menu/MeasureMenuGeneralService'
import { defaultManualTooltipOptions } from '@/util/Helpers'

export default {
  name: 'TargetScoreInput',
  props: [
    'policy',
    'prototype',
    'climate_zone',
    'type_vintage',
    'tier',
    'type_vintage_index',
    'flexible_path_setup'
  ],
  data() {
    return {
      targetScoreInput: 0,
      lastTargetScoreInput: 0,
      inputFocus: false,
      inputTooltip: {
        ...defaultManualTooltipOptions,
        placement: 'left-center',
        show: false,
      }
    }
  },
  computed: {
    getTargetScoreHasError() {
      return this.targetScoreInput == 0 || this.targetScoreInput > this.maxTargetScore
    },
    maxTargetScore() {
      const { climate_zone, prototype, type_vintage_index, flexible_path_setup } = this
      return this.getMaxTargetScore({ climate_zone, prototype, type_vintage_index, flexible_path_setup })
    },
    hasVisitedTargetScoreInput() {
      return this.$store.getters.hasVisitedModule('flexible_path_measure_menu_target_score_input')
    },
    shouldShowTooltip() {
      return this.getTargetScoreHasError ? true : false
    },
    userCanEditPolicy() {
      return this.policy && (this.policy.user_id === this.$store.getters.getterLoggedUser?.id) ? true : false
    },
  },
  watch: {
    inputFocus(focus) {
      this.inputTooltip.show = focus && this.shouldShowTooltip ? true : false
    },
    targetScoreInput(value) {
      this.toggleInputTooltip()
      this.toggleMaxScoreTooltip()
      // Small workaround to define if the number has been changed by the user or at created()
      const isUserChanges = typeof value === 'string'
      if (isUserChanges) {
        this.showMaxScoreTooltipIfFistTime()
      } else {
        this.lastTargetScoreInput = value
      }
    },
    maxTargetScore(maxTargetScore, previousMaxTargetScore) {
      this.reduceTargetScoreIfIsGreaterThanMax(maxTargetScore, previousMaxTargetScore)
    }
  },
  created() {
    const { tier, type_vintage, climate_zone, prototype, type_vintage_index, flexible_path_setup } = this
    this.targetScoreInput = this.getTierTargetScore({ tier, type_vintage, climate_zone, prototype, type_vintage_index, flexible_path_setup })
  },
  methods: {
    
    getMaxTargetScore,
    getTierTargetScore,
    setTierTargetScore,

    onInputBlur() {
      if (this.getTargetScoreHasError) {
        this.targetScoreInput = this.lastTargetScoreInput
      }
      // Workaround to onIconClick works
      setTimeout(() => {
        this.inputFocus = false
      },200)
    },
    onInputFocus() {
      this.inputFocus = true     
    },
    onIconClick() {
      !this.inputFocus ? this.$refs.input.focus() : this.$refs.input.blur()
    },
    checkTooltip() {
      this.inputTooltip.show = this.shouldShowTooltip
    },
    checkTargetScore() {      

      if(isNaN(this.targetScoreInput)) throw new Error(`Target score isn't a valid number: "${this.targetScoreInput}" typeof(${typeof(this.targetScoreInput)})`)

      if(this.getTargetScoreHasError) return
      const { type_vintage, climate_zone, prototype, type_vintage_index, flexible_path_setup, targetScoreInput : value } = this
      if (value) this.lastTargetScoreInput = value
      this.setTierTargetScore({ type_vintage, climate_zone, prototype, type_vintage_index, flexible_path_setup, value })
    },
    reduceTargetScoreIfIsGreaterThanMax(maxTargetScore, previousMaxTargetScore) {
      const maxTargetScoreHasDecreased = maxTargetScore < previousMaxTargetScore
      const targetScoreIsLowerThanMaxTargetScore = this.targetScoreInput > maxTargetScore
      if (maxTargetScoreHasDecreased && targetScoreIsLowerThanMaxTargetScore) {
        this.targetScoreInput = maxTargetScore
      }
    },
    toggleMaxScoreTooltip() {
      if (this.targetScoreInput > this.maxTargetScore) {        
        this.$emit('showMaxScoreTooltip', true)        
      } else if (this.targetScoreInput <= this.maxTargetScore) {        
        this.$emit('showMaxScoreTooltip', false)
      }
    },
    toggleInputTooltip() {
      if (this.targetScoreInput == 0 || !this.targetScoreInput) {
        this.inputTooltip.content = `Choose a value between 1 and ${this.maxTargetScore}`
        this.inputTooltip.show = true
      } else {
        this.inputTooltip.show = false
      }
    },
    showMaxScoreTooltipIfFistTime() {
      if (!this.hasVisitedTargetScoreInput) {
        this.$emit('showMaxScoreTooltip', true)
        this.$store.dispatch('setModuleVisited', 'flexible_path_measure_menu_target_score_input')  
      }
    }
  }
}
</script>

<style>

  .target-score-input {
    transition: all .5s;
  }
  .caret-candy-500 {
    caret-color:  #D65C5A !important;
  }

</style>