<template>
  <div class="app-widget-toggle">
    <div class="select-none breakdown-switch psui-flex psui-justify-between psui-items-center psui-text-gray-60 psui-bg-gray-10">
      <span class="psui-text-small">{{ switch_title }}</span>
      <PsSwitch
        :value="isSwitchActive"
        size="small"
        class="psui-ml-4"
        :label="switch_title"
        @change="onSwitchChange"
      />
    </div>
    <PsToggle 
      v-if="isSwitchActive"
      :items="items"
      :key-label="key_label"
      :key-value="key_value"
      :selected="selected"
      @change="onToggleChange"
    />
    <!-- @change="$emit('update:selected', $event)" -->
  </div>
</template>

<script>
export default {
  name: 'BreakDownWidget',
  props: ['switch_title', 'items', 'value', 'key_label', 'key_value', 'selected', 'isSwitchActive'],
  methods: {
    onSwitchChange(newValue) {
      this.$emit('update:isSwitchActive', newValue)
      if(!this.selected) {
        this.$emit('update:selected', this.items[0])
      } else {
        this.$emit('update:selected', false)
      }
    },
    onToggleChange(event) {
      this.$emit('update:selected', event)       
    }   
  }
}
</script>

<style lang="scss" scoped>
  .breakdown-switch {
    padding: 7px 12px;
    border-radius: 4px 4px 0px 0px;
    border-bottom: 1px solid white;
    
  }

  ::v-deep .psui-el-toggle {
    border-radius: 0px 0px 4px 4px;
    padding: 3px 4px;
  } 

  ::v-deep .psui-el-input {
    width: 130px;
  }

</style>