import Graphql from '@/util/GraphQL'
export default class UserApiService {
  
  static async all({ search } = { }) {    

    const query = `
      {
        users (
          ${search ? `where: [
            { column: "email", operation: "iLIKE", value: "%${search}%"}
          ],` : '' }
          orderBy: [{column: "created_at", order: "asc"}], 
        ){
          id
          email
          created_at
          updated_at          
          is_admin
          is_team
          accept_features_newsletter
          accept_periodic_newsletter
          has_spreadsheet_access
          policies {
            id
            jurisdiction {
              id
              title_type
            }
          }
        }
      }
    `

    const { data } = await Graphql({ query })

    return data.users
  }
}