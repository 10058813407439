<template>
  <p class="ts--accent--2">
    Find more reach code resources at 
    <a
      href="https://localenergycodes.com"
      class="psui-text-blue-60"
      target="_blank"
      title="Statewide Reach Codes Program"
    >LocalEnergyCodes.com</a>
  </p>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
  p {
    color: white;
    a {
      color: app-color('blue02');
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
</style>