<template>
  <div 
    :class="[ 
      position === 'right' ? 'flex-row-reverse justify-end' : '',
      visible ? '' : 'opacity-100-childrens-on-hover',
    ]"
    class="relative flex items-center"
  >
    <span 
      v-tooltip="tooltip"
      :size="iconSize"
      type="info"
      class="icon material-icons-round psui-text-h5 psui-leading-none order-2"
      :class="{
        'opacity-0 transition-all duration-300 ease-in' : !visible,
        'ml-2 mr-1 right-0' : position === 'right',
        'absolute' : absolute,        
        '-ml-4' : absolute && position === 'left',        
        '-mr-5' : absolute && position === 'right',
        'cursor-pointer mt-.5 transition-all' : true,
        [iconClass] : true,
      }"
      @click="$emit('icon-click')"
    >info</span>
    <slot name="default" />
  </div>
</template>

<script>
export default {
  name: 'AppInfoHelper',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    position: {
      type: String,
      default: 'left',
      validator: (v) => ['left', 'right'].indexOf(v) !== -1
    },
    absolute: {
      type: Boolean,
      default: false
    },
    iconClass: {
      type: String,
      default: 'psui-text-blue-50'
    },
    iconSize: {
      type: Number,
      default: 16
    },
    tooltip: {
      type: [Object, String, Boolean],
      default: false
    }
  }
}
</script>

<style>

</style>