import DataAndObjectFunctions from "@/util/DataAndObjectFunctions"

/**
 * Abstracted Data and Object functions to a own module
 */
 export const findByObj = DataAndObjectFunctions.findedByObj
 export const findByString = DataAndObjectFunctions.findByString
 export const find = DataAndObjectFunctions.find
 export const findIndex = DataAndObjectFunctions.findIndex
 export const findAll = DataAndObjectFunctions.findAll
 export const removeAll = DataAndObjectFunctions.removeAll
 export const filterObjectKeys = DataAndObjectFunctions.filterObjectKeys
 export const initClassData = DataAndObjectFunctions.initClassData
 
export const randomString = (length) => {
  var result           = ''
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  var charactersLength = characters.length
  for ( var i = 0; i < length; i++ ) {
     result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export const randomInt = (max, min = 0) => {
  return Math.floor((Math.random() * max) + min)
}

export const randomLetters = (length) => {
  var result           = ''
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
  var charactersLength = characters.length
  for ( var i = 0; i < length; i++ ) {
     result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export const handleTableScroll = (ev) => {
  if(ev.target.scrollLeft > 0) {
    ev.target.classList.add('table-has-scroll-left')
  } else {
    ev.target.classList.remove('table-has-scroll-left')
  }

  if( (ev.target.scrollLeft + ev.target.offsetWidth + 10 ) >= ev.target.scrollWidth) {
    ev.target.classList.remove('table-has-scroll-right')
  } else {
    ev.target.classList.add('table-has-scroll-right')
  }
}

export const objectHasAnyNullValue = (obj) => {
  for (let key in obj) {
    if (obj[key] === null) {
      return true
    }
  }
  return false
}

export const sumValuesFromObject = (obj) => {
  let sum = 0
  for( let el in obj ) {
    if( obj?.[el] ) {
      sum += parseFloat( obj[el] )
    }
  }
  return sum
}

export const joinCommaPlusAnd = (a) => {
  return [a.slice(0, -1).join(', '), a.slice(-1)[0]].join(a.length < 2 ? '' : ' and ')
}

export const onlyUniqueInArray = (value, index, self) => {
  return self.indexOf(value) === index
}

export const getMeasuresLengthText = (measures) => {
  const measuresLength = measures.length

  if (measuresLength === 0) {
    return null
  } else if (measuresLength === 1) {
    return `<span class="psui-flex psui-items-center psui-justify-center psui-bg-blue-60 psui-text-white psui-rounded-full psui-w-6 psui-h-6 psui-mr-3">${measuresLength}</span> Measure combined`
  } else {
    return `<span class="psui-flex psui-items-center psui-justify-center psui-bg-blue-60 psui-text-white psui-rounded-full psui-w-6 psui-h-6 psui-mr-3">${measuresLength}</span> Measures combined`
  }
}

const timestamps = []
export const delayExecution = (ms = 200, callback) => {
  const timestamp = Date.now()
  timestamps.push(timestamp)
  setTimeout( async () => {
    if(timestamps.length === 1) {
      callback()
    }
    timestamps.splice(timestamps.indexOf(timestamp), 1)
  }, ms)
}

export const checkRouteParams = ({ Router, type, item }) => {
  if(!Router.history.current[type][item]) {
    return []
  } else {
    return  Router.history.current[type][item].split(',').map(id => parseInt(id))
  }
}

export const removeNegative = (string) => {
  if (typeof string != 'string') string = string.toString()
  return parseFloat(string.toString().replace('-', ''))
}

export const isNegative = (string) => {
  if (typeof string != 'string') string = string.toString()
  return (string.indexOf('-') >= 0 || string[0] == '-') ? true : false
}

export const roundDecimal = (number, decimals = 1) => {
  var p = Math.pow(10, decimals)
  return Math.round(number * p) / p
}

export const compactNumber = (number, prefix = '') => {
  let numberIsNegative = false
  if (isNegative(number)) {
    numberIsNegative = true
    number = removeNegative(number)
  }
  const SI_SYMBOL = ["", "k", "M", "G", "T", "P", "E"]
  var tier = Math.log10(Math.abs(number)) / 3 | 0
  if(tier == 0) return number
  var suffix = SI_SYMBOL[tier]
  var scale = Math.pow(10, tier * 3)
  var scaled = number / scale
  const string = getToFixedDecimalIfDiffFromZero(scaled)
  return `${numberIsNegative ? '-' : ''}` + prefix + string + suffix
}

export const getToFixedDecimalIfDiffFromZero = (number) => {
  const string = number.toFixed(1)
  if(string.includes('.0')) {
    return number.toFixed(0)
  }
  return number.toFixed(1)
}


export const checkAndRoundDecimalIfExists = (number) => {
  if (number.toString().includes(".")) {
    const nearest = getNearestRoundValue(number)
    return roundDecimal(number, nearest.decimals)
  }
  return number
}

/**
 * get nearest round to value
 */
export const getNearestRoundValue = (number) => {
  number = removeNegative(number)
  const items = [
    { from: 0, to: 0.5, value: 0.01, decimals: 2 },
    { from: 0.5, to: 2, value: 0.1, decimals: 1 },
    { from: 2, to: 5, value: 0.5, decimals: 1 },
    { from: 5, to: 24, value: 1 },
    { from: 25, to: 50, value: 5 },
    { from: 50, to: 100, value: 10 },
    { from: 100, to: 250, value: 25 },
    { from: 250, to: 500, value: 50 },
    { from: 500, to: 2500, value: 100 },
    { from: 2500, to: 5000, value: 500 },
    { from: 5000, to: 25000, value: 1000 },
    { from: 25000, to: 50000, value: 5000 },
    { from: 50000, to: 250000, value: 10000 },
    { from: 250000, to: 1250000, value: 50000 },
    { from: 1250000, to: 100000000, value: 100000 },
    { from: 100000000, to: 999999999999999, value: 500000 },
  ]

  const filtered = items.filter( (item) => number >= item.from && number <= item.to )
  if (filtered.length && filtered.length <= 2) return filtered[0]
  return { value: 1 }
}

export const roundToNearest = (number, nearest) => {
  let numberIsNegative = false
  let roundedNumber
  if (isNegative(number)) {
    numberIsNegative = true
    number = removeNegative(number)
  }
  if (typeof nearest === "undefined" || !nearest) {
    nearest = getNearestRoundValue(number)
  }
  roundedNumber = checkAndRoundDecimalIfExists( Math.round(number / nearest.value) * nearest.value )
  return numberIsNegative ? `-${roundedNumber}` : roundedNumber
}

export const getNumberOfLabels = (number) => {
  if (["0", "1", "2", "4", "5"].includes(number.toString().charAt(0)))
    return 3
  if (["3", "6", "7", "8", "9"].includes(number.toString().charAt(0)))
    return 4
}

export const getParentScrollableEl = (node) => {
  
  if (node == null) {
    return null
  }

  if (node.scrollHeight > node.clientHeight) {
    return node
  } else {
    return getParentScrollableEl(node.parentNode)
  }

}

export const getParentVueComponentByName = (comp, name) => {
  if (comp.$options.name === name) {
    return comp
  } else {
    return getParentVueComponentByName(comp.$parent, name)
  }
}

export const groupBy = (xs, f) => {
  return xs.reduce((r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {})  
}

export const getStudyColumnsBySelectedStatus = (studyColumns, selectedStatus = true) => {
  const studyTypeFiltered = { ...studyColumns }
  studyTypeFiltered.columnGroups = studyColumns.columnGroups.map((columnGroup) => {
    const columnGroupFiltered = { ...columnGroup }
    columnGroupFiltered.columns = columnGroup.columns.filter((column) => column.isActive === selectedStatus )
    return columnGroupFiltered
  }).filter((columnGroup) => columnGroup.columns.length)
  return studyTypeFiltered
}