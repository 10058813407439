import Api from '@/util/Api'
import Policy from '@/models/Policy'
import Graphql from '@/util/GraphQL'
import UserEventApiService from '@/services/api/UserEventApiService'
export default class PolicyApiService {

  static store(payload, config) {

    return Api.post('/api/policies/store', new Policy(payload), config)
    .then(({ data }) => {
        window.$vueInstance.$store.dispatch('policy/getAllUserPolicies')
        UserEventApiService.log({ event: 'POLICY_CREATE', table: 'policies', table_item_id: data.id })
        return data
      })
      .catch(err => {
        if (err?.response?.data?.message) {
          window.$vueInstance.$appToast({ message: err?.response?.data?.message })
        } else {
          window.$vueInstance.$appToast({ message: 'Something went wrong, please try again.' })
        }
      })
  }

  static update(policy_id, payload, config) {
    return Api.post(`/api/policies/update/${policy_id}`, payload, config)
      .then(({ data }) => data )
      .catch(err => {
        if (err?.response?.data?.message) {
          window.$vueInstance.$appToast({ message: err?.response?.data?.message })
        } else {
          window.$vueInstance.$appToast({ message: 'Something went wrong, please try again.' })
        }
      })
  }

  static autoGenerate({ type_prototype_ids, policy_id }, config) {
    UserEventApiService.log({ event: 'POLICY_AUTO_GENERATE', table: 'policies', table_item_id: policy_id })
    return Api.post('/api/policy/auto_generate', { policy_id, type_prototype_ids }, config)
  }

  static get(policy_id) {
    return Api.get(`/api/policy/show/${policy_id}`).then(({ data }) => data )
  }

  static get_summary_data(policy_id) {
    return Api.get(`/api/policy/get_summary_data/${policy_id}`).then(({ data }) => data )
  }

  static getUserPolicies() {
    const query = `
      {
        currentUser {
          id
          email
          policies (
            whereNull: { columns: ["deleted_at"] }
          ) {
            id
            user_id
            jurisdiction_id
            title
            start_at
            annual_penetration_rate
            applicability_rate
            active_policy_duration
            years
            delay_installation
            likelihood_installations_first_third
            likelihood_installations_second_third
            likelihood_installations_final_third
            grid_max_renewables_year
            grid_max_renewables_level
            current_kwh_emissions_factor
            current_therms_emissions_factor
            current_grid_renewable_level
            created_at
            updated_at
            deleted_at
            is_auto_generated
            is_flexible_path
            user {
              id
              email
            }
            jurisdiction {
              id
              title
              title_type
              type
              city
              county
              slug
              climate_zones {
                id
                prefix
                suffix
                raw
              }
            }
          }
        }
      }
    `

    return Graphql({ query })
      .then(({ data }) => {
        if(!data.currentUser) return []
        return data.currentUser.policies.map((policy) => new Policy(policy))
      })
  }
  

  static getUserSharedPolicies() {

    const query = `
      {
        currentUser {
          id
          email
          shared_policies (
            whereNull: { columns: ["deleted_at"] }
          ) {
            id
            user_id
            jurisdiction_id
            title
            start_at
            annual_penetration_rate
            applicability_rate
            active_policy_duration
            years
            delay_installation
            likelihood_installations_first_third
            likelihood_installations_second_third
            likelihood_installations_final_third
            grid_max_renewables_year
            grid_max_renewables_level
            current_kwh_emissions_factor
            current_therms_emissions_factor
            current_grid_renewable_level
            created_at
            updated_at
            deleted_at
            is_auto_generated
            is_flexible_path
            user {
              id
              email
            }
            jurisdiction {
              id
              title
              title_type
              type
              city
              county
              slug
              climate_zones {
                id
                prefix
                suffix
                raw
              }
            }
          }
        }
      }
    `

    return Graphql({ query })
      .then(({ data }) => {
        if(!data.currentUser) return []
        return data.currentUser.shared_policies.map((policy) => new Policy(policy))
      })
  }

  static getPolicyMap({policy_id}) {
    return Api.get(`/api/policy/get_policy_map/${policy_id}`).then(({ data }) => data )
  }
}