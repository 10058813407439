<template>
  <div class="w-full px-12">  
    <h2 class="mt-4 mb-10">
      <router-link
        class="font-bold"
        :to="{name: 'AdminStudyGroupList'}"
      >
        StudyGroups
      </router-link> 
      <template v-if="study_group && study_group.id">
        <span class="text-red"> > </span>
        <router-link
          class="hover:underline"
          :to="{name: 'AdminStudyGroupEdit', params: { study_group_id : study_group.id }}"
        >
          {{ study_group.title }}
        </router-link>
      </template>
      <template v-if="studySelectedByRoute">
        <span class="text-red"> > </span>
        <router-link
          class="hover:underline"
          :to="{name: 'AdminStudyGroupStudyEdit', params: { study_group_id : study_group.id }}"
        >
          {{ studySelectedByRoute.title }}
        </router-link>
      </template>
    </h2>
    
    <AppTabHeader 
      v-if="$route.name !== 'AdminStudyGroupList'"
      :items="tabItems"
      :selected="{ value: $route.name, label: $route.meta.tabTitle }"
      @change="onTabSelect"
    />
    <hr class="border-gray05 my-3 mx-2">
    <router-view />
  </div>
</template>

<script>
import StudyGroup from '@/models/StudyGroup'
import Study from '@/models/Study'
import AdminStudyGroupRoutes from './routes'
export default {
  name: 'AdminStudyGroupIndex',
  data() {
    return {
      study_group: new StudyGroup(),
      tabSelected: ''
    }
  },
  computed: {
    tabItems() {      
      return AdminStudyGroupRoutes[0].children
      .filter(route => route.meta.tabTitle)
      .filter(route => !this.studySelectedByRoute && route.name === 'AdminStudyGroupStudyEdit' ? false : true )
      .map((route) => {
        return {
          value: route.name,
          label: route.meta.tabTitle
        }
      })
      .map(tabItem => {
        return tabItem.value === 'AdminStudyGroupStudyEdit' ? { ...tabItem, label: `Editing ${this.studySelectedByRoute.title} ${this.studySelectedByRoute.admin_title_formmated}`} : tabItem
      })
    },
    hasStudies() {
      return this.study_group && this.study_group?.studies?.length
    },
    studySelectedByRoute() {
      return this.$route?.params?.study_id ? new Study(this.study_group?.studies.find((study) => parseInt(study.id) === parseInt(this.$route.params.study_id))) : false
    },
  }, 
  watch: {
    '$route': {
      immediate: true,
      handler() {
        this.getStudyGroup()
      }
    }    
  },
  methods: {
    async getStudyGroup() {
      
      if(this.$route?.params?.study_group_id) {
        const query = `
          {
            study_group (
              findBy: { column: "id", value: "${this.$route.params.study_group_id}"}              
            ){
              id
              title
              study_type_id
              studies (
                orderBy: [ { column: "released_at", order: "desc" }]
              ) {
                id
                title
                is_private
                released_at
                expected_at
                published_at
                study_group_id
                custom_fields {
                  item_id
                  item_type
                  data
                }
              }
            }
          }
        `
        const { data } = await this.$graphql({ query })
        this.study_group = data.study_group
      } else {
        this.study_group = false
      }

    },
    onTabSelect(tab) {
      this.$router.push({ name: tab.value })
    }
  }
}
</script>

<style>

</style>