
String.prototype.supplant = function(o) {
  return this.replace(/{([^{}]*)}/g, function(a, b) {
    var r = o[b]
    return typeof r === "string" || typeof r === "number" ? r : a
  })
}

/* -----------------------------------------  */

Object.defineProperty(String.prototype, "hashCode", {
  value: function() {
    var hash = 0,
      i,
      chr
    for (i = 0; i < this.length; i++) {
      chr = this.charCodeAt(i)
      hash = (hash << 5) - hash + chr
      hash |= 0 // Convert to 32bit integer
    }
    return hash
  },
})

Object.find = function(obj, value) {
  let result = [],
    key
  
  for (key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj[key] == value ) {
      result.push(key)
    }
  }  
  return result
}

/* Slugfy */
/* ----------------------------------------- */
String.prototype.slugify = function (separator = "-") {
  return this
      .toString()
      .normalize('NFD')                   // split an accented letter in the base letter and the acent
      .replace(/[\u0300-\u036f]/g, '')   // remove all previously split accents
      .toLowerCase()
      .trim()
      .replace(/[^a-z0-9 ]/g, '')   // remove all chars not letters, numbers and spaces (to be replaced)
      .replace(/\s+/g, separator)
}

/* ----------------------------------------- Slugfy */

/* Object By String */
// https://stackoverflow.com/questions/6393943/convert-javascript-string-in-dot-notation-into-an-object-reference
/* ----------------------------------------- */
  Object.byString = function(obj,is,value) {
    if (typeof is == 'string')
        return Object.byString(obj,is.split('.'), value)
    else if (is.length==1 && value!==undefined)
        return obj[is[0]] = value
    else if (is.length==0)
        return obj
    else
        return Object.byString(obj[is[0]],is.slice(1), value)
  }
  
/* ----------------------------------------- Object By String */

