<template>
  <div class="psui-w-full psui-flex psui-flex-col psui-bg-white psui-p-6 psui-px-8">
    <div class="psui-w-full psui-flex psui-flex-wrap psui-items-center psui-mb-5">
      <div class="app-title psui-flex psui-items-center psui-flex-shrink-0 psui-w-auto psui-mr-auto">
        <h1 class="psui-text-h3 psui-text-blue-80 psui-font-bold psui-mr-2 psui-flex-shrink-0">
          Building Estimates for
        </h1>
        <JurisdictionSelect
          :should-scroll="false"
          :input-class="'psui-text-h3'"
          :jurisdiction-selected="lastJurisdictionVisited"
          @closeInput="showInput = false"
          @change="onJurisdictionSelect"
        />
      </div>

      <div class="psui-flex psui-items-center psui-space-x-2">
        <PsButton
          label="Share"
          icon="share"
          size="small"
          layout="onlytext"
          class="psui-text-blue-50"
          @click="onClickShare"
        />
        <Dropdown
          ref="dropdown"
          :button-classes="'px-2 bg-transparent w-full'"
          :dropdown-dialog-classes="'left-full bg-white'"
          :run-on-top="true"
        >
          <template v-slot:buttonLabel>
            <PsButton
              label="Download"
              icon="save_alt"
              size="small"
              layout="onlytext"
              class="psui-text-blue-60"
            />
          </template>
          <template v-slot:items>
            <DropdownMenuList
              :items="['PDF', 'CSV']"
              @change="$refs.dropdown.close(); $emit('trigger-download', $event)"
            />
          </template>
        </Dropdown>
      </div>
    </div>
  
    <div class="filters psui-w-full psui-flex psui-flex-wrap psui-items-center psui-justify-between">
      <FilterBuildingType />
      <div class="psui-flex psui-space-x-8">
        <FilterClimateZone />
        <PsButton
          label="Edit Assumptions"
          icon="tune"
          size="small"
          icon-position="left"
          @click="openAssumptionsDrawer(false)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FilterBuildingType from './filters/FilterBuildingType'
import FilterClimateZone from './filters/FilterClimateZone'
import { mapGetters } from 'vuex'
import { copyUrlToClipboard } from '@/util/Helpers'
import { GA_LABELS } from '@/mixins/GaEventsMixin'
import { ASSUMPTIONS_PATHS } from "../../assumptions/shared/enums"

export default {
  name: 'BuildingEstimatesHeader',
  components: { FilterBuildingType, FilterClimateZone },
  data: () => ({
    ASSUMPTIONS_PATHS,
  }),
  computed: {
    ...mapGetters(['lastJurisdictionVisited'])
  },
  methods: {
    onJurisdictionSelect(jurisdiction) {
      this.$store.dispatch('setUserLastJurisdictionAndFirstClimateZoneVisited', { value: jurisdiction, context: 'updateLastJurisdictionVisitedFromStudyResults'})
      this.pushToParams('jurisdiction_slug', jurisdiction.slug)
    },
    onClickShare() {
      copyUrlToClipboard()
      this.$appToast({ message: 'Link copied to clipboard!', duration: 5500 })
      this.gaEventShareUrl(GA_LABELS.BUILDINGSTOCK_SHARE_URL)
    },
    openAssumptionsDrawer(path) {
      this.$store.commit('assumptions/updateDrawerPath', path)
      this.$store.commit('assumptions/openDrawer')
      this.$store.commit('assumptions/updateDrawerIsEditingFromChart', false)
    }
  }
}
</script>
