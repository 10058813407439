<template>
  <div class="self-center">
    <div 
      class="
        option-policy-method flex items-center mb-5 p-4 border rounded border cursor-pointer relative transition duration-300
        border-deepsky-200 text-ash-500
        hover:border-deepsky-400
        "
      :class="item ? 'bg-deepsky-200 border-deepsky-400': 'bg-white'" 
      @click="$emit('update:item', true)"
    >
      <span
        v-if="isRecommended"
        class="badge-recommended bg-lime-400 rounded-sm"
      >Recommended</span>
      <div 
        :class="item ? 'bg-deepsky-400 border-deepsky-400' : 'bg-ash-100 border-ash-300 text-ash-100'"
        class="policy-method-options-check transition duration-300 mr-3 border rounded-full flex flex-shrink-0"
      >
        <feather
          class="m-auto text-white"
          type="check"
          size="20"
        />
      </div>
      <span
        class="mt-1 font-bold text-header-1 policy-method-options"
        :class="{ 'text-deepsky-500': item }"
      >
        {{ trueLabel }}
      </span>
      <feather
        class="ml-auto flex-shrink-0"
        :class="{ 'text-deepsky-500': item }"
        type="chevron-right"
        size="16"
      />
    </div>
    <div
      class="
        option-policy-method flex items-center mb-5 p-4 border rounded border cursor-pointer relative transition duration-300
        border-deepsky-200 text-ash-500
        hover:border-deepsky-400
        "
      :class="!item ? 'bg-deepsky-200 border-deepsky-400': 'bg-white'" 
      @click="$emit('update:item', false)"
    >
      <div 
        :class="!item ? 'bg-deepsky-400 border-deepsky-400' : 'bg-ash-100 border-ash-300 text-ash-100'"
        class="policy-method-options-check transition duration-300 mr-3 border rounded-full flex flex-shrink-0"
      >
        <feather
          class="m-auto text-white"
          type="check"
          size="20"
        />
      </div>
      <span
        :class="{ 'text-deepsky-500': !item }"
        class="mt-1 font-bold text-h2  policy-method-options"
      >
        {{ falseLabel }}
      </span>
      <feather
        class="ml-auto flex-shrink-0"
        :class="{ 'text-deepsky-500': !item }"
        type="chevron-right"
        size="16"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'WizardToggle',
  props: {
    item: {
      required: true,
      type: Boolean
    },
    isRecommended: {
      default: true
    },
    trueLabel: {
      default: 'Yes'
    },
    falseLabel: {
      default: 'No'
    }
  }
}
</script>

<style>
  .policy-method-options {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 125%;
    order: 0;
    margin: 0px 4px;
  }
  .policy-method-options-check {
    width: 32px;
    height: 32px;
  }
  .policy-method-options-check {
    width: 32px;
    height: 32px;
  }
  .badge-recommended {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 3px 6px;
    position: relative;
    width: 79px;
    height: 16px;
    position: absolute;
    right: -5px;
    top: -5px;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 100%;
    color: #FFFFFF;
  }
</style>