<template>
  <div
    v-if="columnGroup"
    class="w-auto flex flex-col"
    :class="[ 'column-group', `column-group-${columnGroup.key}`]"
  >
    <!-- Super header -->
    <div class="w-full px-2 py-1 h-10 relative">
      <div class="superheader-border w-full border-b-2 border-deepsky-200 h-full column-group-header relative">
        <button class="column-group-helper-button focus:outline-none -ml-2 absolute">
          <feather 
            size="16" 
            type="info" 
            class="text-blue mr-2 cursor-pointer" 
            @click="openColumnsHelper(columnGroup.hasHelper)"
          />
        </button>
        <h2 class="pl-6 text-deepsky-900 text-header-2 font-bold">
          {{ columnGroup.title }}
        </h2>
      </div>
    </div>
    <div class="w-full flex">
      <div 
        v-for="(column, colIndex) in columnGroup.columns"
        :key="colIndex"
        class="comparison-table-column w-auto"
      >
        <!-- HEADER -->
        <div class="w-full h-12 flex items-center py-1 px-4 column-group-header">
          <feather 
            size="16" 
            type="info" 
            class="column-group-helper-button text-blue -ml-2 mr-2 cursor-pointer" 
            @click="openColumnsHelper(column.hasHelper)"
          />
          <div class="flex flex-col items-start justify-start">
            <p class="text-deepsky-900 font-bold">
              {{ column.title }}
            </p>
            <p class="text-ash-500">
              {{ column.description }}
            </p>
          </div>
        </div>

        <!-- ITEM -->
        <div class="w-full flex flex-col">
          <div 
            v-for="policy in policies"
            :key="policy.id"
            class="comparison-table-row w-full px-4 py-2 max-w-xs row-scope-item border-box pl-8"
            :style="{ height: `${itemsRowHeight}px` }"
          >
            <ProgressBarValue
              v-if="getItemMaxValue(column.key) && columnGroup.key === 'city_wide_estimates'"
              :value="getPolicyTotalStudyData(policy)[column.key]"
              :max-value="getItemMaxValue(column.key)"
              :bar-class="column.key == 'forecast_initial_cost' ? 'bg-sunrise-200' : 'bg-deepsky-300'"
              :label-class="column.key == 'forecast_initial_cost' ? 'text-sunrise-800 text-header-2' : 'text-deepsky-800 text-header-2'"
              :label="formatStudyData(column.key, getPolicyTotalStudyData(policy)[column.key], getPolicyTotalStudyData(policy)).toString()"
            />
            <template v-else>
              <p class="text-sunrise-800 text-header-2 flex items-center h-full">
                {{ formatStudyData(column.key, getPolicyTotalStudyData(policy)[column.key], getPolicyTotalStudyData(policy)) }}
              </p>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProgressBarValue from '@/components/general/ProgressBarValue'
export default {
  name: 'ComparisonTableGroupStudyData',
  components: { ProgressBarValue },
  props: [
    'policies',
    'itemsRowHeight',
    'columnGroup'
  ],
  updated() {
    this.$emit('checkScopeRowHeight')
  },
  methods: {
    getPolicyTotalStudyData(policy) {
      return policy.policy_summary_data[0].data
    },
    getItemMaxValue(columnKey) {
      const orderedPolicies = this.policies.map((item) => item).sort((a, b) => b.policy_summary_data[0].data[columnKey] - a.policy_summary_data[0].data[columnKey])
      return orderedPolicies[0].policy_summary_data[0].data[columnKey]
    }
  }
}
</script>
<style lang="scss">
.column-group:last-of-type {
  padding-right: 40px;
  .column-item:last-of-type {
    padding-right: 5px;
    > div {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      margin-right: 1px;
      &:last-of-type {
        border-right-width: 1px;
      }
    }
  }
}
</style>
