<template>
  <div class="w-full flex justify-end">
    <PsButton
      label="Add key"
      icon="add"
      size="medium"
      @click="$refs.customFieldForm.showModal = true"
    />

    <BaseModal
      ref="customFieldForm"
      modal-dialog-classes="w-full max-w-sm"
      title="Add custom field"
    >
      <div class="w-full flex flex-col">
        <InputWithLabel
          v-model="custom_field.item_type"
          class="mt-6"
          :label="'Type (no space allowed)'"
        />
        <InputWithLabel
          v-model="custom_field.key"
          class="mt-6"
          :label="'Key (no space allowed)'"
        />
        <InputWithLabel
          v-model="custom_field.defaultValue"
          class="mt-6"
          :label="'Default value'"
        />
        <InputWithLabel
          v-model="custom_field.options"
          class="mt-6"
          :label="'Options (json)'"
        />
      </div>
      <PsButton
        class="psui-mt-6"
        label="Save"
        icon="add"
        size="medium"     
        :disabled="checkFormHasError"
        @click="addKey()"
      />
    </BaseModal>
  </div>
</template>

<script>
import checkJsonIsValid from '@/util/checkJsonIsValid'
export default {
  name: 'CustomFieldAddKeyForm',
  props: ['item_type', 'item_id'],
  data() {
    return {
      custom_field: {
        item_type: '',
        key: '',
        defaultValue: '',
        options: ''
      }
    }
  },
  computed: {
    checkFormHasError() {
      if(
          !this.custom_field.item_type ||
          this.custom_field.item_type.includes(' ') ||
          this.custom_field.item_type.includes('study_data') ||
          !this.custom_field.key ||
          this.custom_field.key.includes(' ') ||
          this.custom_field.options && !checkJsonIsValid(this.custom_field.options)
      ) return true
      return false
    }
  },
  methods: {
    addKey() {
      this.$api.post('/api/custom_field/add_key', { item: this.custom_field })
        .then(() => {
          this.$refs.customFieldForm.showModal = false
          this.$emit('change')
          this.clearItem()
        })
    },
    clearItem() {
      this.custom_field =  {
        item_type: '',
        key: '',
        defaultValue: '',
        options: ''
      }
    }

  }
}
</script>