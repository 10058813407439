<template>
  <div class="w-full p-6">
    <div class="w-full flex justify-end">
      <PsButton
        class="psui-mr-3"
        icon="sync"
        size="medium"
        label="Sync building stock"
        @click="sync()"
      />
      <router-link :to="{ name: 'AdminBuildingStockCreate' }">
        <PsButton
          size="medium"
          label="Create"
          icon="add"
        />
      </router-link>
    </div>

    <div class="w-full relative mt-6">
      <VueSelect
        v-model="jurisdictionSelected"
        class="vue-select"
        placeholder="Select a jurisdiction"
        :class="jurisdictionSelected ? 'filled' : ''"
        label="title"
        :options="jurisdictions"
        @input="getBuildingStocks()"
      />
      <label>Jurisdiction to filter</label>
    </div>
    <table class="table table-bordered mt-6 whitespace-no-wrap">
      <thead>
        <tr>
          <th>ID</th>
          <th>Jurisdiction</th>
          <th>Climate zones</th>
          <th>Prototype</th>
          <th>Vintage</th>
          <th>Units</th>
          <th>Table row number</th>
          <th class="psui-w-48">
            Edit
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(building_stock, index) in building_stocks"
          :key="`building_stock-${index}-${building_stock.id}`"
        >
          <td>{{ building_stock.id }}</td>
          <td>{{ building_stock.jurisdiction.title }}</td>
          <td>
            <router-link
              v-for="(climate_zone, index) in building_stock.climate_zones"
              :key="`building_stock-cz-${index}-${climate_zone.id}`"
              class="font-bold text-blue02"
              :to="{
                name: 'AdminClimateZoneEdit',
                params: { climate_zone_id: climate_zone.id },
              }"
            >
              {{ climate_zone.raw }}
            </router-link>
          </td>
          <td>{{ building_stock.type_prototype.title }}</td>
          <td>{{ building_stock.type_vintage.title }}</td>
          <td>{{ building_stock.units }}</td>
          <td>{{ building_stock.row_number }}</td>
          <td class="psui-flex psui-space-x-2">
            <router-link
              :to="{
                name: 'AdminBuildingStockEdit',
                params: { building_stock_id: building_stock.id },
              }"
            >
              <PsButton
                label="Edit"
                icon="edit"
                size="medium"
              />
            </router-link>
            <PsButton
              layout="caution"
              label="Delete"
              size="medium"
              icon="delete"
              @click="selectBuildingStockToDelete(building_stock)"
            />
          </td>
        </tr>
      </tbody>
    </table>

    <BaseModal
      ref="deleteModal"
      modal-dialog-classes="w-full max-w-sm"
      title="Attention"
    >
      <div
        v-if="building_stock_selected"
        class="w-full flex flex-col items-start mt-6"
      >
        <p class="text-base text-gray04">
          Delete
          <span class="text-red font-bold">{{
            building_stock_selected.jurisdiction.title
          }}</span>?
        </p>
        <PsButton
          layout="caution"
          class="psui-mt-6"
          label="Confirm delete"
          size="big"
          @click="deleteBuildingStock()"
        />
      </div>
    </BaseModal>

    <Paginator
      v-model="offset"
      :length="building_stocks.length"
      :limit="limit"
      @getItems="getBuildingStocks({ offset })"
    />
  </div>
</template>

<script>
export default {
  name: 'AdminBuildingStockList',
  data() {
    return {
      jurisdictions: [],
      building_stocks: [],
      building_stock_selected: null,
      offset: 0,
      limit: 12,
      jurisdictionSelected: null,
    }
  },
  mounted() {
    this.getBuildingStocks()
    this.getJurisdictions()
  },
  methods: {
    async getJurisdictions() {
      const query = `
        {
          jurisdictions {
            id
            title
          }
        }
      `
      const { data } = await this.$graphql({ query })
      this.jurisdictions = data.jurisdictions
    },

    async getBuildingStocks({ offset = 0 } = { offset: 0 }) {
      this.offset = offset
      const query = `
        {
          building_stocks(
            ${
              this.jurisdictionSelected
                ? `where: [{ column: "jurisdiction_id", operation: "=", value: "${this.jurisdictionSelected.id}"}]`
                : ``
            }
            orderBy: [{column: "id", order: "asc"}], 
            paginate: { offset: ${this.offset}, limit: ${this.limit}}
          ) {
            id
            climate_zone_prefix
            units
            row_number
            jurisdiction { title }
            climate_zones { id raw }
            type_prototype { title }
            type_vintage { title }
          }
        }
      `
      const { data } = await this.$graphql({ query })

      this.building_stocks = data.building_stocks
    },

    selectBuildingStockToDelete(building_stock) {
      this.building_stock_selected = building_stock
      this.$refs.deleteModal.showModal = true
    },

    deleteBuildingStock() {
      this.$api
        .delete(`/building_stock/${this.building_stock_selected.id}`)
        .then(({ data }) => {
          this.$toast.success(data.feedback)
          this.getBuildingStocks()
          this.$refs.deleteModal.showModal = false
          this.building_stock_selected = null
        })
        .catch((err) => {
          this.$toast.error(err.response.data.feedback)
        })
    },

    sync() {
      this.$api.get('/building_stock/sync').then(() => {
        this.$toast.success('Started sync process')
      })
    },
  },
}
</script>
