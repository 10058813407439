import { find, findIndex } from '@/util/Functions'

export default class MeasureMenuTier {  
  constructor({ prototype_id, climate_zone_id, mandatory_measures, excluded_measures, target_scores, title } = {}) {
    if (!prototype_id || !climate_zone_id) {
      throw new Error('Tiers requires a prototype_id and a climate_zone_id')    
    }
    this.title = title ?? ''
    this.prototype_id = prototype_id
    this.climate_zone_id = climate_zone_id
    this.mandatory_measures = mandatory_measures ?? []
    this.excluded_measures = excluded_measures ?? []
    this.target_scores = target_scores ?? []
  }

  updateTargetScore({ type_vintage_id , value }) {    
    const target_score = find(this.target_scores, { type_vintage_id })
    if (target_score) {
      target_score.value = parseInt(value)
    } else {
      this.setTargetScore({ type_vintage_id, value : parseInt(value) })
    }    
  }

  setTargetScore({ type_vintage_id , value }) {
    this.target_scores.push({ type_vintage_id, value })
  }

  toggleExcludedMeasures(item) {    
    const findedIndex = findIndex(this.excluded_measures, item)
    if(findedIndex > -1) {
      this.excluded_measures.splice(findedIndex, 1)
    } else {
      this.excluded_measures.push({ ...item })
    }
  }

  toggleMandatoryMeasures(item) {
    const findedIndex = findIndex(this.mandatory_measures, item)
    if(findedIndex > -1) {
      this.mandatory_measures.splice(findedIndex, 1)
    } else {
      this.mandatory_measures.push({ ...item })
    }
  }
}