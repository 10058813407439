<template>
  <div
    v-if="jurisdictions && jurisdictionSelected"
    class="w-full"
  >
    <div class="w-full flex">
      <h1 class="text-header-4 font-bold text-deepsky-800 mr-6 flex items-center">
        Compare Policies for 
        <Dropdown
          v-if="jurisdictions.length > 1"
          ref="measureDropdown"
          :button-classes="'px-2 bg-transparent w-full'"
          :dropdown-dialog-classes="'left-0 bg-white'"
        >
          <template v-slot:buttonLabel>            
            <span 
              v-if="jurisdictionSelected"
              class="text-header-4 text-deepsky-500 border-b border-gray05 pb-1 flex items-center"
            >
              {{ jurisdictionSelected.label }}
              <feather
                v-if="jurisdictions.length > 1"
                class="ml-2"
                type="chevron-down"
                size="14"
              />
            </span>
          </template>
          <template v-slot:items>
            <DropdownMenuList              
              :items="jurisdictions"
              :selected="jurisdictionSelected.id"
              :key-label="getJurisdictionListDropdownLabel"
              key-value="id"
              @update:selected="onChangeJurisdiction"
            />
          </template>
        </Dropdown>
        <span
          v-else-if="jurisdictionSelected"
          class="text-header-4 text-deepsky-500 border-gray05 ml-2 font-normal"
        >
          {{ jurisdictionSelected.label }}
        </span>
      </h1>

      <div class="actions flex-shrink-0 ml-auto">
        <ul class="flex items-end">
          <li
            class="text-accent-2 text-blue02 cursor-pointer transition hover:opacity-70"
            @click="copyLink()"
          >
            Share
          </li>
        </ul>
      </div>
    </div>

    <div class="w-full flex justify-end items-center">      
      <Toggle
        label="Show Only Different Assumptions" 
        label-class="ts--accent--2 text-gray04"
        :active="isShowingOnlyDifferentAssumptions"
        :disabled="!hasDifferentAssumptionColumns"
        @update:active="onToggleShowDifferentAssumptions"
      />      
      <AppCardButton
        icon="edit"
        description="Select Policies to Compare"
        icon-class="text-deepsky-500"
        class="ml-5 cursor-pointer"
        @click.native="$eventBus.$emit('openComparisonPoliciesSelectDrawer')"
      />
      <AppCardButton
        icon="columns"
        description="Add/Hide Columns"
        icon-class="text-deepsky-500"
        class="cursor-pointer"
        @click.native="$eventBus.$emit('openDrawerComparisonEditColumns')"
      />
    </div>
  </div>
</template>

<script>
import DropdownMenuList from '@/components/general/DropdownMenuList.vue'
import { copyUrlToClipboard } from '@/util/Helpers'
import { POLICIES_COMPARISON_SHOW_ONLY_DIFFERENT_ASSUMPTION_COLUMNS_PATH } from '@/util/Enums'

export default {
  name: 'ComparisonHeader',
  components: { DropdownMenuList },
  props: {
    jurisdictions: {
      type: Array,
      required: true
    },
    buildingType: {
      type: String,
      default: 'existingBuildings',
      validator: (value) => ['existingBuildings', 'newBuildings'].includes(value)
    }
  },
  computed: {
    hasDifferentAssumptionColumns() {
      return this.$store.getters['comparison/getterComparisonColumns'][this.buildingType].columnGroups.find((columnGroup) => columnGroup.key === 'different_assumptions').columns.length ?? false
    },
    isShowingOnlyDifferentAssumptions() {
      return this.$store.getters['getterUserPrefferenceByPath'](POLICIES_COMPARISON_SHOW_ONLY_DIFFERENT_ASSUMPTION_COLUMNS_PATH)
    },
    jurisdictionSelected() {
      const queriedJurisdictionId = this.$route.query.jurisdiction_id
      return this.jurisdictions.length && queriedJurisdictionId ? this.jurisdictions.find(jur => parseInt(jur.id) === parseInt(queriedJurisdictionId)) : false
    }
  },
  watch: {
    jurisdictionSelected: {
      handler: function(jurisdiction) {
        if (jurisdiction) this.$store.dispatch('setUserLastJurisdictionAndFirstClimateZoneVisited', {
          value: jurisdiction,
          context: 'updateLastJurisdictionVisitedFromPoliciesComparisonShow'
        })
      },
      immediate: true
    }
  },
  methods: {
    onChangeJurisdiction(jurisdiction_id) {
      this.$emit('update-jurisdiction', jurisdiction_id)
      this.$refs.measureDropdown.close()
    },
    copyLink() {
      copyUrlToClipboard()
      this.$appToast({ message: 'Link copied to clipboard!' })
    },
    onToggleShowDifferentAssumptions(value) {
      if (value) this.$emit('show-only-different-assumptions')
      this.$store.dispatch('setUserPreferences', { payload : { path: POLICIES_COMPARISON_SHOW_ONLY_DIFFERENT_ASSUMPTION_COLUMNS_PATH, value } })
    },
    getJurisdictionListDropdownLabel(jurisdiction) {
      return `${jurisdiction.title} ${jurisdiction.type}`
    }
  }
}
</script>