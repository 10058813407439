import Api from '@/util/Api'

export default class StudyDataApiService {

  static get(payload) {
    return Api.post(`/study_result/get_study_data`, payload)
    .then(({ data }) => {
      if (data && data.study_data) {
        return data.study_data
      }
    })
  }
  
}
