import StudyType from './StudyType'
import BaseModel from './BaseModel'
export default class StudyGroup extends BaseModel {
  constructor(obj = {}) {
    super(obj)
    this.id = obj.id || null
    this.title = obj.title || ''
    this.study_type_id = obj.study_type_id || ''
    this.last_study_published_at = obj.last_study_published_at || ''
    this.last_study_released_at = obj.last_study_released_at || ''
    this.studies = obj.studies || []
    this.study_type = obj.study_type || new StudyType()

    // Custom fields
    this.custom_field_item_type = 'study_groups'
    this.custom_fields_keys = ['forecast_filters_source_study_title']
    this.addCustomFields()

  }

  get requireds() {
    return [
      { item: 'title', label: 'title' },
      { item: 'study_type_id', label: 'study type' },
    ]
  }

  lastPublishedVersion(studies = this.studies) {
    const versions = studies.filter(study => study.published_at)
    versions.sort( (a, b ) => a.published_at - b.published_at )
    return versions[0]
  }
  
  lastReleasedVersion(studies = this.studies) {
    const versions = studies.filter(study => study.released_at)
    versions.sort( (a, b ) => a.released_at - b.released_at )
    return versions[0]
  }

}