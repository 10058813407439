<template>
  <PsAccordionItem
    title="Installation Time"
    :class="{ 'form-dirty' : hasValueUpdated }"
  >
    On average it will take
    <DropdownWithInputNumber
      :value="getValue"
      :min-value="0"
      :max-value="3"
      :step="0.25"
      :base-value="baseValue"
      :button-label="`${getValue} ${getValue === 1 ? 'year' : 'years'}`"
      :suffix="`${getValue === 1 ? 'year' : 'years'}`"
      @update:value="setValue"
    />
    to install the required measures for a unit. This is the time starting from when the requirement is triggered for an individual unit to the time when the required measures are operational for that unit.
  </PsAccordionItem>
</template>

<script>
import DropdownWithInputNumber from '@/components/general/DropdownWithInputNumber.vue'
import { defaultImpactAssumptionValues } from '@/models/ImpactAssumptions'

export default {
  name: 'AssumptionsCardInstallationTime',
  components: { DropdownWithInputNumber },
  data: () => ({
    baseValue: defaultImpactAssumptionValues.delay_installation
  }),
  computed: {
    getValue() {
      return this.$store.state.assumptions[this.$store.state.assumptions.drawerOptions.type]['delay_installation']
    },
    hasValueUpdated() {
      return this.getValue != this.baseValue
    }
  },
  methods: {
    setValue(value) {
      this.$store.dispatch('assumptions/updateAssumptionsKeyValue', { key: 'delay_installation', value })
    }
  }
}
</script>
