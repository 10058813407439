<template>
  <div
    class="w-full h-full overflow-hidden"
    :class="`route-${routeNameClass}`"
  >
    <BaseSidebar
      :sidebar-with="getSideBarWidth"
      :sidebar="getSidebar"
    />
    <div
      id="main-content"
      class="w-full h-full overflow-hidden relative z-10"
      :style="{ paddingLeft: getSideBarWidth }"
    >
      <router-view class="w-full h-full flex flex-col overflow-y-auto" />
    </div>
  </div>
</template>

<script>
import BaseSidebar from './BaseSidebar'
import SidebarDefault from '@/components/general/SidebarDefault.vue'

export default {
  name: 'BaseLayoutAdmin',
  components: { 
    BaseSidebar,
  },
  data() {
    return {
      windowWidth: 2000
    }
  },
  metaInfo: {
    titleTemplate: '%sCost Effectiveness Explorer'
  },
  computed: {
    routeNameClass() {
      return (this.$route.name ?? this.$route.path).toLocaleLowerCase()
    },

    getSidebar() {
      const parentRouteWithSidebar = this.$route.matched.find(m => m.meta.sidebar && m.meta.sidebar.content)
			if (this.$route.meta.sidebar) {
       return this.$route.meta.sidebar
      } else if (parentRouteWithSidebar) {        
        return parentRouteWithSidebar.meta.sidebar
      } else {
        return SidebarDefault
      }
    },
    getSideBarWidth() {
      if(this.windowWidth < 768) return '100%'
        const mapSizes = {
          small : 40,
          medium: 245,
          large: 610
        }
      return this.getSidebar?.size ? mapSizes[this.getSidebar.size] + 'px' : `245px`
    },
  },
}
</script>