<template>
  <div class="w-full p-6">
    <div class="flex items-center">
      <InputWithLabel
        v-model="search"
        :label="'Search users by email'"
        @keydown="getUsers({ search })"
      />
      <PsButton
        label="Export"
        size="big"
        class="ml-4"
        @click="exportToCsv()"
      />      
    </div>

    <div class="w-full py-2 mt-6 flex">
      <Dropdown          
        ref="dropdown"
        :button-classes="'px-2 bg-transparent w-full'"
        :dropdown-dialog-classes="'left-0 bg-white'"
      >
        <template v-slot:buttonLabel>            
          <span class="text-header-4 text-deepsky-500 border-b border-gray05 pb-1 flex items-center">
            Show/Hide Columns
            <feather
              class="ml-2"
              type="chevron-down"
              size="14"
            />
          </span>
        </template>
        <template v-slot:items>
          <div class="flex flex-col p-5 space-y-1">
            <Checkbox 
              v-for="column in columns" 
              :key="column.key"
              :label="column.label"
              :value="column.isActive"
              checkbox-classes=""
              @change="toggleSet(column.key, 'columns')"
            />
          </div>
        </template>
      </Dropdown>
      
      <Dropdown          
        ref="dropdown"
        :button-classes="'px-2 bg-transparent w-full'"
        :dropdown-dialog-classes="'left-0 bg-white'"
      >
        <template v-slot:buttonLabel>            
          <span class="text-header-4 text-deepsky-500 border-b border-gray05 pb-1 flex items-center">
            Filter by Jurisdictions ({{ jurisdictions.length }})
            <feather
              class="ml-2"
              type="chevron-down"
              size="14"
            />
          </span>
        </template>
        <template v-slot:items>
          <div class="flex flex-col p-5 space-y-1 max-h-1/4 overflow-y-auto">
            <Checkbox
              v-for="jur in jurisdictions" 
              :key="jur.key"
              :label="jur.label"
              :value="jur.isActive"
              checkbox-classes=""
              @change="toggleSet(jur.key, 'jurisdictions')"
            />
          </div>
        </template>
      </Dropdown>
      
      <Dropdown          
        ref="dropdown"
        :button-classes="'px-2 bg-transparent w-full'"
        :dropdown-dialog-classes="'left-0 bg-white'"
      >
        <template v-slot:buttonLabel>            
          <span class="text-header-4 text-deepsky-500 border-b border-gray05 pb-1 flex items-center">
            Filter by Users ({{ getUserList.length }})
            <feather
              class="ml-2"
              type="chevron-down"
              size="14"
            />
          </span>
        </template>
        <template v-slot:items>
          <div class="flex flex-col p-5 space-y-1 max-h-1/4 overflow-y-auto">
            <Checkbox
              v-for="filter in filterUsersBy" 
              :key="filter.key"
              :label="filter.label"
              :value="filter.isActive"
              checkbox-classes=""
              @change="toggleSet(filter.key, 'filterUsersBy')"
            />
          </div>
        </template>
      </Dropdown>

      <Dropdown          
        ref="dropdown"
        :button-classes="'px-2 bg-transparent w-full'"
        :dropdown-dialog-classes="'left-0 bg-white'"
        class="ml-auto"
      >
        <template v-slot:buttonLabel>            
          <span class="text-header-4 text-deepsky-900 border px-2 py-1 rounded flex items-center">
            Statistics
          </span>
        </template>
        <template v-slot:items>
          <ul class="flex flex-col p-5 space-y-1 max-h-1/4 overflow-y-auto">
            <li
              v-for="obj in statistics"
              :key="obj.key"
            >
              {{ obj.title }} : <strong>{{ obj.value }}</strong>
            </li>
          </ul>
        </template>
      </Dropdown>
    </div>
    <table class="table table-bordered mt-6 whitespace-no-wrap">
      <thead>
        <tr>
          <template v-for="column in columns">
            <td
              v-if="column.isActive"
              :key="column.key"
            >
              {{ column.label }}
            </td>
          </template>
          <td>Actions</td>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="user in getUserList"
          :key="user.email"
        >
          <template v-for="column in columns">
            <td
              v-if="column.isActive"
              :key="column.key"
            >
              <BooleanLabel
                v-if="column.type === 'boolean'"
                :value="user[column.key]"
              />
              <template v-else-if="column.key === 'policies'">
                <router-link
                  v-if="user.policies.length > 0"
                  :to="{ name: 'AdminPolicyList', query: { user_id: user.id }}"
                  class="button-sm button-blue04 mr-2"
                >
                  {{ user.policies.length }}
                </router-link>
              </template>
              <template v-else-if="column.key === 'jurisdictions'">
                <span
                  v-if="user.policies.length > 0"
                  v-tooltip="getUserJurisdictions(user).join('<br />')"
                >
                  {{ getUserJurisdictions(user)[0] }} {{ getUserJurisdictions(user)[1] ? '(...)' : '' }}
                </span>
              </template>
              <template v-else>
                {{ user[column.key] }}
              </template>
            </td>
          </template>
          <td class="psui-flex psui-space-x-2">
            <PsButton
              label="Login as"
              size="medium"
              icon="login"
              @click="loginAs(user)"
            />

            <router-link
              :to="{ name: 'AdminUserEdit', params: { user_id: user.id }}"
            >
              <PsButton 
                label="Edit" 
                size="medium"
                icon="edit"
              />
            </router-link>
            <PsButton 
              layout="caution" 
              label="Delete"
              size="medium"
              icon="delete"
              @click="selectUserToDelete(user)"
            />
          </td>
        </tr>
      </tbody>
    </table>

    <BaseModal
      ref="deleteUserModal"
      modal-dialog-classes="w-full max-w-sm"
      title="Attention"
    >
      <div
        v-if="user_selected"
        class="w-full flex flex-col items-start mt-6"
      >
        <p class="text-base text-gray04">
          Delete User <span class="text-red font-bold">{{ user_selected.email }}</span>?
        </p>
        <PsButton
          layout="caution"
          class="psui-mt-6"
          label="Confirm delete"
          size="big"
          @click="deleteUser(user_selected.id)" 
        />
      </div>
    </BaseModal>

    <!-- <Paginator v-model="offset" :length="users.length" :limit="limit" @getItems="getUsers({ offset })"></Paginator> -->
  </div>
</template>

<script>
import UserApiService from '@/services/api/UserApiService'
import { objArrayToCsv, downloadRawData } from '@igortrindade/lazyfy'
import dayjs from 'dayjs'

export default {
  name: 'AdminUsersList',
  data() {
    return {
      users: [],
      search: '',
      offset: 0,
      limit: 12,
      columns: [
        { key: 'id', label: 'ID', isActive: false },
        { key: 'email', label: 'Email', isActive: true },
        { key: 'policies', label: 'Policies Created', isActive: true },
        { key: 'jurisdictions', label: 'Jurisdiction(s)', isActive: true },
        { key: 'is_team', label: 'Is Team', isActive: false, type: 'boolean' },
        { key: 'is_admin', label: 'Is Admin', isActive: false, type: 'boolean' },
        { key: 'accept_periodic_newsletter', label: 'Accept periodic', isActive: false, type: 'boolean' },
        { key: 'accept_features_newsletter', label: 'Features newsletter', isActive: false, type: 'boolean' },
        { key: 'has_spreadsheet_access', label: 'Has access to spreadsheets', isActive: false, type: 'boolean' },
        { key: 'created_at', label: 'Created at', isActive: true },
      ],
      jurisdictions: [],
      filterUsersBy : [
        { key: 'hide_team', label: 'Hide Team members', isActive : true, filter: 'hideUserIfIsTeam' },
        { key: 'show_only_team', label: 'Show only Team members', isActive : false, filter: 'hideUserIfIsNotTeam' },
        
        { key: 'hide_admins', label: 'Hide Admins', isActive : true, filter: 'hideUserIfIsAdmin' },
        { key: 'show_only_admins', label: 'Show Only Admins', isActive : false, filter: 'hideUserIfIsNotAdmin' },
        
        { key: 'hide_users_with_no_policy', label: 'Hide users with zero policies', isActive : false, filter: 'hideUserIfHasNoPolicies' },
        { key: 'hide_users_with_policies', label: 'Hide users with policies', isActive : false, filter: 'hideUserIfHasPolicies' },        
      ],
      statistics: false,
      user_selected: null,
    }
  },
  computed: {
    jurisdictionsActivated() {
      return this.jurisdictions.filter((jur) => jur.isActive).map(jur => jur.label)
    },
    getUserList() {
      let users = this.users
      if (this.jurisdictionsActivated.length > 0) users = users.filter(this.filterUserByJurisdictionsActivated)
      for (const filter of this.filterUsersBy) {
        if (filter.isActive) users = users.filter(this[filter.filter])
      }
      return users
    }
  },
  mounted() {
    this.getUsers()
  },
  methods: {
    toggleSet(key, setKey) {
      const dataset = [...this[setKey] ]      
      const column = dataset.find(col => col.key === key )
      column.isActive = !column.isActive
      this[setKey] = [...dataset ]
    },
    getUserJurisdictions(user) {
      return [...new Set([...user.policies.reduce((jurisdictions, policy) => {
        jurisdictions.push(policy.jurisdiction.title_type)
        return jurisdictions
      }, [])])]
    },
    getUsersJurisdictions() {
      const jurisdictions = [...new Set([...this.users.reduce((jurisdictions, user) => {
        user.policies.forEach(policy => {
          jurisdictions.push(policy.jurisdiction.title_type)
        })
        return jurisdictions
      }, [])])]
      return jurisdictions.map(jur => {
        return {
          key: jur,
          label: jur,
          isActive: false
        }
      })
    },
    async getUsers({ offset = 0, search } = {}) {
      this.offset = offset
      this.users = await UserApiService.all({ search })
      this.jurisdictions = this.getUsersJurisdictions()
      this.generateStatistics()
    },

    filterUserByJurisdictionsActivated(user) {
      const userJurisdictions = this.getUserJurisdictions(user)          
      for (const jurisdiction of this.jurisdictionsActivated) {
        if (userJurisdictions.includes(jurisdiction)) {
          return true
        }
      }
      return false
    },
    hideUserIfIsTeam(user) {      
      return user.is_team ? false : true
    },
    hideUserIfIsNotTeam(user) {      
      return user.is_team ? true : false
    },
    hideUserIfIsAdmin(user) {      
      return user.is_admin ? false : true
    },
    hideUserIfIsNotAdmin(user) {      
      return user.is_admin ? true : false
    },
    hideUserIfHasNoPolicies(user) {      
      return user.policies?.length === 0 ? false : true
    },
    hideUserIfHasPolicies(user) {      
      return user.policies?.length === 0 ? true : false
    },

    selectUserToDelete(user) {
      this.user_selected = user
      this.$refs.deleteUserModal.showModal = true      
    },

    deleteUser(user_selected_id) {
      this.$api.delete(`/api/admin/users/delete/${user_selected_id}`)
        .then(() => {
          this.$toast.success(`User successfully deleted`)
          this.$refs.deleteUserModal.showModal = false
          this.user_selected = null
          this.getUsers()
        })
    },

    exportToCsv() {
      this.$api.get(`/api/admin/users/export_user_list`, { loader: true })
        .then(({ data }) => {
          const fileName = 'explorer_users_' + dayjs().format('YYYY-MM-DD HH:mm:ss') + '.csv'
          downloadRawData(objArrayToCsv(data.users, ','), fileName)
        })
    },

    loginAs(user) {
      this.$api.get(`/api/admin/users/login_as/${user.id}`)
        .then(({ data }) => {
          this.$store.commit('setLoggedUserToken', data)
          this.$store.dispatch('getLoggedUser')
          this.$router.push('/')
          this.$appToast({ message: `Logged as user ${user.email}`, type: 'warning' })
        })
    },

    generateStatistics() {
      const users = this.users
      const customers = users.filter(this.hideUserIfIsTeam).filter(this.hideUserIfIsAdmin)          
      const customersWithPolicy = customers.filter(this.hideUserIfHasNoPolicies)
      const customersWithoutPolicy = customers.filter(this.hideUserIfHasPolicies)
      this.statistics = [
        { title: 'Users Registered' , value: customers.length },
        { title: 'Users with Policy' , value: customersWithPolicy.length },
        { title: 'Users without Policy' , value: customersWithoutPolicy.length },
      ]
    }
  }
}
</script>

<style>

</style>
