import AdminPolicyList from '@/modules/admin/policy/AdminPolicyList'
import baseAdminMeta from '@/modules/admin/baseAdminMeta'

export default [
  {
    name: 'AdminPolicyList',
    path: 'policy',
    component: AdminPolicyList,
    meta: { ...baseAdminMeta, title: 'Policy List' }
  }
]