import Jurisdiction from '@/models/Jurisdiction'
import { defaultImpactAssumptionValues } from '@/models/ImpactAssumptions'
export default class Policy {

  constructor( obj = {}) {
    this.id = obj.id || null
    this.title = obj.title || null 
    this.user_id = obj.user_id || null
    this.jurisdiction_id = obj.jurisdiction_id || null    
    this.created_at = obj.created_at || null
    this.updated_at = obj.updated_at || null
    this.deleted_at = obj.deleted_at || null
    
    // Assumptions
    for ( let key in defaultImpactAssumptionValues) {
      this[key] = typeof obj[key] != 'undefined' ? obj[key] :  defaultImpactAssumptionValues[key]
    }

    // Relationships
    
    this.custom_combinations = obj.custom_combinations || null
    this.jurisdiction = obj.jurisdiction ? new Jurisdiction(obj.jurisdiction) : new Jurisdiction()

    // Helpers
    this.jurisdiction_title = obj.jurisdiction?.title
    this.policy_active_tab = obj.policy_active_tab || null
    this.typePrototypes = obj.typePrototypes || []
    this.policyMap = obj.policyMap || []
    this.loading = typeof obj.loading != 'undefined' ? obj.loading : false
    this.silent_run_steps = typeof obj.silent_run_steps != 'undefined' ? obj.silent_run_steps : []
  }

}