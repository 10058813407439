<template>
  <div class="w-full flex justify-between p-4">
    <h3 class="text-gray text-lg font-semi-bold">
      {{ $route.meta.title }}
    </h3>
    <button 
      class="text-sec font-bold uppercase"
      @click="$store.dispatch('logoutUser')"
    >
      Logout
    </button>
  </div>
</template>

<script>
export default {
  name: 'BaseAdminHeader',
  metaInfo() {
    return {
      title: `Admin Dashboard | `
    }
  },
}
</script>