<template>
  <ul
    class="app-tab-header flex text-header-2 text-ash-400"
    :class="wrapperClass"
  >
    <li 
      v-for="item in getItems" 
      :key="item[keyValue]"
      class="cursor-pointer transition-all p-2 relative"
      :class="{ 'is-selected': getSelected === item[keyValue] }"
      @click="selectTab(item)"
    >
      {{ item[keyLabel] }}

      <span
        v-if="item.notifications"
        :class="notificationClass"
      >
        {{ item.notifications }}
      </span>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'AppTabHeader',
  props: {
    items: {
      type: Array,
      required: true
    },
    selected: {

    },
    keyLabel: {
      type: String,
      default: 'label'
    },
    keyValue: {
      type: String,
      default: 'value'
    },
    notificationClass: {
      type: String,
      default: 'absolute top-0 right-0 rounded-full p-1 bg-red flex items-center justify-center w-6 h-6 text-white -mr-3 -mt-3'
    },
    wrapperClass: {
      type: String,
      default: 'space-x-3'
    }
  },
  computed: {

    getIsObject() {
      return typeof this.selected === 'object'
    },

    getSelected() {
      if (this.selected) {
        if (typeof this.selected === 'object' && this.selected[this.keyValue] ) {
          return this.selected[this.keyValue]        
        } else {
          return this.selected
        }
      } else {
        return false
      }
    },
    getItems() {
      if (this.items.length > 0 && typeof this.items[0] !== 'object') {
        return this.items.map((item) => { 
          return {
            [this.keyLabel]: item,
            [this.keyValue]: item,
            notifications: 0,
          }
        })
      } else {
        return this.items
      }
    }
  },
  methods: {
    selectTab(item) {
      this.$emit('update:selected', this.getIsObject ? item : item[this.keyValue] )
      this.$emit('change', this.getIsObject ? item : item[this.keyValue])
    }
  }
}
</script>

<style lang="scss" scoped>
  .app-tab-header {
    li {
      &.is-selected, &.hover, &:hover {
        background: rgba(75, 140, 166, 0.2);
        border-radius: 6px;
        color: #4B8CA6;
      }
    }
  }
</style>