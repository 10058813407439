<template>
  <div
    v-if="args !== null "
    class="icon"
    @click="getHelper()"
  >
    <img
      class="info"
      src="/icon-info.svg"
    >
  </div>
</template>

<script>
import store from '@/store'
export default {
  props: {
    type: {
      type: String,
      default: 'helper',
      validator: (val) => ['helper', 'drawer', 'cz'].indexOf(val) !== -1
    },
    args: {
      type: [Object, String, Boolean],
    }
  },
  methods: {
    getHelper() {      
      const payload = typeof this.args === 'string' ? this.args : {...this.args }
      if (this.type === 'helper') {
        store.dispatch('app/helperGet', payload)
      } else {
        store.dispatch('app/showContentOnDrawer', payload)
      }
    },
    
  }
}
</script>

<style lang="scss">

.--helper, .--helper-inline {
  position: relative !important;
  .icon {
    cursor: pointer;
    opacity: 0;
    transition: 300ms ease-in-out all;
    transform: translateX(10px);
  }
  &:hover, &.v-tour__target--highlighted {
    .icon {
      opacity: 1;
      transform: translateX(0px);
    }
  }
}

.--helper {
  .icon {
    position: absolute;
    top: 50%;
    left: -18px;      
    padding-right: 5px;
    margin-top: -7px;
    height: 14px;
    display: flex;
  }
}

.--helper-inline {
  .icon {
    display: inline;
    padding-left: 5px;
    margin-top: -10px;
  }  
}




</style>