import formatNumber from './formatNumber'
export default (value, data) => {
  let formatted
  formatted = formatNumber(parseFloat(value))
  const emissions_savings_pct = parseFloat(data['emissions_savings_pct'])
  if (!isNaN(emissions_savings_pct)) {
    const percentage = formatNumber(emissions_savings_pct * 100)
    formatted = `${formatted} (${percentage}%)`
  }
  return formatted
}