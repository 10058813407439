<template>
  <ul class="app-navigation--main mb-auto mt-10 text-deepsky-300">
    <li class="text-header-3">
      <router-link :to="{ name: 'Summary', params: { jurisdiction_slug } } ">
        Summary
      </router-link>
    </li>
      
    <li>
      <router-link 
        :to="{ name: 'BuildingEstimates', params: { jurisdiction_slug } }"
        :class="$route.name === 'BuildingEstimates' ? 'router-link-active' : ''"
      >
        Building Estimates
      </router-link>
    </li>

    <li>
      <router-link 
        :to="getStudyResultsRoute({ jurisdiction_slug })" 
        class="forecast-results"
        :class="$route.name === 'StudyResults' ? 'router-link-active' : ''"
      >
        Results
      </router-link>
    </li>

    <li class="text-header-3">
      <router-link
        :to="{ path: '/policies'}"
        class="flex items-center justify-start"
        active-class="router-link-exact-active"
      >
        <span>Policies</span>
        <ToolTip v-if="!sidebarPolicyTooltipShouldHide">
          <template v-slot:trigger>
            <div class="ml-1 bg-lime-500 w-6 h-6 rounded-sm flex items-center justify-center">
              <feather
                type="gift"
                size="15"
              />
            </div>
          </template>
          <template v-slot:dialog>
            <p class="text-ash-700 hover:text-ash-500">
              Design your own policy <span class="text-white bg-lime-500 py-1 px-2 rounded-sm ml-2">New</span>
            </p>
          </template>
        </ToolTip>
      </router-link>
    </li>

    <div
      v-check-env="['development']"
      class="w-full flex flex-col mt-6 px-5"
    >
      <p class="text-xs text-red">
        Available only in dev
      </p>
      <router-link to="/admin/login">
        Login admin
      </router-link>
    </div>
  </ul>
</template>

<script>
import { utilityRatesOptions, resultsSourcesOptions } from '@/util/Drawers'
import { mapGetters } from 'vuex'
export default {
  computed: {
    
    jurisdiction_slug() {
      if (this.$route.params.jurisdiction_slug) {
        return this.$route.params.jurisdiction_slug
      } else if (this.lastJurisdictionVisited && this.lastJurisdictionVisited?.slug) {
        return this.lastJurisdictionVisited.slug
      } else {
        return null
      }
    },

    sidebarPolicyTooltipShouldHide() {
      return this.$store.state.general.sidebarPolicyTooltipShouldHide
    },
    getUser() {
      return this.$store.state.auth.loggedUser
    },
    ...mapGetters(['lastJurisdictionVisited']),
  },
  methods: {
    onClickUtilityRates() {
      this.$eventBus.$emit('openDrawerContent', utilityRatesOptions(this.$route) )
    },
    onClickResultsSources() {
      this.$eventBus.$emit('openDrawerContent', resultsSourcesOptions(this.$route))
    },
  }
}
</script>

<style lang="scss" scoped>

  ul {

    span {
      transition: 300ms ease-in-out all;
      display: inline-block;
    }
    > li {
      min-height: 33px;
      white-space: nowrap;      

      > a {
        border-left: 6px solid transparent;        
        padding-left: 16px;
      }
      
      a.router-link-active {
        font-weight: bold;
      }

      > a:hover,
      a:hover,
      span:hover,
      a.router-link-exact-active,
      a.router-link-active
      {        
        border-color: app-color(blue02);
        color: white;     
        cursor: pointer;
      }
      
      ul {
        padding-left: 30px;
        padding-top: 12px;
        li {
          min-height: 21px;
          padding-bottom: 6px;
        }
      }
    }
  }
</style>