import GraphQL from '@/util/GraphQL'

export default {
  namespaced: true,
  
  state: {
    climate_zones: []
  },

  getters: {
    getterGlobalClimateZones(state) {
      return state.climate_zones
    },

    getterClimateZoneSelected: state => routeFullPath => {
      const url = new URL(window.location.host + routeFullPath)
      const climate_zone_selected = url.searchParams.get('only_climate_zone')
      if(!climate_zone_selected) return state.climate_zones[0]
      return state.climate_zones.findInArray({ slug: url.searchParams.get('only_climate_zone')})
    }

  },

  actions: {
    loadGlobalClimateZones(context) {
      const query = `
        {
          climate_zones (
            orderBy: [{ column: "prefix", order: "ASC" }]
          ) {
            id
            prefix
            suffix
            raw
          }
        }
      `
      GraphQL({ query })
        .then(({ data }) => {
          context.commit('setGlobalClimateZones', { climate_zones: data.climate_zones})
        })
    }
  },

  mutations: {
    setGlobalClimateZones(state, { climate_zones }) {
      state.climate_zones = climate_zones
    }
  }
}