import EventBus from '@/util/EventBus'

export default (Store) => {
  Store.subscribe((mutation) => {

    if(mutation.type === 'setLoggedUser' ) {
      // Store.dispatch('assumptions/buildingStocks/getCustomBuildingStocks')
      Store.dispatch('policy/getUserPolicies')
      Store.dispatch('general/checkDeviceInfo')
      EventBus.$emit('checkBuildingStockAndEstimateConflict')
    }
    
    if(mutation.type === 'setLogout' ) {
      Store.dispatch('assumptions/resetCustomBuildings')
      Store.commit('policy/clearPoliciesOpened')
    }
   
  })
}