<template>
  <div class="app-inputwithlabel block w-full">
    <div class="relative">
      <input
        v-if="!mask"
        :id="id"
        v-tooltip="tooltip"
        :type="getType"
        :autocapitalize="autocapitalize"
        :class="[inputClass, {'input-has-error' : validation.hasError, 'filled' : placeholder || (value !== '') || (type === 'number' && value === 0) }]"
        :aria-required="required"
        :aria-invalid="validation.hasError"
        :value="value"
        :disabled="disabled"
        :placeholder="placeholder"
        class="input-with-label block appearance-none w-full h-full text-header-3 px-3 pb-2 pt-6 font-normal"
        autocomplete="chrome-off"
        autocorrect="off"
        spellcheck="false"
        @focus="$emit('focus')"
        @blur="$emit('blur')"
        @input="$emit('input', $event.target.value)"
        @keydown.enter="$emit('keydown', $event.target.value)"
        @change="$emit('change')"
      >
      <input
        v-else
        :id="id"
        v-mask="mask"
        v-tooltip="tooltip"
        :type="getType"
        :autocapitalize="autocapitalize"
        :class="{'input-has-error' : validation.hasError, 'filled' : (value !== '') || placeholder || value }"
        :aria-required="required"
        :aria-invalid="validation.hasError"
        :value="value"
        :disabled="disabled"
        :placeholder="placeholder"
        class="input-with-label block appearance-none w-full h-full text-header-3 px-3 pb-2 pt-6 font-normal"
        autocomplete="chrome-off"
        autocorrect="off"
        spellcheck="false"
        @blur="$emit('blur')"
        @input="$emit('input', $event.target.value)"
        @keydown.enter="$emit('keydown', $event.target.value)"
        @change="$emit('change')"
      >
      <label
        v-if="label"
        :for="id"
        class="absolute pin-l flex items-center text-gray04 text-xs pointer-events-none pl-3"
      >{{ label }}</label>
      <button 
        v-if="type === 'password'" 
        :class="[showPassword ? 'text-blue02' : '', validation.hasError ? 'text-red' : '']"
        class="h-full absolute pin-y top-0 right-0 mr-3 flex items-center pl-2 focus:outline-none"
        @click="showPassword = !showPassword"
      >
        <feather
          :type="showPassword ? 'eye-off' : 'eye'"
          size="18"
        />
      </button>
    </div>
    <p
      v-if="validation.hasError && validation.label"
      class="text-red w-full pl-3 pt-2 text-p-1"
    >
      {{ validation.label }}
    </p>
  </div>
</template>

<script>
export default {
  name: "InputWithLabel",
  props: {
    type: {
      type: String,
      default: 'text'
    },
    id: {
      type: String,
      required: false,
      default: null
    },
    placeholder: {
      type: String,
      default: ''
    },
    value: {
      required: true,
    },
    label: {
      type: String,
      required: true
    },
    action: {
      type: Function,
      required: false,
      default: null
    },
    autocapitalize: {
      type: String,
      default: 'sentences'
    },
    autocomplete: {
      type: Boolean,
      default: true
    },
    required: {
      default: false
    },
    validation: {
      type: Object,
      default: () => {
        return { hasError: false, checked: false, filled: false, required: false }
      }
    },
    mask: {
      type: [Boolean, String, Array],
      default: false
    },
    inputClass: {
      type: String,
      default: 'border-b-2 border-gray06 bg-gray06'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: [String, Object, Boolean],
      default: false
    }
  },
  data() {
    return {
      showPassword: false
    }
  },
  computed: {
    getType() {
      if(this.type !== 'password') return this.type
      if(this.type === 'password' && this.showPassword) return 'text'
      return this.type
    }
  },
  methods: {
    makeAction() {
      if(this.action && typeof(this.action) == 'function') this.action()
    },
    focusOnInput(timeout = 400) {
      setTimeout(() => {
        this.$el.getElementsByTagName('input')[0].focus()
      }, timeout)
    },
  }
}
</script>

<style scope>

  .input-with-label + label {
    transition: all 0.25s ease-in;
    bottom: calc(50% - 10px);
  }
  .input-with-label:focus + label {
    color: #4B8CA6 !important;
  }
  .input-with-label:focus + label, .input-with-label.filled + label 
  {
    bottom: 55%;
    font-size: 12px;
    line-height: 16px;
  }

  .input-with-label:focus:not(.input-has-error) {
    border-color: #4B8CA6 !important;
  }

  .input-with-label.input-has-error + label {
    color: #ff0f4a !important;
  }

  .input-has-error {
    border-color: #ff0f4a !important;
  }

  input::-webkit-contacts-auto-fill-button, 
  input::-webkit-credentials-auto-fill-button {
    visibility: hidden;
    position: absolute;
    right: 0;
  }

</style>
