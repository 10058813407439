<template>
  <div>
    <AppTabHeader
      :items="tabs"
      :selected.sync="tabSelected"
      class="mb-8"
    />

    <template v-if="tabSelected === 'Study Info'">
      <div
        v-show="!study.study_group_id"
        class="w-full relative mt-6"
      >
        <VueSelect 
          v-model="study.study_group_id"
          class="vue-select"
          placeholder="Select a filter to group searchs"
          :class="study.study_group_id ? 'filled' : ''"
          label="title"
          :options="study_groups"
          :reduce="item => item.id"
        />
        <label>Study group</label>
      </div>

      <InputWithLabel
        v-model="study.title"
        class="mt-6"
        :label="'Title'"
      />
      
      <InputWithLabel
        v-model="study.title_by_prototypes"
        class="mt-6"
        :label="'Title Prototypes'"
      />

      <InputWithLabel
        v-model="study.source_pdf"
        class="mt-6"
        :label="'Source pdf url'"
      />         

      <InputWithLabel
        v-model="study.summary_message_tag"
        class="mt-6"
        :label="'Summary Message Tag'"
      />         

      <div class="flex space-x-6 pt-6">
        <Checkbox
          v-model="study.is_private"
          :label="'Is private ?'"
        />
        
        <Checkbox
          v-model="study.summary_recommended"
          :label="'Is recommended at Summary?'"
        />

        <Checkbox
          v-model="study.is_upcoming"
          :label="'Is upcoming?'"
        />
      </div>

      <ContentEditor
        class="mt-6"
        :content.sync="study.content"
      />
    </template>    
    <template v-else-if="tabSelected === 'Version Management'">
      <InputWithLabel
        v-model="study.released_at"
        class="mt-6"
        :label="'Released at'"
        :mask="'##/##/####'"
        placeholder="MM/DD/YYYY"
        :required="true"
      />   

      <InputWithLabel
        v-model="study.expected_at"
        class="mt-6"
        :label="'Expected to be published on Explorer at'"
        :mask="'##/##/####'"
        placeholder="MM/DD/YYYY"
      />

      <InputWithLabel
        v-model="study.published_at"
        class="mt-6"
        :label="'Published on Explorer at'"
        :mask="'##/##/####'"
        placeholder="MM/DD/YYYY"
      />      

      <div class="pt-6">
        <label class="pl-3 font-bold">What has been updated in this version?</label>
        <ContentEditor
          class="mt-2"
          :content.sync="study.version_updated_changes"
        />
      </div>
    </template>
    <template v-else-if="tabSelected === 'Data Syncronization'">
      <InputWithLabel
        v-model="study.spreadsheet_id"
        class="mt-6"
        :label="'Spreadsheet id'"
      />
      <a 
        v-if="study.spreadsheet_id" 
        class="ml-2 mt-2 text-header-1 hover:text-ash-600 text-deepsky-600"
        target="_blank"
        :href="`https://docs.google.com/spreadsheets/d/${study.spreadsheet_id}`"
      >Click to edit at Google Sheets</a>

      <InputWithLabel
        v-model="study.worksheet_name"
        class="mt-6"
        :label="'Worksheet name'"
      />
    </template>
    
    <template v-else-if="tabSelected === 'Forecast Variables'">
      <InputWithLabel
        v-model="study.impact_period"
        class="mt-6"
        :label="'Impact period'"
      />
    </template>      
    <template v-else-if="tabSelected === 'Custom Links'">
      <AdminStudyBaseRepeatable 
        v-model="study.custom_links"
        title="Summary Custom Links"
        :base-item-value="{ title: '', href: '', target: '' }"
      >
        <template v-slot="{ item }">
          <PsInput
            label="Title"
            :value="item.title"
            class="w-full psui-flex-grow py-2 pl-2"
            @input="item.title = $event.target.value"
          />
          <PsInput
            label="Url"
            :value="item.href"
            class="w-full psui-flex-grow py-2 pl-2"
            @input="item.href = $event.target.value"
          />
          <div class="flex-shrink-0 mx-4">
            <NativeSelectInput
              :items="navigation"
              :value="item.target"
              label="Should open as"
              key-label="type"
              key-value="value"
              class="mb-2 mt-4"
              @input="item.target = $event"
            />
          </div>
        </template>
      </AdminStudyBaseRepeatable>
    </template>         

    <hr class="border-b border-gray05 my-6">

    <button
      v-if="!this.$route.params.study_id"
      class="mt-6 button button-blue04"
      @click="store()"
    >
      Create study
    </button>    
    <button
      v-else
      class="mt-6 button button-blue04"
      @click="update()"
    >
      Update study
    </button>
  </div>
</template>

<script>

import AppTabHeader from '@/components/general/AppTabHeader.vue'
import AdminStudyBaseRepeatable from '../study-group/AdminStudyBaseRepeatable.vue'
import Study from '@/models/Study'

export default {
  name: 'AdminStudyEdit',
  components: { AppTabHeader, AdminStudyBaseRepeatable },
  data() {
    return {
      typeStudyList: [
        { type_id: 1, type_content: 'New Buildings' },
        { type_id: 2, type_content: 'Existing Buildings' }
      ],
      study: new Study(),
      studyDatumFile: new File(["foo"], "", { type: "text/plain" }),
      study_groups: [],
      studyGroup: null,
      tabSelected: 'Study Info',
      tabs: ['Study Info', 'Version Management', 'Data Syncronization',  'Forecast Variables', 'Custom Links'],
      navigation: [{type: 'Same window', value:'_self'}, {type:'New window', value:'_blank'}]
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.params.study_group_id) {
          this.study.study_group_id = route.params.study_group_id
        }
      }
    }
  },
  mounted() {
    if(this.$route.params.study_id) {
      this.getStudy()
    } else if (this.$route.params.study_group_id) {
      this.getStudyGroup()
    } else {
      this.$toast.$error('There is no study_group_id at the $route, please call an administrator.')
    }
  },
  methods: {
    async getStudy() {
      const query = `
        {
          study (
            where: [
              { column: "id", operation: "=", value: "${this.$route.params.study_id}"}
            ]
          ){
            id
            title
            content
            type_id
            type_content
            study_group_id
            source_pdf
            released_at
            expected_at
            published_at
            version_updated_changes
            spreadsheet_id
            worksheet_name
            impact_period
            created_at
            is_private
            is_upcoming
            custom_fields {
              id
              item_id
              item_type
              data
            }
            study_group {
              id
              title
              study_type {
                id
                title
              }
            }
          }
        }
      `
      const { data } = await this.$graphql({ query })

      this.study = new Study(data.study)
      
    },
    
    async getStudyGroup() {
      const query = `
        {
          study_group (
            where: [
              { column: "id", operation: "=", value: "${this.$route.params.study_group_id}"}
            ]
          ){
            id
            title
            study_type {
              id
              title
            }
          }
        }
      `
      const { data } = await this.$graphql({ query })
      this.studyGroup = data.study_group
      
    },
    store() {      

      const payload = {...this.study}
      payload.type_id = this.studyGroup.study_type.id
      payload.type_content = this.studyGroup.study_type.title

      this.$api.post(`/studies`, payload, { loader: true })
        .then(({ data }) => {
          this.$toast.success('Study successfully created!')
          this.$router.push({ name: 'AdminStudyGroupStudyEdit', params: { study_group_id: this.studyGroup.id, study_id: data.id }})
        })

      
    },
    update() {  
      this.$api.put(`/studies/${this.study.id}`, this.study, { loader: true })
        .then(() => {
          this.$toast.success('Study successfully updated!')
        })
    },
  }
}
</script>
