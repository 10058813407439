<template>
  <div class="w-full">
    <label
      class="w-full flex items-center justify-start py-1"
      :for="id"
    >
      <input
        :id="id"
        v-model="childValue"
        type="radio"
        @change="$emit('change')"
      >
      <span class="ml-3 flex flex-col cursor-pointer">
        <span>{{ label }}</span>
      </span>
    </label>
  </div>
</template>

<script>
import { randomString } from '@/util/Functions'
export default {
  name: 'RadioButton',
  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      required: true
    }
  },
  data() {
    return {
      id: ''
    }
  },
  computed: {
    childValue: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue )
      }
    }
  },
  mounted() {
    this.id = randomString(8)
  }
}
</script>