<template>
  <transition
    enter-active-class="transition ease-in-out duration-300 transform"
    enter-class="opacity-0 translate-y-48"
    enter-to-class="opacity-100"
    leave-active-class="transition ease-in duration-300 transform"
    leave-class="opacity-100"
    leave-to-class="opacity-0 translate-y-48"
  >
    <div
      v-if="show()"
      class="tutorial-dialog origin-bottom fixed bottom-0 mt-5 mr-24 z-50 p-1 w-25vw pb-6 shadow"
      :style="getStyle"
    >
      <div class="rounded  text-white shadow">
        <div class="w-full bg-deepsky-500 rounded-t-sm pl-6 px-4 py-4 relative flex items-center text-white">
          <div
            class="h-6 text-header-4 font-bold	w-full text-left whitespace-no-wrap ellipsis pr-10 my-0.5 cursor-pointer"
            @click="$store.commit('tutorial/setContentShow', type)"
          >
            {{ title }}
          </div>

          <div class="h-full flex items-center justify-left space-x-1">
            <button
              class="flex items-center justify-center rounded-full focus:outline-none w-6	h-6"
              @click="$store.commit('tutorial/setContentShow', type)"
            >
              <feather
                :type="showContent() ? 'chevrons-down' : 'chevrons-up'"
                size="24"
              />
            </button>
            <button
              class="flex items-center justify-center rounded-full focus:outline-none w-6 h-6"
              @click="$store.commit('tutorial/setTutorialShow', { type })"
            >
              <feather
                type="x"
                size="24"
              />
            </button>
          </div>
        </div>
        <div
          v-show-slide="showContent()"
          class="w-full rounded-b-sm bg-white flex flex-col"
        >
          <slot />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'BaseTutorial',
  props: ['title', 'type'],
  computed: {
    getStyle() {
      if(!this.$store.state.assumptions.drawerOptions.visibility) return { right: '0px' }
      return { right: '340px !important', marginRight: '16px' }
    },
  },
  methods: {
    show() {
      return this.$store.state.tutorial[this.type].show
    },

    showContent() {
      return this.$store.state.tutorial[this.type].contentShow
    }
  }

}
</script>

<style lang="scss">
  .tutorial-dialog {
    width: 350px;
    max-height: calc(100vh - 30px);
    overflow-y: auto;
    > .rounded {
      box-shadow: 0px 30px 90px rgba(0, 0, 0, 0.15);
    }
  }
</style>