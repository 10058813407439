export default class Jurisdiction {
  constructor(obj = {}) {
    this.key = obj.key || 0
    this.id = obj.id || null
    this.title = obj.title || null
    this.type = obj.type || null
    this.title_type = obj.title_type || null
    this.county = obj.county || null
    this.slug = obj.slug || null
    this.created_at = obj.created_at || null
    this.updated_at = obj.updated_at || null
    this.climate_zones = obj.climate_zones || []
  }

  get label () {
    const { type, title } = this
    if (title) {
      if (type === 'City') {
        return `City of ${title}`
      } else {
        return `${title} County`
      }
    } 
    return title
  }
}