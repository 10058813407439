<template>
  <div class="w-full">
    <vue-skeleton-loader
      class="my-3"
      :width="width"
      :height="133"
    />
    <vue-skeleton-loader
      class="my-3"
      :width="width"
      :height="133"
    />
    <vue-skeleton-loader
      class="my-3"
      :width="width"
      :height="133"
    />
  </div>
</template>

<script>
export default {
  name: 'SkeletonComparisonTable',
	data() {
		return {
			width: window.innerWidth - 300
		}
	}	
}
</script>