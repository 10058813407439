<template>
  <div class="flex items-center ml-3">
    <Dropdown
      ref="dropdown"
      :button-classes="!isFiltering ? 'bg-transparent rounded-full border border-gray05 text-gray04' : 'bg-blue05-50 text-blue02 border border-blue03 rounded-full'"
      :min-width-dropdown="400"
      :dropdown-dialog-classes="'min-w-300 bg-white p-6'"
      title="Fuel Type"
    >
      <template v-slot:buttonLabel>
        <div class="w-full flex justify-between items-center whitespace-no-wrap px-2">
          <span>Fuel</span>
        </div>
      </template>
      <template v-slot:items>
        <div class="w-full flex flex-col font-medium text-gray02">
          <Checkbox
            v-for="type_fuel in type_fuels"
            :key="`filter-fuel-${type_fuel.id}`"
            class="w-full"              
            label-classes="ts--accent--2 text-gray04"
            :label="type_fuel.title"
            :value="!checkQueryKeyValue('exclude_type_fuel_ids', type_fuel.id)"
            @change="toggleQueryKeyValue('exclude_type_fuel_ids', type_fuel.id)"
          />
        </div>
      </template>
    </Dropdown>
  </div>
</template>

<script>
export default {
  name: 'FilterTypeFuel',
  data() {
    return {
      type_fuels: []
    }
  },
  computed: {
    isFiltering() {
      return this.$route.fullPath.includes('exclude_type_fuel_ids')
    }
  },
  mounted() {
    this.getVintages()
  },
  methods: {
    getVintages() {
      const query = `
        {
          type_fuels (
            orderBy: [{ column: "title", order: "asc" }]
          ){
            id
            title
          }
        }
      `
      this.$graphql({ query, shouldCache: true })
        .then(({ data }) => {
          this.type_fuels = data.type_fuels
        })
    }
  }
}
</script>