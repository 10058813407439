<template>
  <BaseDrawer
    ref="baseDrawer"
    title="Upcoming Features"
  >
    <div class="w-full w-full">
      <div class="description">
        <p>
          We are busy working on some exciting features for roll-out in late 2020.<br>Got an idea for other functionality you would like to see? Please <a
            href="mailto:eric.engelman@gmail.com"
            target="_blank"
          >share it</a>.
        </p>
        <br>
        <p>
          We would love your help as a Beta Tester!<br> As we roll out new features you will have early access, and your feedback will help us improve. <a
            href="https://forms.gle/MN43r5pCcTFABrZw9"
            target="_blank"
          >Sign up!</a>
        </p>
      </div>
      <div class="space--2" />
      <div class="space--2" />
      <div class="content">        
        <div
          v-for="step in steps"
          :key="step.title"
          class="row"
        >
          <div class="media">
            <img
              :src="step.img"
              :alt="step.title"
            >
          </div>
          <div class="description">
            <h2 class="ts--title--5">
              {{ step.title }}
            </h2>
            <p class="ts--p--2">
              {{ step.content }}
            </p>
          </div>
        </div>  
      </div>
    </div>
  </BaseDrawer>
</template>

<script>

export default {
  name: 'DrawerUpcomingFeatures',
  data() {
    return {
      steps: [
        {
          title: 'More Studies',
          content: 'As more state-wide cost-effectiveness studies are published, we plan to add results to the Cost-Effectiveness Explorer',
          img : '/images/more-studies.jpg'
        },      
        {
          title: 'New Construction Estimates',
          content: 'To help you develop reach codes that affect new construction, we are working on creating new construction forecast for each California city and county unicorporated area. These will include estimates of the volume of future construction expected from 2023 to 2025, which corresponds to the 2022 building code cycle. Forecasts will first cover residential and then non-residential construction.',
          img : '/images/impact-estimation.jpg'
        },
        {
          title: 'Policy Impact Estimation for New Construction',
          content: 'We have already added the ability to select a set of existing residential measures and estimate the city-wide (or county-wide) impact if those are adopted as a reach code. This includes the potential community-wide energy, utility bill, and GHG savings over the licycle of the policy. We are currently working on extending this capability to support new building policies. Following that, we will add existing non-residential policies.',
          img : '/images/impact-comparison.jpg'
        },
        {
          title: 'Bring Your Own Data',
          content: "Rather than using our estimates, soon users will have an option to input their cities' own building stock data or construction forecasts to power our policy impact estimates.",
          img : '/images/mobile-experience.jpg'
        },
      ]
    }
  },
  mounted() {
    this.$eventBus.$on('openUpcomingFeaturesDrawer', () => {
      this.$refs.baseDrawer.openDrawer()
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('openUpcomingFeaturesDrawer')
  },
  methods: {
    closeDrawer() {
      this.showDrawer = false
    },
  }
}
</script>

<style lang="scss" scoped>

  .row {
    display: flex;
    border-bottom: 1px solid #E4EEF2;
    padding-bottom: 36px;
    padding-top: 36px;
    align-items: center;
    max-width: 1100px;

    &:last-of-type {
      border-bottom: 0;
    }

    .media {
      flex-shrink: 0;
      margin-right: 60px;
    }

    &:nth-of-type(2n) {
      flex-direction: row-reverse;
      .media {
        margin-left: 60px;
        margin-right: 0;
      }
    }
  }

  h1 {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 42px;
    line-height: 48px;
    color: #4B8CA6;
  }

  .description {
    h2 {
      margin-bottom: 28px;
    }
    p {
      margin-bottom: 0;
      color: #404040;
    }
    a {
      color: initial;
    }
  }
  
    
</style>