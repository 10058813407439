<template>
  <div class="h-screen max-h-screen overflow-y-hidden flex items-start m-0 relative">
    <router-view />
    <QualityAssuranceModal />
  </div>
</template>
<script>
import QualityAssuranceModal from './components/development/QualityAssuranceModal.vue'
import 'vue-toast-notification/dist/theme-sugar.css'

export default {
  name: 'App',
  components: { QualityAssuranceModal },
  created() {
    this.initStore()
    this.developmentClearCacheHotKeyEventListener()
    this.qaHotKeyEventListener()
  },
  methods: {

    initStore() {
      this.$store.commit('assumptions/closeDrawer') // Drawer should always start as closed DDEV-243
      this.$store.dispatch('assumptions/initBuildingsData')
      this.$store.dispatch('globalEntities/loadGlobalEntities')
      this.$store.dispatch('general/checkDeviceInfo')
    },

    developmentClearCacheHotKeyEventListener() {
      document.addEventListener("keydown", function(event) {
        if (event.altKey && event.code === "KeyX") {
          event.preventDefault()
          localStorage.clear()
          sessionStorage.clear()
          window.location.reload(true)
        }
      })
    },

    qaHotKeyEventListener() {
      document.addEventListener("keydown", (event) => {
        if (event.altKey && event.code === "KeyQ" && !this.$store.state.general.qaHotKeyPressed) {
          event.preventDefault()
          event.stopImmediatePropagation()
          event.stopPropagation()
          this.$store.commit('general/setQaHotKeyPressed', true)
        }
      })
      document.addEventListener("keyup", (event) => {
        event.preventDefault()
        if(this.$store.state.general.qaHotKeyPressed) this.$store.commit('general/setQaHotKeyPressed', false)
      })
    }
    
  }
}

</script>
<style lang="scss">
  
  @import './assets/reset.css';
  @import './assets/css/index.css';
  @import './assets/global';

</style>

