<template>
  <ul>
    <li class="py-8 px-6 border-b border-deepsky-200">
      <h2 class="text-header-6 text-deepsky-900 font-bold ">
        Methodology
      </h2>
      <p class="text-header-4 text-ash-500 pt-4">
        How do we calculate the data?
      </p>
      <p
        class="text-header-4 text-deepsky-500 pt-4 transition-all hover:opacity-70 cursor-pointer"
        @click="$eventBus.$emit('openDrawerContent', { type: 'helper', slug: 'building-stock-estimation-methodology'})"
      >
        Building Stock
      </p>
      <!-- <p
        @click="$eventBus.$emit('openDrawerContent', { type: 'helper', slug: 'forecast-constructions-methodology'})"
        class="text-header-4 text-deepsky-500 mt-1/2 pb-8 transition-all hover:opacity-70 cursor-pointer">Construction Forecasts</p> -->
    </li>
    
    <li 
      class="pt-8 px-6 border-b border-deepsky-200 transition-all text-deepsky-900 hover:text-deepsky-500 cursor-pointer" 
      @click="onClickResultsSources()"
    >
      <h2 class="text-header-6 font-bold text-current">
        Results Sources
      </h2>
      <p class="text-header-4 text-ash-500 pt-4 pb-8">
        Where do the cost-effectiveness results in this tool come from?
      </p>
    </li>
    <!-- <li @click="openTutorial()">
      <h2>Tutorial</h2>
      <p>We’ve built a tutorial to walk you through the findings.</p>
    </li> -->
    <li 
      class="pt-8 px-6 border-b border-deepsky-200 transition-all text-deepsky-900 hover:text-deepsky-500 cursor-pointer" 
      @click="onClickUtilityRates()"
    >
      <h2 class="text-header-6 font-bold text-current">
        Utility Rate Info
      </h2>
      <p class="text-header-4 text-ash-500 pt-4 pb-8">
        What utility rates were assumed for these calculations?
      </p>
    </li>
    <!-- <li @click="openChangeLog()">
      <h2>Changelog</h2>
      <p>Be updated on the tool releases!</p>
    </li> -->
  </ul>
</template>

<script>
import { utilityRatesOptions, resultsSourcesOptions } from '@/util/Drawers'
export default {
  name: 'SidebarMenuInfo',
  methods: {
    onClickUtilityRates() {
      this.$eventBus.$emit('openDrawerContent', utilityRatesOptions(this.$route) )
    },
    onClickResultsSources() {
      this.$eventBus.$emit('openDrawerContent', resultsSourcesOptions(this.$route))
    },
  }
}
</script>

<style scoped>
  p {
    max-width: 300px;
  }
</style>