import GraphQL from '@/util/GraphQL'

export default {
  namespaced: true,
  
  state: {
    study_types: []
  },

  getters: {
    getterGlobalStudyTypes(state) {
      return state.study_types
    },

    getterStudyTypeWithStudyGroupsAndStudies(state, getters, rootState) {
      const items =  state.study_types.map((study_type) => {
        const study_groups = rootState.globalEntities
          .StudyGroup.study_groups
          .filter((study_group) => study_group.study_type_id == study_type.id)
          .map((study_group) => {
          const studies = rootState.globalEntities
            .Study.studies
            .filter((studies) => studies.study_group_id == study_group.id)
            return {
              studies,
              ...study_group
            }
        })
        return {
          study_groups,
          ...study_type
        }
      })
      return items
    },

    getterStudyTypeStudyIds: (state, getters) => study_type_id => {
      return getters['getterStudyTypeWithStudyGroupsAndStudies'].findInArray({ id: study_type_id })
        .study_groups.reduce((acc, study_group) => {
          return acc = [ ...acc, ...study_group.studies.map((study) => study.id)]
        }, [])
    }

  },

  actions: {
    loadGlobalStudyTypes(context) {
      const query = `
        {
          study_types (
            orderBy: [{ column: "order", order: "ASC" }]
          ) {
            id
            title
            order
          }
        }
      `
      GraphQL({ query })
        .then(({ data }) => {
          context.commit('setGlobalStudyTypes', { study_types: data.study_types})
        })
    }
  },

  mutations: {
    setGlobalStudyTypes(state, { study_types }) {
      state.study_types = study_types
    }
  }
}