// Residential buildings should have the same SLUG as registered at the database
export const ASSUMPTIONS_PATHS = {
  IMPACT_RESULTS : {
    slug: 'impact-results',
    component: 'AssumptionsImpacts'
  },
  RESIDENTIAL_BUILDINGS : {
    title: 'Residential Buildings',
    description: 'New and Existing Units',
    slug: 'residental-buildings',
    component: 'AssumptionsResidential',
    icon: 'home_work'
  },
  NON_RESIDENTIAL_BUILDINGS : {
    title: 'Nonresidential',
    description: 'New and Existing Buildings',
    slug: 'non-residental-buildings',
    component: 'AssumptionsNonResidential',
    icon: 'corporate_fare'
  }
}

export const STUDY_TYPES = {
  EXISTING : 'Existing Units',
  NEW : 'New Construction'
}

export const RESIDENTIAL_NEW_SPECIFICATIONS = {
  BUILDING_TYPE : {
    label: 'Building Type',
    key: 'BUILDING_TYPE',
  },
  CLIMATE_ZONE : {
    label: 'Climate Zone',
    key: 'CLIMATE_ZONE',
  },
  YEAR : {
    label: 'Year',
    key: 'YEAR',
  },
  // CONSTRUCTION_TYPE : {
  //   label: 'Construction Type',
  //   key: 'CONSTRUCTION_TYPE',
  // },
}

export const BUILDING_ESTIMATES_BASE_YEARS = [ 2023, 2024, 2025 ]
export const BUILDING_ESTIMATES_BASE_YEARS_TIMEFRAME = `${BUILDING_ESTIMATES_BASE_YEARS[0]} - ${BUILDING_ESTIMATES_BASE_YEARS[2]}`

export default {
  ASSUMPTIONS_PATHS,
  STUDY_TYPES
}

export const ASSUMPTIONS_DRAWER_TYPES = {
  POLICIES : 'policyImpactAssumptions',
  RESULTS: 'resultsImpactAssumptions'
}

export const BUILDINGS_DRAWER_TABS = {
 NEW:  'New Construction',
 EXISTING: 'Existing Units'
}
