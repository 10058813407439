<template>
  <BaseDrawer
    ref="baseDrawer"
  >
    <div
      v-if="getStudyDataSelected"
      class="w-full"
    >
      <div class="flex items-end mb-3 justify-between">
        <h1 class="text-header-4 font-bold text-deepsky-800">
          {{ getJurisdictionSelected.type }}-wide estimates for 
          <Dropdown
            ref="measureDropdown"
            :button-classes="'px-2 bg-transparent w-full'"
            :dropdown-dialog-classes="'left-0 bg-white'"
          >
            <template v-slot:buttonLabel>            
              <span class="text-header-4 text-deepsky-500 border-b border-gray05 pb-1 flex items-center mt-2">
                {{ getStudyDataSelected.measure.title }}
                <feather
                  class="ml-2"
                  type="chevron-down"
                  size="14"
                />
              </span>
            </template>
            <template v-slot:items>
              <div class="w-auto flex flex-wrap flex-col justify-start font-medium">
                <button
                  v-for="(option, index) in studyDataRows"
                  :key="index"
                  class="text-header-3 w-auto text-left bg-transparent focus:outline-none border-b border-blue05 last:border-none py-2 px-5"
                  :class="option.measure.id == measure.id ? 'text-gray04 cursor-default' : 'text-sec font-bold'"
                  @click="onSelectMeasure(option.measure)"
                >
                  {{ option.measure.title }}
                </button>
              </div>
            </template>
          </Dropdown>
        </h1>
        <ForecastAssumptionsWidget class="ml-10 bg-ash-200 p-2 px-4 rounded-sm" />
      </div>
      <ul
        v-if="getSubtitle"
        class="subtitle ts--accent--1 text-gray03"
      >
        <li
          v-for="item in getSubtitle"
          :key="item"
        >
          {{ item }}
        </li>        
      </ul>
      
      <div
        v-if="!editAssumptions"
        class="w-full flex flex-col"
      >
        <div class="w-full flex justify-start mt-8">
          <CardProjection
            ref="cardProjection"
            :study_data="getStudyDataSelected.data"
            :assumption="resultsImpactAssumptions"
            :building_stock_units="building_stock_units"
            :column-selected-key.sync="columnSelectedKey"
            :first-selected-column-key="firstSelectedColumnKey"
          />
        </div>
      </div>
    </div>
  </BaseDrawer>
</template>

<script>
import ForecastAssumptionsWidget from './ForecastAssumptionsWidget.vue'
import CardProjection from '@/components/projections/CardProjection.vue'
import { find } from '@/util/Functions'
import { mapState } from 'vuex'
export default {
  name: 'DrawerProjections',
  components: { CardProjection, ForecastAssumptionsWidget },
  data() {
    return {
      subtitle: '',      
      studyDataRows: [],
      prototype: null,
      vintage: null,
      measure: null,
      assumption: null,
      building_stock_units: 0,
      dataInitialized: false,
      columnSelectedKey: null,
      firstSelectedColumnKey: null,
      editAssumptions: false,
      projectionsByYear: []
    }
  },
  computed: {
    getJurisdictionSelected() {
      return this.$store.getters['lastJurisdictionVisited']
    },
    getStudyDataRows() {
      return this.studyDataRows.map((studyDataRow) => {
        studyDataRow.assumption = this.resultsImpactAssumptions
        studyDataRow.building_stock_units = this.$store.getters['assumptions/buildingStocks/getterAllBuildingStocksUnits'](this.buildingStockFilters)
        studyDataRow.generateStudyResultProjections()
        return studyDataRow
      })
    },
    buildingStockFilters() {
      const jurisdiction_id = this.getJurisdictionSelected.id
      const climate_zone_prefix = this.$route?.params?.climate_zone_raw?.split('-')?.[0] ?? null
      const type_prototype_id = this.prototype.type_prototype_id
      const type_vintage_id = this.vintage.type_vintage_id
      return { jurisdiction_id, climate_zone_prefix, type_prototype_id, type_vintage_id }
    },
    getStudyDataSelected() {
      if(!this.studyDataRows || !this.measure) return false
      const studyDataRow = this.studyDataRows.findInArray({ measure_id: this.measure.id })
      studyDataRow.assumption = this.resultsImpactAssumptions
      studyDataRow.building_stock_units = this.$store.getters['assumptions/buildingStocks/getterAllBuildingStocksUnits'](this.buildingStockFilters)
      studyDataRow.generateStudyResultProjections()
      return studyDataRow
    },
    getColumnSelected() {
      return find(this.columns, { key: this.columnSelectedKey})
    },
    getSubtitle() {
      const study = this.prototype.study      
      const climateZonePrefix = this.studyDataRows[0].climate_zone_raw.split('-')[0]
      return [ study.title, this.prototype.title, this.vintage.title, `Climate Zone ${climateZonePrefix}`, `${Number(this.building_stock_units).toLocaleString()} Units`]
    },
    ...mapState('assumptions', ['resultsImpactAssumptions'])
  },
  watch: {
    '$route.fullPath'() {
      this.$refs.baseDrawer.closeDrawer()
    }
  },
  mounted() {
    this.$eventBus.$on('openProjectionsModal', ({ studyData, columnSelectedKey, prototype, vintage , measure, building_stock_units }) => {
    
      this.columnSelectedKey = columnSelectedKey
      this.firstSelectedColumnKey = columnSelectedKey
      this.measure = measure
      this.studyDataRows = studyData
      this.prototype = prototype
      this.vintage = vintage
      
      this.building_stock_units = building_stock_units      
      this.$refs.baseDrawer.openDrawer()

    })
  },
  beforeDestroy() {
    this.$eventBus.$off('openProjectionsModal')
  },
  methods: {
    onSelectMeasure(measure) {
      this.measure = measure
      this.$refs.measureDropdown.close()
    }
  }
}
</script>

<style scoped lang="scss">
  button {
    transition: 300ms ease-in-out all;
    color: #979797;
    position: relative;
    &.is-selected .tab-title {
      color: #4B8CA6;
      &::before {
        content: "";
        position: absolute;
        left: 8px;
        top: 10px;
        background: #4B8CA6;
        width: 4px;
        height: 12px;
        display: inline-block;
        margin-right: 5px;
      }
    }
  }

  .subtitle {
    @media screen and (min-width: 1600px) {
      display: flex;
      li {
        &:after {
          content: " ・ "
        }
        &:last-of-type:after {
          content: "";
        }
      }
    }
  }
  .tab-title {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 120%;
    color: #76848A;
  }
  
  .tab-description {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 120%;
    color: #76848A;
  }
  
  .tab-total {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #64666D;
  }
  
  ::v-deep .forecast-assumptions-widget button img {
    display: none;
  }
</style>