<template>
  <table>
    <thead>
      <th>Raw</th>
      <th>Number</th>
      <th>Money</th>
    </thead>
    <tr
      v-for="(number, index) in numbers"
      :key="index"
    >
      <td>{{ number }}</td>
      <td>{{ formatNumber(number) }}</td>
      <td>{{ formatCurrency(number) }}</td>
    </tr>
  </table>
</template>

<script>
export default {
  computed: {
    numbers() {
      const numbers = [
        0.000123456789,
        0.00123456789,
        0.0123456789,
        0.123456789,
        1.23456789,
        5.0,
        12.3456789,
        50.00,
        123.456789,
        1234.56789,
        12345.6789,
        123456.789,
        1234567.89,
        12000000.9,
        12345678.9,
        123000000.9,
        123456789.123456789,
        1234567891.223456789,
        12345678912.3456789,
        123456789123.456789,
        1234567891234.56789,
        12345678912345.6789,
        123456789123456.789,
        1234567891234567.89,
        12345678912345678.9,
        1234567891234567891.23456789,
        12345678912345678912.3456789,
        123456789123456789123.456789,
        1234567891234567891234.56789,
        12345678912345678912345.6789,
        123456789123456789123456.789,
        1234567891234567891234567.89,
        12345678912345678912345678.9,
      ]

      return numbers
    }
  }
}
</script>
