<template>
  <tbody>
    <PolicyTableRow
      v-for="(row, index) in getRows"
      :key="index"
      :policy="policy"
      :item="row"
      :index-row="index"
      :rows-length="getRows.length"
      :column-groups="columnGroups"
      :items-hidden-indexes="itemsHiddenIndexes"
    />
  </tbody>
</template>

<script>
import PolicyTableRow from './PolicyTableRow.vue'
export default {
	name: 'PolicyTableBody',
	components: { PolicyTableRow },
	props: {
		policy: {
			type: Object,
			required: true
		},
    policy_summary_data: {
			type: Array,
			required: true
		},
    columnGroups: {
			type: Array,
			required: true
		},
		itemsHiddenIndexes: {
			type: Array,
			required: true
		}
	},
	data: () => ({
		rows: []
	}),
  computed: {
    columns: () => {
      return this.columnGroups.length
    },

		getRows() {
			const rows = []
			for (let index = 0; index < this.policy_summary_data.length; index++) {
				const item = this.policy_summary_data[index]
				this.addRow(item, 0, `${index}`, rows)
			}
			return rows
		}
  },
	methods: {
		addRow(item, deep = 0, index, rows) {
      item.deep = deep
			item.index = index
			rows.push(item)
			item.last_deep = item.items ? false : true
			if (item.items) {
				const items_child = [...item.items]
				for (let indexChild = 0; indexChild < items_child.length; indexChild++) {
					const item_child = items_child[indexChild]
					item_child.is_last = indexChild + 1 === items_child.length ? true : false
					this.addRow(item_child, deep + 1, `${index}-${indexChild}`, rows)					
				}
			}
		}
	}
}
</script>

<style>

</style>