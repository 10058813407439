import Vue from 'vue'
import router from '@/router'
import Api from '@/util/Api'
import UserEventApiService from '@/services/api/UserEventApiService'

export default {  

  getLoggedUser(context) {
    return new Promise( (resolve, reject) => {
      Api.get(`/auth/get_logged_user`)
      .then(({ data }) => {
        const user = data
        context.commit('setLoggedUser', { user })
        resolve({ user })
        context.dispatch('general/checkRedirectTo')
      })
      .catch(() => {
        Vue.$toast.error('Error on get logged user')
        reject()
      })
    })
  },

  logoutUser(context, {showNotification = true, redirect = true} = {}) {
    context.commit('setLogout')
    if(showNotification) Vue.$toast.success('Logged out!')
    if(redirect) router.push('/', () => {})
  },

  setUserPreferences({ commit, dispatch }, { mutation = 'setUserPreferences', payload }) {
    commit(mutation, payload)
    dispatch('updateUserPreferencesAtDatabase')
  },

  setUserLastJurisdictionVisited({ dispatch }, { context, value }) {
    UserEventApiService.log({ event: 'SHOW_JURISDICTION', table: 'jurisdictions', table_item_id: value.id })
    return dispatch('setUserPreferences', { payload: { path: 'lastJurisdictionVisited', context, value } })
  },
  
  setUserLastClimateZonesVisited({ dispatch }, { context, value }) {
    return dispatch('setUserPreferences', { payload: { path: 'lastClimateZonesVisited', context, value } })
  },

  setUserLastJurisdictionAndFirstClimateZoneVisited({ dispatch }, { context, value }) {
    UserEventApiService.log({ event: 'SHOW_JURISDICTION', table: 'jurisdictions', table_item_id: value.id })
    dispatch('setUserPreferences', { payload: { path: 'lastClimateZonesVisited', context, value: value.climate_zones } })
    return dispatch('setUserPreferences', { payload: { path: 'lastJurisdictionVisited', context, value } })
  },

  setModuleVisited({ commit, dispatch }, module ) {
    commit('setModuleVisited', module)
    dispatch('updateUserPreferencesAtDatabase')
  },

  updateUserPreferencesAtDatabase(context) {
    const userId = context.getters.getterLoggedUser?.id
    if (userId) {
      Api.post(`/api/users/update/${userId}`, { user_preferences: context.state.user_preferences })
    }
  }
  
}
