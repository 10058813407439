<template>
  <div class="w-full p-8 mt-6 flex flex-col">
    <div class="w-full flex justify-end mb-3">
      <PsButton
        size="medium"
        label="Create"
        icon="add"
        @click="$eventBus.$emit('openConstructionImplementationTypeModal')"
      />
    </div>

    <table class="table table-bordered table-hover table-striped">
      <thead>
        <tr>
          <td>ID</td>
          <td>Construction Implementation Type</td>
          <td>Slug</td>
          <td class="psui-w-48">
            Actions
          </td>
        </tr>
      </thead>
      <tbody>
        <tr 
          v-for="(construction_implementation_type, construction_implementation_type_index) in construction_implementation_types"
          :key="`construction_implementation_type_${construction_implementation_type_index}`" 
          class="cursor-move"
          draggable="true"
          @dragstart="dragStart(construction_implementation_type_index)"
          @dragover.prevent
          @dragend="dragEnd"
          @drop="dragFinish(construction_implementation_type_index, construction_implementation_types)"
        >
          <td>{{ construction_implementation_type.id }}</td>
          <td>{{ construction_implementation_type.title }}</td>
          <td>{{ construction_implementation_type.slug }}</td>
          <td class="flex space-x-2">
            <PsButton 
              size="medium" 
              label="Edit" 
              icon="edit"
              @click="$eventBus.$emit('openConstructionImplementationTypeModal', { construction_implementation_type_id: construction_implementation_type.id })" 
            />
            <PsButton 
              layout="caution"
              label="Delete"
              size="medium"
              icon="delete"
              @click="selectConstructionImplementationTypeToDelete(construction_implementation_type)"
            />
          </td>
        </tr>
      </tbody>
    </table>

    <AdminConstructionImplementationTypeEditModal @getConstructionImplementationTypes="getConstructionImplementationTypes" />

    <BaseModal
      ref="deleteModal"
      modal-dialog-classes="w-full max-w-sm"
      title="Attention"
    >
      <div
        v-if="construction_implementation_type_selected"
        class="w-full flex flex-col items-start mt-6"
      >
        <p class="text-base text-gray04">
          Delete <span class="text-red font-bold">{{ construction_implementation_type_selected.title }}</span>?
        </p>
        <PsButton
          layout="caution"
          class="psui-mt-6"
          label="Confirm delete"
          size="big"
          @click="deleteConstructionImplementationType()"
        />
      </div>
    </BaseModal>
  </div>
</template>

<script>
import AdminConstructionImplementationTypeEditModal from './AdminConstructionImplementationTypeEditModal'
export default {
  name: 'AdminConstructionImplementationTypeList',
  components: { AdminConstructionImplementationTypeEditModal },
  data() {
    return {
      construction_implementation_types: [],
      construction_implementation_type_selected: null
    }
  },
  computed: {
    getTableToOrdenateItems() {
      return `construction_implementation_types`
    }
  },
  mounted() {
    this.getConstructionImplementationTypes()
  },
  methods: {
    getConstructionImplementationTypes() {
      const query = `
        {
          construction_implementation_types (
            orderBy: [
              { column: "order" }
            ]
          ) {
            id
            title
            slug
          }
        }
      `
      this.$graphql({ query })
        .then(({ data }) => {
          this.construction_implementation_types = data.construction_implementation_types
        })
    },

    selectConstructionImplementationTypeToDelete(construction_implementation_type) {
      this.construction_implementation_type_selected = construction_implementation_type
      this.$refs.deleteModal.showModal = true
    },

    deleteConstructionImplementationType() {
      this.$api.delete(`/api/admin/construction_implementation_type/delete/${this.construction_implementation_type_selected.id}`)
        .then(() => {
          this.getConstructionImplementationTypes()
          this.$refs.deleteModal.showModal = false
          this.construction_implementation_type_selected = null
        })
    }
  }
}
</script>
