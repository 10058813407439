import BasePdfService from '@/services/pdf/BasePdfService'
import html2canvas from "html2canvas"


export default class BuildingEstimatesGeneratePdfService extends BasePdfService {

  fontSizeDefault = 10
  studyTypes = []
  type_prototypes = []
  prototypes = []
  elemsToImage = []
  html2canvasOptions = {}
  _pageImages = []
  updateStateCallback = null

  constructor(elemsToImage, updateStateCallback, args) {
    super(args)
    this.elemsToImage = elemsToImage
    this.updateStateCallback = updateStateCallback
    if (args.html2canvasOptions) {
      this.html2canvasOptions = args.html2canvasOptions
    }
    return this.initPdf()
  }
  
  async initPdf() {
    try {
      this._pageImages = await this.getPageImageElements()
      if (!this._pageImages || !Array.isArray(this._pageImages) || this._pageImages.length <= 0) {
        throw new Error('No page images informed')
      }

      await this.addImagesAsPages({images: this._pageImages})
      await this.export()
    } catch (error) {
      console.error(error)
      throw new Error(error)
    }
  }

  async getPageImageElements() {
    return new Promise((resolve, reject) => {
      if (this.elemsToImage.length <= 0) {
        return reject('no-elements-to-export')
      }

      const placeholderClones = []
      this.elemsToImage.forEach(el => {
        const clone = el.cloneNode(true)
        el.parentElement.insertBefore(clone, el)
        placeholderClones.push(clone)
      })
      if (this.updateStateCallback) {
        this.updateStateCallback(true)
      }

      let times = 40
      const fixFunc = () => {
        this.elemsToImage.forEach(el => {
          el.classList.add('exporting-to-pdf')
          const customWidth = el.getAttribute('attr-export-width')
          if (customWidth) {
            el.style.width = customWidth
          }
        })
      }
      fixFunc()
      const interval = setInterval(() => {
        fixFunc()
        times--
        if (times <= 0) {
          clearInterval(interval)
          const promises = []
          this.elemsToImage.forEach((el) => {
            const options = { ...this.html2canvasOptions }
            const image = html2canvas(el, options).then(canvas => canvas.toDataURL())
            promises.push(image)
          })

          return Promise.all(promises).then((result) => {
            if (this.updateStateCallback) {
              this.updateStateCallback(false)
            }
            placeholderClones.forEach(clone => {
              clone.remove()
            })
            this.elemsToImage.forEach((el) => {
              const customWidth = el.getAttribute('attr-export-width')
              if (customWidth) {
                el.style.width = null
              }
              el.classList.remove('exporting-to-pdf')
            })
            resolve(result)
          }).catch(reject)
        }
      }, 50)
    })
  }

}
