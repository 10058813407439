<template>
  <PsAccordionItem
    title="Clean Energy Progress"
    :class="{ 'form-dirty' : hasValueUpdated }"
  >
    Electricity from the grid will transition steadily away from fossil fuel sources to renewable or zero-emission power sources, leveling off at a maximum of 
    <DropdownWithInputNumber
      :value="getMaxLevel"
      :min-value="0"
      :max-value="100"
      :step="0.1"
      :base-value="maxLevelBaseValue"      
      :button-label="`${getMaxLevel}%`"
      suffix="%"
      @update:value="setMaxLevel"
    />
    by
    <DropdownWithInputNumber
      :value="getMaxYear"
      :min-value="2015"
      :max-value="2080"
      :step="1"
      :base-value="maxYearBaseValue"      
      :button-label="`${getMaxYear}`"
      @update:value="setMaxYear"
    />.
  </PsAccordionItem>
</template>

<script>
import DropdownWithInputNumber from '@/components/general/DropdownWithInputNumber.vue'
import { defaultImpactAssumptionValues } from '@/models/ImpactAssumptions'

export default {
  name: 'AssumptionsCardCleanEnergyProgress',
  components: { DropdownWithInputNumber },
  data: () => ({
    maxLevelBaseValue: defaultImpactAssumptionValues.grid_max_renewables_level,
    maxYearBaseValue: defaultImpactAssumptionValues.grid_max_renewables_year,
  }),
  computed: {
    getMaxLevel() {
      return this.$store.state.assumptions[this.$store.state.assumptions.drawerOptions.type]['grid_max_renewables_level']
    },
    getMaxYear() {
      return this.$store.state.assumptions[this.$store.state.assumptions.drawerOptions.type]['grid_max_renewables_year']
    },
    hasValueUpdated() {
      return this.getMaxLevel != this.maxLevelBaseValue || this.getMaxYear != this.maxYearBaseValue
    }
  },
  methods: {
    setMaxLevel(value) {
      this.$store.dispatch('assumptions/updateAssumptionsKeyValue', { key: 'grid_max_renewables_level', value })
    },
    setMaxYear(value) {
      this.$store.dispatch('assumptions/updateAssumptionsKeyValue', { key: 'grid_max_renewables_year', value })
    }
  }
}
</script>
