
<template>
  <div
    id="building-estimates-index"
    class="w-full h-full flex flex-col overflow-y-auto psui-bg-gray-10"
  >
    <BuildingEstimatesHeader
      ref="header"
      class="psui-sticky psui-top-0 psui-z-40 psui-bg-white psui-shadow-elevation-10"
      @trigger-download="download($event)"
    />
    <div class="psui-w-full psui-flex psui-flex-col psui-p-8 main-content">
      <div
        :class="{
          'psui-w-full psui-grid psui-grid-cols-5 psui-gap-6': getBuildingTypeSelected.slug === path.RESIDENTIAL_BUILDINGS.slug,
          'psui-w-full': getBuildingTypeSelected.slug === path.NON_RESIDENTIAL_BUILDINGS.slug
        }"
      >
        <div
          v-if="getBuildingTypeSelected.slug === path.RESIDENTIAL_BUILDINGS.slug"
          class="--add-to-pdf"
          :class="{
            'psui-col-span-full' : getterContentContainerWidth < 1000,
            'psui-col-span-2' : getterContentContainerWidth >= 1000,
          }"
          :attr-export-width="`${html2canvasOptions.windowWidth/4}px`"
        >
          <ExistingUnitsDonutsChart
            :get-type-prototypes-with-units="getTypePrototypesWithUnits"
          />
        </div>
        
        <div
          :class="{
            'psui-col-span-full' : getterContentContainerWidth < 1000,
            'psui-col-span-3' : getterContentContainerWidth >= 1000,
          }"
        >
          <FutureUnitsForecastChart 
            :get-type-prototypes-with-units="getTypePrototypesWithUnits"
            :get-climate-zone-selected="getClimateZoneSelected"
            :get-building-type-selected="getBuildingTypeSelected"
          />
        </div>
      </div>
      <div class="psui-w-full psui-mt-6">
        <TypePrototypeCardList
          ref="prototypeCards"
          :get-type-prototypes-with-units="getTypePrototypesWithUnits" 
          :get-building-stock-total-units="getBuildingStockTotalUnits"
          :get-climate-zone-selected="getClimateZoneSelected"
          :get-building-type-selected="getBuildingTypeSelected"
        />
      </div>
    </div>
  </div>
</template>
<script>


import BuildingEstimatesHeader from './BuildingEstimatesHeader.vue'
import ExistingUnitsDonutsChart from './existing/ExistingUnitsDonutsChart.vue'
import FutureUnitsForecastChart from './future/FutureUnitsForecastChart.vue'
import TypePrototypeCardList from './TypePrototypeCardList.vue'
import BuildingEstimatesGeneratePdfService from '@/services/pdf/BuildingEstimatesGeneratePdfService'
import BuildingEstimatesGenerateCsvService from '@/services/csv/BuildingEstimatesGenerateCsvService'
import { GA_LABELS } from '@/mixins/GaEventsMixin'
import { joinCommaPlusAnd } from '@/util/Functions'
import { mapGetters } from "vuex"
import {formatDate} from "../../../../util/Helpers"
import { ASSUMPTIONS_PATHS } from '@/modules/app/assumptions/shared/enums'

export default {
  name: 'BuildingEstimatesIndex',
  components: { BuildingEstimatesHeader, ExistingUnitsDonutsChart, FutureUnitsForecastChart, TypePrototypeCardList  },
  metaInfo: {
    title: 'Building Estimates | '
  },
  data() {
    return {
      isExporting: false,
      containerWidth: false,
      path: ASSUMPTIONS_PATHS
    }
  },
  computed: {
    ...mapGetters({
      lastClimateZonesVisited : 'lastClimateZonesVisited',
      lastJurisdictionVisited : 'lastJurisdictionVisited',
      getterContentContainerWidth: 'general/getterContentContainerWidth',
      type_prototypes : 'globalEntities/TypePrototype/getterGlobalTypePrototypes',
    }),
    getBuildingTypeSelected() {
      return this.$store.getters['globalEntities/BuildingType/getterBuildingTypeSelected'](this.$route.fullPath)
    },
    getTypePrototypes() {
      if(!this.getBuildingTypeSelected) return []
      return this.$store.getters['globalEntities/TypePrototype/getterGlobalTypePrototypeFilteredByBuildingType'](this.getBuildingTypeSelected.id)
    },
    getClimateZoneSelected() {
      const climate_zones = this.$store?.getters['lastJurisdictionVisited']?.climate_zones ?? []
      return climate_zones
        .filter((climate_zone) => !this.checkQueryKeyValue('exclude_climate_zone_prefix', climate_zone.prefix))
    },
    getTypePrototypesWithUnits() {
      return this.getTypePrototypes.map((type_prototype) => {
        return {
          ...type_prototype,
          building_stock_units: this.getTypePrototypeBuildingStockUnits(type_prototype)
        }
      })
    },
    getBuildingStockTotalUnits() {
      return this.getTypePrototypesWithUnits.reduce((acc, item) => acc + parseInt(item.building_stock_units), 0)
    },
    getCsvData() {
      const csvFiles = []
      this.getTypePrototypes.forEach((typePrototype) => {
        const vintages = this.$store.getters['globalEntities/TypeVintage/getterGlobalTypeVintages'].map((type_vintage) => {
          return {
            ...type_vintage,
            building_stock_units: this.$store.getters['assumptions/buildingStocks/getterAllBuildingStocksUnits']({
              type_vintage_id: type_vintage.id,
              type_prototype_id: typePrototype.type_prototype_id,
              climate_zone_prefix: this.getClimateZoneSelected.map(cz => cz.prefix)
            })
          }
        })
        console.log(this.type_prototypes)
        const prototype = {
          title: this.type_prototypes.find((tp) => tp.id === typePrototype.id).building_stock_title,
          slug: typePrototype.slug,
          climateZones: {}
        }
        this.getClimateZoneSelected.map(cz => cz.prefix).forEach((climateZone) => {
          const climateZoneData = {}
          vintages.forEach((vintage) => {
            climateZoneData[vintage.slug] = {
              title: vintage.title,
              building_stock_units: this.$store.getters['assumptions/buildingStocks/getterAllBuildingStocksUnits']({
                type_vintage_id: vintage.id,
                type_prototype_id: typePrototype.type_prototype_id,
                climate_zone_prefix: climateZone
              })
            }
          })
          prototype.climateZones[climateZone] = climateZoneData
        })

        csvFiles.push(prototype)
      })
      return csvFiles
    },
    html2canvasOptions() {
      return {
        windowWidth: 1920,
        logging : true
      }
    },
  },
  methods: {
    getTypePrototypeBuildingStockUnits(type_prototype) {
      if(!type_prototype.id) return 0
      const filters = {
        type_prototype_id: type_prototype.id,
        climate_zone_prefix: this.getClimateZoneSelected.map(cz => cz.prefix)
      }
      return this.$store.getters['assumptions/buildingStocks/getterAllBuildingStocksUnits'](filters)
    },
    setExporting(value) {
      if (value) {
        this.isExporting = true
        this.$refs.prototypeCards.setExporting(true)
      } else {
        this.isExporting = false
        this.$refs.prototypeCards.setExporting(false)
      }
    },
    download(type) {
      const showLoadingModal = () => {
        this.$eventBus.$emit('openLoadingModal', {
          title: 'Download in progress...',
          description: 'We are preparing your document. Your download will be ready in a moment...',
          showCloseBtn: false
        })
      }
      switch(type) {
        case 'PDF':
          this.gaEventDownloadFile(GA_LABELS.BUILDING_ESTIMATES_DOWNLOAD_PDF, window.location.href)
          showLoadingModal()
          this.generatePdfFile()
          break
        case 'CSV':
          this.gaEventDownloadFile(GA_LABELS.BUILDING_ESTIMATES_DOWNLOAD_CSV, window.location.href)
          showLoadingModal()
          this.generateCsvFile()
          break
        default:
          console.error('Unsupported download type: ', type)
          break
      }
    },
    async generatePdfFile() {
      this.gaEventDownloadFile(GA_LABELS.BUILDING_ESTIMATES_DOWNLOAD_PDF)
      const args = {
        title: this.lastJurisdictionVisited?.title_type,
        subtitle: `Climate Zone ${joinCommaPlusAnd(this.lastClimateZonesVisited.map(c => c.prefix))}`,
        headline: 'Residential Building Estimates',
        sources : [
          {
            "title": "2019 V2 Cost-Effectiveness Study: Existing Single Family Residential Building Upgrades",
            "url": "https://localenergycodes.com/download/875/file_path/fieldList/2019%20V2-Residential%20Retrofit%20Cost-eff%20Report-2021-08-27.pdf",
            "subtitle": "California Energy Codes and Standards Program, PG&E. Produced by:  Frontier Energy, Inc, Misti Bruceri & Associates."
          },
          {
            "title": "2019 V1 Cost-Effectiveness Study: Existing Low-Rise Residential Building Efficiency Upgrade",
            "url": "https://localenergycodes.com/download/876/file_path/fieldList/2019-V1%20Res%20Retrofit%20Cost-eff%20Report-2020-02-06.pdf",
            "subtitle": "California Energy Codes and Standards Program, PG&E. Produced by:  Frontier Energy, Inc, Misti Bruceri & Associates."
          },
          {
            "title": "2019 Cost-Effectiveness Study: Low-Rise Residential New Construction",
            "url": "https://localenergycodes.com/download/73/file_path/fieldList/2019%20Res%20NC%20Cost-eff%20Report",
            "subtitle": "California Energy Codes and Standards Program, PG&E. Produced by:  Frontier Energy, Inc, Misti Bruceri & Associates."
          },
          {
            "title": "2019 Nonresidential New Construction Reach Code Cost-Effectiveness Study",
            "url": "https://localenergycodes.com/download/74/file_path/fieldList/2019%20NR%20NC%20Cost%20Effectiveness%20Report",
            "subtitle": "California Energy Codes and Standards Program, SoCal Edison. Produced by:  TRC, EnergySoft."
          }
        ],
        html2canvasOptions: this.html2canvasOptions
      }

      const pdf = new BuildingEstimatesGeneratePdfService(this.$el.querySelectorAll('.--add-to-pdf'),
          this.setExporting, args)
      pdf.then(() => {
        this.$eventBus.$emit('closeLoadingModal')
        this.toastInstance = this.$appToast({ message: 'The PDF is ready and is being downloaded!',
          duration: 5000 })
      }).catch(() => {
        this.$eventBus.$emit('closeLoadingModal')
        this.toastInstance = this.$appToast({ message: 'Sorry, something went wrong with the PDF export. Try again',
          duration: 5000 })
      })
    },
    async generateCsvFile() {
      this.gaEventDownloadFile(GA_LABELS.BUILDING_ESTIMATES_DOWNLOAD_CSV)
      const header = `${this.lastJurisdictionVisited?.title_type} - Residential Building Stock\n\n` +
          `Building Type: Single Family and Multifamily\n\n` +
          `SOURCE: Cost-Effectiveness Explorer - Exported - ${formatDate({date: new Date()})}${this.customData()}\n` +
          `URL: ${window.location.href}\n`
      const csvGenerator = new BuildingEstimatesGenerateCsvService(this.getCsvData,
          `${this.lastJurisdictionVisited?.slug}-residential-building-stock.csv`, header)
      csvGenerator.export().then(() => {
        this.$eventBus.$emit('closeLoadingModal')
        this.toastInstance = this.$appToast({ message: 'Your CSV is ready!',
          duration: 5000 })
      }).catch(() => {
        this.$eventBus.$emit('closeLoadingModal')
        this.toastInstance = this.$appToast({ message: 'Sorry, something went wrong with the CSV\'s export. Try again',
          duration: 5000 })
      })
    },
    customData() {
      const hasCustomBuildingEstimates = this.$store.getters['assumptions/buildingStocks/getterCustomJurisdictionBuildingStocks']().length ?? false
      return hasCustomBuildingEstimates ? ', some figures edited by user' : ''      
    }
  }
}
</script>