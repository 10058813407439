const BaseModel = class {
  
  constructor(obj = {}) {
    this.custom_fields = obj.custom_fields || null
  }

  get errors(){
    return this.requireds.filter((req) => {

      if(typeof (this[req.item]) == 'boolean' && !this[req.item]) {
        return false
      }

      if (typeof (req.validation) == 'function') {
        return req.validation(this[req.item], this)
      }

      if (
        typeof (this[req.item]) == 'string' && !this[req.item].length
        || Array.isArray(this[req.item]) && !this[req.item].length
        || typeof (this[req.item]) == 'object' && !this[req.item]
      ) {
        return true
      }

      return false
    }).map((item) => {
      return { item: item.item, label: item.label }
    })
  }

  get hasError(){
    return (this.errors.length) ? true : false
  }

  get errorPhrase(){
    return {
      init: 'Please check the items ',
      end: ' to continue.'
    }
  }

  get validationPhrase(){
    return this.errorPhrase.init + this.errors.map((erro) => erro.label).join(', ') + this.errorPhrase.end
  }

  get validationPhraseHtml(){
    return `${this.errorPhrase.init} <b>${this.errors.map((erro) => erro.label).join(', ')}</b> ${this.errorPhrase.end}`
  }

  validation = (input) => {
    // if(typeof(this[input]) == 'string' && !this[input].length || typeof(this[input]) == 'number' && this[input] == 0) return { hasError: true, checked: false, filled: false, required: true, item: null, label: null }

    const requiredFinded = this.errors.filter((item) => item.item == input)

    if (!requiredFinded.length) return { hasError: false, checked: true, filled: true, required: true, item: null, label: null }

    return { hasError: true, checked: false, filled: true, required: true, item: requiredFinded[0].item, label: requiredFinded[0].label }
  
  }

  addCustomFields() {
    if (!this.custom_fields_keys || !this.custom_field_item_type) return 

    if (!this.custom_fields) this.custom_fields = { item_type: this.custom_field_item_type , data: { } }
    
    if (this.id) this.custom_fields.item_id = this.id

    this.custom_fields_keys.forEach(field_key => {

      // Fallback for object reactivity
      if (!this.custom_fields.data[field_key]) {
        this.custom_fields.data[field_key] = { value : '' }
      }
      
      Object.defineProperty(this, field_key, {

        get() {
          return this.custom_fields?.data?.[field_key]?.value ?? ''
        },

        set(value) {
          this.custom_fields.data[field_key].value = value
        }

      })

    })
  }

}

module.exports = BaseModel
