<template>
  <div
    ref="apexChartGeneral"
    :style="{ height: getOptions.chart.height, minHeight: getOptions.chart.height }"
    class="psui-flex psui-items-center psui-justify-center"
  />
</template>

<script>
import { apexChartBarDefaultOptions, apexChartDonutsDefaultOptions } from '@/business-logic/constants/chartDefaultOptions'
import ApexCharts from 'apexcharts'
import { deepMergeObject } from '@igortrindade/lazyfy'
export default {
  name: "ApexChartGeneral",
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      chartInstance: null,
      chartIsLoading: false
    }
  },
  computed: {
    getOptions() {
      const chartDefaultOptions = (this.options.chart.type == 'donut') ? apexChartDonutsDefaultOptions : apexChartBarDefaultOptions
      return deepMergeObject(JSON.parse(JSON.stringify(chartDefaultOptions)), this.options)
    }
  },
  watch: {
    options: {
      deep: true,
      handler() {
        this.mountChart()
      }
    },
  },
  mounted() {
    this.mountChart()
  },
  beforeDestroy() {
    if(this.chartInstance) this.chartInstance.destroy()
  },
  methods: {
    mountChart() {
      if(this.chartIsLoading) return
      this.chartIsLoading = true
      if(this.chartInstance) this.chartInstance.destroy()
      setTimeout(() => {
        this.chartInstance = new ApexCharts(this.$refs.apexChartGeneral, this.getOptions)
        this.chartInstance.render()
        this.chartIsLoading = false
      }, 200)
    }
  }
}
</script>