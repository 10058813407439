<template>
  <div class="w-full flex flex-col">
    <!-- <button @click="$emit('update:step', 1)" class="w-full flex items-center space-x-2 text-ash-400 font-light mb-4 text-xs">
      <feather
        class="cursor-pointer mr-1 relative hover:text-deepsky-700"
        type="chevron-left"
        size="16"
      />
      <span 
        v-for="breadcrumb in breadcrumbs"
        :key="breadcrumb"
        class="text-header-1 transition-all hover:text-deepsky-700 cursor-pointer"
      >
        {{breadcrumb}}
      </span>
    </button> -->

    <iframe
      width="720"
      height="484"
      src="https://www.youtube.com/embed/hIXwONdToho"
      title="Flexible Paths"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    />
  </div>
</template>

<script>
export default {
  name: 'StepHelperVideo',
  props: ['step'],
  data: () => ({
    breadcrumbs: ['Policy Type', 'Flexible compliance policy', 'Learn how it works' ]
  })
}
</script>

<style lang="scss" scoped>
  span {
    &::after {
      content: "/";
      display: inline-block;
      padding: 0 5px;
    }
    &:last-of-type::after {
      display: none;
    }
  }
  button {
    position: relative;
    top: -25px;
    margin-top: -15px;
  }
</style>