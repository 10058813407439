<template>
  <div class="assumption_tab">
    <AssumptionsBreadcrumb 
      :path="path"
      :drawer="getDrawerType"
      class="pt-4"
      @update:path="$emit('update:path', $event)"
    />
    
    <h2 class="px-6 font-bold psui-text-gray-80 psui-text-h6 pb-2">
      {{ getTitle }}
    </h2>

    <PsTabHeader
      layout="underline"
      :items="['Basic', 'Advanced']"
      :selected.sync="getTabSelected"
      class="psui-px-6 psui-relative psui-z-10 psui-bg-white"
    />
    <div class="tabheader-shadow psui-shadow-elevation-10 psui-mb-6" />

    <div class="psui-px-6 psui-mb-4">
      <PsButton
        label="Restore default"
        icon="restart_alt"
        size="medium"
        layout="ghost"
        icon-position="left"
        class="psui-w-full psui-justify-center"
        :disabled="Object.keys($store.getters['assumptions/getterImpactAssumptionsChanges']).length === 0"
        @click="$store.dispatch('assumptions/clearImpactAssumptions')"
      />
    </div>
    
    <PsAccordion class="psui-px-6">
      <div v-show="getTabSelected === 'Basic'">
        <AssumptionsImpactsEffectiveDate />
        <AssumptionsImpactsActivePolicyDuration />
        <AssumptionsImpactsPenetrationRate />
        <AssumptionsImpactsApplicabilityRate
          ref="applicability_rate"
          class="mb-10"
        />
      </div>
      <div v-show="getTabSelected === 'Advanced'">
        <AssumptionsImpactsInstallationTime />
        <AssumptionsImpactsCleanEnergyProgress />
        <AssumptionsImpactsCurrentGridCleanEnergyShare />
        <AssumptionsImpactsNaturalGasEmissionsFactor />
        <AssumptionsImpactsBaselineInstallationRate />
      </div>      
    </PsAccordion>
  </div>
</template>

<script>
import AssumptionsBreadcrumb from '@/modules/app/assumptions/shared/AssumptionsBreadcrumb.vue'
import AssumptionsImpactsActivePolicyDuration from './AssumptionsImpactsActivePolicyDuration.vue'
import AssumptionsImpactsEffectiveDate from './AssumptionsImpactsEffectiveDate.vue'
import AssumptionsImpactsPenetrationRate from './AssumptionsImpactsPenetrationRate.vue'
import AssumptionsImpactsApplicabilityRate from './AssumptionsImpactsApplicabilityRate.vue'
import AssumptionsImpactsInstallationTime from './AssumptionsImpactsInstallationTime.vue'
import AssumptionsImpactsCleanEnergyProgress from './AssumptionsImpactsCleanEnergyProgress.vue'
import AssumptionsImpactsCurrentGridCleanEnergyShare from './AssumptionsImpactsCurrentGridCleanEnergyShare.vue'
import AssumptionsImpactsNaturalGasEmissionsFactor from './AssumptionsImpactsNaturalGasEmissionsFactor.vue'
import AssumptionsImpactsBaselineInstallationRate from './AssumptionsImpactsBaselineInstallationRate.vue'
import { jurisdiction_TYPES } from "../../../../util/Enums"
import { ASSUMPTIONS_DRAWER_TYPES } from "../shared/enums"
import { mapGetters } from "vuex"

const TAB_ITEMS = ['Basic', 'Advanced']

export default {
  name: 'AssumptionsImpacts',
  components: { 
    AssumptionsBreadcrumb, 
    AssumptionsImpactsEffectiveDate, 
    AssumptionsImpactsActivePolicyDuration,
    AssumptionsImpactsPenetrationRate,
    AssumptionsImpactsApplicabilityRate,
    AssumptionsImpactsInstallationTime,
    AssumptionsImpactsCleanEnergyProgress,
    AssumptionsImpactsCurrentGridCleanEnergyShare,
    AssumptionsImpactsNaturalGasEmissionsFactor,
    AssumptionsImpactsBaselineInstallationRate,
  },
  props: ['path'],
  data(){
    return {
      TAB_ITEMS
    }
  },
  computed: {
    ...mapGetters(['lastJurisdictionVisited']),
    getAssumptionsOptions() {
      return this.$store.getters['assumptions/getterAssumptionsDrawerOptions']
    },
    getDrawerType(){
      return `${this.$store.getters.lastJurisdictionVisited.type}-wide Estimates`
    },
    getTitle() {
      return this.getAssumptionsOptions.type === ASSUMPTIONS_DRAWER_TYPES.POLICIES ? 'Policy Assumptions' :
          (this.lastJurisdictionVisited.type === jurisdiction_TYPES.CITY ?
              'City-wide Impact Assumptions' : 'County-wide Impact Assumptions')
    },
    getTabSelected: {
      get() {
        const seletectedTab = this.$store.getters['assumptions/getterAssumptionsDrawerOptions'].tab
        return seletectedTab && TAB_ITEMS.includes(seletectedTab) ? seletectedTab : TAB_ITEMS[0]
      },
      set(tab) {
        this.$store.commit('assumptions/updateDrawerTab', tab)
      }
    }
  },
  methods:{
    restoreToDefault(){
      this.$store.dispatch('assumptions/clearImpactAssumptions')
    }
  },
}
</script>

<style lang="scss" scoped>
  h2 {
   margin-bottom: 6px; 
  }
  .tabheader-shadow {
    height: 4px;
    position: relative;
    top: -4px;
  }
  ::v-deep {    

    .psui-el-accordion-item {

      &-header {
        position: relative;
        &:before {
          content: "";
          position: absolute;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          top: 23px;
          right: 24px;
          transition: 300ms ease-in-out all;
        }        
      }
      &.form-dirty .psui-el-accordion-item-header::before {
        background: #5DB883;
      }
    }

    .psui-el-accordion-item-content {
      font-size: 16px;
      line-height: 130%;
      color: #515E6A;
    }
    .dynamic {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 26px;
      font-weight: bold;
      color: #4B8CA6;
      position: relative;
      display: inline-block;
      cursor: pointer;    
      padding: 0;
      min-height: 0;
      &:after {
        content: "";
        position: absolute;
        border-bottom: 1px solid #4B8CA6;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
    .different-from-base-value .dynamic {
      color: #44A06A;
      &:after {
        border-bottom-color: #44A06A;
      }
    }
  }
</style>
