import BuildingTypeApiService from '@/services/api/BuildingTypeApiService'

export default class BuildingType extends BuildingTypeApiService {
  constructor(obj = {}) {
    super()
    this.id = obj.id ?? null
    this.title = obj.title ?? ''
    this.content = obj.content ?? ''
    this.icon = obj.icon ?? ''
    this.slug = obj.slug ?? ''

    this.type_prototypes = obj.type_prototypes ?? []
    this.type_prototypes = obj.type_prototypes ?? []
    this.custom_fields = obj.custom_fields ?? null
  }
}