<template>
  <BaseModal
    ref="baseModal"
    modal-dialog-classes="w-full max-w-sm"
  >
    <h4 class="w-full font-bold text-pri text-lg text-center font-normal tracking-wide">
      Supported study versions
    </h4>
    <div class="w-full flex flex-col items-center  p-4">
      <p class="text-p-2 text-ash-600 text-center">
        Currently this feature supports policies with measures from:
      </p>
      <p class="text-p-2 text-ash-600 text-center">
        Existing Low Residential Building Study August 27, 2021 version  Single Family Only
      </p>
      <p class="text-p-2 text-ash-600 text-center">
        Existing Low Residential Building Study February 9, 2020 version  Multifamily Only
      </p>
      
      <AppAlert
        description="If you have measures from other study versions in your policy, they won't be included in your flexible compliance path."
        icon="alert-circle"
        :icon-size="32"
        wrapper-class="p-2 text-sunrise-600 border-2 border-sunrise-400 rounded-md text-header-2"
        class="w-full mt-6"
      />
    </div>
  </BaseModal>
</template>

<script>

export default {
  name: 'ModalSupportedStudyVersions',
  methods: {
    open() {
      this.$refs.baseModal.showModal = true
    }
  }
}
</script>