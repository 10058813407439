import { ASSUMPTIONS_DRAWER_TYPES } from '@/modules/app/assumptions/shared/enums'
import { defaultImpactAssumptionValues } from '@/models/ImpactAssumptions'
export default {  
  
  isUpdating(state) {
    return state.buildingStocks.is_updating ||state.buildingEstimates.is_updating
  },

  getterAssumptionsSelected(state) {
    return state[state.drawerOptions.type]
  },

  getterAssumptionsDrawerOptions(state){
    return state.drawerOptions
  },

  getterImpactAssumptionsChanges(state) {
    const assumptions = state.drawerOptions.type === ASSUMPTIONS_DRAWER_TYPES.POLICIES ? {...state.policyImpactAssumptions } : {...state.resultsImpactAssumptions}
    const assumptionItemsChanged = {}
    Object.keys(defaultImpactAssumptionValues).map((key) => {
      if(defaultImpactAssumptionValues[key] != assumptions[key]) {
        assumptionItemsChanged[key] = assumptions[key]
      }
    })
    return assumptionItemsChanged
  },

  getterPolicyImpactAssumptions: state => state.policyImpactAssumptions,

  getterUserCanEditTheSelectedPolicy(state,getters,rootState,rootGetters) {
    return rootGetters.getterLoggedUser?.id == state.policyImpactAssumptions.user_id
  },

  getterIsEditingPolicyAssumptions(state) {
    return state.drawerOptions.type === ASSUMPTIONS_DRAWER_TYPES.POLICIES
  }
  
}