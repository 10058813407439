<template>
  <BaseDrawer
    ref="baseDrawer"
    title="Select Policies to Compare"
    class="w-full md:max-w-md"
  >  
    <h4
      v-if="jurisdiction"
      class="subtitle mt-2 text-header-4 text-ash-500 mb-8"
    >
      Existing Building Policies for 
      <Dropdown
        v-if="jurisdictions.length > 1"
        ref="measureDropdown"
        :button-classes="'px-2 bg-transparent w-full'"
        :dropdown-dialog-classes="'left-0 bg-white'"
        class="mt-0"
      >
        <template v-slot:buttonLabel>            
          <span 
            v-if="jurisdiction"
            class="text-header-4 text-deepsky-500 border-b border-gray05 pb-1 flex items-center mt-0"
          >
            {{ jurisdiction.label }}
            <feather
              v-if="jurisdictions.length > 1"
              class="ml-2"
              type="chevron-down"
              size="14"
            />
          </span>
        </template>
        <template v-slot:items>
          <DropdownMenuList              
            :items="jurisdictions"
            :selected="jurisdiction.id"
            key-label="label"
            key-value="id"
            @update:selected="onChangeJurisdiction"
          />
        </template>
      </Dropdown>
      <span
        v-else-if="jurisdiction"
        class="text-header-4 text-deepsky-500 border-gray05 ml-2 font-normal"
      >
        {{ jurisdiction.label }}
      </span>
    </h4>
    <div
      v-if="!getterLoggedUser"
      class="w-full"
    >
      <h2 class="text-header-3 text-ash-400">
        <router-link
          :to="{ name: 'PolicyList' }"
          class="font-bold"
        >
          Create your account
        </router-link> to create your own policies.
      </h2>
    </div>
    <div
      v-else-if="getterLoggedUser && !jurisdictions.length"
      class="w-full"
    >
      <h2 class="text-header-3 text-ash-400">
        You have no saved policies to add for this jurisdiction.
      </h2>
    </div>
    <div
      v-else
      class="w-full"
    >
      <AppTabHeader
        :items="showPoliciesByOptions"
        :selected.sync="showPoliciesBy"
        class="mb-8"
      />

      <div class="w-full flex mb-3">
        <div class="w-3/5 pl-7 flex items-center opacity-100-all-childrens-on-hover">
          <h3 class="text-header-2 font-bold text-gray02 ml-3">
            Policy Name
          </h3>
          <button
            class="focus:outline-none"
            :class="{ 'opacity-0' : orderBy.column != 'title' }"
          >
            <feather
              size="16"
              :type="orderBy.direction == 'asc' ? 'arrow-down' : 'arrow-up'"
              class="text-blue ml-2 cursor-pointer"
              @click="setOrderColumn('title')"
            />
          </button>
        </div>
        <div class="w-1/5 flex items-center opacity-100-all-childrens-on-hover">
          <h3 class="text-header-2 font-bold text-gray02">
            Last Update
          </h3>
          <button
            class="focus:outline-none"
            :class="{ 'opacity-0' : orderBy.column != 'updated_at' }"
          >
            <feather
              size="16"
              :type="orderBy.direction == 'asc' ? 'arrow-down' : 'arrow-up'"
              class="text-blue ml-2 cursor-pointer"
              @click="setOrderColumn('updated_at')"
            />
          </button>
        </div>
        <div class="w-1/5" />
      </div>

      <div 
        v-for="policy in policiesFiltered" 
        :key="policy.id"
        class="w-full flex py-4 border-b border-deepsky-200 last:border-b-0 text-gray-04 actions-wrapper opacity-100-all-childrens-on-hover"
      >
        <div class="w-3/5 pl-1 flex items-center">
          <Checkbox
            class="mr-4 cursor-pointer transition-all flex-shrink-0"              
            :label="policy.title"
            :value="$route.query.policies_ids.split(',').includes(String(policy.id))"
            label-classes="text-ash-600 text-p-2"
            @change="$emit('toggle-policy', policy.id)"
          />
        </div>
        <div class="w-1/5">
          <h3 class="text-p-2 text-gray04">
            {{ formatDate({ date: policy.updated_at}) }}
          </h3>
        </div>
        <div class="w-1/5">
          <feather
            v-if="policy.user_id !== getterLoggedUser.id"
            type="users"
            class="text-ash-300"
          />
        </div>
      </div>
    </div>
  </BaseDrawer>
</template>

<script>
import AppTabHeader from '@/components/general/AppTabHeader.vue'
import { mapGetters } from 'vuex'
import { showPoliciesByOptions } from '@/business-logic/constants/policiesEnum'

export default {
  name: 'ComparisonPoliciesSelectDrawer',
  components: { AppTabHeader },
  props: {
    jurisdiction: {
      type: [Object, Boolean],
      default: false
    },
    jurisdictions: {
      type: Array,
      required: true
    },
  },
  data: () => ({
    showPoliciesByOptions: Object.values(showPoliciesByOptions),
    showPoliciesBy: showPoliciesByOptions.ALL,
    orderBy: {
      column: 'title',
      direction: 'asc'
    },
  }),
  mounted() {
    this.$eventBus.$on('openComparisonPoliciesSelectDrawer', () => {      
      this.$refs.baseDrawer.openDrawer()
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('openComparisonPoliciesSelectDrawer')
  },
  computed: {
    policiesByJurisdiction() {
      return this.jurisdiction ? this.getterUserPoliciesByJurisdiction[this.jurisdiction.id] : []
    },
    policiesFiltered() {
      return this.policiesByJurisdiction ? this.policiesByJurisdiction.filter((policy) => {
        if (this.showPoliciesBy === showPoliciesByOptions.SHARED ) {
          return policy.user_id !== this.getterLoggedUser.id
        } else if ( this.showPoliciesBy === showPoliciesByOptions.USER ) {
          return policy.user_id === this.getterLoggedUser.id
        } else {
          return true
        }
      })
      .sort((a, b) => {
        const key = this.orderBy.column
        const order = this.orderBy.direction

        if (!Object.prototype.hasOwnProperty.call(a, key) || !Object.prototype.hasOwnProperty.call(b, key)) {
          // property doesn't exist on either object
          return 0
        }

        const varA = (typeof a[key] === 'string')
          ? a[key].toUpperCase() : a[key]
        const varB = (typeof b[key] === 'string')
          ? b[key].toUpperCase() : b[key]

        let comparison = 0
        if (varA > varB) {
          comparison = 1
        } else if (varA < varB) {
          comparison = -1
        }
        return (
          (order === 'desc') ? (comparison * -1) : comparison
        )

      }) : []
    },
    ...mapGetters('comparison', ['getterUserPoliciesByJurisdiction']),
    ...mapGetters(['getterLoggedUser'])
  },
  methods: {
    setOrderColumn(column) {
      if(this.orderBy.column == column) {
        this.orderBy.direction = (this.orderBy.direction == 'asc') ? 'desc' : 'asc'
      } else {
        this.orderBy.column = column
        this.orderBy.direction = 'asc'
      }
    },
    onChangeJurisdiction(jurisdiction_id) {
      this.$emit('update-jurisdiction', jurisdiction_id)
      this.$refs.measureDropdown.close()
    },
  }
}
</script>

<style lang="scss" scoped>  
  ::v-deep .app-drawer-content {    
    margin-top: 0;    
  }

  ::v-deep {
    &.app-drawer {
      width: 478px;
    }
  }
</style>