<template>
  <div class="flex items-center ml-3">
    <Dropdown
      ref="dropdown"
      :button-classes="!isFiltering ? 'bg-transparent rounded-full border border-gray05 text-gray04' : 'bg-blue05-50 text-blue02 border border-blue03 rounded-full'"
      title="Show only if cost-effective based on"
      :dropdown-dialog-classes="'left-0 bg-white p-6'"
      :min-width-dropdown="'300px'"
    >
      <template v-slot:buttonLabel>
        <div class="w-full flex justify-between items-center whitespace-no-wrap px-2">
          Cost-Effectiveness
        </div>
      </template>
      <template
        v-slot:items
        class="w-full min-w-3/4"
      >        
        <div class="flex flex-col mt-4">
          <Checkbox 
            label="On-Bill Benefit/Cost Ratio"
            label-classes="ts--accent--2 text-gray02"
            :value="checkQueryKeyValue('show_only_cost_effectiveness', 'onBill')"
            :tooltip="getHelperContent('forecast_filter_on_bill_benefit_to_cost_ratio')"
            @change="toggleQueryKeyValue('show_only_cost_effectiveness', 'onBill')"
          />
          <Checkbox
            label="TDV Benefit/Cost Ratio"
            label-classes="ts--accent--2 text-gray02"
            :value="checkQueryKeyValue('show_only_cost_effectiveness', 'tdv')"
            :tooltip="getHelperContent('forecast_filter_tdv_benefit_to_cost_ratio')"
            @change="toggleQueryKeyValue('show_only_cost_effectiveness', 'tdv')"
          />
        </div>
        <p class="desc">
          TDV may not be reported by some studies.
        </p>
      </template>
    </Dropdown>
  </div>
</template>

<script>
export default {
  name: 'FilterCostEffective',
  computed: {
    isFiltering() {
      return this.checkQueryHasKey('show_only_cost_effectiveness')
    }
  },
  methods: {
    getHelperContent(slug) {            
      return { 
        content: () => { return this.getHelperContentData(slug) }, 
        loadingContent: 'Loading...',
        html: true
      }
    },
    async getHelperContentData(slug) {
      const query = `
        {
          helper (
            where: [
              {column: "slug", operation: "=", value: "${slug}"},
            ]
          ) {            
            description
          }
        }
      `
      const { data } = await this.$graphql({ query })
      return data.helper?.description ?? 'Oops, content not found...'
    }
  }
}
</script>
<style scoped>
  ::v-deep .desc {  
    font-style: italic;
    font-weight: normal;
    font-size: 12px;
    line-height: 120%;
    color: #81878A;
  }
</style>