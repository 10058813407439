<template>
  <label 
    class="inline-flex items-center cursor-pointer" 
    :class="[checkboxClasses]" 
    @mouseover="isHovering = true" 
    @mouseout="isHovering = false"
  >
    <input
      v-model="childValue"
      type="checkbox"
      class="hidden"
    >
    <span 
      class="flex-shrink-0 w-5 h-5 rounded-sm relative flex items-center justify-center block text-white border border-2 cursor-pointer transition duration-300 ease-in-out" 
      :class="[`border-${bg}`]"
    >
      <span
        class="rounded-sm w-3 h-3"
        :class="[childValue ? `bg-${bg}` : 'bg-white']"
      />
    </span>
    <span
      class="ml-2 cursor-pointer flex items-center whitespace-pre"
      :class="labelClasses"
    >{{ label }}</span>
  </label>
</template>

<script>
export default {
  name: 'CheckboxSelectAll',
  props: {
    value: {
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    bg: {
      type: String,
      default: 'blue04'
    },
    type: {
      type: String,
      default: 'checkbox'
    },
    checkboxClasses: {
      type: String,
      default: 'mb-2'
    },
    labelClasses: {
      type: String,
      default: 'text-gray04'
    }
  },
  data: () => ({
    isHovering: false
  }),
  computed: {
    childValue: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue )
        this.$emit('change', newValue )
      }
    }
  }
}
</script>