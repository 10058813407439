import GraphQL from '@/util/GraphQL'

export default {
  namespaced: true,
  
  state: {
    prototypes: []
  },

  getters: {
    getterGlobalPrototypes(state) {
      return state.prototypes
    },

    getterPrototypeSelected: state => routeFullPath => {
      const url = new URL(window.location.host + routeFullPath)
      const prototype_selected = url.searchParams.get('only_prototype')
      if(!prototype_selected) return state.prototypes[0]
      return state.prototypes.findInArray({ slug: url.searchParams.get('only_prototype')})
    },

    getterPrototypeById: state => prototype_id => {
      return state.prototypes.findInArray({ id: prototype_id })
    }

  },

  actions: {
    loadGlobalPrototypes(context) {
      const query = `
        {
          prototypes (
            orderBy: [{ column: "order", order: "ASC" }]
          ) {
            id
            title
            slug
            type_prototype_id
            study_id
            proportional_type_prototypes {
              id
              title
              slug
              pivot {
                percentage
              }
            }
          }
        }
      `
      GraphQL({ query })
        .then(({ data }) => {
          context.commit('setGlobalPrototypes', { prototypes: data.prototypes})
        })
    }
  },

  mutations: {
    setGlobalPrototypes(state, { prototypes }) {
      state.prototypes = prototypes
    }
  }
}