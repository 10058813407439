<template>
  <BaseDrawer
    ref="baseDrawer"
    title="Changelog"
  >
    <div class="w-full w-full">
      <AppTabHeader
        :items="tabs"
        :selected.sync="tabSelected"
        class="mb-8"
      />

      <div
        v-if="tabSelected === 'Database'"
        class="w-full flex align-left mt-6"
      >
        <AppLoader
          v-if="!databaseChanges"
          text="Loading"
        />
        <RenderHtmlExtended
          v-else
          class="app-changelog ml-10"
          :html="databaseChanges"
        />
      </div>
      <ul
        v-else
        class="ml-10"
      >
        <li 
          v-for="({date, version, title, description }) in applicationChanges"
          :key="version"
        >
          <p> <span class="text-gray">{{ date }}</span></p>
          <h4>{{ title }}</h4>
          <div
            class="content"
            v-html="description"
          />
        </li>
      </ul>
    </div>
  </BaseDrawer>
</template>

<script>
import RenderHtmlExtended from './RenderHtmlExtended.vue'
export default {
  name: 'DrawerChangelog',
  components: { RenderHtmlExtended },
  data() {
    return {
      tabs: [ 'Database', 'Application'],
      tabSelected: 'Database',
      databaseChanges: null,
      // https://semver.org/
      applicationChanges: [
        { 
          date: '06/15/2021', 
          version: '2.0', 
          title: 'Policies', 
          description: 'The user is able to create policies',
          changes: [
            'Policy Studio',
            'Policy Comparision',
            'User Account'
          ]
        }
      ],
    }
  },
  mounted() {
    this.$eventBus.$on('openDrawerChangelog', () => {
      this.$refs.baseDrawer.openDrawer()
      this.getDatabaseChanges()
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('openDrawerChangelog')
  },
  methods: {
    async getDatabaseChanges() {

      const query = `
        {
          helper (
            where: [
              {column: "slug", operation: "=", value: "menu-changelog-database"},
            ]
          ) {
            title
            content
          }
        }
      `
      const { data } = await this.$graphql({ query })
      this.databaseChanges = data.helper.content

    }
  }
}
</script>

<style lang="scss" scoped>
  ::v-deep {
    
    .app-changelog > ul > li {
      padding-bottom: 20px;
    }

    ul {
      ul {
        padding-left: 40px;
        color: gray;
        list-style: circle;
      }
    }
  }  
  // ul {
  //   li {      
  //     position: relative;
  //     img {
  //       position: absolute;
  //       left: 0;
  //     }
  //     h2 {
  //       font-size: 24px;
  //       line-height: 30px;
  //       margin-bottom: .5em;
  //       cursor: pointer;
  //       color: black;
  //     }
  //     .content ::v-deep {
  //       margin-bottom: 2em;
  //       color: #404040;
  //       p {
  //         color: #404040;
  //       }        
  //       > p:first-of-type {
  //         padding-top: 11px;
  //       }
  //       > p:last-of-type {
  //         font-size: 16px;
  //         line-height: 26px;
  //         padding-bottom: 2em;
  //       }
  //     }
  //   }
  // }
</style>