<template>
  <BaseDrawer 
    ref="baseDrawer" 
    title="Add/Hide Columns"
    subtitle="Select or re-arrange columns to display"
  >
    <div class=" flex pr-4">
      <div 
        class="w-1/2 border-b-2 py-3 pr-3"
        :class="[studyTypeSelected == 'Existing Buildings' ? 'border-blue04 text-pri font-bold' : 'border-blue04-10 text-gray font-semi-bold']"
      >
        <button 
          :class="[studyTypeSelected == 'Existing Buildings' ? 'border-blue04 text-blue02 font-bold' : 'border-blue04-10 text-gray font-semi-bold']"
          class="focus:outline-none"
          @click="studyTypeSelected = 'Existing Buildings'"
        >
          Existing Buildings
        </button>
      </div>
      <div 
        class="w-1/2 border-b-2 py-3 px-3" 
        :class="[studyTypeSelected == 'New Buildings' ? 'border-blue04 text-pri font-bold' : 'border-blue04-10 text-gray font-semi-bold']"
      >
        <button 
          :class="[studyTypeSelected == 'New Buildings' ? 'border-blue04 text-blue02 font-bold' : 'border-blue04-10 text-gray font-semi-bold']"
          class="focus:outline-none"
          @click="studyTypeSelected = 'New Buildings'"
        >
          New Buildings
        </button>
      </div>
    </div>
    <DragAndDropEditColumnsList
      :get-columns="getColumns"
      module="studyResults"
    />
  </BaseDrawer>
</template>

<script>
export default {
  name: 'DrawerForecastEditColumns',
  data() {
    return {
      studyTypeSelected: 'Existing Buildings',
      
    }
  },
  computed: {
    getColumns() {
      if(this.studyTypeSelected == 'New Buildings') {
        return this.$store.getters['studyResults/getterNewBuildingsColumns']
      }
      return this.$store.getters['studyResults/getterExistingBuildingsColumns']
    },
  },

  mounted() {
    this.$eventBus.$on('openStudyResultsDrawerEditColumns', (studyType) => {
      if (studyType) {
        this.studyTypeSelected = studyType 
      }
      this.$refs.baseDrawer.openDrawer()
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('openStudyResultsDrawerEditColumns')
  }
}
</script>
<style lang="scss" scoped>
  ::v-deep {
    &.app-drawer {
      width: 478px;
    }
  }
</style>
