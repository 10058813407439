<template>
  <label 
    class="app-checkbox-item inline-flex items-center" 
    :class="[`app-checkbox-${type}`, checkboxClasses, disabled ? 'cursor-not-allowed opacity-60' : 'cursor-pointer']"
  >
    <input 
      v-if="!prevent" 
      v-model="childValue" 
      type="checkbox" 
      class="hidden" 
      :disabled="disabled"
    >
    <span 
      v-if="type == 'checkbox'"       
      class="flex-shrink-0 rounded-sm relative flex items-center justify-center block text-white cursor-pointer transition duration-300 ease-in-out" 
      :class="[childValue ? `bg-${bg}` : `border`, disabled ? 'cursor-not-allowed border-ash-300' : 'border-deepsky-400']"
      :style="this.getSize"
    >
      <feather
        v-if="childValue"
        type="check"
        size="14"
      />
    </span>
    <span 
      v-else 
      class="radio-button inline-block rounded-full border flex-no-shrink" 
      :class="[childValue ? `bg-${bg} checked` : ``, disabled ? 'border-ash-300' : 'border-deepsky-400']"
      :style="this.getSize"
    />
    <span 
      v-tooltip="tooltip"
      class="ml-2 flex items-center whitespace-pre"
      :class="labelClasses" 
      v-html="label" 
    />
    <slot />
  </label>
</template>

<script>
export default {
  name: 'Checkbox',
  props: {
    value: {
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    bg: {
      type: String,
      default: 'blue04'
    },
    type: {
      type: String,
      default: 'checkbox'
    },
    checkboxClasses: {
      type: String,
      default: 'mb-2'
    },
    labelClasses: {
      type: String,
      default: 'text-gray04'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: [Object, Boolean, String, Promise],
      default: false
    },
    prevent: {
      default: false
    },
    size: {
      default: 16
    }
  },
  computed: {
    childValue: {
      get () {
        return this.value
      },
      set (newValue) {
        if(this.prevent) return
        this.$emit('input', newValue)
        this.$emit('change', newValue)
      }
    },
    getSize() {
      return { width: this.size + 'px', height: this.size + 'px'}
    }
  }
}
</script>

<style lang="scss">

.app-checkbox-radio {
  align-items: center;
}
.radio-button {
  transition: background .2s,
  transform .2s;  
}

.radio-button.checked {
  box-shadow: 0px 0px 0px 2px white inset !important;
}

::v-deep {
  .radio-button {
    + span {
      color: #383B44 !important;
    }
  }
}
  
</style>