<template>
  <div class="w-full">
    <AdminStudyTable :studies="studies" />
  </div>
</template>

<script>
import AdminStudyTable from '../study/AdminStudyTable.vue'
export default {
  name: 'AdminStudyGroupStudies',
  components: { AdminStudyTable },  
  computed: {
    studies() {
      return this?.$parent?.study_group?.studies ?? []
    }
  }
}
</script>

<style>

</style>