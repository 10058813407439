<template>
  <div class="app--piechart">
    <div
      v-tooltip.top-center="tooltip"
      class="vintage-pie"
    >
      <AppPieChartSvg :value="value" />
    </div>
    <span
      v-if="caption"
      v-tooltip.top-center="tooltip"
      class="ml-2 text-header-2 text-deepsky-800 font-bold"
    >{{ caption }}</span>
  </div>
</template>

<script>
import AppPieChartSvg from './AppPieChartSvg.vue'
export default {
  components: { AppPieChartSvg },
  props: {
    value: {
      type: Number,
      required: true,
    },
    tooltip: {
      type: String,
      required: false,
      default: "",
    },
    caption: {
      type: String,
      required: false
    }
  },
}
</script>

<style lang="scss" scoped>
.app--piechart {
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>
