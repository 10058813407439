<template>
  <div class="w-full p-6">
    <div class="w-full flex flex-col">
      <div
        v-for="study_type of study_types"
        :key="`study_type_${study_type.id}`"
        class="w-full flex flex-col mt-6"
      >
        <h3 class="text-header-6 font-bold text-deepsky-800">
          {{ study_type.title }}
        </h3>
        
        <div class="mt-4 rounded bg-deepsky-100 border border-deepsky-200 p-6 px-10">
          <div
            v-for="type_prototype of study_type.type_prototypes" 
            :key="`type_prototype_${type_prototype.id}_${study_type.id}`" 
            class="w-full flex flex-col mb-6 last:mb-0"
          >
            <h3 class="text-header-6 font-bold text-deepsky-800">
              {{ type_prototype.title }}
            </h3>

            <div class="w-full flex overflow-x-auto space-x-4 pb-6 mt-4">
              <div 
                v-for="type_vintage of type_prototype.type_vintages" 
                :key="`type_vintage_${type_vintage.id}_${type_prototype.id}_${study_type.id}`" 
                class="w-300 flex flex-col rounded p-4"
                :class="[type_vintage.studies.length ? 'bg-white shadow-sm' : 'border border-dashed border-deepsky-450']"
              >
                <h4 class="text-header-2 font-bold text-deepsky-800">
                  {{ type_vintage.title }}
                </h4>
                <div class="w-full flex flex-col mt-2 items-start">
                  <h4
                    v-for="study of type_vintage.studies"
                    :key="`study_${study.id}_${study_type.id}`"
                    class="text-header-1 text-ash-450 mb-2"
                  >
                    <div class="w-full flex">
                      <button
                        class="text-xxs flex text-red mr-1"
                        @click="selectStudyMapToDelete(study)"
                      >
                        <feather
                          type="x"
                          size="14"
                        />
                      </button>
                      <span>{{ study.title }}</span>
                    </div>
                  </h4>

                  <router-link
                    :to="{ name: 'AdminStudyMapCreate', query: { type_prototype_id: type_prototype.id, type_vintage_id: type_vintage.id, study_type_id: study_type.id }}"
                    class="text-sm flex items-center border rounded border-deepsky-300 text-deepsky-450 px-2 font-bold"
                  >
                    <feather
                      type="plus"
                      size="14"
                    /> Add Studies
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <BaseModal
      ref="deleteStudyMapModal"
      modal-dialog-classes="w-full max-w-sm"
      title="Attention"
    >
      <div
        v-if="studyMapSelected"
        class="w-full flex flex-col items-start mt-6"
      >
        <p class="text-base text-gray04">
          Delete this <span class="text-red font-bold">StudyMap</span>?
        </p>
        <AppButton
          class="mt-6"
          label="Confirm delete"
          size="big"
          @click="deleteStudyMap()" 
        />
      </div>
    </BaseModal>
  </div>
</template>

<script>
export default {
  name: 'AdminStudyMapList',
  data() {
    return {
      study_types: [],
      studyMapSelected: null,
      search: '',
      offset: 0,
      limit: 12,
    }
  },
  mounted() {
    this.getStudyMaps()
  },
  methods: {
    async getStudyMaps() {
      this.$api.get('/study_map/get')
        .then(({ data }) => {
          this.study_types = data.study_types
        })
    },


    selectStudyMapToDelete(study) {
      this.studyMapSelected = study
      this.$refs.deleteStudyMapModal.showModal = true
    },

    deleteStudyMap() {
      this.$api.delete(`/study_map/delete/${this.studyMapSelected.study_map_id}`, { loader: true})
        .then(() => {
          this.$refs.deleteStudyMapModal.showModal = false
          this.getStudyMaps()
        })
    }
  }
}
</script>