<template>
  <PsAccordionItem
    title="Applicability Rate"
    :class="{ 'form-dirty' : hasValueUpdated }"
  >
    The required measures will apply to
    <DropdownWithInputNumber
      :value="getValue"
      :min-value="1"
      :max-value="100"
      :step="0.1"
      :base-value="baseValue"      
      :button-label="`${getValue}%`"
      suffix="%"
      @update:value="setValue"
    />
    of those units as the rest will have already implemented a similar upgrade or otherwise be exempt.
  </PsAccordionItem>
</template>

<script>
import DropdownWithInputNumber from '@/components/general/DropdownWithInputNumber.vue'
import { defaultImpactAssumptionValues } from '@/models/ImpactAssumptions'

export default {
  name: 'AssumptionsCardPenetrationRate',
  components: { DropdownWithInputNumber },
  data: () => ({
    baseValue: defaultImpactAssumptionValues.applicability_rate
  }),
  computed: {
    getValue() {
      return this.$store.state.assumptions[this.$store.state.assumptions.drawerOptions.type]['applicability_rate']
    },
    hasValueUpdated() {
      return this.getValue != this.baseValue
    }
  },
  methods: {
    setValue(value) {
      this.$store.dispatch('assumptions/updateAssumptionsKeyValue', { key: 'applicability_rate', value })
    }
  }
}
</script>
