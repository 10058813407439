<template>
  <div
    v-if="isShow"
    class="fixed right-0 bottom-0 rounded-sm shadow-sm p-3 m-24 bg-ash-200"
  >
    <div class="w-full flex relative">
      <h3 class="w-full font-bold text-base text-center border-b mb-4 mr-6">
        In-App Calculator
      </h3>
      <button @click="isShow = false">
        <feather
          class="text-red absolute top-0 right-0"
          type="x"
        />
      </button>
    </div>
    <div class="w-full flex flex-col px-2">
      <div
        v-for="(item, index) in valuesToSum"
        :key="index"
        class="w-full flex justify-between"
      >
        <span class="mr-3"> <button
          class="rounded-full text-red text-xs mr-2"
          @click="valuesToSum.splice(index, 1)"
        >-</button>{{ item.label }}</span>
        <span>{{ item.value }}</span>
      </div>
    </div>
    <div class="w-full border-t px-2 flex justify-between mt-2 pt-2">
      <span class="mr-3">
        <button
          class="rounded-full text-red text-xs mr-2"
          @click="valuesToSum = []"
        >x</button>
        <span>Total:</span>
      </span>
      <span>{{ getSumTotal }}</span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isShow: false,
      valuesToSum: []
    }
  },
  computed: {
    getSumTotal() {
      return this.valuesToSum.reduce((acc, item) => acc + item.value, 0)
    }
  },
  methods: {
    addValueToSum(value, label) {
      this.isShow = true
      const valueAlreadyAdded = this.valuesToSum.findInArray({ value, label })
      if(valueAlreadyAdded) alert('Item already added to the calculator')
      this.valuesToSum.push({ value, label })
    }
  }
}
</script>

<style>

</style>