<template>
  <div class="app-switcher flex items-center text-header-2">
    <span
      v-if="label"
      class="text-ash-600 mr-2"
    >{{ label }}</span>
    <div
      v-if="getItems.length > 0"
      class="rounded-6 px-1 py-1"
    >
      <span
        v-for="(item, index) in getItems" 
        :key="index"
        class="text-header-2 inline-block rounded-sm cursor-pointer hover:bg-ash-0 hover:text-deepsky-500 transition-all duration-300 mr-2 last:mr-0"
        :class="getSelected[objKey] === item[objKey] ? 'bg-ash-0 text-deepsky-500 font-bold' : 'text-ash-500'"
        @click="$emit('update:selected', item['_source'] ? item['_source'] : item )"
      >
        {{ item[objLabel] }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppSwitcher',
  props: {
    label: {
      type: String
    },
    items: {
      type: Array,
      default: () => []
    },
    selected: {
      type: [String, Object]
    },
    objKey: {
      type: String,
      default: 'value'
    },
    objLabel: {
      type: String,
      default: 'label'
    },
  },
  computed: {
    getItems() {
      return this.items.map(item => this.mapItem(item))
    },
    getSelected() {
      return this.mapItem(this.selected)
    },
  },
  methods: {
    mapItem(item) {
      if (typeof item === 'string' || item instanceof String) {
        return { [this.objKey] : item, [this.objLabel]: item, _source: item }
      } else if (typeof item === 'object' && item !== null) {
        return item
      }
    }
  }
}
</script>

<style scoped>
  span {
    padding: 2.5px 6px;
  }
  .app-switcher div {
    background-color: #EDEFF0;
  }
</style>