<template>
  <div
    v-tooltip="getTooltip"
    class="flex items-center justify-start transition-all mr-3 flex-shrink-0"
    :class="[
      !studyDataRow.measure_is_added ? '' : 'opacity-100'
    ]"
  >
    <span
      v-if="getIcon"
      class="material-icons my-auto"
      :class="[getIcon.class, { 'cursor-pointer' : !getIcon.disabled }]"
      @click="getIcon.disabled ? '' : $emit('toggleMeasure')"
    >{{ getIcon.material }}</span>
  </div>
</template>

<script>
export default {
  name: "ForecastTableRowActions",
  props: ["studyDataRow"],
  computed: {
    getTooltip() {
      const studyData = this.studyDataRow
      let options = { html: true, placement: "top", hideOnTargetClick: false }
      if (!studyData.isCostEffective) {
        options.content = `<strong>Measures that are not cost-effective cannot be added to a combination</strong>`
        options.classes =
          "tooltip-combination-warnings tooltip-not-cost-effective"
      } else if (studyData.measure_combination_conflict) {
        options.content = `<strong>Cannot be combined with ${studyData.measure_combination_conflict.title}</strong>`
        options.classes =
          "tooltip-combination-warnings tooltip-combination-conflict"
      } else if (studyData?.measure?.include_in_combination === false) {
        options.content = studyData.measure.exclude_in_combination_reason
        options.classes =
          "tooltip-combination-warnings tooltip-combination-conflict"
      }
      return options.content ? options : false
    },
    getIcon() {
      const { studyDataRow } = this
      if (studyDataRow.measure_is_added) {
        return {
          material: 'check_box',
          class: 'psui-text-blue-60'
        }
      } else if (studyDataRow.measure_could_be_combined && studyDataRow.isCostEffective) {
        return {
          material: 'check_box_outline_blank',
          class: 'psui-text-blue-60'
        }
      } else if (!studyDataRow.isCostEffective) {
        return {
          material: 'check_box_outline_blank',
          class: 'psui-text-red-15',
          disabled: true,
        }
      } else {
        return {
          material: 'check_box_outline_blank',
          class: 'psui-text-gray-30',
          disabled: true,
        }
      }
    }
  },
}
</script>

<style></style>
