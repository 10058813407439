<template>
  <div class="w-full p-8 mt-6 flex flex-col">
    <div class="w-full flex justify-end mb-3">
      <!-- <PsButton
        size="medium"
        label="Create"
        icon="add"
        @click="$eventBus.$emit('openBuildingTypeModal')"
      /> -->
    </div>

    <table class="table table-bordered table-hover table-striped">
      <thead>
        <tr>
          <td>ID</td>
          <td>Building Type</td>
          <td>Slug</td>
          <td class="psui-w-48">
            Actions
          </td>
        </tr>
      </thead>
      <tbody>
        <tr 
          v-for="(building_type, building_type_index) in building_types"
          :key="`building_type_${building_type_index}`" 
          class="cursor-move"
          draggable="true"
          @dragstart="dragStart(building_type_index)"
          @dragover.prevent
          @dragend="dragEnd"
          @drop="dragFinish(building_type_index, building_types)"
        >
          <td>{{ building_type.id }}</td>
          <td>{{ building_type.title }}</td>
          <td>{{ building_type.slug }}</td>
          <td class="flex space-x-2">
            <PsButton
              size="medium"
              label="Edit"
              icon="edit"
              @click="$eventBus.$emit('openBuildingTypeModal', { building_type_id: building_type.id })" 
            />
            <PsButton 
              layout="caution"
              size="medium" 
              label="Delete" 
              icon="delete"
              @click="selectBuildingTypeToDelete(building_type)"
            />
          </td>
        </tr>
      </tbody>
    </table>

    <AdminBuildingTypeEditModal @getBuildingTypes="getBuildingTypes" />

    <BaseModal
      ref="deleteModal"
      modal-dialog-classes="w-full max-w-sm"
      title="Attention"
    >
      <div
        v-if="building_type_selected"
        class="w-full flex flex-col items-start mt-6"
      >
        <p class="text-base text-gray04">
          Delete <span class="text-red font-bold">{{ building_type_selected.title }}</span>?
        </p>
        <PsButton
          layout="caution"
          class="psui-mt-6"
          label="Confirm delete"
          size="big"
          @click="deleteBuildingType()"
        />
      </div>
    </BaseModal>
  </div>
</template>

<script>
import AdminBuildingTypeEditModal from './AdminBuildingTypeEditModal'
export default {
  name: 'AdminBuildingTypeList',
  components: { AdminBuildingTypeEditModal },
  data() {
    return {
      building_types: [],
      building_type_selected: null
    }
  },
  computed: {
    getTableToOrdenateItems() {
      return `building_types`
    }
  },
  mounted() {
    this.getBuildingTypes()
  },
  methods: {
    getBuildingTypes() {
      const query = `
        {
          building_types (
            orderBy: [
              { column: "order" }
            ]
          ) {
            id
            title
            slug
          }
        }
      `
      this.$graphql({ query })
        .then(({ data }) => {
          this.building_types = data.building_types
        })
    },

    selectBuildingTypeToDelete(building_type) {
      this.building_type_selected = building_type
      this.$refs.deleteModal.showModal = true
    },

    deleteBuildingType() {
      this.$api.delete(`/api/admin/building_type/delete/${this.building_type_selected.id}`)
        .then(() => {
          this.getBuildingTypes()
          this.$refs.deleteModal.showModal = false
          this.building_type_selected = null
        })
    }
  }
}
</script>