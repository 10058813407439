<template>
  <thead class="sticky z-30 top-0 bg-white py-2 text-header-2">
    <tr>
      <th class="sticky z-10 left-0 bg-white psui-text-gray-80 text-header-3">
        <span>Measure & Packages</span>
      </th>
      <th
        v-for="columnGroup of columnsSelectedForStudy.columnGroups" 
        :key="columnGroup.key"
        :colspan="columnGroup.columns.reduce( (prev, cur) => cur.isActive ? prev + 1 : prev ,0)"
        class="text-left bg-white psui-text-gray-80"
      >
        <div class="flex items-center psui-text-p font-bold psui-leading-none">
          <AppInfoHelper 
            :absolute="true" 
            :size="18"
            position="right" 
            class="info-helper"
            @icon-click="$eventBus.$emit('openDrawerContent', { type: columnGroup.hasHelper.type, id: columnGroup.hasHelper.id })"
          >
            <span>{{ columnGroup.title }}</span>
          </AppInfoHelper>
          <span 
            v-if="columnGroup.key === 'city_wide_impact'"
            class="city-wide-actions flex hide-if-collapsed transition-all"
          >
            <PsButton 
              label="Edit Assumptions"
              layout="onlytext"
              icon="tune"
              size="small"
              class="psui-ml-6 psui-mr-1"
              @click.native="$emit('openAssumptionsDrawer')"
            />
            <PsButton 
              label="Chart"
              layout="onlytext"
              icon="bar_chart"
              size="small"
              @click.native="$emit('openProjectionsModal', { columnKey: columnGroup.columns[0].key })"
            />
          </span>
        </div>
      </th>
    </tr>
    <tr>
      <th class="sticky z-10 left-0 bg-white text-gray-50 psui-text-xsmall psui-font-normal psui-text-gray-50 desc">
        <span>Select the measures you want to combine <br>to create your policy.</span>
      </th>
      <template v-for="columnGroup of columnsSelectedForStudy.columnGroups">
        <th 
          v-for="column of columnGroup.columns" 
          :key="columnGroup.key + '-' + column.key" 
          class="font-bold opacity-100-all-childrens-on-hover bg-white align-top pl-4"
        >
          <div class="flex flex-col items-end">
            <span class="text-header-2 psui-text-gray-80 relative flex flex-row">
              <AppInfoHelper 
                :absolute="false" 
                size="16"
                position="left"
                class="info-helper"
                @icon-click="$eventBus.$emit('openDrawerContent', { type: column.hasHelper.type, id: column.hasHelper.id })"
              >
                <button class="flex focus:outline-none order-1 mr-1">
                  <span 
                    :type="orderDirection == 'asc' ? 'arrow_upward' : 'arrow_upward'"
                    size="16"
                    class="psui-text-blue-50 cursor-pointer material-icons-round psui-text-p opacity-0 psui-text-p psui-leading-none transition-all duration-300 ease-in" 
                    @click="$parent.setOrderColumn({ orderColumn: column.key })" 
                  >{{ orderDirection == 'asc' ? 'arrow_downward' : 'arrow_upward' }}</span>
                </button>
                <span class="order-3 ml-1">{{ column.title }}</span>
              </AppInfoHelper>             
            </span>
            <span class="psui-text-gray-50 psui-text-xsmall font-normal">{{ column.description }}</span>
          </div>
        </th>
      </template>
    </tr>
  </thead>
</template>

<script>
export default {
  name: 'ForecastTableHead',
  computed: {
    columnsSelectedForStudy() {
      return this.$parent.columnsSelectedForStudy
    },
    orderColumn() {
      return this.$parent.orderColumn
    },
    orderDirection() {
      return this.$parent.orderDirection
    }
  }
}
</script>
