import Api from '@/util/Api'
import GraphQL from '@/util/GraphQL'
import Store from '@/store'
import DataAndObjectFunctions from '@/util/DataAndObjectFunctions'
import { ERRORS } from '@/util/Enums'
export default class CustomBuildingStockApiService {  

  store(payload = null) {
    if(!Store.getters['getterLoggedUser']) return new Promise((res, rej) => rej(ERRORS.USER_NOT_LOGGED_IN))
    return Api.post(`/api/custom_building_stock/store`, { custom_building_stock: DataAndObjectFunctions.filterObjectKeys(CUSTOM_BUILDING_STOCK_PARAMS, payload) })
  }

  static store_batch(custom_building_stocks = []) {
    if(!Store.getters['getterLoggedUser']) return new Promise((res, rej) => rej(ERRORS.USER_NOT_LOGGED_IN))
    return Api.post(`/api/custom_building_stock/store_batch`, { custom_building_stocks })
  }

  get() {
    const query = `
      {
        currentUser {
          custom_building_stocks {
            id
            user_id
            policy_id
            jurisdiction_id
            climate_zone_prefix
            type_prototype_id
            type_vintage_id
            units
          }
        }
      }
    `

    return GraphQL({ query, caller: 'CustomBuildingStockApiService.get' })
  }

  static delete_batch(custom_building_stocks = []) {
    if(!Store.getters['getterLoggedUser']) return new Promise((res, rej) => rej(ERRORS.USER_NOT_LOGGED_IN_DELETE_FAIL))
    return Api.delete(`/api/custom_building_stock/delete_batch`, { data: { custom_building_stocks } })
  }

}

export const CUSTOM_BUILDING_STOCK_PARAMS = [
  'policy_id', 
  'jurisdiction_id', 
  'climate_zone_prefix', 
  'type_prototype_id', 
  'type_vintage_id', 
  'units'
]