<template>
  <div class="card-main flex flex-col justify-center">
    <WizardBreadcrumb 
      ref="policyWizardBreadcrump"
      :current-step.sync="currentStep"
    />

    <transition
      name="fade"
      mode="out-in"
    >
      <div class="w-full flex flex-col justify-center">
        <div class="mb-6">
          <h1
            v-if="getCurrentStepKeyValue('title')"
            class="text-header-5 text-deepsky-900 font-bold text-center"
          >
            {{ getCurrentStepKeyValue('title') }}
          </h1>
          <p
            v-if="getCurrentStepKeyValue('subtitle')"
            class="text-header-3 text-ash-600 text-center mt-2"
          >
            {{ getCurrentStepKeyValue('subtitle') }}
          </p>
        </div>

        <component
          :is="currentStep.component"
          :current-step="currentStep"
          :policy.sync="policy"
          @nextStep="nextStep"
        />
      </div>
    </transition>

    <div class="footer flex mt-auto">
      <div 
        v-if="currentStep.guide"
        class="text-p-1 font-bold text-ash-400 p-2 mt-2"
      >
        {{ currentStep.guide }}
      </div>

      <div class="ml-auto flex items-center">
        <span
          v-if="currentStep.id == 'policy-basics'"
          class="text-header-1 mr-2 text-ash-500"
        >
          You can always revise your choices later,  and you can draft as many policies as you like.
        </span>
        <span
          v-else-if="getCurrentStepKeyValue('hasNextHelper')"
          class="text-ash-500 mr-2 text-12 font-bold"
        >NEXT:</span>
        <AppButton
          v-if="getCurrentStepKeyValue('getNextButtonText')"
          size="medium"
          :label="getCurrentStepKeyValue('getNextButtonText')"
          :disabled="getCurrentStepKeyValue('getNextButtonTooltip') || policy.loading ? true : false"
          :tooltip="getCurrentStepKeyValue('getNextButtonTooltip') ? getCurrentStepKeyValue('getNextButtonTooltip') : null"
          @click="nextStep()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import StepHelperVideo from './StepHelperVideo.vue'
import StepJurisdictionAndTitle from "./StepJurisdictionAndTitle.vue"
import StepAutoGenerate from "./StepAutoGenerate.vue"
import StepFlexiblePath from "./StepFlexiblePath.vue"
import StepBuildingTypes from "./StepBuildingTypes.vue"
import StepManualFinish from "./StepManualFinish.vue"
import StepAutoGeneratePolicySuccess from './StepAutoGeneratePolicySuccess.vue'
import StepGeneratePolicyLoading from './StepGeneratePolicyLoading.vue'
import WizardBreadcrumb from "./WizardBreadcrumb.vue"
import { mapGetters } from 'vuex'

import { find } from '@/util/Functions'
import Policy from '@/models/Policy'
import steps from './steps'

export default {
  name: "PolicyWizard",
  components: { 
    WizardBreadcrumb,
    StepHelperVideo,
    StepJurisdictionAndTitle,
    StepAutoGenerate,
    StepFlexiblePath,
    StepBuildingTypes,
    StepManualFinish,
    StepAutoGeneratePolicySuccess,
    StepGeneratePolicyLoading,

  },
  data() {
    return {
      policy: new Policy(),
      currentStep: steps[0],
      steps
    }
  },
  computed: {
    ...mapGetters(['lastJurisdictionVisited'])
  },
  mounted() {
    this.updateJustidiction()
  },
  methods: {
    resetState() {
      this.policy = new Policy()
      this.currentStep = steps[0]
      this.updateJustidiction()
    },

    getCurrentStepKeyValue(key) {
      if(key == 'getNextButtonText' && this.policy.loading) return 'LOADING'
      if(!this.currentStep[key]) return false
      if(typeof this.currentStep[key] == 'function') return this.currentStep[key](this.policy)
      return this.currentStep[key]
    },

    async nextStep() {
      let newPolicy, nextStep

      if(this.currentStep.beforeNext) {
        newPolicy = await this.currentStep.beforeNext(this.policy)
        if(newPolicy) this.$set(this, 'policy', newPolicy)
      }

      // Check if has steps to run silent
      // If has, run it successively
      if(this.policy.silent_run_steps.length) {
        for(const step_to_ignore of this.policy.silent_run_steps) {
          const step = find(this.steps, { id: step_to_ignore })
          if(step.beforeNext) {
            newPolicy = await step.beforeNext(this.policy)
            if(newPolicy) this.$set(this, 'policy', newPolicy)
          }
          this.$refs.policyWizardBreadcrump.addStepToNavigation({ ...step })
          nextStep = find(this.steps, { id: step.getNextStep(this.policy) })
        }
        this.policy.silent_run_steps = []
      } else {
        nextStep = find(this.steps, { id: this.currentStep.getNextStep(this.policy) })
      }

      if(nextStep) {
        this.currentStep = nextStep
        this.$refs.policyWizardBreadcrump.addStepToNavigation({ ...nextStep })
      } else {
        alert(`Step not found`)
        this.currentStep = this.steps[0]
      }

    },

    updateJustidiction() {
      if(this.lastJurisdictionVisited) {
        this.policy.jurisdiction = { ...this.lastJurisdictionVisited }
        this.policy.jurisdiction_id = this.lastJurisdictionVisited.id
      }
    }

  }
}
</script>

<style scoped lang="scss">
  .card-main {
    width: 720px;
    min-height: 521px;

    ::v-deep {

      .card-options {
        width: 296px;
      }
      .card-content {
        width: 424px;
      }
      
      .card-content-edit-policy {
        height: 394px;
      }
      .option-policy-method {
        width: 248px;
      }
    }
  }
</style>