<script>
import VueWithCompiler from 'vue/dist/vue.esm'

export default {
  props: {
    html: {
      type: String,
      default: ''
    },
  },
  data: () => ({
    templateRender: undefined
  }),
  watch: {
    html() {
      this.updateRender()
    }
  },
  created() {   
    this.updateRender()
  },
  methods: {
    updateRender() {
      const compiled = VueWithCompiler.compile(`<div class="content">${this.html}</div>`)
      this.templateRender = compiled.render
      this.$options.staticRenderFns = []
      for (const staticRenderFunction of compiled.staticRenderFns) {
				this.$options.staticRenderFns.push(staticRenderFunction)
			}
    }
  },
  render() {
		return this.templateRender()
	},
}
</script>