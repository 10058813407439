<script>
export default {
  data() {
    return {
      componentTreeStructure: ''
    }
  },
  mounted() {
    if(this.$el) this.$el.addEventListener('click', this.handleComponentClickStructure)
  },
  methods: {
    handleComponentClickStructure() {
      if(this.$store.state.general.qaHotKeyPressed && !this.$store.state.general.componentStructureTracing) {
        this.$store.commit('general/setComponentStructureTracing', true)
        const componentName = this.$options.name ? this.$options.name : 'Unamed component'
        if(this.$options.name) this.componentTreeStructure = componentName
        this.getParentName(this)
      }
    },
    getParentName(component) {
      const componentName = component.$parent.$options.name ? component.$parent.$options.name : 'Unamed component'
      if(componentName == 'App' || !component.$parent) {
        this.openQaModal()
      } else {
        this.componentTreeStructure = `${componentName} > ${this.componentTreeStructure}`
        this.getParentName(component.$parent)
      }
    },
    openQaModal() {
      this.$eventBus.$emit('openQAModal', this.componentTreeStructure)
      this.openQAModal
      setTimeout(() => {
        this.$store.commit('general/setComponentStructureTracing', false)
      }, 1000)
      this.componentTreeStructure = ''
    }
  }
}
</script>