import sumItemsWeightedByAttr from '@/business-logic/services/calcs/sumItemsWeightedByAttr'
import getOnBillCostRatio from '@/business-logic/services/study-results/getOnBillCostRatio'
import { GREATER_THEN_ONE_NUMBER } from '@/business-logic/constants/helpersEnum'

export default ({ itemData, itemsRef }) => {

  const allItemsIsGreaterThanOneOnBillCost = itemsRef.filter((item) => item.on_bill_cost_ratio == GREATER_THEN_ONE_NUMBER)
  const allItemsIsGreaterThanOneTdv = itemsRef.filter((item) => item.tdv_benefit_to_cost_ratio == GREATER_THEN_ONE_NUMBER)

  // Per home cols
  itemData.initial_cost = sumItemsWeightedByAttr(itemsRef, 'initial_cost', 'forecast_units_affected')
  itemData.annual_bill_savings = sumItemsWeightedByAttr(itemsRef, 'annual_bill_savings', 'forecast_units_affected')
  itemData.annual_bill_savings_avg = sumItemsWeightedByAttr(itemsRef, 'annual_bill_savings_avg', 'forecast_units_affected')
  itemData.emissions_savings = sumItemsWeightedByAttr(itemsRef, 'emissions_savings', 'forecast_units_affected')
  itemData.lifecycle_savings = sumItemsWeightedByAttr(itemsRef, 'lifecycle_savings', 'forecast_units_affected')
  itemData.kwh_savings = sumItemsWeightedByAttr(itemsRef, 'kwh_savings', 'forecast_units_affected')
  itemData.therms_savings = sumItemsWeightedByAttr(itemsRef, 'therms_savings', 'forecast_units_affected')
  itemData.energy_savings_combined = sumItemsWeightedByAttr(itemsRef, 'energy_savings_combined', 'forecast_units_affected')
  // Cost effectiveness cols
  itemData.on_bill_cost_ratio = allItemsIsGreaterThanOneOnBillCost.length == itemsRef.length ? GREATER_THEN_ONE_NUMBER : getOnBillCostRatio({ initial_cost: itemData.initial_cost, annual_bill_savings_avg: itemData.annual_bill_savings_avg })
  itemData.tdv_benefit_to_cost_ratio = allItemsIsGreaterThanOneTdv.length == itemsRef.length ? GREATER_THEN_ONE_NUMBER :  getOnBillCostRatio({ initial_cost: itemData.initial_cost, annual_bill_savings_avg: itemData.annual_bill_savings })
  itemData.tdv2022_benefit_to_cost_ratio = itemsRef.reduce((acc, item) => acc + item.tdv2022_benefit_to_cost_ratio, 0)
  itemData.simple_payback = sumItemsWeightedByAttr(itemsRef, 'simple_payback', 'initial_cost')
  // City wide cols
  itemData.forecast_units_affected = itemsRef.reduce((acc, item) => acc + item.forecast_units_affected, 0)
  itemData.forecast_initial_cost = itemsRef.reduce((acc, item) => acc + item.forecast_initial_cost, 0)
  itemData.forecast_emissions_savings = itemsRef.reduce((acc, item) => acc + (item?.forecast_emissions_savings || 0), 0)
  itemData.forecast_kwh_savings = itemsRef.reduce((acc, item) => acc + item.forecast_kwh_savings, 0)
  itemData.forecast_therms_savings = itemsRef.reduce((acc, item) => acc + item.forecast_therms_savings, 0)
  itemData.forecast_lifecycle_savings = itemsRef.reduce((acc, item) => acc + item.forecast_lifecycle_savings, 0)
}
