<template>
  <div class="w-full header px-10 pt-6 mt-2">
    <div class="w-full flex flex-wrap">
      <div class="app-title flex items-center flex-shrink-0 w-auto mr-auto">
        <h1 class="text-header-6 text-deepsky-900 font-bold mr-2 flex-shrink-0">
          Summary for
        </h1> 
        <JurisdictionSelect
          :should-scroll="false"
          :input-class="'text-header-6'"
          :jurisdiction-selected="jurisdiction"
          @closeInput="showInput = false"
          @change="onJurisdictionSelect"
        />
      </div>

      <div class="w-1/2 actions flex items-start justify-end">
        <div class="psui-flex psui-items-end psui-space-x-4">
          <PsButton
            label="Share"
            icon="share"
            size="small"
            layout="onlytext"
            class="psui-text-blue-50"
            @click="onCopyLink"
          />
        </div>        
      </div>

      <div class="w-full">
        <p class="text-accent-2 text-ash-500 mb-3 mt-2">
          {{ climateZoneText }}
        </p>
      </div>
    </div> <!-- grid-cols-2 -->
  </div>
</template>

<script>
import { slugify, copyUrlToClipboard } from '@/util/Helpers'
import { joinCommaPlusAnd } from '@/util/Functions'

export default {
  props: {
    jurisdiction :{
      type: [Boolean, Object]
    }
  },
  computed: {
    pageTitle() {
      return `${this.jurisdiction.title_type} - Summary`
    },
    fileName() { 
      return slugify(this.pageTitle)
    },    
    climateZoneText() {
      if (this.jurisdiction) {
        const zonesLength = this.jurisdiction?.climate_zones?.length
        const climateZonesPrefixes = this.jurisdiction.climate_zones.map((cz) => cz.prefix)
        return `${this.jurisdiction.title_type} has ${zonesLength} climate zone${zonesLength > 1 ? 's' : ''}: ${joinCommaPlusAnd(climateZonesPrefixes)}`        
      } else {
        return ''
      }
    },
  },
  methods: {
    onJurisdictionSelect(jurisdiction) {
      this.$store.dispatch('setUserLastJurisdictionAndFirstClimateZoneVisited', { value: jurisdiction, context: 'updateLastJurisdictionVisitedFromSummary' })
      this.pushToParams('jurisdiction_slug', jurisdiction.slug)
    },
    actionsHandler(event) {      
      this[event]()
    },
    onCopyLink() {
      copyUrlToClipboard()
      this.$appToast({ message: 'Link copied to clipboard!' })
    },
    onDownloadPdf() {
      this.$store.dispatch('buildingStock/exportToPdf', this.fileName)
    },
  },
}
</script>

<style scoped lang="scss">
  .header {
    display: flex;
    flex-wrap: wrap;
  }
  .headline {
    width: 100%;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    margin-bottom: 2px;
    margin-left: -23px;
    img {
      margin-right: 8px;
      margin-left: -1px;
    }
    a {
      text-decoration: none;
    }
  }
  .title {
    ::v-deep {
      .jurisdiction--select {
        width: auto;
      }
    }
  }
  .actions {
    // margin-top: 24px;    
    ul {
      li {
        display: inline-block;
        margin-left: 44px;
        cursor: pointer;

        &:first-of-type {
          margin-left: 0;
        }
      }
    }
  }

  @media screen and (min-width: 1190px){
    .header {
      align-items: center;
    }
    .actions {
      margin-left: auto;
      margin-top: 0;
    }
  }
</style>

<style>
.route-summary #base-header {
  border-bottom: 0;
}
</style>
