<template>
  <PsAccordionItem
    title="Effective Date"
    :class="{ 'form-dirty' : hasValueUpdated }"
  >
    Requirements will take effect starting 
    <Dropdown 
      button-classes="dynamic"
      :class="{ 'different-from-base-value' : hasValueUpdated }"
      @close="onCloseStartAtDateQuarterDropdown"
    >
      <template v-slot:buttonLabel>
        {{ dateAsQuarter }}
      </template>
      <template v-slot:items>
        <Checkbox
          v-for="quarter in availableQuarters"
          :key="quarter.format('DD/MM/YYYY')" 
          class="w-full"
          :label="`<strong>Quarter ${quarter.quarter()}</strong> (starting ${quarter.format('MMM D')})`"
          :value="quarter.isSame(startAtDateQuarterInputValue)"
          type="radio"
          @input="onToggleStartAtDateQuarterCheckbox(quarter)"
        />
      </template>
    </Dropdown>,
    <DropdownWithInputNumber
      :value="startAtDate.year()"
      :min-value="startAtDateYearMin"
      :max-value="startAtDateYearMax"
      :placeholder="`${startAtDate.year()}`"
      :base-value="Number(startAtYearBaseValue)"
      :class="{ 'different-from-base-value' : hasValueUpdated }"
      @update:value="onUpdateStartAtDateYear($event)"
      @updateValue="$emit('updateAssumption')"
    />.
  </PsAccordionItem>
</template>

<script>
import DropdownWithInputNumber from '@/components/general/DropdownWithInputNumber.vue'
import dayjs from 'dayjs'
import { getQuartersInYear } from '@/util/DateFunctions'
import { defaultImpactAssumptionValues } from '@/models/ImpactAssumptions'
import { getNextQuarterFromToday } from '@/util/DateFunctions'

export default {
  name: 'AssumptionsImpactsEffectiveDate',
  components: { 
    DropdownWithInputNumber
  },
  data: () => ({
    startAtDateYearMin : 2015,
    startAtDateYearMax : 2080,
    startAtDateYearInputValue : '',
    startAtDateQuarterInputValue : '',    
    startAtBaseValue : defaultImpactAssumptionValues.start_at,
    startAtYearBaseValue : getNextQuarterFromToday({ format: 'YYYY' })
  }),
  computed: {
    hasValueUpdated() {
      return this.startAtBaseValue !== this.startAtDate.format('MM/DD/YYYY')
    },
    startAtDate() {
      return dayjs(this.$store.state.assumptions[this.$store.state.assumptions.drawerOptions.type]['start_at'])
    },
    dateAsQuarter() {
      return `Quarter ${this.startAtDate.quarter()}`
    },
    availableQuarters() {        
      const quarters = getQuartersInYear(this.startAtDate.year())
      return quarters.map((quarter) => dayjs(quarter))
    },
  },
  methods: {

    onUpdateStartAtDateYear(value) {
      const v = this.startAtDate.year(value).format('MM/DD/YYYY')
      this.$store.dispatch('assumptions/updateAssumptionsKeyValue', { key: 'start_at', value: v })
    },    
    onToggleStartAtDateQuarterCheckbox(quarter) {
      this.startAtDateQuarterInputValue = quarter
    },
    onCloseStartAtDateQuarterDropdown() {
      const v = this.startAtDateQuarterInputValue
      this.$store.dispatch('assumptions/updateAssumptionsKeyValue', { key: 'start_at', value: v.format('MM/DD/YYYY') })
      this.$emit('updateAssumption')
    }        
  }
  
}
</script>