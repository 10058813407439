<template>
  <div class="w-full flex flex-col bg-ash-200">
    <AdminPageTitle title="Users Dashboard" />

    <div class="w-full h-auto flex space-x-4 px-8 py-4">
      <div class="w-1/2 psui-w-full h-auto psui-rounded-md psui-bg-white psui-shadow-elevation-5 p-8">
        <AdminDashboardMobileInfo />
      </div>
      <div class="w-1/2 psui-w-full h-auto psui-rounded-md psui-bg-white psui-shadow-elevation-5 p-8">
        <AdminDashboardOsInfo />
      </div>
    </div>
    <div class="w-full h-auto flex space-x-4 px-8 py-4">
      <div class="w-1/2 psui-w-full h-auto psui-rounded-md psui-bg-white psui-shadow-elevation-5 p-8">
        <AdminDashboardTopJurisdiction />
      </div>
      <div class="w-1/2 psui-w-full h-auto psui-rounded-md psui-bg-white psui-shadow-elevation-5 p-8">
        <AdminDashboardTopJurisdictionVisited />
      </div>
    </div>
    <div class="w-full h-auto flex space-x-4 px-8 py-4">
      <div class="w-1/2 psui-w-full h-auto psui-rounded-md psui-bg-white psui-shadow-elevation-5 p-8">
        <AdminDashboardTopPoliciesVisited />
      </div>
      <div class="w-1/2 psui-w-full h-auto psui-rounded-md psui-bg-white psui-shadow-elevation-5 p-8">
        <AdminDashboardTopUsersBySession />
      </div>
    </div>
  </div>
</template>

<script>
import AdminDashboardMobileInfo from './AdminDashboardMobileInfo.vue'
import AdminDashboardOsInfo from './AdminDashboardOsInfo.vue'
import AdminDashboardTopJurisdiction from './AdminDashboardTopJurisdiction.vue'
import AdminDashboardTopJurisdictionVisited from './AdminDashboardTopJurisdictionVisited.vue'
import AdminDashboardTopPoliciesVisited from './AdminDashboardTopPoliciesVisited.vue'
import AdminDashboardTopUsersBySession from './AdminDashboardTopUsersBySession.vue'
export default {
  name: 'AdminDashboardIndex',
  components: { 
    AdminDashboardMobileInfo, 
    AdminDashboardOsInfo, 
    AdminDashboardTopJurisdiction, 
    AdminDashboardTopJurisdictionVisited, 
    AdminDashboardTopPoliciesVisited,
    AdminDashboardTopUsersBySession,
  },
  data() {
    return {
      mobile_info: {
        total: 0,
        is_mobile_count: 0,
        session_count: 0
      },
      os_info: [],
      browser_info: [],
    }
  }
}
</script>