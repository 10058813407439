<template>
  <div class="pt-4">
    <AssumptionsBuildingStocksInput
      label="Existing Units"
      :filters="filters"
      class="stock-input"
    />
    <div
      v-for="year in years"
      :key="year"
      class="pt-7 psui-text-small psui-text-gray-80"
    >
      <h2 class="font-bold mb-4">
        {{ year }}
      </h2>
      <AssumptionsBuildingEstimatesInputs
        :filters="{...filters, year }"
      />
    </div>
  </div>
</template>

<script>
import AssumptionsBuildingStocksInput from '../AssumptionsBuildingStocksInput.vue'
import AssumptionsBuildingEstimatesInputs from '../AssumptionsBuildingEstimatesInputs.vue'
import { BUILDING_ESTIMATES_BASE_YEARS } from '@/modules/app/assumptions/shared/enums'
export default {
  name: 'AssumptionsResidentialNewYears',
  components: { AssumptionsBuildingEstimatesInputs, AssumptionsBuildingStocksInput },
  props: {
    filters: Object,
  },
  data: () => ({
    years: BUILDING_ESTIMATES_BASE_YEARS
  })
}
</script>

<style lang='scss' scoped>
  ::v-deep .stock-input {
    & > div {
      margin-bottom: 0;
    }
  }

</style>