import Api from '@/util/Api'
import GraphQL from '@/util/GraphQL'
export default class ConstructionImplementationTypeApiService {

  storeOrUpdate(payload = null) {
    payload = payload ?? this
    if(payload.id) return Api.post(`/api/admin/construction_implementation_type/update/${payload.id}`, { ...payload })
    return Api.post(`/api/admin/construction_implementation_type/store`, { ...payload })
  }

  show(construction_implementation_type_id = null) {
    construction_implementation_type_id = construction_implementation_type_id ?? this.id
    const query = `
      {
        construction_implementation_type (find: ${construction_implementation_type_id}) {
          id
          title
          slug
          content
        }
      }
    `
    return GraphQL({ query })
  }

}