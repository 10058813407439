<template>
  <div
    id="study-results-header"
    class="w-full flex flex-col pt-2 pb-2 px-8"
  >
    <div class="w-full flex flex-wrap">
      <div class="app-title flex items-center flex-shrink-0 w-auto mr-auto">
        <h1 class="text-header-6 text-deepsky-900 font-bold mr-2 flex-shrink-0">
          Results for
        </h1> 
        <JurisdictionSelect
          :should-scroll="false"
          :input-class="'text-header-6'"
          :jurisdiction-selected="lastJurisdictionVisited"
          @change="onJurisdictionSelect"
        />
      </div>
      
      <div class="psui-flex psui-items-end psui-space-x-4">
        <PsButton
          label="Share"
          icon="share"
          size="small"
          layout="onlytext"
          class="psui-text-blue-50"
          @click="copyLink"
        />
        <PsButton
          label="Download"
          icon="save_alt"
          size="small"
          layout="onlytext"
          class="psui-text-blue-60"
          @click="generatePdf()"
        />
      </div>
    </div>

    <FiltersWrapper />
  </div>
</template>

<script>
import FiltersWrapper from './filters/FiltersWrapper'
import { mapGetters } from 'vuex'
import StudyResultsGeneratePdfService from '@/services/pdf/StudyResultsGeneratePdfService'
import { joinCommaPlusAnd } from '@/util/Functions'
import { GA_LABELS } from '@/mixins/GaEventsMixin'
import { copyUrlToClipboard } from '@/util/Helpers'

export default {
  name: 'StudyResultsHeader',
  components: { FiltersWrapper },
  computed: {
    ...mapGetters(['lastJurisdictionVisited', 'lastClimateZoneVisited'])
  },
  methods: {
    onJurisdictionSelect(jurisdiction) {
      this.$store.dispatch('setUserLastJurisdictionAndFirstClimateZoneVisited', { value: jurisdiction, context: 'updateLastJurisdictionVisitedFromStudyResults'})
      this.$router.push({
        name: 'StudyResults',
        params: {
          jurisdiction_slug: jurisdiction.slug,
          climate_zone_raw: jurisdiction.climate_zones?.[0]?.raw
        }
      })
    },
    generatePdf() {
      this.toastInstance = this.$appToast({ message: 'Loading...', duration: 40000 })
      this.generatePdfFile()
      this.gaEventDownloadFile(GA_LABELS.RESULTS_DOWNLOAD_PDF, window.location.href)
    },
    copyLink() {
      copyUrlToClipboard()
      this.$appToast({ message: 'Link copied to clipboard!', duration: 5500 })
      this.gaEventShareUrl(GA_LABELS.RESULTS_SHARE_URL)
    },

    async generatePdfFile() {

      this.gaEventDownloadFile(GA_LABELS.RESULTS_DOWNLOAD_PDF)

      const args = {
        title: this.lastJurisdictionVisited?.title_type,
        subtitle: `Climate Zone ${joinCommaPlusAnd([this.lastClimateZoneVisited.prefix])}`,
        sources : [
          {
            "title": "2019 V2 Cost-Effectiveness Study: Existing Single Family Residential Building Upgrades",
            "url": "https://localenergycodes.com/download/875/file_path/fieldList/2019%20V2-Residential%20Retrofit%20Cost-eff%20Report-2021-08-27.pdf",
            "subtitle": "California Energy Codes and Standards Program, PG&E. Produced by:  Frontier Energy, Inc, Misti Bruceri & Associates."
          },
          {
            "title": "2019 V1 Cost-Effectiveness Study: Existing Low-Rise Residential Building Efficiency Upgrade",
            "url": "https://localenergycodes.com/download/876/file_path/fieldList/2019-V1%20Res%20Retrofit%20Cost-eff%20Report-2020-02-06.pdf",
            "subtitle": "California Energy Codes and Standards Program, PG&E. Produced by:  Frontier Energy, Inc, Misti Bruceri & Associates."
          },
          {
            "title": "2019 Cost-Effectiveness Study: Low-Rise Residential New Construction",
            "url": "https://localenergycodes.com/download/73/file_path/fieldList/2019%20Res%20NC%20Cost-eff%20Report",
            "subtitle": "California Energy Codes and Standards Program, PG&E. Produced by:  Frontier Energy, Inc, Misti Bruceri & Associates."
          },
          {
            "title": "2019 Nonresidential New Construction Reach Code Cost-Effectiveness Study",
            "url": "https://localenergycodes.com/download/74/file_path/fieldList/2019%20NR%20NC%20Cost%20Effectiveness%20Report",
            "subtitle": "California Energy Codes and Standards Program, SoCal Edison. Produced by:  TRC, EnergySoft."
          }
        ]
      }

      const pdf = new StudyResultsGeneratePdfService( args )
      pdf.then(() => {    
        this.toastInstance.message =  'The PDF is ready and is being downloaded!'
        setTimeout(() => {
          this.toastInstance.dismiss()
        }, 5000)
      }).catch(() => {
        this.toastInstance.message =  'Sorry, something went wrong with the PDF export. Try again'
        this.toastInstance.type =  'error'
        setTimeout(() => {
          this.toastInstance.dismiss()
        }, 5000)
      })
    }
  },    
}
</script>