<template>
  <div class="w-full flex flex-col items-center justify-center">
    <img
      src="/images/policy_wizard/step_manual_finish.png"
      class="max-w-full h-auto inline-block"
    >

    <p class="text-ash-600 text-header-3 mt-3">
      Now you can choose what types of buildings your policy will cover and add requirements.
    </p>

    <AppButton
      class="mt-12"
      size="medium"
      label="BUILD SCOPE AND REQUIREMENTS"
      :to="{ name: 'PolicyShow', params: { policy_id: policy.id} }"
      @click.native="$eventBus.$emit('closeModalAutoGeneratePolicyWizard')"
    />
  </div>
</template>

<script>
export default {
  name: 'StepManualFinish',
  props: ['policy']
}
</script>