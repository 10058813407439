import AdminStudyTypeList from '@/modules/admin/study-type/AdminStudyTypeList'
import AdminStudyTypeForm from '@/modules/admin/study-type/AdminStudyTypeForm'
import baseAdminMeta from '@/modules/admin/baseAdminMeta'

export default [
  {
    name: 'AdminStudyTypeList',
    path: 'study-types',
    component: AdminStudyTypeList,
    meta: { ...baseAdminMeta, title: 'Study types' }
  },
  {
    name: 'AdminStudyTypeCreate',
    path: 'study-types/create',
    component: AdminStudyTypeForm,
    meta: { ...baseAdminMeta, title: 'Study type create' }
  },
  {
    name: 'AdminStudyTypeEdit',
    path: 'study-types/:study_type_id/edit',
    component: AdminStudyTypeForm,
    meta: { ...baseAdminMeta, title: 'Study type edit' }
  }
]