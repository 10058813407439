<template>
  <div>
    <div class="w-full">
      <h1 class="text-header-4 font-bold text-deepsky-800 mr-3 float-left">
        Compare Policies for
      </h1>
      <vue-skeleton-loader
        class="float-left"
        :width="200"
        :height="31"
      />
    </div>
    <div class="w-full block float-left">
      <vue-skeleton-loader
        class="float-right"
        :width="600"
        :height="40"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SkeletonComparisonHeader'
}
</script>