<template>
  <div class="w-full">
    <!-- <h4 class="text-base font-bold text-gray04 mt-6">{{title}}</h4> -->

    <p
      v-if="!items || !items.length"
      class="text-red text-base"
    >
      This list is empty!
    </p>
    <table
      v-else
      class="table table-bordered whitespace-no-wrap"
    >
      <thead>
        <tr>
          <th width="5%">
            Order
          </th>
          <th width="5%">
            ID
          </th>
          <th width="30%">
            Title
          </th>
          <th
            v-if="showStudyColumn"
            width="30%"
          >
            Study
          </th>
          <th
            v-if="showStudyColumn"
            width="30%"
          >
            Study Released at
          </th>
          <th
            width="10%"
            class="capitalize"
          >
            {{ this.type }} Type
          </th>
          <th
            v-if="type === 'measure'"
            width="10%"
          >
            Is Package
          </th>
          <th width="10%">
            Slug (external id)
          </th>
          <th width="15%">
            Actions
          </th>
        </tr>
      </thead>
      <tbody>
        <tr 
          v-for="(item, index) in items" 
          :key="`study-${title}-${item.id}`"
          draggable="true"
          class="cursor-move"
          :class="{ 'bg-ash-200' : item.include_in_combination }"
          @dragstart="dragStart(index)"
          @dragover.prevent
          @dragend="dragEnd"
          @drop="dragFinish(index, items)"
        >
          <td>{{ item.order }}</td>
          <td>{{ item.id }}</td>
          <td>{{ item.title }}</td>
          <td v-if="showStudyColumn">
            {{ item.study.title }}
          </td>
          <td v-if="showStudyColumn">
            {{ item.study.released_at }}
          </td>
          <td>
            <span v-if="item[`type_${type}`]">{{ item[`type_${type}`].title }}</span>
            <span 
              v-if="item.type_measures && item.type_measures.length" 
            >
              <router-link
                v-for="(type_measure, index_type_measure) in item.type_measures"
                :key="`index_type_measure_${index_type_measure}`"
                :to="{ name: 'AdminTypeFilterEdit', params: { type: 'measure', type_filter_id: type_measure.id }}"
                class="bg-ash-200 p-2 mr-2 rounded-sm"
              >
                <span>{{ type_measure.title }}</span>
              </router-link>

            </span>
          </td>
          <td v-if="type === 'measure'">
            {{ item.is_package ? 'Yes' : 'No' }}
          </td>
          <td>{{ item.slug }}</td>
          <td class="psui-flex psui-space-x-2">
            <!-- <router-link 
              :to="{ name: 'AdminStudyItemEdit', params: { study_item_id: item.id, type: type}}" 
              label="edit" 
              class="button-sm button-blue04 mr-2"
            >
              Edit
            </router-link>
            <button
              class="button-sm button-red"
              @click="deleteItem(item.id)"
            >
              Delete
            </button> -->
            <router-link 
              :to="{ name: 'AdminStudyItemEdit', params: { study_item_id: item.id, type: type}}" 
            >
              <PsButton  
                label="Edit" 
                size="medium"
                icon="edit"
              />
            </router-link>
            <PsButton
              layout="caution"
              label="Delete"
              size="medium"
              icon="delete"
              @click="deleteItem(item.id)"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'AdminBaseModelTable',
  props: ['type', 'title', 'items', 'showStudyColumn'],
  computed: {
    getTableToOrdenateItems() {
      return `${this.type}s`
    }
  },
  methods: {
    deleteItem(idItem) {
      this.$api.delete(`/${this.type}/${idItem}`)
        .then(({ data }) => {
          this.$toast.success(data.message)
          this.$emit('getItems')
        })
    }
  }
}
</script>