<template>
  <div class="app--title-with-icon">
    <div
      v-if="icon"
      class="icon"
    >
      <img
        :src="`${publicPath}icons/${icon}.svg`"
        alt="Icon"
      >
    </div>
    <h2
      class="title"
      :class="cssTitle"
    >
      {{ title }}
    </h2>
    <div class="content flex items-end">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String
    },
    title: {
      type: String,
      required: true,
    },
    cssTitle: {
      type: String,
      default: 'ts--title--5 font-bold text-bluebrand'
    }
  },
  data: () => ({
    publicPath: process.env.BASE_URL
  })
}
</script>
<style scoped lang="scss">
  .app--title-with-icon {
    display: flex;
    align-items: flex-end;

    .icon {
      margin-right: 11px;
    }
    .title {
      margin-right: 14px;      
    }
    .title, .content * {
      line-height: 1;
    }
  }
</style>