import GraphQL from '@/util/GraphQL'

export default {
  namespaced: true,
  
  state: {
    construction_implementation_types: []
  },

  getters: {
    getterGlobalConstructionImplementationTypes(state) {
      return state.construction_implementation_types
    },

    getterConstructionImplementationTypeSelected: state => routeFullPath => {
      const url = new URL(window.location.host + routeFullPath)
      const construction_implementation_type_selected = url.searchParams.get('only_construction_implementation_type')
      if(!construction_implementation_type_selected) return state.construction_implementation_types[0]
      return state.construction_implementation_types.findInArray({ slug: url.searchParams.get('only_construction_implementation_type')})
    }

  },

  actions: {
    loadGlobalConstructionImplementationTypes(context) {
      const query = `
        {
          construction_implementation_types (
            orderBy: [{ column: "order", order: "ASC" }]
          ) {
            id
            title
            slug
          }
        }
      `
      GraphQL({ query })
        .then(({ data }) => {
          context.commit('setGlobalConstructionImplementationTypes', { construction_implementation_types: data.construction_implementation_types})
        })
    }
  },

  mutations: {
    setGlobalConstructionImplementationTypes(state, { construction_implementation_types }) {
      state.construction_implementation_types = construction_implementation_types
    }
  }
}