import LogRocket from 'logrocket'
import createPlugin from 'logrocket-vuex'

const identifyUserAtLogRocketOnUserLoggedIn = (Store) => {
  Store.subscribe((mutation) => {
    if(mutation.type === 'setLoggedUser' ) {
      const user = mutation?.payload?.user
      LogRocket.identify(user.id, { email : user.email })
    }
    if(mutation.type === 'setLogout') {
      LogRocket.identify()
    }
  })
}

export default async (Store) => {

  const isMainURI = window.location.origin == 'https://explorer.localenergycodes.com' ? true : false
  const isQaURI = window.location.origin.includes('qa-') ? true : false
  
  Store.subscribe((mutation) => {
    if(mutation.type === 'general/setDeviceInfo') {
      if(isMainURI) {
        if(!Store.getters['general/getterDeviceInfo']?.is_admin && !Store.getters['general/getterDeviceInfo']?.is_team) {
          LogRocket.init('ecxjdb/cost-effectiveness-explorer')
          const LogRocketPlugin = createPlugin(LogRocket)
          LogRocketPlugin(Store)
        }
        identifyUserAtLogRocketOnUserLoggedIn(Store)
    
      } else if (isQaURI) {
        LogRocket.init('explorer-devs/explorer-qa')
        const LogRocketPlugin = createPlugin(LogRocket)
        LogRocketPlugin(Store)
        identifyUserAtLogRocketOnUserLoggedIn(Store)
      }
    }
  })
  

}