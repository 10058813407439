import { GREATER_THEN_ONE_NUMBER } from '@/business-logic/constants/helpersEnum'

export default (value) => {

  if (value === GREATER_THEN_ONE_NUMBER ) {
    return '∞'
  } else if (value === '' || isNaN(value) || Number.isNaN(value)) {
    return value ? value : '--'
  } else {
    
    const onlyNumbers = Math.abs(value)
    
    // <100 -> 12.3
    if(onlyNumbers < 100) return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2, minimumFractionDigits: 2,  }).format(value)
    // <1000 -> 123
    if(onlyNumbers < 1000) return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }).format(value)
    // <10,000 -> 1,234
    // <100,000 -> 12,345
    // <1,000,000 -> 123,456
    if(onlyNumbers < 1000000) return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }).format(value)
    // <10,000,000 -> 1.23M
    if(onlyNumbers < 10000000) return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2, minimumFractionDigits: 2, notation: "compact" , compactDisplay: "short" }).format(value)
    // <100,000,000 -> 12.3M
    if(onlyNumbers < 100000000) return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 1, minimumFractionDigits: 1, notation: "compact" , compactDisplay: "short" }).format(value)
    // <1,000,000,000 -> 123M
    if(onlyNumbers < 1000000000) return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0, notation: "compact" , compactDisplay: "short" }).format(value)
    // <10,000,000,000 -> 1.23B
    if(onlyNumbers < 10000000000) return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2, minimumFractionDigits: 2, notation: "compact" , compactDisplay: "short" }).format(value)
    // <100,000,000,000 -> 12.3B
    if(onlyNumbers < 100000000000) return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 1, minimumFractionDigits: 1, notation: "compact" , compactDisplay: "short" }).format(value)
    // <1,000,000,000,000 -> 123B
    if(onlyNumbers < 1000000000000) return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0, notation: "compact" , compactDisplay: "short" }).format(value)
    // <10,000,000,000,000 -> 1.23T
    if(onlyNumbers < 10000000000000) return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2, minimumFractionDigits: 2, notation: "compact" , compactDisplay: "short" }).format(value)
    // <100,000,000,000,000 -> 12.3T
    if(onlyNumbers < 100000000000000) return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 1, minimumFractionDigits: 1, notation: "compact" , compactDisplay: "short" }).format(value)
    // <1,000,000,000,000,000 -> 123T
    if(onlyNumbers < 10000000000000000) return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0, notation: "compact" , compactDisplay: "short" }).format(value)

    //Finish it later
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0, notation: "compact" , compactDisplay: "short" }).format(value)
    // <10,000,000,000,000,000 -> 1.23x10^15
    // <100,000,000,000,000,000 -> 12.3x10^15
    // <1,000,000,000,000,000,000 -> 123x10^15

  }

}