export const POLICY_LIST_JURISDICTION_FILTER_ALL = {
  id: "ALL",
  title_type: "All Cities / Counties",
}

/* Policies/Comparison */
/* ----------------------------------------- */
export const showPoliciesByOptions = {
  ALL: "All",
  USER: "Created by me",
  SHARED: "Shared with me",
}
/* ----------------------------------------- Policies/Comparison */

/* Policy Auto Generate Methods */
/* ----------------------------------------- */
  export const POLICY_METHOD_AUTO_GENERATE = 'POLICY_METHOD_AUTO_GENERATE'
  export const POLICY_METHOD_DEFAULT = 'POLICY_METHOD_DEFAULT'
/* ----------------------------------------- Policy Auto Generate Methods */
