export default {
  methods: {
    validationClear(key = null) {
      if(!this.validation) return
      if (key) {
        if(!this.validation[key]) return
        this.validation[key].hasError = false
        this.validation[key].label = ''
        this.validation[key].updated = false
      } else {
        Object.keys(this.validation).forEach((key) => {
          if(!this.validation[key]) return
          this.validation[key].hasError = false
          this.validation[key].label = ''
          this.validation[key].updated = false
        })
      }
    }
  }
}