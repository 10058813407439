import Api from '@/util/Api'
import GraphQL from '@/util/GraphQL'
export default class BuildingTypeApiService {

  storeOrUpdate(payload = null) {
    payload = payload ?? this
    if(payload.id) return Api.post(`/api/admin/building_type/update/${payload.id}`, { ...payload })
    return Api.post(`/api/admin/building_type/store`, { ...payload })
  }

  show(building_type_id = null) {
    building_type_id = building_type_id ?? this.id
    const query = `
      {
        building_type (find: ${building_type_id}) {
          id
          title
          slug
          content
          type_prototypes {
            id
            title
            slug
          }
          type_prototypes {
            id
            title
          }
        }
      }
    `
    return GraphQL({ query })
  }


}