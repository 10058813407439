<template>
  <div class="w-full">
    <div class="w-full flex flex-wrap px-2">
      <div class="w-full" />
      <div class="w-full flex items-center mb-6">
        <h1 class="text-header-4 font-bold text-deepsky-800 mr-6 flex items-center">
          Saved policies
        </h1>        
        <PoliciesListFilters
          :jurisdictions="policiesJurisdictions"
          class="flex items-center mr-auto"
        />
        <button 
          class="ml-6 bg-blue04 uppercase text-white rounded-xs px-2 py-1 text-accent-2 font-bold flex items-center" 
          @click="$eventBus.$emit('openModalAutoGeneratePolicyWizard')"
        >
          <feather
            type="file-plus"
            size="24"
          />
          <span class="ml-2 leading-none">CREATE NEW POLICY</span>  
        </button>
      </div>
      <PoliciesListTable
        v-if="policiesFiltered.length > 0"
        :policies="policiesFiltered"
      />
      <SkeletonPoliciesListTable v-else-if="getAllUserPolicies === null" />
    </div>
    <PoliciesListUserHasNoPolicies v-if="!getAllUserPolicies.length" />
  </div>
</template>

<script>
import SkeletonPoliciesListTable from './skeleton/SkeletonPoliciesListTable.vue'
import PoliciesListUserHasNoPolicies from './PoliciesListUserHasNoPolicies.vue'
import PoliciesListTable from './PoliciesListTable.vue'
import PoliciesListFilters from './PoliciesListFilters.vue'
import { showPoliciesByOptions } from '@/business-logic/constants/policiesEnum'
import { ArrayHelpers } from '@igortrindade/lazyfy'

export default {
  name: 'PoliciesListUserHasPolicies',
  components: {
    PoliciesListFilters,
    PoliciesListTable,
    PoliciesListUserHasNoPolicies,
    SkeletonPoliciesListTable
  },
  computed: {
    getUser() {
      return this.$store.state.auth.loggedUser
    },
    getAllUserPolicies() {
      return this.$store.getters['policy/getterAllUserPolicies']()
    },
    filterByAuthor() {
      return this.$store.state.auth.user_preferences?.policies?.myPolicies?.filterByAuthor
    },
    filterByJurisdiction() {
      return this.$store.state.auth.user_preferences?.policies?.myPolicies?.filterByJurisdiction
    },
    policiesFiltered() {
      return this.getAllUserPolicies
        .filter(this.filterPoliciesByAuthor)
        .filter(this.filterPoliciesByJurisdiction)
    },
    policiesJurisdictions() {
      if (!this.getAllUserPolicies) return []
      return ArrayHelpers.uniqueByKey(this.getAllUserPolicies, 'jurisdiction_id').map(policy => policy.jurisdiction)
    }
  },
  methods: {
    filterPoliciesByAuthor(policy) {
      if (this.filterByAuthor === showPoliciesByOptions.SHARED ) {
        return policy.user_id !== this.getUser.id
      } else if ( this.filterByAuthor === showPoliciesByOptions.USER ) {
        return policy.user_id === this.getUser.id
      } else {
        return true
      }      
    },
    filterPoliciesByJurisdiction(policy) {
      return this.filterByJurisdiction && !this.filterByJurisdiction.includes(`${policy.jurisdiction_id}`) ? true : false
    }
  }
}
</script>

<style>

</style>