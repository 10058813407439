import GraphQL from '@/util/GraphQL'

export default {
  namespaced: true,
  
  state: {
    building_heights: []
  },

  getters: {
    getterGlobalBuildingHeights(state) {
      return state.building_heights
    },

    getterBuildingHeightSelected: state => routeFullPath => {
      const url = new URL(window.location.host + routeFullPath)
      const building_height_selected = url.searchParams.get('only_building_height')
      if(!building_height_selected) return state.building_heights[0]
      return state.building_heights.findInArray({ slug: url.searchParams.get('only_building_height')})
    }

  },

  actions: {
    loadGlobalBuildingHeights(context) {
      const query = `
        {
          building_heights (
            orderBy: [{ column: "order", order: "ASC" }]
          ) {
            id
            title
            slug
          }
        }
      `
      GraphQL({ query })
        .then(({ data }) => {
          context.commit('setGlobalBuildingHeights', { building_heights: data.building_heights})
        })
    }
  },

  mutations: {
    setGlobalBuildingHeights(state, { building_heights }) {
      state.building_heights = building_heights
    }
  }
}